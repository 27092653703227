
import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography, Button, TextField, Box, Card, CardContent, IconButton, Grid, Grid2, Container, List, ListItem, ListItemIcon, ListItemText, Switch, FormControlLabel, Select, MenuItem, Radio, RadioGroup, FormControl, FormLabel, Input, InputLabel, Checkbox, } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IoIosCheckboxOutline } from "react-icons/io";
import { MdOutlineFileUpload, MdOutlineAccessTime } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";

import {
    Subject as TextAreaIcon,
    List as SelectIcon,
    DragIndicator as DragIndicatorIcon,
    Delete as DeleteIcon,
    TextFields,
    Numbers,
} from '@mui/icons-material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { RxSwitch } from "react-icons/rx";
import FormView from './FormView';
import CustomComponentContainer from '../../components/container/CustomComponentContainer';
import { POST, PUT } from '../../helpers/http';
import { errorToast, successToast } from '../../helpers/apiToast';
import * as Yup from 'yup';
import { ThemeColors } from '../../hooks/ThemeColors';

const theme = createTheme({
    components: {

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'gray', // outline color on hover
                        borderWidth: '.5px',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'gray', // outline color when focused
                        borderWidth: '1px',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-disabled):before': {
                        borderColor: 'gray', // underline color on hover
                        borderWidth: '1px',
                    },
                    '&.Mui-focused:after': {
                        borderColor: 'gray', // underline color when focused
                        borderWidth: '1px',
                    }
                },
            },
        },
    },
});

const FormBuilder = ({ editForm, edit, toggleDrawer }) => {
    const [formFields, setFormFields] = useState(editForm?.fields || []);
    const [changes, setChanges] = useState(0);
    const [draggedItem, setDraggedItem] = useState(null);
    const [dragOverIndex, setDragOverIndex] = useState(null);
    const [viewForm, setViewForm] = useState(false);
    const [finalForm, setFinalForm] = useState({ name: editForm?.name, description: editForm?.description } || { name: '', description: '' })
    const [errors, setErrors] = useState({});
    const { commonSX, background_color, color, textColor, borderSX } = ThemeColors();

    const bottomRef = useRef(null); // Reference to the last item

    // Scroll to the bottom whenever data updates
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [changes]);

    const fieldTypes = [
        { type: 'text', label: 'Text', icon: <TextFields /> },
        { type: 'number', label: 'Number', icon: <Numbers /> },
        { type: 'textarea', label: 'Text Area', icon: <TextAreaIcon /> },
        { type: 'select', label: 'Dropdown', icon: <SelectIcon /> },
        { type: 'multiSelect', label: 'Multiple Selection', icon: <SelectIcon /> },
        { type: 'switch', label: 'Switch', icon: <span><RxSwitch style={{ fontSize: '24px' }} /></span> },
        { type: 'checkbox', label: 'Checkbox', icon: <span><IoIosCheckboxOutline style={{ fontSize: '24px' }} /></span> },
        // { type: 'fileUpload', label: 'File Upload', icon: <span><MdOutlineFileUpload style={{ fontSize: '24px' }} /></span> },
        { type: 'date', label: 'Date', icon: <span><BsCalendarDate style={{ fontSize: '24px' }} /></span> },
        { type: 'time', label: 'Time', icon: <span><MdOutlineAccessTime style={{ fontSize: '24px' }} /></span> },
    ];

    const handleAddField = (field) => {
        setChanges(changes + 1);
        const newField = {
            id: Date.now(),
            type: field.type,
            value: '',
            label: field?.label,
            required: false,
            options: field?.type === 'select' || field?.type === 'radio' || field?.type === 'multiSelect' ? ['Option 1', 'Option 2'] : [],
        };
        setFormFields([...formFields, newField]);
    };

    // Drag and drop handlers remain the same as previous version
    const handleDragStart = (e, index) => {
        setDraggedItem(index);
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
        setDragOverIndex(null);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        if (index !== dragOverIndex) {
            setDragOverIndex(index);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOverIndex(null);
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        e.stopPropagation();

        const dragIndex = Number(e.dataTransfer.getData('text/plain'));
        if (dragIndex === dropIndex) return;

        const newFormFields = [...formFields];
        const draggedField = newFormFields[dragIndex];
        newFormFields.splice(dragIndex, 1);
        newFormFields.splice(dropIndex, 0, draggedField);

        setFormFields(newFormFields);
        setDragOverIndex(null);
        setDraggedItem(null);
    };

    const handleDeleteField = (index) => {
        const newFormFields = [...formFields];
        newFormFields.splice(index, 1);
        setFormFields(newFormFields);
    };

    const handleFieldChange = (index, field) => {
        const newFormFields = [...formFields];
        newFormFields[index] = { ...newFormFields[index], ...field };

        setFormFields(newFormFields);
    };

    const handleOptionChange = (index, optionIndex, value) => {
        const newFormFields = [...formFields];
        const newOptions = [...newFormFields[index].options];
        newOptions[optionIndex] = value;
        newFormFields[index].options = newOptions;
        setFormFields(newFormFields);
    };

    const vaidateSchema = Yup.object().shape({
        name: Yup.string()
            .required('Form Name is required'),
        // description: Yup.string()
        // .required('Descripton is required'),

    })

    const validateSchemaFormFields = Yup.array().of(
        Yup.object().shape({
            label: Yup.string()
                .required('Label is required')
                .test(
                    'label-not-equal-type',
                    `Label should not be equal to type`,
                    function (value) {
                        // 'this' refers to the current object in the array
                        const { type } = this.parent;
                        return value !== type;
                    }
                ),
            type: Yup.string().required('Type is required'),
            // Add validation for other fields if needed
        })
    )

    const handleSubmit = async () => {
        // Implement form submission logic here
        try {
            console.log({ formFields })
            await vaidateSchema.validate(finalForm, { abortEarly: false });
            // await validateSchemaFormFields.validate(formFields, { abortEarly: false })
            const response = edit ? await PUT(`${process.env.REACT_APP_URL}ppm/v1/form/${editForm._id}`, { ...finalForm, fields: formFields }, " Create Form ") : await POST(`${process.env.REACT_APP_URL}ppm/v1/form`, { ...finalForm, fields: formFields }, " Create Form ")
            if (response.status === 201) {
                successToast("Form Submitted Successfully")
                setErrors({})
            } else if (response.status === 200) {
                successToast("Form Editted Successfully")
                toggleDrawer()
                setErrors({})
            }
        } catch (error) {
            console.log(error)
            if (error.name === 'ValidationError') {
                const newErrors = {};
                error.inner.forEach(e => {
                    newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
            } else {
                console.error('Submission error:', error);
            }
        }
        // You can add further processing like sending data to an API
    };

    const handleClearForm = () => {
        setFormFields([]);
    };


    const handleTimeChange = (newValue, index) => {
        const updatedFields = [...formFields];
        updatedFields[index] = { ...updatedFields[index], time: newValue };
        setFormFields(updatedFields);
    };

    const handleViewForm = (value) => {
        setViewForm(value);
    }

    const handleFormName = (key, value) => {
        const v = value.trim().length === 0 ? value?.trim() : value
        if (v.length > 0) setErrors({ ...errors, [key]: '' });
        setFinalForm((prevForm) => ({ ...prevForm, [key]: v }));
    };


    const renderFieldPreview = (field, index) => {
        switch (field.type) {
            case 'text':
            case 'number':
            case 'email':
                return (
                    <ThemeProvider theme={theme}>
                        <TextField
                            fullWidth
                            type={field.type}
                            disabled={true}
                            helperText={errors[`[${index}].label`] || ''}
                            error={errors[`[${index}].label`] ?? ''}
                            placeholder={`${field.label}`}
                            size="small"
                            required={field.required}

                        />
                    </ThemeProvider>
                );
            case 'textarea':
                return (
                    <ThemeProvider theme={theme}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            helperText={errors[`[${index}].label`] || ''}
                            error={errors[`[${index}].label`] ?? ''}
                            disabled={true}
                            placeholder="Text area"
                            size="small"
                            required={field.required}
                        />
                    </ThemeProvider>
                );

            case 'fileUpload':
                return (
                    <Box key={index}
                        sx={{
                            mb: 2,
                        }}
                    >
                        <Input
                            type="file"
                            disabled={true}
                        // onChange={(event) => handleFileUpload(event, index)}
                        />
                        {field.file && (
                            <Typography variant="body2"

                                sx={{
                                    mt: 1,
                                }}>
                                {field.file.name}
                            </Typography>

                        )}
                        {errors[`[${index}]?.label`] && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {errors[`[${index}]?.label`]}
                            </Typography>
                        )}
                    </Box>
                );
            case 'date':
                return (
                    <Box key={index}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Select Date"
                                disabled
                                size="small"
                                // onChange={(newValue) => handleDateChange(newValue, index)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        helperText={errors[`[${index}].label`] || ''}
                                        error={errors[`[${index}].label`] ?? ''}
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                height: 40,
                                                color: textColor(),
                                            },
                                        }
                                        }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                );

            case 'switch':
                return (
                    <Box key={index}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled
                                    checked={false}
                                    value={false}
                                    helperText={errors[`[${index}].label`] || ''}
                                    error={errors[`[${index}].label`] ?? ''}
                                    // onChange={handleSwitchToggle(index, field)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                        // ...borderSX,
                                        // '& .MuiInputBase-input': {
                                        //     color: textColor(), // Text color for the input text
                                        // },
                                        // '& .MuiInputBase-input::placeholder': {
                                        //     color: textColor(), // Placeholder text color
                                        //     opacity: 1, // Ensure placeholder is visible
                                        // },

                                    }}
                                />
                            }
                            label={field?.label}
                        />
                    </Box>
                )
            case 'checkbox':
                return (
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox

                                    disabled

                                />
                            }
                            label={field?.label}
                            helperText={errors[`[${index}].label`] || ''}
                            error={errors[`[${index}].label`] ?? ''}
                        />
                    </Box>
                )
            case 'time':
                return (
                    <Box key={index} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={theme}>
                                <TimePicker
                                    disabled={true}
                                    label="Select Time"

                                    // value={time}
                                    onChange={(newValue) => handleTimeChange(newValue, index)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            helperText={errors[`[${index}].label`] || ''}
                                            error={errors[`[${index}].label`] ?? ''}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    height: 40, // Set your desired height here
                                                    ...borderSX,
                                                    '& .MuiInputBase-input': {
                                                        color: textColor(), // Text color for the input text
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: textColor(), // Placeholder text color
                                                        opacity: 1, // Ensure placeholder is visible
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </ThemeProvider>
                        </LocalizationProvider>
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderField = (field, index) => {
        const isDragging = draggedItem === index;
        const isOver = dragOverIndex === index;

        return (
            <Box
                key={field.id}
                sx={{
                    position: 'relative',
                    width: '100%',
                    mb: 2,
                    '&::after': isOver ? {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'primary.main',
                        opacity: 0.1,
                        zIndex: 1,
                        pointerEvents: 'none',
                    } : {},
                }}
            >
                <Card
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e, index)}
                    sx={{
                        opacity: isDragging ? 0.5 : 1,
                        color: textColor(),
                        // width: "70%",
                        cursor: 'move',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            boxShadow: 3,
                        },
                        ...borderSX,
                        '& .MuiInputBase-input': {
                            color: textColor(), // Text color for the input text
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: textColor(), // Placeholder text color
                            opacity: 1, // Ensure placeholder is visible
                        },
                    }}
                >
                    <CardContent sx={{ background: background_color }}>
                        <Grid container alignItems="center" spacing={2}
                            sx={{
                                mb: 2,
                                background: background_color,

                            }}
                        >
                            <Grid item sx={{
                                md: 1,
                                background: background_color,

                            }}>
                                <DragIndicatorIcon
                                    sx={{
                                        color: 'text.secondary',
                                        cursor: 'grab',
                                        '&:active': { cursor: 'grabbing' },
                                        size: 'large',
                                        background: background_color,

                                    }}
                                />
                            </Grid>
                            <Grid item xs
                                sx={{
                                    ...borderSX,
                                    '& .MuiInputBase-input': {
                                        color: textColor(), // Text color for the input text
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textColor(), // Placeholder text color
                                        opacity: 1, // Ensure placeholder is visible
                                    },
                                }} >
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        // value={field.value}
                                        placeholder={field.label}
                                        onChange={(e) => handleFieldChange(index, { label: e.target.value })}
                                        size="small"
                                        fullWidth
                                        variant="filled"
                                        sx={{
                                            ...borderSX,
                                            ...commonSX,
                                            '& .MuiInputBase-input': {
                                                color: textColor(), // Text color for the input text
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: textColor(), // Placeholder text color
                                                opacity: 1, // Ensure placeholder is visible
                                            },
                                            mb: 2,

                                        }}
                                    />
                                    <TextField
                                        // value={field.value}
                                        placeholder="Description (Optional)"
                                        onChange={(e) => handleFieldChange(index, { description: e.target.value })}
                                        size="small"
                                        fullWidth
                                        variant="standard"
                                        sx={{
                                            color: textColor()

                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={field.required}
                                            onChange={(e) => handleFieldChange(index, { required: e.target.checked })}
                                            size="small"
                                            sx={{ ml: 4 }}
                                        />

                                    }
                                    height={20}
                                    label="Required"
                                />
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={() => handleDeleteField(index)}
                                    color="error"
                                    size="small"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {(field.type === 'select' || field.type === 'radio' || field.type === 'multiSelect') && (
                            <Box sx={{ mb: 1, ml: 5 }}>

                                {/* Add and Delete buttons at the top */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            const newOptions = [...field.options, `Option ${field.options.length + 1}`];
                                            handleFieldChange(index, { options: newOptions });
                                        }}
                                    >
                                        Add Option
                                    </Button>
                                </Box>

                                {/* Options with delete buttons */}
                                {field.options.map((option, optionIndex) => (
                                    <Box key={optionIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                // value={option}
                                                placeholder={option}
                                                onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                                                size="small"
                                                sx={{ width: '75%', mr: 1 }}
                                            />
                                        </ThemeProvider>
                                        {field?.options.length > 1 && < Button
                                            size="small"
                                            color="error"

                                            onClick={() => {
                                                const updatedOptions = field.options.filter((_, i) => i !== optionIndex);
                                                handleFieldChange(index, { options: updatedOptions });
                                            }}
                                        >
                                            Delete
                                        </Button>
                                        }
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {/* <Paper sx={{ mx: 2 }}> */}
                        <Grid container sx={{ ml: 5, width: "90%" }}>

                            {renderFieldPreview(field, index)}
                        </Grid>
                        {/* </Paper> */}
                    </CardContent>
                </Card>
            </Box >



        );
    };

    console.log({ errors })

    return (

        <CustomComponentContainer sx={{ width: "100vw", mt: 1, mx: 1 }}>
            <Grid container  >
                <Grid item xs={8} md={2.5}
                    sx={{
                        p: "10px",
                        // border: "1px solid red"
                    }}
                >
                    <Paper
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            padding: "10px",
                            background: background_color,
                            ...borderSX,
                            '& .MuiInputBase-input': {
                                color: textColor(), // Text color for the input text
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: textColor(), // Placeholder text color
                                opacity: 1, // Ensure placeholder is visible
                            },

                            // border: "1px solid blue"
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Form Elements
                        </Typography>
                        <List sx={{ background: background_color }}>
                            {fieldTypes.map((fieldType) => (
                                <ListItem
                                    key={fieldType.type}
                                    button
                                    onClick={() => handleAddField(fieldType)}
                                    sx={{
                                        mb: 1,
                                        border: 1,
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        background: background_color,
                                        color: textColor()
                                    }}
                                >
                                    <ListItemIcon >{fieldType.icon}</ListItemIcon>
                                    <ListItemText primary={fieldType.label} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>

                </Grid>
                {/* main content */}
                <Grid item xs={12} md={9.5}
                    sx={{
                        padding: "10px",
                        background: background_color,
                        ...borderSX,
                        '& .MuiInputBase-input': {
                            color: textColor(), // Text color for the input text
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: textColor(), // Placeholder text color
                            opacity: 1, // Ensure placeholder is visible
                        },
                        // border: "1px solid red"
                    }}
                >
                    <Paper sx={{
                        width: '100%',
                        // height: "100%",
                        overflow: 'hidden',
                        padding: "10px",
                        background: background_color
                    }}>

                        <ThemeProvider theme={theme}>
                            <TextField
                                required
                                // id="filled-required"
                                helperText={errors?.name || ''}
                                error={errors?.name ?? ''}
                                label="Form Name"
                                onChange={(e) => { handleFormName('name', e.target.value) }}
                                variant="filled"
                                sx={{
                                    mb: 2,
                                    mx: 4,
                                    width: "20%",
                                    background: background_color,
                                    color: textColor(),
                                    commonSX

                                }}
                                size="small"
                                value={finalForm?.name}
                            />
                            <TextField
                                label="Description"
                                helperText={errors?.description || ''}
                                error={errors?.description ?? ''}
                                // disabled
                                onChange={(e) => { handleFormName("description", e.target.value) }}
                                size="small"

                                variant='filled'
                                sx={{
                                    mb: 2,
                                    width: "70%",
                                    background: background_color,
                                    commonSX
                                }}
                                value={finalForm?.description}

                            />
                        </ThemeProvider>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Paper
                                variant="outlined"
                                sx={{

                                    px: 5,
                                    py: 2,
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                    backgroundColor: '#FCFCFD',
                                    border: '2px dashed #CBD5E1',
                                    borderRadius: 3,
                                    width: "95%",
                                    height: '75vh',
                                    overflowY: 'auto',
                                    display: formFields.length === 0 ? 'flex' : 'block', // Flex for centering text, block for content
                                    justifyContent: formFields.length === 0 ? 'center' : 'flex-start', // Center text when no fields
                                    alignItems: formFields.length === 0 ? 'center' : 'stretch', // Vertical alignment when no fields
                                    background: background_color,

                                }}
                            >
                                {formFields?.length === 0
                                    ? "Select form elements from the left panel"
                                    : (
                                        <>
                                            {formFields.map((field, index) => renderField(field, index))}
                                            <div ref={bottomRef} />
                                        </>
                                    )
                                }

                            </Paper>

                        </Box>
                        <Box sx={{ mt: 2, ml: 5 }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mr: 2,
                                    backgroundColor: '#dc4d28',
                                    '&:hover': {
                                        backgroundColor: '#b33c20', // Darker shade for hover state
                                    }
                                }}
                                onClick={handleSubmit}
                                disabled={formFields.length === 0}
                            >
                                {edit ? 'Edit' : 'Submit'}
                            </Button>
                            <Button
                                variant="outlined"
                                // color="secondary"
                                onClick={handleClearForm}
                                sx={{
                                    mr: 2,
                                    color: "grey",
                                    border: "1px solid grey "
                                }}
                                disabled={formFields.length === 0}
                            >
                                Clear Form
                            </Button>
                            <Button
                                variant="outlined"
                                // color="secondary"
                                sx={{
                                    color: "grey",
                                    border: "1px solid grey "
                                }}
                                onClick={() => handleViewForm(true)}
                                disabled={formFields.length === 0}
                            >
                                Preview
                            </Button>

                        </Box>

                    </Paper>

                </Grid>
                {
                    viewForm && <FormView finalForm={finalForm} formData={formFields} open={viewForm} handleClose={handleViewForm} handleSubmit={handleSubmit} errors={errors} />
                }

            </Grid>

        </CustomComponentContainer>

    );
};

export default FormBuilder;