import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { GET_CSRF, errorHandler } from '../../../components/facilityManager/helpers/http'
import defaultDashboardGrid, { addNewWidgetTemplateItems } from '../../../components/facilityManager/helpers/defaultDashboardGrid'

const fetchGridSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchAllDashboardTemplates: builder.query({
            query: () => ({
                url: `um/v1/user/configurations`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch All Dashboard Templates')
            }
        }),
        updateADashboardTemplatebyTemplateId: builder.mutation({
            query: ({ data, id }) => ({
                url: `um/v1/user/configurations/${id}`,
                method: 'PUT',
                body: data,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformResponse: async (responseData, meta, arg) => {
                return { responseData, selectedDashboardTemplateId: arg.id }
            },
            transformErrorResponse: (error) => {
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) {
                    GET_CSRF()
                    errorHandler(error, 'Invalid csrf token')
                }
                else errorHandler(error, 'Update Selected Dashboard Template')
            }
        }),
        addANewDashboardTemplate: builder.mutation({
            query: (data) => ({
                url: `um/v1/user/configurations`,
                method: 'POST',
                body: data,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) {
                    GET_CSRF()
                    errorHandler(error, 'Invalid csrf token')
                }
                else
                    errorHandler(error, 'Add New Dashboard Template')
            }
        }),
        deleteADashboardTemplate: builder.mutation({
            query: (id) => ({
                url: `um/v1/user/configurations/${id}`,
                method: 'DELETE',
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) {
                    GET_CSRF()
                    errorHandler(error, 'Invalid csrf token')
                }
                else errorHandler(error, 'Delete New Dashboard Template')
            }
        })
    })
})

export const { useFetchAllDashboardTemplatesQuery, useUpdateADashboardTemplatebyTemplateIdMutation, useAddANewDashboardTemplateMutation, useDeleteADashboardTemplateMutation } = fetchGridSlice

const rowHeight = 100

const margin = [10, 10] //[x,y]

const headerHeight = 49

const dimensionsHeightArray = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
    return {
        [ele]: (rowHeight * ele) + (margin[0] * (ele - 1)) - headerHeight
    }
})

const dimensionsHeight = {}

for (const item of dimensionsHeightArray) {
    const key = Object.keys(item)[0];
    const value = `${item[key]}px`;
    dimensionsHeight[key] = value;
}

const serviceNotAddedItemsBuilder = (servicesArray) => {
    const constants = {
        w: 3,
        h: 3,
    };

    const resultArray = [];

    for (let i = 0; i < servicesArray?.length; i++) {
        const x = (i % 1) * 3;
        const y = Math.floor(i / 1) * 3;

        resultArray.push({
            i: servicesArray?.[i],
            x,
            y,
            ...constants
        });
    }

    return resultArray;
}

const initialState = {
    enabledServices: {
        isLoaded: false,
        data: []
    },
    dashboardTemplates: {
        isLoaded: false,
        data: []
    },
    selectedDashboardTemplate: {
        isLoaded: false,
        data: {}
    },
    selectedServicesForAddingNewTemplate: {
        isLoaded: false,
        data: []
    },
    defaultDashboardTemplate: defaultDashboardGrid,
    addNewWidgetTemplateItems,
    dimensions: {
        rowHeight,
        margin,
        headerHeight,
        dimensionsHeight,
        // cols: { lg: 12, md: 12, sm: 3, xs: 2, xxs: 1 },
        cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
    },
    enabledServicesNotPresentInSelectedDashboardTemplate: {
        isLoaded: false,
        data: [],
        items: []
    },
    modalStates: {
        openCustomisePanel: false,
        showAddNewTemplateWidgetsForm: false,
        addWidgetstoSelectedDashboardTemplate: true,
    }
}

const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setEnabledServices: (state, action) => {
            state.enabledServices.isLoaded = true
            state.enabledServices.data = action.payload
            const servicesNotDisplayedOnScreen = action.payload?.filter(service => {
                return !state.selectedDashboardTemplate?.data?.items?.some(item => item.i === service)
            })
            state.enabledServicesNotPresentInSelectedDashboardTemplate.isLoaded = true
            state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
            state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
            state.addNewWidgetTemplateItems = serviceNotAddedItemsBuilder(action.payload)
        },
        setSelectedDashboardTemplate: (state, action) => {
            const selectedDashboardTemplate = state.dashboardTemplates?.data?.customConfiguration?.dashboard?.find(item => item._id === action.payload)
            state.selectedDashboardTemplate.isLoaded = true
            state.selectedDashboardTemplate.data = selectedDashboardTemplate
            const servicesNotDisplayedOnScreen = state?.enabledServices?.data?.filter(service => {
                return !state.selectedDashboardTemplate?.data?.items?.some(item => item.i === service)
            })
            state.enabledServicesNotPresentInSelectedDashboardTemplate.isLoaded = true
            state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
            state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
        },
        setOpenCustomisePanel: (state, action) => {
            state.modalStates.openCustomisePanel = !!action.payload
        },
        setShowAddNewTemplateWidgetsForm: (state, action) => {
            state.modalStates.showAddNewTemplateWidgetsForm = !!action.payload
        },
        setAddWidgetstoSelectedDashboardTemplate: (state, action) => {
            state.modalStates.addWidgetstoSelectedDashboardTemplate = !!action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllDashboardTemplates' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const selectedDashboardTemplate = action.payload?.data?.customConfiguration?.dashboard?.find(item => item._id === action.payload?.data?.customConfiguration?.selectedDashboardTemplateId)
                    const servicesNotDisplayedOnScreen = state?.enabledServices?.data?.filter(service => {
                        return !selectedDashboardTemplate?.items?.some(item => item.i === service)
                    })
                    state.dashboardTemplates.isLoaded = true
                    state.dashboardTemplates.data = action.payload?.data
                    state.selectedDashboardTemplate.isLoaded = true
                    state.selectedDashboardTemplate.data = selectedDashboardTemplate?._id ? selectedDashboardTemplate : action.payload?.data?.customConfiguration?.dashboard?.[0]
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
                    state.dimensions.dimensionsHeight = dimensionsHeight
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'updateADashboardTemplatebyTemplateId' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const updatedItem = action.payload?.responseData?.template?.customConfiguration?.dashboard?.find(item => item._id === action.payload?.selectedDashboardTemplateId)
                    const servicesNotDisplayedOnScreen = state?.enabledServices?.data?.filter(service => {
                        return !updatedItem?.items?.some(item => item.i === service)
                    })
                    const data = state.dashboardTemplates?.data?.customConfiguration?.dashboard?.map(item => {
                        if (item._id === updatedItem?._id) {
                            return updatedItem
                        }
                        return item
                    })
                    state.dashboardTemplates.data.customConfiguration.dashboard = data
                    state.selectedDashboardTemplate.data = updatedItem
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'addANewDashboardTemplate' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const servicesNotDisplayedOnScreen = state?.enabledServices?.data?.filter(service => {
                        return !action.payload?.data?.items?.some(item => item.i === service)
                    })
                    state.dashboardTemplates.data.customConfiguration.dashboard.push(action.payload?.data)
                    state.selectedDashboardTemplate.data = action.payload?.data
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
                    state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'deleteADashboardTemplate' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const remainingDashboardTemplates = state.dashboardTemplates?.data?.customConfiguration?.dashboard?.filter(item => item?._id !== action.payload?.deletedDashboardTemplateId)
                    state.dashboardTemplates.data.customConfiguration.dashboard = remainingDashboardTemplates
                    if (state.selectedDashboardTemplate?.data?._id === action.payload?.deletedDashboardTemplateId) {
                        const servicesNotDisplayedOnScreen = state?.enabledServices?.data?.filter(service => {
                            return !remainingDashboardTemplates?.[0]?.items?.some(item => item.i === service)
                        })
                        state.selectedDashboardTemplate.data = remainingDashboardTemplates[0]
                        state.enabledServicesNotPresentInSelectedDashboardTemplate.data = servicesNotDisplayedOnScreen
                        state.enabledServicesNotPresentInSelectedDashboardTemplate.items = serviceNotAddedItemsBuilder(servicesNotDisplayedOnScreen)
                    }
                }
            )
    }
})

export const { setEnabledServices, setSelectedDashboardTemplate, setOpenCustomisePanel, setShowAddNewTemplateWidgetsForm, setAddWidgetstoSelectedDashboardTemplate } = gridSlice.actions

export default gridSlice.reducer