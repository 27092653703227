import { Button } from "@mui/material"
import CommonStyles from "../../styles/CommonStyles"
import { errorToast, successToast } from "../../helpers/apiToast"
import { PUT } from "../../helpers/http"

const ActionButton = ({ id, type, isDisabled, isDeleted, handleItemUpdate, name,endPoint , communityId}) => {

	const handleAction = async (id, type, state) => {
		try {
			const body = {
				id: id,
				type: type,
				action: state
			}
			if (communityId) body.communityId = communityId
			const response = await PUT(endPoint, body, `Disable/Delete ${type}`)
			if (response) {
				successToast(response?.data?.isDisabled ? `${type} has been ${state}d.` : `${type} has been ${state}d`)
				handleItemUpdate()
			}
		} catch (error) {
			console.log(error)
			if (error?.response) {
				errorToast(error?.response?.data?.message)
			} else {
				errorToast(error?.message)
			}
		}
	}

	return (
		<>
			{name === "disable" &&
				<>
					{!isDisabled ?
						<Button onClick={() => handleAction(id, type, "disable")} sx={{ ...CommonStyles.actionButton }}>Disable</Button>
						:
						<Button onClick={() => handleAction(id, type, "enable")} sx={{ ...CommonStyles.actionButton }}>Enable</Button>}
				</>
			}
			{name === "delete" &&
				<>
					{!isDeleted ?
						<Button onClick={() => handleAction(id, type, "delete")} sx={{ ...CommonStyles.actionButton }}>Delete</Button>
						:
						<Button onClick={() => handleAction(id, type, "restore")} sx={{ ...CommonStyles.actionButton }}>Restore</Button>}
				</>
			}
		</>
	)
}

export default ActionButton