import React from 'react'
import { CircularProgress, Typography } from '@mui/material'

const ForgeLoader = () => {
    return (
        <div id="forgeLoader" >
            <div>
                <Typography sx={{ mb: 2, textAlign: 'center' }} ><CircularProgress size={40} sx={{ color: 'black' }} /></Typography>
                <Typography sx={{ color: 'black' }}>Loading please wait ......</Typography>
            </div>
        </div>
    )
}

export default ForgeLoader