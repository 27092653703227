import { Lock } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import ServiceUnsubscribedStyles from '../../styles/cctv/LockedService/ServiceUnsubscribedStyles'

const ServiceUnsubscribed = (props) => {
    return (
        <Box sx={{ ...ServiceUnsubscribedStyles?.boxContainer, height: props?.height }}>
            <Box>
                {!props.NA && <Typography align='center' ><Lock /></Typography>}
                <Typography align='center' >{props.NA ? `Service Unavailable` : `Service not subscribed!`}</Typography>
                {!props.NA && <Typography align='center' fontSize={'10px'} >Please subscribe the service for more data.</Typography>}
            </Box>
        </Box>
    )
}

export default ServiceUnsubscribed