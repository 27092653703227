import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import {KeyboardArrowLeft,KeyboardArrowRight} from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import AssetCarouselStyles from '../../styles/assetManagement/assetView/AssetCarouselStyles'
import { GET } from '../../helpers/http'
import { defaultImage } from '../../../../data/imageUrls'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function Carousel(props) {
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState(0)
    const { id } = useParams()
    const [assetDetails, setAssetDetails] = useState('')

    const fileReadUrl = useSelector((state) => state.configurations?.fileReadUrl)

    // const images = [
    // 	{
    // 		label: 'San Francisco – Oakland Bay Bridge, United States',
    // 		imgPath:
    // 			'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    // 	},
    // 	{
    // 		label: 'Bird',
    // 		imgPath:
    // 			'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    // 	},
    // 	{
    // 		label: 'Bali, Indonesia',
    // 		imgPath:
    // 			'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    // 	},
    // 	{
    // 		label: 'Goč, Serbia',
    // 		imgPath:
    // 			'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    // 	},
    // ];
    const maxSteps = assetDetails?.imageLinks?.length
    useEffect(() => {
        GET(`${process.env.REACT_APP_BASE_URL}am/v1/asset/${id || props.id}`, "Fetch single asset detail")
            .then((data) => {
                setAssetDetails(data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    return (
        <Box sx={AssetCarouselStyles.boxContainer}>
            <Box sx={AssetCarouselStyles.paperContainer}>
                {' '}
                {/*display='inline-block'*/}
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {assetDetails?.imageLinks?.length > 0 ? (
                        assetDetails?.imageLinks?.map((step, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        component="img"
                                        sx={AssetCarouselStyles.boxItem}
                                        src={`${fileReadUrl}${step}`}
                                    />
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <Box
                            component="img"
                            sx={AssetCarouselStyles.boxItemTwo}
                            src={defaultImage.src}
                            alt={defaultImage.alt}
                        />
                    )}
                </AutoPlaySwipeableViews>
                {/* <Box position='absolute' bottom='0.5rem' left='9vw' backgroundColor='none'>
					<MobileStepper
						steps={maxSteps}
						position="static"
						style={{ backgroundColor: 'none' }}
						sx={{
							"& *": {
								backgroundColor: "none",
							},
						}}
						activeStep={activeStep}
					// nextButton={
					//   <Button
					//     size="small"
					//     onClick={handleNext}
					//     disabled={activeStep === maxSteps - 1}
					//   >
					//     Next
					//     {theme.direction === 'rtl' ? (
					//       <KeyboardArrowLeft />
					//     ) : (
					//       <KeyboardArrowRight />
					//     )}
					//   </Button>
					// }
					// backButton={
					//   <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
					//     {theme.direction === 'rtl' ? (
					//       <KeyboardArrowRight />
					//     ) : (
					//       <KeyboardArrowLeft />
					//     )}
					//     Back
					//   </Button>
					// }
					/>
				</Box> */}
            </Box>
        </Box>
    )
}

export default Carousel
