import { Avatar, Box, Card, CardHeader, Grid, Skeleton, Typography } from "@mui/material"
import { ThemeColors } from "../../../../../hooks/ThemeColors"
import { useEffect, useState } from "react"
import { errorToast } from "../../../../../helpers/apiToast"
import { GET } from "../../../../../helpers/http"
import ImageRenderer from "../../../../../components/image/ImageRenderer"
import { profilePicture } from "../../../../../../../data/imageUrls"
import { useSelector } from "react-redux"
import commentsStyle from "../../../../../styles/wall/contentReviewBody/CommentStyle"

const Reactions = ({ id, communityId, postloading }) => {
    const [loading, setLoading] = useState({ reactions: true, list: true })
    const [page, setPage] = useState(1)
    const [hideLoadmore, setHideLoadMmore] = useState(false)
    const [reactionCounts, setReactionCounts] = useState([])
    const [responders, setResponders] = useState([])
    const [selectedReaction, setSelectedReaction] = useState("all")
    const communitiesFileReadUrl = useSelector(state => state?.configurations?.communitiesFileReadUrl)
    const { background_color, textColor } = ThemeColors()

    const getResponders = async ({ id, page, selectedReaction, communityId }) => {
        try {
            let endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/list/responders?communityId=${communityId}&relatedTo=${id}&reactionType=${selectedReaction}&page=${page || 1}`
            const response = await GET(endPoint, "Get Replies")
            if (reactionCounts.length === 0) {
                setReactionCounts(response?.data?.reactionCounts?.sort((a, b) => b?.count - a?.count))
            }
            if (response?.data?.responders?.length < 10) {
                setHideLoadMmore(true)
            }
            setResponders([...responders, ...response?.data?.responders])
            setLoading({ ...loading, list: false, reactions: false })
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const reactionButtonHandle = (type) => {
        if (selectedReaction !== type) {
            setLoading({ ...loading, list: true })
            setSelectedReaction(type)
            setResponders([])
            setPage(1)
            setHideLoadMmore(false)
        }
    }

    useEffect(() => {
        if (selectedReaction && communityId) {
            getResponders({ id, page, selectedReaction, communityId })
        }
    }, [selectedReaction, page, communityId])

    return (
        <>
            <Card className="boxShadowNone pad25"
                sx={{ backgroundColor: background_color, height: "65vh" }}>
                <Typography variant="h6">Reactions</Typography >

                {!postloading && !loading?.reactions ?
                    <Grid container spacing={2}
                        sx={{ width: "inherit", marginX: "0px", marginTop: "0.1rem", paddingBottom: "0.7rem" }}>
                        {reactionCounts?.map((reactionType, i) => {
                            return (
                                <Grid item xs={4} key={i}>
                                    <Box
                                        className="border txtcenter pad5 cursorPtr bdrRadius15px flex flexJustifyCenter gap8"
                                        sx={{
                                            backgroundColor: selectedReaction !== reactionType?.type && "lightgray",
                                            color: textColor
                                        }}
                                        onClick={() => reactionButtonHandle(reactionType?.type)}
                                    >
                                        {reactionType?.type === "all"
                                            ?
                                            <Box>All</Box>
                                            :
                                            <img
                                                src={reactionType?.imageLink}
                                                alt={reactionType?.type}
                                                className="reactionicon"
                                            />}
                                        {reactionType?.count}
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    :
                    <Grid container spacing={2} sx={{ width: "inherit", marginX: "0px", marginTop: "0.5rem" }}>
                        <Grid item xs={4} >
                            <Skeleton animation="wave" height={50} width={100} />
                        </Grid>
                        <Grid item xs={4} >
                            <Skeleton animation="wave" height={50} width={100} />
                        </Grid>
                        <Grid item xs={4} >
                            <Skeleton animation="wave" height={50} width={100} />
                        </Grid>
                        <Grid item xs={4} >
                            <Skeleton animation="wave" height={50} width={100} />
                        </Grid>
                    </Grid>
                }
                {!loading?.list
                    ?
                    responders.length !== 0
                        ?
                        <>
                            <Box className="overflowAuto maxHeight510px padright8px"
                                sx={{ ...commentsStyle.scrollBarStyle }}>
                                {responders?.map((responder) => {
                                    return (
                                        <Box
                                            key={responder?._id}
                                            className="flex flexJustifyBetween flexAlignItemsCenter padright20px"
                                            sx={{
                                                borderBottom: (responders[responders?.length - 1]._id === responder?._id) ? "0px" : "1px solid lightgray",
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <>
                                                        {
                                                            responder?.user.profilePictureUrl !== "" ?
                                                                <Avatar alt={`${responder?.user.fullName}'s img`} src={`${communitiesFileReadUrl}${responder?.user.profilePictureUrl}`} /> :
                                                                <Avatar>
                                                                    <ImageRenderer src={profilePicture.src} alt={profilePicture.alt} />
                                                                </Avatar>
                                                        }
                                                    </>
                                                }
                                                title={<Typography >{responder?.user?.fullName}</Typography>}
                                                subheader={
                                                    <Box
                                                        variant="p"
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: 'gray'
                                                        }}>
                                                        {responder?.user?.designation ? responder?.user?.designation : " "}
                                                    </Box>}
                                                style={{ overflow: 'hidden' }}
                                            />
                                            <img
                                                src={responder?.reactionType?.imageLink}
                                                alt={responder?.reactionType?.type}
                                                className="reactionicon"
                                            />
                                        </Box>
                                    )
                                })}
                                {!hideLoadmore &&
                                    <Box
                                        className="flex flexJustifyCenter cursorPtr font14 color-grey"
                                        onClick={() => setPage(page + 1)}>
                                        Load more
                                    </Box>}
                            </Box>
                        </>
                        :
                        <Box
                            className="height100p flex flexJustifyCenter flexAlignItemsCenter color-grey font14 padbtm120">
                            No reactions yet...
                        </Box>
                    :
                    <Box className="fxWidth100 delayed2s">
                        {Array.from({ length: 5 }).map((ele, i) => {
                            return (
                                <Box key={i} className="flex flexDirectionRow flexAlignItemsCenter padtop20">
                                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                    <Box className="flex flexDirectionCol">
                                        <Skeleton
                                            animation="wave"
                                            height={20}
                                            width={180}
                                            className="mrgnleft16 mrgnbtm8"
                                        />
                                        <Skeleton animation="wave" height={20} className="mrgnleft16" />
                                    </Box>
                                    <Skeleton animation="wave" height={40} width={30} className="mrgnleft50 bdrRadius15px" />
                                </Box>
                            )
                        })}
                    </Box>
                }
            </Card >
        </>
    )
}

export default Reactions