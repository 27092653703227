export const AppStyle = {
    box: {
        display: 'flex',
    },
    container: {
        height: '100%',
        minWidth: "13%",
        p: 1,
    },
    commonMarginInlineStart: {
        marginInlineStart: '-6px',
    },
    assetmanagementListButton: {
        pl: 0.5,
        mb: 2,
        height: '4vh',
        '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
        },
        '&:last-child': {
            mb: 0,
        },
    },
    visitorManagementListButton: {
        pl: 0.5,
        mb: 2,
        height: '4vh',
        '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
        },
        '&:last-child': {
            mb: 0,
        },
    },
    dashboardListButton: {
        p: 0,
        mb: 2,
        ml: 0.5,
        // borderRadius: 10,
        height: '4vh',
        '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
        },
        '&:last-child': {
            mb: 0,
        },
    },
}
