import * as React from 'react';
import { Backdrop, Box, Modal, Fade, IconButton, } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '90%',
    height: '80vh',
    display: 'flex',
    opacity: 1.8
};

export default function TransitionsModal(props) {
    const { url, handleClose } = props

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={true}>
                    <Box sx={style} >
                        <img src={url}
                            alt='area img'
                            // width='800'
                            style={{
                                objectFit: 'contain',
                                maxWidth: '85vw',
                                borderRadius: '15px'
                            }}
                        />
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                bgcolor: 'rgba(0,0,0,0.2)',
                                color: 'white',
                                ":hover": {
                                    bgcolor: 'rgba(0,0,0,0.4)',
                                },
                            }}
                        >
                            <CloseOutlined
                                fontSize='large'
                            />
                        </IconButton>
                    </Box>
                </Fade>

            </Modal>
        </div >
    );
}