import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../../facilityManager/helpers/http'

export const asyncFetchBookingsHistory = createAsyncThunk('deskBookingHistory/asyncFetchBookingsHistory', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}db/v1/bookings/history?building-id=${bid}`, 'Desk booking history')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchTotalBookedData = createAsyncThunk('deskTotalBookedData/asyncFetchTotalBookedData', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}db/v1/bookings/data?building-id=${bid}`, 'Desk TotalBooked Data')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const initialState = {
    bookingHistory: {
        isLoaded: false,
        data: [],
        margins: [10, 0, 25, 30],
        min: new Date().getTime() - (16 * 24 * 60 * 60 * 1000),
        max: new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
    },
    totalBookedDesks: {
        isLoaded: false,
        data: {},
        margins: [10, 0, 25, 30]
    }
}

const deskBookingSlice = createSlice({
    name: 'deskBookingSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(asyncFetchBookingsHistory.fulfilled, (state, action) => {
            state.bookingHistory.isLoaded = true
            state.bookingHistory.data = action.payload
            // state.bookingHistory.min = action.payload[0][0]
            // state.bookingHistory.max = action.payload[action.payload.length - 1][0]
        })
        builder.addCase(asyncFetchTotalBookedData.fulfilled, (state, action) => {
            state.totalBookedDesks.isLoaded = true
            state.totalBookedDesks.data = action.payload
        })
    }

})

export default deskBookingSlice.reducer