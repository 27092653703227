import { Box, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import Button from '../../../../components/formInputs/buttons/Button'
import FileInput from '../../../../components/formInputs/FileInput';
import swal from 'sweetalert';
import { FileUpload } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import './bulkUpload.css'
import { FILEPOST } from '../../../../helpers/http';
import AddAssetExcelStyles from '../../../../../facilityManager/styles/assetManagement/addExcel/AddAssetExcelStyles';
import { useRouter } from '../../../../../../routes/hooks/useRouter';
import BulkUploadFailed from '../modals/uploadFailed/BulkUploadFailed';
import { errorToast, successToast } from '../../../../helpers/apiToast';
import BulkUploadFailedModal from '../modals/uploadFailed/AssetUploadFailedModal';

const BulkUpload = (props) => {
    const router = useRouter()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = !themeMode ? '#282a2e' : '#fff'

    const [modalOpen, setModalOpen] = useState(false);
    const [modalBulkOpen, setModalBulkOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [errorContent, setErrorContent] = useState('');
    const [notUploadableUserData, setNotUploadableUserData] = useState([]);

    const FileInputHandler = (file) => {
        swal({
            title: "This might take some time",
            text: "Please make sure you have added with correct format mentioned in the template",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((ok) => {
                if (ok) {
                    const fileData = file.target.files[0];
                    const formData = new FormData();
                    formData.append("file", fileData);
                    FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/users/onboard`, formData, 'User Management Excel Upload', true)
                        .then((data) => {
                            if (data?.status === 200 && data?.data?.invalidUsers?.length > 0) {
                                setModalBulkOpen(true);
                                setModalTitle(`${data?.data?.validUsersCount} user(s) onboarded and ${data?.data?.invalidUsers?.length} user(s) failed to onboard`);
                                setNotUploadableUserData(data?.data?.invalidUsers?.map(el => { return { ...el.employee } }));
                            }
                            else if (data?.status === 200 && data?.data?.invalidUsers?.length === 0) {
                                successToast('User Management Excel Upload Successful');
                                router.push('/admin/usermanagement');
                            }
                            else {
                                errorToast('Something went wrong!!');
                            }
                        }).catch(e => {
                            if (e?.status === 400) {
                                setModalOpen(true);
                                setModalTitle('Duplicate User(s) Found (upload failed)');
                                setErrorContent(e?.response?.data?.error)
                            }
                        })
                }
            });
    }

    const url = `${process.env.REACT_APP_DOWNLOAD_BULK_USER_TEMPLATE_URL}`;

    return (
        <Paper className='theme-component-background theme-border theme-cards-boxShadow theme-color bdrRadius4px'
            sx={{
                margin: '20px',
                width: '100%',
                minHeight: '85.5vh',
            }}
        >
            <Grid sx={AddAssetExcelStyles.gridContainer}>
                <Box sx={AddAssetExcelStyles.boxContainer}>
                    <Box sx={AddAssetExcelStyles.fontWeight700}>Upload An Excel</Box>
                    <Box sx={{ m: 1 }}>Make sure to upload the excel in the same template format that's downloaded from below </Box>
                    <Box sx={{ m: 2 }} className={themeMode ? 'button-container-dark' : 'button-container-light'}>
                        <a style={{ 'text-decoration': 'none' }} href={url}>
                            <Button name="Download Template" variant={"contained"} bgcolor={bgcolor} />
                        </a>
                    </Box>
                </Box>
                <Box sx={AddAssetExcelStyles.boxContainerTwo} className='theme-component-background theme-color'>
                    <Box><FileUpload fontSize='large' /></Box>
                    <Box sx={{ m: 1 }}>Use the below button to select the file</Box>
                    <Box sx={AddAssetExcelStyles.boxItem}>
                        <FileInput
                            accept={".xlsx"}
                            buttonText="SELECT FILE"
                            pickHandler={((file) => FileInputHandler(file))}
                            sx={AddAssetExcelStyles.fileInput}
                            name="Select File"
                        />
                    </Box>
                </Box>
            </Grid>

            {modalOpen && <BulkUploadFailed
                errorContent={errorContent}
                title={modalTitle}
                modalOpen={modalOpen}
                handleBulkUploadModalClose={() => setModalOpen(false)}
                handleBulkUploadModalOpen={() => setModalBulkOpen(true)}
            />}

            {modalBulkOpen && <BulkUploadFailedModal
                openModal={modalBulkOpen}
                handleCloseModal={() => setModalBulkOpen(false)}
                notUploadableUserData={notUploadableUserData}
            />}

        </Paper>

    )
}

export default BulkUpload