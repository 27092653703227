import React, { useState } from 'react'
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import TabContainerStyles from '../../../../styles/TabStyles';

const GridTabContainer = (props) => {
    const { tabList, tabPanels, children } = props
    const theme = useTheme()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const paperStyle = { bgcolor, mx: 2.5, display: "flex", flexDirection: "row", justifyContent: "space-between",paddingRight: 1, }
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const colorFunc = (index) => {
        if (themeMode) {
            if (value === index) {
                return theme.palette.common.white
            }
            else {
                return theme.palette.grey['500']
            }
        }
        else {
            if (value === index) {
                return theme.palette.common.black
            }
            else {
                return theme.palette.grey['500']
            }
        }
    }

    return (
        <Box sx={TabContainerStyles.boxContainer} typography={'body1'}>
            <Box sx={paperStyle } className='theme-border theme-cards-boxShadow bdrRadius4px'>
                <Tabs onChange={handleChange} sx={{ ml: 2 }} textColor="inherit" value={value}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#D97D54',
                            marginBottom: 1,
                        },
                    }}>
                    {
                        tabList.map((title, index) => {
                            return (<Tab key={index} sx={{ ...TabContainerStyles.tabList, color: colorFunc(index), fontWeight: value === index ? 'bold' : '' }} label={title} value={index} />
                            )
                        })
                    }
                </Tabs>
                {children}
            </Box>
            {
                Array.isArray(tabPanels) ?
                    <>
                        {value === 0 && (
                            <Box>
                                {tabPanels[0]}
                            </Box>
                        )}
                        {value === 1 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[1]}
                            </Box>
                        )}
                        {value === 2 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[2]}
                            </Box>
                        )}
                        {value === 3 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[3]}
                            </Box>
                        )}
                        {value === 4 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[4]}
                            </Box>
                        )}
                        {value === 5 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[5]}
                            </Box>
                        )}
                    </>
                    : tabPanels()
            }
        </Box >
    )
}

export default GridTabContainer