import { isNumeric, isIP, isPort } from 'validator'

export const runDVRValidation = (formData, DVRVerified) => {
    const errors = {}

    if (formData.ipAddress === '') {
        errors.ipAddress = "IP address should not be empty"
    } else if (!isIP(formData.ipAddress)) {
        errors.ipAddress = "Invalid IP address"
    }

    if (formData.username === '') {
        errors.username = "Username should not be empty"
    }

    if (formData.password === '') {
        errors.password = "Password should not be empty"
    } else if (formData.password === undefined) {
        errors.password = "Password should not be Empty"
    }

    if (formData.port === '') {
        errors.port = "Port should not be Empty"
    } else if (!isNumeric(formData.port)) {
        errors.port = "Port should be Number"
    } else if (!isPort(formData.port)) {
        errors.port = "Invalid Port Number"
    }

    if (!isIP(formData.gatewayIpAddress) && formData.gatewayIpAddress !== '') {
        errors.gatewayIpAddress = "Invalid Gateway IP Address"
    }

    if (formData.subTenantReference === '') {
        errors.subTenantReference = "Subtenant should not be Empty"
    }

    if (DVRVerified) {
        if (formData.buildingReference === '') {
            errors.buildingReference = "Building should not be Empty"
        }

        if (formData.locationReference === '') {
            errors.locationReference = "Location should not be Empty"
        }

        if (formData.name === '') {
            errors.name = "Name should not be Empty"
        }
    }

    return errors
}
