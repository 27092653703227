import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import Plyr from "plyr-react"
import "plyr-react/plyr.css"

const ReturnMultiMedia = ({ media }) => {
    const wallFileReadUrl = useSelector(state => state.configurations?.wallFileReadUrl)
    const plyrOptions = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
        hideControls: false,
        ratio: "16:9",
        settings: ['captions', 'quality', 'speed', 'loop'],
        quality: { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240] }
    }

    if (media?.type?.includes('video')) {
        return (
            <Box
                sx={{
                    height: "34vh",
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: "#bdbdbd",
                    borderRadius: '1rem'
                }}
            >
                <Plyr
                    source={{ type: "video", sources: [{ src: `${wallFileReadUrl}${media?.key}` }] }}
                    options={plyrOptions}
                />
            </Box>
        )
    } else if (media?.type?.includes("image")) {
        return (
            <Box sx={{
                height: "34vh", overflow: "hidden", display: 'flex',
                justifyContent: 'center', borderRadius: '1rem',
                backgroundColor: "#000", width: "100%"
            }}>
                <img
                    src={`${wallFileReadUrl}${media?.key}`}
                    alt={media?.key}
                    loading="lazy"
                    style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: '1rem',
                    }}
                />
            </Box>
        )
    } else
    //  if (media?.type?.includes("pdf"))
    {
        return (
            <Box sx={{
                width: '100%',
                height: "34vh",
                overflow: "hidden",
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#000"
            }}>
                <iframe
                    title={media?.key}
                    src={`${wallFileReadUrl}${media?.key}`}
                    allowFullScreen
                    loading="lazy"
                    frameBorder="0"
                    style={{
                        // height: "600",
                        width: "65%",
                        borderRadius: '1rem'
                    }}
                ></iframe>
            </Box>
        )
    }


}

export default ReturnMultiMedia