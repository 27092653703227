import Dashboard from "../components/facilityManager/layouts/dashboardContainer/Dashboard"
import AdminDashboard from '../components/admin/layout/dashboard/AdminDashboard'
import PrivateRoute from "../components/facilityManager/authentication/PrivateRoute"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const Router = (props) => {
    const location = useLocation()
    const user = useSelector(state => state?.user)  /** remove condition when admin console is moved */
    return <PrivateRoute
        path="/*"
        component={(location.pathname?.includes('admin') && user?.role === 'admin') ? AdminDashboard : Dashboard}
    />
}

export default Router
