export const SensorsValueColor = (sensors, feature) => {
	if (Array.isArray(sensors)) {
		if (feature === "Temperature") {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 21) {
					const value = 0.25
					return { ...ele, heatmapValue: value }
				}
				else if (Number(ele.value) > 21 && Number(ele.value) <= 29) {
					const value = ele.value / 38.4;
					return { ...ele, heatmapValue: value }
				}

				else if (Number(ele.value) > 29) {
					const value = ele.value / 34.8;
					return { ...ele, heatmapValue: value }
				}

				else if (Number(ele.value) > 34) {
					return { ...ele, heatmapValue: 1 };
				}
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'CO2') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 800) {
					return { ...ele, heatmapValue: 0.6}
				}
				else if (Number(ele.value) > 800 && Number(ele.value) <= 1200) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 1200) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'Humidity') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 40) {
					return { ...ele, heatmapValue: 0.6 }
				}
				else if (Number(ele.value) > 40 && Number(ele.value) <= 60) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 60) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'Air Pressure') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) < 1013.3) {
					return { ...ele, heatmapValue: 0.6 }
				}
				else if (Number(ele.value) === 1013.3) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 1013.3) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'Illuminance') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 300) {
					return { ...ele, heatmapValue: 0.6 }
				}
				else if (Number(ele.value) > 300 && Number(ele.value) <= 500) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 500) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'TVOC') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 112) {
					return { ...ele, heatmapValue: 0.6 }
				}
				else if (Number(ele.value) > 112 && Number(ele.value) <= 336) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 336) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}

		else if (feature === 'Radon') {
			const updatedSensors = sensors.map(ele => {
				if (Number(ele.value) <= 100) {
					return { ...ele, heatmapValue: 0.6 }
				}
				else if (Number(ele.value) > 100 && Number(ele.value) <= 150) {
					return { ...ele, heatmapValue: 0.7 }
				}
				else if (ele.value > 150) return { ...ele, heatmapValue: 0.86 }
				else if (isNaN(Number(ele.value))) {
					return { ...ele, heatmapValue: 0.25 };
				}
			})
			return updatedSensors
		}
	}

	else {
		if (feature === "Temperature") {
			let updatedSensor
			if (Number(sensors.value) <= 21 || isNaN(Number(sensors.value))) {
				const value = 0.25
				updatedSensor = { ...sensors, heatmapValue: value,value:sensors.value }
			}
			else if (Number(sensors.value) > 21 && Number(sensors.value) <= 29) {
				const value = sensors.value / 38.4;
				updatedSensor = { ...sensors, heatmapValue: value,value:sensors.value  }
			}

			else if (Number(sensors.value) > 29) {
				const value = sensors.value / 34.8;
				updatedSensor = { ...sensors, heatmapValue: value,value:sensors.value  }
			}

			else if (Number(sensors.value) > 34) {
				updatedSensor = { ...sensors, heatmapValue: 1 ,value:sensors.value };
			}
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25 };
			}
			return updatedSensor
		}
		else if (feature === 'CO2') {
			let updatedSensor
			if (Number(sensors.value) <= 800) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) > 800 && Number(sensors.value) <= 1200) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 1200) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25 };
			}
			return updatedSensor
		}

		else if (feature === 'Humidity') {
			let updatedSensor
			if (Number(sensors.value) <= 40) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) > 40 && Number(sensors.value) <= 60) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 60) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25 };
			}
			return updatedSensor
		}

		else if (feature === 'Air Pressure') {
			let updatedSensor
			if (Number(sensors.value) < 1013.3) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) === 1013.3) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 1013.3) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25  };
			}
			return updatedSensor
		}

		else if (feature === 'Illuminance') {
			let updatedSensor
			if (Number(sensors.value) <= 300) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) > 300 && Number(sensors.value) <= 500) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 500) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25  };
			}
			return updatedSensor
		}

		else if (feature === 'TVOC') {
			let updatedSensor
			if (Number(sensors.value) <= 112) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) > 112 && Number(sensors.value) <= 336) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 336) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25 };
			}
			return updatedSensor
		}

		else if (feature === 'Radon') {
			let updatedSensor
			if (Number(sensors.value) <= 100) {
				updatedSensor = { ...sensors, heatmapValue: 0.6,value:sensors.value  }
			}
			else if (Number(sensors.value) > 100 && Number(sensors.value) <= 150) {
				updatedSensor = { ...sensors, heatmapValue: 0.7,value:sensors.value  }
			}
			else if (sensors.value > 150) updatedSensor = { ...sensors, heatmapValue: 0.86 }
			else if (isNaN(Number(sensors.value))) {
				updatedSensor = { ...sensors, heatmapValue: 0.25};
			}
			return updatedSensor
		}
	}
}