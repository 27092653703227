export const headerCard = {
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    headerGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buildingGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        pr: 2
    },
    avatarIcon: {
        height: 32, width: 32
    },
    logoutCard: {
        display: "row",
        minWidth: '230px',
        minHeight: '120px',
        backgroundColor: 'black',
        color: 'white',
        p: 2,
        borderRadius: '6px',
    },
    signoutButton: {
        backgroundColor: '#f00',
        color: 'black',
        '&:hover': {
            background: '#f00',
            color: 'white',
        },
    },
    cancelButton: {
        backgroundColor: 'grey',
        color: 'black',
        '&:hover': {
            background: 'darkgray',
            color: 'white',
        },
    },
    resetPasswordModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
    },
    changePasswordButton: {
        bgcolor: '#dc4d28',
        "&:hover": { bgcolor: '#dc4c00' },
        textTransform: 'none',
        float: 'right'
    }
}
