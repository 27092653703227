import { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Button, Stack } from "@mui/material"
import CommonStyles from "../../../../styles/CommonStyles";


const ImageCropper = (props) => {
    const { img, open, uploadCroppedImg, setImg, close } = props
    const imgRef = useRef(null);
    const [image, setImage] = useState(null);
    const [cropperOpen, setCropperOpen] = useState(open)
    const [crop, setCrop] = useState()


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // height: '80vh',
        boxShadow: 24,
        borderRadius: '10px',
        p: 4,
    };


    const handleClose = () => {
        setCropperOpen(false)
        close()
    }

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        )
        setCrop(crop)
    }


    // function onImageLoad(e) {
    //     const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    //     const minWidth = 300; // Minimum crop dimensions
    //     const minHeight = 200;

    //     const cropWidth = Math.max(width, minWidth);
    //     const cropHeight = Math.max(height, minHeight);

    //     const crop = centerCrop(
    //         makeAspectCrop(
    //             {
    //                 unit: '%',
    //                 width: 90,
    //             },
    //             16 / 9,
    //             cropWidth,
    //             cropHeight
    //         ),
    //         cropWidth,
    //         cropHeight
    //     );

    //     setCrop(crop);
    // }



    const cropImageNow = (image, crop) => {
        const canvas = document.createElement('canvas');

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        canvas.toBlob((file) => {
            uploadCroppedImg(file);
        }, "image/jpeg", 0.8);
        close()
    };

    return (
        <Modal
            open={cropperOpen}
            onClose={handleClose}
        >
            <Box sx={style}>
                {img &&
                    <Stack gap={2} >
                        < ReactCrop
                            crop={crop}
                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                            aspect={16 / 9}
                            style={{ maxWidth: '80vw', maxHeight: '60vh' }}
                            keepSelection
                            onImageLoaded={setImg || setImage}>
                            <img
                                alt="crop img"
                                ref={imgRef}
                                src={img}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                onLoad={onImageLoad}
                            />
                            {/* <img
                                alt="crop img"
                                ref={imgRef}
                                src={img}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain" // Ensures the image fits without stretching 
                                }}
                                onLoad={onImageLoad}
                            /> */}

                        </ReactCrop >
                        <Button
                            variant="contained"
                            sx={CommonStyles.actionButton}
                            onClick={() => {
                                cropImageNow(
                                    imgRef.current, // HTMLImageElement
                                    convertToPixelCrop(
                                        crop,
                                        imgRef.current.width,
                                        imgRef.current.height
                                    )
                                );
                            }}
                        >Crop</Button>
                    </Stack>
                }
            </Box>
        </Modal >
    )
}

export default ImageCropper