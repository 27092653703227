import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './kioskSessionsContainer.css'
import CommonStyles from '../../../styles/CommonStyles'
import KioskSessionsContainerStyles from '../../../styles/KioskSessionContainerStyles'
import Loading from '../../../components/loading/Loading'
import { GET } from '../../../helpers/http'
import Datatable from '../../../components/datatable/Datatable'

const KioskSessionsContainer = (props) => {
    const { id, kioskCodeUpdate } = props
    const [sessions, setSessions] = useState([])
    const [areSessionsLoaded, setAreSessionsLoaded] = useState(false)
    // const [logsModal, setLogsModal] = useState(false)
    // const [selectedSession, setselectedSession] = useState({})

    useEffect(() => {
        if (id) {
            GET(`${process.env.REACT_APP_BASE_URL}vm/v1/dashboard/kiosk/sessions/${id}`, 'Fetch Kiosk sessions VM')
                .then((response) => {
                    const result = response?.data
                    if (response.status === 200) {
                        setAreSessionsLoaded(true)
                        setSessions(result?.relatedSessions)
                        kioskCodeUpdate(result?.kioskCode)
                    } else {
                        setAreSessionsLoaded(true)
                    }
                })
                .catch(error => console.log(error))
        }
    }, [id, kioskCodeUpdate])

    // const openLogsModal = (session) => {
    //     setLogsModal(true)
    //     setselectedSession(session)
    // }

    // const closeLogsModal = () => {
    //     setLogsModal(false)
    //     setselectedSession({})
    // }

    const columns = [
        {
            field: 'deviceId',
            headerName: 'Device Id',
            width: 250,
            valueGetter: (params) =>
                `${params.row?.deviceData?.deviceId || ''}`,
        },
        {
            field: 'deviceOS',
            headerName: 'Device OS',
            width: 150,
            valueGetter: (params) =>
                `${params.row?.deviceData?.baseOS || ''}`,
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            width: 250,
            valueGetter: (params) =>
                `${params.row?.deviceData?.deviceName || ''}`,
        },
        {
            field: 'connectedOn',
            headerName: 'Connected on',
            width: 250,
            valueGetter: (params) =>
                `${params.row?.createdAt ? moment(params.row?.createdAt).format('lll') : ''}`,
        },
        {
            field: 'connectionStatus',
            headerName: 'Connection Status',
            width: 150,
            renderCell: (cellValues) => {
                return (<>
                    {cellValues?.row?.status ? (
                        <Typography
                            className={cellValues?.row?.status === 'active' ? 'active' :
                                cellValues?.row?.status === 'idle' ? 'idle' : 'inactive'}
                        >{cellValues?.row?.status?.toUpperCase()}</Typography>
                    ) : ("")}
                </>)
            },
        },
        {
            field: 'lastPingTime',
            headerName: 'Last Ping Time',
            width: 250,
            valueGetter: (params) =>
                `${params.row?.lastLog?.createdAt ? moment(params.row?.lastLog?.createdAt).format('lll') : ''}`,

        },
        {
            field: 'chargingStatus',
            headerName: 'Last Charging Status',
            width: 200,
            valueGetter: (params) =>
                `${params.row?.lastLog?.deviceData?.batteryState ? params.row?.lastLog?.deviceData?.batteryState?.toUpperCase() : ''}`,

        },
        // {
        //     field: 'lastBattery',
        //     headerName: 'Last Battery %',
        //     width: 130,
        //     valueGetter: (params) =>
        //         `${params.row?.lastLog?.deviceData?.batteryPercent ? params.row?.lastLog?.deviceData?.batteryPercent?.toFixed(2) : ''}`,

        // },

        // {
        //     field: 'Actions',
        //     width: 100,
        //     renderCell: (cellValues) => {
        //         return (
        //             <IconButton
        //                 size="small"
        //                 onClick={() => openLogsModal(cellValues?.row)}
        //             >
        //                 <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28' }} />
        //                 <Typography color={'#DC4D28'} variant={'caption'}>
        //                     &nbsp;LOGS
        //                 </Typography>
        //             </IconButton>
        //         )
        //     },
        // },
    ]
    return (
        <Box
            sx={KioskSessionsContainerStyles?.boxContainer}
        >
            <Grid
                container
                spacing={2}
                sx={{ height: "100%" }}
            >
                <Grid
                    item
                    xs={12}
                >
                    {areSessionsLoaded ? (
                        <Datatable
                            rows={sessions}
                            columns={columns}
                            pageSize={15}
                            rowsPerPage={15}
                            checkBox={false}
                        />
                    ) : (
                        <Box
                            sx={{ height: "100%", ...CommonStyles?.centerDivHeight100 }}
                        >
                            <Loading />
                        </Box>
                    )}
                </Grid>
            </Grid>
            {/* {logsModal && <ModalComp
                modalOpen={logsModal}
                children={
                    <SessionLogs
                        kioskConfigId={id}
                        selectedSession={selectedSession}
                    />
                }
                modalWidth={'50%'}
                closeModal={closeLogsModal}
            />
            } */}
        </Box>
    )
}

export default KioskSessionsContainer