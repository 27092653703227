import moment from "moment";

export function formatMinutesToHours(minutes) {
    const duration = moment.duration(minutes, 'minutes');
    const hours = duration.hours();
    const remainingMinutes = duration.minutes();

    if (hours > 0 && remainingMinutes > 0) {
        const formattedTime = moment.duration(remainingMinutes, 'minutes').humanize();
        return `${hours} hours and ${formattedTime}`;
    } else if (hours > 0) {
        return `${hours} hours`;
    } else {
        return `${remainingMinutes} minutes`;
    }
}