import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchOccupancySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchAllFloorsOccupancy: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/occupancy/s1/data?buildingId=${buildingId}&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Occupancy Dashboard')
            },
            providesTags: ['occupancy']
        }),
        fetchSecondaryAllFloorsTotalOccupancyData: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/occupancy/s2/stats?buildingId=${buildingId}&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Occupancy Secondary All Floors Total Occupancy')
            },
            providesTags: ['occupancy']
        }),
        fetchSecondaryOccupancyDurations: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/occupancy/s2/options?buildingId=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Occupancy Secondary Options')
            },
            providesTags: ['occupancy']
        }),
        fetchSecondaryFloorwiseOccupancyChartData: builder.query({
            query: ({ buildingId, duration, startDate, endDate }) => ({
                url: `twin/v1/occupancy/s2/data?buildingId=${buildingId}&duration=${duration}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Occupancy Secondary Chart Data')
            },
            providesTags: ['occupancy']
        }),
    })
})

export const {
    useFetchAllFloorsOccupancyQuery,
    useFetchSecondaryAllFloorsTotalOccupancyDataQuery,
    useFetchSecondaryOccupancyDurationsQuery,
    useFetchSecondaryFloorwiseOccupancyChartDataQuery
} = fetchOccupancySlice

const initialState = {
    isLoaded: false,
    error: "",
    data: [],
    totalOccupancy: "NA",
    margins: [10, 0, 25, 30],
    lineWidth: 2,

    secondary: {
        isLoaded: false,
        durations: [],
        selectedDuration: 'today',
        allFloorsOccupancyData: {},
        allFloorsOccupancyDataIsLoaded: false,
        floorwiseOccupancyChartDataIsLoaded: false,
        floorwiseOccupancyChartData: [],
        error: "",
        margins: [15, 10, 25, 35],
        lineWidth: 2
    }
}

const occupancySlice = createSlice({
    name: 'occupancy',
    initialState,
    reducers: {
        resetDashboardTotalOccupancy: (state, action) => {
            state.isLoaded = initialState.isLoaded
            state.data = initialState.data
        },
        setSelectedDuration(state, action) {
            if (state.secondary.selectedDuration !== action.payload) {
                state.secondary.floorwiseOccupancyChartDataIsLoaded = false
                state.secondary.selectedDuration = action.payload
                return state
            }
        },
        resetSecondaryData(state, action) {
            state.secondary = initialState.secondary
        },
        socketDashboardOccupancyData(state, action) {
            const newChartData = [action.payload?.updatedTime, action.payload?.count]
            const socketFloorData = state.data?.find(ele => ele.name === action.payload?.floor)
            socketFloorData.data.push(newChartData)
            socketFloorData.count = action.payload?.count

            let totalOccupancy = 0
            state.data?.forEach(ele => {
                (!isNaN(ele?.count)) ? totalOccupancy += ele?.count : totalOccupancy += 0
            })

            state.totalOccupancy = totalOccupancy
        },
        socketSecondaryOccupancyData(state, action) {
            const newChartData = [action.payload?.updatedTime, action.payload?.count]
            const individualFloorData = state?.secondary?.floorwiseOccupancyChartData?.find(ele => ele?.floor === action.payload?.floor)
            individualFloorData?.data.push(newChartData)
            if (!isNaN(action.payload?.count)) individualFloorData.recentRecord.count = action.payload?.count
            if (!isNaN(action.payload?.entry)) individualFloorData.recentRecord.entry = action.payload?.entry
            if (!isNaN(action.payload?.exit)) individualFloorData.recentRecord.exit = action.payload?.exit
            if (!isNaN(action.payload?.updatedTime)) individualFloorData.recentRecord.updatedTime = action.payload?.updatedTime

            let entryToday = 0, exitToday = 0, totalCount = 0

            state?.secondary?.floorwiseOccupancyChartData?.forEach(ele => {
                (!isNaN(ele?.recentRecord?.count)) ? totalCount += ele?.recentRecord?.count : totalCount += 0;
                (!isNaN(ele?.recentRecord?.entry)) ? entryToday += ele?.recentRecord?.entry : entryToday += 0;
                (!isNaN(ele?.recentRecord?.exit)) ? exitToday += ele?.recentRecord?.exit : exitToday += 0;
            })

            state.secondary.allFloorsOccupancyData.totalCount = totalCount
            state.secondary.allFloorsOccupancyData.entryToday = entryToday
            state.secondary.allFloorsOccupancyData.exitToday = exitToday
            state.secondary.allFloorsOccupancyDataIsLoaded = true
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllFloorsOccupancy' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const totalOccupancy = action.payload?.reduce((a, b) => (!isNaN(b?.recentRecord?.count)) ? Number(a) + Number(b?.recentRecord?.count) : Number(a) + 0, 0)
                    const data = action.payload?.map(ele => ({
                        name: ele?.floor,
                        color: ele?.floor === 'Ground Floor' ? '#A0E400' :
                            ele?.floor === 'First Floor' ? '#8EB3FC' :
                                ele?.floor === 'Second Floor' ? '#FF4E5E' :
                                    ele?.floor === 'Terrace Floor' ? '#02B9A4' : '',
                        data: ele?.data,
                        count: ele?.recentRecord?.count
                    }))

                    state.isLoaded = true
                    state.data = data
                    state.totalOccupancy = totalOccupancy
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryAllFloorsTotalOccupancyData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.isLoaded = true
                    state.secondary.allFloorsOccupancyData = action.payload
                    state.secondary.allFloorsOccupancyDataIsLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryOccupancyDurations' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.isLoaded = true
                    state.secondary.durations = action.payload?.durations
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryFloorwiseOccupancyChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const data = action.payload?.map(ele => (
                        {
                            name: `${ele?.floorName} Occupancy`,
                            data: ele?.data,
                            recentRecord: ele?.recentRecord,
                            floor: ele?.floorName
                        }
                    ))

                    const newData = structuredClone(data)

                    newData?.sort((a, b) => {
                        if (a.floor.toLowerCase().startsWith('ground') && (b.floor.toLowerCase().startsWith('first') || b.floor.toLowerCase().startsWith('second') || b.floor.toLowerCase().startsWith('terrace'))) {
                            return -1
                        }
                        if (a.floor.toLowerCase().startsWith('first') && (b.floor.toLowerCase().startsWith('second') || b.floor.toLowerCase().startsWith('terrace'))) {
                            return -1
                        }
                        if (a.floor.toLowerCase().startsWith('second') && b.floor.toLowerCase().startsWith('terrace')) {
                            return -1
                        }
                    })

                    state.secondary.isLoaded = true
                    state.secondary.floorwiseOccupancyChartDataIsLoaded = true
                    state.secondary.floorwiseOccupancyChartData = newData
                }
            )
    }
})

export const { resetDashboardTotalOccupancy, setSelectedDuration, resetSecondaryData, socketDashboardOccupancyData, socketSecondaryOccupancyData } = occupancySlice.actions

export default occupancySlice.reducer