import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTheme } from '@mui/material'

const HighChartsAssetManagementCategories = (props) => {
    const { data, height, title } = props
    const theme = useTheme()

    const options = {
        chart: {
            height: height || '70%',
            backgroundColor: null,
            type: 'pie',
            responsive: {
                rules: [{
                    condition: {
                        maxHeight: height,
                        // maxWidth: props.width,
                    },
                    // ... set options for smaller screen sizes if needed
                }],
            },
        },
        title: {
            text: title || ''
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            pointFormat: ''
        },
        accessibility: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: false
            },
            pie: {
                // colors: [
                // 	'#50B432',
                // 	'#0000FF',
                // 	'#DDDF00',
                // 	'#24CBE5',
                // 	'#64E572',
                // 	'#FF9655',
                // 	'#FFF263',
                // 	'#6AF9C4'
                // ],
                // colors: [
                //     '#90EC7D',
                //     '#8085E9',
                //     '#F7A35C',
                //     '#F15C80',
                //     '#7CB5EC',
                //     '#FFF263',
                //     '#6AF9C4'
                // ],
                colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
                    return {
                        radialGradient: {
                            cx: 0.5,
                            cy: 0.3,
                            r: 0.7
                        },
                        stops: [
                            [0, color],
                            [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
                        ]
                    };
                }),
                allowPointSelect: false,
                size: '100%',
                cursor: 'pointer',
                borderWidth: 0,
                slicedOffset: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.name}',
                    style: {
                        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                        fontSize: '9px',
                    }
                },
                showInLegend: true
            }
        },

        series: [{
            name: "Total",
            colorByPoint: true,
            data: data
        }],

        legend: {
            enabled: false,
            // layout: 'horizontal',
            // align: 'center',
            verticalAlign: 'bottom',
            itemStyle: {
                fontWeight: 500,
                fontSize: '12px',
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
            }
        },
        credits: {
            enabled: false
        },
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default HighChartsAssetManagementCategories