import { FormHelperText } from '@mui/material';
import React, { memo } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ThemeColors } from '../../../../../hooks/ThemeColors';

const CountryPhoneInput = ({ phone, handlePhoneInputs, formErrors, name, inputStyle }) => {
    const { background_color, textColor } = ThemeColors()
    return (
        <>
            <PhoneInput
                id={name}
                country={'in'}
                value={phone}
                onChange={handlePhoneInputs}
                inputStyle={{ width: '100%', backgroundColor: background_color(), color: textColor(), ...inputStyle }}
                buttonStyle={{ backgroundColor: 'lightgrey' }}
                dropdownStyle={{ maxWidth: '300px', backgroundColor: background_color() }}
                autoFormat={true}
                countryCodeEditable={false}
            />
            {formErrors && <FormHelperText style={{ color: '#d32f2f' }}>{formErrors}</FormHelperText>}
        </>
    )
}

export default memo(CountryPhoneInput)