import { Box, Typography } from "@mui/material"
import CommonStyles from "../../../styles/CommonStyles"
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles"
import { useParams } from "react-router-dom"
import { useCallback, useState } from "react"
import KioskSessionsContainer from "../../../features/visitorKiosk/KioskSessionsContainer/KioskSessionsContainer"

const VisitorKioskSessions = () => {
    const params = useParams()
    const { id } = params

    const [kioskCode, setKioskCode] = useState("")

    const kioskCodeUpdate = useCallback((kioskCode) => {
        setKioskCode(kioskCode)
    }, [])

    return (
        <Box
            sx={CommonStyles?.OuterBox}>
            <Typography
                sx={DepartmentSettingsStyles.headingTypo}
            >
                Visitor Kiosk Sessions{kioskCode && ` - ${kioskCode}`}
            </Typography>
            <KioskSessionsContainer id={id} kioskCodeUpdate={kioskCodeUpdate} />
        </Box>
    )
}

export default VisitorKioskSessions