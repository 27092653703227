import { Box, Grid } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AppStyle } from "../../styles/AppStyle"
import Footer from "../../layout/footer/Footer"
import PrivateRoute from "../../authentication/PrivateRoute"
import Breadcrumb from "../../components/breadcrumb/Breadcrumb"
import AdminNavigation from "../../layout/sideBar/AdminNavigation"
import { useLocation } from 'react-router-dom'
import { breadcrumbItems, breadcrumbValues } from "./data/breadCrumbs/breadCrumbs"
import { pathnameToCheck } from "./data/checkPathname/CheckPathname"
import { cctvRoutes, routes } from "./data/adminRoutes/adminRoutes"
import { DVRProvider } from "../../features/cctv/DVRContext"
import { useEffect } from "react"
import { fetchAttendanceConfigurations, fetchCommunitiesConfigurations, fetchMrConfigurations, fetchTwinConfigurations, fetchVmConfigurations, fetchWallConfigurations } from "../../../../redux/features/configurationsSlice"

const AppContainer = () => {
    const location = useLocation()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const dispatch = useDispatch()

    const pathname = location?.pathname

    useEffect(() => {
        /* fetching twin config only for fileReadUrl for displaying images(logo, image uploads preview)
        there should be a seperate config call for um to get fileReadUrl*/
        dispatch(fetchTwinConfigurations())

        dispatch(fetchWallConfigurations())
        dispatch(fetchCommunitiesConfigurations())
        dispatch(fetchAttendanceConfigurations())
        dispatch(fetchVmConfigurations())
        dispatch(fetchMrConfigurations())
    }, [dispatch])

    return (
        <Box sx={{ minWidth: '95dvw', overflowX: 'auto' }} className="mainContainer">
            <Box sx={AppStyle.box}>
                <AdminNavigation />
                {breadcrumbItems.hasOwnProperty(pathnameToCheck(pathname)) && <Grid
                    item
                    sx={{
                        ...AppStyle.container,
                        borderRadius: '8px',
                        bgcolor: themeMode ? '#000' : 'transparent',
                        mx: 1
                    }}
                    className="breadcrumbContainer padX8"
                >
                    <Breadcrumb keys={breadcrumbValues[breadcrumbItems[pathnameToCheck(pathname)]]} />
                </Grid>}
                {/* mapping over routes for navigation */}
                {routes.map(({ index, path, component }) => <PrivateRoute key={path} path={path} component={component} />)}
                <DVRProvider>
                    {cctvRoutes.map(({ index, path, component }) => <PrivateRoute key={path} path={path} component={component} />)}
                </DVRProvider>
            </Box>
            <Box ml={14.5}>
                <Footer />
            </Box>
        </Box>
    )
}

export default AppContainer