import { MenuItem } from '@mui/material';
import React from 'react'

const UserMenu = (props) => {
    const { user, onClickFunctionReset, onClickFunctionLogout } = props

    return (
        <>
            {user.isPasswordResetEnabled && (
                <MenuItem
                    onClick={onClickFunctionReset}>
                    Reset Password
                </MenuItem>
            )}
            < MenuItem onClick={onClickFunctionLogout} > Logout</ MenuItem></>
    )
}

export default UserMenu