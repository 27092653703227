import { Avatar, Box, Typography } from "@mui/material"
import moment from "moment"
import HighlightedText from "./HighlightedText";
import IconButton from '@mui/material/IconButton';

import {
    NoteTwoTone,
    TextFieldsTwoTone,
    ArticleTwoTone,
    GppMaybeTwoTone,
    EditNote,
    TrackChangesTwoTone,
    CheckCircleTwoTone,
    PermMediaTwoTone,
    ArrowCircleRight,
    PaymentsTwoTone,
    TaskTwoTone,
    WatchLaterTwoTone,
    AssignmentIndTwoTone,
    BusinessTwoTone,
    PersonOutlineTwoTone,
    DateRangeTwoTone,
    CasesTwoTone,
    LocationOnTwoTone,
    DeleteForeverTwoTone
} from '@mui/icons-material';

const HistoryCard = ({ data, type, handleHistoryDetail, isCardSelected, sx, disableArrow }) => {
    const avatarStyle = {
        position: "absolute",
        left: "-1.3rem",
        top: "3.5rem",
        zIndex: 1
    }

    const ReturnIcons = ({ type, value }) => {
        if (type === "notes") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "khaki" }}>
                <NoteTwoTone />
            </Avatar>)
        else if (type === "title") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "darkslategray" }}>
                <TextFieldsTwoTone />
            </Avatar>)
        else if (type === "description") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightblue" }}>
                <ArticleTwoTone />
            </Avatar>)
        else if (type === "priority") {
            if (value === "low") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                    <GppMaybeTwoTone />
                </Avatar>
            } else if (value === "medium") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "#FFA07A" }}>
                    <GppMaybeTwoTone />
                </Avatar>
            } else {
                return < Avatar
                    sx={{
                        ...avatarStyle,
                        backgroundColor: "#ef5350"
                    }}>
                    <GppMaybeTwoTone />
                </Avatar >
            }
        } else if (type === "status") {
            if (value === "closed") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                    <CheckCircleTwoTone />
                </Avatar >
            } else {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "#FA8072" }}>
                    <TrackChangesTwoTone />
                </Avatar >
            }

        } else if (type === "media") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightblue" }}>
                <PermMediaTwoTone />
            </Avatar >
        } else if (type === "ppm") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#ffd700" }}>
                <TaskTwoTone />
            </Avatar>
        }
        else if (type === "overdue") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#DC143C" }}>
                <WatchLaterTwoTone />
            </Avatar>
        }
        else if (type === "assignedUser") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#008B8B" }}>
                <AssignmentIndTwoTone />
            </Avatar>
        }
        else if (type === "assignedVendor") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#4682B4" }}>
                <BusinessTwoTone />
            </Avatar>
        }
        else if (type === "assignedUserType") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#ffd700" }}>
                <PersonOutlineTwoTone />
            </Avatar>
        }
        else if (type === "date") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#4682b4" }}>
                <DateRangeTwoTone />
            </Avatar>
        }
        else if (type === "category") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "Orange" }}>
                <CasesTwoTone />
            </Avatar>
        }
        else if (type === "area") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "gray" }}>
                <LocationOnTwoTone />
            </Avatar>
        }
        else if (type === "ppm_deleted") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "Crimson" }}>
                <DeleteForeverTwoTone />
            </Avatar>
        }
        else if (type === 'cost') {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#4682b4" }}>
                <PaymentsTwoTone />
            </Avatar>
        } else return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                <EditNote />
            </Avatar>)
    }

    return (
        <Box
            className="flex flexDirectionRow"
            sx={{ position: "relative", height: "6rem", paddingLeft: "5rem", ...sx }}
        >
            <Typography
                sx={{ width: "10rem", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                {moment(data?.updatedTime).format('Do MMMM YYYY hh:mm A')}
            </Typography>
            <Box
                sx={{ border: `1px dashed gray`, height: type === "last" ? "80%" : "100%", marginLeft: "2rem" }}>
            </Box>
            <Box sx={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                <ReturnIcons type={data?.fieldType} value={data?.currentValue} />
                <Box sx={{ paddingLeft: "3rem" }}>
                    <Typography><HighlightedText text={data?.textInfo && data?.textInfo} /></Typography>
                    <Typography>by {data?.user?.fullName}</Typography>
                </Box>

            </Box>
            {(data?.fieldType === "cost" || data?.fieldType === "media" || data?.comment) && <IconButton
                sx={{ marginLeft: "2rem", height: "2.5rem", marginTop: "3rem", color: isCardSelected ? "black" : 'lightgray' }}
                onClick={() => handleHistoryDetail({ historyObj: data })}>
                {disableArrow && <ArrowCircleRight size="large" />}
            </IconButton>}
        </Box>
    )
}

export default HistoryCard