import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { Avatar, Box, Checkbox, FormControlLabel, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { ThemeColors } from '../../../../hooks/ThemeColors';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function OperationalHours({ formData, setFormData, operationalHours, setOperationalHours, disableSubmit, viewMode, enableSubmit }) {
    const [selectedDays, setSelectedDays] = useState([]);
    const formDataRef = useRef(new FormData()); // Use ref to hold FormData
    const [timeErrors, setTimeErrors] = useState({});
    const [applyForAll, setApplyForAll] = useState(false);
    const [allDaysStartTime, setAllDaysStartTime] = useState(null);
    const [allDaysEndTime, setAllDaysEndTime] = useState(null);
    const [applyForWeekdays, setApplyForWeekdays] = useState(false);
    const [customApply, setCustomApply] = useState(false)
    const { commonSX, background_color } = ThemeColors();

    useEffect(() => {
        setCustomApply(true)
    }, [])

    useEffect(() => {
        // Extract open days and update selectedDays

        const openDays = Object.keys(operationalHours)
            .filter(day => !operationalHours[day].isClosed)
            .map(day => {
                // Convert to capitalized weekday format
                const index = weekdays.findIndex(weekday => weekday.toLowerCase() === day);
                return weekdays[index];
            });

        setSelectedDays(openDays);
    }, [operationalHours]);

    // Update FormData whenever operationalHours changes
    useEffect(() => {
        formDataRef.current.set("operationalHours", JSON.stringify(operationalHours));
        setFormData((prevFormData) => ({
            ...prevFormData, operationalHours: JSON.parse(formDataRef.current.get("operationalHours"))
        }));
        validateTimeRanges()
    }, [operationalHours]);


    useEffect(() => {
        if (allDaysStartTime && allDaysEndTime) {
            const formattedStart = dayjs(allDaysStartTime).format('HH:mm');
            const formattedEnd = dayjs(allDaysEndTime).format('HH:mm');
            if (formattedEnd < formattedStart) {
                setTimeErrors({ error: "End Time Cannot Be Less Than Start Time" })
            }

            if (applyForAll) {
                const updatedHours = weekdays.reduce((acc, day) => {
                    acc[day.toLowerCase()] = {
                        open: formattedStart,
                        close: formattedEnd,
                    };
                    return acc;
                }, {});

                setOperationalHours(updatedHours);
            } else if (applyForWeekdays) {
                const updatedHours = weekdays.reduce((acc, day) => {
                    if (day !== 'Saturday' && day !== 'Sunday') {
                        acc[day.toLowerCase()] = {
                            open: formattedStart,
                            close: formattedEnd,
                            isClosed: false
                        }
                    } else {
                        acc[day.toLowerCase()] = {
                            open: "",
                            close: "",
                            isClosed: true, // Indicates the day is closed
                        };
                    }
                    return acc;
                }, {});

                setOperationalHours((prevHours) => ({
                    ...prevHours,
                    ...updatedHours,
                }));
            }

        }
    }, [allDaysStartTime, allDaysEndTime, applyForAll, applyForWeekdays]);


    const validateTimeRanges = () => {
        const newErrors = {};
        Object.keys(operationalHours).forEach((day) => {
            const { open, close } = operationalHours[day.toLowerCase()] || {};
            if (open && close) {
                const startTime = dayjs(`2024-01-01T${open}`, "YYYY-MM-DDTHH:mm");
                const endTime = dayjs(`2024-01-01T${close}`, "YYYY-MM-DDTHH:mm");

                if (endTime.isBefore(startTime)) {
                    newErrors[day] = `End time cannot be earlier than start time for ${day}.`;
                }
            }
        });
        Object.keys(newErrors).length > 4 ? setTimeErrors({ error: "End Time Cannot Be Less Than Start Time" }) : setTimeErrors(newErrors);

        Object.keys(newErrors).length ? disableSubmit() : enableSubmit()


    };

    const handleDaySelection = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );

    };

    const handleStartTimeChange = (newTime, day) => {
        const formattedTime = dayjs(newTime).format("HH:mm");

        setOperationalHours((prevHours) => {
            const updatedHours = {
                ...prevHours,
                [day.toLowerCase()]: {
                    ...prevHours[day.toLowerCase()],
                    open: formattedTime,
                    isClosed: false, // Ensure the day is marked as open
                },
            };
            validateTimeRanges();
            return updatedHours;
        });
    };

    const handleEndTimeChange = (newTime, day) => {
        const formattedTime = dayjs(newTime).format("HH:mm");
        validateTimeRanges()

        setOperationalHours((prevHours) => {
            const updatedHours = {
                ...prevHours,
                [day.toLowerCase()]: {
                    ...prevHours[day.toLowerCase()],
                    close: formattedTime,
                },
            };
            return updatedHours;
        });
    };


    const handleApplyForAll = () => {
        if (allDaysStartTime && allDaysEndTime) {
            const formattedStart = dayjs(allDaysStartTime).format("HH:mm");
            const formattedEnd = dayjs(allDaysEndTime).format("HH:mm");

            const updatedHours = weekdays.reduce((acc, day) => {
                acc[day.toLowerCase()] = {
                    open: formattedStart,
                    close: formattedEnd,
                };
                return acc;
            }, {});
            validateTimeRanges()

            setOperationalHours(updatedHours);
            setTimeErrors({}); // Clear errors when applying for all
        }
    };

    const handleApplyForWeekdays = () => {
        if (allDaysStartTime && allDaysEndTime) {
            const formattedStart = dayjs(allDaysStartTime).format("HH:mm");
            const formattedEnd = dayjs(allDaysEndTime).format("HH:mm");

            const updatedHours = weekdays.reduce((acc, day) => {
                if (day !== 'Saturday' && day !== 'Sunday') {
                    acc[day.toLowerCase()] = {
                        open: formattedStart,
                        close: formattedEnd,
                        isClosed: false
                    }
                } else {
                    acc[day.toLowerCase()] = {
                        open: "",
                        close: "",
                        isClosed: true, // Indicates the day is closed
                    };
                }
                return acc;
            }, {});
            validateTimeRanges()

            setOperationalHours((prevHours) => ({
                ...prevHours,
                ...updatedHours,
            }));
            setTimeErrors({}); // Clear errors when applying for weekdays
        }
    };

    return (
        <>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={applyForAll}
                                onClick={(e) => {
                                    setApplyForAll(e.target.checked);
                                    setCustomApply(false)
                                    setApplyForWeekdays(false);
                                    if (e.target.checked) handleApplyForAll();
                                }}
                            />
                        }
                        label="Apply to All Days"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={applyForWeekdays}
                                onChange={(e) => {
                                    setApplyForWeekdays(e.target.checked);
                                    setApplyForAll(false);
                                    setCustomApply(false)
                                    if (e.target.checked) {

                                        handleApplyForWeekdays();
                                    }
                                }}
                            />
                        }
                        label="Apply to Weekdays Only"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={customApply}
                                onChange={(e) => {
                                    setCustomApply(e.target.checked);
                                    if (e.target.checked) {
                                        setApplyForAll(false);
                                        setApplyForWeekdays(false)
                                    }
                                }}
                            />
                        }
                        label="Custom Apply"
                    />
                </Grid>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={6}>
                        <TimePicker
                            label="Start Time"
                            disabled={customApply || (!applyForAll && !applyForWeekdays)}
                            views={["hours", "minutes"]}
                            value={allDaysStartTime}
                            onChange={(newTime) => setAllDaysStartTime(newTime)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="End Time"
                            disabled={customApply || (!applyForAll && !applyForWeekdays)}
                            views={["hours", "minutes"]}
                            value={allDaysEndTime}
                            onChange={(newTime) => setAllDaysEndTime(newTime)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>


                </Grid>
            </LocalizationProvider>
            {
                timeErrors?.error && <p style={{ color: "red" }}>{timeErrors?.error}</p>
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column", // Set items to stack vertically
                    justifyContent: "right", // Align items vertically in the center
                    alignItems: "right", // Center items horizontally
                    gap: "1rem", // Add spacing between items
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    // border: "1px solid red"
                }}
            >
                {weekdays.map((day) => (
                    <div style={{ display: "flex", justifyContent: "row" }}>
                        <Avatar
                            key={day}
                            // onClick={() => handleDaySelection(day)}
                            onClick={!viewMode && !applyForAll && !applyForWeekdays ? () => handleDaySelection(day) : undefined}
                            sx={{
                                width: 50,
                                height: 50,
                                bgcolor: selectedDays.includes(day) ? "primary.main" : "secondary",
                                color: "white",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                cursor: "pointer",
                                marginRight: "10px",
                                "&:hover": { bgcolor: "primary.light" },
                            }}
                        >
                            <Typography variant="button">{day.slice(0, 3)}</Typography>
                        </Avatar>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TimePicker
                                        label="Start Time"
                                        views={["hours", "minutes"]}
                                        disabled={viewMode || applyForAll || applyForWeekdays || !customApply}
                                        value={
                                            // dayjs(operationalHours[day.toLowerCase()]?.open || null, "HH:mm")
                                            operationalHours[day.toLowerCase()]?.open
                                                ? dayjs(`2024-01-01T${operationalHours[day.toLowerCase()].open}`, "YYYY-MM-DDTHH:mm")
                                                : null
                                        }
                                        onChange={(newTime) => handleStartTimeChange(newTime, day)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TimePicker
                                        label="End Time"
                                        views={["hours", "minutes"]}
                                        disabled={viewMode || applyForAll || applyForWeekdays || !customApply}
                                        value={
                                            // dayjs(operationalHours[day.toLowerCase()]?.close || null, "HH:mm")
                                            operationalHours[day.toLowerCase()]?.close
                                                ? dayjs(`2024-01-01T${operationalHours[day.toLowerCase()].close}`, "YYYY-MM-DDTHH:mm")
                                                : null

                                        }
                                        onChange={(newTime) => handleEndTimeChange(newTime, day)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                {/* {timeError && <p style={{ color: "red", fontSize: "13px", padding: "10px" }}>{timeError}</p>} */}
                                {timeErrors[day.toLowerCase()] &&
                                    Object.keys(timeErrors).map((ele, i) => (
                                        <p key={i} style={{ color: "red", fontSize: "13px", padding: "10px" }}>
                                            {timeErrors[ele]}
                                        </p>
                                    ))}

                            </Grid>
                        </LocalizationProvider>

                    </div>


                ))}
            </Box >


        </>
    );
}




export default OperationalHours;
