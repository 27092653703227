import React, { forwardRef, memo, useContext, useEffect, useRef, useState } from 'react';
import ImageRenderer from '../../../../components/image/ImageRenderer';
import { Box } from '@mui/material';
import CustomTypography from '../../../../components/texts/CustomTypography';
import { ChatContext } from '../../context/ChatContext';
import CustomIconButton from '../CustomIconButton';
import { marked } from 'marked';
import './response.css';
import copy from 'copy-to-clipboard';
import { ContentCopyRounded, ThumbDownOffAltRounded, VolumeUpOutlined, StopRounded } from '@mui/icons-material';
import { responseVote } from '../../helper';

const Reponse = forwardRef(({ logo, alt, response, id }) => {
    const { chatInfo } = useContext(ChatContext);
    const { interfaceChat } = chatInfo;

    const [isCopied, setIsCopied] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [neutral, setNeutral] = useState(false);
    const speechRef = useRef(null);
    const volumeButtonRef = useRef(null);

    const renderFormattedText = (text) => {
        const html = marked(text);
        return { __html: html };
    };

    const handleCopyButtonClick = () => {
        copy(response);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
    };

    const handleVolumeButtonClick = () => {
        if (isSpeaking) {
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
            return;
        }

        const textChunks = chunkText(response, 200);
        textChunks.forEach((chunk, index) => {
            const msg = new SpeechSynthesisUtterance(chunk);
            if (index === textChunks.length - 1) {
                speechRef.current = msg;
            }
            msg.onstart = () => setIsSpeaking(true);
            msg.onend = () => setIsSpeaking(false);
            window.speechSynthesis.speak(msg);
        });
    };

    const chunkText = (text, chunkSize) => {
        const chunks = [];
        let startIndex = 0;
        while (startIndex < text.length) {
            chunks.push(text.slice(startIndex, startIndex + chunkSize));
            startIndex += chunkSize;
        }
        return chunks;
    };

    const handleVote = (type) => {
        if (type === 'upvote') {
            setLiked(true);
            setDisliked(false);
            setNeutral(false);
        } else if (type === 'downvote') {
            setLiked(false);
            setDisliked(true);
            setNeutral(false);
        } else {
            setLiked(false);
            setDisliked(false);
            setNeutral(true);
        }

        responseVote({ threadId: interfaceChat?._id, responseId: id, userResponse: type });
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (volumeButtonRef.current && !volumeButtonRef.current.contains(event.target)) {
                window.speechSynthesis.cancel();
                setIsSpeaking(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            if (speechRef.current) {
                window.speechSynthesis.cancel();
            }
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <Box
            className='response'
            sx={{
                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', maxWidth: '100%', paddingBottom: '1.5rem',
                overflow: 'auto', '&::-webkit-scrollbar': {
                    height: '5px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#e0e0e0',
                    borderRadius: '10px',
                    border: '1px solid #e0e0e0',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#e0e0e0',
                },
            }}
        >
            <Box>
                <ImageRenderer src={logo} alt={alt} className='d30' />
            </Box>
            <Box sx={{ mt: '.1rem', ml: '1.5rem' }}>
                <CustomTypography sx={{ lineHeight: 1.2, fontSize: '18px', wordWrap: 'break-word', color: '#606060 !important' }}>
                    <span className='formatted-text' dangerouslySetInnerHTML={renderFormattedText(response)} />
                </CustomTypography>
                <Box sx={{ mt: '1.5rem' }}>
                    <CustomIconButton
                        onClick={() => handleVote('upvote')}
                        sx={{
                            transition: 'transform 0.2s',
                            '&:hover': { transform: 'scale(1.1)' },
                            '&:active': { transform: 'scale(0.9)' },
                        }}
                    >
                        {liked ? <ThumbDownOffAltRounded sx={{ transform: 'rotate(180deg)', fontSize: '20px', color: '#007bff' }} /> : <ThumbDownOffAltRounded sx={{ transform: 'rotate(180deg)', fontSize: '20px' }} />}
                    </CustomIconButton>
                    <CustomIconButton
                        onClick={() => handleVote('downvote')}
                        sx={{
                            ml: '1rem',
                            transition: 'transform 0.2s',
                            '&:hover': { transform: 'scale(1.1)' },
                            '&:active': { transform: 'scale(0.9)' },
                        }}
                    >
                        {disliked ? <ThumbDownOffAltRounded sx={{ fontSize: '20px', color: '#dc3545' }} /> : <ThumbDownOffAltRounded sx={{ fontSize: '20px' }} />}
                    </CustomIconButton>
                    <CustomIconButton
                        onClick={() => handleVote('neutral')}
                        sx={{
                            ml: '1rem',
                            transition: 'transform 0.2s',
                            '&:hover': { transform: 'scale(1.1)' },
                            '&:active': { transform: 'scale(0.9)' },
                        }}
                    >
                        {neutral ? <ThumbDownOffAltRounded sx={{ transform: 'rotate(-90deg)', fontSize: '20px', color: '#cece5fa3' }} /> : <ThumbDownOffAltRounded sx={{ transform: 'rotate(-90deg)', fontSize: '20px' }} />}
                    </CustomIconButton>
                    <CustomIconButton sx={{ ml: '1rem' }} onClick={handleCopyButtonClick}>
                        {
                            !isCopied ? <ContentCopyRounded sx={{ fontSize: '20px' }} /> :
                                <CustomTypography sx={{ color: '#0000008a !important', display: 'inline' }}>Copied!</CustomTypography>
                        }
                    </CustomIconButton>
                    <CustomIconButton sx={{ ml: '1rem' }} onClick={handleVolumeButtonClick} ref={volumeButtonRef}>
                        {isSpeaking ? <StopRounded sx={{ fontSize: '20px' }} /> : <VolumeUpOutlined sx={{ fontSize: '20px' }} />}
                    </CustomIconButton>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Reponse);
