import Modal from "../../components/modals/NewModal"
import PPMStatsProvider from "./context/PPMStatsProvider"
import PPMDelete from "./PPMDelete"

const DeletePPMModal = (props) => {
    const { openDeletePPMModal, handleCloseDeletePPMModal, deletingPPMId, handleCloseViewPPMModal, setCalendarEvents, selectedDateRange, triggerList } = props

    return (
        <Modal
            modalOpen={openDeletePPMModal}
            closeModal={handleCloseDeletePPMModal}
            title={'Delete PPM'}
            modalWidth={500}
        >
            <PPMStatsProvider>
                <PPMDelete
                    triggerList={triggerList}
                    deletingPPMId={deletingPPMId}
                    handleCloseDeletePPMModal={handleCloseDeletePPMModal}
                    handleCloseViewPPMModal={handleCloseViewPPMModal}
                    setCalendarEvents={setCalendarEvents}
                    selectedDateRange={selectedDateRange}
                />
            </PPMStatsProvider>
        </Modal>
    )
}

export default DeletePPMModal