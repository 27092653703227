
const TabContainerStyles = {
    boxContainer: {
        width: '100%'
    },
    boxItem: {
        borderBottom: '1px solid',
        border: 'none'
    },
    paperContainer: {
        height: '100%',
        pl: 1, ml: 1
    },
    tabs: {
        minHeight: 0
    },
    tabList: {
        minHeight: '10px',
        textTransform: 'none',
        mb: 0,
        fontSize: '14px'
    }
}
export default TabContainerStyles