import React from 'react'
import { TextField } from '@mui/material'
import { useSelector } from 'react-redux'

const TextInput = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const { label, variant, size, multiline, placeholder, name, rows, disabled,
        value, type, onChange, onKeyDown, id, inputProps, helperText, error, InputProps,
        fullWidth, select, SelectProps, children, sx, focused } = props

    return (
        <TextField
            autoComplete='off'
            InputLabelProps={{ sx: { color } }}
            id={id}
            label={label}
            size={size}
            variant={variant}
            value={value}
            multiline={!!multiline}
            rows={rows}
            placeholder={placeholder}
            name={name}
            type={type}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputProps={inputProps}
            InputProps={InputProps}
            fullWidth={!!fullWidth}
            sx={{ ...sx }}
            disabled={!!disabled}
            focused={!!value || focused}
            error={!!error}
            helperText={helperText}
            select={!!select}
            SelectProps={SelectProps}
            FormHelperTextProps={{ sx: { fontSize: '12px' } }}
        >
            {children}
        </TextField>
    )
}

export default TextInput;