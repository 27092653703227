import { Box, Button, MenuItem, Paper } from "@mui/material"
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles"
import FormInput from "../../../components/formInputs/FormInput"

const Selectors = (props) => {
    const {
        selectedYear, handleYearChange, selectedBuilding, handleBuildingChange, initialBuildingId,
        buildings, holidayYearOptions, handleOpenAddSingleHolidayFormModal, handleOpenAddMultipleHolidaysExcelModal
    } = props

    return (
        <Paper
            sx={{
                ...DepartmentSettingsStyles.paperContainer,
            }}
            className="theme-component-background theme-border theme-cards-boxShadow"
        >
            <Box className='displayFlex flexJustifyBetween columngap16'>
                <Box className='displayFlex alignItemsCenter columngap16'>
                    <FormInput
                        label="Year"
                        value={selectedYear}
                        onChange={handleYearChange}
                        select
                        size='small'
                    >
                        {holidayYearOptions?.map((year) => (
                            <MenuItem
                                key={year}
                                value={year}
                            >
                                {year}
                            </MenuItem>
                        ))}
                    </FormInput>
                    {initialBuildingId && <FormInput
                        label="Building"
                        value={selectedBuilding}
                        onChange={handleBuildingChange}
                        select
                        size='small'
                    >
                        {buildings?.map((building) => (
                            <MenuItem
                                key={building._id}
                                value={building._id}
                            >
                                {building.name}
                            </MenuItem>
                        ))}
                    </FormInput>}
                </Box>
                <Box className='displayFlex alignItemsCenter columngap16'>
                    <Button
                        className='actionButton'
                        onClick={handleOpenAddSingleHolidayFormModal}
                    >
                        Add Single Holiday
                    </Button>
                    <Button
                        className='actionButton'
                        onClick={handleOpenAddMultipleHolidaysExcelModal}
                    >
                        Add Multiple Holidays
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

export default Selectors