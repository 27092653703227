import React from 'react'
import { Box, IconButton, Modal as MuiModal, Paper, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ModalStyles from '../../../../styles/NewModal'
import CommonStyles from '../../../../styles/CommonStyles'
import { ThemeColors } from '../../../../hooks/ThemeColors'

const PPMModal = (props) => {
    const { closeModal, modalOpen, modalWidth, title, children, height, sx } = props

    const { background_color, color } = ThemeColors()

    return (
        <MuiModal open={modalOpen} onClose={closeModal} disableScrollLock disableRestoreFocus>
            <Paper sx={{
                ...ModalStyles.paperContainer, width: modalWidth || ModalStyles.paperContainer.width,
                height: height || 'auto', ...sx, background: background_color, color: color
            }} className='bdrRadius4px'>
                <Box sx={ModalStyles.boxContainer}>
                    <Typography sx={{ fontWeight: 600, fontSize: '24px', paddingLeft: "2rem", paddingTop: "0.5rem" }}>{title}</Typography>
                    <IconButton onClick={closeModal}>
                        <CloseOutlinedIcon sx={{ color: 'grey' }} />
                    </IconButton>
                </Box>
                <Box sx={{ m: 2, ...CommonStyles.scrollStyle }} className='maxHeight80vh overflowAuto'>
                    {children}
                </Box>
            </Paper>
        </MuiModal>
    )
}

export default PPMModal