import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchVisitorManagementSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchDashboardVisitorStats: builder.query({
            query: (buildingId) => ({
                url: `vm/v1/dashboard/s1/data?buildingId=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Visitor Management')
            },
        })
    }),
});

export const { useFetchDashboardVisitorStatsQuery } = fetchVisitorManagementSlice

const initialState = {
    isLoaded: false,
    data: {}
}

const visitorManagementSlice = createSlice({
    name: 'visitorManagement',
    initialState,
    reducers: {
        resetVisitorManagementStats: (state, action) => {
            return state = initialState
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchDashboardVisitorStats' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.isLoaded = true
                state.data = action.payload
            }
        )
    },
})

export const { resetVisitorManagementStats } = visitorManagementSlice.actions

export default visitorManagementSlice.reducer