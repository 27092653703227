import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, Grid, MenuItem, } from '@mui/material'
import { useResetPasswordMutation } from '../../../../redux/features/passwordSlice'
import { switchModes } from '../../../../redux/features/themeSlice'
import { resetUserDetails } from '../../../../redux/features/userSlice'
import { resetBuildings, setSelectedBuilding, useGetBuildingsQuery, setSelectedBuildingData } from '../../../../redux/features/buildingsSlice'
import { successToast } from '../../helpers/apiToast'
import { useFetchAllDashboardTemplatesQuery, useUpdateADashboardTemplatebyTemplateIdMutation } from '../../../../redux/features/services/gridSlice'
import { POST } from '../../helpers/http'
import WeatherTemp from './components/weather/WeatherTemp'
import { Contrast } from '@mui/icons-material'
import './header.css'
import { resetCurrency, useGetCurrencyQuery } from '../../../../redux/features/currencySlice'
import { resetVisitorManagementStats } from '../../../../redux/features/services/visitorManagementSlice'
import { resetAssetManagementStats } from '../../../../redux/features/services/assetManagementSlice'
import { resetDeviceManagementStats } from '../../../../redux/features/services/deviceManagementSlice'
import { resetDashboardAlerts } from '../../../../redux/features/services/alertSlice'
import { resetDashboardAqi } from '../../../../redux/features/services/aqiSlice'
import { resetDashboardDesks } from '../../../../redux/features/services/desksSlice'
import { resetDashboardEnergy } from '../../../../redux/features/services/energySlice'
import { resetDashboardMeetingRooms } from '../../../../redux/features/services/meetingRoomsSlice'
import { resetDashboardTotalOccupancy } from '../../../../redux/features/services/occupancySlice'
import { resetDashboardRestRooms } from '../../../../redux/features/services/washroomSlice'
import { resetDashboardFaults } from '../../../../redux/features/services/faultReportSlice'
import { resetFeedback } from '../../../../redux/features/services/feedbackSlice'
import { resetCameraDashboardStats } from '../../../../redux/features/services/cameraSlice'
import { resetDocuentHubDashboardStats } from '../../../../redux/features/services/digitalDocumentationSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import NhanceLogoutModal from './components/modal/logoutConfirmation/NhanceLogoutModal'
import NhanceResetPassword from './components/modal/resetPassword/NhanceResetPassword'
import RoleSwitch from './components/menu/roleSwitchMenu/RoleSwitch'
import NhanceHeaderMenu from './components/menu/NhanceHeaderMenu'
import UserMenu from './components/menu/userMenu/UserMenu'
import NhanceAvatar from '../../components/avatar/NhanceAvatar'
import ImageRenderer from '../../../admin/components/image/ImageRenderer'
import { chocolateBoxIcon } from '../../../../data/imageUrls'
import { resetDashboardWaterConsumption } from '../../../../redux/features/services/waterConsumptionSlice'
import CustomTypography from '../../components/texts/CustomTypography'
import FormInput from '../../components/formInputs/FormInput'

const Header = () => {
    // Get the current pathname from the location
    const location = useLocation()
    const pathname = location?.pathname

    // Get the window width for mobile responsiveness
    const isMobile = window.innerWidth;

    // Hook to navigate to different routes
    const navigate = useNavigate()

    // Redux dispatch function to dispatch actions
    const dispatch = useDispatch()

    // Get the user data from the Redux store
    const user = useSelector((state) => state.user)

    // Get configurations data from the Redux store
    const configurations = useSelector((state) => state?.configurations)
    const { logo } = configurations

    // Get the current theme mode from the Redux store (dark or light)
    const themeMode = useSelector((state) => state.theme.darkTheme)

    // State to manage the visibility of the logout confirmation modal
    const [open, setOpen] = useState(false)

    // State to manage the anchor element for user menu
    const [anchorEl, setAnchorEl] = useState(null);

    // State to manage the visibility of the reset password modal
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)

    // State to manage the new password input field
    const [newPassword, setNewPassword] = useState('')

    // State to manage the confirm password input field
    const [confirmPassword, setConfirmPassword] = useState('')

    // State to manage password error messages
    const [passwordError, setPasswordError] = useState('')

    // State to manage the anchor element for admin console menu
    const [adminConsoleAnchorEl, setAdminConsoleAnchorEl] = useState(null)

    // Mutation hook for resetting the password
    const [resetPassword] = useResetPasswordMutation()

    // Boolean value to determine if the user menu is open
    const menuOpen = Boolean(anchorEl);

    // Boolean value to determine if the admin console menu is open
    const adminConsoleMenuOpen = Boolean(adminConsoleAnchorEl);

    // Get buildings data from the Redux store
    const buildings = useSelector((state) => state.buildings)

    const selectedBuilId = localStorage.getItem('bid')
    // Get the selected dashboard template data from the Redux store
    const selectedDashboardTemplate = useSelector(state => state.grid.selectedDashboardTemplate?.data)

    const foundBuilding = buildings?.buildings?.find((ele) => ele._id === selectedBuilId)

    // Mutation hook for updating a dashboard template by its ID
    const [updateADashboardTemplatebyTemplateId] = useUpdateADashboardTemplatebyTemplateIdMutation()

    // Fetch building and dashboard templates data
    useGetBuildingsQuery()
    useFetchAllDashboardTemplatesQuery()

    useEffect(() => {
        if (foundBuilding?.name) {
            dispatch(setSelectedBuildingData(foundBuilding))
        }
    }, [foundBuilding?.name])

    // Update theme mode based on the selected dashboard template
    useEffect(() => {
        if (selectedDashboardTemplate?.themeUsed) {
            dispatch(switchModes(selectedDashboardTemplate?.themeUsed))
        }
    }, [dispatch, selectedDashboardTemplate?.themeUsed])

    // Function to handle building change
    const onBuildingChange = (e) => {
        const value = e.target.value
        dispatch(resetCurrency()) // Reset currency data
        dispatch(setSelectedBuilding(value)) // Set the selected building
        localStorage.setItem('bid', value) // Store the selected building ID in localStorage

        // Reset various dashboard data based on the selected building
        dispatch(resetDashboardAlerts());
        dispatch(resetDashboardAqi());
        dispatch(resetDashboardDesks());
        dispatch(resetDashboardEnergy());
        dispatch(resetDashboardMeetingRooms());
        dispatch(resetDashboardTotalOccupancy());
        dispatch(resetDashboardRestRooms());
        dispatch(resetDashboardFaults());
        dispatch(resetFeedback());
        dispatch(resetCameraDashboardStats());
        dispatch(resetDocuentHubDashboardStats());
        dispatch(resetDeviceManagementStats())
        dispatch(resetAssetManagementStats())
        dispatch(resetVisitorManagementStats())
        dispatch(resetDashboardWaterConsumption())
    }

    // Get the selected building ID from localStorage
    const selectedBuildingId = localStorage.getItem('bid')

    // Validate if the selected building ID is valid
    const validBuilding = (
        selectedBuildingId !== null &&
        selectedBuildingId !== undefined &&
        selectedBuildingId !== '' &&
        selectedBuildingId !== 'undefined'
    )

    // Refetch currency data based on the selected building ID and configurations
    const { refetch: refetchCurrency } = useGetCurrencyQuery(
        validBuilding && configurations?.services?.assetManagement && !configurations?.underMaintenance?.assetManagement ? selectedBuildingId : skipToken
    )

    // Update the selected building ID in localStorage if it doesn't match the stored ID
    useEffect(() => {
        if (
            selectedBuildingId === null ||
            selectedBuildingId === undefined ||
            selectedBuildingId === 'undefined' ||
            selectedBuildingId === ''
        ) {
            if (buildings.selectedBuilding) localStorage.setItem('bid', buildings.selectedBuilding)
        }
    }, [buildings.selectedBuilding])

    // Refetch currency data based on the selected building and configurations
    useEffect(() => {
        if (validBuilding && configurations?.services?.assetManagement && !configurations?.underMaintenance?.assetManagement) refetchCurrency()
    }, [buildings?.selectedBuilding])

    // Function to handle input changes in password fields
    const handlePasswordInputChange = (e) => {
        if (e.target.name === 'newPassword') {
            setNewPassword(e.target.value)
        }
        if (e.target.name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
    }

    // Function to handle closing the user menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Function to handle closing the admin console menu
    const handleAdminConsoleMenuClose = () => {
        setAdminConsoleAnchorEl(null);
    };

    // Function to handle closing the reset password modal
    const handleCloseResetPasswordModal = () => {
        setResetPasswordModalOpen(false)
        setPasswordError('')
        setNewPassword('')
        setConfirmPassword('')
        handleMenuClose()
    }

    // Function to handle opening the user menu when the user avatar is clicked
    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle opening the admin console menu when the ChocolateBox icon is clicked
    const handleChocolateIconClick = (event) => {
        setAdminConsoleAnchorEl(event.currentTarget);
    };

    // Function to handle the logout process
    const handleLogout = useCallback(async () => {
        // Make a POST request to log out the user
        await POST(`${process.env.REACT_APP_BASE_URL}um/v1/user/logout`, {}, 'user logout')
        // Clear localStorage
        localStorage.clear()
        // Reset user details and buildings in Redux
        dispatch(resetUserDetails())
        dispatch(resetBuildings())
        // Redirect the user to the accounts UI URL with their email as a query parameter
        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${user?.email}`
    }, [dispatch, user?.email])

    // Function to handle closing the logout confirmation modal
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    // Function to toggle the visibility of the logout confirmation modal
    const handleToggle = () => {
        setOpen(!open)
        handleMenuClose()
    }

    // Function to submit the new password
    const submitPassword = async () => {
        if (newPassword?.trim()?.length === 0 || confirmPassword?.trim()?.length === 0) {
            setPasswordError('Passwords cannot be empty')
        }
        else if (newPassword?.length < 8) {
            setPasswordError('Password must be at least 8 characters')
        }
        else if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match')
        }
        else if (newPassword?.length >= 8 && newPassword === confirmPassword) {
            setPasswordError('')
            try {
                // Call the mutation to reset the password
                const response = await resetPassword(newPassword)?.unwrap()
                successToast(response.message)
                handleCloseResetPasswordModal()
            } catch (error) {
                console.log({ error })
            }
        }
    }

    // Function to submit the password when the Enter key is pressed in the confirm password field
    const onKeyDownConfirmPassword = (e) => {
        if (e.key === 'Enter') {
            submitPassword()
        }
    }

    // Function to navigate to the admin dashboard when the admin console option is clicked
    const handleAdminDashboardClick = useCallback(() => {
        navigate('/admin/usermanagement')
    }, [])

    // Function to navigate to the home page when the Digital Twin option is clicked
    const handleDigitalTwinClick = useCallback(() => {
        navigate('/')
    }, [])

    // Function to toggle the theme between dark and light
    const handleToggleTheme = async (event) => {
        if (selectedDashboardTemplate?.themeUsed) {
            // Dispatch the switchModes action to toggle the theme
            dispatch(switchModes(selectedDashboardTemplate?.themeUsed));
            // Create a clone of the selected dashboard template
            const newDashboardTemplate = structuredClone(selectedDashboardTemplate)
            // Update the themeUsed property to the opposite theme mode
            newDashboardTemplate.themeUsed = themeMode ? 'light' : 'dark'
            // Remove the ID from the cloned template   
            delete newDashboardTemplate?._id;
            try {
                // Call the mutation to update the dashboard template by its ID
                await updateADashboardTemplatebyTemplateId({ id: selectedDashboardTemplate?._id, data: newDashboardTemplate })?.unwrap()
            } catch (error) {
                console.log({ error })
            }
        }
    };

    const handleUserMenuClick = useCallback(() => {
        // Open Reset Password modal
        setResetPasswordModalOpen(true);
        handleMenuClose();
    }, [])

    return (
        // Header component
        <AppBar component='nav' className='bg-white theme-appbar-boxShadow theme-component-background posSticky header-container'>
            {/* Grid container for the header */}
            <Grid container className='flexJustifyBetween gap16'>
                {/* Grid item - left side of the header */}
                <Grid item>
                    {/* Nested Grid container - ChocolateBox Icon, Logo, Building Name */}
                    <Grid container className='flexJustifyCenter flexAlignItemsCenter'>
                        {/* Grid item for the ChocolateBox icon */}
                        <Grid item className='height72px'>
                            {/* ChocolateBoxIcon */}
                            <ImageRenderer
                                src={chocolateBoxIcon.src}
                                alt={chocolateBoxIcon.alt}
                                className={`${user?.role === "admin" ? "cursorPtr" : null} iconlogo`}
                                onClick={(e) => {
                                    // If user is an admin, trigger ChocolateIconClick event
                                    if (user?.role === "admin") handleChocolateIconClick(e);
                                }} />
                            {/* Admin Console Menu */}
                            <NhanceHeaderMenu anchorEl={adminConsoleAnchorEl} open={adminConsoleMenuOpen} onCloseFunction={handleAdminConsoleMenuClose} themeMode={themeMode} className='switchAdminMenu menu'
                                children={
                                    <RoleSwitch themeMode={themeMode} handleAdminDashboardClick={handleAdminDashboardClick} handleDigitalTwinClick={handleDigitalTwinClick} pathname={pathname} />
                                }
                            />

                        </Grid>
                        {/* Grid item for logo */}
                        <Grid item className='theme-appbar-border-right'>
                            {/* Company logo */}
                            <Box
                                component={"img"}
                                src={themeMode ? logo?.light : logo?.dark}
                                alt="Company logo"
                                className='height25px cursorPtr mrgnleft20 padright20px cursorPtr'
                                onClick={() => navigate("/")} // Navigate to the home page
                            />
                        </Grid>
                        {/* Grid item for displaying building name */}
                        {isMobile > 767 && buildings.selectedBuilding && (
                            <Grid item>
                                {/* Display the selected building name */}
                                <CustomTypography className="mrgnleft16 fontweight700"
                                    children={
                                        buildings?.buildings?.find(
                                            (ele) => ele._id === selectedBuildingId,
                                        )?.name
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* Grid item - right side of the header */}
                <Grid item className='padright15'>
                    {/* Nested Grid container - Temperature, Weather, Date, ModeToggle, UserAction */}
                    <Grid container className='height100p flexJustifyCenter flexAlignItemsCenter gap16'>
                        {/* Dropdown menu for selecting buildings */}
                        {buildings?.buildings?.length > 0 && location.pathname === '/' && (<FormInput
                            select
                            value={buildings.selectedBuilding}
                            onChange={onBuildingChange}
                            size='small'
                        >
                            {buildings.buildings?.map((item, i) => <MenuItem
                                key={item?.name}
                                value={item?.id || item?._id}
                                disabled={item?.disabled}
                            >
                                {item?.name}
                            </MenuItem>)}
                        </FormInput>)}
                        {/* Conditionally rendering WeatherTemp component based on screen width */}
                        {isMobile > 767 && <Grid item>
                            {/* Display weather and temperature */}
                            <WeatherTemp themeMode={themeMode} />
                        </Grid>}
                        {/* Grid item for theme toggle */}
                        <Grid item>
                            {/* Avatar icon for switching theme */}
                            <NhanceAvatar className={themeMode ? 'darkicon' : null} onClick={handleToggleTheme}
                                children={<Contrast />}
                            />
                        </Grid>
                        {/* Grid item for user avatar and menu */}
                        <Grid item>
                            {/* Avatar icon for the user */}
                            <NhanceAvatar onClick={handleAvatarClick}
                                children={user.fullName && user?.fullName[0]}
                            />

                            {/* User menu */}
                            <NhanceHeaderMenu anchorEl={anchorEl} open={menuOpen} onCloseFunction={handleMenuClose} themeMode={themeMode} children={
                                <UserMenu onClickFunctionLogout={handleToggle} onClickFunctionReset={handleUserMenuClick} user={user} />
                            } />

                            {/* Reset Password Modal */}
                            <NhanceResetPassword resetPasswordModalOpen={resetPasswordModalOpen} handleCloseResetPasswordModal={handleCloseResetPasswordModal} themeMode={themeMode} newPassword={newPassword} handlePasswordInputChange={handlePasswordInputChange} confirmPassword={confirmPassword} onKeyDownConfirmPassword={onKeyDownConfirmPassword} submitPassword={submitPassword} passwordError={passwordError} />
                            {/* Backdrop for logout confirmation */}
                            <NhanceLogoutModal open={open} handleClose={handleClose} handleLogout={handleLogout} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    )
}

export default memo(Header);
