import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'

import Loader from '../../../components/loading/Loader'
import UnderMaintenance from '../../../components/underMaintenance/UnderMaintenance'
import { GET } from '../../../helpers/http'
import MeetingRoomTabBox from '../MeetingRoomTabBox/MeetingRoomTabBox'


function TabConfigContainer() {

    const [tabs, setTabs] = useState([])
    // const [tabs, setTabs] = useState([{ "_id": "652fa5e54492cfaf810f9b67", "location": { "_id": "64422f831b37415ce1fabfca", "name": "Bengaluru" }, "building": { "_id": "64422f9e1b37415ce1fabfe2", "name": "Nhance SDeG" }, "floor": { "_id": "64422fe41b37415ce1fabffb", "name": "First Floor" }, "area": { "_id": "64425c63fe8965e4cc64935c", "name": "Meeting Room" }, "isDisabled": false, "isConnected": true, "accessCode": "N4VC7B", "resourceId": "f1r1@twinspiration.in", "tenantId": "TWINSPIRATION", "tenantCode": "twinspiration", "logo": { "light": "fjbweibfwujbf", "dark": "kncewjfbjwe" }, "createdAt": "2023-10-18T09:16:43.066Z", "updatedAt": "2023-10-18T09:16:43.066Z", "__v": 0, "calendarId": "AAMkADM2MWFkMTcyLThiNzgtNDgzMS1iMzQ4LWM0NWRhMWM2OGY0ZQBGAAAAAAAGN9uhw-sXSYqn-8oxjOA2BwAislG-oJxtR7hQuXdSBvDAAAAAAAEGAAAislG-oJxtR7hQuXdSBvDAAAAINbljAAA=", "roomId": "f8d5fbc1-a3cb-448d-9922-9ecb21d7177e" }])
    const [areTabsLoaded, setAreTabsLoaded] = useState(false)

    const serviceEnabled = useSelector((state) => state.configurations?.services?.meetingRoomsTab)

    const underMaintenance = useSelector((state) => state.configurations?.underMaintenance?.meetingRoomsTab)

    const selectedBuildingId = localStorage.getItem('bid')

    const centerDiv = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
    }

    useEffect(() => {
        if (selectedBuildingId && (serviceEnabled && serviceEnabled !== 'NA') && !underMaintenance) {
            // Fetching all the kiosks configured for this building
            GET(`${process.env.REACT_APP_BASE_URL}mr/v1/saas/mrtabconfig/list?buildingId=${selectedBuildingId}`, 'Fetch All Kiosks MR Tab')
                .then((response) => {
                    const result = response.data
                    if (response?.status === 200) {
                        setAreTabsLoaded(true)
                        setTabs(result)
                    } else {
                        setAreTabsLoaded(true)
                    }
                })
                .catch((err) => console.log(err))
        }
    }, [selectedBuildingId, underMaintenance, serviceEnabled])

    const updateTabsArr = (updatedTab) => {
        setTabs(prevState => {
            return prevState.map(tab => {
                if (tab?._id === updatedTab?._id) {
                    return { ...tab, ...updatedTab }
                } else {
                    return { ...tab }
                }
            })
        })
    }

    return (
        underMaintenance ? <UnderMaintenance height="80vh" /> : <Box ml={1} width={'100%'}>
            {areTabsLoaded ? (
                <Box>
                    {tabs?.length > 0 ? (
                        <Grid container>
                            {tabs.map(tab => {
                                return <Grid key={tab?._id} item md={6} lg={4} >
                                    <MeetingRoomTabBox {...tab} updateTabsArr={updateTabsArr} />
                                </Grid>
                            })}
                        </Grid>
                    ) : (
                        <Box sx={centerDiv}>
                            No Tabs Added.
                        </Box>
                    )}
                </Box>
            ) : <Loader height="80vh" width="80vw" />}
        </Box >
    )

}

export default TabConfigContainer