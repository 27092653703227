import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchDeviceManagementSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchSensorDevicesStats: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/devicemanagement/s1/data?bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Sensor Devices Dashboard Widget Stats')
            },
        })
    }),
});

export const { useFetchSensorDevicesStatsQuery } = fetchDeviceManagementSlice

const initialState = {
    isLoaded: false,
    data: {}
}

const deviceManagementSlice = createSlice({
    name: 'deviceManagement',
    initialState,
    reducers: {
        resetDeviceManagementStats: (state, action) => {
            return state = initialState
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchSensorDevicesStats' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.isLoaded = true
                state.data = action.payload
            }
        )
    },
})

export const { resetDeviceManagementStats } = deviceManagementSlice.actions

export default deviceManagementSlice.reducer