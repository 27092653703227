const CommonStyles = {
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    scrollStyle: {
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid lightgrey'
        }
    },
    centerDivWidth100: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    centerDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    ModalPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
    },
    justifyEnd: {
        display: 'flex',
        justifyContent: 'end'
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    justifyContentCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    justifyContentFlexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    spaceBetweenWidth100: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    justifyCenterAlignEndWidth100: {
        width: "100%",
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',

    },
    spaceEvenlyAlignCenter: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    flexEndAlignCenter: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    columnFlexCenter: {
        display: "flex", flexDirection: "column", justifyContent: "center",
    },
    centerDivHeight100: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    centerDivHeight80: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    actionButton: {
        bgcolor: '#dc4d28',
        color: 'white',
        textTransform: 'none',
        "&:hover": {
            bgcolor: '#dc3d28'
        },
        fontSize: '0.75rem'
    },
    OuterBox: {
        p: 1,
        width: "100%",
        // height: "89.9vh",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    pageHeading: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 2,
        mb: '16px',
        mt: 1
    },
    topPaper: {
        p: 1,
        mb: 2,
        boxShadow: '0px 0px 6px #c2cfdb',
    },
    innerBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}

export default CommonStyles