import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../components/facilityManager/helpers/http'

const initialState = {
    isLoaded: false,
    error: "",
    apiAlerts: {}
}

export const asyncFetchDeviceStatusAlerts = createAsyncThunk('alertSliceFidelity/asyncFetchDeviceStatusAlerts', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/alerts/s1/data?bid=${bid}&duration=today`, 'Alerts')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const alertSliceFidelity = createSlice({
    name: 'alertSliceFidelity',
    initialState,
    reducers: {
        updateSSEAlerts: (state, action1) => {

            const data = JSON.parse(JSON.stringify(state?.apiAlerts))
            const action = action1.payload
            const isValid = action.payload?.isValid, lastUpdatedTime = action.payload?.lastUpdatedTime
            const feature = action.payload?.feature?.toLowerCase(), isLow = action.payload?.isLow, isHigh = action.payload?.isHigh
            let str = ""
            if (lastUpdatedTime && isValid === false && !isHigh && !isLow) str = "Inactive Device - Last Value"
            else if (lastUpdatedTime === null && isValid === false && !isHigh && !isLow) str = "Inactive device"
            else if (isValid === false && lastUpdatedTime === undefined && !isHigh && !isLow) str = "Invalid Data"

            const sseData = action.payload
            sseData.alertText = feature === 'temperature' ? (isValid ? (isHigh ? 'High Temperature' : 'Low Temperature') : str) :
                feature === 'humidity' ? (isValid ? (isHigh ? 'High Humidity' : 'Low Humidity') : str) :
                    feature === 'illuminance' ? (isValid ? (isHigh ? 'High Illuminance' : 'Low Illuminance') : str) :
                        feature === 'co2' ? (isValid ? (isHigh ? 'High CO2' : 'Low CO2') : str) :
                            feature === 'air pressure' ? (isHigh ? 'High Air Pressure' : isLow ? 'Low Air Pressure' : '') :
                                feature === 'radon' ? (isValid ? (isHigh ? ' High Radon' : 'Low Radon') : str) :
                                    feature === 'voc' ? (isValid ? (isHigh ? ' High Tvoc' : 'Low Tvoc') : str) :
                                        feature === 'paper towel usage' ? (isValid ? (isHigh ? ' Paper Towel Full' : 'Paper Towel Empty') : str) :
                                            feature === 'bin usage' ? (isValid ? (isHigh && ' Bin is full') : str) :
                                                feature === 'body temperature' ? (isValid ? 'Abnormal temperature detected in an individual' : str) :
                                                    feature === 'people count' ? (isValid ? 'Negative people count' : str) :
                                                        feature === 'meetingroom_occupancy' ? (isValid ? 'Occupancy Count Exceeded' : str) :
                                                            feature === 'energy' ? (isValid ? '' : str) :
                                                                feature === 'aqi' ? (isValid ? (isHigh && 'High AQI') : str) :
                                                                    feature === 'occupancy' ? (isValid ? '' : str) :
                                                                        feature === 'ammonia' ? (isValid ? '' : str) :
                                                                            feature === 'desk occupancy' ? (isValid ? '' : str) :
                                                                                feature === 'wetness' ? (isValid ? '' : str) :
                                                                                    feature === 'soap level' ? (isValid ? '' : str) :
                                                                                        feature === 'odour' ? (isValid ? '' : str) :
                                                                                            feature === 'proximity' ? (isValid ? '' : str) : ''
            const newData = [{ ...sseData, area: sseData.areaName, floor: sseData.floorName }, ...data?.alerts]
            const newCount = { ...data?.count, [sseData?.status?.toLowerCase()]: data?.count[sseData?.status?.toLowerCase()] + 1, all: data?.count?.all + 1 }
            const finalData = { alerts: newData, count: newCount }
            state.apiAlerts = finalData
            state.dashboard = { ...state.dashboard, isLoaded: true, apiAlerts: finalData }

        }
    },
    extraReducers(builder) {
        builder.addCase(asyncFetchDeviceStatusAlerts.fulfilled, (state, action) => {
            const data = action.payload?.alerts
            const newData = data.map(ele => {
                const isValid = ele?.isValid, lastUpdatedTime = ele?.lastUpdatedTime, feature = ele.feature?.toLowerCase()
                let str = ""
                if (lastUpdatedTime && isValid === false && !ele?.isHigh && !ele?.isLow) str = "Inactive Device - Last Value"
                else if (lastUpdatedTime === null && isValid === false && !ele?.isHigh && !ele?.isLow) str = "Inactive Device"
                else if (isValid === false && lastUpdatedTime === undefined && !ele?.isHigh && !ele?.isLow) str = "Invalid Data"

                const alertText = feature === 'temperature' ? (isValid ? (ele.isHigh ? 'High Temperature' : 'Low Temperature') : str) :
                    feature === 'humidity' ? (isValid ? (ele.isHigh ? 'High Humidity' : 'Low Humidity') : str) :
                        feature === 'illuminance' ? (isValid ? (ele.isHigh ? 'High Illuminance' : 'Low Illuminance') : str) :
                            feature === 'co2' ? (isValid ? (ele.isHigh ? 'High CO2' : 'Low CO2') : str) :
                                feature === 'air pressure' ? (isValid ? (ele.isHigh ? 'High Air Pressure' : 'Low Air Pressure') : str) :
                                    feature === 'radon' ? (isValid ? (ele.isHigh ? ' High Radon' : 'Low Radon') : str) :
                                        feature === 'voc' ? (isValid ? (ele.isHigh ? ' High Tvoc' : 'Low Tvoc') : str) :
                                            feature === 'paper towel usage' ? (isValid ? (ele.isHigh ? ' Paper Towel Full' : 'Paper Towel Empty') : str) :
                                                feature === 'bin usage' ? (isValid ? (ele.isHigh && ' Bin is full') : str) :
                                                    feature === 'body temperature' ? (isValid ? 'Abnormal temperature detected in an individual' : str) :
                                                        feature === 'people count' ? (isValid ? 'Negative people count' : str) :
                                                            feature === 'energy' ? (isValid ? '' : str) :
                                                                feature === 'aqi' ? (isValid ? (ele.isHigh && 'High AQI') : str) :
                                                                    feature === 'occupancy' ? (isValid ? '' : str) :
                                                                        feature === 'ammonia' ? (isValid ? '' : str) :
                                                                            feature === 'desk occupancy' ? (isValid ? '' : str) :
                                                                                feature === 'wetness' ? (isValid ? '' : str) :
                                                                                    feature === 'soap level' ? (isValid ? '' : str) :
                                                                                        feature === 'odour' ? (isValid ? '' : str) :
                                                                                            feature === 'proximity' ? (isValid ? '' : str) :
                                                                                                feature === 'meetingroom_occupancy' ? (isValid ? 'Occupancy Count Exceeded' : str) :
                                                                                                    feature === 'occupancy sensor' ? (isValid ? 'Occupancy Count Exceeded' : str) : ''

                return {
                    ...ele, alertText
                }
            })
            const finalData = { alerts: newData, count: action.payload?.count, total: action.payload?.total }

            state.isLoaded = true
            state.apiAlerts = finalData
        })
    }
})

export const { updateSSEAlerts } = alertSliceFidelity.actions

export default alertSliceFidelity.reducer