const ExternalEmailsStyles = {
    headingTypo: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
    },
    innerBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    }

}

export default ExternalEmailsStyles