import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { GET, PUT } from "../../../../../helpers/http"
import { Box, Button, Checkbox, FormHelperText, ListItemText, MenuItem, Stack, Typography } from "@mui/material"
import NotificationsStyles from "../../../../../styles/NotificationsStyles"
import TextInput from "../../../../../components/formInputs/TextInput"
import CheckBox from "../../../../../components/formInputs/CheckBox"
import { useEffect, useState } from "react"
import CommonStyles from "../../../../../styles/CommonStyles"
import * as Yup from 'yup';
import { errorToast, successToast } from "../../../../../helpers/apiToast"


export default function EditMaintenance() {
    const navigate = useNavigate()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const [formErrors, setFormErrors] = useState({})
    const [selectedCheckBox, setSelectedCheckBox] = useState('')
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const location = useLocation()
    const maintainenceId = location.pathname.replace('/admin/settings/maintenance-mapping/edit/', '')
    const [options, setOptions] = useState({
        categories: [],
        departments: [],
    })

    const [maintanenceMapping, setMaintanenceMapping] = useState({
        name: '',
        code: '',
        serviceReference: { name: '' },
        categoriesReference: [],
        departmentsReference: [],
        description: ''
    })
    const schema = Yup.object().shape({
        name: Yup.string().trim().min(3, 'Name must be at least 3 characters').max(256, 'Name cannot exceed 256 characters').required('Maintenance Mapping Name is required'),
        code: Yup.string().trim().min(3, 'Code must be at least 3 characters').max(256, 'Code cannot exceed 256 characters').required('Maintenance Mapping Code is required'),
        categoriesReference: Yup.array().min(1, 'Select at least one category'),
        departmentsReference: Yup.array().min(1, 'Select at least one department'),
        serviceReference: Yup.object().required('Service selection is required'),
        description: Yup.string().trim().notRequired(),
    });
    useEffect(() => {
        (async () => {
            try {
                console.log({ maintanenceMapping })
                // const response = await GET(`http://localhost:8001/v1/admin/maintenance-mapping?id=${maintainenceId}`)
                const response = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/admin/maintenance-mapping?id=${maintainenceId}`)
                console.log("🚀 ~ response:", response)
                setMaintanenceMapping(response.data)
            } catch (error) {
                console.log(error)
            }
        })();
        async function fetchCategories() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, 'Get Categories') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`
                setOptions(prevState => ({ ...prevState, categories: data }))
            } catch (error) {
                console.log({ error })
            }
        }
        async function fetchDepartments() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Get Departments') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`
                setOptions(prevState => ({ ...prevState, departments: data }))
            } catch (error) {
                console.log({ error })
            }
        }

        fetchCategories()
        fetchDepartments()

    }, [])



    const handleSubmit = async () => {
        const finalObject = {
            name: maintanenceMapping.name,
            code: maintanenceMapping.code,
            description: maintanenceMapping.description,
            categoriesReference: maintanenceMapping.categoriesReference.map(category => category._id),
            departmentsReference: maintanenceMapping.departmentsReference.map(department => department._id),
            serviceReference: {
                name: maintanenceMapping.serviceReference.name,
            },
        }

        try {
            await schema.validate(maintanenceMapping, { abortEarly: false });
            setFormErrors({});
            // const response = await PUT(`http://localhost:8001/v1/admin/maintenance-mapping?id=${maintainenceId}`, finalObject, 'Edit Maintenance Mapping')
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/maintenance-mapping?id=${maintainenceId}`, finalObject, 'Edit Maintenance Mapping')
            if (response.status === 200) {
                successToast("Maintenance Mapping Successfully Edited")
            }
            navigate('/admin/settings/maintainence-mapping')
        } catch (error) {
            const errors = {};
            errorToast("Maintenance Mapping Failed")
            error?.inner?.forEach((error) => {
                errors[error.path] = error.message;
            });
            console.log({ errors })

            setFormErrors(errors);
        }
    }

    const handleMaintenaceMapping = (event) => {
        const { name, value, checked } = event.target
        if (name === 'name' || name === 'code' || name === 'description') {
            setMaintanenceMapping((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'categoriesReference' || name === 'departmentsReference') {
            // Determine which options list to filter
            const optionsList = name === 'categoriesReference' ? options?.categories : options?.departments;

            // Filter the relevant options based on selected IDs
            const updatedReferences = optionsList?.filter(option => value.includes(option._id));

            setMaintanenceMapping((prevState) => ({
                ...prevState,
                [name]: updatedReferences, // Set the full objects to the state
            }));
        } else if (name === 'ASSET_MANAGEMENT_API' || name === 'FAULT_REPORT_API') {
            setSelectedCheckBox(event.target.checked ? event.target.name : '');
            setMaintanenceMapping((prevState) => ({
                ...prevState,
                serviceReference: {
                    ...prevState.serviceReference,
                    name: checked ? name : '',
                },
            }));
        }

    }
    return (
        <Box minHeight={'90vh'} p={1} width={'100%'}>
            <Typography
                sx={NotificationsStyles.headingTypo}
            >
                Edit  Maintainence Mapping
            </Typography>
            <Box
                sx={{ ...NotificationsStyles.innerBox, justifyContent: null, mt: 2 }}
            >
                <Stack ml={1} spacing={2} width={'100%'}>
                    <TextInput
                        fullWidth
                        label="Maintenance Mapping Name"
                        name='name'
                        value={maintanenceMapping.name}
                        onChange={handleMaintenaceMapping}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                        inputProps={{ style: { color, bgcolor } }}
                    />
                    <TextInput
                        fullWidth
                        variant='outlined'
                        label="Maintenance Mapping Code"
                        name='code'
                        value={maintanenceMapping.code}
                        onChange={handleMaintenaceMapping}
                        error={!!formErrors.code}
                        helperText={formErrors.code}
                        inputProps={{ style: { color, bgcolor } }}
                    />
                    <TextInput
                        fullWidth
                        label="Maintenance Mapping Description"
                        name='description'
                        value={maintanenceMapping.description}
                        onChange={handleMaintenaceMapping}
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                        inputProps={{ style: { color, bgcolor } }}
                    />
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, color }}>Select a service:</Typography>
                    <Box sx={{ display: 'flex', columnGap: 5 }}>
                        <Box>
                            <CheckBox
                                checkboxLabel="Asset Management"
                                name='ASSET_MANAGEMENT_API'
                                value='ASSET_MANAGEMENT_API'
                                checked={selectedCheckBox === 'ASSET_MANAGEMENT_API' || maintanenceMapping?.serviceReference?.name === 'ASSET_MANAGEMENT_API'}
                                onChange={handleMaintenaceMapping}
                            />
                        </Box>
                        <Box>
                            <CheckBox
                                checkboxLabel="Fault Report"
                                name='FAULT_REPORT_API'
                                value='FAULT_REPORT_API'
                                checked={selectedCheckBox === 'FAULT_REPORT_API' || maintanenceMapping?.serviceReference?.name === 'FAULT_REPORT_API'}
                                onChange={handleMaintenaceMapping}
                            />

                        </Box>
                    </Box>
                    <FormHelperText sx={{ color: 'red !important' }}>{formErrors.serviceReference}</FormHelperText>

                    <TextInput
                        select
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => {
                                const categoriesNames = options?.categories?.filter(category => selected?.indexOf(category?._id) > -1)?.map(category => category?.name)
                                return categoriesNames.join(', ')
                            },
                            style: { color }
                        }}
                        label="Select categories"
                        name='categoriesReference'
                        value={maintanenceMapping?.categoriesReference.map(category => category?._id)}
                        onChange={handleMaintenaceMapping}
                    >
                        {
                            options?.categories?.map(category => <MenuItem key={category?._id} value={category?._id}>
                                <Checkbox
                                    checked={maintanenceMapping?.categoriesReference?.some(ref => ref._id === category._id)}
                                />
                                <ListItemText primary={category?.name} />
                            </MenuItem>)
                        }
                    </TextInput>
                    <TextInput
                        select
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => {
                                const departmentsNames = options?.departments?.filter(department => selected?.indexOf(department?._id) > -1)?.map(department => department?.name)
                                return departmentsNames.join(', ')
                            },
                            style: { color }
                        }}

                        fullWidth
                        label="Select departments"
                        name='departmentsReference'
                        value={maintanenceMapping?.departmentsReference?.map(department => department?._id)}
                        onChange={handleMaintenaceMapping}

                    >
                        {
                            options?.departments?.map(department => <MenuItem key={department?._id} value={department?._id}>
                                <Checkbox checked={maintanenceMapping?.departmentsReference?.some(ref => ref._id === department._id)}
                                />
                                <ListItemText primary={department?.name} />
                            </MenuItem>)
                        }
                    </TextInput>
                    <Box sx={{ display: 'flex', columnGap: 1 }}>
                        <Button sx={{ ...CommonStyles.actionButton, width: '50%', py: 1, bgcolor: 'black', fontSize: '14px' }}
                            onClick={() => { navigate('/admin/settings/maintainence-mapping') }}
                        >Cancel</Button>
                        <Button sx={{ ...CommonStyles.actionButton, width: '50%', py: 1, fontSize: '14px' }}
                            onClick={handleSubmit}
                        >Edit Maintainence Map</Button>
                    </Box>
                </Stack>
            </Box>

        </Box>
    )
}