import { Box, Button, IconButton, Modal, Paper, Stack, Typography, Zoom } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CommonStyles from '../../styles/CommonStyles'
import ReportDateRangeSelector from './ReportDateRangeSelector'
import Loading from '../loading/Loading'
import { CheckCircleOutlineOutlined, CloseOutlined, ErrorOutlineOutlined } from '@mui/icons-material'
import { GET } from '../../helpers/http';
import { errorToast } from '../../helpers/apiToast';
import { convertToXls } from '../../helpers/convertToXls';

const GenerateReportModal = (props) => {

    const themeMode = useSelector(state => state.theme.darkTheme)

    const [isReportGenerated, setIsReportGenerated] = useState("idle")
    const [generatedReport, setGeneratedReport] = useState([])

    const [reportGeneration, setReportGeneration] = useState({ startTime: "", endTime: "" })

    const { closeReportModal, reportModalOpen, body, type } = props

    const color = themeMode ? '#fff' : '#000'

    const bgcolor = !themeMode ? '#fff' : '#333539'

    const modalStyle = {
        width: 'fit-content',
        bgcolor,
        boxShadow: 24,
        p: 2,
    };

    const handleCloseReportModal = () => {
        if (closeReportModal) {
            setIsReportGenerated("idle")
            closeReportModal()
        }
    }

    const handleSetStartTimeEndTime = ({ startTime, endTime }) => {
        setReportGeneration({ startTime: startTime, endTime: endTime })
    }

    const handleGenerateReport = async () => {
        let endPoint = ""
        if (body?.genEmpRep === true) {
            endPoint = `${process.env.REACT_APP_URL}at/v1/report?startDate=${reportGeneration?.startTime}&endDate=${reportGeneration?.endTime}&reportType=single&empCode=${body?.empCode}`
        } else {
            endPoint = `${process.env.REACT_APP_URL}at/v1/report?startDate=${reportGeneration?.startTime}&endDate=${reportGeneration?.endTime}&reportType=general`
        }
        try {
            setIsReportGenerated("pending")
            const response = await GET(endPoint, "Generate Genaral Report")
            if (response?.status === 200) {
                setGeneratedReport(response?.data)
                setIsReportGenerated("fulfilled")
            } else (
                setIsReportGenerated("rejected")
            )
        } catch (error) {
            console.log(error);
            setIsReportGenerated("rejected")
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }

    return (
        <Box>
            <Modal open={reportModalOpen} onClose={handleCloseReportModal} disableScrollLock>
                <Paper sx={{ ...CommonStyles.ModalPaper, ...modalStyle, color }}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h6' mb={2} sx={{ fontSize: '18px', fontWeight: 600, color }}>Generate Report <Typography component={'span'} fontWeight={400} fontSize={'16px'}>(Upto last 90 days)</Typography></Typography>
                        <IconButton sx={{ mb: 2, bgcolor: 'rgba(0,0,0,0.2)' }} size='small' onClick={handleCloseReportModal}>
                            <CloseOutlined sx={{ color }} fontSize='small' />
                        </IconButton>
                    </Box>
                    {
                        isReportGenerated === 'idle' ? <>
                            <Box sx={{ ...CommonStyles.centerDiv }}>
                                <ReportDateRangeSelector handleSetStartTimeEndTime={handleSetStartTimeEndTime} />
                            </Box>
                            {reportGeneration?.error && <Typography sx={{ mt: 1, fontSize: '12px', color: 'red' }}>{reportGeneration?.error}</Typography>}
                            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <Button sx={{ mt: 1, width: '100px', ...CommonStyles.actionButton, textTransform: 'uppercase' }} onClick={handleGenerateReport}>Generate</Button>
                            </Box>
                        </> : isReportGenerated === 'pending' ? <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2} py={2} width={'400px'}>
                            <Typography align='center' fontSize={'16px'}>
                                The report generation is in progress.
                            </Typography>
                            <Typography align='center' fontSize={'16px'}>
                                Please wait until the process completes, and you receive a confirmation or download link.
                            </Typography>
                            <Box sx={{ width: '100%', ...CommonStyles.centerDiv, pt: 1 }}>
                                <Loading height={'100%'} />
                            </Box>
                        </Stack> : isReportGenerated === 'rejected' ? <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                            <Zoom in>
                                <ErrorOutlineOutlined color='error' sx={{ fontSize: '100px' }} />
                            </Zoom>
                            {
                                reportGeneration?.noDataMessage ? <Typography align='center' fontSize={'14px'} p={2} width={'350px'}>
                                    {reportGeneration?.noDataMessage}
                                </Typography> : <>
                                    <Typography align='center' fontSize={'16px'}>
                                        Apologies, but it seems an error occurred while generating your report.
                                    </Typography>
                                    <Typography align='center' fontSize={'16px'}>
                                        Please try again.
                                    </Typography>
                                    <Button sx={{ ...CommonStyles.actionButton }}
                                        onClick={() => setIsReportGenerated('idle')}
                                    >Try again</Button>
                                </>
                            }
                        </Stack> : isReportGenerated === 'fulfilled' ? <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                            <Zoom in>
                                <CheckCircleOutlineOutlined color='success' sx={{ fontSize: '100px' }} />
                            </Zoom>
                            <Typography align='center' fontSize={'16px'} px={2}>
                                Your report is ready.
                            </Typography>
                            <Typography align='center' fontSize={'16px'} px={2}>
                                Please make sure to download your report before closing this modal.
                            </Typography>
                            <Typography align='center' fontSize={'16px'} px={2} >
                                Once closed, you may lose access to the download link.
                            </Typography>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: (type === 'faultReports' || type === 'feedback' || type === 'assetManagement') ? 'space-around' : 'center', pt: 1 }}>
                                <Button sx={{ ...CommonStyles.actionButton }} onClick={() => {
                                    convertToXls(generatedReport, 'Genaral Report');
                                    setIsReportGenerated("idle")
                                    closeReportModal()
                                }}>Download Excel Report</Button>
                            </Box>
                        </Stack> : null
                    }
                </Paper>
            </Modal>
        </Box>
    )
}

export default GenerateReportModal