import newLogo from '../assets/logo/Image.svg'
export const microPhoneLight = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/microphone_light.svg',
    alt: 'microphone light'
}

export const microPhoneDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/microphone_dark.svg',
    alt: 'microphone dark'
}

export const edit_light = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/edit_white.svg',
    alt: 'edit light'
}

export const edit_dark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/edit_dark.svg',
    alt: 'edit dark'
}

export const typing_light = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/typing_white.svg',
    alt: 'typing light'
}

export const typing_dark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/typing_dark.svg',
    alt: 'typing dark'
}

export const chatLogo = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/nhance_icon.png',
    alt: 'chat logo'
}

export const adminConsoleDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/AdminConsoleDark.png',
    alt: 'admin console dark'
};

export const adminConsoleWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/AdminConsoleWhite.png',
    alt: 'admin console white'
};

export const airPressure = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Airpressure.svg',
    alt: 'air pressure'
};

export const assetFaultIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/AssetFaultIcon.svg',
    alt: 'asset fault icon'
};

export const brokenIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/BrokenIcon.png',
    alt: 'broken icon'
};

export const chocolateBoxIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/ChocolateBoxIcon.svg',
    alt: 'chocolate box icon'
};

export const comingSoon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Coming_Soon.svg',
    alt: 'coming soon'
};

export const digitalTwinDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/DigitalTwinDark.png',
    alt: 'digital twin dark'
};

export const digitalTwinWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/DigitalTwinWhite.png',
    alt: 'digital twin white'
};

export const fullscreen = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Fullscreen.svg',
    alt: 'fullscreen'
};

export const fullscreenExit = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/FullscreenExit.svg',
    alt: 'fullscreen exit'
};

export const insights = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Insights.svg',
    alt: 'insights'
};

export const levels = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Levels.svg',
    alt: 'levels'
};

export const lumens = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Lumens.svg',
    alt: 'lumens'
};

export const maintenanceDueAssetMgmt = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/MaintenanceDueAssetMgmt.svg',
    alt: 'maintenance due asset mgmt'
};

export const meetingRoomFloorView = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/MeetingRoomFloorView.png',
    alt: 'meeting room floor view'
};

export const orbitDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Orbit_dark.svg',
    alt: 'orbit dark'
};

// export const settings = {
//     src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Settings.svg',
//     alt: 'settings'
// };

export const tvOc = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/TVOc.svg',
    alt: 'tv oc'
};

export const userManagementWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/UserManagement-White.svg',
    alt: 'user management white'
};

export const userManagement = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/UserManagement.svg',
    alt: 'user management'
};

export const zoom = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Zoom.svg',
    alt: 'zoom'
};

export const addAssetExcel = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/addassetexcel.svg',
    alt: 'add asset excel'
};

export const addAssetExcelWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/addassetexcelwhite.svg',
    alt: 'add asset excel white'
};

export const addAssetForm = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/addassetform.svg',
    alt: 'add asset form'
};

export const addAssetFormWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/addassetformwhite.svg',
    alt: 'add asset form white'
};

export const addBox = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/addbox.svg',
    alt: 'add box'
};

export const alerts = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/alerts.svg',
    alt: 'alerts'
};

export const alertsWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/alertswhite.svg',
    alt: 'alerts white'
};

export const analytics = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/analytics.svg',
    alt: 'analytics'
};

export const analyticsWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/analyticswhite.svg',
    alt: 'analytics white'
};

export const appointments = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/appointments.svg',
    alt: 'appointments'
};

export const appointmentsWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/appointmentswhite.svg',
    alt: 'appointments white'
};

export const aqi = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/aqi.svg',
    alt: 'aqi'
};

export const aqiWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/aqiwhite.svg',
    alt: 'aqi white'
};

export const assetExpiring = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetexpiring.svg',
    alt: 'asset expiring'
};

export const assetExpiringWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetexpiringwhite.svg',
    alt: 'asset expiring white'
};

export const assetList = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetlist.svg',
    alt: 'asset list'
};

export const assetListWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetlistwhite.svg',
    alt: 'asset list white'
};

export const assetMgmt = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetmgmt.svg',
    alt: 'asset mgmt'
};

export const assetMgmtDashboard = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetmgmtDashboard.svg',
    alt: 'asset mgmt dashboard'
};

export const assetMgmtDashboardWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetmgmtDashboardwhite.svg',
    alt: 'asset mgmt dashboard white'
};

export const assetMgmtWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetmgmtwhite.svg',
    alt: 'asset mgmt white'
};

export const assetUnderMaintenance = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetundermaintenance.svg',
    alt: 'asset under maintenance'
};

export const assetUnderMaintenanceWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/assetundermaintenancewhite.svg',
    alt: 'asset under maintenance white'
};

export const autodesk = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/autodesk.svg',
    alt: 'autodesk'
};

export const autodeskWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/autodeskwhite.svg',
    alt: 'autodesk white'
};

export const camera = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/camera.svg',
    alt: 'camera'
};

export const cameraInteractions = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/cameraInteractions.svg',
    alt: 'camera interactions'
};

export const cameraConnected = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/camera_connected.svg',
    alt: 'camera connected'
};

export const cameraConnectedInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/camera_connected_inverted.svg',
    alt: 'camera connected inverted'
};

export const cameraDisconnected = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/camera_disconnected.svg',
    alt: 'camera disconnected'
};

export const cameraDisconnectedInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/camera_disconnected_inverted.svg',
    alt: 'camera disconnected inverted'
};

export const cctv = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/cctv.svg',
    alt: 'cctv'
};

export const cctvWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/cctvwhite.svg',
    alt: 'cctv white'
};

export const close = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/close.svg',
    alt: 'close'
};

export const co2Small = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/co2-small.svg',
    alt: 'co2 small'
};

export const co2 = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/co2.svg',
    alt: 'co2'
};

export const co2Aqi = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/co2aqi.svg',
    alt: 'co2 aqi'
};

export const correct = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/correct.svg',
    alt: 'correct'
};

export const defaultImage = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/default-image.png',
    alt: 'default image'
};

export const defaultAlertsIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/defaultAlertsIcon.png',
    alt: 'default alerts icon'
};

export const desk = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/desk.svg',
    alt: 'desk'
};

export const deskWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/deskwhite.svg',
    alt: 'desk white'
};

export const deviceMgmt = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/devicemgmt.svg',
    alt: 'device mgmt'
};

export const deviceMgmtWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/devicemgmtwhite.svg',
    alt: 'device mgmt white'
};

export const documentBrowser = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/document-browser.svg',
    alt: 'document browser'
};

export const document = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/document.svg',
    alt: 'document'
};

export const documentWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/documentwhite.svg',
    alt: 'document white'
};

export const down = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/down.svg',
    alt: 'down'
};

export const drop = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/drop.svg',
    alt: 'drop'
};

export const dvr = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/dvr.svg',
    alt: 'dvr'
};

export const dvrIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/dvrIcon.svg',
    alt: 'dvr icon'
};

export const dvrInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/dvrInverted.svg',
    alt: 'dvr inverted'
};

export const energy = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/energy.svg',
    alt: 'energy'
};

export const energyWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/energywhite.svg',
    alt: 'energy white'
};

export const explode = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/explode.svg',
    alt: 'explode'
};

export const faultIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/faultIcon.png',
    alt: 'fault icon'
};

export const faultReport = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/faultreport.svg',
    alt: 'fault report'
};

export const faultReportWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/faultreportwhite.svg',
    alt: 'fault report white'
};

export const feedback = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/feedback.svg',
    alt: 'feedback'
};

export const feedbackWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/feedbackwhite.svg',
    alt: 'feedback white'
};

export const folder = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/folder.svg',
    alt: 'folder'
};

export const fpvDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/fpv_dark.svg',
    alt: 'fpv dark'
};

export const greenDot = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/green_dot.png',
    alt: 'green dot'
};

export const hardDrive1 = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hard-drive_1.svg',
    alt: 'hard drive 1'
};

export const hddConnected = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hdd_connected.svg',
    alt: 'hdd connected'
};

export const hddConnectedInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hdd_connected_inverted.svg',
    alt: 'hdd connected inverted'
};

export const hddDisconnected = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hdd_disconnected.svg',
    alt: 'hdd disconnected'
};

export const hddDisconnectedInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hdd_disconnected_inverted.svg',
    alt: 'hdd disconnected inverted'
};

export const heatmap = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/heatmap.svg',
    alt: 'heatmap'
};

export const homeIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/home-icon.svg',
    alt: 'home icon'
};

export const home = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/home.svg',
    alt: 'home'
};

export const homeWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/homewhite.svg',
    alt: 'home white'
};

export const humidity = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/humidity.svg',
    alt: 'humidity'
};

export const kioskConfig = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/kioskconfig.svg',
    alt: 'kiosk config'
};

export const kioskConfigWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/kioskconfigwhite.svg',
    alt: 'kiosk config white'
};

export const lastSync = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/last-sync.svg',
    alt: 'last sync'
};

export const lucideSmilePlus = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/lucide_smile-plus.svg',
    alt: 'lucide smile plus'
};

export const mattReport = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/mattreport.svg',
    alt: 'matt report'
};

export const mattReportWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/mattreportwhite.svg',
    alt: 'matt report white'
};

export const meetingRoom = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/meetingroom.svg',
    alt: 'meeting room'
};

export const meetingRoomWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/meetingroomwhite.svg',
    alt: 'meeting room white'
};

export const menuDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/menu_dark.svg',
    alt: 'menu dark'
};

export const menuWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/menu_white.svg',
    alt: 'menu white'
};

export const modalBrowser = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/modalbroser.svg',
    alt: 'modal browser'
};

export const moveToRoom = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/moveToRoom.svg',
    alt: 'move to room'
};

export const occupancy = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/occupancy.svg',
    alt: 'occupancy'
};

export const occupancyWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/occupancywhite.svg',
    alt: 'occupancy white'
};

export const orangeDot = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/orange_dot.png',
    alt: 'orange dot'
};

export const panDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/pan_dark.svg',
    alt: 'pan dark'
};

export const planeX = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/plane-x.svg',
    alt: 'plane x'
};

export const planeY = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/plane-y.svg',
    alt: 'plane y'
};

export const planeZ = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/plane-z.svg',
    alt: 'plane z'
};

export const poweredByNhance = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/poweredbynhance.png',
    alt: 'powered by nhance'
};

export const profilePicture = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/profilePicture.svg',
    alt: 'profile picture'
};

export const properties = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/properties.svg',
    alt: 'properties'
};

export const radon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/radon.svg',
    alt: 'radon'
};

export const recycleBin = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/recyclebin.svg',
    alt: 'recycle bin'
};

export const redDot = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/red_dot.png',
    alt: 'red dot'
};

export const restroom = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/restroom.svg',
    alt: 'restroom'
};

export const restroomWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/restroomwhite.svg',
    alt: 'restroom white'
};

export const ruler = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/ruler.svg',
    alt: 'ruler'
};

export const sectionAnalysis = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/sectionAnalysis.svg',
    alt: 'section analysis'
};

export const settingsWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/settings-white.svg',
    alt: 'settings white'
};

export const settings = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/settings.svg',
    alt: 'settings'
};

export const settingsWhiteAlt = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/settingswhite.svg',
    alt: 'settings white alt'
};

export const soap = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/soap.svg',
    alt: 'soap'
};

export const splitScreen = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/split-screen.svg',
    alt: 'split screen'
};

export const stamp = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/stamp.svg',
    alt: 'stamp'
};

export const support = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/support.svg',
    alt: 'support'
};

export const supportWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/supportwhite.svg',
    alt: 'support white'
};

export const syncInverted = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/syncInverted.svg',
    alt: 'sync inverted'
};

export const temperature = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/temperature.svg',
    alt: 'temperature'
};

export const temprature = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/temprature.svg',
    alt: 'temprature'
};

export const tissuePaper = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/tissue-paper.svg',
    alt: 'tissue paper'
};

export const tvDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/tv_dark.svg',
    alt: 'tv dark'
};

export const tvDisabled = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/tv_disabled.svg',
    alt: 'tv disabled'
};

export const tvWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/tv_white.svg',
    alt: 'tv white'
};

export const twin = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/twin.svg',
    alt: 'twin'
};

export const twinWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/twinwhite.svg',
    alt: 'twin white'
};

export const typing = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/typing.gif',
    alt: 'typing'
};

export const up = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/up.svg',
    alt: 'up'
};

export const videoDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/videodark.svg',
    alt: 'video dark'
};

export const videoWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/videowhite.svg',
    alt: 'video white'
};

export const visitorMgmt = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/visitormgmt.svg',
    alt: 'visitor mgmt'
};

export const visitorMgmtWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/visitormgmtwhite.svg',
    alt: 'visitor mgmt white'
};

export const visitorsList = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/visitorslist.svg',
    alt: 'visitors list'
};

export const visitorsListWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/visitorslistwhite.svg',
    alt: 'visitors list white'
};

export const wallDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/wallDark.svg',
    alt: 'wall dark'
};

export const wallLight = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/wallLight.svg',
    alt: 'wall light'
};

export const communitiesDark = {
    src: "https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/communitiesDarkIcon.svg",
    alt: "communities dark"
}

export const communitiesLight = {
    src: "https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/communitiesLightIcon.svg",
    alt: "communities light"
}

export const hvac = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hvac.svg',
    alt: 'hvac'
};

export const hvacWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/hvacwhite.svg',
    alt: 'hvac white'
};

export const water = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/water.svg',
    alt: 'water'
};

export const waterWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/waterwhite.svg',
    alt: 'water white'
};

export const defaultMeetingRoomImage = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/meetingroom.jpg',
    alt: 'default meeting room image'
};

export const ppm = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/ppm.svg',
    alt: 'ppm'
}

export const ppmWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/ppmwhite.svg',
    alt: 'ppm white'
}

export const PpmList = {
    src: 'https://twin-development.s3.ap-south-1.amazonaws.com/$2b$10$2qSSN7q2sQI0G2cYx8nAhu__PPMListIcon.svg',
}

export const PpmListWhite = {
    src: 'https://twin-development.s3.ap-south-1.amazonaws.com/$2b$10$PiicmXXrB1.PM9FDH.uHE.__PPMListIconWhite.svg',
}


export const energyAdminDark = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/energyLight.svg',
    alt: 'energy admin white'
}

export const energyAdminWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/energyDark.svg',
    alt: 'energy admin dark'
}

export const attendance = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Attendance.svg',
    alt: 'attendance'
}

export const attendanceWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/Attendance-white.svg',
    alt: 'attendance white'
}

export const assetInsuranceWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/insurance_light.svg',
    alt: 'asset insurance white'
}

export const assetInsurance = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/insurance_Dark.svg',
    alt: 'asset insurance'
}

export const chatBackgroundVideo = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/background-chat-video.mp4',
}

export const change = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/change.svg',
    alt: 'change'
}

export const disabledChangeIcon = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/disabledMenu.svg',
    alt: 'change disabled'
}

export const newConversationLogo = {
    src: newLogo,
    alt: 'new conversation'
}

export const FRDashboardBlack = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/dashboard-black.svg',
}

export const FRDashboardWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/dashboard-white.svg',
}

export const FRMyJobsBlack = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/jobs-black.svg',
}

export const FRMyJobsWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/jobs-white.svg',
}

export const FRFaultListBlack = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/listing-black.svg',
}

export const FRFaultListWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/listing-white.svg',
}

export const FRCalenderBlack = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/calender-black.svg',
}

export const FRCalenderWhite = {
    src: 'https://nhance-assets-public.s3.ap-south-1.amazonaws.com/static-files/calender-white.svg',
}

