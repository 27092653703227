import { Stack, Button } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TablePagination = ({ handleNextPrevBtn, disableNext, disablePrev }) => {

    return (
        <Stack direction="row" gap={2} justifyContent="end" paddingRight="5rem" paddingTop="1rem">
            <Button onClick={() => handleNextPrevBtn("prev")}
                sx={{
                    backgroundColor: "#dc3d28",
                    color: 'white',
                    "&:hover": { backgroundColor: "#dc3d28" }
                }}
                disabled={disablePrev}
            >
                <ArrowBackIcon />
            </Button>
            <Button onClick={() => handleNextPrevBtn("next")}
                sx={{
                    backgroundColor: "#dc3d28",
                    color: 'white',
                    "&:hover": { backgroundColor: "#dc3d28" }
                }}
                disabled={disableNext}>
                <ArrowForwardIcon />
            </Button>
        </Stack>
    )
}

export default TablePagination