import { AddCircleOutline, Delete } from "@mui/icons-material"
import { Avatar, Box, Grid, styled } from "@mui/material"
import Loading from "../../../../components/loading/Loading";
import { ThemeColors } from "../../../../hooks/ThemeColors";
import { useSelector } from "react-redux";
import { useState } from "react";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { DELETE, FILEPOST } from "../../../../helpers/http";
import ReturnMultiTypeMedia from "../../../../../admin/components/media/ReturnMultiMedia"

const CoverPageMedia = ({ formData, setFormData, coverPage, setCoverPage, viewMode }) => {
    const { themeMode } = ThemeColors();
    const fileReadUrl = useSelector((state) => state?.configurations?.fileReadUrl)
    const [uploadingMedia, setUploadingMedia] = useState(false)



    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleImage = async ({ event }) => {
        const fileData = event.target.files[0]
        const formData = new FormData()
        formData.append('file', fileData)
        if (Object.keys(coverPage).length > 0) {
            return errorToast("You Can Upload Only One Video")
        }
        if (fileData && fileData.type.startsWith('video/')) {
            setUploadingMedia(true)
            try {
                const response = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, formData, "Upload File")
                if (response.status === 201) {
                    setUploadingMedia(false)
                    setCoverPage(response.data)
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        coverPageMedia: response.data
                    }))

                }


            } catch (error) {
                errorToast(error?.message || error?.response?.message)
                setUploadingMedia(false)
                setCoverPage({})
            }

        } else {
            errorToast("Cover Page must be  a Video")
            event.target.value = null; // Reset the file input if it's not a video
        }

    }

    const handleDeleteMediaFun = async (ele) => {
        try {
            // const response = await DELETE(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media?uploadId=${ele?.uploadId}`, "Delete File")
            setCoverPage({})
            setFormData((prevFormdata) => (
                { ...prevFormdata, coverPageMedia: {} }
            )
            )
            successToast("Media Deleted Successfully")
        } catch (error) {
            console.log(error)
        }

    }
    return (
        <Box sx={{ height: "8rem", overflow: "auto" }}>
            <Grid container spacing={2} sx={{ height: "8rem", }}>
                <Grid item sm={6} md={4} lg={3} xl={3} >
                    <Box
                        component='label'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                            // ...sx
                        }}>
                        <AddCircleOutline sx={{ fontSize: '3rem' }} />
                        <VisuallyHiddenInput
                            type="file"
                            disabled={viewMode}
                            onChange={(event) => handleImage({ event })}
                        />
                    </Box>
                </Grid>
                {Object.keys(coverPage)?.length > 0 &&
                    <Grid
                        item
                        sm={6} md={4} lg={3} xl={3}
                        sx={{ position: "relative", height: "100%" }}
                    >
                        <ReturnMultiTypeMedia
                            // fitImage="cover" 
                            fileReadUrl={fileReadUrl} media={coverPage} height="100%"
                            sx={{
                                border: '1px solid lightgray',
                                borderRadius: "1rem",
                                // width: "100%",           // Ensures the video spans the full width
                                // height: "auto",           // Adjusts height to maintain aspect ratio
                                // objectFit: "cover",
                            }} />
                        {!viewMode &&
                            <Avatar sx={{
                                position: 'absolute',
                                height: "1.5rem",
                                width: "1.5rem",
                                backgroundColor: "black",
                                right: "-2rem",
                                top: "1.5rem"
                            }}>
                                < Delete
                                    fontSize="small"
                                    diabled={viewMode}
                                    onClick={() => handleDeleteMediaFun(coverPage)}
                                    sx={{ cursor: 'pointer' }} />

                            </Avatar>
                        }
                    </Grid>
                }
                {uploadingMedia && <Grid item sm={6} md={4} lg={3} xl={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                        }}>
                        <Loading height="100%" />
                    </Box>
                </Grid>}
            </Grid>
        </Box >
    )

}
export default CoverPageMedia