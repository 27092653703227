import { Box } from "@mui/material"
import CustomTypography from "../../../../components/texts/CustomTypography"


const StatsNumberCard = ({ data }) => {


    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
            <CustomTypography className="color-gray"
                sx={{ fontSize: "70px", fontWeight: 700 }}>{data}</CustomTypography>
        </Box>
    )
}

export default StatsNumberCard