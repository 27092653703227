import React from 'react'
import { Box, IconButton, Modal as MuiModal, Paper, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ModalStyles from '../../styles/ModalStyles'
import { useSelector } from 'react-redux'

const Modal = (props) => {
    const { closeModal, modalOpen, modalWidth, title, children, height, sx } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    return (
        <MuiModal open={modalOpen} onClose={closeModal}>
            <Paper sx={{ ...ModalStyles.paperContainer, bgcolor, width: modalWidth || ModalStyles.paperContainer.width, height: height || 'auto', ...sx, }} className='bdrRadius4px'>
                <Box sx={ModalStyles.boxContainer}>
                    <Typography sx={{ fontWeight: 600, fontSize: '24px', color }}>{title}</Typography>
                    <IconButton onClick={closeModal}>
                        <CloseOutlinedIcon sx={{ color }} />
                    </IconButton>
                </Box>
                <Box sx={{ m: 2 }}>
                    {children}
                </Box>
            </Paper>
        </MuiModal>
    )
}

export default Modal