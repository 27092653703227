import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Avatar, Box, Button, Grid, Backdrop, useTheme, Typography, Menu, MenuItem, Modal } from '@mui/material'
import { useResetPasswordMutation } from '../../../../redux/features/passwordSlice'
import { switchModes } from '../../../../redux/features/themeSlice'
import { resetUserDetails } from '../../../../redux/features/userSlice'
import { resetBuildings, setSelectedBuildingData, useGetBuildingsQuery } from '../../../../redux/features/buildingsSlice'
import { headerCard } from '../../styles/HeaderStyles'
import { successToast } from '../../helpers/apiToast'
import TextInput from '../../components/formInputs/TextInput'
import { POST } from '../../helpers/http'
import { useFetchAllDashboardTemplatesQuery, useUpdateADashboardTemplatebyTemplateIdMutation } from '../../../../redux/features/services/gridSlice'
import { Contrast } from '@mui/icons-material'
import ImageRenderer from '../../components/image/ImageRenderer'
import { adminConsoleDark, adminConsoleWhite, chocolateBoxIcon, digitalTwinDark, digitalTwinWhite } from '../../../../data/imageUrls'

const Header = () => {
    const location = useLocation()
    const pathname = location?.pathname

    const theme = useTheme()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const themeMode = useSelector((state) => state.theme.darkTheme)

    const user = useSelector((state) => state.user)

    const configurations = useSelector((state) => state.configurations)

    const [open, setOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);

    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)

    const [newPassword, setNewPassword] = useState('')

    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordError, setPasswordError] = useState('')

    const [adminConsoleAnchorEl, setAdminConsoleAnchorEl] = useState(null)

    const [resetPassword] = useResetPasswordMutation()

    const menuOpen = Boolean(anchorEl);

    const adminConsoleMenuOpen = Boolean(adminConsoleAnchorEl);

    const color = themeMode ? '#fff' : '#000'

    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const boxShadowColor = themeMode ? '#545454' : '#d2dae2'

    const selectedDashboardTemplate = useSelector(state => state.grid.selectedDashboardTemplate?.data)

    const buildId = localStorage.getItem('bid')

    const buildings = useSelector((state) => state.buildings.buildings)
    const foundBuilding = buildings.find((ele) => ele._id === buildId)

    useGetBuildingsQuery()

    const [updateADashboardTemplatebyTemplateId] = useUpdateADashboardTemplatebyTemplateIdMutation()

    useEffect(() => {
        if (foundBuilding?.name) {
            dispatch(setSelectedBuildingData(foundBuilding))
        }
    }, [foundBuilding?.name])

    useFetchAllDashboardTemplatesQuery()

    useEffect(() => {
        if (selectedDashboardTemplate?.themeUsed) {
            dispatch(switchModes(selectedDashboardTemplate?.themeUsed))
        }
    }, [dispatch, selectedDashboardTemplate?.themeUsed])

    const handlePasswordInputChange = (e) => {
        if (e.target.name === 'newPassword') {
            setNewPassword(e.target.value)
        }
        if (e.target.name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAdminConsoleMenuClose = () => {
        setAdminConsoleAnchorEl(null);
    };

    const handleCloseResetPasswordModal = () => {
        setResetPasswordModalOpen(false)
        setPasswordError('')
        setNewPassword('')
        setConfirmPassword('')
        handleMenuClose()
    }

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChocolateIconClick = (event) => {
        setAdminConsoleAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen(!open)
        handleMenuClose()
    }

    const submitPassword = async () => {
        if (newPassword?.trim()?.length === 0 || confirmPassword?.trim()?.length === 0) {
            setPasswordError('Passwords cannot be empty')
        }
        else if (newPassword?.length < 8) {
            setPasswordError('Password must be at least 8 characters')
        }
        else if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match')
        }
        else if (newPassword?.length >= 8 && newPassword === confirmPassword) {
            setPasswordError('')
            try {
                const response = await resetPassword(newPassword)?.unwrap()
                successToast(response.message)
                handleCloseResetPasswordModal()
            } catch (error) {
                console.log({ error })
            }
        }
    }

    const onKeyDownConfirmPassword = (e) => {
        if (e.key === 'Enter') {
            submitPassword()
        }
    }

    const handleLogout = async () => {
        await POST(`${process.env.REACT_APP_BASE_URL}um/v1/user/logout`, {}, 'user logout')
        localStorage.clear()
        dispatch(resetUserDetails())
        dispatch(resetBuildings())
        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${user?.email}`
    }

    const handleAdminDashboardClick = () => {
        navigate('/admin/usermanagement')
    }

    const handleDigitalTwinClick = () => {
        navigate('/')
    }

    const handleToggleTheme = async (event) => {
        if (selectedDashboardTemplate?.themeUsed) {
            dispatch(switchModes(selectedDashboardTemplate?.themeUsed));
            const newDashboardTemplate = structuredClone(selectedDashboardTemplate)
            newDashboardTemplate.themeUsed = themeMode ? 'light' : 'dark'
            delete newDashboardTemplate?._id;

            try {
                await updateADashboardTemplatebyTemplateId({ id: selectedDashboardTemplate?._id, data: newDashboardTemplate })?.unwrap()
            } catch (error) {
                console.log({ error })
            }
        }
    };


    return (
        <AppBar
            elevation={0}
            component="nav"
            position="sticky"
            sx={{
                height: '72px',
                boxShadow: `0px 4px 6px ${boxShadowColor}`,
                background: themeMode ? '#282a2e' : '#fff',
                zIndex: theme.zIndex.drawer + 1,
            }}
        >
            <Box sx={headerCard.header}>
                <Grid container>
                    <Grid item xs={3} md={6} sx={headerCard.headerGrid}>
                        <Box sx={headerCard.box}>
                            <ImageRenderer src={chocolateBoxIcon.src} alt={chocolateBoxIcon.alt} height={'72px'} width={'72px'} style={{ cursor: user?.role === 'admin' ? "pointer" : null }} onClick={(e) => { if (user?.role === 'admin') handleChocolateIconClick(e) }} />
                            {(configurations?.logo?.dark || configurations?.logo?.light) && (
                                <img
                                    src={`${themeMode ? configurations?.logo?.dark : configurations?.logo?.light
                                        }`}
                                    alt="logo"
                                    height="25px"
                                    style={{
                                        marginLeft: 20,
                                        paddingRight: 20,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/')}
                                />
                            )}

                        </Box>
                    </Grid>
                    <Grid item xs={9} md={6} sx={headerCard.buildingGrid} columnGap={2}>

                        <Avatar className={`avataricon cursorPtr ${themeMode ? "darkicon" : null} `}>
                            <Contrast onClick={handleToggleTheme} />
                        </Avatar>
                        <Avatar sx={{ ...headerCard.avatarIcon, mr: 1, cursor: 'pointer' }} onClick={handleAvatarClick}>
                            {user.fullName && user?.fullName[0]}
                        </Avatar>
                        <Menu
                            anchorEl={adminConsoleAnchorEl}
                            open={adminConsoleMenuOpen}
                            onClose={handleAdminConsoleMenuClose}
                            sx={{
                                ".MuiMenu-paper": {
                                    borderRadius: '16px', backgroundColor: bgcolor,
                                    boxShadow: '0px 0px 4px #808080'
                                }
                            }}
                            disableScrollLock
                        >
                            <Box sx={{ p: 2, display: 'flex', columnGap: 2, }} >
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                                    {/* <img
                                        src={themeMode ? DigitalTwinWhite : DigitalTwinDark}
                                        alt=''
                                        height={'72px'}
                                        width={'72px'}
                                        style={{
                                            border: !pathname?.includes('admin') ? '4px solid #dc4d28' : '0.5px solid grey',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleDigitalTwinClick}
                                    /> */}
                                    <ImageRenderer
                                        src={themeMode ? digitalTwinWhite.src : digitalTwinDark.src}
                                        alt={themeMode ? digitalTwinWhite.alt : digitalTwinDark.alt}
                                        height={'72px'}
                                        width={'72px'}
                                        style={{
                                            border: !pathname?.includes('admin') ? '4px solid #dc4d28' : '0.5px solid grey',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleDigitalTwinClick} />
                                    <Typography textAlign='center' mt={1} color={color}>Digital Twin</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                                    <img
                                        src={themeMode ? adminConsoleWhite.src : adminConsoleDark.src}
                                        alt={themeMode ? adminConsoleWhite.alt : adminConsoleDark.alt}
                                        height={'72px'}
                                        width={'72px'}
                                        style={{
                                            border: pathname?.includes('admin') ? '4px solid #dc4d28' : '0.5px solid grey',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleAdminDashboardClick}
                                    />
                                    <Typography textAlign='center' mt={1} color={color}>Admin Console</Typography>
                                </Box>
                            </Box>
                        </Menu>
                        <Menu
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            disableScrollLock
                            sx={{
                                '& .MuiMenu-paper': {
                                    color: themeMode ? '#fff' : '#000',
                                    backgroundColor: themeMode ? '#282a2e' : '#fff',
                                    boxShadow: '0px 0px 4px #808080'
                                }
                            }}
                        >
                            {user.isPasswordResetEnabled && <MenuItem onClick={() => { setResetPasswordModalOpen(true); handleMenuClose() }}>Reset Password</MenuItem>}
                            <MenuItem onClick={handleToggle}>Logout</MenuItem>
                        </Menu>
                        <Modal
                            open={resetPasswordModalOpen}
                            onClose={handleCloseResetPasswordModal}
                        >
                            <Box sx={{ ...headerCard.resetPasswordModal, bgcolor }}>
                                <Typography sx={{ mb: 2, fontSize: '18px', fontWeight: 700 }}>Reset Password</Typography>
                                <TextInput
                                    placeholder="Enter new password"
                                    variant='outlined'
                                    type='password'
                                    width={'100%'}
                                    size={'small'}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={handlePasswordInputChange}
                                />
                                <TextInput
                                    placeholder="Confirm new password"
                                    variant='outlined'
                                    type='password'
                                    width={'100%'}
                                    size={'small'}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handlePasswordInputChange}
                                    onKeyDown={onKeyDownConfirmPassword}
                                    sx={{ my: 2 }}
                                />
                                {
                                    passwordError && <Typography sx={{ color: 'red', fontSize: '12px', mb: 1 }}>
                                        {passwordError}
                                    </Typography>
                                }
                                <Box>
                                    <Button variant='contained' sx={headerCard.changePasswordButton} onClick={submitPassword}>Change Password</Button>
                                </Box>
                            </Box>
                        </Modal>
                        {/* <Avatar sx={[headerCard.avatarIcon, { mr: 1 }]}>
                            <IconButton onClick={handleToggle} size="smaller">
                                <LogoutIcon />
                            </IconButton>
                        </Avatar> */}
                    </Grid>
                </Grid>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <Box>
                    <Grid container sx={headerCard.logoutCard}>
                        <Grid item xs={12} align="center">
                            Are you sure?
                        </Grid>
                        <Grid item xs={6} align="center">
                            <Button
                                variant="small"
                                sx={headerCard.signoutButton}
                                onClick={handleLogout}
                            >
                                Sign Out
                            </Button>
                        </Grid>
                        <Grid item xs={6} align="center">
                            <Button
                                variant="small"
                                sx={headerCard.cancelButton}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            </Backdrop>
        </AppBar>
    )
}

export default Header
