import { errorToast } from "../../helpers/apiToast"
import { GET, PUT } from "../../helpers/http";

export const responseVote = async ({ threadId, responseId, userResponse }) => {
    try {
        const response = await PUT(`${process.env.REACT_APP_MAVERICK_URL}chat/${threadId}/${responseId}`, { response: userResponse }, 'Chat');
        if (response?.status === 200) {
            console.log({ response });
        }
    } catch (error) {
        errorToast(error?.response?.data?.message || error?.message);
    }
};

export const getFeedBackData = async ({ pageNumber }) => {
    try {
        const result = await GET(`${process.env.REACT_APP_MAVERICK_URL}admin/feedbacks/1`, 'feedback');
        if (result?.status === 200) {
            return result?.data;
        }
    } catch (error) {
        errorToast(error?.response?.data?.message || error?.message);
    }
}

export const getUsersList = async () => {
    try {
        const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/list`, 'Get User List')
        if (result?.status === 200) {
            return result?.data;
        }
    } catch (error) {
        errorToast(error?.response?.data?.message || error?.message)
    }
}