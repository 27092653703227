import { Box, Button, Checkbox, IconButton, ListItemText, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckBox from '../../../../../components/formInputs/CheckBox'
import TextInput from '../../../../../components/formInputs/TextInput'
import CommonStyles from '../../../../../styles/CommonStyles'
import { GET, POST } from '../../../../../helpers/http'
import NotificationsStyles from '../../../../../styles/NotificationsStyles'
import { DeleteOutlined } from '@mui/icons-material'
import { errorToast, successToast } from '../../../../../helpers/apiToast'
import { useNavigate } from 'react-router-dom'
import Modal from '../../../../../components/modal/Modal'
import { useSelector } from 'react-redux'

const NotifyForm = (props) => {
    const navigate = useNavigate()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const [options, setOptions] = useState({
        categories: [],
        departments: [],
        groups: [],
        locations: [],
        buildings: {
            selectedLocation: '',
            buildingsList: [],
            selectedBuildings: [],
            allBuildings: []
        }
    })

    const [notifications, setNotifications] = useState({
        name: '',
        code: '',
        selectedServices: [],
        servicesReference: [],
        categoriesReference: [],
        departmentsReference: [],
        groupsReference: [],
        locationsGeoReference: []     //[{locationReference: '',buildingsReference: []}]
    })

    const [addGroupWarningModal, setAddGroupWarningModal] = useState(false)

    const handleOpenAddGroupWarningModal = () => {
        setAddGroupWarningModal(true)
    }

    const handleCloseAddGroupWarningModal = () => {
        setAddGroupWarningModal(false)
    }

    const handleNotificationChange = (event) => {
        const { name, value, checked } = event.target
        if (name === 'name' || name === "code") {
            setNotifications(prevState => {
                return {
                    ...prevState,
                    [name]: value
                }
            })
        }
        else if (name === 'ASSET_MANAGEMENT_API') {
            setNotifications(prevState => {
                if (checked) {
                    if (!prevState.selectedServices.includes('ASSET_MANAGEMENT_API')) {
                        return {
                            ...prevState,
                            selectedServices: [...prevState.selectedServices, name]
                        }
                    }
                    return prevState
                }
                const servicesReference = prevState.servicesReference?.filter((item) => item?.name !== 'ASSET_MANAGEMENT_API')
                return {
                    ...prevState,
                    selectedServices: prevState.selectedServices.filter((item) => item !== name),
                    servicesReference
                }
            })
        }
        else if (name === 'AMServiceEmail') {
            setNotifications(prevState => {
                if (checked) {
                    const newServicesReference = {
                        name: 'ASSET_MANAGEMENT_API',
                        notificationType: 'EMAIL',
                        isActive: true
                    }
                    return {
                        ...prevState,
                        servicesReference: [
                            ...prevState.servicesReference,
                            newServicesReference
                        ]
                    }
                }
                const servicesReference = prevState.servicesReference?.filter((item) => !(item?.name === 'ASSET_MANAGEMENT_API' && item?.notificationType === 'EMAIL'))
                return {
                    ...prevState,
                    servicesReference
                }
            })
        }
        else if (name === 'AMServiceSMS') {
            setNotifications(prevState => {
                if (checked) {
                    const newServicesReference = {
                        name: 'ASSET_MANAGEMENT_API',
                        notificationType: 'SMS',
                        isActive: true
                    }
                    return {
                        ...prevState,
                        servicesReference: [
                            ...prevState.servicesReference,
                            newServicesReference
                        ]
                    }
                }
                const servicesReference = prevState.servicesReference?.filter((item) => !(item?.name === 'ASSET_MANAGEMENT_API' && item?.notificationType === 'SMS'))
                return {
                    ...prevState,
                    servicesReference
                }
            })
        }
        else if (name === 'FAULT_REPORT_API') {
            setNotifications(prevState => {
                if (checked) {
                    if (!prevState.selectedServices.includes('FAULT_REPORT_API')) {
                        return {
                            ...prevState,
                            selectedServices: [...prevState.selectedServices, name]
                        }
                    }
                    return prevState
                }
                const servicesReference = prevState.servicesReference?.filter((item) => item?.name !== 'FAULT_REPORT_API')
                return {
                    ...prevState,
                    selectedServices: prevState.selectedServices.filter((item) => item !== name),
                    servicesReference
                }
            })
        }
        else if (name === 'FRServiceEmail') {
            setNotifications(prevState => {
                if (checked) {
                    const newServicesReference = {
                        name: 'FAULT_REPORT_API',
                        notificationType: 'EMAIL',
                        isActive: true
                    }
                    return {
                        ...prevState,
                        servicesReference: [
                            ...prevState.servicesReference,
                            newServicesReference
                        ]
                    }
                }
                const servicesReference = prevState.servicesReference?.filter((item) => !(item?.name === 'FAULT_REPORT_API' && item?.notificationType === 'EMAIL'))
                return {
                    ...prevState,
                    servicesReference
                }
            })
        }
        else if (name === 'FRServiceSMS') {
            setNotifications(prevState => {
                if (checked) {
                    const newServicesReference = {
                        name: 'FAULT_REPORT_API',
                        notificationType: 'SMS',
                        isActive: true
                    }
                    return {
                        ...prevState,
                        servicesReference: [
                            ...prevState.servicesReference,
                            newServicesReference
                        ]
                    }
                }
                const servicesReference = prevState.servicesReference?.filter((item) => !(item?.name === 'FAULT_REPORT_API' && item?.notificationType === 'SMS'))
                return {
                    ...prevState,
                    servicesReference
                }
            })
        }
        else if (name === 'categoriesReference' || name === "departmentsReference" || name === "groupsReference") {
            setNotifications(prevState => {
                return {
                    ...prevState,
                    [name]: typeof value === 'string' ? value.split(',') : value
                }
            })
        }
        else if (name === 'locationReference') {
            setOptions(prevState => {
                return {
                    ...prevState,
                    buildings: {
                        ...prevState.buildings,
                        selectedLocation: value
                    }
                }
            })
        }
        else if (name === 'buildingsReference') {
            setOptions(prevState => {
                return {
                    ...prevState,
                    buildings: {
                        ...prevState.buildings,
                        selectedBuildings: typeof value === 'string' ? value.split(',') : value
                    }
                }
            })
        }
    }

    const handleAddToBuildingsList = () => {
        setNotifications(prevState => {
            const existingLocation = prevState.locationsGeoReference?.find((item) => item.locationReference === options.buildings?.selectedLocation)
            if (existingLocation?.locationReference) {
                return {
                    ...prevState,
                    locationsGeoReference: prevState.locationsGeoReference?.map((item) => {
                        if (item?.locationReference === options.buildings?.selectedLocation) {
                            return {
                                ...item,
                                buildingsReference: [...options.buildings?.selectedBuildings]
                            }
                        }
                        return item
                    })
                }
            }
            return {
                ...prevState,
                locationsGeoReference: [...prevState.locationsGeoReference, {
                    locationReference: options.buildings?.selectedLocation,
                    buildingsReference: options.buildings?.selectedBuildings
                }]
            }
        })
        setOptions(prevState => {
            return {
                ...prevState,
                buildings: {
                    ...prevState.buildings,
                    selectedLocation: '',
                    selectedBuildings: []
                }
            }
        })
    }

    const clearLocationsGeoReference = (location) => {
        setNotifications(prevState => {
            const locationsGeoReference = prevState.locationsGeoReference?.filter((item) => item?.locationReference !== location?.locationReference)
            return {
                ...prevState,
                locationsGeoReference
            }
        })
    }

    const handleNotify = async () => {
        const data = structuredClone(notifications)
        delete data.selectedServices
        if (data.departmentsReference?.length > 0) {
            delete data.groupsReference
            delete data.locationsGeoReference
        }
        else if (data.groupsReference?.length > 0) {
            delete data.departmentsReference
        }

        if (data?.name?.trim()?.length < 3) {
            errorToast('Notification Mapping Name shoud be min. 3 characters')
        }
        else if (data?.name?.trim()?.length > 256) {
            errorToast('Notification Mapping Name should be within 256 characters')
        }
        else if (data?.code?.trim()?.length < 3) {
            errorToast('Notification Mapping Code shoud be min. 3 characters')
        }
        else if (data?.code?.trim()?.length > 256) {
            errorToast('Notification Mapping Code shoud be within 256 characters')
        }
        else if (data?.categoriesReference?.length === 0) {
            errorToast('Please select at least one category')
        }
        else if (data?.servicesReference?.length === 0) {
            errorToast('Please select at least one service')
        }
        else if (data?.servicesReference?.some(item => item?.notificationType === '')) {
            errorToast('Please select at least one notification type')
        }
        else if (data?.groupsReference?.length === 0 && data?.departmentsReference?.length === 0) {
            errorToast('Either a group or a department must be selected')
        }
        else if (data?.groupsReference?.length > 0 && (data?.locationsGeoReference?.length === 0 || data?.locationsGeoReference?.some(item => item?.locationReference === ''))) {
            errorToast('Please select a location')
        }
        else if (data?.groupsReference?.length > 0 && data?.locationsGeoReference?.length > 0 && data?.locationsGeoReference?.some(item => item?.buildingsReference?.length === 0)) {
            errorToast('Please select at least one building')
        }
        else if (
            data?.name?.trim()?.length >= 3 &&
            data?.name?.trim()?.length <= 256 &&
            data?.code?.trim()?.length >= 3 &&
            data?.code?.trim()?.length <= 256 &&
            data?.categoriesReference?.length > 0 &&
            data?.servicesReference?.length > 0 &&
            (!(data?.servicesReference?.some(item => item.notificationType === ''))) &&
            (data?.groupsReference?.length > 0 || data?.departmentsReference?.length > 0)
        ) {

            try {
                const { data: responseData } = await POST(`${process.env.REACT_APP_BASE_URL}um/v1/admin/notification-mappings`, data, 'Create Notification Mapping')
                if (responseData?._id) {
                    successToast(`Notification Mapping with name ${responseData?.name} added succesfully`)
                    navigate('/admin/settings/notifications')
                }
            } catch (error) {
                console.log({ error })
            }
        }
    }

    useEffect(() => {
        async function fetchCategories() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, 'Get Categories') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`
                setOptions(prevState => ({ ...prevState, categories: data }))
            } catch (error) {
                console.log({ error })
            }
        }
        async function fetchDepartments() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Get Departments') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`
                setOptions(prevState => ({ ...prevState, departments: data }))
            } catch (error) {
                console.log({ error })
            }
        }
        async function fetchGroups() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/group/list`, 'Get Groups') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/group/list`
                setOptions(prevState => ({ ...prevState, groups: data }))
            } catch (error) {
                console.log({ error })
            }
        }

        fetchCategories()
        fetchDepartments()
        fetchGroups()
    }, [])

    useEffect(() => {
        async function fetchLocations() {
            try {
                const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/location/list`, 'Get Locations') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/location/list`
                setOptions(prevState => ({ ...prevState, locations: data }))
            } catch (error) {
                console.log({ error })
            }
        }

        if (notifications?.groupsReference?.length > 0) {
            fetchLocations()
        }
    }, [notifications?.groupsReference?.length])

    useEffect(() => {
        async function fetchBuildings() {
            try {
                if (options?.buildings?.selectedLocation) {
                    const { data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list/?location=${options?.buildings?.selectedLocation}`, 'Get Buildings') //`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list`
                    setOptions(prevState => {
                        return {
                            ...prevState,
                            buildings: {
                                ...prevState.buildings,
                                buildingsList: data,
                                allBuildings: [...prevState.buildings?.allBuildings, ...data]
                            }
                        }
                    })
                }
            } catch (error) {
                console.log({ error })
            }
        }
        fetchBuildings()
    }, [options?.buildings?.selectedLocation])

    return (
        <Box minHeight={'90vh'} p={1} width={'100%'}>
            <Typography
                sx={NotificationsStyles.headingTypo}
            >
                Add Notifications Mapping
            </Typography>
            <Box
                sx={{ ...NotificationsStyles.innerBox, justifyContent: null, mt: 2 }}
            >
                <Stack ml={1} spacing={2} width={'100%'}>
                    <TextInput
                        fullWidth
                        value={notifications?.name}
                        onChange={handleNotificationChange}
                        label="Notification Mapping Name"
                        name='name'
                        inputProps={{ style: { color, bgcolor } }}
                    />
                    <TextInput
                        fullWidth
                        variant='outlined'
                        value={notifications?.code}
                        onChange={handleNotificationChange}
                        label="Notification Mapping Code"
                        name='code'
                        inputProps={{ style: { color, bgcolor } }}
                    />
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, color }}>Select a service:</Typography>
                    <Box sx={{ display: 'flex', columnGap: 5 }}>
                        <Box>
                            <Box>
                                <CheckBox
                                    onChange={handleNotificationChange}
                                    checkboxLabel="Asset Management"
                                    name='ASSET_MANAGEMENT_API'
                                />
                            </Box>
                            {
                                notifications?.selectedServices?.includes("ASSET_MANAGEMENT_API") && <>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600, my: 1, color }}>Select notification type:</Typography>
                                    <CheckBox
                                        onChange={handleNotificationChange}
                                        checkboxLabel="EMAIL"
                                        name='AMServiceEmail'
                                    />
                                    <CheckBox
                                        onChange={handleNotificationChange}
                                        checkboxLabel="SMS"
                                        name='AMServiceSMS'
                                    />
                                </>
                            }
                        </Box>
                        <Box>
                            <Box>
                                <CheckBox
                                    onChange={handleNotificationChange}
                                    checkboxLabel="Fault Reports"
                                    name='FAULT_REPORT_API'
                                />
                            </Box>
                            {
                                notifications?.selectedServices?.includes("FAULT_REPORT_API") && <>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600, my: 1, color }}>Select notification type:</Typography>
                                    <CheckBox
                                        onChange={handleNotificationChange}
                                        checkboxLabel="EMAIL"
                                        name='FRServiceEmail'
                                    />
                                    <CheckBox
                                        onChange={handleNotificationChange}
                                        checkboxLabel="SMS"
                                        name='FRServiceSMS'
                                    />
                                </>
                            }
                        </Box>
                    </Box>
                    <TextInput
                        select
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => {
                                const categoriesNames = options?.categories?.filter(category => selected?.indexOf(category?._id) > -1)?.map(category => category?.name)
                                return categoriesNames.join(', ')
                            },
                            style: { color }
                        }}
                        value={notifications?.categoriesReference}
                        onChange={handleNotificationChange}
                        label="Select categories"
                        name='categoriesReference'
                    >
                        {
                            options?.categories?.map(category => <MenuItem key={category?._id} value={category?._id}>
                                <Checkbox checked={notifications?.categoriesReference?.indexOf(category?._id) > -1} />
                                <ListItemText primary={category?.name} />
                            </MenuItem>)
                        }
                    </TextInput>
                    <Stack sx={{ p: 2, bgcolor, borderRadius: '8px', boxShadow: 1 }}>
                        <TextInput
                            select
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => {
                                    const departmentsNames = options?.departments?.filter(department => selected?.indexOf(department?._id) > -1)?.map(department => department?.name)
                                    return departmentsNames.join(', ')
                                },
                                style: { color }
                            }}
                            value={notifications?.departmentsReference}
                            onChange={handleNotificationChange}
                            fullWidth
                            label="Select departments"
                            name='departmentsReference'
                            disabled={notifications?.groupsReference?.length > 0}
                        >
                            {
                                options?.departments?.map(department => <MenuItem key={department?._id} value={department?._id}>
                                    <Checkbox checked={notifications?.departmentsReference?.indexOf(department?._id) > -1} />
                                    <ListItemText primary={department?.name} />
                                </MenuItem>)
                            }
                        </TextInput>
                        <Typography align='center' my={2} color={color}>OR</Typography>
                        <Stack sx={{ border: notifications?.departmentsReference?.length > 0 ? 0 : '0.5px dashed', p: notifications?.departmentsReference?.length > 0 ? 0 : 2, borderRadius: '8px' }}>
                            <TextInput
                                select
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => {
                                        const groupsNames = options?.groups?.filter(group => selected?.indexOf(group?._id) > -1)?.map(group => group?.name)
                                        return groupsNames.join(', ')
                                    },
                                    style: { color }
                                }}
                                value={notifications?.groupsReference}
                                onChange={handleNotificationChange}
                                fullWidth
                                label="Select groups"
                                name='groupsReference'
                                disabled={notifications?.departmentsReference?.length > 0}
                            >
                                {
                                    options?.groups?.map(group => <MenuItem key={group?._id} value={group?._id}>
                                        <Checkbox checked={notifications?.groupsReference?.indexOf(group?._id) > -1} />
                                        <ListItemText primary={group?.name} />
                                    </MenuItem>)
                                }
                            </TextInput>
                            {
                                notifications?.departmentsReference?.length === 0 && <>
                                    <Typography align='center' my={2}>OR</Typography>
                                    <Button sx={{ ...CommonStyles.actionButton, fontSize: '14px', width: 'fit-content', alignSelf: 'center' }} onClick={handleOpenAddGroupWarningModal}>Create a new group</Button>
                                </>
                            }
                            <Modal
                                modalOpen={addGroupWarningModal}
                                closeModal={handleCloseAddGroupWarningModal}
                                modalWidth='500px'
                            >
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color }}>By creating a group, you will be redirected to the groups section. You will lose your unsaved existing notification mapping data. Would you like to continue creating group?</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                        <Button sx={{ ...CommonStyles.actionButton, bgcolor: 'black', fontSize: '14px' }} onClick={handleCloseAddGroupWarningModal}>Cancel</Button>
                                        <Button sx={{ ...CommonStyles.actionButton, fontSize: '14px' }} onClick={() => { handleCloseAddGroupWarningModal(); navigate('/admin/settings/groups') }}>Continue</Button>
                                    </Box>
                                </Box>
                            </Modal>
                            {
                                notifications?.groupsReference?.length > 0 && <>
                                    <TextInput
                                        select
                                        value={options?.buildings?.selectedLocation}
                                        onChange={handleNotificationChange}
                                        fullWidth
                                        label="Select location"
                                        name='locationReference'
                                        sx={{ mt: 2 }}
                                        SelectProps={{
                                            style: { color },
                                        }}
                                    >
                                        {options?.locations?.map(location => <MenuItem key={location?._id} value={location?._id}>{location?.name}</MenuItem>)}
                                    </TextInput>
                                    {
                                        options?.buildings?.selectedLocation && <>
                                            <TextInput
                                                select
                                                SelectProps={{
                                                    multiple: true,
                                                    renderValue: (selected) => {
                                                        const buildingsNames = options?.buildings?.buildingsList?.filter(building => selected?.indexOf(building?._id) > -1)?.map(building => building?.name)
                                                        return buildingsNames.join(', ')
                                                    },
                                                    style: { color }
                                                }}
                                                value={options?.buildings?.selectedBuildings}
                                                onChange={handleNotificationChange}
                                                fullWidth
                                                label="Select buildings"
                                                name='buildingsReference'
                                                sx={{ mt: 2 }}
                                            >
                                                {
                                                    options?.buildings?.buildingsList?.map(building => <MenuItem key={building?._id} value={building?._id}>
                                                        <Checkbox checked={options?.buildings?.selectedBuildings?.indexOf(building?._id) > -1} />
                                                        <ListItemText primary={building?.name} />
                                                    </MenuItem>)
                                                }
                                            </TextInput>
                                            {options?.buildings?.selectedBuildings?.length > 0 && <Button sx={{ ...CommonStyles.actionButton, width: 'fit-content', alignSelf: 'center', fontSize: '14px', mt: 2 }} onClick={handleAddToBuildingsList}>Add to buildings list</Button>}
                                        </>
                                    }
                                </>
                            }
                            {
                                notifications?.locationsGeoReference?.length > 0 &&
                                <Box my={2}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: 2 }}>Selected Buildings</Typography>
                                    <TableContainer >
                                        <Table sx={{
                                            width: "100%",
                                            "& .MuiTableCell-head": {
                                                fontWeight: 700,
                                                bgcolor,
                                                color
                                                // minWidth: 500
                                            },
                                            "& .MuiTableCell-body": {
                                                color,
                                                bgcolor
                                                // minWidth: 500
                                            }
                                        }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Location</TableCell>
                                                    <TableCell>Buildings</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    notifications?.locationsGeoReference?.map((location, i) => (
                                                        <TableRow
                                                            key={i}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {options?.locations?.find(l => l?._id === location?.locationReference)?.name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    location.buildingsReference.map(id => {
                                                                        const building = options?.buildings?.allBuildings?.find(b => b._id === id);
                                                                        return building?.name || '';
                                                                    }).join(', ')
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={() => clearLocationsGeoReference(location)}>
                                                                    <DeleteOutlined color='error' />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            }
                        </Stack>
                    </Stack>
                    <Box sx={{ display: 'flex', columnGap: 1 }}>
                        <Button sx={{ ...CommonStyles.actionButton, width: '50%', py: 1, bgcolor: 'black', fontSize: '14px' }} onClick={() => navigate('/admin/settings/notifications')}>Cancel</Button>
                        <Button sx={{ ...CommonStyles.actionButton, width: '50%', py: 1, fontSize: '14px' }} onClick={handleNotify}>Create Notifications Map</Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default NotifyForm