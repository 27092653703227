import { Box } from '@mui/material'
import React, { useContext } from 'react'
import ImageRenderer from '../../../../components/image/ImageRenderer'
import { microPhoneLight, typing_light } from '../../../../../../data/imageUrls'
import { ChatContext } from '../../context/ChatContext'

const VoiceCommand = () => {
    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { voiceCommand } = chatInfo;
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            onClick={() => {
                voiceCommand ? chatDispatch({ type: "SET_VOICE_RUNNING", payload: false }) :
                    chatDispatch({ type: "SET_VOICE_RUNNING", payload: true })
            }}
        >
            <ImageRenderer
                src={!voiceCommand ? microPhoneLight.src : typing_light.src}
                alt={!voiceCommand ? microPhoneLight.alt : typing_light.alt}
                sx={{ border: '1px solid #b5b5b5', borderRadius: '100%', backgroundColor: '#b5b5b5', padding: '0.5rem', fontSize: '1rem' }} />
        </Box>
    )
}

export default VoiceCommand;