import React from "react";
import { Drawer, Box, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import CustomTypography from "../../components/texts/CustomTypography";
import { ThemeColors } from "../../hooks/ThemeColors";


const MUIRightDrawer = ({ children, open, toggleDrawer, title }) => {
    const { textColor, background_color } = ThemeColors()
    return (
        <Box>
            <Drawer anchor="right" open={open} sx={{
                '& .MuiDrawer-paper': {
                    background: background_color(),
                }
            }} onClose={() => toggleDrawer(false)}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem' }}>
                    <CustomTypography variant='h5' sx={{ fontWeight: 'bold' }}>{title}</CustomTypography>
                    <IconButton onClick={() => toggleDrawer(false)}>
                        <CloseOutlined sx={{ color: textColor() }} />
                    </IconButton>
                </Box>
                {children}
            </Drawer>
        </Box>
    );
}
export default MUIRightDrawer;