import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal/Modal'
import FormInput from '../../../components/formInputs/FormInput'
import { Button, FormControlLabel, Stack, Switch } from '@mui/material'
import { POST, PUT } from '../../../helpers/http'
import { successToast } from '../../../helpers/apiToast'

const CatalogEditForm = (props) => {
    const { openEditCatalogModal, handleCloseEditCatalogModal = () => { }, editingCatalog, setSensorCatalogs = () => { } } = props

    const [sensorCatalog, setSensorCatalog] = useState({
        sensorDisplayName: editingCatalog?.sensorDisplayName || '',
        sensorDeviceRef: editingCatalog?.sensor_id || '',
        description: editingCatalog?.description || '',
        readsAreaConsumption: !!editingCatalog?.readsAreaConsumption || false,
        areaInSqft: editingCatalog?.areaInSqft || null
    })

    useEffect(() => {
        setSensorCatalog({
            sensorDisplayName: editingCatalog?.sensorDisplayName || '',
            sensorDeviceRef: editingCatalog?.sensor_id || '',
            description: editingCatalog?.description || '',
            readsAreaConsumption: !!editingCatalog?.readsAreaConsumption || false,
            areaInSqft: editingCatalog?.areaInSqft || null
        })
    }, [editingCatalog?.areaInSqft, editingCatalog?.description, editingCatalog?.readsAreaConsumption, editingCatalog?.sensorDisplayName, editingCatalog?.sensor_id])

    const [sensorCatalogError, setSensorCatalogError] = useState({
        sensorDisplayName: '',
        areaInSqft: ''
    })

    const handleChange = (event) => {
        const { name, value, checked } = event.target

        setSensorCatalogError({
            sensorDisplayName: '',
            areaInSqft: ''
        })

        if (name === 'readsAreaConsumption') {
            setSensorCatalog({
                ...sensorCatalog,
                [name]: checked,
                areaInSqft: checked ? sensorCatalog?.areaInSqft : null
            })
        }
        else {
            setSensorCatalog({
                ...sensorCatalog,
                [name]: value,
            })
        }
    }

    const createSensorCatalog = async () => {
        const selectedBuilding = localStorage.getItem('bid')
        if (selectedBuilding) {
            try {
                const { data } = await POST(`${process.env.REACT_APP_URL}v1/energysensorcatalogs?bid=${selectedBuilding}`, [sensorCatalog], "Create Sensor Catalog")
                setSensorCatalogs(prevData => {
                    const updatedData = prevData?.map(sensor => {
                        const newSensor = { ...data }
                        newSensor.id = newSensor?.sensor_id
                        return sensor?.sensor_id === data?.sensor_id ? newSensor : sensor
                    })
                    return updatedData
                })
                successToast("Sensor Catalog added successfully.")
                handleCloseEditCatalogModal()
            } catch (error) {
                console.log({ error })
            }
        }
    }

    const updateSensorCatalog = async () => {
        const selectedBuilding = localStorage.getItem('bid')
        if (selectedBuilding) {
            try {
                const { data = {} } = await PUT(`${process.env.REACT_APP_URL}v1/energysensorcatalogs/${editingCatalog?.catalog_id}?bid=${selectedBuilding}`, sensorCatalog, "Update Sensor Catalog")
                setSensorCatalogs(prevData => {
                    const updatedData = prevData?.map(sensor => {
                        const newSensor = { ...data }
                        newSensor.id = newSensor?.sensor_id
                        return sensor?.sensor_id === data?.sensor_id ? newSensor : sensor
                    })
                    return updatedData
                })
                successToast("Sensor Catalog updated successfully.")
                handleCloseEditCatalogModal()
            } catch (error) {
                console.log({ error })
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (sensorCatalog.sensorDisplayName?.trim()?.length < 3) {
            setSensorCatalogError({
                ...sensorCatalogError,
                sensorDisplayName: 'Name must be min. 3 characters'
            })
        } else if (sensorCatalog.sensorDisplayName?.trim()?.length > 40) {
            setSensorCatalogError({
                ...sensorCatalogError,
                sensorDisplayName: 'Name must be max. 40 characters'
            })
        } else if ((sensorCatalog.readsAreaConsumption && sensorCatalog.areaInSqft <= 0) || sensorCatalog.areaInSqft == null) {
            setSensorCatalogError({
                ...sensorCatalogError,
                areaInSqft: 'Area must be positive'
            })
        }

        if (
            sensorCatalog.sensorDisplayName?.trim()?.length >= 3 &&
            sensorCatalog.sensorDisplayName?.trim()?.length <= 40 &&
            (sensorCatalog.readsAreaConsumption ? sensorCatalog.areaInSqft > 0 : true)
        ) {
            if (editingCatalog?.catalog_id) {
                updateSensorCatalog()
            } else {
                createSensorCatalog()
            }
        }
    }

    return (
        <Modal
            modalOpen={openEditCatalogModal}
            closeModal={handleCloseEditCatalogModal}
            title={'Update Sensor Catalog'}
        >
            <Stack
                spacing={2}
                component={'form'}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <FormInput
                    label='Catalog Name'
                    size='small'
                    name='sensorDisplayName'
                    value={sensorCatalog.sensorDisplayName}
                    onChange={handleChange}
                    error={!!sensorCatalogError.sensorDisplayName}
                    helperText={sensorCatalogError.sensorDisplayName}
                />
                <FormInput
                    label='Catalog Description'
                    size='small'
                    name='description'
                    multiline
                    rows={4}
                    value={sensorCatalog.description}
                    onChange={handleChange}
                />
                <FormControlLabel
                    control={
                        <Switch checked={sensorCatalog.readsAreaConsumption} onChange={handleChange} name='readsAreaConsumption' />
                    }
                    label="Reads Area Consumption"
                />
                {sensorCatalog.readsAreaConsumption && <FormInput
                    type='number'
                    label='Area (Sq. ft)'
                    size='small'
                    name='areaInSqft'
                    value={sensorCatalog.areaInSqft}
                    onChange={handleChange}
                    error={!!sensorCatalogError.areaInSqft}
                    helperText={sensorCatalogError.areaInSqft}
                />}
                <Button className='actionButton' type='submit'>Update</Button>
            </Stack>
        </Modal>
    )
}

export default CatalogEditForm