import Grid from "@mui/material/Unstable_Grid2"
import { ThemeColors } from "../../../../../hooks/ThemeColors";
import PostStatusBar from "./PostStatusBar";
import { useEffect, useState } from "react";
import { GET } from "../../../../../helpers/http";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Post from "./Post";
import Comment from "./Comment";
import { errorToast } from "../../../../../helpers/apiToast"
import Reactions from "./Reactions";
import { Box, Breadcrumbs, Card, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ManageCommPostContainer = (props) => {
	const { background_color,textColor } = ThemeColors();
	const location = useLocation()
	const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [postData, setPostData] = useState({})

    const getPostDetail = async () => {
        try {
			const endPointPost = `${process.env.REACT_APP_URL}comms/v1/admin/item?type=${"post"}&id=${location?.state?.id}`
            const postResult = await GET(endPointPost, "Fetch Single Post")
            setPostData(postResult?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const postDataUpdater = (data) => {
        setPostData(data)
    }

    //initialCall
    useEffect(() => {
        getPostDetail()
	}, [])

	function handleClickCommunity(event) {
		event.preventDefault();
		navigate(`/admin/communities/view/${postData?.community}`, { state: { id: postData?.community } })
	}

	function handleClickAll(event) {
		event.preventDefault();
		navigate(`/admin/communities`)
	}
	
	const breadcrumbs = [
		<Typography className="cursorPtr"
			onClick={handleClickAll}
			sx={{ color: textColor }}
		>
			Communities
		</Typography>,
		<Typography className="cursorPtr"
			onClick={handleClickCommunity}
			sx={{ color: textColor }}
		>
			{location?.state?.name?.length > 15 ? `${location?.state?.name?.slice(0, 15)}...` : location?.state?.name}
		</Typography>,
		<Typography key="3">
			{`Post by ${postData?.user?.fullName}`}
		</Typography>
	];


    return (
		<Box className="flex flexDirectionCol width100 padright20px" sx={{ height: "89.3vh" }}>
			<Card
				sx={{ backgroundColor: background_color }}
				className="width100 boxShadowNone height12vh flex flexDirectionCol flexJustifyAround mrgnbtm6 mrgntop20 pad8x16"
			>
				<Breadcrumbs
					separator={<NavigateNextIcon sx={{ color: textColor }} fontSize="small" />}
					aria-label="breadcrumb"
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Typography className="fontweight700 font26">
					Manage Community
				</Typography>
				<Typography className="fontweight400 font20">
					Review Post
				</Typography>
			</Card>
            <Grid container spacing={2}
                sx={{ width: "inherit", marginX: "0px", marginTop: "0.5rem" }}>
                <Grid xs={12} px={0} >
                    <PostStatusBar loading={loading} data={postData} postDataUpdater={postDataUpdater} />
                </Grid>
                <Grid xs={5} md={12} lg={5}
                    sx={{ paddingLeft: "0px", height: "inherit" }} >
                    <Post loading={loading} data={postData} />
                </Grid>
                <Grid xs={4} md={6} lg={4}
                    sx={{ height: "inherit" }} >
                    <Comment id={location?.state?.id} communityId={postData?.community} />
                </Grid>
                <Grid xs={3} md={6} lg={3}
                    sx={{ paddingRight: "0px", height: "inherit" }} >
					<Reactions id={location?.state?.id} communityId={postData?.community} postloading={loading} />
                </Grid>
            </Grid >
        </Box>
    )
}

export default ManageCommPostContainer