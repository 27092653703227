import React, { useState } from 'react'
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import TabContainerStyles from '../../styles/TabStyles';

const TabContainer = (props) => {
    const { tabList, tabPanels, childrens } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#000' : '#fff'
    const paperBorderColor = themeMode ? '#444A67' : '#D2DAE2'


    const paperStyle = {
        bgcolor,
        //border: `1px solid ${paperBorderColor}`,
        boxShadow: 0
    }

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
		setValue(newValue);
		props.onChange(newValue)
    };

    const colorFunc = (index) => {
        if (themeMode) {
            if (value === index) {
                return 'white'
            }
            else {
                return "hsl(215, 15%, 65%)"
            }
        }
        else {
            if (value === index) {
                return "hsl(200, 10%, 4%)"
            }
            else {
                return "hsl(215, 15%, 65%)"
            }
        }
	}
	

    return (
        <Box sx={TabContainerStyles.boxContainer} typography={'body1'} >
            <Box sx={TabContainerStyles.boxItem} >
				<Paper elevation={0} sx={{ ...TabContainerStyles.paperContainer, bgcolor, ...paperStyle, }} className='theme-component-background boxShadowNone bdrRadius4px'> {/*borderRadius: `0px 0px 4px 4px`*/}
                    <Tabs onChange={handleChange} sx={TabContainerStyles.tabs} textColor="inherit" value={value}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#D97D54',
                                marginBottom: 1,
                            },
                        }}>
                        {
                            tabList.map((title, index) => {
                                return (<Tab key={index} sx={{ ...TabContainerStyles.tabList, color: colorFunc(index), fontWeight: value === index ? 'bold' : '' }} label={title} value={index} />
                                )
                            })
                        }
                    </Tabs>
                    {childrens}
                </Paper>
            </Box>
            {
                Array.isArray(tabPanels) ?
                    <>
                        {value === 0 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[0]}
                            </Box>
                        )}
                        {value === 1 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[1]}
                            </Box>
                        )}
                        {value === 2 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[2]}
                            </Box>
                        )}
                        {value === 3 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[3]}
                            </Box>
                        )}
                        {value === 4 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[4]}
                            </Box>
                        )}
                        {value === 5 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[5]}
                            </Box>
                        )}
                    </>
                    : tabPanels()
            }
        </Box>
    )
}

export default TabContainer