import { Box, Grid, IconButton, InputAdornment, MenuItem, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NotificationsStyles from '../../../styles/NotificationsStyles'
import CommonStyles from '../../../styles/CommonStyles'
import { useSelector } from 'react-redux'
import { DeleteOutlined, EditOutlined, SearchOutlined, VisibilityOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { GET } from '../../../helpers/http'
import validSearchTerm from '../../../helpers/validSearchTerm'
import Datatable from '../../../components/datatable/Datatable'
import DeleteNotificationMappingModal from '../../../features/settings/notification/components/modals/DeleteNoficationMappingModal'
import ViewNotificationMappingModal from '../../../features/settings/notification/components/modals/ViewNotificationMappingModal'
import CustomButton from '../../../components/button/CustomButton'

const Notifications = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const navigate = useNavigate()
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const [timer, setTimer] = useState(0)
    const [notificationMappings, setNotificationMappings] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const [openDeleteMappingModal, setOpenDeleteMappingModal] = useState(false)
    const [openViewMappingModal, setOpenViewMappingModal] = useState(false)
    const [selectedMapping, setSelectedMapping] = useState({})
    const [selectedViewMapping, setSelectedViewMapping] = useState({})


    const handleAddNewCategoryMappingClick = () => {
        navigate('/admin/settings/notifications/add')
    }

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        const endpoint = searchTerm?.length >= 2 ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/notification-mappings/list?searchTerm=${validSearchTerm(searchTerm)?.trim()}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/notification-mappings/list`
        debounce(() => {
            GET(endpoint, 'Fetch Notification Mappings')
                .then((response) => {
                    if (response?.status === 200) {
                        setNotificationMappings(response?.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 500)
    }, [searchTerm])

    const onSearchChange = (e) => {
        setSearchTerm(e.target?.value)
    }

    const deleteClickHandler = (mapping) => {
        setSelectedMapping(mapping)
        setOpenDeleteMappingModal(true)
    }

    const viewClickHandler = (mapping) => {
        setSelectedViewMapping(mapping)
        setOpenViewMappingModal(true)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteMappingModal(false)
    }

    const handleCloseViewModal = () => {
        setOpenViewMappingModal(false)
    }

    const handleEditCategoryClick = (mappingId) => {
        navigate(`/admin/settings/notifications/edit/${mappingId}`)
    }

    const columns = [
        {
            field: 'service',
            headerName: 'Service',
            width: 250,
            renderCell: (cellValues) => {
                const services = cellValues?.row?.servicesReference?.map(service => `${service?.name} (${service?.notificationType})`).join(', ')
                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row?.servicesReference?.map((service, i) => {
                                        return <MenuItem key={i}>{`${service?.name} (${service?.notificationType})`}</MenuItem>
                                    })
                                }
                            </Box>
                        </>
                    }
                >
                    {services}
                </Tooltip>
            },
        },
        {
            field: 'name',
            headerName: 'Notification Mapping Name',
            width: 200,
        },
        {
            field: 'code',
            headerName: 'Notification Mapping Code',
            width: 200,
        },
        {
            field: 'categories',
            headerName: 'Categories',
            width: 250,
            renderCell: (cellValues) => {
                const categories = cellValues?.row?.categoriesReference?.map(category => category?.name).join(', ')
                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row?.categoriesReference?.map((category, i) => {
                                        return <MenuItem key={i}>{category?.name}</MenuItem>
                                    })
                                }
                            </Box>
                        </>
                    }
                >
                    {categories}
                </Tooltip>
            },
        },
        {
            field: 'departments',
            headerName: 'Departments',
            width: 250,
            renderCell: (cellValues) => {
                const departments = cellValues?.row?.departmentsReference?.map(department => department?.name).join(', ')
                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row?.departmentsReference?.map((department, i) => {
                                        return <MenuItem key={i}>{department?.name}</MenuItem>
                                    })
                                }
                            </Box>
                        </>
                    }
                >
                    {departments}
                </Tooltip>
            },
        },
        {
            field: 'groups',
            headerName: 'Groups',
            width: 250,
            renderCell: (cellValues) => {
                const groups = cellValues?.row?.groupsReference?.map(group => group?.name).join(', ')
                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row?.groupsReference?.map((group, i) => {
                                        return <MenuItem key={i}>{group?.name}</MenuItem>
                                    })
                                }
                            </Box>
                        </>
                    }
                >
                    {groups}
                </Tooltip>
            },
        },
        {
            field: 'locations',
            headerName: 'Locations',
            width: 250,
            renderCell: (cellValues) => {
                let locations = ''
                const locationsArr = cellValues?.row?.locationsGeoReference
                if (locationsArr?.length > 0) locations = locationsArr?.map(ele => ele?.locationReference?.name).join(', ')
                return locations
            },
        },
        {
            field: 'buildings',
            headerName: 'Buildings',
            width: 250,
            renderCell: (cellValues) => {
                let buildings = ''
                const locationsArr = cellValues?.row?.locationsGeoReference
                if (locationsArr?.length > 0) buildings = locationsArr?.map(location => {
                    return location?.buildingsReference?.map(building => building?.name)?.join(', ')
                })?.join(', ')
                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row?.locationsGeoReference?.map(location => {
                                        return location?.buildingsReference?.map(((building, i) => <MenuItem key={i}>{building?.name}</MenuItem>))
                                    })
                                }
                            </Box>
                        </>
                    }
                >
                    {buildings}
                </Tooltip>
            },
        },
        {
            field: 'Actions',
            headerAlign: 'center',
            align: 'right',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Tooltip title='EDIT' placement='top'>
                            <IconButton
                                onClick={() => handleEditCategoryClick(cellValues?.row?._id)}
                            >
                                <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;EDIT
                            </Typography> */}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='VIEW' placement='top'>
                            <IconButton
                                onClick={() => viewClickHandler(cellValues?.row)}
                            >
                                <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;VIEW
                            </Typography> */}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => deleteClickHandler(cellValues?.row)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                    &nbsp;DELETE
                                </Typography> */}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ]
    return (
        <Box minHeight={'90vh'} p={1} width={'82%'}>
            <Typography
                sx={NotificationsStyles.headingTypo}
            >
                Notifications
            </Typography>
            <Box
                sx={NotificationsStyles.innerBox}
            >
                <Paper
                    sx={{
                        ...NotificationsStyles.paperContainer,
                        bgcolor,
                    }}
                    className='theme-border theme-component-background bdrRadius4px'
                >
                    <Grid container direction={'row-reverse'} spacing={1}>
                        <Grid item xs={1.5} border={'solid transparent'}> {/* for button alignment */}
                            <CustomButton textContent={'Add New Mapping'} className={'btnFontSize'} onClick={handleAddNewCategoryMappingClick} sx={{ ...NotificationsStyles.addButton }} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={searchTerm}
                                onChange={onSearchChange}
                                sx={NotificationsStyles.searchContainer}
                                placeholder="Search a Notification by name or code.."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlined sx={{ color: '#616161' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        // Customize the border color
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the focused border color
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the input text color
                                        '& .MuiInputBase-input': {
                                            color: themeMode ? '#fff' : '#000'
                                        },
                                        // Customize the cursor color
                                        '& .MuiInputBase-input::selection': {
                                            backgroundColor: '#808080',
                                        },
                                        // Customize the border color on hover
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Datatable
                    height={650}
                    checkBox={false}
                    rows={notificationMappings}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                />
            </Box>
            <DeleteNotificationMappingModal
                openDeleteMappingModal={openDeleteMappingModal}
                handleCloseDeleteModal={handleCloseDeleteModal}
                selectedMapping={selectedMapping}
                notificationMappings={notificationMappings}
                setNotificationMappings={setNotificationMappings}
            />
            <ViewNotificationMappingModal
                openViewMappingModal={openViewMappingModal}
                handleCloseViewModal={handleCloseViewModal}
                selectedViewMapping={selectedViewMapping}
            />
        </Box >
    )
}

export default Notifications