import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../../facilityManager/helpers/http'

// export const asyncFetchBookingsHistory = createAsyncThunk('deskBookingHistory/asyncFetchBookingsHistory', async (bid) => {
//     try {
//         const response = await GET(`${process.env.REACT_APP_BASE_URL}db/v1/bookings/history?building-id=${bid}`, 'Desk booking history')
//         if (response?.status === 200) {
//             return response?.data
//         }
//     } catch (error) {
//         console.log(error)
//     }
// })

export const asyncFetchTotalOccupancyData = createAsyncThunk('meetingroom/asyncFetchTotalOccupancy', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s1/occupancy?bid=${bid}`, 'Desk TotalBooked Data')
        console.log({ response })
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const initialState = {
    mrOccupancy: {
        isLoaded: false,
        data: [],
        margins: [10, 0, 25, 30]
    }
}

const mrOccupancySlice = createSlice({
    name: 'mrOccupancySlice',
    initialState,
    reducers: {
        updateSSETataMeetingRoomsChartData: (state, action) => {
            state.mrOccupancy.data = [...state.mrOccupancy.data, [action.payload?.updatedTime, action.payload?.count]]
        }
    },
    extraReducers(builder) {
        builder.addCase(asyncFetchTotalOccupancyData.fulfilled, (state, action) => {
            state.mrOccupancy.isLoaded = true
            state.mrOccupancy.data = action.payload
        })
    }

})

export const { updateSSETataMeetingRoomsChartData } = mrOccupancySlice.actions

export default mrOccupancySlice.reducer
