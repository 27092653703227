import React from 'react'
import { Box, Button, Grid } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SecondaryStyles from '../../styles/SecondaryStyles';
import CustomTypography from '../texts/CustomTypography';
import { NavigateBefore } from '@mui/icons-material';

const Secondary = (props) => {
    const { title, returnPage, returnRoute, children, className } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#000' : 'transparent' //#191e30

    const navigate = useNavigate()
    const handleReturnClick = () => {
        navigate(returnRoute)
    }

    return (
        <Box sx={{ bgcolor, ...SecondaryStyles?.boxContainer }}>
            <Grid container direction='row' width={'100%'}>
                <Grid item xs={12} pl={2}>
                    {/* <Paper elevation={0} sx={{ borderRadius: `4px 4px 0px 0px`, bgcolor }}> */}
                    {(returnRoute.includes('/ppm') || returnRoute.includes('/assetmanagement') || returnRoute.includes('/digitaldocumentation')) && <Button sx={{ ...SecondaryStyles?.returnButton, color: themeMode ? 'white' : '#191E30' }} onClick={handleReturnClick}> <NavigateBefore /> {`${returnPage}`}</Button>}
                    <CustomTypography sx={{ ...SecondaryStyles?.title, color: themeMode ? 'white' : '#191E30' }} className={className && className}>
                        {title}
                    </CustomTypography>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box >
    )
}

export default Secondary