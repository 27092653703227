export const PPMListReducer = (state, action) => {
    switch (action.type) {
        case 'list': {
            return { isLoaded: action.isLoaded, data: [...action.payload] }
        }
        case 'taskStatusUpdate': {
            return { isLoaded: action.isLoaded, data: state.data.map(ele => ele._id === action.payload._id ? { ...ele, taskStatus: action.payload.taskStatus, statusOptions: action.payload.statusOptions } : ele) }
        }
        default: return state
    }
}