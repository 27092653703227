import { LinearProgress, Backdrop, Typography, Stack } from "@mui/material"
import { Button } from "@mui/material"

const DVRBackdrop = (props) => {
    const { loading, message, cancelDVRCheck } = props

    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "rgba(0,0,0,0.8)",
                padding: "0px",
                margin: "0px"
            }}
            open={loading}
        >
            <Stack direction="column" justifyContent='center' width="15rem">
                <Typography className="color-white" sx={{ marginBottom: "0.5rem", color: "#42a5f5" }}>{message}</Typography>
                <LinearProgress color="warning" />
                {cancelDVRCheck && <Button onClick={cancelDVRCheck}>Cancel</Button>}
            </Stack>
        </Backdrop >
    )

}

export default DVRBackdrop