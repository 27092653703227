import { Avatar, Box, Button, Card, Chip, Skeleton, Typography } from "@mui/material"
import postStyle from "../../../../../styles/wall/contentReviewBody/PostStyle"
import { ThemeColors } from "../../../../../hooks/ThemeColors"
import ImageRenderer from "../../../../../components/image/ImageRenderer"
import { profilePicture } from "../../../../../../../data/imageUrls"
import { useSelector } from "react-redux"
import { useState } from "react"
import Carousel from "react-material-ui-carousel"
import ReturnMultiMedia from "../../../../../components/media/ReturnMultiMedia"
//icons
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import moment from "moment"

const Post = ({ loading, data }) => {
    const { background_color } = ThemeColors()
    const [readmore, setReadmore] = useState(false)
    const communitiesFileReadUrl = useSelector(state => state.configurations?.communitiesFileReadUrl)

    return (
        <>
            <Card sx={{ ...postStyle.card, backgroundColor: background_color }}>
                <Typography variant="h6">Post</Typography>
                {!loading
                    ?
                    <>
                        <Box sx={{ ...postStyle.profileCard }}>
                            <>
                                {
                                    data?.user?.profilePictureUrl !== "" ?
                                        <Avatar alt={`${data?.user?.fullName}'s img`} src={`${communitiesFileReadUrl}${data?.user?.profilePictureUrl}`} /> :
                                        <Avatar>
                                            <ImageRenderer src={profilePicture?.src} alt={profilePicture?.alt} />
                                        </Avatar>
                                }
                            </>
                            <Box>
                                <Typography sx={{ fontWeight: "600" }}>
                                    Posted by {data?.user?.fullName}
                                    <Chip
                                        sx={{ marginLeft: "1rem" }}
                                        size="small"
                                        label={
                                            <Box
                                                sx={{ ...postStyle.flagCountBox }}>
                                                <FlagOutlinedIcon
                                                    sx={{ color: "#ef5350", height: "20px" }} />
                                                {data?.flagCount}
                                            </Box>
                                        } />
                                </Typography>
                                {
                                    data?.user?.designation ?
                                        <Typography sx={{ fontSize: '13px' }}>{data?.user?.designation}
                                        </Typography>
                                        :
                                        <Typography sx={{ fontSize: '13px' }}>Designation</Typography>
                                }
                            </Box>
                        </Box >
                        <Box
                            sx={{
                                overflow: "auto", maxHeight: "49vh", '&::-webkit-scrollbar': {
                                    width: '0.4em',
                                    borderRadius: '8px',
                                    opacity: 0
                                },
                                "&:hover::-webkit-scrollbar": {
                                    opacity: 1
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    borderRadius: '8px',
                                },
                                '&:hover::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)', // Adjusted color when hovered
                                },
                            }}>
                            <Box sx={{ paddingY: "0.5rem", paddingRight: "0.5rem" }}>
                                {!readmore ?
                                    <Typography sx={{ textAlign: "justify" }}>
                                        {<Typography sx={{ fontSize: "14px" }} >
                                            {data?.description?.slice(0, 230) + " "}
                                            {(data?.description?.length > 260) &&
                                                <Box
                                                    className="cursorPtr txtTransformLow fontweight600" component="span"
                                                    onClick={() => setReadmore(!readmore)} size="small">
                                                    <i>...Read more</i>
                                                </Box>}
                                        </Typography>}
                                    </Typography>
                                    :
                                    <Typography sx={{ textAlign: "justify" }} >
                                        {<Box
                                            sx={{ fontSize: "14px" }}
                                        >{data?.description} {readmore &&
                                            <i
                                                className="cursorPtr fontweight600 txtTransformLow"
                                                onClick={() => setReadmore(!readmore)}
                                            > ...read less
                                            </i>}
                                        </Box>}
                                    </Typography>
                                }
                            </Box>
                            <Box key={data?._id} sx={{ ...postStyle.mediaBox }}>
                                {data?.media?.length !== 0 ?
                                    <Carousel
                                        autoPlay={false}
                                        stopAutoPlayOnHover={false}
                                        animation='fade'
                                        navButtonsWrapperProps={{
                                            style: {
                                                height: "50px",
                                                marginTop: "15vh"
                                            }
                                        }}
                                        navButtonsProps={{
                                            style: {
                                                backgroundColor: "#ef5350"
                                            }
                                        }}
                                        sx={{ ...postStyle.mediaBoxCarousel }}>
                                        {data?.media?.map((item, i) => <ReturnMultiMedia media={item} />)}
                                    </Carousel>
                                    :
                                    <Typography variant="h6" sx={{ ...postStyle.noMediaTypo }}>
                                        No media
                                    </Typography>
                                }
                            </Box>
                        </Box>
                        <Box sx={{ ...postStyle.reactionStatusBox }}>
                            <Box className="color-grey" sx={{ ...postStyle.leftBarBox }}>
                                <Box sx={{ ...postStyle.reactionTypo }}>
                                    <ThumbUpOffAltIcon />
                                    Reaction ({data?.reactionsCount?.all})
                                </Box>
                                <Box sx={{ ...postStyle.reactionTypo }}>
                                    <ChatBubbleOutlineOutlinedIcon />
                                    Comments({data?.commentsCount})
                                </Box>
                            </Box>
                            <Box className="color-grey">
                                {moment(data?.createdAt).fromNow()}
                            </Box>
                        </Box>
                    </>
                    :
                    <Box className="fxWidth100 height100p flex flexDirectionCol gap24" >
                        <Box className="flex flexDirectionRow padtop20">
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            <Box className="flex flexDirectionCol">
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="20vw"
                                    className="mrgnleft16 mrgnbtm8"
                                />
                                <Skeleton animation="wave" height={20} className="mrgnleft16 width40" />
                            </Box>
                        </Box>
                        <>
                            <Skeleton animation="wave" height={20} />
                            <Skeleton animation="wave" height={20} />
                        </>
                        <Skeleton
                            className="height34vh bdrRadius8px"
                            animation="wave" variant="rectangular" />
                        <Box className="flex flexJustifyBetween">
                            <Skeleton variant="text" className="width30" />
                            <Skeleton variant="text" className="width10 mrgnright16" />
                        </Box>
                    </Box>}
            </Card >
        </>
    )
}

export default Post