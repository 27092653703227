import React, { memo } from 'react';
import { Box } from '@mui/material';
import Secondary from '../../../components/secondary/Secondary';
import { useSelector } from 'react-redux';
import UnderMaintenance from '../../../components/underMaintenance/UnderMaintenance';
import FRDashboard from '../../../features/faultReports/newFaultReports/Dashboard';

const NewFaultReports = ({ height }) => {
    const underMaintenance = useSelector(state => state.configurations.underMaintenance.faultReports2)
    return (
        <Box width={"100%"} >
            <Secondary
                title={"Fault Reporting"}
                returnPage={"Dashboard"}
                returnRoute={"/"}
                children={underMaintenance ? <UnderMaintenance /> : <FRDashboard />}
                className='mrgn0'
            />
        </Box>
    )
}

export default memo(NewFaultReports)
