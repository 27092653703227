import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { dropdownFloorsSort } from '../../../components/facilityManager/helpers/dropdownFloorsSort'
import { errorHandler } from '../../../components/facilityManager/helpers/http'
import { SensorsValueColor } from '../../../components/facilityManager/helpers/aqiLeafletColorsValue'
import { aqiColors } from '../../../components/facilityManager/helpers/aqiColors'

const aqiFeaturesOrder = ["Temperature", "CO2", "Humidity", "TVOC", "Air Pressure", "Radon", "Illuminance"];
const aqiFeaturesChartOrder = ["temperature", "co2", "humidity", "voc", "air_pressure", "radon", "illuminance"]

const floorInitialState = (floor, areaId, areaName, subarea) => {
    return {
        isLoaded: false,
        error: "",
        floor,
        areaId,
        areaName,
        subarea,
        data: [],
    }
}

const fetchAqiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAqiDashboardData: builder.query({
            query: (queryParams) => ({
                url: 'twin/v1/aqi/s1/data',
                method: 'GET',
                credentials: "include",
                params: queryParams
            }),
            transformResponse: responseData => {
                if (responseData) {
                    const data = responseData?.map(ele => {
                        return {
                            ...ele,
                            areaData: ele?.areaData?.map(param => {
                                return {
                                    ...param,
                                    color: aqiColors(param?.index, param?.value, param?.isLatest)?.[param?.index]
                                }
                            })
                        }
                    })
                    return data
                }
            },
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'AQI Dashboard Carousel Data')
            },
            providesTags: ['aqi']
        }),
        fetchAqiSecondaryOptions: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/aqi/s2/options?floors=true&bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: responseData => {
                const floors = responseData?.floors
                const subAreas = responseData?.subAreas
                const areasList = responseData?.areasList
                const parameters = responseData?.parametersAvailable

                const sortedFloors = Object.entries(areasList).sort(([floorA], [floorB]) => {
                    if (floorA === "Ground Floor") {
                        return -1 // floorA comes first
                    }
                    if (floorB === "Ground Floor") {
                        return 1 // floorB comes first
                    }
                    return floorA.localeCompare(floorB)
                })

                const newSortedFloors = {}

                sortedFloors?.forEach(ele => {
                    newSortedFloors[ele[0]] = ele[1]
                })

                dropdownFloorsSort(floors)

                const dynamicAreaDetails = Object.values(newSortedFloors)[0]
                const dynamicAreaName = Object.keys(newSortedFloors)[0]

                const newFloorsData = []

                dynamicAreaDetails?.forEach(area => {
                    newFloorsData.push(floorInitialState(dynamicAreaName, area.id, area.name, area.subarea))
                })

                return {
                    newFloors: {
                        isLoaded: true,
                        subareas: subAreas,
                        selectedFloor: floors?.[0]?.name,
                        selectedFloorId: floors?.[0]?.id,
                        parameters,
                        floors,
                        areasList
                    },
                    newFloorsData
                }
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'AQI Secondary Options')
            },
            providesTags: ['aqi']
        }),
        fetchAqiSecondarySelectedFloorData: builder.query({
            query: ({ area, bid }) => ({
                url: `twin/v1/aqi/s2/data/${area.areaId}?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData, meta, arg) => {
                const modifiedResponse = responseData?.map(ele => {
                    const label = ele?.hasOwnProperty('air_pressure') ? "air_pressure" :
                        ele?.hasOwnProperty('co2') ? "co2" :
                            ele?.hasOwnProperty('humidity') ? "humidity" :
                                ele?.hasOwnProperty('illuminance') ? "illuminance" :
                                    ele?.hasOwnProperty('radon') ? "radon" :
                                        ele?.hasOwnProperty('temperature') ? "temperature" :
                                            ele?.hasOwnProperty('voc') ? "voc" : ""

                    const value = ele?.air_pressure || ele?.co2 || ele?.humidity ||
                        ele?.illuminance || ele?.radon || ele?.temperature || ele?.voc || 'NA'

                    return {
                        name: ele?.hasOwnProperty('air_pressure') ? "Air Pressure" :
                            ele?.hasOwnProperty('co2') ? "CO2" :
                                ele?.hasOwnProperty('humidity') ? "Humidity" :
                                    ele?.hasOwnProperty('illuminance') ? "Illuminance" :
                                        ele?.hasOwnProperty('radon') ? "Radon" :
                                            ele?.hasOwnProperty('temperature') ? "Temperature" :
                                                ele?.hasOwnProperty('voc') ? "TVOC" : "",
                        label,
                        value,
                        isLatest: ele?.isLatest,
                        unit: ele?.units,
                        color: Object.values(aqiColors(label, value, ele?.isLatest))?.[0]
                    }
                })

                modifiedResponse.sort((a, b) => aqiFeaturesOrder?.indexOf(a?.name) - aqiFeaturesOrder?.indexOf(b?.name))

                return { modifiedResponse, areaId: arg?.area?.areaId }
            },
            transformErrorResponse: (errorResponse, apiStatus, context) => {
                errorHandler(errorResponse, 'AQI Secondary SelectedFloorData')
            },
            providesTags: ['aqi']
        }),
        fetchAqiFloorViewFloors: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/aqi/s2/options?floors=true&bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'AQI Floorview Options')
            },
            providesTags: ['aqi']
        }),
        fetchAqiFloorViewSensors: builder.query({
            query: ({ floorId, parameter, bid }) => ({
                url: `twin/v1/aqi/s2/data?floorId=${floorId}&parameter=${parameter}&bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, "AQI FloorView Fetch Sensors")
            },
            providesTags: ['aqi']
        }),
        fetchAqiTertiaryLatestData: builder.query({
            query: ({ areaId, bid }) => ({
                url: `twin/v1/aqi/s2/data/${areaId}?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: responseData => {
                if (responseData) {
                    const newData = responseData?.map(ele => {
                        const label = ele?.hasOwnProperty('air_pressure') ? "air_pressure" :
                            ele?.hasOwnProperty('co2') ? "co2" :
                                ele?.hasOwnProperty('humidity') ? "humidity" :
                                    ele?.hasOwnProperty('illuminance') ? "illuminance" :
                                        ele?.hasOwnProperty('radon') ? "radon" :
                                            ele?.hasOwnProperty('temperature') ? "temperature" :
                                                ele?.hasOwnProperty('voc') ? "voc" : ""

                        const value = ele?.air_pressure || ele?.co2 || ele?.humidity ||
                            ele?.illuminance || ele?.radon || ele?.temperature || ele?.voc || 'NA'

                        return {
                            name: ele?.hasOwnProperty('air_pressure') ? "Air Pressure" :
                                ele?.hasOwnProperty('co2') ? "CO2" :
                                    ele?.hasOwnProperty('humidity') ? "Humidity" :
                                        ele?.hasOwnProperty('illuminance') ? "Illuminance" :
                                            ele?.hasOwnProperty('radon') ? "Radon" :
                                                ele?.hasOwnProperty('temperature') ? "Temperature" :
                                                    ele?.hasOwnProperty('voc') ? "TVOC" : "",
                            label,
                            value,
                            isLatest: ele?.isLatest,
                            unit: ele?.units,
                            color: Object.values(aqiColors(label, value, ele?.isLatest))?.[0]
                        }
                    })
                    return newData.sort((a, b) => aqiFeaturesOrder?.indexOf(a?.name) - aqiFeaturesOrder?.indexOf(b?.name))
                }
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'AQI Tertiary Latest Data')
            },
            providesTags: ['aqi']
        }),
        fetchAqiTertiaryDurations: builder.query({
            query: () => ({
                url: `twin/v1/aqi/s3/options?durations=true`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'AQI Tertiary Durations')
            },
            providesTags: ['aqi']
        }),
        fetchAqiTertiaryChartData: builder.query({
            query: ({ areaId, dataType, duration, bid, startDate, endDate }) => ({
                url: `twin/v1/aqi/s3/data/${areaId}/${dataType}?duration=${duration}&bid=${bid}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: responseData => {
                if (responseData) {
                    return responseData?.sort((a, b) => aqiFeaturesChartOrder?.indexOf(a?.[0]) - aqiFeaturesChartOrder?.indexOf(b?.[0]))
                }
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'AQI Tertiary Chart Data')
            },
            providesTags: ['aqi']
        })
    })
})

export const {
    useGetAqiDashboardDataQuery,
    useFetchAqiSecondaryOptionsQuery,
    useFetchAqiFloorViewFloorsQuery,
    useFetchAqiFloorViewSensorsQuery,
    useFetchAqiTertiaryLatestDataQuery,
    useFetchAqiTertiaryDurationsQuery,
    useFetchAqiTertiaryChartDataQuery
} = fetchAqiSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        error: "",
        data: []
    },
    secondary: {
        newFloors: {
            isLoaded: false,
            error: "",
            selectedFloor: "",
            selectedFloorId: "",
            floors: [],
            subareas: {},
            areas: {}
        },
        newFloorsData: []
    },
    secondaryLeaflet: {
        sensors: [],
        isLoaded: false,
        floors: [],
        selectedFloor: '',
        selectedFloorId: "",
        selectedFeature: { ui: 'Temperature', api: 'temperature' },
        parameters: [],
        floorsIsLoaded: false
    },
    tertiary: {
        latest: {
            isLoaded: false,
            error: "",
            data: [],
        },
        chartData: {
            isLoaded: false,
            error: "",
            data: [],
        },
        durations: {
            isLoaded: false,
            error: "",
            data: [],
        },
        margins: [15, 10, 25, 43],
        lineWidth: 2
    }
}

const aqiSlice = createSlice({
    name: 'aqiSlice',
    initialState,
    reducers: {
        resetDashboardAqi: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        dashboardAQISocketData: (state, action) => {
            const payload = structuredClone(action.payload)
            const label = action.payload?.name === "Air Pressure" ? "air_pressure" :
                action.payload?.name === "CO2" ? "co2" :
                    action.payload?.name === "Humidity" ? "humidity" :
                        action.payload?.name === "Illuminance" ? "illuminance" :
                            action.payload?.name === "Radon" ? "radon" :
                                action.payload?.name === "Temperature" ? "temperature" :
                                    action.payload?.name === "TVOC" ? "voc" : ""
            payload.label = label
            payload.color = Object.values(aqiColors(label, payload?.value, payload?.isLatest))?.[0]

            const updatingData = state.dashboard.data?.map(ele => {
                if (ele?.areaName === payload?.area) {
                    const updatingAreaData = ele?.areaData?.map(ele => {
                        if (ele?.index === payload?.label) {
                            return {
                                ...ele,
                                value: payload?.value,
                                isLatest: true,
                                color: payload?.color
                            }
                        }
                        return ele
                    })
                    return {
                        ...ele,
                        areaData: updatingAreaData
                    }
                }
                return ele
            })

            state.dashboard.data = updatingData
            // state.dashboard?.data?.shift()
            // state.dashboard?.data?.push(payload)
        },
        resetAllFloorsData: (state, action) => {
            state.secondary = initialState.secondary
        },
        resetFloorViewData: (state, action) => {
            state.secondaryLeaflet = initialState.secondaryLeaflet
        },
        updateSelectedFloor: (state, action) => {
            if (action?.payload?.name === 'All Floors') action.payload.id = 'All Floors'
            const areasList = state.secondary?.newFloors?.areasList
            const newFloorsData = []
            for (let key in areasList) {
                areasList[key]?.forEach(code => {
                    (action.payload?.name === key || action.payload?.name === "All Floors") && newFloorsData.push(floorInitialState(key, code.id, code.name, code.subarea))
                })
            }

            state.secondary.newFloors.isLoaded = true
            state.secondary.newFloors.selectedFloor = action.payload?.name
            state.secondary.newFloors.selectedFloorId = action.payload?.id
            state.secondary.newFloorsData = newFloorsData
        },
        updateSelectedFloorLeaflet: (state, action) => {
            state.secondaryLeaflet.isLoaded = true
            state.secondaryLeaflet.selectedFloor = action.payload?.name
            state.secondaryLeaflet.selectedFloorId = action.payload?.id
        },
        updateSelectedFeature: (state, action) => {
            state.secondaryLeaflet.selectedFeature = action?.payload
        },
        updateSocketSensorData: (state, action) => {
            const sensors = state.secondaryLeaflet?.sensors
            const socketSensorData = action.payload
            const updatedSocketSensorData = SensorsValueColor(socketSensorData, socketSensorData.name)
            const updatedArr = sensors.map((ele) => {
                return (ele.sensorId === updatedSocketSensorData?.sensorId) ?
                    { ...ele, heatmapValue: updatedSocketSensorData.heatmapValue, value: updatedSocketSensorData.value } : ele
            })
            state.secondaryLeaflet.sensors = updatedArr
        },
        resetTertiaryAQI: (state, action) => {
            state.tertiary = initialState.tertiary
        },
        resetTertiaryChartIsLoaded: (state, action) => {
            state.tertiary.chartData.isLoaded = false
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'getAqiDashboardData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.dashboard.isLoaded = true
                state.dashboard.data = action.payload
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiSecondaryOptions' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.secondary.newFloors = action.payload?.newFloors
                state.secondary.newFloorsData = action.payload?.newFloorsData
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiSecondarySelectedFloorData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const data = state.secondary?.newFloorsData?.map(ele => {
                    return (ele.areaId === action.payload?.areaId) ?
                        { ...ele, data: action.payload?.modifiedResponse, isLoaded: true } : ele
                })

                state.secondary.newFloorsData = data
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiFloorViewFloors' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const floors = action.payload?.floors
                dropdownFloorsSort(floors)

                state.secondaryLeaflet.floors = floors
                state.secondaryLeaflet.selectedFloor = floors?.[0]?.name
                state.secondaryLeaflet.selectedFloorId = floors?.[0]?.id
                state.secondaryLeaflet.parameters = action.payload?.parametersAvailable
                state.secondaryLeaflet.floorsIsLoaded = true
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiFloorViewSensors' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const sensors = action.payload
                const feature = state.secondaryLeaflet?.selectedFeature?.ui
                const updatedSensors = SensorsValueColor(sensors, feature)

                state.secondaryLeaflet.sensors = updatedSensors
                state.secondaryLeaflet.isLoaded = true
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiTertiaryLatestData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary.latest = { ...state.tertiary.latest, isLoaded: true, data: action.payload }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiTertiaryDurations' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary.durations = { ...state.tertiary.durations, isLoaded: true, data: action.payload }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAqiTertiaryChartData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary.chartData = { ...state.tertiary.chartData, isLoaded: true, data: action.payload }
            }
        )
    }
})

export const {
    resetDashboardAqi,
    dashboardAQISocketData,
    resetAllFloorsData,
    updateSelectedFloor,
    resetFloorViewData,
    updateSocketSensorData,
    updateSelectedFloorLeaflet,
    updateSelectedFeature,
    resetTertiaryAQI,
    resetTertiaryChartIsLoaded
} = aqiSlice.actions

export default aqiSlice.reducer