import Compressor from 'compressorjs'

export const optimizImg = async (img, mimeType) => {
    const compressedFile = await new Promise((resolve, reject) => {
        new Compressor(img, {
            // quality: 0.8,
            convertSize: 1500000,
            width: 1920,
            height: 1080,
            mimeType: mimeType || 'image/jpeg',
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
            },
        });
    });

    return compressedFile
}