const PDFReportGeneratorStyles = {
    pageContainer: {
        height: '100vh',
        padding: '100px',
    },
    reportTitle: {
        fontSize: { xs: '35px', md: '40px' },
        fontWeight: 500,
        my: '16px'
    },
    clientLogo: {
        height: '75px',
        width: '200px',
        objectFit: 'contain'
    },
    initialPageFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    reportHeader: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px'
    },
    reportContainer: {
        justifyContent: 'space-between',
        height: '100%',
    },
    reportInsightsTitle: {
        fontSize: '20px',
        fontWeight: 600
    }
}

export default PDFReportGeneratorStyles