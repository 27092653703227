import { Autocomplete, Box, Button, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import FormInput from "../../components/formInputs/FormInput"
import { useContext, useEffect, useState } from "react";
import { PPMContext, PPMDispatchContext, PPMDispatchOptionsContext, PPMOptionsContext } from "./context/PPMContextProvider";
import { DELETE, FILEPOST, GET, POST, PUT } from "../../helpers/http";
import { errorToast, successToast } from "../../helpers/apiToast";
import { initialPPMState } from "./context/PPMReducer";
import DateTimeAndRecursiveInputs from "./DateTimeAndRecursiveInputs";
import Loader from "../../components/loading/Loader";
import { fetchCalendarData } from "./helpers";
import PPMSwitch from "./components/switch/PPMSwitch";
import CustomTypography from "../../components/texts/CustomTypography";
import MediaUploadForm from "../../components/formInputs/MediaUploadInput";
import { useSelector } from "react-redux";
import FormView from "../../../admin/features/dynamicForm/FormView";

const CreatePPMForm = (props) => {
    const { handleCloseModal, assetId, selectedDateRange, setCalendarEvents, handleCloseViewPPMModal, selectedUpdatePPMOption, triggerList } = props
    const PPMData = useContext(PPMContext);
    const dispatch = useContext(PPMDispatchContext);
    const PPMOptionsData = useContext(PPMOptionsContext);
    const optionsDispatch = useContext(PPMDispatchOptionsContext);
    const [selectedArea, setSelectedArea] = useState(PPMOptionsData?.areas?.[0] || {})
    const [selectedCategory, setSelectedCategory] = useState(PPMOptionsData?.categories?.[0] || {})
    const [selectedAsset, setSelectedAsset] = useState(PPMOptionsData?.assets?.[0] || {})
    const [selectedVendor, setSelectedVendor] = useState(PPMOptionsData?.vendors?.find(ven => ven?._id === PPMData?.currentService?.assignedVendor?.id)?.id || {})
    const [selectedVendorAssignee, setSelectedVendorAssignee] = useState(PPMOptionsData?.vendorAssignees?.[0] || {})
    const [isPPMrecursive, setIsPPMrecursive] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        title: '',
        priority: '',
        date: '',
        startTime: '',
        endTime: '',
        interval: '',
        frequency: '',
        until: '',
        category: '',
        assignedUser: '',
        assignedVendor: ''
    })
    const [editingPPMBeforeUpdate, setEditingPPMBeforeUpdate] = useState({
        isLoaded: false,
        data: {}
    })
    const [userTypeChecked, setUserTypeChecked] = useState(false)
    const [uploadingMedia, setUploadingMedia] = useState(false)
    const fileReadUrl = useSelector((state) => state?.configurations?.ppmfileReadUrl)
    const [formOptions, setFormOptions] = useState([])
    const [form, setForm] = useState({})
    const [preview, setPreview] = useState(false)

    console.log({ form })

    const handleIsRecursiveChange = (e) => {
        setIsPPMrecursive(e.target.checked)
        //resetting recursive values
        dispatch({ type: 'isRecursive', payload: e.target.checked })
        dispatch({ type: 'interval', payload: null })
        dispatch({ type: 'frequency', payload: '' })
        dispatch({ type: 'until', payload: '' })
        dispatch({ type: 'avoidSaturday', payload: false })
        dispatch({ type: 'avoidSunday', payload: false })
    }

    useEffect(() => {
        if (dispatch) {
            if (assetId) {
                const fetchEditingPPM = async () => {
                    try {
                        const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/details/${assetId}`, 'Fetch Editing PPM')
                        const formattedData = {
                            ...data,
                            building: data?.building?.id,
                            floor: data?.floor?.id,
                            area: data?.area?.id,
                            category: data?.category?.id,
                            assetRef: data?.assetRef?.id,
                            date: data?.date,
                            startTime: data?.startTime,
                            endTime: data?.endTime,
                            isRecursive: data?.isRecursive,
                            recursiveConfiguration: {
                                avoidSaturday: data.recursiveConfiguration?.avoidSaturday,
                                avoidSunday: data.recursiveConfiguration?.avoidSunday,
                                interval: data.recursiveConfiguration?.interval,
                                frequency: data.recursiveConfiguration?.frequency,
                                until: data.recursiveConfiguration?.until,
                            },
                            currentService: {
                                assignedVendor: data?.currentService?.assignedVendor?.id ?? '',
                                assignedUser: data?.currentService?.assignedUser?.id ?? "",
                                assignedUserType: data?.currentService?.assignedUserType
                            },
                        }
                        if (data?.currentService?.assignedUserType === "external") setUserTypeChecked(true)
                        dispatch({ type: 'update', payload: formattedData })
                        setEditingPPMBeforeUpdate({ isLoaded: true, data })
                        setIsPPMrecursive(data?.isRecursive)
                        setSelectedArea(data?.area)
                        setSelectedCategory(data?.category)
                        setSelectedAsset(data?.assetRef)
                        setSelectedVendor(data?.currentService?.assignedVendor)
                        setSelectedVendorAssignee(data?.currentService?.assignedUser)
                    } catch (error) {
                        console.log({ error })
                    }
                }
                fetchEditingPPM()
            }
            else {
                setEditingPPMBeforeUpdate({ isLoaded: true, data: {} })
            }
        }

        return () => {
            dispatch({ type: 'update', payload: initialPPMState })
            setEditingPPMBeforeUpdate({ isLoaded: false, data: {} })
        }
    }, [assetId, dispatch])

    useEffect(() => {

        const fetchOptions = async () => {
            try {
                const buildingId = localStorage.getItem('bid')
                const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/create/options?buildingId=${buildingId}${selectedArea?.id ? `&areaId=${selectedArea?.id}` : ''}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}`, 'PPM Create Form Options')
                Object.keys(data)?.length > 0 && Object.keys(data).map(key => optionsDispatch({ type: key, payload: data[key] || [] }))
            } catch (error) {
                console.log({ error })
                if (error?.response) errorToast(error?.response?.data?.message)
                else errorToast(error?.message);
            }
        }

        fetchOptions()
    }, [optionsDispatch, selectedArea?.id, selectedCategory?.id])

    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const buildingId = localStorage.getItem('bid')
                let validTillQuery = ''
                if (isPPMrecursive && PPMData?.until) {
                    validTillQuery = `&validTill=${PPMData?.until}`
                } else if (!isPPMrecursive && PPMData?.date) {
                    validTillQuery = `&validTill=${PPMData?.date}`
                }
                const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/vendors?buildingId=${buildingId}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}${validTillQuery && validTillQuery}`, 'PPM Create Form Vendor Options')
                if (Boolean(PPMData?.assignedVendor)) {
                    const result = data?.find(el => el?.id === PPMData?.assignedVendor)
                    if (!result) {
                        errorToast('Vendor is not valid for the selected range')
                        return;
                    }
                }
                optionsDispatch({ type: 'vendors', payload: data || [] })
            } catch (error) {
                console.log({ error })
                if (error?.response) errorToast(error?.response?.data?.message)
                else errorToast(error?.message);
            }
        }
        const fetchInternalUsers = async () => {
            try {
                const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/internal/users?categoryId=${selectedCategory?.id}`, 'PPM Create Form Vendor Options')
                optionsDispatch({ type: 'internalAssignee', payload: data?.data || [] })
            } catch (error) {
                console.log(error)
                errorToast(error?.message)
            }
        }

        if (PPMData?.currentService?.assignedUserType === "external") {
            fetchVendors()
        } else if (PPMData?.currentService?.assignedUserType === "internal") {
            if (selectedCategory?.id) fetchInternalUsers()
        }
    }, [optionsDispatch, userTypeChecked, isPPMrecursive, PPMData?.recursiveConfiguration?.until, PPMData?.date, PPMData?.currentService?.assignedVendor, PPMData?.category, assetId])

    useEffect(() => {
        const fetchVendorAssignees = async () => {
            try {
                const buildingId = localStorage.getItem('bid')
                if (selectedVendor?.id) {
                    const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/vendor/${selectedVendor?.id}/users?buildingId=${buildingId}`, 'PPM Create Form Vendor Assignee Options')
                    optionsDispatch({ type: 'vendorAssignees', payload: data || [] })
                }
            } catch (error) {
                console.log({ error })
                if (error?.response) errorToast(error?.response?.data?.message)
                else errorToast(error?.message);
            }
        }

        fetchVendorAssignees()
    }, [optionsDispatch, selectedVendor?.id, PPMData?.currentService?.assignedVendor])
    useEffect(() => {
        (async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/form/list`, "GET Form list")
                console.log(response.data)
                setFormOptions(response.data)

            } catch (error) {
                console.log(error)
            }
        })();
    }, [])

    const handleFormInputsChange = (e) => {
        const { name, value, checked } = e.target
        //checked only for checkbox inputs
        dispatch({ type: name, payload: (name === 'avoidSaturday' || name === 'avoidSunday') ? checked : value })
        setValidationErrors({ ...validationErrors, [name]: '' })
    }

    const handleFormOptions = (e) => {
        const { name, value } = e.target
        console.log({ name, value })
        setForm(value)
        dispatch({ type: 'form', payload: value._id })

    }

    const validators = (ppm) => {
        const errors = {}
        if (ppm.title?.trim()?.length < 3) {
            errors.title = 'Title should be 3 or more characters'
        }
        if (!ppm.priority?.trim()) {
            errors.priority = 'Priority is required'
        }
        if (!ppm.date?.trim()) {
            errors.date = 'Start Date is required'
        }
        if (!ppm.startTime?.trim()) {
            errors.startTime = 'Start Time is required'
        }
        if (!ppm.endTime?.trim()) {
            errors.endTime = 'End Time is required'
        } else if (new Date(`${ppm.date}T${ppm.startTime}`) > new Date(`${ppm.date}T${ppm.endTime}`)) {
            errors.endTime = 'End Time must be greater then Start Time'
        }
        if (!ppm.category?.trim()) {
            errors.category = 'Category is required'
        }
        if (ppm.isRecursive) {
            if (ppm.recursiveConfiguration?.interval < 1) {
                errors.interval = 'Interval must be a positive number'
            }
            if (!ppm.recursiveConfiguration?.frequency?.trim()) {
                errors.frequency = 'Frequency is required'
            }
            if (!ppm.recursiveConfiguration?.until?.trim()) {
                errors.until = 'Stop Date is required'
            }
        }
        if (ppm?.currentService) {
            if (ppm.currentService?.assignedUserType === 'internal') {
                if (!ppm.currentService?.assignedUser?.trim()) {
                    errors.assignedUser = 'Assigned User is required'
                }
            }
            if (ppm.currentService?.assignedUserType === 'external') {
                if (!ppm.currentService?.assignedUser?.trim()) {
                    errors.assignedUser = 'Assigned User is required'
                }
                if (!ppm.currentService?.assignedVendor?.trim()) {
                    errors.assignedVendor = 'Assigned Vendor is required'
                }
            }
        }
        return errors
    }

    const handleCreatePPM = async (e) => {

        e.preventDefault()
        const { frequency, interval, until, avoidSaturday, avoidSunday, ...ppm } = PPMData
        const errors = validators(ppm)

        setValidationErrors(errors)

        if (Object.keys(errors).length === 0) {
            try {
                if (assetId) {
                    delete ppm?.id
                    delete ppm?.statusOptions
                    delete ppm?.assignmentStatus
                    delete ppm?.taskStatus
                    delete ppm?.previousServices
                    delete ppm?.createdBy
                    delete ppm?.textInfo
                    delete ppm?.overdueFields
                    delete ppm?.isDeleted
                    if (ppm?.currentService?.assignedUserType === 'internal') delete ppm?.currentService?.assignedVendor

                    if (!ppm.isRecursive) {
                        delete ppm.recursiveConfiguration
                    }
                    const { status, data } = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/${assetId}${(ppm.isRecursive && editingPPMBeforeUpdate?.data?.isRecursive) ? `?type=${selectedUpdatePPMOption ?? "CRR_PPM"}` : ''}`,
                        {
                            ...ppm,
                            building: localStorage.getItem('bid')
                        }, 'Update PPM', true)
                    if (status === 200) {
                        triggerList({ id: data?.id })
                        successToast(`PPM ${ppm.title} updated successfully`)
                        if (handleCloseModal) {
                            handleCloseModal()
                        }
                        // fetchPPMList(dispatchPPMList)
                        if (handleCloseViewPPMModal) {
                            handleCloseViewPPMModal()
                        }
                        if (selectedDateRange?.start && selectedDateRange?.end) {
                            fetchCalendarData({ startDate: selectedDateRange?.start, endDate: selectedDateRange?.end, setEvents: setCalendarEvents })
                        }
                    }
                }
                else {
                    if (ppm?.currentService?.assignedUserType === 'internal') delete ppm?.currentService?.assignedVendor
                    if (!ppm?.isRecursive) delete ppm.recursiveConfiguration
                    const { status } = await POST(`${process.env.REACT_APP_URL}ppm/v1/task`,
                        {
                            ...ppm,
                            building: localStorage.getItem('bid')
                        },
                        'Create PPM', true)
                    if (status === 201) {
                        // await fetchPPMStats()
                        triggerList()
                        successToast(`PPM created successfully`)
                        if (handleCloseModal) {
                            handleCloseModal()
                            // fetchPPMList(dispatchPPMList)
                            if (selectedDateRange?.start && selectedDateRange?.end) {
                                fetchCalendarData({ startDate: selectedDateRange.start, endDate: selectedDateRange.end, setEvents: setCalendarEvents })
                            }
                        }
                    }
                }
            } catch (error) {
                console.log({ error })
                errorToast(error?.message);
            }
        }
    }

    const textInputs = [
        {
            label: 'Title',
            size: 'small',
            fullWidth: true,
            name: 'title',
            multiline: true,
            rows: 2,
            value: PPMData?.title,
            onChange: handleFormInputsChange,
            error: !!validationErrors.title,
            helperText: validationErrors.title
        },
        {
            label: 'Description',
            size: 'small',
            multiline: true,
            rows: 2,
            fullWidth: true,
            name: 'description',
            value: PPMData?.description,
            onChange: handleFormInputsChange,
        },
        {
            label: 'Priority',
            size: 'small',
            select: true,
            fullWidth: true,
            name: 'priority',
            value: PPMData?.priority,
            onChange: handleFormInputsChange,
            error: !!validationErrors.priority,
            helperText: validationErrors.priority,
            children: PPMOptionsData?.priorities?.map((option) => (
                <MenuItem
                    key={option}
                    value={option}
                >
                    {option}
                </MenuItem>
            ))
        }
    ]

    const autoCompleteInputsOptions = [
        {
            value: selectedArea,
            onChange: (event, newValue) => {
                setSelectedArea(newValue)
                dispatch({ type: 'area', payload: newValue?.id || '' })
                optionsDispatch({ type: 'assets', payload: [] })
                setSelectedAsset({})
            },
            options: PPMOptionsData?.areas || [],
            getOptionLabel: (option) => ((option?.name && option?.floor?.name) ? `${option?.name} - ${option?.floor?.name}` : ''),
            placeholder: 'Search an area',
        },
        {
            value: selectedCategory,
            onChange: (event, newValue) => {
                setSelectedCategory(newValue)
                dispatch({ type: 'category', payload: newValue?.id || '' })
                setSelectedVendor({})
                setSelectedVendorAssignee({})
                setSelectedAsset({})
                dispatch({ type: 'assignedVendor', payload: '' })
                dispatch({ type: 'assignee', payload: '' })
                optionsDispatch({ type: 'vendorAssignees', payload: [] })
                optionsDispatch({ type: 'assets', payload: [] })
                setValidationErrors({ ...validationErrors, category: '' })
            },
            options: PPMOptionsData?.categories || [],
            getOptionLabel: (option) => option?.name || '',
            placeholder: 'Search a category',
            field: 'category'
        },
        {
            value: selectedAsset,
            onChange: (event, newValue) => {
                setSelectedAsset(newValue)
                dispatch({ type: 'assetRef', payload: newValue?.id || '' })
            },
            options: PPMOptionsData?.assets || [],
            getOptionLabel: (option) => option?.name || '',
            placeholder: 'Search an asset',
        },
    ]

    const vendorAndVUser = [
        {
            value: userTypeChecked && selectedVendor,
            onChange: (event, newValue) => {
                setSelectedVendor(newValue)
                setSelectedVendorAssignee({})
                dispatch({ type: 'assignedUser', payload: '' })
                dispatch({ type: 'assignedVendor', payload: newValue?.id || '' })
                setValidationErrors({ ...validationErrors, assignedVendor: '' })
            },
            options: PPMOptionsData?.vendors || [],
            getOptionLabel: (option) => option?.name || '',
            placeholder: 'Search a vendor',
            // disabled: isPPMrecursive ? !PPMData?.until : !PPMData?.date 
            disabled: !userTypeChecked,
            field: "assignedVendor"
        },
        {
            value: selectedVendorAssignee,
            onChange: (event, newValue) => {
                setSelectedVendorAssignee(newValue)
                dispatch({ type: 'assignedUser', payload: newValue?.id || '' })
                setValidationErrors({ ...validationErrors, assignedUser: '' })
            },
            options: PPMData?.currentService?.assignedUserType === "external" ? PPMOptionsData?.vendorAssignees : PPMOptionsData?.internalAssignees || [],
            getOptionLabel: (option) => option?.name || '',
            placeholder: PPMData?.currentService?.assignedUserType === "external" ? 'Search a vendor user' : 'Search a Internal user',
            // disabled: isPPMrecursive ? !PPMData?.until : !PPMData?.date
            field: "assignedUser"
        },
    ]

    const handleAssignedUserType = (e) => {
        setUserTypeChecked(e.target.checked)
        setSelectedVendor({ name: "" })
        setSelectedVendorAssignee({})
        dispatch({ type: 'assignedUserType', payload: e.target.checked ? "external" : "internal" })
    }

    const handleMediaUpload = async ({ event }) => {
        try {
            const maxLimit = 5
            //check maxMedia count
            if (PPMData?.media?.length < maxLimit) {
                const fileData = event.target.files[0]
                const formData = new FormData()
                formData.append('file', fileData)
                setUploadingMedia(true)
                const response = await FILEPOST(`${process.env.REACT_APP_URL}ppm/v1/media/upload`, formData, 'File Upload : Add Image', true)
                if (response?.status === 200) {
                    dispatch({ type: 'media', payload: [...PPMData?.media, response?.data] })
                    setUploadingMedia(false)
                    successToast("Image Uploaded Successfully")
                }
            } else {
                errorToast(`You can upload only ${maxLimit} medias`)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            setUploadingMedia(false)
            console.log(error)
        }
    }

    const handleMediaDelete = async (media) => {
        try {
            const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/media?uploadId=${media?.uploadId}`)
            if (response) {
                dispatch({ type: 'media', payload: PPMData?.media?.filter(mda => mda?.uploadId !== media?.uploadId) })
                successToast("Media Deleted Successfully")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }
    }

    const handleClose = () => {
        setPreview(false)
    }

    const handleFormView = () => {
        setPreview(true)
    }

    return (
        <Box sx={{ padding: "1rem" }} >
            {
                editingPPMBeforeUpdate?.isLoaded ? <>
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        spacing={8}
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleCreatePPM}
                        useFlexGap
                    >
                        <Stack spacing={2} useFlexGap width="100%">
                            {
                                textInputs.map((input) => (
                                    <FormInput
                                        key={input.name}
                                        {...input}
                                    />
                                ))
                            }
                            {
                                autoCompleteInputsOptions.map((option) => (<Autocomplete
                                    key={option.placeholder}
                                    {...option}
                                    noOptionsText="No Data"
                                    renderInput={(params) => {
                                        return (
                                            <FormInput
                                                {...params}
                                                disabled={option.disabled}
                                                size='small'
                                                placeholder={option.placeholder}
                                                fullWidth
                                                error={option?.field?.includes('category') && !!validationErrors.category}
                                                helperText={option?.field?.includes('category') && validationErrors.category}
                                            />
                                        )
                                    }}
                                    style={{ maxHeight: '150px', width: '100%', overflow: 'auto' }}
                                />))
                            }
                            <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                p={0} m={0}>
                                <PPMSwitch
                                    defaultChecked
                                    checked={userTypeChecked}
                                    onChange={handleAssignedUserType}
                                />
                                <CustomTypography
                                    className="color-grey"
                                    sx={{ fontSize: "12px" }}>
                                    {`You have selected ${userTypeChecked ? "External" : "Internal"} User as the assignee.`}
                                </CustomTypography>
                            </Stack>
                            {vendorAndVUser.map((option) => (<Autocomplete
                                key={option.placeholder}
                                {...option}
                                noOptionsText="No Data"
                                renderInput={(params) => {
                                    return (
                                        <FormInput
                                            {...params}
                                            disabled={option.disabled}
                                            size='small'
                                            placeholder={option.placeholder}
                                            fullWidth
                                            error={!!validationErrors[option?.field]}
                                            helperText={validationErrors[option?.field]}
                                        />
                                    )
                                }}
                                style={{ maxHeight: '150px', width: '100%', overflow: 'auto' }}
                            />))
                            }

                        </Stack>
                        <Stack spacing={2} useFlexGap width="100%" justifyContent="space-between">
                            <Stack gap={2} >
                                <DateTimeAndRecursiveInputs
                                    PPMData={PPMData}
                                    handleFormInputsChange={handleFormInputsChange}
                                    validationErrors={validationErrors}
                                    isPPMrecursive={isPPMrecursive}
                                    handleIsRecursiveChange={handleIsRecursiveChange}
                                    PPMOptionsData={PPMOptionsData}
                                    assetId={assetId}
                                    selectedUpdatePPMOption={selectedUpdatePPMOption}
                                />
                                {!assetId && <MediaUploadForm
                                    sx={{ width: "20rem" }}
                                    fileReadUrl={fileReadUrl}
                                    medias={PPMData?.media}
                                    handleMediaUpload={handleMediaUpload}
                                    handleMediaDelete={handleMediaDelete}
                                    uploadingMedia={uploadingMedia}
                                />}
                                <FormInput
                                    label={'Comment'}
                                    size={'small'}
                                    multiline
                                    rows={2}
                                    fullWidth
                                    name={'comment'}
                                    value={PPMData?.comment}
                                    onChange={handleFormInputsChange}
                                />
                                <Select
                                    onChange={handleFormOptions}
                                    displayEmpty
                                    renderValue={(selected) => selected ? selected.name : "Select the form"}

                                >
                                    <MenuItem value="">
                                        Select the form
                                    </MenuItem>
                                    {
                                        formOptions.map((ele, i) => {
                                            return <MenuItem key={i} value={ele}> {ele.name}</MenuItem>
                                        })

                                    }
                                </Select>

                                {
                                    Object.keys(form).length > 0 && <span onClick={handleFormView} style={{ color: "blue", textDecoration: "underline" }}> View Selected Form</span>
                                }

                            </Stack>
                            {
                                preview && <FormView finalForm={{ name: form?.name, description: form?.description }} formData={form.fields} open={preview} handleClose={handleClose} disableSubmit={true} />
                            }


                            <Button type="submit" className="actionButton">
                                {assetId ? 'Update PPM' : 'Create PPM'}
                            </Button>
                        </Stack>
                    </Stack>
                </> : <Loader height={'80vh'} />
            }
        </Box>
    )
}

export default CreatePPMForm