import { Box, Typography } from "@mui/material"
import CommonStyles from "../../../../styles/CommonStyles"
import WallContentReviewBody from "../../../../features/wall/components/wallContentReviewBody/WallContentReviewBody"

const WallContentReview = (props) => {

    return (
        <>
            <Box sx={{ ...CommonStyles?.OuterBox }}>
                <Typography variant="h3" sx={{ margin: "1rem" }}>
                    Content Review
                </Typography>
                <WallContentReviewBody />
            </Box>
        </>
    )
}

export default WallContentReview