import { createContext, useReducer } from "react";
import { initialPPMState, PPMReducer } from "./PPMReducer";
import { initialPPMOptions, PPMOptionsReducer } from "./PPMOptionsReducer";

export const PPMContext = createContext(null);
export const PPMOptionsContext = createContext(null);
export const PPMDispatchContext = createContext(null);
export const PPMDispatchOptionsContext = createContext(null);

const PPMProvider = ({ children }) => {
    const [PPMData, dispatch] = useReducer(PPMReducer, initialPPMState);
    const [PPMOptionsData, optionsDispatch] = useReducer(PPMOptionsReducer, initialPPMOptions);

    return (
        <PPMOptionsContext.Provider value={PPMOptionsData}>
            <PPMDispatchOptionsContext.Provider value={optionsDispatch}>
                <PPMContext.Provider value={PPMData}>
                    <PPMDispatchContext.Provider value={dispatch}>
                        {children}
                    </PPMDispatchContext.Provider>
                </PPMContext.Provider>
            </PPMDispatchOptionsContext.Provider>
        </PPMOptionsContext.Provider>
    )
}

export default PPMProvider