

export const wallContentReviewBodyStyle = {
    tabChildrenBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '3rem',
    },
    autoCompleteSearch: {
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
    },
    autoCompleteTextField: {
        width: 200,
        height: 35,
        borderRadius: "10px",
        '& .MuiInputBase-root': {
            height: '100%',
            alignContent: 'center'
        },
    }
}   