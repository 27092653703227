import { Box, Stack, Typography } from "@mui/material";
import Modal from '../../../../components/modal/Modal'
import { useSelector } from "react-redux";

export default function ViewMaintenanceMapping(props) {
    const { openViewMappingModal, handleCloseViewModal, selectedViewMapping } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    return (
        <Modal
            modalOpen={openViewMappingModal}
            closeModal={handleCloseViewModal}
            title={selectedViewMapping?.name}
        >
            <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Stack spacing={1}>
                    <Typography sx={{ fontSize: '16px', color }}><b>Services: </b>{selectedViewMapping?.serviceReference?.name}
                    </Typography>
                    <Typography sx={{ fontSize: '16px', color }}><b>Name: </b>{selectedViewMapping?.name}</Typography>
                    {
                        selectedViewMapping?.description && <Typography sx={{ fontSize: '16px', color }}><b>description: </b>{selectedViewMapping?.description}</Typography>

                    }
                    <Typography sx={{ fontSize: '16px', color }}><b>Categories: </b>{selectedViewMapping?.categoriesReference?.map(category => category?.name)?.join(', ')}</Typography>
                    {
                        selectedViewMapping?.departmentsReference?.length > 0 &&
                        <Typography sx={{ fontSize: '16px', color }}><b>Departments: </b>{selectedViewMapping?.departmentsReference?.map(department => department?.name)?.join(', ')}</Typography>
                    }

                </Stack>
            </Box>

        </Modal>
    )
}