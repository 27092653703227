

const postStyle = {
    card: {
        boxShadow: "0",
        height: "65vh",
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    profileCard: {
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        alignItems: "center",
        paddingTop: "0.5rem"
    },
    flagCountBox: {
        display: "flex",
        gap: "0.3rem",
        color: "#ef5350",
        alignItems: "center"
    },
    mediaBox: {
        display: 'flex',
        flexDirection: 'row',
        height: "38vh",
        width: "inherit"
    },
    mediaBoxCarousel: {
        width: "100%",
        height: '100%',
        borderRadius: "1rem",
    },
    noMediaTypo: {
        width: "45vw",
        borderRadius: '1rem',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
    },
    reactionStatusBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: "0.5rem"
    },
    leftBarBox: {
        display: "flex",
        flexDirection: "row",
        gap: "1.5rem"
    },
    reactionTypo: {
        display: "flex",
        alignContent: "center",
        gap: "0.5rem"
    }
}

export default postStyle