export const areaImageViewStyle = {
    topBarPaper: {
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        width: "100%",
        height: '8vh',
        mt: 1,
    },
    singleImagePaper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '10px',
        // height: '80%',
    },
    singleImgTopbar: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    singleImg: {
        objectFit: 'contain',
        minHeight: 207,
        maxHeight: 207,
        // marginBottom: "2rem",
        width: '100%',
        // height: '100%',
        borderRadius: '0px 0px 10px 10px'
    }
}