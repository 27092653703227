import { Stack, Button, CircularProgress, Box, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { useDVR, useDVRDispatch } from './DVRContext'
import { GET } from "../../helpers/http"
import { useParams, useNavigate } from "react-router-dom"
import CommonStyles from "../../styles/CommonStyles"
import DVRDashboard from "../../components/Dashboard/DVRDashboard"
import { errorToast } from "../../helpers/apiToast"
import { dvrDataParser } from "../../helpers/dvrDataParser"
import ManualRefreshButton from "./ManualRefreshButton"
import { useSelector } from 'react-redux'

const DVRView = (props) => {
    const [errorMessage, setErrorMessage] = useState('')
    let [refreshAfterSeconds, setRefreshAfterSeconds] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const tenantCode = useSelector(state => state?.user?.tenantCode)


    //dashboard states
    const [cameras, setCameras] = useState([])
    const [hardDrives, setHardDrives] = useState([])
    const [dvr, setDvr] = useState({})

    const params = useParams()
    const navigate = useNavigate()

    const DVRstate = useDVR()
    const DVRdispatch = useDVRDispatch()
    const viewDVRState = DVRstate?.viewDvr

    const stateUpdator = (data) => {
        setCameras(data?.cameras?.list)
        setHardDrives(data?.hardwareDetails?.hardDisks)
        setDvr(dvrDataParser(data))
    }

    const errorAndLoadedStateUpdator = (data) => {
        if (!data?.error?.message) {
            if (!data?.error) {
                // setIsLoaded(true)
                stateUpdator(data)
            }
            setIsLoaded(true)
        } else {
            setIsLoaded(true)
            setErrorMessage(data?.error?.message === "Data Error" ? "Lost connection with DVR" : data?.error?.message)
            stateUpdator({
                cameras: [],
                hdd: [],
                dvr: {}
            })
        }
    }

    const errorAndLoadedStateUpdatorRefresh = (data) => {
        if (!data?.error) {
            if (!data?.error) {
                setIsLoaded(true)
                stateUpdator(data)
            }
            setIsLoaded(true)
        } else {
            setIsLoaded(true)
            // setErrorMessage(data?.error?.message === "Data Error" ? "Lost connection with DVR" : data?.error?.message)
            setCameras([])
            setHardDrives([])
            setDvr({})
        }
    }

    useEffect(() => {
        (async () => {
            try {
                let gotDVR
                if (Object.keys(viewDVRState)?.length !== 0) {
                    const DVRDetails = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr?dvrUUID=${viewDVRState?.UUID}`, "VIEW DVR")
                    gotDVR = DVRDetails.data

                } else {
                    const DVRDetails = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr?dvrUUID=${params.id}`, "VIEW DVR")
                    gotDVR = DVRDetails.data
                }
                if (gotDVR?.dvrData?.error) {
                    setRefreshAfterSeconds(gotDVR?.dvrData?.refreshAfterSeconds || 0)
                    errorToast(`${gotDVR?.dvrData?.error}`)
                    errorAndLoadedStateUpdator(gotDVR?.dvrData)
                } else {
                    setRefreshAfterSeconds(Number(gotDVR?.dvrData?.refreshAfterSeconds) || 0)
                    errorAndLoadedStateUpdator(gotDVR?.dvrData)
                }

            } catch (error) {
                if (error?.response?.data?.message) {
                    errorToast(error?.response?.data?.message)
                } else {
                    errorToast(error?.message)
                }
            }
        })()
    }, [])

    const cancelButtonHandle = () => {
        navigate('/admin/cctv')
        DVRdispatch({ type: "CLEAR_SELECTED" })
    }

    return (
        <Box sx={{ margin: "auto", minHeight: "89.9vh", maxHeight: "100%", width: "100%" }}>
            {isLoaded ?
                <Stack >
                    <Stack direction="row" justifyContent="start" pt={1}>
                        <Button onClick={cancelButtonHandle} sx={{ ...CommonStyles.actionButton, width: "10rem", margin: '1rem', bgcolor: "black", fontSize: '14px', py: 1, height: "2.5rem" }}>Back</Button>
                        <Stack direction='row' alignItems='center'>
                            {isLoaded && <ManualRefreshButton seconds={refreshAfterSeconds} dvrData={params.id} errorAndLoadedStateUpdator={errorAndLoadedStateUpdatorRefresh} />}
                        </Stack>
                    </Stack>
                    <DVRDashboard isLoaded={isLoaded} errorMessage={errorMessage} dvr={dvr} hardDrives={hardDrives} cameras={cameras} />
                </Stack>
                :
                <CircularProgress sx={{ color: "#dc4d28", marginTop: "40vh", marginLeft: "45vw" }} />
            }
        </Box>
    )
}

export default DVRView