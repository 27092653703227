const CommonStyles = {
    commonDisplay: {
        display: 'flex',
    },
    commonAlignment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh',
    },
    commonWidthItems: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    commonTextAlignment: {
        fontSize: '12px',
        pt: '8px',
    },
    commonFontSize12: {
        fontSize: '12px',
    },
    commonFontSize16: {
        fontSize: '16px'
    },
    commonFontWeight400: {
        fontSize: '12px',
        fontWeight: 400,
    },
    commonFontWeight500: {
        fontSize: '12px',
        fontWeight: 500,
    },
    commonFontWeight700: {
        fontWeight: 700,
        fontSize: '20px',
    },
    commonFontSize26: {
        fontSize: '26px',
        fontWeight: 700,
    },
    commonChipStyle: {
        height: '100%',
        mt: '4px',
        color: 'black',
    },
    commonPaperContainer: {
        height: '15vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    commonBoxContainer: {
        minHeight: '80vh',
        ml: 1,
    },
    commonTertiaryBoxContainer: {
        height: '12vh',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        ml: 2,
    },
    commonTertiaryGridContainer: {
        display: "flex",
        justifyContent: 'flex-end',
        pr: 2
    },
    commonTertiaryChartDataContainer: {
        height: '16vh',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        ml: 1
    },
    commonBackdrop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',
    },
    commonGridQrCode: {
        width: '500px',
        height: '600px',
        display: 'flex',
        borderRadius: '10px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    commonQrCode: {
        border: '3px solid black'
    },
    commonContainer: {
        ml: 1,
        mt: 1.5,
        minHeight: '72vh',
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    },
    scrollStyle: {
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid lightgrey'
        }
    },
    centerDivWidth100: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    centerDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    ModalPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
    },
    justifyEnd: {
        display: 'flex',
        justifyContent: 'end'
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    justifyContentCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    justifyContentFlexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    spaceBetweenWidth100: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    justifyCenterAlignEndWidth100: {
        width: "100%",
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',

    },
    spaceEvenlyAlignCenter: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    flexEndAlignCenter: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    centerDivHeight100: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    centerDivHeight80: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    actionButton: {
        bgcolor: '#dc4d28',
        color: 'white',
        textTransform: 'none',
        "&:hover": {
            bgcolor: '#dc3d28'
        },
        fontSize: '0.75rem'
    }
}
export default CommonStyles
