import { Box, Button, Stack, Typography } from "@mui/material"
import { DELETE } from "../../../helpers/http"

const DeleteHoliday = (props) => {
    const { closeDeleteHolidayModal = () => { }, setHolidayData = () => { }, deletingHoliday = {} } = props

    const handleDeleteHoliday = async () => {
        try {
            await DELETE(`${process.env.REACT_APP_URL}at/v1/holidaycalendar/${deletingHoliday?._id}?bid=${deletingHoliday?.building?._id}`, "DELETE HOLIDAY", true)
            setHolidayData(prevData => prevData.filter(holiday => holiday?._id !== deletingHoliday?._id))
            closeDeleteHolidayModal()
        } catch (error) {
            console.log({ error })
            closeDeleteHolidayModal()
        }
    }

    return (
        <Stack spacing={2}>
            <Typography align="center">Are you sure you want to delete this holiday <b>{deletingHoliday?.name}</b>?</Typography>
            <Box className='displayFlex flexJustifyBetween columngap16'>
                <Button
                    className='actionButton'
                    onClick={closeDeleteHolidayModal}
                    fullWidth
                >
                    Cancel
                </Button>
                <Button
                    className='actionButton'
                    onClick={handleDeleteHoliday}
                    fullWidth
                >
                    Delete
                </Button>
            </Box>
        </Stack>
    )
}

export default DeleteHoliday