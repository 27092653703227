import { errorToast, successToast } from "../../../../helpers/apiToast";
import { DELETE, FILEPOST, GET, POST, PUT } from "../../../../helpers/http";
import * as Yup from 'yup';
import swal from 'sweetalert'



const useApiCalls = () => {

    const getPpmStatistics = async ({ setStatisticsData, setChartData, setTotalPpm, setMonthChart, setPieChart1, setPieChart2, setPieChart3 }) => {
        try {
            const bid = localStorage.getItem('bid');
            if (bid) {
                const urls = [
                    GET(`${process.env.REACT_APP_URL}ppm/v1/task/statistics?buildingId=${bid}`, 'Statistics'),
                    GET(`${process.env.REACT_APP_URL}ppm/v1/task/month/history?buildingId=${bid}`, 'Monthly History'),
                    GET(`${process.env.REACT_APP_URL}ppm/v1/task/task-count?buildingId=${bid}`, 'Total PPM'),
                    GET(`${process.env.REACT_APP_URL}ppm/v1/task/month-wise/history?buildingId=${bid}`, 'Month Wise History'),
                    GET(`${process.env.REACT_APP_URL}/ppm/v1/task/statistics-data?buildingId=${bid}&type=all`, 'Category wise PPM'),
                    GET(`${process.env.REACT_APP_URL}/ppm/v1/task/statistics-data?buildingId=${bid}&type=pending`, 'Category wise pending PPM'),
                    GET(`${process.env.REACT_APP_URL}/ppm/v1/task/priority-statistics?buildingId=${bid}`, 'Priority wise PPM')
                ];

                const [res1, res2, res3, res4, res5, res6, res7] = await Promise.allSettled(urls);

                if (res1.status === "fulfilled") {
                    setStatisticsData({ isLoaded: true, data: res1?.value?.data || {} });
                } else {
                    console.log("Error in Statistics:", res1.reason);
                    // errorToast(res1.reason?.response?.data?.message || res1.reason.message);
                }

                if (res2.status === "fulfilled") {
                    setChartData({ isLoaded: true, data: res2?.value?.data || {} });
                } else {
                    console.log("Error in Monthly Statistics:", res2.reason);
                    // errorToast("Error in GetPpmStatistics");
                }

                if (res3.status === "fulfilled") {
                    setTotalPpm({ isLoaded: true, data: res3?.value?.data || {} });
                } else {
                    console.log("Error in Task Count:", res3.reason);
                    // errorToast("Error in GetPpmStatistics");
                }

                if (res4.status === "fulfilled") {
                    setMonthChart({ isLoaded: true, data: res4?.value?.data || {} });
                } else {
                    console.log("Error in Monthly Chart:", res4.reason);
                    // errorToast("Error in GetPpmStatistics");
                }

                if (res5.status === "fulfilled") {
                    const transformedData = res5.value.data.map(item => ({
                        name: item.name.toUpperCase(), // Convert the name to uppercase if needed
                        y: item.percentage // Use the percentage value for the 'y' field
                    }));
                    setPieChart1({ isLoaded: true, data: transformedData });
                } else {
                    console.log("Error in Monthly Chart:", res5.reason);
                    // errorToast("Error in GetPpmStatistics");
                }
                if (res6.status === 'fulfilled') {
                    const transformedData = res6.value.data.map(item => ({
                        name: item.name.toUpperCase(), // Convert the name to uppercase if needed
                        y: item.percentage // Use the percentage value for the 'y' field
                    }));
                    setPieChart2({ isLoaded: true, data: transformedData });
                } else {
                    console.log("Error in Monthly Chart:", res6.reason);
                    // errorToast("Error in GetPpmStatistics");
                }
                if (res7.status === 'fulfilled') {
                    const transformedData = res7.value.data.map(item => ({
                        name: item.priority.toUpperCase(), // Convert the name to uppercase if needed
                        y: item.percentage // Use the percentage value for the 'y' field
                    }));
                    setPieChart3({ isLoaded: true, data: transformedData });

                }
                else {
                    console.log("Error in Statistics Data:", res6.reason);
                    // errorToast("Error in GetPpmStatistics");
                }
            }
        } catch (error) {
            console.log(error);
            // errorToast("Error in GetPpmStatistics");
        }


    }

    const fetchEditingPPM = async ({ dispatch, setEditingPPMBeforeUpdate, setIsPPMrecursive, setSelectedArea, setSelectedCategory, setSelectedVendorAssignee, setSelectedAsset, assetId, setUserTypeChecked, setSelectedVendor }) => {
        try {
            const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${assetId}`, 'Fetch Editing PPM')
            const formattedData = {
                ...data,
                building: data?.building?.id,
                floor: data?.floor?.id,
                area: data?.area?.id,
                category: data?.category?.id,
                assetRef: data?.assetRef?.id,
                date: data?.date,
                startTime: data?.startTime,
                endTime: data?.endTime,
                isRecursive: data?.isRecursive,
                recursiveConfiguration: {
                    avoidSaturday: data.recursiveConfiguration?.avoidSaturday,
                    avoidSunday: data.recursiveConfiguration?.avoidSunday,
                    interval: data.recursiveConfiguration?.interval,
                    frequency: data.recursiveConfiguration?.frequency,
                    until: data.recursiveConfiguration?.until,
                },
                currentService: {
                    assignedVendor: data?.currentService?.assignedVendor?.id ?? '',
                    assignedUser: data?.currentService?.assignedUser?.id ?? "",
                    assignedUserType: data?.currentService?.assignedUserType
                },
            }
            if (data?.currentService?.assignedUserType === "external") setUserTypeChecked(true)
            dispatch({ type: 'update', payload: formattedData })
            setEditingPPMBeforeUpdate({ isLoaded: true, data })
            setIsPPMrecursive(data?.isRecursive)
            setSelectedArea(data?.area)
            setSelectedCategory(data?.category)
            setSelectedAsset(data?.assetRef)
            setSelectedVendor(data?.currentService?.assignedVendor)
            setSelectedVendorAssignee(data?.currentService?.assignedUser)
        } catch (error) {
            console.log({ error })
        }
    }

    const fetchOptions = async ({ selectedArea, selectedCategory, optionsDispatch }) => {
        try {
            const buildingId = localStorage.getItem('bid')
            // const { data = {} } = await GET(`${'http://localhost:8030/'}v1/create/options?buildingId=${buildingId}${selectedArea?.id ? `&areaId=${selectedArea?.id}` : ''}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}`, 'PPM Create Form Options')
            const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/create/options?buildingId=${buildingId}${selectedArea?.id ? `&areaId=${selectedArea?.id}` : ''}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}`, 'PPM Create Form Options')
            Object.keys(data)?.length > 0 && Object.keys(data).map(key => optionsDispatch({ type: key, payload: data[key] || [] }))
        } catch (error) {
            console.log({ error })
            if (error?.response) errorToast(error?.response?.data?.message)
            else errorToast(error?.message);
        }
    }

    const fetchVendors = async ({ selectedCategory, PPMData, optionsDispatch, isPPMrecursive }) => {
        try {
            const buildingId = localStorage.getItem('bid')
            let validTillQuery = ''
            if (isPPMrecursive && PPMData?.until) {
                validTillQuery = `&validTill=${PPMData?.until}`
            } else if (!isPPMrecursive && PPMData?.date) {
                validTillQuery = `&validTill=${PPMData?.date}`
            }
            // const { data = {} } = await GET(`${'http://localhost:8030/'}v1/vendors?buildingId=${buildingId}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}`, 'PPM Create Form Vendor Options')
            const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/vendors?buildingId=${buildingId}${selectedCategory?.id ? `&categoryId=${selectedCategory?.id}` : ''}${validTillQuery && validTillQuery}`, 'PPM Create Form Vendor Options')
            if (Boolean(PPMData?.assignedVendor)) {
                const result = data?.find(el => el?.id === PPMData?.assignedVendor)
                if (!result) {
                    // setValidationErrors({ ...validationErrors, inValidVendor: 'Vendor is not valid for the selected range' })
                    errorToast('Vendor is not valid for the selected range')
                    return;
                }
            }
            optionsDispatch({ type: 'vendors', payload: data || [] })
        } catch (error) {
            console.log({ error })
            if (error?.response) errorToast(error?.response?.data?.message)
            else errorToast(error?.message);
        }
    }

    const fetchInternalUsers = async ({ selectedCategory, optionsDispatch }) => {
        try {
            const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/internal/users?categoryId=${selectedCategory?.id}`, 'PPM Create Form Vendor Options')
            optionsDispatch({ type: 'internalAssignee', payload: data?.data || [] })
        } catch (error) {
            console.log(error)
            errorToast(error?.message)
        }
    }

    const getAllCost = async ({ id, setTableData }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/cost/list/?ppmId=${id}`)
            setTableData(response?.data || [])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchUserBasedOnCategories = async ({ task, setUsers, assigneType }) => {
        try {
            if (task?.category.id) {
                const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/category/users?categoryId=${task?.category?.id}&userType=${assigneType}`, 'Get Users')
                setUsers(response?.data)
            }

        } catch (error) {
            errorToast(error?.message)
        }
    }

    const createOrUpdateCost = async ({ formData, id, toggleDrawer, editMode, data, setErrors, handleUpdateParent }) => {

        try {
            const response = editMode ? await PUT(`${process.env.REACT_APP_URL}ppm/v1/cost/?costId=${data._id}`, { ...formData, ppmReference: id }, "Cost Update") : await POST(`${process.env.REACT_APP_URL}ppm/v1/cost`, { ...formData, ppmReference: id }, "Create Cost")
            // updateParentState({ data: response?.data });
            handleUpdateParent()

            if (response.status === 201) {
                toggleDrawer()
                successToast("Cost Created Successfully")
            } else {
                toggleDrawer()
                successToast("Cost Updated Successfully ")
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach(err => {
                    newErrors[err.path] = err.message; // Set each error message
                });
                setErrors(newErrors); // Set errors in state
            }
            console.log(error)
        }

    }

    const deleteCost = async ({ costId, id, tableData, setTableData }) => {
        swal({
            title: 'Alert',
            text: 'Are you sure you want to delete',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/cost/?costId=${costId}&ppmId=${id}`)
                successToast('Successfully Deleted Cost')
                const finalTable = tableData.filter(ele => {
                    return ele._id !== response?.data?._id
                })
                setTableData(finalTable)

            }
        })
            .catch(error => {
                errorToast('Error While Deleting The Cost')
                console.log(error)
            })


    }

    const getMedia = async ({ setLoading, id, setMedia }) => {
        try {
            setLoading(true)
            const mediaResult = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/details/${id}`, "GET single PPM")
            setMedia(mediaResult?.data?.media)
            setLoading(false)
        } catch (error) {
            console.log(error)
            errorToast(error?.response?.data?.message || error?.message)
            setLoading(false)
        }
    }

    const imagesSubmit = async ({ ele, setMedia, id }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/media/${id}`,
                { newMediaArray: [ele] },
                "Update Fault for Media")
            // const response = await PUT(`http://localhost:8030/v1/task/media/${id}`, { newMediaArray: [ele] }, "Update Fault for Media")
            // setMedia(response?.data?.media)
            setMedia(response?.data?.data)

            if (response?.status === 200) {
                successToast('updated media in ppm')
            }

        } catch (error) {
            console.log(error)
        }

    }

    const uploadImages = async ({ event, handleImagesFormSubmit, setMedia, setIsMedia, media, isMedia }) => {
        try {
            // const maxLimit = 5
            //check maxMedia count
            // if (media?.length < maxLimit) {
            const fileData = event.target.files[0]
            const formData = new FormData()
            formData.append('file', fileData)
            const response = await FILEPOST(`${process.env.REACT_APP_URL}ppm/v1/media/upload`, formData, 'File Upload : Add Image', true)
            handleImagesFormSubmit(response.data)
            if (response?.status === 200) {
                setMedia([...media, response?.data])
                setIsMedia(!isMedia)
            }
            // } else {
            //     errorToast(`You can upload only ${maxLimit} medias`)
            // }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }

    }

    const deleteMedia = ({ ele, id, setMedia }) => {
        try {
            swal({
                title: 'Alert',
                text: 'Are you sure you want to permanently delete this media?',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            }).then(async (confirm) => {
                if (confirm) {
                    const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/media?uploadId=${ele?.uploadId}`, "Delete Media")
                    if (response.status === 200) {
                        const updatePPM = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/task/media/${id}/?uploadId=${ele?.uploadId}`)
                        // const updatePPM = await DELETE(`http://localhost:8030/v1/task/media/${id}/?uploadId=${ele?.uploadId}`)
                        setMedia(updatePPM?.data?.data?.media)
                        successToast("Media Deleted Successfully")
                    }
                }
            })


        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }
    }


    const fetchHistory = async ({ id, batchNumber, setHistoryList, setHistoryListLoaded, historyList, setLoadmoreEnded, setRecentHistory }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/ppm-history/${id}?batch=${batchNumber}`, "Fault Report - Get Fault History")
            // const response = await GET(`http://localhost:8004/v2/fault/history?id=${id}`, "Fault Report - Get Fault History")
            if (response?.status === 200) {
                setHistoryList([
                    // ...historyList,
                    ...response?.data])
                if (setRecentHistory) {
                    setRecentHistory([...historyList, ...response?.data].slice(0, 5))

                }
                setHistoryListLoaded(true)
                if (response?.data?.length < 10) setLoadmoreEnded(true)
            }
        } catch (error) {
            console.log(error)
            if (error?.response) errorToast(error?.response?.data?.message)
            else errorToast(error?.message);
            setHistoryListLoaded(true)
        }
    }


    const fetchSingleTask = async ({ id, setIsLoading, setTaskData }) => {
        try {
            setIsLoading(true)
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/details/${id}`, 'Get Single PPM Task')
            setTaskData(response?.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const editPPM = async ({ isRecursive, setEditoptions, setEditOptionModalOpen, setupdatingPPM, setEditPPMModalFormOpen, ppmId }) => {
        try {
            const updateOptionsUrl = `${process.env.REACT_APP_URL}ppm/v1/update/options?type=update`
            const { data = {}, status } = await GET(updateOptionsUrl, 'PPM Create Form Vendor Assignee Options')

            if (status === 200) {
                if (isRecursive) {
                    setEditoptions(data)
                    setEditOptionModalOpen(true)
                    setupdatingPPM(ppmId)
                } else {
                    setEditPPMModalFormOpen(true)
                    setupdatingPPM(ppmId)
                }
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const getGroupedPpmTask = async ({ id, setPPMTasks }) => {
        try {
            const tasksListUrl = `${process.env.REACT_APP_URL}ppm/v1/task/grouped-tasks?id=${id}`
            const { data = {}, status } = await GET(tasksListUrl, 'Get PPM Task List')

            if (status === 200) {
                setPPMTasks(data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const fetchSinglePpm = async ({ selectedPPMId, setPpm }) => {
        try {
            const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/details/${selectedPPMId}`, 'Fetch PPM by Id')
            setPpm({ isLoaded: true, data })
        } catch (error) {
            console.log({ error })
            setPpm({ isLoaded: true, data: {} })
        }
    }

    const changeTaskStatus = async ({ ppmId, taskStatus }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/status`, { ppmId, taskStatus }, 'Change Task Status');
            return response
        } catch (error) {
            console.log({ error })
            if (error?.response) errorToast(error?.response?.data?.message)
            else errorToast(error?.message);
        }
    }

    const changeOverdue = async ({ ppmId, overdueFields }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/overdue`, {
                ppmId,
                overdueFields
            }, 'Change Overdue Date')
            return response
        } catch (error) {
            console.log({ error })
            if (error?.response) errorToast(error?.response?.data?.message)
            else errorToast(error?.message);
        }
    }

    return {
        getPpmStatistics, fetchEditingPPM, fetchOptions, fetchVendors, fetchInternalUsers, getAllCost, fetchUserBasedOnCategories, createOrUpdateCost, deleteCost, getMedia, imagesSubmit,
        uploadImages, deleteMedia, fetchHistory, fetchSingleTask, editPPM, getGroupedPpmTask, fetchSinglePpm, changeTaskStatus, changeOverdue
    }

}
export default useApiCalls