import { Box, Typography } from "@mui/material"
import CommonStyles from "../../../styles/CommonStyles"
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles"
import TabConfigContainer from "../../../features/mrTab/TabConfigContainer/TabConfigContainer"

const MRTabConfig = () => {
    return (
        <Box
            sx={CommonStyles?.OuterBox} height='90vh'>
            <Typography
                sx={DepartmentSettingsStyles.headingTypo}
            >
                Meeting Room Tab Configurations
            </Typography>
            <TabConfigContainer />
        </Box>
    )
}

export default MRTabConfig