import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { GET_CSRF, errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchDigitalDocumentationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchAllCategories: builder.query({
            query: (buildingId) => ({
                url: `doc/v1/categories?building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Categories')
            },
            providesTags: ['digitalDocumentation']
        }),
        addNewCategory: builder.mutation({
            query: ({ name, buildingId }) => ({
                url: `doc/v1/categories?building=${buildingId}`,
                method: "POST",
                body: name,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Add New Category')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        editCategoryName: builder.mutation({
            query: ({ categoryName, categoryId, buildingId }) => ({
                url: `doc/v1/categories/${categoryId}?building=${buildingId}`,
                method: "PUT",
                body: categoryName,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Edit a Category Name')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        deleteACategory: builder.mutation({
            query: ({ categoryId, buildingId }) => ({
                url: `doc/v1/categories/${categoryId}?building=${buildingId}`,
                method: "DELETE",
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Delete a category')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        fetchAllDocumentsOfSelectedCategory: builder.query({
            query: ({ buildingId, categoryId }) => ({
                url: `doc/v1/documents?category=${categoryId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch All Documents of Selected Category')
            },
            providesTags: ['digitalDocumentation']
        }),
        addADocumentToSelectedCategory: builder.mutation({
            query: ({ buildingId, categoryId, document }) => ({
                url: `doc/v1/documents?category=${categoryId}&building=${buildingId}`,
                method: "POST",
                body: document,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Add a document to selected category')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        fetchADocumentById: builder.query({
            query: ({ documentId, categoryId, buildingId }) => ({
                url: `doc/v1/documents/${documentId}?category=${categoryId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch a single document by ID')
            },
            providesTags: ['digitalDocumentation']
        }),
        editADocument: builder.mutation({
            query: ({ buildingId, documentId, document }) => ({
                url: `doc/v1/documents/${documentId}?building=${buildingId}`,
                method: "PUT",
                body: document,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Edit a single document by ID')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        deleteADocument: builder.mutation({
            query: ({ buildingId, categoryId, documentId }) => ({
                url: `doc/v1/documents/${documentId}?category=${categoryId}&building=${buildingId}`,
                method: "DELETE",
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Delete a single document by ID')
            },
            invalidatesTags: ['digitalDocumentation']
        }),
        fetchAllKeyDates: builder.query({
            query: ({ documentId, buildingId }) => ({
                url: `doc/v1/keydates?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch all Keydates by document id')
            },
            providesTags: ['digitalDocumentationKeydate']
        }),
        fetchKeyDateById: builder.query({
            query: ({ keyDateId, documentId, buildingId }) => ({
                url: `doc/v1/keydates/${keyDateId}?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch selected keydate by id')
            },
            providesTags: ['digitalDocumentationKeydate']
        }),
        verifyAKeyDate: builder.mutation({
            query: ({ buildingId, documentId, keyDateId, keyDate }) => ({
                url: `doc/v1/keydates/${keyDateId}?document=${documentId}&building=${buildingId}`,
                method: "PUT",
                body: keyDate,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Digital Documentation Verify a keydate document')
            },
            invalidatesTags: ['digitalDocumentationKeydate']
        }),
        addANewKeyDate: builder.mutation({
            query: ({ buildingId, documentId, keyDate }) => ({
                url: `doc/v1/keydates?document=${documentId}&building=${buildingId}`,
                method: "POST",
                body: keyDate,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Digital Documentation Add a new keydate to the document')
            },
            invalidatesTags: ['digitalDocumentationKeydate']
        }),
        deleteAKeyDate: builder.mutation({
            query: ({ buildingId, documentId, keyDateId }) => ({
                url: `doc/v1/keydates/${keyDateId}?document=${documentId}&building=${buildingId}`,
                method: "DELETE",
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Digital Documentation Delete a keydate for a document')
            },
            invalidatesTags: ['digitalDocumentationKeydate']
        }),
        addAReminder: builder.mutation({
            query: ({ buildingId, documentId, reminder }) => ({
                url: `doc/v1/reminders?document=${documentId}&building=${buildingId}`,
                method: "POST",
                body: reminder,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Add a new reminder for a document')
            },
            invalidatesTags: ['digitalDocumentationReminder']
        }),
        editAReminder: builder.mutation({
            query: ({ buildingId, reminderId, reminder, documentId }) => ({
                url: `doc/v1/reminders/${reminderId}?document=${documentId}&building=${buildingId}`,
                method: "PUT",
                body: reminder,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Edit existing reminder for a document')
            },
            invalidatesTags: ['digitalDocumentationReminder']
        }),
        deleteAReminder: builder.mutation({
            query: ({ buildingId, reminderId, documentId }) => ({
                url: `doc/v1/reminders/${reminderId}?document=${documentId}&building=${buildingId}`,
                method: "DELETE",
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Delete reminder for a document')
            },
            invalidatesTags: ['digitalDocumentationReminder']
        }),
        fetchAReminderById: builder.query({
            query: ({ buildingId, reminderId, documentId }) => ({
                url: `doc/v1/reminders/${reminderId}?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch a reminder by id')
            },
            providesTags: ['digitalDocumentationReminder']
        }),
        fetchAllReminders: builder.query({
            query: ({ documentId, buildingId }) => ({
                url: `doc/v1/reminders?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch all reminders by document id')
            },
            providesTags: ['digitalDocumentationReminder']
        }),
        fetchAllSharedEmails: builder.query({
            query: ({ documentId, buildingId }) => ({
                url: `doc/v1/shares?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch all Shared Email List by document id')
            },
            providesTags: ['digitalDocumentationShare']
        }),
        fetchSharedEmailById: builder.query({
            query: ({ shareId, buildingId }) => ({
                url: `doc/v1/shares/${shareId}?building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch selected Shared Email by shared id')
            },
            providesTags: ['digitalDocumentationShare']
        }),
        editASharedEmailById: builder.mutation({
            query: ({ shareId, buildingId, documentId, share }) => ({
                url: `doc/v1/shares/${shareId}?document=${documentId}&building=${buildingId}`,
                method: "PUT",
                body: share,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Update selected Shared Email by shared id')
            },
            invalidatesTags: ['digitalDocumentationShare']
        }),
        postSharedEmailList: builder.mutation({
            query: ({ documentId, buildingId, shares }) => ({
                url: `doc/v1/shares?document=${documentId}&building=${buildingId}`,
                method: "POST",
                body: { shares },
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Add a Share Email')
            },
            invalidatesTags: ['digitalDocumentationShare']
        }),
        deleteASharedEmail: builder.mutation({
            query: ({ shareId, documentId, buildingId }) => ({
                url: `doc/v1/shares/${shareId}?document=${documentId}&building=${buildingId}`,
                method: "DELETE",
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Digital Documentation Delete a Share Email')
            },
            invalidatesTags: ['digitalDocumentationShare']
        }),
        fetchDocumentLogs: builder.query({
            query: ({ documentId, buildingId }) => ({
                url: `doc/v1/logs?document=${documentId}&building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Fetch Document Logs')
            },
            providesTags: ['digitalDocumentation']
        }),
        postQueryChatResponse: builder.mutation({
            query: ({ documentId, buildingId, query }) => ({
                url: `doc/v1/queries?document=${documentId}&building=${buildingId}`,
                method: 'POST',
                body: query,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Digital Documentation post query chat response')
            },
            invalidatesTags: ['digitalDocumentationChat']
        }),
        getQueryChatResponse: builder.query({
            query: ({ documentId, buildingId, jobId }) => ({
                url: `doc/v1/queries?document=${documentId}&building=${buildingId}&jobId=${jobId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation get query chat response for pdf.')
            },
            providesTags: ['digitalDocumentationChat']
        }),
        fetchDashboardDocumentStats: builder.query({
            query: (buildingId) => ({
                url: `doc/v1/dashboard/s1/data?building=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Digital Documentation Dashboard Stats.')
            },
        }),
    })
})

export const {
    useFetchAllCategoriesQuery,
    useAddNewCategoryMutation,
    useEditCategoryNameMutation,
    useDeleteACategoryMutation,
    useFetchAllDocumentsOfSelectedCategoryQuery,
    useAddADocumentToSelectedCategoryMutation,
    useFetchADocumentByIdQuery,
    useEditADocumentMutation,
    useDeleteADocumentMutation,
    useFetchAllKeyDatesQuery,
    useFetchKeyDateByIdQuery,
    useVerifyAKeyDateMutation,
    useAddANewKeyDateMutation,
    useDeleteAKeyDateMutation,
    useAddAReminderMutation,
    useEditAReminderMutation,
    useDeleteAReminderMutation,
    useFetchAReminderByIdQuery,
    useFetchAllRemindersQuery,
    useFetchAllSharedEmailsQuery,
    useFetchSharedEmailByIdQuery,
    useEditASharedEmailByIdMutation,
    usePostSharedEmailListMutation,
    useDeleteASharedEmailMutation,
    useFetchDocumentLogsQuery,
    usePostQueryChatResponseMutation,
    useGetQueryChatResponseQuery,
    useFetchDashboardDocumentStatsQuery
} = fetchDigitalDocumentationSlice

const initialState = {
    isLoaded: false,
    dashboardStats: {
        isLoaded: false,
        data: {}
    },
    categories: [],
    addNewCategoryName: { name: '' },
    editExistingCategory: {
        isEditing: false,
        id: '',
        name: ''
    },
    selectedDropDownCategory: 'Select a Category',
    selectedCategory: {
        id: '',
        name: '',
        documentCount: 0,
        documents: [],
        selectedDocument: {
            id: '',
            title: '',
            notes: '',
            tags: [],
            category: '',
            docType: '',
            url: '',
            keyDates: [],
            remindersGeneratedKeyDates: [],
            reminders: [],
            selectedReminder: {},
            editSelectedReminder: {},
            addNewReminder: {
                name: '',
                dueDate: '',
                alertDueDate: '',
                notes: '',
                keyDate: undefined
            },
            share: {
                email: '',
                accessExpiry: '',
                fetchedEmailsList: [],
                shareEmailList: [],
                selectedSharedEmail: {
                    email: '',
                    accessExpiry: '',
                },
            },
            logs: [],
            queryChat: [],
            queryChatError: '',
            queryJobId: '',

        }
    },
}

const digitalDocumentationSlice = createSlice({
    name: 'digitalDocumentation',
    initialState,
    reducers: {
        setNewCategoryName: (state, action) => {
            state.addNewCategoryName.name = action.payload
        },
        editExistingCategoryName: (state, action) => {
            state.editExistingCategory.isEditing = true
            state.editExistingCategory.id = action.payload?.id
            state.editExistingCategory.name = action.payload?.name
        },
        cancelEditCategoryName: (state, action) => {
            state.editExistingCategory = initialState.editExistingCategory
        },
        setSelectedCategory: (state, action) => {
            if (action.payload) {
                state.selectedCategory = { ...state.selectedCategory, ...action.payload }
                const selectedCategoryIndex = state.categories?.findIndex(ele => ele?.id === action.payload?.id)
                state.categories?.splice(selectedCategoryIndex, 1)
                state.categories?.unshift(action.payload)
            }
        },
        resetSelectedCategory: (state, action) => {
            state.selectedCategory = initialState.selectedCategory
        },
        setSelectedDropDownCategory: (state, action) => {
            state.selectedDropDownCategory = action.payload
        },
        setSelectedDocument: (state, action) => {
            state.selectedCategory.selectedDocument = { ...state.selectedCategory.selectedDocument, ...action.payload }
        },
        resetSelectedDocument: (state, action) => {
            state.selectedCategory.selectedDocument = initialState.selectedCategory.selectedDocument
        },
        setEditSelectedReminder: (state, action) => {
            state.selectedCategory.selectedDocument.editSelectedReminder = action.payload
        },
        handleEditSelectedReminderChange: (state, action) => {
            state.selectedCategory.selectedDocument.editSelectedReminder[action.payload.name] = action.payload.value
        },
        handleAddNewReminderChange: (state, action) => {
            state.selectedCategory.selectedDocument.addNewReminder[action.payload.name] = action.payload.value
        },
        resetAddNewReminder: (state, action) => {
            state.selectedCategory.selectedDocument.addNewReminder = initialState.selectedCategory.selectedDocument.addNewReminder
        },
        resetSelectedReminder: (state, action) => {
            state.selectedCategory.selectedDocument.selectedReminder = initialState.selectedCategory.selectedDocument.selectedReminder
        },
        handleShareEmailChange: (state, action) => {
            state.selectedCategory.selectedDocument.share[action.payload?.name] = action.payload.value
        },
        handleShareEmailListChange: (state, action) => {
            state.selectedCategory.selectedDocument.share.shareEmailList.push(action.payload)
        },
        handleDeleteShareEmailFromShareEmailList: (state, action) => {
            state.selectedCategory.selectedDocument.share.shareEmailList = action.payload
        },
        resetShareEmailList: (state, action) => {
            state.selectedCategory.selectedDocument.share.shareEmailList = initialState.selectedCategory.selectedDocument.share.shareEmailList
        },
        addQueryMessage: (state, action) => {
            state.selectedCategory.selectedDocument.queryChat.push(action.payload)
        },
        setJobId: (state, action) => {
            state.selectedCategory.selectedDocument.queryJobId = action.payload
        },
        resetAllCategories: (state, action) => {
            state.categories = initialState.categories
        },
        resetDocuentHubDashboardStats: (state, action) => {
            state.dashboardStats = initialState.dashboardStats
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllCategories' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.isLoaded = true
                    state.categories = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllDocumentsOfSelectedCategory' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.documents = action.payload
                    const selectedCategory = state.categories?.find(ele => (ele?.id === action.payload?.[0]?.category || ele?.id === state.selectedCategory?.id))
                    const selectedCategoryIndex = state.categories?.findIndex(ele => (ele?.id === action.payload?.[0]?.category || ele?.id === state.selectedCategory?.id))
                    if (selectedCategory) {
                        state.categories?.splice(selectedCategoryIndex, 1)
                        state.categories?.unshift(selectedCategory)
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchADocumentById' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument = { ...state.selectedCategory.selectedDocument, ...action.payload }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllKeyDates' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.keyDates = action.payload
                    state.selectedCategory.selectedDocument.remindersGeneratedKeyDates = state.selectedCategory.selectedDocument.reminders?.filter(ele => ele?.keyDate)?.map(ele => ele?.keyDate)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAReminderById' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.selectedReminder = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllReminders' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.reminders = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAllSharedEmails' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.share.fetchedEmailsList = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getSharedEmailById' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.share.selectedSharedEmail = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDocumentLogs' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.selectedCategory.selectedDocument.logs = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getQueryChatResponse' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (action.payload.error) {
                        state.selectedCategory.selectedDocument.queryChatError = action.payload.error
                    }
                    else {
                        state.selectedCategory.selectedDocument.queryChat.push({ by: 'AI-bot', message: Object.values(action.payload)?.[0] })
                        state.selectedCategory.selectedDocument.queryChatError = ''
                        state.selectedCategory.selectedDocument.queryJobId = ''
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDashboardDocumentStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboardStats.data = action.payload
                    state.dashboardStats.isLoaded = true
                }
            )
    }
})

export const {
    setNewCategoryName,
    editExistingCategoryName,
    cancelEditCategoryName,
    setSelectedCategory,
    resetSelectedCategory,
    setSelectedDropDownCategory,
    setSelectedDocument,
    resetSelectedDocument,
    setEditSelectedReminder,
    handleEditSelectedReminderChange,
    handleAddNewReminderChange,
    resetAddNewReminder,
    resetSelectedReminder,
    handleShareEmailChange,
    handleShareEmailListChange,
    handleDeleteShareEmailFromShareEmailList,
    resetShareEmailList,
    addQueryMessage,
    setJobId,
    resetAllCategories,
    resetDocuentHubDashboardStats
} = digitalDocumentationSlice.actions

export default digitalDocumentationSlice.reducer
