import React from 'react';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import { Avatar, Box, Grid, IconButton, Tooltip } from '@mui/material';
import CustomTypography from '../../../components/texts/CustomTypography';
import moment from 'moment';
import { ArrowBack, EditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useRouter } from '../../../../../routes/hooks/useRouter';

const InfoRow = ({ label, value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomTypography variant="body1" sx={{ mr: '1rem' }}>{label}</CustomTypography>
        <CustomTypography variant="body1" sx={{ textTransform: typeof value === 'string' ? 'capitalize' : 'none' }}>
            {value || 'N/A'}
        </CustomTypography>
    </Box>
);

const View = ({ userData }) => {
    const router = useRouter();
    const { fileReadUrl } = useSelector((state) => state?.configurations);

    const { userRef, department, reportingChain, buildings, bloodGroup, dateOfJoining, dateOfBirth, emergencyContact, address, deskNumber, parkingSlotNumber } = userData || {};

    const commonStyle = {
        border: '1px solid #e0e0e0',
        borderRadius: '1rem',
        padding: '16px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '.75rem',
    };

    return (
        <Box sx={{ m: 4, minHeight: '81vh', width: '100%' }}>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Back">
                    <IconButton
                        sx={{
                            mr: 2,
                            backgroundColor: '#dc4d28',
                            color: 'white',
                            '&:hover': { backgroundColor: '#b64b1e' },
                            height: '2rem',
                            width: '2rem'
                        }}
                        onClick={() => router.back()}
                    >
                        <ArrowBack sx={{ color: '#fff' }} />
                    </IconButton>
                </Tooltip>
                <CustomTypography variant="h5" sx={{ mb: 2 }}>{`${userRef?.fullName?.toUpperCase()} - DETAILS`}</CustomTypography>
                <EditOutlined
                    sx={{ mt: '4px', color: '#dc4d28', cursor: 'pointer' }}
                    //   onClick={() => navigate(`/admin/usermanagement/edit/${userRef?._id}`, { state: { type: 'edit' } })}
                    onClick={() => router.push(`/admin/usermanagement/edit/${userRef?._id}`, { state: { type: 'edit' } })}
                />
            </Box>

            <CustomComponentContainer sx={{ p: 2, borderRadius: '1rem !important' }}>
                <Grid container sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Grid item xs={4} sx={commonStyle}>
                        <CustomTypography variant="h6" sx={{ mb: 1 }}>Profile Picture</CustomTypography>
                        <Avatar sx={{ width: 120, height: 120 }} src={`${fileReadUrl}${userData?.profilePictureUrl}`} />

                        <CustomTypography variant="h6" sx={{ mt: 4, mb: 2 }}>Personal Information</CustomTypography>
                        <InfoRow label="Full Name:" value={userRef?.fullName} />
                        <InfoRow label="Employee Code:" value={userRef?.empCode} />
                        <InfoRow label="Email:" value={userRef?.email} />
                        <InfoRow label="Mobile:" value={userData?.mobile} />
                        <InfoRow label="About:" value={userData?.about} />
                    </Grid>

                    <Grid item xs={8} sx={{ pl: '1rem', display: 'flex', gap: '1rem' }}>
                        <Grid container>
                            {/* Department Info */}
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Department Information</CustomTypography>
                                <InfoRow label="Department:" value={department?.name} />
                                <InfoRow label="Reports To:" value={userData?.reportsTo?.fullName} />
                                <InfoRow label="Date of Joining:" value={dateOfJoining ? moment(dateOfJoining).format('DD/MM/YY') : ''} />
                            </Grid>

                            {/* Role & Building Info */}
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Role & Building Information</CustomTypography>
                                <InfoRow label="Current Building:" value={buildings?.find((b) => b._id === userData?.buildingId?._id)?.name} />
                                <InfoRow label="Role:" value={userRef?.role} />
                                <InfoRow label="All Buildings:" value={buildings?.map((el) => el.name).join(', ')} />
                            </Grid>
                        </Grid>

                        <Grid container>
                            {/* Personal Details */}
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Personal Details</CustomTypography>
                                <InfoRow label="Blood Group:" value={bloodGroup?.toUpperCase()} />
                                <InfoRow label="Date of Birth:" value={dateOfBirth ? moment(dateOfBirth).format('DD/MM/YY') : ''} />
                                <InfoRow label="Emergency Contact:" value={emergencyContact?.name} />
                                <InfoRow label="Emergency Contact Mobile:" value={emergencyContact?.mobile} />
                                <InfoRow label="Emergency Contact Relation:" value={emergencyContact?.relation} />
                                <InfoRow label="Address:" value={address} />
                            </Grid>

                            {/* Workspace Info */}
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Workspace Information</CustomTypography>
                                <InfoRow label="Desk Number:" value={deskNumber} />
                                <InfoRow label="Parking Slot:" value={parkingSlotNumber} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomComponentContainer>
        </Box>
    );
};

export default View;
