const KioskSessionsContainerStyles = {
    boxContainer: {
        ml: 1,
        height: "80vh",
        width: "99%",
    }


}

export default KioskSessionsContainerStyles