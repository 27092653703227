import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedBuilding } from '../../../../../../../redux/features/buildingsSlice';
import { GET } from '../../../../../helpers/http';
import { ThemeColors } from '../../../../../hooks/ThemeColors';


const Autodesk = window.Autodesk;
const THREE = window.THREE

const ForgeTopBar = (props) => {

    const { viewer, getModelUrn, forge } = props

    const themeMode = useSelector(state => state.theme.darkTheme)

    const { textColor } = ThemeColors()


    const dispatch = useDispatch()

    const buildingListFromStore = useSelector((state) => state.buildings)


    const [buildingList, setBuildingList] = useState([])
    const [defaultLeafs, setDefaultLeafs] = useState([])
    const [building, setBuilding] = useState("")
    const [levels, setLevels] = useState("")
    const [rooms, setRooms] = useState("")
    const [layers, setLayers] = useState([])
    const [levelList, setLevelList] = useState([])
    const [defaultDisciplineDataList, setDefaultDisciplineDataList] = useState({})
    const [viewBubbleNode, setViewBubbleNode] = useState([])
    const [roomModel, setRoomModel] = useState(null)
    const [roomDbIdsBasedOnLevel, setRoomDbIdsBasedOnLevel] = useState({})
    const [formattedRoomDbIdsBasedOnLevel, setFormattedRoomDbIdsBasedOnLevel] = useState([])
    const [roomListActive, setRoomListActive] = useState(false)


    async function createBubbleNodeListAndSetLevel() {
        const doc = viewer?.model?.getDocumentNode()?.getDocument();
        const rootNode = doc.getRoot();
        const views = rootNode.search({ 'type': 'geometry', 'role': '3d' });
        setViewBubbleNode(views)
        const levelsNames = views?.map(view => {
            return { name: view?.data?.name, role: view?.data?.role, guid: view?.data?.guid }
        })
        setLevelList(levelsNames)
        !levels && setLevels(levelsNames[0]?.name)
        window.factory.level = levelsNames[0]?.name;//Not Recommended to do this
    }


    function getAllDbIds() {
        console.count("----------------Getting all DbIds-----------------")
        return new Promise((resolve, reject) => {
            try {
                const instanceTree = viewer?.model?.getData().instanceTree;
                const allDbIdsStr = Object.keys(instanceTree?.nodeAccess?.dbIdToIndex);
                const allDbIds = allDbIdsStr.map(id => Number(id));
                resolve(allDbIds.slice(2))
            } catch (error) {
                return reject(error)
            }
        });
    }

    function getBulkPropertiesAsync(model, dbIds) {
        return new Promise((resolve, reject) => {
            model.getBulkProperties2(
                dbIds,
                {
                    propFilter: ['name', 'DT_LEVEL', 'externalId']
                },
                (result) => resolve(result),
                (error) => reject(error),
            );
        });
    }

    function getPropertiesAsync(dbId, model) {
        return new Promise((resolve, reject) => {
            model.getProperties(
                dbId,
                (result) => resolve(result),
                (error) => reject(error)
            );
        });
    };

    const getRoomDbIds = () => {
        return new Promise((resolve, reject) => {
            viewer.search(
                'Revit Rooms',
                (dbIds) => resolve(dbIds),
                (error) => reject(error),
                ['Category'],
                { searchHidden: true }
            );
        });
    };

    async function getBoundingBox(dbId) {
        const model = roomModel;
        const it = model.getInstanceTree();
        const fragList = model.getFragmentList();
        let bounds = new THREE.Box3();

        it.enumNodeFragments(dbId, (fragId) => {
            let box = new THREE.Box3();
            fragList.getWorldBounds(fragId, box);
            bounds.union(box);
        }, true);

        return bounds;
    }

    async function tweenToPoint({ point, roomSideWallCenter }) {
        await viewer.setActiveNavigationTool('bimwalk');

        const views = [];
        const up = new THREE.Vector3(0, 0, 1);
        views.push({
            up: up.toArray(),
            eye: roomSideWallCenter.toArray(),
            target: point.toArray()
        });

        await processTweens(views);
    }

    async function processTweens(data) {
        //process each promise
        //refer to http://jsfiddle.net/jfriend00/h3zaw8u8/
        const promisesInSequence = (tasks, callback) => {
            const results = [];
            return tasks.reduce((p, item) => {
                return p.then(() => {
                    return callback(item).then((data) => {
                        results.push(data);
                        // console.log({ results });
                        return results;
                    });
                });
            }, Promise.resolve());
        };

        //start to process
        return promisesInSequence(data, (d) => executeTweenPromised(d));
    }

    async function executeTweenPromised(view) {
        const cameraTweenTool = await viewer.getExtension('Autodesk.ADN.CameraTweenTool');
        return new Promise((resolve, reject) => {
            const onTweenExecuted = (event) => {
                // console.log(event);
                event.status === 'completed' && setRoomListActive(false);
                viewer.removeEventListener(
                    Autodesk.ADN.CameraTweenTool.CAMERA_TWEEN_ANIMATION_COMPLETED_EVENT,
                    onTweenExecuted
                );

                resolve();
            };

            viewer.addEventListener(
                Autodesk.ADN.CameraTweenTool.CAMERA_TWEEN_ANIMATION_COMPLETED_EVENT,
                onTweenExecuted
            );
            cameraTweenTool.tweenCameraTo({ viewport: view });
        });
    }

    async function assignDefaultDisciplineDataList() {
        const parentData = await getAllDbIds()
        const modelData = {};
        parentData.forEach((dbId, i) => {
            viewer.getProperties(dbId, function (props) {
                const dtServices = props?.properties?.find(property => property.displayName === "DT_DISCIPLINE") || null//To get the DT_Services - Can be changed and Modified
                if (dtServices?.displayValue && (modelData[dtServices?.displayValue] === null || modelData[dtServices?.displayValue] === undefined)) modelData[dtServices?.displayValue] = [];

                if (Array.isArray(modelData[dtServices?.displayValue]) && dtServices?.displayValue) {
                    modelData[dtServices?.displayValue].push({ dbId: dbId, name: props.name, extId: props.externalId });
                }
                if (parentData.length - 1 === i) {
                    const newModelData = Object.keys(modelData).reduce((acc, key) => {
                        const lowerKey = key.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
                        acc[lowerKey] = modelData[key];
                        return acc;
                    }, {});
                    setDefaultDisciplineDataList(newModelData)
                    const leafsData = Object.values(newModelData).flat().map(leaf => leaf.dbId);
                    setDefaultLeafs(Array.isArray(leafsData) ? leafsData : leafsData)
                }
            })
        }
        )
    }
    // async function workerFunction(viewer, setDefaultDisciplineDataList, setDefaultLeafs) {
    //     try {
    //         const dbIds = await getAllDbIds()
    //         const properties = await viewer.model?.getPropertyDb().executeUserFunction(function userFunction(pdb, dbIds) {
    //             const modelData = {};
    //             let result = {};
    //             dbIds.forEach((dbId, i) => {
    //                 const props = pdb.getObjectProperties(dbId)
    //                 const dtServices = props?.properties?.find(property => property.displayName === "DT_DISCIPLINE") || null//To get the DT_Services - Can be changed and Modified
    //                 // const level = props.properties.find(property => property.displayName === "DT_LEVEL");//To get the Level - Can be changed and Modified

    //                 if (dtServices?.displayValue && (modelData[dtServices?.displayValue] === null || modelData[dtServices?.displayValue] === undefined)) modelData[dtServices?.displayValue] = [];

    //                 if (Array.isArray(modelData[dtServices?.displayValue]) && dtServices?.displayValue) {
    //                     modelData[dtServices?.displayValue].push({ dbId: dbId, name: props.name, extId: props.externalId });
    //                 }
    //                 if (dbIds.length - 1 === i) {
    //                     const newModelData = Object.keys(modelData).reduce((acc, key) => {
    //                         const lowerKey = key.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
    //                         acc[lowerKey] = modelData[key];
    //                         return acc;
    //                     }, {});
    //                     result = newModelData
    //                 }
    //             })
    //             return result
    //         }, dbIds);
    //         setDefaultDisciplineDataList(properties)
    //         const leafsData = Object.values(properties).flat().map(leaf => leaf.dbId);
    //         setDefaultLeafs(Array.isArray(leafsData) ? leafsData : leafsData)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    async function loadRoomModel() {
        try {
            console.count('-----------------LoadRoomModel------------------')
            const level = window.factory.level
            const roomDbIdArray = window?.factory?.roomsBasedOnLevel?.[level] ? window?.factory?.roomsBasedOnLevel?.[level].map(d => d.dbId) : null
            const roomDbIds = roomDbIdArray || await getRoomDbIds();

            if (!roomDbIds || roomDbIds.length <= 0) {
                throw new Error('No Rooms found in current model');
            }

            const doc = viewer?.model?.getDocumentNode().getDocument();
            await viewer.loadExtension("Autodesk.DataVisualization");

            const firstRoomProps = await getPropertiesAsync(roomDbIds[0], viewer.model)


            const possibleViewableIds = firstRoomProps?.properties?.filter(prop => prop.attributeName === 'viewable_in').map(prop => prop.displayValue);

            let masterViewBubble = null;

            for (let i = 0; i < possibleViewableIds.length; i++) {
                const bubbles = doc.getRoot().search({ 'viewableID': possibleViewableIds[i] });

                if (!bubbles || bubbles.length <= 0)
                    continue;

                const bubble = bubbles[0];
                if (bubble.is2D())
                    continue;

                masterViewBubble = bubble;
            }

            if (!masterViewBubble) {
                throw new Error('No Room model found in current model');
            }

            const roomViewerModel = await viewer.loadDocumentNode(
                doc,
                masterViewBubble,
                {
                    ids: roomDbIds,
                    //skipHiddenFragments: false,
                    keepCurrentModels: true,
                    // modelSpace: true, 
                    applyRefPoint: true,
                    preserveView: true,
                    globalOffset: new THREE.Vector3(),//this.viewer.model.getGlobalOffset(),
                    placementTransform: viewer.model.getModelToViewerTransform()
                }
            );
            setRoomListActive(false);
            setRoomModel(roomViewerModel);

            await viewer.waitForLoadDone();

        } catch (ex) {
            console.warn(`[Load Room Models]: ${ex}`);
            throw new Error('Failed to load room models', ex);
        }
    }


    const getLevelUsingViewer = async () => {
        try {
            viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, async function (event) {
                if (event?.model?.getDocumentNode()?.data?.name === viewer?.model?.getDocumentNode()?.data?.name) { // Not the Way to do it , should remove the event listeners after or do something else
                    console.count('---------------Object Tree Created----------------')
                    await assignDefaultDisciplineDataList();
                    // await workerFunction(viewer, setDefaultDisciplineDataList, setDefaultLeafs);
                    // (window.factory.level?.toLowerCase().includes('overview') === false) && 
                    await loadRoomModel();
                }
            });

            viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, async function (event) {
                if (event?.model?.getDocumentNode()?.data?.name === viewer?.getAllModels()[1]?.getDocumentNode()?.data?.name) { // Not the Way to do it , should remove the event listeners after or do something else
                    console.count('---------------Model Root Loaded---------------')
                    const vizManager = viewer?.impl?.visibilityManager
                    const level = window.factory.level
                    const roomDbIdArray = window?.factory?.roomsBasedOnLevel?.[level] ? window?.factory?.roomsBasedOnLevel?.[level].map(d => d.dbId) : null
                    const roomDbId = roomDbIdArray || await getRoomDbIds();
                    if (vizManager && vizManager?.models?.[1] && roomDbId.length > 0 && Object.keys(window?.factory?.roomsBasedOnLevel || {})?.length === 0) {
                        console.count('-----------Creating Room Object Tree------------')
                        // roomDbId.forEach(dbId => vizManager?.setNodeOff(dbId, true, vizManager?.models?.[1]));
                        const roomData = await getBulkPropertiesAsync(vizManager?.models?.[1], roomDbId);
                        const roomDbIdsUsingLevels = roomData.reduce((acc, obj) => {
                            const { name, dbId, externalId, properties } = obj;

                            if (properties.length > 0) {
                                const level = properties?.[0]?.displayValue;

                                if (!acc[level]) {
                                    acc[level] = [];
                                }

                                acc[level].push({ name, dbId, externalId });
                            }

                            return acc;
                        }, {});
                        roomDbIdsUsingLevels["OVERVIEW"] = Object.values(roomDbIdsUsingLevels).flatMap(dbId => [...dbId])
                        window.factory.roomsBasedOnLevel = roomDbIdsUsingLevels
                        setRoomDbIdsBasedOnLevel(roomDbIdsUsingLevels);
                        setFormattedRoomDbIdsBasedOnLevel(roomDbIdsUsingLevels['OVERVIEW']);
                        // window.factory.level && roomDbIdsBasedOnLevel[window.factory.level] && setFormattedRoomDbIdsBasedOnLevel(roomDbIdsUsingLevels[window.factory.level]);
                        await viewer.waitForLoadDone()
                    }
                }
            });

            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async function (event) {
                console.count('-----------------Geometry Loaded----------------')
                await createBubbleNodeListAndSetLevel();//On second Load it should not happen 
                // await loadRoomModel();
                const propertiesPanel = viewer.loadedExtensions['Autodesk.PropertiesManager']
                propertiesPanel?.activate()
                propertiesPanel?.deactivate()
                // Desk Color
                const forgeDeskData = forge?.deskOccupancyData
                const forgeMeetingRoomData = forge?.meetingRoomOccupancyData
                if (Object.keys(forgeDeskData?.initialOccupiedData).length > 0) {
                    const configDeskData = forge.initialConfig?.deskOccupancySensors
                    for (let sensorId in configDeskData) {
                        const dbId = await getDbIdUsingExternalId(configDeskData[sensorId])
                        if (forgeDeskData?.initialOccupiedData[sensorId] === true) {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1))
                        } else if (forgeDeskData?.initialOccupiedData[sensorId] === "NA") {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(1, 1, 1, 1))
                        } else {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1))
                        }
                    }
                }
                // Meeting Room Color 
                if (Object.keys(forgeMeetingRoomData?.initialOccupiedData).length > 0) {
                    const configRoomData = forge.initialConfig?.meetingRoomOccupancySensors
                    for (let areaId in configRoomData) {
                        const dbId = await getDbIdUsingExternalId(configRoomData[areaId])
                        if (forgeMeetingRoomData?.initialOccupiedData[areaId]?.meetingRoomOccupancy > 0 && forgeMeetingRoomData?.initialOccupiedData[areaId]?.isLatest) {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1))
                        } else if (forgeMeetingRoomData?.initialOccupiedData[areaId]?.meetingRoomOccupancy === 0 && forgeMeetingRoomData?.initialOccupiedData[areaId]?.isLatest) {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1))
                        } else {
                            viewer?.model?.setThemingColor(dbId, new THREE.Vector4(1, 1, 1, 1))
                        }
                    }
                }
            }, { once: true });
        } catch (error) {
            console.log('Error in getLevelUsingViewer:', error)
        }
    }

    const getBuildingList = async () => {
        const buildings = buildingListFromStore?.buildings?.length > 0 && await createBuildingList(buildingListFromStore)
        setBuildingList(buildings)
    }


    async function createBuildingList(buildingListFromStore) {
        try {
            const buildingArray = await Promise.all(
                buildingListFromStore?.buildings?.map(async (building) => {
                    try {
                        const response = await GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/config?bid=${building?._id}`, "Forge Initial Setup");

                        if (response?.status === 200) {
                            return {
                                ...building,
                                viewerUrn: response?.data?.viewerUrn,
                            };
                        }

                        return building;
                    } catch (error) {
                        console.error("Error processing building:", error);
                        throw error;
                    }
                })
            );
            return buildingArray;
        } catch (error) {
            console.error("Error in createBuildingList:", error);
            throw error;
        }
    }

    async function getDbIdUsingExternalId(externalId) {
        if (externalId && viewer) {
            return new Promise(resolve => {
                viewer.model?.getExternalIdMapping(data => {
                    let dbId = data[externalId]
                    return resolve(dbId)
                })
            })
        }
    }
    async function isolateFull(viewer, dbIds = [], model = null) {
        try {
            if (!viewer) return;
            model = model || viewer.activeModel || viewer.model

            const vizMng = viewer.impl.visibilityManager

            vizMng.isolate(dbIds, model)

            const targetIds = Array.isArray(dbIds) ? dbIds : [dbIds]

            // const targetLeafIds = await getLeafNodes(model, targetIds)

            const targetLeafIds = targetIds

            const leafIds = defaultLeafs.length > 0 ? defaultLeafs : await getAllDbIds()//Needs to be changed

            const leafTasks = leafIds.map((dbId) => {
                return new Promise((resolveLeaf) => {
                    const show = !targetLeafIds.length || targetLeafIds.indexOf(dbId) > -1
                    viewer[show ? 'show' : 'hide'](dbId, model)
                    vizMng.setNodeOff(dbId, !show, model)
                    resolveLeaf()
                })
            })

            return Promise.all(leafTasks)
        } catch (ex) {
            return Promise.reject(ex)
        }
    }


    const selectedBuildingId = localStorage.getItem('bid')

    useEffect(() => {
        if (
            selectedBuildingId === null ||
            selectedBuildingId === undefined ||
            selectedBuildingId === 'undefined' ||
            selectedBuildingId === ''
        ) {
            if (buildingListFromStore.selectedBuilding)
                localStorage.setItem('bid', buildingListFromStore.selectedBuilding)
        }
    }, [buildingListFromStore.selectedBuilding])

    useEffect(() => {
        building && getModelUrn(buildingList.find(b => b.name === building)?.viewerUrn)
    }, [building])

    useEffect(() => {
        buildingList.length > 0 && setBuilding(buildingList?.find(b => b._id === buildingListFromStore?.selectedBuilding)?.name);
    }, [buildingList])

    useEffect(() => {
        getBuildingList()
        getLevelUsingViewer()
    }, [])

    const getObjectDbIds = async (data, clickedCategories) => {
        // Filter out non-existent categories

        const validCategories = clickedCategories.filter(category => data.hasOwnProperty(category));

        // Use flatMap to directly extract dbId values
        const dbIdsArray = validCategories.flatMap(category => data[category].map(item => item.dbId));

        defaultDisciplineDataList && await isolateFull(viewer, dbIdsArray, viewer.model);
    };

    const handleSelectChange = async (e) => {
        if (e.target.name === "Building") {
            const buildingId = buildingList.find(building => building.name === e.target.value)
            setBuilding(e.target.value)
            localStorage.setItem('bid', buildingId?._id)
            dispatch(setSelectedBuilding(buildingId?._id))
            getLevelUsingViewer()
            setLevels("")
            setRooms("")
            setLevelList([])
            setLayers([])
            setRoomDbIdsBasedOnLevel({})
        } else if (e.target.name === "Level") {
            setLevels(e.target.value)
            window.factory.level = e.target.value
            setLayers([])
            setDefaultLeafs([])
            setRoomListActive(true)
            setRoomModel(null)
            window.factory?.roomsBasedOnLevel?.[e.target.value] && setFormattedRoomDbIdsBasedOnLevel(window.factory?.roomsBasedOnLevel?.[e.target.value])
            await viewer.tearDown()
            if (levelList.length > 0) {
                const bubbleNode = viewBubbleNode?.find(view => view?.data?.name === e.target.value)
                const doc = bubbleNode?.getDocument()
                await viewer.loadDocumentNode(doc, bubbleNode)
                await viewer.loadExtension('Autodesk.AEC.LevelsExtension')//Should change on requirement
                await viewer.waitForLoadDone({ propDb: true })
            }
        } else if (e.target.name === "Room") {
            setRooms(e.target.value)
            const selectedRoomDbId = formattedRoomDbIdsBasedOnLevel?.find(room => room?.name === e.target.value).dbId
            if (selectedRoomDbId && viewer) {
                viewer.fitToView(Array.isArray(selectedRoomDbId) ? selectedRoomDbId : [selectedRoomDbId], roomModel, false);
                // viewer && roomDbIds.length > 0 && roomDbIds.forEach(async id => await viewer.impl?.highlightObjectNode(roomModel, id, false))
            }
            setRoomListActive(true)
            const bbox = await getBoundingBox(selectedRoomDbId)
            const center = bbox.getCenter()
            const point = new THREE.Vector3(center.x, center.y, (bbox.max.z + bbox.min.z) / 2);
            const roomSideWallCenter = new THREE.Vector3((bbox.max.x + bbox.min.x) / 2, bbox.min.y + 1.3, (bbox.min.z + bbox.max.z) / 2);
            await tweenToPoint({ point, roomSideWallCenter })
        } else if (e.target.name === "Layer") {
            const { target: { value } } = e;
            setLayers(typeof value === 'string' ? value.split(',') : value)
            getObjectDbIds(defaultDisciplineDataList, value)
        }
    }

    const highlightRoomOnHover = async (id) => {
        viewer && await viewer.impl?.highlightObjectNode(roomModel, id, true, false)
    }

    const removeHighlightOnLeavingItem = async (id) => {
        viewer && await viewer.impl?.highlightObjectNode(roomModel, id, false)
    }

    // console.log({ layers, defaultDisciplineDataList, levels, defaultLeafs, viewBubbleNode, levelList, roomModel, roomDbIdsBasedOnLevel, formattedRoomDbIdsBasedOnLevel, building })

    // const bgcolor = background_color()

    return (
        <div style={{ position: "absolute", top: 0, width: "75%", backgroundColor: "transparent", zIndex: 100, display: "flex", columnGap: "5px", justifyContent: "space-between" }}>
            <div>
                <FormControl sx={{ width: "150px", mt: 1, ml: 1 }} size='small'>
                    <InputLabel sx={{ color: 'black' }}>Select building</InputLabel>
                    <Select
                        sx={{
                            color: 'black',
                            fontStyle: 'normal',
                            // bgcolor,
                            '.MuiOutlinedInput-notchedOutline': {
                                border: `0.5px solid black`,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: `0.5px solid black`,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: `0.5px solid black`,
                            },
                            '.MuiSvgIcon-root ': {
                                fill: `black`,
                            }
                        }}
                        MenuProps={{
                            disableScrollLock: 'true',
                            className: themeMode ? 'menu-container-dark' : 'menu-container-light'
                        }}
                        value={building}
                        onChange={handleSelectChange}
                        label="Select building"
                        name="Building"
                    >
                        {
                            buildingList?.length > 0 && buildingList?.map((item, i) => <MenuItem key={i} value={item?.name} >{item?.name} </MenuItem>)
                        }

                    </Select>
                </FormControl>

                {
                    (building && levelList?.length > 0) && <FormControl sx={{ width: "150px", mt: 1, ml: 1 }} size='small'>
                        <InputLabel sx={{ color: 'black' }}>Select level</InputLabel>
                        <Select
                            sx={{
                                color: "black",
                                fontStyle: 'normal',
                                // bgcolor,
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: `black`,
                                }
                            }}
                            value={levels}
                            onChange={handleSelectChange}
                            label="Select level"
                            name="Level"
                            MenuProps={{
                                disableScrollLock: 'true',
                                className: themeMode ? 'menu-container-dark' : 'menu-container-light'
                            }}
                        >
                            {
                                levelList?.length > 0 && levelList?.map((item) => <MenuItem key={item?.guid} value={item?.name}
                                    disabled={Object.keys(roomDbIdsBasedOnLevel).length === 0}
                                >{item?.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                }
                {

                    levels && building && formattedRoomDbIdsBasedOnLevel?.length > 0 && <FormControl sx={{ width: "150px", mt: 1, ml: 1 }} size='small'>
                        <InputLabel sx={{ color: 'black' }}>Select Room</InputLabel>
                        <Select
                            sx={{
                                color: 'black',
                                fontStyle: 'normal',
                                // bgcolor,
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: `black`,
                                }
                            }}
                            value={rooms}
                            onChange={handleSelectChange}
                            label="Select room"
                            name="Room"
                            MenuProps={{
                                disableScrollLock: 'true',
                                className: themeMode ? 'menu-container-dark' : 'menu-container-light'
                            }}
                        >
                            {
                                formattedRoomDbIdsBasedOnLevel?.length > 0 && formattedRoomDbIdsBasedOnLevel?.map((item) => <MenuItem key={item?.dbId} value={item?.name} disabled={roomListActive} onMouseEnter={() => highlightRoomOnHover(item?.dbId)} onMouseLeave={() => removeHighlightOnLeavingItem(item?.dbId)} onClick={() => removeHighlightOnLeavingItem(item?.dbId)}>{item?.name}</MenuItem>)
                            }

                        </Select>
                    </FormControl>

                }
            </div>

            <>
                {
                    building && <FormControl sx={{ width: "150px", mt: 1, ml: 1 }} size='small'>
                        <InputLabel sx={{ color: 'black' }}>Layers</InputLabel>
                        <Select
                            sx={{
                                color: "black",
                                fontStyle: 'normal',
                                // bgcolor,
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: `0.5px solid black`,
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: `black`,
                                }
                            }}
                            value={layers}
                            onChange={handleSelectChange}
                            label="Layers"
                            name="Layer"
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                disableScrollLock: 'true',
                                className: themeMode ? 'menu-container-dark' : 'menu-container-light'
                            }}
                        >
                            {
                                Object.keys(defaultDisciplineDataList)?.length > 0 && Object.keys(defaultDisciplineDataList)?.map((item, i) => {
                                    return (
                                        <MenuItem key={i} value={item}>
                                            {layers.indexOf(item) > -1 ? (
                                                <ListItemIcon>
                                                    <VisibilityIcon sx={{ color: textColor() }} />
                                                </ListItemIcon>
                                            ) : (
                                                <ListItemIcon>
                                                    <VisibilityOffIcon sx={{ color: textColor() }} />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText primary={`${item} (${defaultDisciplineDataList?.[item]?.length})`} />
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                }
            </>

        </div>
    )
}

export default ForgeTopBar