import { Avatar, Box, Grid, styled } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import { Delete } from "@mui/icons-material"
import { ThemeColors } from "../../../hooks/ThemeColors";
import { useEffect, useState } from "react";
import Loader from "../../../components/loading/Loader";
import Modal from "../../../components/modals/NewModal";
import { useSelector } from "react-redux";
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import ReturnMultiTypeMedia from "../../faultReports/newFaultReports/components/returnMultiTypeMedia";
import AddMediaCard from "../AddMediaCard";
import AddMediasForm from "../components/taskView/AddMediaForm";
import { useParams } from "react-router-dom";
import useApiCalls from "../components/hooks/useApiCalls";

const Medias = ({ isDeleted }) => {
    const { themeMode } = ThemeColors();
    const { getMedia, imagesSubmit, uploadImages, deleteMedia } = useApiCalls()
    const [loading, setLoading] = useState(false)
    const [media, setMedia] = useState([])
    const [mediaChange, setMediaChange] = useState(false)
    const [addMediaModalOpen, setAddMediaModalOpen] = useState(false)
    const fileReadUrl = useSelector(state => state?.configurations?.ppmfileReadUrl)
    const [modalOpen, setModalOpen] = useState(false)
    const [isMedia, setIsMedia] = useState(false)
    const { id } = useParams()
    const [selectedMedia, setSelectedMedia] = useState({})

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        getMedia({ setLoading, id, setMedia })
        // (async () => {
        //     try {
        //         setLoading(true)
        //         const mediaResult = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${id}`, "GET single PPM")
        //         setMedia(mediaResult?.data?.media)
        //         setLoading(false)
        //     } catch (error) {
        //         console.log(error)
        //         errorToast(error?.response?.data?.message || error?.message)
        //         setLoading(false)
        //     }
        // })();
    }, [])

    const handleClose = () => {
        setModalOpen(false);
    }

    const plyrOptions = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
        hideControls: false,
        ratio: "16:9",
        settings: ['captions', 'quality', 'speed', 'loop'],
    }

    const mediaTriggerhandle = () => {
        setMediaChange(!mediaChange)
    }

    const mediaShow = (media) => {
        setSelectedMedia(media)
        setModalOpen(true)
    }

    const handleImagesFormSubmit = async (ele) => {
        imagesSubmit({ ele, setMedia, id })
        // onImageUpload()

    }

    const handleImage = async ({ event }) => {
        uploadImages({ event, handleImagesFormSubmit, setMedia, setIsMedia, media, isMedia })
    }

    const handleDeleteMedia = async (ele) => {
        deleteMedia({ ele, id, setMedia })

    }

    return (
        <>
            {!loading ?
                <CustomComponentContainer
                    sx={{
                        margin: '1rem',
                        padding: "1rem",
                    }}
                >
                    <Grid container spacing={2} minHeight={600}>
                        {/* <AddMediasForm sx={{ height: "10rem", width: "100vw" }} setMedia={setMedia} /> */}
                        {!isDeleted && <Grid item sm={6} md={4} lg={3} xl={2}>
                            <AddMediaCard
                                sx={{ height: "10rem", width: "15rem" }}
                                type="add"
                                handleAddImage={handleImage}
                            // id={id}
                            />
                        </Grid>}

                        {media?.map((ele, i) => (
                            <Grid
                                key={i}
                                item sm={6} md={4} lg={3} xl={2}
                            >
                                <Box sx={{
                                    position: "relative",
                                    backgroundColor: themeMode ? '#5a5a5a' : '#eceff4',
                                    borderRadius: '1rem',
                                    width: "15rem", // Ensure the width is dynamic based on the grid size
                                    height: "10rem",
                                    overflow: "hidden",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '1px solid lightgray',
                                }}>
                                    {/* Handle image, video, or pdf rendering */}
                                    {ele?.type?.includes("image") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                            overflow: 'hidden',

                                        }}
                                            onClick={() => { mediaShow(ele) }}
                                        >
                                            <img
                                                src={`${fileReadUrl}${ele?.key}`}
                                                style={{
                                                    cursor: 'pointer',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                                alt={ele?.key}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("pdf") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: "10rem",
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            borderRadius: '1rem',
                                            objectFit: "fill"
                                        }}>
                                            <iframe
                                                title="pdf"
                                                src={`${fileReadUrl}${ele?.key}`}
                                                type="application/pdf"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("video") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '1rem',
                                            height: "10rem",
                                            overflow: 'hidden',
                                        }}>
                                            <Plyr
                                                source={{ type: "video", sources: [{ src: `${fileReadUrl}${ele?.key}` }] }}
                                                options={plyrOptions}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <p>No Media</p>
                                        </Box>
                                    )}

                                    {/* Delete icon for user-uploaded media */}

                                    {!isDeleted && <Avatar sx={{
                                        position: 'absolute',
                                        height: "1.5rem",
                                        width: "1.5rem",
                                        backgroundColor: "black",
                                        right: "1rem",
                                        top: "0.5rem"
                                    }}>
                                        <Delete
                                            fontSize="small"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteMedia(ele)}
                                        />
                                    </Avatar>}
                                </Box>
                            </Grid>
                        ))}
                    </Grid >
                </CustomComponentContainer >
                : <Loader height="70vh" />
            }

            <Modal
                modalOpen={modalOpen}
                closeModal={handleClose}
                height="62vh"
                modalWidth="50vw"
                title="Media"
                children={
                    <ReturnMultiTypeMedia fileReadUrl={fileReadUrl} media={selectedMedia} height="50vh" />
                }
            />
            <Modal
                modalOpen={addMediaModalOpen}
                closeModal={() => setAddMediaModalOpen(false)}
                height="40vh"
                modalWidth="40vw"
                title="Add Media"
                children={
                    <AddMediasForm
                        // id={fault?._id}
                        handleCloseModal={() => setAddMediaModalOpen(false)}
                        mediaTrigger={mediaTriggerhandle}
                    />
                }
            />
        </>
    )
}

export default Medias