import { Suspense } from "react"
import { useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import Loading from "../components/loading/Loading"
import { Box } from "@mui/material"
import CommonStyles from "../styles/CommonStyles"

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user)

    return (
        <Routes>
            <Route
                {...rest}
                element={
                    <Suspense fallback={<Box sx={{ ...CommonStyles.centerDivWidth100, height: '80vh' }}><Loading /></Box>}>
                        <Component {...rest} />
                    </Suspense>
                }
            />
        </Routes>
    )
}

export default PrivateRoute