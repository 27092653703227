import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import './reactBigCalendar.scss'

import moment from 'moment'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import SecondaryPageRenderer from '../../components/secondaryPageRenderer/SecondaryPageRenderer';
import Selector from './Selector';
import SidebarModal from '../../components/modals/SidebarModal';
import PPMProvider from './context/PPMContextProvider';
import CreatePPMForm from './CreatePPMForm';
import { fetchCalendarData } from './helpers';
import Modal from '../../components/modals/NewModal';
import PPMView from './PPMView';
import { useSelector } from 'react-redux';
import PPMStatsProvider from './context/PPMStatsProvider';
import PPMUpdate from './PPMUpdate';
import { GET } from '../../helpers/http';
import EditPPMModal from './EditPPMModal';

// const DragAndDropCalendar = withDragAndDrop(Calendar)

const mLocalizer = momentLocalizer(moment)

const PPMCalendar = ({ triggerList, setTabSelected }) => {
    const defaultDate = useMemo(() => new Date(), [])

    const { ppm } = useSelector(state => state.configurations.services)

    const [myEvents, setMyEvents] = useState([])

    const [selectedDateRange, setSelectedDateRange] = useState({ start: moment().startOf('week').format('yyyy-MM-DD'), end: moment().endOf('week').format('yyyy-MM-DD') })

    const [openCreatePPMModal, setOpenCreatePPMModal] = useState(false)

    const [openViewPPMModal, setOpenViewPPMModal] = useState(false)

    const [selectedPPMId, setSelectedPPMId] = useState(null)

    const [openUpdatePPMModal, setOpenUpdatePPMModal] = useState(false)

    const [editOptionModalOpen, setEditOptionModalOpen] = useState(false)
    const [selectedUpdatePPMOption, setSelectedUpdatePPMOption] = useState("")
    const [editOptions, setEditoptions] = useState({})
    const [updatingPPM, setupdatingPPM] = useState('')



    useEffect(() => {
        if (setTabSelected) setTabSelected("calender")
    }, [])

    const handleOpenViewPPMModal = () => {
        setOpenViewPPMModal(true)
    }

    const handleCloseViewPPMModal = useCallback(() => {
        setOpenViewPPMModal(false)
    }, [])

    const handleOpenCreatePPMModal = () => {
        setOpenCreatePPMModal(true)
    }

    const handleCloseCreatePPMModal = () => {
        setOpenCreatePPMModal(false)
    }

    useEffect(() => {
        if (ppm && selectedDateRange.start && selectedDateRange.end) {
            fetchCalendarData({ startDate: selectedDateRange.start, endDate: selectedDateRange.end, setEvents: setMyEvents })
        }
    }, [selectedDateRange.end, selectedDateRange.start, ppm])

    // const moveEvent = useCallback(({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
    //     const { allDay } = event
    //     if (!allDay && droppedOnAllDaySlot) {
    //         event.allDay = true
    //     }
    //     if (allDay && !droppedOnAllDaySlot) {
    //         event.allDay = false;
    //     }

    //     setMyEvents((prev) => {
    //         const existing = prev.find((ev) => ev.id === event.id) ?? {}
    //         const filtered = prev.filter((ev) => ev.id !== event.id)
    //         return [...filtered, { ...existing, start, end, allDay: event.allDay }]
    //     })
    // }, [])

    // const resizeEvent = useCallback(({ event, start, end }) => {
    //     setMyEvents((prev) => {
    //         const existing = prev.find((ev) => ev.id === event.id) ?? {}
    //         const filtered = prev.filter((ev) => ev.id !== event.id)
    //         return [...filtered, { ...existing, start, end }]
    //     })
    // }, [])

    // const handleSelectSlot = useCallback(({ start, end }) => { //create event
    //     const daysDifference = moment(end).diff(moment(start), 'days')
    //     if (daysDifference !== 1 && daysDifference !== 0) {
    //         window.alert('Please select only one day')
    //     } else {
    //         const title = window.prompt('New Event name')
    //         if (title) {
    //             setMyEvents((prev) => [...prev, { start, end, title }])
    //         }
    //     }
    // }, [])

    const handleSelectEvent = useCallback((event) => {  //delete/edit event
        handleOpenViewPPMModal()
        setSelectedPPMId(event.id)
    }, [])

    const onRangeChange = useCallback((range) => {
        //  month: object with keys start and end date
        //  week: array of 7 dates starting from this week sunday to this week saturday
        //  work week: array of 5 dates starting from this week monday to this week friday
        //  day:array of 1 date(today)
        if (range.start && range.end) {
            setSelectedDateRange({ start: moment(range?.start)?.format('yyyy-MM-DD'), end: moment(range?.end)?.format('yyyy-MM-DD') })
        }
        else if (range.length > 1) {
            setSelectedDateRange({ start: moment(range?.[0])?.format('yyyy-MM-DD'), end: moment(range?.[range?.length - 1])?.format('yyyy-MM-DD') })
        }
        else {
            setSelectedDateRange({ start: moment(range?.[0])?.format('yyyy-MM-DD'), end: moment(range?.[0])?.format('yyyy-MM-DD') })
        }
    }, [])

    // const onView = useCallback((newView) => console.log({ newView }), [])

    const components = [
        // {
        //     xs: 12,
        //     component: <Selector handleOpenCreatePPMModal={handleOpenCreatePPMModal} />,
        //     className: 'commonComponentSpacing',
        //     title: '',
        // },
        {
            xs: 12,
            component: <Box height={'700px'} p={2} m={0}>
                <Calendar
                    defaultDate={defaultDate}
                    defaultView={Views.WEEK}
                    events={myEvents}
                    localizer={mLocalizer}
                    // onEventDrop={moveEvent}
                    // onEventResize={resizeEvent}
                    // popup
                    // resizable
                    // selectable
                    onSelectEvent={handleSelectEvent}
                    // onSelectSlot={handleSelectSlot}
                    onRangeChange={onRangeChange}
                    // onView={onView}
                    views={Object.values(Views)}
                />
            </Box>,
            className: 'commonComponentSpacing',
            title: '',
        }
    ]

    const handleEditPPM = async ({ ppmId, isRecursive }) => {
        try {
            const updateOptionsUrl = `${process.env.REACT_APP_URL}ppm/v1/update/options?type=update`
            const { data = {}, status } = await GET(updateOptionsUrl, 'Get PPM Update Option')

            if (status === 200) {
                if (isRecursive) {
                    setEditoptions(data)
                    setEditOptionModalOpen(true)
                    // handleCloseViewPPMModal(false)
                    setupdatingPPM(ppmId)
                } else {
                    setOpenUpdatePPMModal(true)
                    setupdatingPPM(ppmId)
                }
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const updateOptionPPM = async () => {
        try {
            setEditOptionModalOpen(false)
            setOpenUpdatePPMModal(true)
        } catch (error) {
            console.log({ error })
        }
    }

    return (
        <Box >
            <SecondaryPageRenderer componentData={components} />
            {/* <SidebarModal
                openModal={openCreatePPMModal}
                handleClose={handleCloseCreatePPMModal}
                title={'Create PPM'}
            >
                <PPMProvider>
                    <PPMStatsProvider>
                        <CreatePPMForm
                            handleCloseModal={handleCloseCreatePPMModal}
                            selectedDateRange={selectedDateRange}
                            setCalendarEvents={setMyEvents}
                        />
                    </PPMStatsProvider>
                </PPMProvider>
            </SidebarModal> */}
            <Modal
                modalOpen={openViewPPMModal}
                closeModal={handleCloseViewPPMModal}
                title={'View PPM'}
                modalWidth={500}
            >
                <PPMView
                    handleEditPPM={handleEditPPM}
                    triggerList={triggerList}
                    selectedPPMId={selectedPPMId}
                    handleCloseViewPPMModal={handleCloseViewPPMModal}
                    selectedDateRange={selectedDateRange}
                    setCalendarEvents={setMyEvents}
                />
            </Modal>
            <Modal
                modalOpen={editOptionModalOpen}
                closeModal={() => setEditOptionModalOpen(false)}
                title={'Update PPM'}>
                <PPMUpdate
                    selectedUpdatePPMOption={selectedUpdatePPMOption}
                    setSelectedUpdatePPMOption={setSelectedUpdatePPMOption}
                    updatePPMOptions={editOptions}
                    updatePPM={updateOptionPPM}

                />
            </Modal>
            <EditPPMModal
                triggerList={triggerList}
                openUpdatePPMModal={openUpdatePPMModal}
                handleCloseUpdatePPMModal={() => setOpenUpdatePPMModal(false)}
                editingPPMId={updatingPPM}
                handleCloseViewPPMModal={handleCloseViewPPMModal}
                setCalendarEvents={setMyEvents}
                selectedDateRange={selectedDateRange}
                selectedUpdatePPMOption={selectedUpdatePPMOption}
            />
        </Box>
    )
}

export default memo(PPMCalendar)