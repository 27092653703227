import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import ContentReviewTable from "./contentReviewTable/ContentReviewTable";
import { GET } from "../../../../helpers/http";
import { errorToast } from "../../../../helpers/apiToast";
import { wallContentReviewBodyStyle } from "../../../../styles/wallContentReviewBodyStyle";
import Dropdown from "../../../../components/dropdown/Dropdown";
import TabContainer from "../../../../components/tab/Tab";
import { useSelector } from "react-redux";
import { ThemeColors } from "../../../../../facilityManager/hooks/ThemeColors";

const WallContentReviewBody = (props) => {
    const [options, setOptions] = useState({})
    const [selectedOption, setSelectedOption] = useState({})
    const [timer, setTimer] = useState(0)
    const [usersList, setUsersList] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    // const color = themeMode ? '#fff' : '#000'
    const { commonSX } = ThemeColors()
    // const [postId, setPostId] = useState('')
    // const [sendPostId, setSendPostId] = useState('')

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    const getItemOption = async () => {
        try {
            const endPoint = `${process.env.REACT_APP_URL}wall/v1/admin/item-options`
            const optionsData = await GET(endPoint, "Fetch Wall Options")
            setOptions(optionsData?.data)
            setSelectedOption(optionsData?.data?.states[0])
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    useEffect(() => {
        getItemOption()
    }, [])

    useEffect(() => {
        if (searchTerm && !selectedUser) {
            const endpoint = `${process.env.REACT_APP_URL}wall/v1/admin/users/search?searchTerm=${searchTerm}`
            debounce(async function fetchData() {
                try {
                    const response = await GET(endpoint, "Get users by search")
                    setUsersList(response?.data)
                } catch (error) {
                    console.log(error)
                }
            }, 500)
        } else if (searchTerm === "") {
            setUsersList([])
        }
    }, [searchTerm])

    const onDropdownChange = (event) => {
        setSelectedOption(event.target.value)
    }


    return (
        <Box
            sx={{
                p: 1,
                width: "100%",
                height: "79vh",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}
        >
            {options?.types &&
                <TabContainer
                    // tabList={options?.types.map((type) => `${type[0].toUpperCase()}${type.slice(1)}`)}
                    tabList={["Post"]}
                    tabPanels={[
                        <ContentReviewTable
                            entity={"post"}
                            state={selectedOption}
                            selectedUser={selectedUser?._id} />,
                        // <ContentReviewTable
                        //     entity={"comment"}
                        //     state={selectedOption}
                        //     selectedUser={selectedUser?._id} />,
                    ]}
                    childrens={
                        <Box sx={{ ...wallContentReviewBodyStyle.tabChildrenBox }}>
                            <Dropdown
                                margin={0}
                                data={options?.states.map((type) => `${type[0].toUpperCase()}${type.slice(1)}`)}
                                value={`${selectedOption[0].toUpperCase()}${selectedOption.slice(1)}`}
                                onChange={onDropdownChange}
                            />
                            <Stack sx={{ ...wallContentReviewBodyStyle.autoCompleteSearch }}  >
                                <Typography>Search by Users</Typography>
                                <Autocomplete
                                    disablePortal
                                    id="search-by-users"
                                    getOptionLabel={(option) => option?.fullName ?? option}
                                    options={usersList}
                                    value={selectedUser}
                                    onChange={(event, newValue) => {
                                        setSelectedUser(newValue);
                                    }}
                                    inputValue={searchTerm}
                                    onInputChange={(event, newInputValue) => {
                                        setSearchTerm(newInputValue);
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                        return option._id === (value?._id)
                                    }}
                                    renderInput={(params) => {
                                        return (<TextField
                                            {...params}
                                            sx={{ ...wallContentReviewBodyStyle.autoCompleteTextField, bgcolor, ...commonSX }}
                                        // inputProps={{ ...params.inputProps, style: { color } }}
                                        />)
                                    }}
                                />
                            </Stack>
                            {/* <Box sx={{
                                display: "flex",
                                flexDirection: "row"
                            }}>
                                <Typography>Search by Post Id</Typography>
                                <TextField label="Outlined" variant="outlined" value={postId} onChange={(e) => setPostId(e.target.value)}>
                                </TextField>
                                <Button onClick={() => setSendPostId(postId)}>Search</Button>
                            </Box> */}
                        </Box>
                    }
                />}
        </Box >
    )
}

export default WallContentReviewBody