import React, { memo, useEffect, useState } from 'react';
import {
    Box,
    Divider,
    Grid,
    MenuItem,
    Button,
    Autocomplete,
    TextField,
    Avatar,
    Input,
    IconButton,
    Tooltip,
    TextareaAutosize,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    FormHelperText,
} from '@mui/material';
import { handleCreateUser, handleEditFormSubmit } from '../helpers';
import Loader from '../../../components/loading/Loader';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import CustomTypography from '../../../components/texts/CustomTypography';
import FormInput from '../../../components/formInputs/FormInput';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ThemeColors } from '../../../hooks/ThemeColors';
import { useSelector } from 'react-redux';
import { ArrowBack, DeleteOutlined, PhotoCamera } from '@mui/icons-material';
import { optimizImg } from '../../../helpers/optimizeImg';
import { FILEPOST } from '../../../helpers/http';
import { errorToast, successToast } from '../../../helpers/apiToast';
import { useRouter } from '../../../../../routes/hooks/useRouter';
import DropDownPhoneInput from '../components/PhoneInput';
import ImageCropper from './ImageCropper';

const AddUser = ({ userData, buildingsList, departmentsList, userList, userId, type, rolesList }) => {
    const { borderSX, background_color, textColor } = ThemeColors()
    const themeMode = useSelector(state => state.theme.darkTheme);
    const { fileReadUrl } = useSelector(state => state?.configurations);

    const activeUserId = useSelector(state => state?.user?._id)

    const router = useRouter();

    const bloodTypesList = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

    const [cropModal, setCropModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedReportsTo, setSelectedReportsTo] = useState({ id: '', fullName: '' });
    const [reportsToOptions, setReportsToOptions] = useState([]);

    const [formFields, setFormFields] = useState({
        fullName: '',
        email: '',
        mobile: '',
        address: '',
        buildingId: '',
        buildings: [],
        department: '',
        about: '',
        customName: '',
        empCode: '',
        role: '',
        designation: '',
        employeeId: '',
        deskNumber: '',
        bloodGroup: '',
        parkingSlotNumber: '',
        dateOfBirth: '',
        dateOfJoining: '',
        reportsTo: '',
        directReportsFrom: [],
        emergencyContactName: "",
        emergencyContactMobile: "",
        emergencyContactRelation: "",
        profilePictureUrl: '',
    });

    const navigate = useNavigate();

    const userSchema = Yup.object().shape({
        fullName: Yup.string()
            .min(3, 'Full Name must be at least 3 characters')
            .required('Full Name is required'),
        email: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
        mobile: Yup.string()
            .min(10, 'Mobile number must be at least 10 characters')
            .max(15, 'Mobile number must be less than 16 characters')
            .required('Mobile number is required'),
        buildingId: Yup.string().required('Building is required'),
        buildings: Yup.array().min(1, 'At least one building must be selected').required('Buildings are required'),
        department: Yup.string().required('Department is required'),
        empCode: Yup.string().required('Employee Code is required'),
        role: Yup.string().required('Role is required'),
        designation: Yup.string().required('Designation is required'),
        employeeId: Yup.string().required('Employee ID is required'),
        deskNumber: Yup.string().optional(),
        bloodGroup: Yup.string().required('Blood Group is required'),
        dateOfBirth: Yup.date().transform((value, originalValue) => (originalValue === "" ? null : value))
            .nullable().default(null),
        dateOfJoining: Yup.date(),
        reportsTo: Yup.string(),
        emergencyContact: Yup.object().shape({
            name: Yup.string(),
            mobile: Yup.string(),
            relation: Yup.string(),
        }),
        address: Yup.string().optional(),
        customName: Yup.string().optional(),
        profilePictureUrl: Yup.string().optional(),
    }).required();


    const userFields = [
        { name: 'fullName', label: 'Full Name', isDisabled: type === 'edit' ? true : false },
        { name: 'email', label: 'Email', isDisabled: type === 'edit' ? true : false },
        { name: 'mobile', label: 'Contact', type: 'phoneField' },
        { name: 'address', label: 'Address', type: 'textarea' },
        { name: 'buildingId', label: 'Building', isSelect: true },
        { name: 'department', label: 'Department', isSelect: true },
        { name: 'about', label: 'About', type: 'textarea' },
        { name: 'customName', label: 'Display Name' },
        { name: 'empCode', label: 'Employee Code' },
        { name: 'role', label: 'Role', isSelect: true },
        { name: 'designation', label: 'Designation' },
        { name: 'employeeId', label: 'Employee ID' },
        { name: 'deskNumber', label: 'Desk Number' },
        { name: 'bloodGroup', label: 'Blood Group', isSelect: true },
        { name: 'parkingSlotNumber', label: 'Parking Slot Number' },
        { name: 'dateOfBirth', label: 'Date of Birth', type: 'date' },
        { name: 'dateOfJoining', label: 'Date of Joining', type: 'date' },
    ];

    const emergencyFields = [
        { error: 'emergencyContact.name', name: 'emergencyContactName', label: 'Name' },
        { error: 'emergencyContact.mobile', name: 'emergencyContactMobile', label: 'Contact', type: 'phoneField' },
        { error: 'emergencyContact.relation', name: 'emergencyContactRelation', label: 'Relation' },
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValidationErrors({ ...validationErrors, [error || name]: null });
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handlePhoneInputs = ({ name, result }) => {
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: result,
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            fullName: formFields.fullName,
            email: formFields.email,
            role: formFields.role,
            empCode: formFields.empCode,
            customName: formFields.customName,
            address: formFields.address,
            buildingId: formFields.buildingId,
            buildings: formFields.buildings,
            department: formFields.department,
            designation: formFields.designation,
            dateOfJoining: formFields.dateOfJoining,
            dateOfBirth: formFields.dateOfBirth,
            deskNumber: formFields.deskNumber,
            employeeId: formFields.employeeId,
            reportsTo: formFields.reportsTo,
            bloodGroup: formFields.bloodGroup,
            profilePictureUrl: Boolean(selectedImage) ? selectedImage : '',
            mobile: formFields.mobile,
            about: formFields.about,
            parkingSlotNumber: formFields.parkingSlotNumber,

            emergencyContact: {
                name: formFields.emergencyContactName,
                mobile: formFields.emergencyContactMobile,
                relation: formFields.emergencyContactRelation,
            }
        };

        try {
            // Validate payload using Yup schema
            await userSchema.validate(payload, { abortEarly: false });

            setError(null);
            setValidationErrors({});

            if (type === 'edit') {
                delete payload?.fullName;
                delete payload?.email;
            }

            const result = type === 'edit' ? await handleEditFormSubmit({ id: userId, payload, setUserList: () => { } }) : await handleCreateUser({ payload, setUserList: () => { } })
            if (result?.status === 201 || result?.status === 200) {
                router.push('/admin/usermanagement');
            }
        } catch (error) {
            const errorMessages = error.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            console.log({ error });
            setValidationErrors(errorMessages);
        }
    };

    useEffect(() => {
        if (userData && Object.keys(userData).length > 0) {
            setFormFields({
                fullName: userData.userRef?.fullName,
                email: userData.userRef?.email,
                mobile: String(userData.mobile),
                address: userData.address,
                buildingId: userData.buildingId._id,
                buildings: userData?.buildings?.map(b => b?._id),
                department: userData.department?._id,
                about: userData.about,
                customName: userData.customName,
                empCode: userData.userRef?.empCode,
                role: rolesList?.find(el => el?.id === userData?.userRef?.role)?.id,
                designation: userData.designation,
                employeeId: userData.employeeId,
                deskNumber: userData.deskNumber,
                bloodGroup: userData.bloodGroup,
                parkingSlotNumber: userData.parkingSlotNumber,
                dateOfBirth: userData.dateOfBirth ? new Date(userData.dateOfBirth).toISOString().split('T')[0] : '',
                dateOfJoining: userData.dateOfJoining ? new Date(userData.dateOfJoining).toISOString().split('T')[0] : '',
                reportsTo: userData?.reportsTo?._id,
                emergencyContactName: userData.emergencyContact?.name,
                emergencyContactMobile: String(userData.emergencyContact?.mobile),
                emergencyContactRelation: userData.emergencyContact?.relation,
                profilePictureUrl: userData?.profilePictureUrl,
            })
            setSelectedReportsTo(userData?.reportsTo);
            setSelectedImage(userData?.profilePictureUrl);
        }
    }, [userData, rolesList, fileReadUrl]);

    useEffect(() => {
        const reportToOptions = () => {
            const result = [...new Set(userList?.filter(el => el._id !== activeUserId))]?.map(el => ({ id: el._id, fullName: el.fullName }));
            setReportsToOptions(result);
        };
        reportToOptions();
    }, [userList]);

    if (loading) {
        if (type === 'add') setLoading(false);
        else if (type === 'edit' && Object?.keys(userData)?.length > 0) setLoading(false);
        return <Loader height={'100vh'} width={'100vw'} />;
    };

    const uploadCroppedImg = async (img) => {
        const optImg = await optimizImg(img)

        const myFile = new File([optImg], 'image.jpeg', {
            type: optImg.type,
        });

        //upload Img
        let formData = new FormData();
        formData.append('file', myFile)
        FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/user/image`, formData, "IMAGE UPLOAD")
            .then((data) => {
                if (data?.status === 201) {
                    successToast('Image uploaded successfully!!');
                    setSelectedImage(data?.data)
                }
                else errorToast('Something went wrong!!');
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    const renderFormFields = (field) => {
        if (field?.type === 'textarea') {
            return <TextareaAutosize
                style={{
                    width: '100%',
                    borderRadius: '.25rem',
                    padding: '.4rem 0 0 .9rem',
                    backgroundColor: background_color(),
                    color: textColor()
                }}
                name={field.name}
                placeholder={field.label}
                value={formFields[field.name]}
                onChange={handleChange}
                maxRows={4}
                minRows={1.4}
            />
        } else if (field?.type === 'phoneField') {
            return <DropDownPhoneInput
                name={field.name}
                phone={formFields[field.name]}
                isValid={() => !Boolean(validationErrors[field.name] || validationErrors[field?.error])}
                handlePhoneInputs={handlePhoneInputs}
                formErrors={validationErrors[field.name] || validationErrors[field?.error]}
            />
        } else {
            return <FormInput
                select={field.isSelect}
                disabled={field.isDisabled}
                size='small'
                type={field.type}
                value={formFields[field.name]}
                onChange={handleChange}
                fullWidth
                label={field.label}
                name={field.name}
                error={!!validationErrors[field.name] || validationErrors[field?.error]}
                helperText={validationErrors[field.name] || validationErrors[field?.error]}
                SelectProps={field.multiple ? {
                    multiple: true,
                    renderValue: (selected) => {
                        return buildingsList.filter(b => selected.includes(b._id)).map(b => b.name).join(', ');
                    },
                } : {}}
            >
                {field.isSelect && field.name === 'buildingId' && buildingsList.map((building) => (
                    <MenuItem key={building._id} value={building._id}>
                        {building.name}
                    </MenuItem>
                ))}
                {field.isSelect && field.name === 'department' && departmentsList.map((department) => (
                    <MenuItem key={department._id} value={department._id}>
                        {department.name}
                    </MenuItem>
                ))}
                {field.isSelect && field.name === 'role' && rolesList.map((role) => (
                    <MenuItem key={role?.id} value={role?.id}>
                        {role?.name}
                    </MenuItem>
                ))}
                {field.isSelect && field.name === 'bloodGroup' && bloodTypesList.map((bloodtype) => (
                    <MenuItem key={bloodtype} value={bloodtype}>
                        {bloodtype}
                    </MenuItem>
                ))}
            </FormInput>
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minHeight: '89vh' }}>
            <CustomComponentContainer className='mrgn20' sx={{ borderRadius: '.5rem !important' }}>
                <Box component="form" onSubmit={handleSubmit} className='pad16'>
                    {error && <CustomTypography color="error">{error}</CustomTypography>}
                    <CustomTypography variant="h4" gutterBottom>
                        <Tooltip title="Back">
                            <IconButton
                                sx={{
                                    mr: 2,
                                    backgroundColor: '#dc4d28',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#b64b1e' },
                                    height: '2rem',
                                    width: '2rem'
                                }}
                                onClick={() => router.back()}
                            >
                                <ArrowBack sx={{ color: '#fff' }} />
                            </IconButton>
                        </Tooltip>
                        {type === 'edit' ? 'Edit User' : 'Add New User'}
                    </CustomTypography>

                    <Divider sx={{ backgroundColor: 'grey', marginBottom: 2 }} />

                    <CustomTypography variant="h6" gutterBottom>
                        User Profile
                    </CustomTypography>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    width: 'fit-content',
                                    '&:hover .backdrop': {
                                        display: 'flex',
                                    },
                                }}
                            >
                                <Avatar
                                    src={`${fileReadUrl}${selectedImage}`}
                                    sx={{ width: 200, height: 200, marginRight: 2 }}
                                />

                                {selectedImage ? (
                                    <Box
                                        className="backdrop"
                                        sx={{
                                            display: 'none',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 200,
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            height: 200,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        <DeleteOutlined sx={{ fontSize: 48 }} onClick={() => setSelectedImage(null)} />
                                    </Box>
                                ) : <label htmlFor="icon-button-file">
                                    <Input
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setSelectedImage(URL.createObjectURL(e.target.files[0]))
                                            setCropModal(true)
                                        }}
                                    />
                                    <Box
                                        className="backdrop"
                                        sx={{
                                            display: 'none',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 200,
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            height: 200,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        <PhotoCamera sx={{ fontSize: 48 }} />
                                    </Box>
                                </label>
                                }
                            </Box>

                        </Grid>
                    </Grid>

                    {
                        cropModal && <ImageCropper
                            img={selectedImage}
                            setImg={setSelectedImage}
                            open={cropModal}
                            close={() => setCropModal(false)}
                            uploadCroppedImg={uploadCroppedImg}
                        />
                    }

                    <CustomTypography variant="subtitle1" gutterBottom>
                        Please fill out the user information below.
                    </CustomTypography>
                    <Grid container spacing={2}>
                        {userFields?.map((field) => (
                            <Grid item xs={12} sm={6} md={4} key={field.name}>
                                {renderFormFields(field)}
                            </Grid>
                        ))}

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth size='small' sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#808080', // Set default border color to #808080
                                    },
                                    '&:focus-within .MuiOutlinedInput-notchedOutline': {
                                        borderColor: textColor(), // Change the border color to primary when focused
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: textColor(), // Keep the border color the same on hover
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textColor(), // Set input text color to white (#fff)
                                        cursor: textColor(), // Set cursor color to white (#fff)
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textColor(), // Set placeholder color to white (#fff)
                                    },
                                },
                                '& .MuiInputLabel-shrink + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    borderColor: textColor(), // Set border color to primary when the label floats up
                                },
                            }}
                            >
                                <InputLabel id="demo-multiple-name-label" sx={{ color: textColor() }}>Buildings</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={formFields.buildings || []}
                                    onChange={handleChange}
                                    name={'buildings'}
                                    input={<OutlinedInput label="Buildings" />}
                                    error={Boolean(validationErrors.buildings)}
                                    MenuProps={{
                                        className: themeMode ? 'menu-container-dark' : 'menu-container-light',
                                    }}
                                >
                                    {buildingsList?.map((name) => (
                                        <MenuItem
                                            key={name._id}
                                            value={name._id}
                                        >
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {validationErrors.buildings && (
                                    <FormHelperText error>
                                        {validationErrors.buildings}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                sx={borderSX}
                                size='small'
                                fullWidth
                                value={selectedReportsTo}
                                name={'reportsTo'}
                                id="reportsTo"
                                // options={userList?.map(el => ({ id: el._id, fullName: el.fullName }))}
                                options={reportsToOptions}
                                getOptionLabel={(option) => option.fullName}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setSelectedReportsTo(newValue);
                                        setFormFields({ ...formFields, reportsTo: newValue.id });
                                    } else {
                                        setSelectedReportsTo({ id: '', fullName: '' });
                                        setFormFields({ ...formFields, reportsTo: null });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="Reports To"
                                        variant="outlined"
                                        error={Boolean(validationErrors.reportsTo)}
                                        helperText={validationErrors.reportsTo}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* New Emergency Contact Section */}
                    <Divider sx={{ backgroundColor: 'grey', marginY: 2 }} />
                    <CustomTypography variant="h6" gutterBottom>
                        Emergency Contact Information
                    </CustomTypography>
                    <Grid container spacing={2}>
                        {emergencyFields.map((field) => (
                            <Grid item xs={12} sm={6} md={4} key={field.name}>
                                {renderFormFields(field)}
                            </Grid>
                        ))}
                    </Grid>

                    <Button type="submit" variant="contained" color="primary" sx={{
                        marginTop: 4,
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '15%'
                    }}>
                        Submit
                    </Button>

                    <Button variant="contained" color="primary" sx={{
                        marginTop: 4,
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '15%',
                        ml: 3
                    }}
                        onClick={() => navigate('/admin/usermanagement')}
                    >
                        cancel
                    </Button>

                </Box>
            </CustomComponentContainer>
        </Box >
    );
};

export default memo(AddUser);

