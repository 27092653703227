import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    excludeWeekend: true
}

const excludeWeekendSlice = createSlice({
    name: 'excludeWeekend',
    initialState,
    reducers: {
        setExcludeWeekend: (state) => {
            state.excludeWeekend = !state.excludeWeekend
        },
        resetExcludeWeekend: () => {
            return initialState
        }
    }
})

export const { setExcludeWeekend, resetExcludeWeekend } = excludeWeekendSlice.actions

export default excludeWeekendSlice.reducer