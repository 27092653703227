import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import DataView from '../../../components/widgets/dashboard/DataView'
import HardDriveInfoPopover from '../../../components/Popover/HardDriveInfoPopover'

const HardDriveComp = (props) => {
    const { data, errorMessage } = props
    const ele = {
        status: data?.status,
        freeSpace: data?.freeSpace,
        capacity: data?.capacity,
        type: data?.type,
    }

    const colorRange = (value, maxValue) => {
        if (value !== null && !isNaN(value)) {
            if (value >= (0.3 * Number(maxValue))) return '#47D095'
            else if ((value >= (0.1 * Number(maxValue)) && value < (0.3 * Number(maxValue)))) return '#F39406'
            else if (value < (0.1 * Number(maxValue))) return '#F01103'
        }
        return '#AAAAAA'
    }

    return (
        <Stack height={'80%'} mx={2} spacing={3} maxHeight={'50vh'} overflow={'auto'} margin={2} >
            < Box >
                {
                    Object.keys(ele)?.length > 0 &&
                    Object.keys(ele)?.map((element, i) => (
                        <DataView
                            key={i}
                            data={
                                element === 'capacity' ?
                                    < Typography lineHeight={2}><strong>Capacity</strong>: {ele?.capacity?.value} {ele?.capacity?.unit}</Typography>
                                    :
                                    element === 'type' ?
                                        <Typography lineHeight={2}><strong>Type</strong>: {ele?.type?.toUpperCase()} </Typography>
                                        :
                                        element === 'freeSpace' ?
                                            <Typography lineHeight={2}><strong>Free Space</strong>: {ele?.freeSpace?.value} {ele?.freeSpace?.unit} </Typography>

                                            : element === 'status' ?
                                                < Typography lineHeight={2}><strong>Status</strong>:
                                                    {ele?.status ? ele?.status?.name?.toUpperCase() : ""}
                                                    <HardDriveInfoPopover info={ele?.status?.message} />
                                                </Typography>
                                                : ""
                            }
                            bgcolor={
                                element === 'capacity' ? 'none' :
                                    element === 'type' ? 'none' :
                                        element === 'freeSpace' ? colorRange(ele?.freeSpace?.value, ele?.capacity?.value) :
                                            element === 'status' ? ele?.status?.name?.toLowerCase() === 'ok' ? '#47D095' : '#F01103' : ''
                            }
                            statusIndicator={!(element === 'capacity' || element === 'type')}
                            fontWeight={element === 'name' ? 700 : 400}
                        />
                    ))
                }
            </Box>

        </Stack >
    )
}

export default HardDriveComp