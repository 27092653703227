import { Autocomplete, Button, Checkbox, Chip, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Modal from '../../../../../components/modal/Modal';
import TextInput from '../../../../../components/formInputs/TextInput';
import isEmail from 'validator/lib/isEmail';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import CommonStyles from '../../../../../styles/CommonStyles'
import { GET, POST } from '../../../../../helpers/http';
import { successToast } from '../../../../../helpers/apiToast';
import AddGroupModalStyles from '../../../../../styles/AddGroupModalStyles';
import validSearchTerm from '../../../../../helpers/validSearchTerm';

const AddGroupModal = (props) => {
    const { openAddGroupModal, handleCloseAddGroupModal, groups, setGroups } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const [allUsersList, setAllUsersList] = useState([])

    const [users, setUsers] = useState([])

    const [searchTerm, setSearchTerm] = useState('');

    const [timer, setTimer] = useState(0)

    const [group, setGroup] = useState({
        name: '',
        groupCode: '',
        description: '',
        members: []
    })

    const [selectedEmails, setSelectedEmails] = useState([]);

    const [groupError, setGroupError] = useState('');

    const [addEmailError, setAddEmailError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target
        setGroup({
            ...group,
            [name]: value
        })
    }

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/users`);
                if (response?.status === 200) {
                    setAllUsersList([...response?.data])
                }
            } catch (error) {
                console.log({ error })
            }
        }
        fetchAllUsers()
    }, [])

    useEffect(() => {
        let active = true;

        if (searchTerm?.length >= 2) {
            debounce(async () => {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/users/?searchTerm=${validSearchTerm(searchTerm)}`); // Apicall.
                if (active) {
                    setUsers([...response?.data]);
                }
            }, 500)
        }

        return () => {
            active = false;
        };
    }, [searchTerm]);

    const closeModal = () => {
        if (handleCloseAddGroupModal) {
            handleCloseAddGroupModal()
        }
        setGroupError('')
        setSearchTerm('')
        setUsers([])
        setGroup({
            name: '',
            groupCode: '',
            description: '',
            members: []
        })
        setSelectedEmails([])
        setUsers([])
    }

    const createGroup = async () => {
        const conditionsPassed = group?.name?.length >= 3 && group?.name?.length < 256 && group?.groupCode?.length >= 2 && group?.groupCode?.length < 256 && group?.description?.length < 1024 && (searchTerm?.length === 0 || (searchTerm?.length > 0 && selectedEmails.includes(searchTerm)))
        if (group?.name?.length < 3) {
            setGroupError('Group name should be at least 3 characters')
        }
        else if (group?.name?.length > 256) {
            setGroupError('Group name should be less than 256 characters')
        }

        else if (group?.groupCode?.length < 2) {
            setGroupError('Group code should be at least 2 characters')
        }
        else if (group?.groupCode?.length > 256) {
            setGroupError('Group code should be less than 256 characters')
        }
        else if (group?.description?.length > 1024) {
            setGroupError('Group description should be less than 1024 characters')
        }
        else if (searchTerm?.length > 0 && !selectedEmails.includes(searchTerm)) {
            setGroupError(`Please either add or remove ${searchTerm}. Press Enter to add.`)
        }
        else if (conditionsPassed) {
            setGroupError('')
            const apiFormat = selectedEmails?.map((email) => {
                const user = allUsersList?.find((user) => {
                    return user?.email === email
                })

                return user ? user?._id : email
            })
            const newGroup = {
                ...group,
                members: apiFormat
            }
            try {
                const response = await POST(`${process.env.REACT_APP_BASE_URL}um/v1/admin/group`, newGroup, 'Create Group')
                successToast(`Group ${response?.data?.name} created successfully`)
                setGroups([...groups, response?.data])
                closeModal()
                setGroup({ name: '', groupCode: '', description: '', members: [] })
                setSelectedEmails([])
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <Modal
            modalOpen={openAddGroupModal}
            closeModal={closeModal}
            title={'Create a group'}
        >
            <Stack spacing={2}>
                <TextInput
                    label={'Group Name'}
                    fullWidth
                    value={group.name}
                    onChange={handleChange}
                    name={'name'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Group Code'}
                    fullWidth
                    value={group.groupCode}
                    onChange={handleChange}
                    name={'groupCode'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Group Description (Optional)'}
                    fullWidth
                    multiline
                    rows={4}
                    value={group.description}
                    onChange={handleChange}
                    name={'description'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <Stack sx={AddGroupModalStyles.innerStack} spacing={2}>
                    <Typography color={color}>Add Members</Typography>
                    <Autocomplete
                        value={selectedEmails}
                        onChange={(event, newValue) => {
                            if (newValue.length > 0 && !isEmail(newValue?.[newValue.length - 1])) {
                                setAddEmailError('Invalid email')
                                setGroupError('')
                            }
                            else if (newValue.length > 0 && isEmail(newValue?.[newValue.length - 1])) {
                                setAddEmailError('')
                                setSelectedEmails(newValue);
                                setSearchTerm('')
                                setGroupError('')
                            }
                            else if (newValue.length === 0) {
                                setAddEmailError('')
                                setSelectedEmails([]);
                                setGroupError('')
                            }
                        }}
                        noOptionsText="No Data"
                        multiple
                        limitTags={3}
                        disableCloseOnSelect
                        options={users.map((option) => option.email)}
                        // loading={loading}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    style={{ marginRight: 8, color: bgcolor }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        freeSolo
                        renderTags={(selectedEmails, getTagProps) =>
                            selectedEmails.map((option, index) => (
                                <Chip variant="outlined" sx={{ color }} label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Members"
                                onChange={handleSearchTermChange}
                                placeholder="Search Users"
                                inputProps={{ ...params.inputProps, style: { color, bgcolor } }}
                                InputLabelProps={{ ...params.InputLabelProps, sx: { color } }}
                            />
                        )}
                        style={AddGroupModalStyles.autoComplete}
                    />
                    {
                        addEmailError && <Typography color='error' sx={AddGroupModalStyles.errorTypo}>{addEmailError}</Typography>
                    }
                </Stack>
                {
                    groupError && <Typography color='error' sx={AddGroupModalStyles.errorTypo}>{groupError}</Typography>
                }
                <Button onClick={createGroup} sx={{ ...CommonStyles.actionButton }}>Create Group</Button>
            </Stack>
        </Modal>
    )
}

export default AddGroupModal