import { Avatar, Box, Button, Chip, Stack, Typography } from "@mui/material";
import WallDatatable from "../../../../components/datatable/WallDatatable"
import CommonStyles from "../../../../styles/CommonStyles";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import {  GET, POST, PUT } from "../../../../helpers/http";
import ActionButton from "../../../../components/button/PostActionButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import TableSkeleton from "../../../../components/Skeleton/table/TableSkeleton";

const ManageCommunityTable = ({ entity, communityId, state, selectedUser, communityName, community,communityStateCountUpdater }) => {
	const location = useLocation()
	const[loading, setLoading] = useState(true)
	const [tableData, setTableData] = useState({})
	const [page, setPage] = useState(location?.state?.page || 1)
    const [disablePrev, setDisablePrev] = useState(false)
    const[timer, setTimer] = useState(0)
	const [disableNext, setDisableNext] = useState(false)
	const communitiesFileReadUrl = useSelector(state => state?.configurations?.communitiesFileReadUrl)
	const navigate = useNavigate()

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }
	
	const getTableData = async ({ entity, communityId, page, state, selectedUser }) => {
		try {
			let endPoint
			if (entity === "posts") {
				endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/items?type=${"post"}&state=${state?.toLowerCase()}&page=${page}&communityId=${communityId}`;
				if (selectedUser) {
					endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/items?type=${"post"}&state=${state?.toLowerCase()}&page=${page}&communityId=${communityId}&linkedUser=${selectedUser}`
				}
			}
			
			else if (entity === "members") endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/members?communityId=${communityId}&page=${page}&type=${"all"}`
				
			else if (entity === "blocked") endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/members?communityId=${communityId}&page=${page}&type=${"blocked"}`
				
			else if (entity === "requests") endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/requestlist?communityId=${communityId}&page=${page}&requestType=pending`

			const tableData = await GET(endPoint, "Fetch Communities");
			setTableData({
				totalCount: tableData?.data?.totalCount, list: tableData?.data?.list?.map((data, idx) => {
					return { ...data, slno: (idx + 1) + ((page -1) * 10) }
			})
		});
			setLoading(false);
			if (tableData?.data?.list?.length < 10) setDisableNext(true)
			else if (tableData?.data?.list?.length === 10) setDisableNext(false)
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	};

	useEffect(() => {
			getTableData({ entity, communityId, page, state, selectedUser })
			if (page === 1) setDisablePrev(true)
			else setDisablePrev(false)
	}, [page, state, selectedUser])
	
	const handleItemUpdate = () => {
		getTableData({ entity, communityId, page, state, selectedUser })
	}

	const handleChangeRole = async({communityId, memberId, type,name}) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/changerole?communityId=${communityId}`
			const body = {memberId,type}
			const result = await PUT(endPoint, body,"Change members role");
            if (result) {
                communityStateCountUpdater({
                    community:{...community,
                    adminsCount: type === "communityAdmin" ?
                        community?.adminsCount + 1 :
                        community?.adminsCount - 1}
                })
				getTableData({ entity, communityId,page })
				successToast(`${name} is now ${type}.`)
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleBlockMember = async ({communityId, memberId, action,name}) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/blockaction?communityId=${communityId}`
			const body = { memberId, action }
			const result = await PUT(endPoint, body, "Block member");
            if (result?.status === 200) {
                communityStateCountUpdater({
                    community: {
                        ...community,
                        blockedMembersCount: action === "block" ?
                            community?.blockedMembersCount + 1 :
                            community?.blockedMembersCount - 1,
                        membersCount: action === "block" ?
                            community?.membersCount - 1 :
                            community?.membersCount + 1
                    }
                })
				getTableData({ entity, communityId,page })
				successToast(`${name} is ${action}ed.`)
			}
		} catch (error) {
			console.log(error); //handled in http
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleRemoveMember = async ({ communityId, memberId, action, name }) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/removemembers?communityId=${communityId}`
			const body = { members:[{id:memberId}] }
			const result = await PUT(endPoint, body, "Remove Member");
            if (result) {
                communityStateCountUpdater({
                    community: {
                        ...community,
                        membersCount: action === "remove" ?
                            community?.membersCount - 1 :
                            community?.membersCount + 1
                    }
                })
				getTableData({ entity, communityId,page })
				successToast(`${name} is ${action}ed.`)
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleRequestActions = async ({ memberId, action, communityId, name}) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/requestaction?communityId=${communityId}`
			const body = { memberId, action }
			const result = await POST(endPoint, body, "Accept/Reject request");
            if (result) {
                communityStateCountUpdater({
                    community: {
                        ...community,
                        membersCount: action === "accepted" &&
                            community?.membersCount + 1 
                    }
                })
				getTableData({ entity, communityId,page })
				successToast(`${name} is ${action === "accepted" ? "accept" : "reject"}ed.`)
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleOnCellClick = (params) => {
		if (params?.field !== "actions" &&  entity === "posts") {
			// setSelectedRow(params)
			navigate(`/admin/communities/post-view/${params?.id}`, { state: { id: params?.id, name: communityName } }, )
		}
	}

    const handleNextPrevBtn = (value) => {
        debounce(() => {
            if (value === "next") {
                setPage(page + 1)
                //navigate(`/admin/communities/view/${location?.state?.id}`, { state: { page: page + 1 } })
            } else if (value === "prev") {
                if (page >= 2) {
                    setPage(page - 1)
                    //navigate(`/admin/communities/view/${location?.state?.id}`, { state: { page: page - 1 } })
                }
            }
        },500)
	}

	const postsColumn = [
		{
			field: "slno",
            headerName: "Sl No",
			width: 60,
		},
		{
            field: "user",
            headerName: "Posted By",
            sortable: false,
			width: 250,
			renderCell: (params) => {
				return (
					<Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
								<Avatar alt={`${params?.row?.user?.fullName}'s img`}
									src={`${communitiesFileReadUrl}${params.row.user.profilePictureUrl}`}
								/>
						<Typography> {params?.row?.user?.fullName}</Typography>
					</Box >
				)
			}
		},
		{
			field: "description",
			headerName: "Description",
			width: 350,
		},
		{
			field: "createdAt",
			headerName: "Date Posted",
			width: 230,
			renderCell:(params) => moment(params?.row?.createdAt).format('lll')
		},
		{
			headerName: "Status",
            width: 250,
            sortable: false,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={1}>
						{params?.row?.isEdited && <Chip sx={{ border: "solid 1px gray" }} label="Edited" size="small" />}
						{params?.row?.isDisabled && <Chip sx={{ border: "solid 1px gray" }} label="Disabled" size="small" />}
						{params?.row?.isDeleted && <Chip sx={{ border: "solid 1px gray" }} label="Deleted" size="small" />}
					</Stack>
				)
			}
		},
		{
			field: "flagCount",
			headerName: "Flag Count",
			width: 100,
			renderCell: (params) => params?.row?.type,
		},
		{
			field: "actions",
			headerName: "Actions",
            width: 300,
            sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Stack direction="row" gap={2}>
							<ActionButton
								id={params?.row?._id}
								type={"post"}
								isDisabled={params?.row?.isDisabled}
								handleItemUpdate={handleItemUpdate}
								name={"disable"}
								isDeleted={params?.row?.isDeleted}
								communityId={params?.row?.community}
								endPoint={`${process.env.REACT_APP_URL}comms/v1/admin/actions`}
							/>
							<ActionButton
								id={params?.row?._id}
								type={"post"}
								handleItemUpdate={handleItemUpdate}
								name={"delete"}
								isDeleted={params?.row?.isDeleted}
								communityId={params?.row?.community}
								endPoint={`${process.env.REACT_APP_URL}comms/v1/admin/actions`}
							/>
						</Stack>
					</>
				);
			},
		},
	];


	const membersColumn = [
		{
			field: "slno",
			headerName: "Sl No",
			width: 60,
		},
		{
			field: "fullName",
			headerName: "Name",
			width: 250,
			renderCell: (params) => {
				return (
					<Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
								<Avatar alt={`${params?.row?.fullName}'s img`}
									src={`${communitiesFileReadUrl}${params.row?.profilePictureUrl}`}
                            isCreator	/>
						<Typography> {params?.row?.fullName}</Typography>
					</Box >
				)
			}
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 350,
		},
		{
			field: "accessType",
			headerName: "Role",
            width: 150, 
            renderCell: (params) => {
                return (<Typography> {params?.row?.isCreator ? "creator" : params?.row?.accessType} </Typography>)
            }
		},
		{
			field: "actions",
            headerName: "Actions",
            sortable: false,
			width: 500,
			renderCell: (params) => {
				return (
                    <>
                        {!params?.row?.isCreator  && <Box className="flex flexDirectionRow gap16">
                            {params?.row?.accessType === "communityAdmin" ? (
                                <Button sx={{ ...CommonStyles.actionButton }}
                                    onClick={() => handleChangeRole({
                                        communityId: communityId,
                                        memberId: params?.row?._id,
                                        name: params?.row?.fullName,
                                        type: "member"
                                    })}
                                >Remove admin</Button>
                            ) : (
                                <Button sx={{ ...CommonStyles.actionButton }}
                                    onClick={() => handleChangeRole({
                                        communityId: communityId,
                                        memberId: params?.row?._id,
                                        name: params?.row?.fullName,
                                        type: "communityAdmin"
                                    })}
                                >Appoint admin</Button>
                            )}
                            <Box className="flex flexDirectionRow gap16">
                                <Button sx={{ ...CommonStyles.actionButton }}
                                    onClick={() => handleBlockMember({
                                        memberId: params?.row?._id,
                                        name: params?.row?.fullName,
                                        communityId: communityId,
                                        action: "block"
                                    })}
                                >Block</Button>
                                <Button sx={{ ...CommonStyles.actionButton }}
                                    onClick={() => handleRemoveMember({
                                        memberId: params?.row?._id,
                                        name: params?.row?.fullName,
                                        communityId: communityId,
                                        action: "remove"
                                    })}
                                >Remove</Button>
                            </Box>
                        </Box>}
                    </>
				);
			},
		},
	];

	const blockedColumn = [
		{
			field: "slno",
			headerName: "Sl No",
			width: 60,
		},
		{
			field: "fullName",
			headerName: "Name",
			width: 250,
			renderCell: (params) => {
				return (
					<Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
								<Avatar alt={`${params?.row?.fullName}'s img`}
									src={`${communitiesFileReadUrl}${params.row?.profilePictureUrl}`}
								/>
						<Typography> {params?.row?.fullName}</Typography>
					</Box >
				)
			}
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 350,
		},
		{
			field: "accessType",
			headerName: "Role",
			width: 150,
		},
		{
			field: "actions",
            headerName: "Actions",
            sortable: false,
			width: 500,
			renderCell: (params) => {
				return (
					<Box className="flex flexDirectionRow gap16">
							<Button sx={{ ...CommonStyles.actionButton }}
								onClick={() => handleBlockMember({
									memberId: params?.row?._id,
									name: params?.row?.fullName,
									communityId: communityId,
									action: "unblock"
								})}
							>Unblock</Button>
					</Box>
				);
			},
		},
	];


	const requestColumn = [
		{
			field: "slno",
			headerName: "Sl No",
			width: 60,
		},
		{
			field: "user.fullName",
			headerName: "Name",
            sortable: false,
			width: 250,
			renderCell: (params) => {
				return (
					<Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
						{
								<Avatar alt={`${params?.row?.user?.fullName}'s img`}
									src={`${communitiesFileReadUrl}${params.row?.user?.profilePictureUrl}`}
								/>
						}
						<Typography> {params?.row?.user?.fullName}</Typography>
					</Box >
				)
			}
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 200,
			renderCell: (params) => params?.row?.user?.designation
		},
		{
			field: "createdAt",
			headerName: "Requested Time",
			width: 250,
		},
		{
			field: "actions",
			headerName: "Actions",
            width: 300,
            sortable: false,
			renderCell: (params) => {
				return (
						<Box className="flex flexDirectionRow gap16">
							<Button sx={{ ...CommonStyles.actionButton }}
							onClick={() => handleRequestActions({ action: "accepted", memberId: params?.row?.user?._id, name: params?.row?.user?.fullName, communityId: params?.row?.community })}
							>Accept</Button>
							<Button sx={{ ...CommonStyles.actionButton }}
							onClick={() => handleRequestActions({ action: "rejected", memberId: params?.row?.user?._id, name: params?.row?.user?.fullName, communityId: params?.row?.community })}
						>Reject</Button>
						</Box>
				);
			},
		},
	]

	return (
		<>
			
			{!loading ?
				<WallDatatable
				checkBox={false}
				rows={tableData?.list}
				columns={
					entity === "posts" ? postsColumn :
						entity === "members" ? membersColumn :
							entity === "blocked" ? blockedColumn :
								entity === "requests" && requestColumn
				}
				pageSize={15}
				rowsPerPage={15}
				autoHeight={false}
				getRowHeightValue={50}
				isLoading={true} //change later
				hideFooter={true}
				tableHeight={"60vh"}
				hideheaderToolBar={true}
				onAssetClick={handleOnCellClick}
				/>
				:
				<TableSkeleton tableProp={
					entity === "posts" ? postsColumn :
						entity === "members" ? membersColumn :
							entity === "blocked" ? blockedColumn :
								entity === "requests" && requestColumn
				} />
		}
			<Box className="flex flexDirectionRow gap16 flexJustifyEnd padright80 padtop15 flexAlignItemsCenter width100" >
				{(!loading && tableData?.totalCount !== 0 && tableData?.totalCount) &&
				<Typography>
					{`${1 + ((page - 1) * 10)} to ${10 * page > tableData?.totalCount ? tableData?.totalCount : 10 * page} of ${tableData?.totalCount}`}
				</Typography>}
				<Button
					onClick={() => handleNextPrevBtn("prev")}
					sx={{
						backgroundColor: "#dc3d28",
						color: "white",
						"&:hover": { backgroundColor: "#dc3d28" },
					}}
					disabled={disablePrev}
				>
					<ArrowBackIcon />
				</Button>
				<Button
					onClick={() => handleNextPrevBtn("next")}
					sx={{
						backgroundColor: "#dc3d28",
						color: "white",
						"&:hover": { backgroundColor: "#dc3d28" },
					}}
					disabled={disableNext}
				>
					<ArrowForwardIcon />
				</Button>
			</Box>
		</>
	)
}

export default ManageCommunityTable