import { useEffect, useState } from "react"
import CustomComponentContainer from "../../components/container/CustomComponentContainer"
import Datatable from "../../components/datatable/Datatable"
import { DELETE, GET } from "../../helpers/http"
import { IconButton, Tooltip } from "@mui/material"
import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material"
import { errorToast, successToast } from "../../helpers/apiToast"
import Loader from "../../components/loading/Loader"
import FormView from "./FormView"
import swal from 'sweetalert'
import FormBuilder from "./FormBuilder"
import MUIRightDrawer from "./MUIRightDrawer"



const FormList = () => {
    const [rows, setRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [viewForm, setViewForm] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [edit, setEdit] = useState(false)
    const [refreshTable, setRefreshTable] = useState(false)
    const [editForm, setEditForm] = useState({})

    useEffect(() => {
        (async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/form/list`, "GET Form list")
                console.log(response.data)
                setRows(response.data)
                setLoaded(true)
            } catch (error) {
                setLoaded(false)
                console.log(error)
            }

        })();

    }, [refreshTable])


    const handleFormView = (ele) => {
        setViewForm(ele)
        setDisableSubmit(true)
        setModalOpen(true)
    }

    const toggleDrawer = async (state) => {
        setEdit(state);
        if (!state) {
            // Reset states when closing the drawer
            setEdit(false);
            // setEditData(null);
        }
    };


    const handleClose = () => {
        setModalOpen(false)
    }


    const handleDelete = async (id) => {
        swal({
            title: 'Alert',
            text: 'Are you sure you want to delete',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/form/${id}`, "Delete Form")
                successToast('Successfully Deleted Form')
                setRefreshTable(!refreshTable)
            }
        })
            .catch(error => {
                errorToast('Error While Deleting The Form')
                console.log(error)
            })


    }

    const handleEditClick = (ele) => {
        setEditForm(ele)
        setEdit(true)


    }
    const handleCloseEdit = () => {
        setEdit(false)
    }

    const columns = [
        { field: 'name', headerName: 'Form Name', flex: 1 },
        { field: 'description', headerName: "Description", flex: 1 },
        {
            field: 'fields', headerName: "Total Fields", flex: 1,
            renderCell: (params) => {
                return params.formattedValue.length

            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip>
                        <IconButton
                            //  disabled={params.row.isDeleted}
                            aria-label="view"
                            onClick={() => { handleFormView(params.row) }}
                        >
                            <RemoveRedEyeOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                        <IconButton
                            // disabled={params.row.isDeleted}
                            aria-label="edit"
                            onClick={() => handleEditClick(params.row)}
                        >
                            <EditOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                        <IconButton
                            // disabled={params.row.isDeleted} 
                            aria-label="delete"
                            onClick={() => handleDelete(params.row._id)}
                        >
                            <DeleteOutline sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },


    ]


    return <CustomComponentContainer
        sx={{
            width: "100vw",
            mt: 1, mx: 1
        }}
    >
        {
            loaded ? <Datatable rows={rows || []} columns={columns}
                height="89vh"
            /> : <Loader />
        }

        {modalOpen && <FormView
            formData={viewForm?.fields}
            finalForm={{ name: viewForm?.name, description: viewForm?.description }}
            open={true}
            handleClose={handleClose}
            disableSubmit={disableSubmit}
        />
        }
        {
            <MUIRightDrawer open={edit}
                toggleDrawer={toggleDrawer}
                title={`${edit ? 'Update' : 'Add'} Costing`}>
                <FormBuilder
                    editForm={editForm}
                    edit={edit}
                    toggleDrawer={toggleDrawer}
                />

            </MUIRightDrawer>
        }

    </CustomComponentContainer>
}
export default FormList