import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { errorHandler } from '../../../components/facilityManager/helpers/http'
import { dropdownFloorsSort } from '../../../components/facilityManager/helpers/dropdownFloorsSort'
import { validData } from '../../../components/facilityManager/helpers/validData'

const selectedFloorId = (floorId) => (floorId !== "All Floors" && floorId?.length === 24) ? floorId : "all"

const chartDataFormatter = (payload) => {
    const data = payload?.map(ele => ({
        name: ele?.floor,
        color: ele?.floor === 'Ground Floor' ? '#8085E9' :
            ele?.floor === 'First Floor' ? '#4BBAA5' :
                ele?.floor === 'Second Floor' ? '#B3EB35' : '',
        data: ele?.data
    }))
    return data
}

const fetchWaterConsumptionSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getWaterConsumptionDashboardStats: builder.query({
            query: (bid) => ({
                url: `twin/v1/water/s1/stats?bid=${bid}&type=consumption&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Consumption Dashboard Stats')
            },
            providesTags: ['water']
        }),
        getOptions: builder.query({
            query: (bid) => ({
                url: `twin/v1/water/s2/options?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Consumption Floors and Durations')
            },
            providesTags: ['water']
        }),
        getUsageData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/water/s2/stats?bid=${bid}&duration=${duration}&type=comparison&floor=${selectedFloorId(floorId)}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Consumption Usage Data')
            },
            providesTags: ['water']
        }),
        getConsumptionForecast: builder.query({
            query: ({ bid, floorId }) => ({
                url: `twin/v1/water/s2/data/upcoming?bid=${bid}&floor=${selectedFloorId(floorId)}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Consumption Forecast')
            },
            providesTags: ['water']
        }),
        getWaterConsumptionHistory: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/water/s2/data?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=consumption&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Consumption Week history')
            },
            providesTags: ['water']
        }),
        getWeekTotalConsumptionChartData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/water/s2/data?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=consumption&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Week Total Consumption Chart Data')
            },
            providesTags: ['water']
        }),
        getMonthwiseTotalConsumptionChartData: builder.query({
            query: ({ bid, floorId }) => ({
                url: `twin/v1/water/s2/consumption?bid=${bid}&floor=${selectedFloorId(floorId)}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Monthwise Total Consumption Chart Data')
            },
            providesTags: ['water']
        }),
        getWaterFloorStats: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/water/s2/stats?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=source-floor&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Water Secondary Source Floor Stats')
            },
            providesTags: ['water']
        }),
    })
})

export const {
    useGetWaterConsumptionDashboardStatsQuery, useGetOptionsQuery, useGetUsageDataQuery, useGetConsumptionForecastQuery, useGetWaterConsumptionHistoryQuery,
    useGetWeekTotalConsumptionChartDataQuery, useGetMonthwiseTotalConsumptionChartDataQuery, useGetWaterFloorStatsQuery
} = fetchWaterConsumptionSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        data: {}
    },
    secondary: {
        sourceFloorStats: {
            isLoaded: false,
            error: "",
            consumptionBySource: {},
            consumptionByFloor: []
        },
        floors: {
            isLoaded: false,
            error: "",
            data: [],
            selectedFloor: "",
        },
        durations: {
            list: [],
            selected: 'Today'
        },
        usage: {
            isLoaded: false,
            error: "",
            data: []
        },
        consumptionForecast: {
            isLoaded: false,
            error: "",
            currentConsumption: 0,
            monthEstimation: 0
        },
        consumptionHistory: {
            isLoaded: false,
            error: "",
            data: [],
        },
        weekConsumption: {
            isLoaded: false,
            error: "",
            data: [],
        },
        monthWiseConsumption: {
            isLoaded: false,
            error: "",
            data: {},
        },
    }
}

const waterConsumptionSlice = createSlice({
    name: 'water',
    initialState,
    reducers: {
        resetDashboardWaterConsumption: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        setWaterSecondaryDuration: (state, action) => {
            if (state.secondary.durations.selected !== action.payload) {
                state.secondary.durations.selected = action.payload
                state.secondary.usage.isLoaded = false
                state.secondary.sourceFloorStats.isLoaded = false
                // state.secondary.consumptionForecast.isLoaded = false
                state.secondary.consumptionHistory.isLoaded = false
                // state.secondary.weekConsumption.isLoaded = false
                // state.secondary.monthWiseConsumption.isLoaded = false
            }
        },
        setWaterSecondaryFloor: (state, action) => {
            state.secondary.floors.selectedFloor = action.payload
            state.secondary.usage.isLoaded = false
            state.secondary.sourceFloorStats.isLoaded = false
            state.secondary.consumptionForecast.isLoaded = false
            state.secondary.consumptionHistory.isLoaded = false
            state.secondary.weekConsumption.isLoaded = false
            state.secondary.monthWiseConsumption.isLoaded = false
        },
        resetWaterSecondaryData: (state, action) => {
            state.secondary = initialState.secondary
        },
        updateSSEWaterConsumption: (state, action) => {
            state.dashboard.data.todayTillNow = Number((state.dashboard.data.todayTillNow + action.payload?.waterConsumptionDifference).toFixed(2))
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getWaterConsumptionDashboardStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.isLoaded = true
                    state.dashboard.data = action.payload.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const floors = structuredClone(action.payload?.data?.floors)
                    floors?.length > 0 && dropdownFloorsSort(floors)

                    state.secondary.floors.isLoaded = true
                    state.secondary.floors.data = floors
                    state.secondary.floors.selectedFloor = "All Floors"
                    // state.secondary.floors.selectedFloor = floors?.[0]?.id || "All Floors"
                    state.secondary.durations.list = action.payload?.data?.durations
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getUsageData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.usage.isLoaded = true
                    state.secondary.usage.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getConsumptionForecast' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    let currentTotal = 0, estimationTotal = 0, comparisonPercentWithLastMonth = 0

                    action.payload?.data?.forEach(ele => {
                        validData(ele?.thisMonthConsumption) ? currentTotal += ele?.thisMonthConsumption : currentTotal += 0
                        validData(ele?.thisMonthEstimation) ? estimationTotal += ele?.thisMonthEstimation : estimationTotal += 0
                        validData(ele?.comparisonPercentWithLastMonth) ? comparisonPercentWithLastMonth += ele?.comparisonPercentWithLastMonth : comparisonPercentWithLastMonth += 0
                    })

                    state.secondary.consumptionForecast.isLoaded = true
                    state.secondary.consumptionForecast.currentConsumption = Number((currentTotal).toFixed(2))
                    state.secondary.consumptionForecast.monthEstimation = Number((estimationTotal).toFixed(2))
                    state.secondary.consumptionForecast.comparisonPercentWithLastMonth = Number((comparisonPercentWithLastMonth / action.payload?.data?.length).toFixed(2))
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getWaterConsumptionHistory' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.consumptionHistory.isLoaded = true
                    state.secondary.consumptionHistory.data = [...chartDataFormatter(action.payload?.data?.floorWiseData)]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getWeekTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.weekConsumption.isLoaded = true
                    state.secondary.weekConsumption.data = [...chartDataFormatter(action.payload?.data?.floorWiseData)]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getMonthwiseTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.monthWiseConsumption.isLoaded = true
                    state.secondary.monthWiseConsumption.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getWaterFloorStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.sourceFloorStats.isLoaded = true
                    state.secondary.sourceFloorStats.consumptionBySource = action.payload?.data?.sourceConsumption
                    state.secondary.sourceFloorStats.consumptionByFloor = action.payload?.data?.floorsConsumption
                }
            )
    }
})

export const { resetDashboardWaterConsumption, resetWaterSecondaryData, setWaterSecondaryDuration, setWaterSecondaryFloor, updateSSEWaterConsumption } = waterConsumptionSlice.actions

export default waterConsumptionSlice.reducer
