const MeetingRoomTabBoxStyles = {
    paperContainer: {
        mr: 2,
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    headingBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "15%",
        px: 2
    },
    headingTypography: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    gridBox: {},
    gridTypography: { fontSize: "15px", fontWeight: "700" },
    connectionStatus: {
        width: "100%",
        fontSize: '15px', fontWeight: "600",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    connected: {
        fontSize: "15px",
        fontWeight: "600"
    },
    bottomBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        px: 2,
    },
    resourceId: {
        fontSize: "15px",
        fontWeight: '600',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    gridValues: { fontSize: "15px", fontWeight: '600', },
    bottomGrid: { display: 'flex', justifyContent: 'flex-end', px: 2, py: 2 },
    parentGrid: { pl: 2, py: 1, pr: 0 }
}

export default MeetingRoomTabBoxStyles