const SecondaryStyles = {
    boxContainer: {
        p: 1,
        width: "100%",
        minHeight: '850px',
    },
    returnButton: {
        textTransform: 'none',
        margin: 0,
        padding: 0,
        mt: '8px',
        mb: '16px',
        fontSize: '14px'
    },
    title: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
        mb: '16px'
    }
}

export default SecondaryStyles