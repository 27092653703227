import { Button } from '@mui/material'
import React from 'react'

const NhanceLogoutBtn = (props) => {
    return (
        <Button
            className={`btnFontSize ${props.className}`}
            variant="small"
            onClick={props.onClickFunction}>
            {props.textContent}
        </Button>
    )
}

export default NhanceLogoutBtn