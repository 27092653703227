import { Box, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DepartmentSettingsStyles from '../../../styles/DepartmentSettingStyles'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@mui/icons-material'
import Datatable from '../../../components/datatable/Datatable'
import { GET } from '../../../helpers/http'
import CategoryModal from '../../../features/settings/category/components/modals/CategoryModal'
import { useSelector } from 'react-redux'
import CommonStyles from '../../../styles/CommonStyles'
import validSearchTerm from '../../../helpers/validSearchTerm'
import DeleteDepartmentCategoryModal from '../../../components/modal/DeleteDepartmentCategoryModal'
import CustomButton from '../../../components/button/CustomButton'

const CategorySettings = () => {
    const [categories, setCategories] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false)
    const [deletingCategory, setDeletingCategory] = useState({})
    const [categoryModalOpen, setCategoryModalOpen] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({})
    const [timer, setTimer] = useState(0)

    const themeMode = useSelector(state => state.theme.darkTheme)

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        const endpoint = searchTerm?.length >= 2 ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list?searchTerm=${validSearchTerm(searchTerm)?.trim()}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`

        debounce(() => {
            GET(endpoint, 'Fetch Categories')
                .then((response) => {
                    if (response?.status === 200) {
                        setCategories(response?.data?.toSorted((a, b) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 500)
    }, [searchTerm])

    const onSearchChange = (e) => {
        setSearchTerm(e.target?.value)
    }

    const handleAddCategoryClick = () => {
        setCategoryModalOpen(true)
    }

    const handleEditCategoryClick = (categoryObj) => {
        setCategoryModalOpen(true)
        setSelectedCategory(categoryObj)
    }

    const addNewCategory = (newCategoryObj) => {
        setCategories((prevState) => {
            return [...prevState, newCategoryObj]
        })
    }

    const editCategory = (updatedCategoryObj) => {
        setCategories((prevState) => {
            return prevState.map(category => {
                if (category?._id === updatedCategoryObj?._id) {
                    return { ...category, ...updatedCategoryObj }
                } else {
                    return { ...category }
                }
            })
        })
    }

    const handleDeleteCategoryModalOpen = (category) => {
        setDeleteCategoryModalOpen(true)
        setDeletingCategory(category)
    }

    const handleDeleteCategoryModalClose = () => {
        setDeleteCategoryModalOpen(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Category Name',
            width: 250,
        },
        {
            field: 'code',
            headerName: 'Category Code',
            width: 250,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 450,
        },
        {
            field: 'parent',
            headerName: 'Parent Category',
            width: 250,
            valueGetter: (params) => `${params.row?.parent?.name || ''}`,
        },
        {
            field: 'Actions',
            headerAlign: 'center',
            align: 'right',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Tooltip title='EDIT' placement='top'>
                            <IconButton
                                onClick={() => handleEditCategoryClick(cellValues?.row)}
                            >
                                <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                    &nbsp;EDIT
                                </Typography> */}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => handleDeleteCategoryModalOpen(cellValues?.row)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                    &nbsp;DELETE
                                </Typography> */}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ]

    return (
        <Box
            sx={CommonStyles?.OuterBox}>
            <Typography
                sx={DepartmentSettingsStyles.headingTypo}
            >
                Categories
            </Typography>
            <Box
                sx={DepartmentSettingsStyles.innerBox}
            >
                <Paper
                    sx={{
                        ...DepartmentSettingsStyles.paperContainer,
                    }}
                    className='theme-cards-boxShadow theme-component-background theme-border'
                >
                    <Grid container direction={'row-reverse'} spacing={1} className='flexAlignItemsCenter'>
                        <Grid item xs={1.5}>
                            <CustomButton textContent='Add Category' variant="outlined" sx={DepartmentSettingsStyles.addButton} onClick={handleAddCategoryClick} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={searchTerm}
                                onChange={onSearchChange}
                                className='width100 maxHeight40px'
                                placeholder="Search a Category by name or code.."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlined sx={{ color: '#616161' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        // Customize the border color
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the focused border color
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the input text color
                                        '& .MuiInputBase-input': {
                                            color: themeMode ? '#fff' : '#000'
                                        },
                                        // Customize the cursor color
                                        '& .MuiInputBase-input::selection': {
                                            backgroundColor: '#808080',
                                        },
                                        // Customize the border color on hover
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Datatable
                    checkBox={false}
                    rows={categories}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                />
            </Box>
            <CategoryModal
                categoryModalOpen={categoryModalOpen}
                setCategoryModalOpen={setCategoryModalOpen}
                addNewCategory={addNewCategory}
                editCategory={editCategory}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
            />
            <DeleteDepartmentCategoryModal
                deleteModalOpen={deleteCategoryModalOpen}
                handleDeleteModalClose={handleDeleteCategoryModalClose}
                deletingCategory={deletingCategory}
                setCategories={setCategories}
            />
        </Box>
    )
}

export default CategorySettings