import { Box, Grid } from '@mui/material'
import React, { memo, useContext } from 'react'
import { Mic, Person4Outlined } from '@mui/icons-material'
import CustomTypography from '../../components/texts/CustomTypography'
import CustomCard from './components/CustomCard'
import CustomIconButton from './components/CustomIconButton'
import { useSelector } from 'react-redux'
import TextFieldContainer from './components/textField/TextFieldContainer'
import VideoBackground from './components/VideoBackground'
import { ChatContext } from './context/ChatContext'

const LandingPage = () => {

    const userName = useSelector(state => state.user.fullName);

    const { chatDispatch } = useContext(ChatContext);

    const populatedCardsChat = [
        { question: 'Lorem ipsum dolor sit amet consectetur.' },
        { question: 'Lorem ipsum dolor sit amet consectetur.' },
        { question: 'Lorem ipsum dolor sit amet consectetur.' },
        { question: 'Lorem ipsum dolor sit amet consectetur.' },
    ]

    return (
        <VideoBackground>
            <Box
                sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                    height: '80%', minWidth: '70%', width: '70%', background: '#efefef',
                    borderRadius: "1.5rem", position: "relative", zIndex: 2, opacity: 0.9
                }}
                className='elevation'
            >
                <Box sx={{ height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container sx={{
                        alignItems: "center", justifyContent: "center", width: '70%', minWidth: '47vw',
                    }}>
                        <Grid item xs={12} sx={{ marginBottom: "0.5rem" }}>
                            <CustomTypography variant="h3" sx={{ lineHeight: 1.1 }} className='gradient-text'>
                                Hi there, {userName.split(' ')[0]}
                            </CustomTypography>
                            <CustomTypography variant="h3" sx={{ lineHeight: 1.1 }} className='gradient-text'>
                                What would you like to know?
                            </CustomTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                            <CustomTypography variant="h5" sx={{
                                lineHeight: 1.1,
                                fontSize: '1.38rem',
                                color: '#9d9d9d !important'
                            }}>
                                Use one of the most common prompts
                            </CustomTypography>
                            <CustomTypography variant="h5" sx={{
                                lineHeight: 1.1,
                                fontSize: '1.38rem',
                                color: '#9d9d9d !important'
                            }}>
                                below or use your own to begin
                            </CustomTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                            <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                {populatedCardsChat?.map(({ question }, index) => (
                                    <Grid item xs={2.9} key={index}>
                                        <CustomCard>
                                            <Grid container sx={{ height: "9rem", padding: "1rem" }}>
                                                <Grid item xs={12}>
                                                    <CustomTypography variant='subtitle1' sx={{ lineHeight: 1.1 }}>
                                                        {question}
                                                    </CustomTypography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomIconButton>
                                                        <Person4Outlined />
                                                    </CustomIconButton>
                                                </Grid>
                                            </Grid>
                                        </CustomCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '70%', minWidth: '47vw', height: '15%' }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: '1rem', textAlign: 'center' }}>
                            <CustomIconButton>
                                <>
                                    <Mic sx={{ color: '#9d9d9d !important' }} />
                                    <CustomTypography sx={{ color: '#9d9d9d !important' }}
                                        onClick={() => {
                                            chatDispatch({ type: "SET_CHAT_RUNNING", payload: false })  // (false)
                                            chatDispatch({ type: 'SET_VOICE_RUNNING', payload: true })
                                        }}
                                    >Speak to ask</CustomTypography>
                                </>
                            </CustomIconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldContainer />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </VideoBackground>
    )
}

export default memo(LandingPage)