import { useEffect, useState } from 'react';
import { errorToast } from '../../../helpers/apiToast';
import { GET } from '../../../helpers/http';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../../components/loading/Loader';
import { fetchBuildings, fetchDepartments, fetchRolesList, fetchUserList } from '../helpers';
import AddUser from './addUserForm';

const EditPage = () => {
    const { id } = useParams();

    const [userData, setUserData] = useState({});
    const [enabledFields, setEnabledFields] = useState({});
    const [buildingsList, setBuildingsList] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const { state } = useLocation();

    useEffect(() => {
        fetchBuildings({ setBuildings: setBuildingsList });
        fetchDepartments({ setDepartments: setDepartmentsList });
        fetchUserList({ setUserList });
        fetchRolesList({ setRolesList })
    }, []);

    useEffect(() => {
        if (id) {
            const fetchUserData = async (id) => {
                try {
                    const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/${id}`, 'Get User');
                    if (result?.status === 200) {
                        setUserData(result?.data?.userDetails || {});
                        setEnabledFields(result?.data?.editableFields);
                    }
                } catch (error) {
                    errorToast(error?.response?.data?.message || error?.message)
                }
            };
            fetchUserData(id);
        }
    }, [id]);

    return true ?
        <AddUser
            userData={userData}
            enabledFields={enabledFields}
            buildingsList={buildingsList}
            departmentsList={departmentsList}
            userList={userList}
            userId={id}
            type={state?.state?.type}
            rolesList={rolesList}
        />
        : <Loader height='90vh' width='100%' />
};

export default EditPage;
