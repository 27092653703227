import { Card } from '@mui/material'
import React, { memo } from 'react'

const CustomCard = ({ children, sx, borderRadius, border, ...rest }) => {
    return (
        <Card {...rest} sx={{ borderRadius: borderRadius || "1rem", border: border || '1px solid #e0e0e0', backgroundColor: 'transparent', ...sx }} elevation={0}>
            {children}
        </Card>
    )
}

export default memo(CustomCard)