import { Box, Typography } from '@mui/material'
import React from 'react'
import CommonStyles from '../../styles/CommonStyles'

const NoDataFound = (props) => {
    return (
        <Box sx={{ ...CommonStyles?.centerDivWidth100, height: props?.height }} >
            <Typography>No Data Found!</Typography>
        </Box>
    )
}

export default NoDataFound