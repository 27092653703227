import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../../../redux/store/store';

const state = store.getState()

const setHeaders = () => {
    let headers = {};
    headers['Content-Type'] = 'application/json';
    // headers['Access-Control-Allow-Credentials'] = true;
    // headers["Access-Control-Allow-Origin"] = "*"
    headers['x-csrf-token'] = localStorage.getItem('csrfToken');
    return headers;
};

const setConfigObj = () => {
    let config = {}
    config.headers = setHeaders()
    config.withCredentials = true
    return config
}

export const errorHandler = (error, label) => {
    const errorResponse = error.response
    if (
        errorResponse?.status === 400 ||
        // errorResponse?.status === 403 ||
        // errorResponse?.status === 404 ||
        errorResponse?.status === 406 ||
        errorResponse?.status === 409 ||
        errorResponse?.status === 413 ||
        errorResponse?.status === 500 ||
        errorResponse?.status === 502 ||
        (errorResponse?.status === 503 && errorResponse?.data?.error !== 'Service Unavailable' && errorResponse?.data?.isServiceEnabled !== 'NA') ||
        errorResponse?.status === 504 ||
        errorResponse?.status === 'FETCH_ERROR' ||
        error.originalStatus === 400 ||
        // error.originalStatus === 403 ||
        // error.originalStatus === 404 ||
        error.originalStatus === 406 ||
        error.originalStatus === 409 ||
        error.originalStatus === 413 ||
        error.originalStatus === 500 ||
        error.originalStatus === 502 ||
        error.originalStatus === 503 ||
        error.originalStatus === 504 ||
        error.originalStatus === 'FETCH_ERROR' ||
        error.status === 400 ||
        // error.status === 403 ||
        // error.status === 404 ||
        error.status === 406 ||
        error.status === 409 ||
        error.status === 413 ||
        error.status === 500 ||
        error.status === 502 ||
        error.status === 503 ||
        error.status === 504 ||
        error.status === 'FETCH_ERROR'
    ) {
        toast.error(`${label}: ${errorResponse?.data?.error?.[0]?.message || errorResponse?.data?.error || error?.data?.error?.[0]?.message || errorResponse?.data?.message || error?.data?.message || error?.data?.error || error?.error || errorResponse?.data[0]?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    if (errorResponse?.status === 401 || error.originalStatus === 401 || error.status === 401) {
        toast.error(`Invalid Token`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        localStorage.clear()
        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${state?.user?.email}`
    }
}

export const POST = (endpoint, body, label, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(endpoint, body, setConfigObj()) //process.env.REACT_APP_URL + 
            resolve(response)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && (error?.response?.data?.error === "Invalid csrf token" ||
                error?.response?.data?.message === "Invalid csrf token")) {
                GET_CSRF()
                if (showCsrfError === true) errorHandler(error, label)
            } else {
                errorHandler(error, label)
            }
            reject(error);
        }
    });
};


export const FILEPOST = (endpoint, body, label, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            let fileHeaders = {};
            const configObj = {}
            fileHeaders['Content-Type'] = 'multipart/form-data';
            fileHeaders['x-csrf-token'] = localStorage.getItem('csrfToken');
            configObj.withCredentials = true

            configObj.headers = fileHeaders

            // const response = await axios.post(endpoint, body, { withCredentials: true, headers: fileHeaders })
            const response = await axios.post(endpoint, body, configObj)
            resolve(response)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && (error?.response?.data?.error === "Invalid csrf token" ||
                error?.response?.data?.message === "Invalid csrf token")) {
                GET_CSRF()
                if (showCsrfError === true) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const PUT = (endpoint, body, label, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(endpoint, body, setConfigObj())
            resolve(response);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && (error?.response?.data?.error === "Invalid csrf token" ||
                error?.response?.data?.message === "Invalid csrf token")) {
                GET_CSRF()
                if (showCsrfError === true) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const GET = (endpoint, label) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get(endpoint, setConfigObj())
            resolve(response);
        } catch (error) {
            console.log(error);
            errorHandler(error, label)
            reject(error);
        }
    });
};

export const DELETE = (endpoint, label, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(endpoint, setConfigObj())
            resolve(response);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && (error?.response?.data?.error === "Invalid csrf token" ||
                error?.response?.data?.message === "Invalid csrf token")) {
                GET_CSRF()
                if (showCsrfError === true) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const DELETEWITHBODY = (endpoint, body, label, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(endpoint, { ...setConfigObj(), data: body })
            resolve(response);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && (error?.response?.data?.error === "Invalid csrf token" ||
                error?.response?.data?.message === "Invalid csrf token")) {
                GET_CSRF()
                if (showCsrfError === true) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const GET_CSRF = async (callback) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/csrf`, { withCredentials: true })
        if (response?.status === 200) {
            localStorage.setItem('csrfToken', response?.data?.csrf_token)
            callback && callback(true)
        }
    } catch (error) {
        console.log(error);
        errorHandler(error, "Fetch Csrf token")
    }
}
