import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import TextInput from '../../../../../components/formInputs/TextInput'
import { ThemeColors } from '../../../../../hooks/ThemeColors'

const NhanceResetPassword = (props) => {

    const { background_color } = ThemeColors()

    return (
        <Modal
            open={props.resetPasswordModalOpen}
            onClose={props.handleCloseResetPasswordModal}
            disableScrollLock={true}>
            {/* Reset Password Modal content */}
            <Box sx={{ bgcolor: background_color(props.themeMode) }} className='resetPasswordModal'>
                <Typography sx={{ mb: 2, fontSize: '18px', fontWeight: 700 }}>Reset Password</Typography>
                {/* Input fields for new password and confirmation */}
                <TextInput
                    placeholder="Enter new password"
                    variant='outlined'
                    type='password'
                    width={'100%'}
                    size={'small'}
                    name="newPassword"
                    value={props.newPassword}
                    onChange={props.handlePasswordInputChange}
                />
                <TextInput
                    placeholder="Confirm new password"
                    variant='outlined'
                    type='password'
                    width={'100%'}
                    size={'small'}
                    name="confirmPassword"
                    value={props.confirmPassword}
                    onChange={props.handlePasswordInputChange}
                    onKeyDown={props.onKeyDownConfirmPassword}
                    sx={{ my: 2 }}
                />
                {/* Display password error message */}
                {props.passwordError && (
                    <Typography sx={{ color: 'red', fontSize: '12px', mb: 1 }}>
                        {props.passwordError}
                    </Typography>
                )}
                <Box>
                    {/* Button to change the password */}
                    <Button variant='contained' className='changePasswordButton btnFontSize' onClick={props.submitPassword}>Change Password</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default NhanceResetPassword