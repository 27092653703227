import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { updateUser } from '../helpers';

function StatusSwitch({ status, id, disabled }) {
    const [checked, setChecked] = useState(status ? !Boolean(status) : true);

    // Handle switch change
    const handleChange = async (event) => {
        const result = await updateUser({ id, payload: { isSuspended: !event.target.checked } });
        if (result?.status === 200) setChecked(!checked);
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                    sx={{
                        // Switch base (overall switch container)
                        '& .MuiSwitch-switchBase': {
                            color: 'red ', // Default color when unchecked
                            '&.Mui-checked': {
                                color: 'green', // Color when checked
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.08) !important', // Red hover when unchecked
                            },
                            '&.Mui-checked:hover': {
                                backgroundColor: 'rgba(0, 128, 0, 0.08) !important', // Green hover when checked
                            },
                        },
                        // Track (background)
                        '& .MuiSwitch-track': {
                            backgroundColor: disabled ? '#808080 !important' : checked ? 'green !important' : '#dc4d28 !important', // Track color changes based on checked state
                        },
                        // Thumb (the round button)
                        '& .MuiSwitch-thumb': {
                            backgroundColor: disabled ? '#808080' : checked ? 'green' : '#dc4d28', // Thumb is always white
                        },
                    }}
                />
            }
        />
    );
}

export default StatusSwitch;
