import { Avatar, Box, Grid, styled } from "@mui/material"
import ReturnMultiTypeMedia from "../media/ReturnMultiTypeMedia"
import { AddCircleOutline, Delete } from "@mui/icons-material"
import Loading from "../loading/Loading"
import { ThemeColors } from "../../hooks/ThemeColors"

const MediaUploadForm = ({ fileReadUrl, handleMediaUpload, handleMediaDelete, uploadingMedia, medias, sx }) => {
    const { themeMode } = ThemeColors();

    //Styles 
    const visuallyHiddenInputStyle = {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    }

    const outerBoxStyle = {
        width: "100%",
        overflow: "auto",
        border: '0.5px solid gray',
        padding: "1rem",
        borderRadius: '1rem',
    }

    const addmediaButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '1rem',
        border: '1px solid lightgray',
        height: "100%",
        backgroundColor: themeMode ? '#5a5a5a' : '#fff',
    }

    const deleteAvatarStyle = {
        position: 'absolute',
        height: "1.5rem",
        width: "1.5rem",
        backgroundColor: "black",
        right: "0.5rem",
        top: "1.5rem"
    }

    const loadingStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '1rem',
        border: '1px solid lightgray',
        height: "100%",
        backgroundColor: themeMode ? '#5a5a5a' : '#fff',
    }

    const VisuallyHiddenInput = styled('input')(visuallyHiddenInputStyle);

    return (
        <Box sx={{ ...sx, ...outerBoxStyle }}>
            <Grid container spacing={2} sx={{ height: "8rem", }}>
                <Grid item sm={6} md={4} lg={3} xl={3} >
                    <Box
                        component='label'
                        sx={addmediaButtonStyle}>
                        <AddCircleOutline sx={{ fontSize: '3rem' }} />
                        <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => handleMediaUpload({ event })}
                        />
                    </Box>
                </Grid>
                {medias?.length > 0 &&
                    medias?.map(media => {
                        return <Grid item sm={6} md={4} lg={3} xl={3} sx={{ position: "relative", height: "100%" }}>
                            <ReturnMultiTypeMedia fitImage="cover" fileReadUrl={fileReadUrl} media={media} height="100%"
                                sx={{ border: '1px solid lightgray', borderRadius: "1rem" }} />
                            <Avatar sx={deleteAvatarStyle}>
                                <Delete
                                    fontSize="small"
                                    onClick={() => handleMediaDelete(media)}
                                    sx={{ cursor: 'pointer' }} />
                            </Avatar>
                        </Grid>
                    })}
                {uploadingMedia && <Grid item sm={6} md={4} lg={3} xl={3}>
                    <Box
                        sx={loadingStyle}>
                        <Loading height="100%" />
                    </Box>
                </Grid>}
            </Grid>
        </Box >
    )
}

export default MediaUploadForm