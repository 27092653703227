import { Menu } from '@mui/material'
import React from 'react'
import { ThemeColors } from '../../../../hooks/ThemeColors'

const NhanceHeaderMenu = (props) => {
    const { anchorEl, open, onCloseFunction, themeMode, className, children } = props

    const { background_color, color } = ThemeColors()

    return (
        <Menu
            anchorEl={anchorEl}
            disableScrollLock={true}
            open={open}
            onClose={onCloseFunction}
            className={className}
            sx={{
                '& .MuiMenu-paper': {
                    color: color(themeMode),
                    backgroundColor: background_color(themeMode),
                    boxShadow: '0px 0px 4px #808080'
                }
            }
            }>
            {children}
        </Menu>
    )
}

export default NhanceHeaderMenu