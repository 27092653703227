const AssetCarouselStyles = {
    boxContainer: {
        borderRadius: '10px',
        backgroundColor: 'lightgrey',
    },
    paperContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '10px',
    },
    boxItem: {
        height: '30vh',
        display: 'block',
        border: 'solid 0.5px lightgrey',
        overflow: 'hidden',
        width: '100%',
        borderRadius: '10px',
    },
    boxItemTwo: {
        height: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: 'solid 0.5px lightgrey',
        // overflow: 'hidden',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '10px',
    },
}
export default AssetCarouselStyles
