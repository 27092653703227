import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from 'react'
require("highcharts/highcharts-more")(Highcharts);

const HighChartsForgeAQI = (props) => {
    const options = {
        chart: {
            type: 'gauge',
            backgroundColor: null,
            height: '150',
            margin: '0',
        },
        credits: {
            enabled: false,
        },

        title: {
            text: ""
        },

        exporting: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        pane: [
            {
                startAngle: -90,
                endAngle: 90,
                background: null,
                center: ['50%', '90%'],
                size: 200,
            },
        ],
        yAxis: [
            {
                min: props.range?.good?.start,
                max: props.range?.bad?.end,
                minorTickPosition: 'inside',
                tickPosition: 'outside',
                labels: {
                    rotation: 'auto',
                    distance: 10,
                    style: {
                        color: 'gray',
                        fontSize: '10px',
                    },
                },

                plotBands: [
                    {
                        from: props.range?.good?.start,
                        to: props.range?.good?.end,
                        color: props.range?.good?.color,
                        innerRadius: '100%',
                        outerRadius: '70%',
                    },
                    {
                        from: props.range?.better?.start,
                        to: props.range?.better?.end,
                        color: props.range?.better?.color,
                        innerRadius: '100%',
                        outerRadius: '70%',
                    },
                    {
                        from: props.range?.bad?.start,
                        to: props.range?.bad?.end,
                        color: props.range?.bad?.color,
                        innerRadius: '100%',
                        outerRadius: '70%',
                    },
                ],
                title: {
                    text: '<br/><span style="font-size:12px;font-weight:600;">' + props.value + '</span>',
                    y: 25,
                },
            },
        ],

        plotOptions: {
            gauge: {
                dataLabels: {
                    enabled: false,
                },
                dial: {
                    radius: '100%',
                    backgroundColor: '#000',
                    borderColor: '#cccccc',
                    borderWidth: 1,
                },
            },
        },

        series: [{
            data: [props.value]
        }],
    }
    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default HighChartsForgeAQI