import { Stack, CircularProgress } from "@mui/material"
import { useSelector } from "react-redux"

const CircularProgressLoader = () => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <CircularProgress sx={{ color: color }} size={20} />
        </Stack>
    )
}

export default CircularProgressLoader