import React, { useState } from "react";
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { alpha, Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import DataTableStyles from "../../styles/DatatableStyles";
import CommonStyles from "../../styles/CommonStyles";

const WallDatatable = (props) => {
    const ODD_OPACITY = 0.2
    const [pageNum, setPageNum] = useState(0)
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#000' : '#fff'

    const StripedDataGrid = styled(DataGrid)(({ theme }) => (
        !themeMode ? {
            [`& .${gridClasses.row}.even`]: {
                backgroundColor: "#fff",
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha('#000', ODD_OPACITY),
                    color,
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                        color,
                    },
                },
                '&.Mui-selected': {
                    backgroundColor: alpha(
                        '#000',
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                    color,
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha(
                            '#000',
                            ODD_OPACITY +
                            theme.palette.action.selectedOpacity +
                            theme.palette.action.hoverOpacity,
                        ),
                        color,
                    },
                },
                ...CommonStyles.scrollStyle
            },
            [`& .${gridClasses.row}.odd`]: {
                backgroundColor: "#fff",
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha('#000', ODD_OPACITY),
                    color,
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                        color,
                    },
                },
                '&.Mui-selected': {
                    backgroundColor: alpha(
                        '#000',
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                    color,
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha(
                            '#000',
                            ODD_OPACITY +
                            theme.palette.action.selectedOpacity +
                            theme.palette.action.hoverOpacity,
                        ),
                        color,
                    },
                },
                ...CommonStyles.scrollStyle
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: bgcolor,
                color,
            },
        } :
            {
                [`& .${gridClasses.row}.even`]: {
                    backgroundColor: theme.palette.grey["A700"],
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha('#808080', ODD_OPACITY),
                        color,
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                            color,
                        },
                    },
                    '&.Mui-selected': {
                        backgroundColor: alpha(
                            '#fff',
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                        color,
                        '&:hover, &.Mui-hovered': {
                            backgroundColor: alpha(
                                '#fff',
                                ODD_OPACITY +
                                theme.palette.action.selectedOpacity +
                                theme.palette.action.hoverOpacity,
                            ),
                            color,
                        },
                    },
                    ...CommonStyles.scrollStyle
                },
                [`& .${gridClasses.row}.odd`]: {
                    backgroundColor: theme.palette.grey["A700"],
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha('#808080', ODD_OPACITY),
                        color,
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                            color,
                        },
                    },
                    '&.Mui-selected': {
                        backgroundColor: alpha(
                            '#fff',
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                        color,
                        '&:hover, &.Mui-hovered': {
                            backgroundColor: alpha(
                                '#fff',
                                ODD_OPACITY +
                                theme.palette.action.selectedOpacity +
                                theme.palette.action.hoverOpacity,
                            ),
                            color,
                        },
                    },
                    ...CommonStyles.scrollStyle
                },
                '& .MuiCheckbox-root svg': {
                    color
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: bgcolor,
                    color,
                },
            }
    ))

    return (
        <Box sx={{ ...DataTableStyles.boxContainer, height: props.tableHeight || 730 }}>
            <StripedDataGrid
                sx={{
                    ...CommonStyles.scrollStyle, ...DataTableStyles.textSize, '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    }
                }}
                className="theme-color"
                rows={props.rows}
                columns={props.columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                page={pageNum}
                pageSize={props.pageSize || 10}
                rowsPerPageOptions={[props.rowsPerPage || 10]}
                checkboxSelection={props.checkBox !== false || false}
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    props.setSelectionModel(newSelectionModel);
                }}
                slots={!props.hideheaderToolBar && { toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                getRowId={(row) => row.id ? row.id : row._id}
                onPageChange={(newPage) => setPageNum(newPage)}
                selectionModel={props.selectionModel}
                // showColumnRightBorder
                // showCellRightBorder
                experimentalFeatures={{ newEditingApi: true }}
                onCellClick={(event) => props.onAssetClick ? props.onAssetClick(event) : null}
                onRowClick={(event) => props.onRowClick ? props.onRowClick(event) : null}
                getRowHeight={() => props?.getRowHeightValue}
                hideFooter={props.hideFooter ? true : false}
            />
        </Box>
    )
}


export default WallDatatable;