import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import { errorHandler } from "../../components/facilityManager/helpers/http";

const fetchBuildingsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBuildings: builder.query({
            query: () => ({
                url: 'twin/v1/buildings',
                method: 'GET',
                credentials: "include"
            }),
            transformResponse: (responseData) => {
                const bid = localStorage.getItem('bid')
                if (!responseData?.some(e => e?._id === bid)) {
                    localStorage.setItem("bid", responseData?.[0]?._id)
                }
                return responseData
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Buildings')
            },
            providesTags: ['buildings']
        })
    })
})

export const { useGetBuildingsQuery } = fetchBuildingsSlice

const initialState = {
    appIsLoaded: false,
    isLoaded: false,
    buildings: [],
    selectedBuildingData: {},
    selectedBuilding: '',
};

const buildingsSlice = createSlice({
    name: 'buildings',
    initialState,
    reducers: {
        setAppIsLoaded: (state, action) => {
            state.appIsLoaded = action.payload
        },
        setSelectedBuilding: (state, action) => {
            state.isLoaded = true
            state.selectedBuilding = action.payload
            state.selectedBuildingData = state.buildings?.find(building => building._id === action.payload)
        },
        setSelectedBuildingData: (state, action) => {
            state.selectedBuildingData = action.payload
        },
        resetBuildings: (state, action) => {
            state = initialState
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'getBuildings' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const selectedBuilding = localStorage.getItem('bid')
                state.isLoaded = true
                state.buildings = action.payload
                if (selectedBuilding !== null && selectedBuilding !== undefined && selectedBuilding !== '' && selectedBuilding !== 'undefined') state.selectedBuilding = selectedBuilding
                else state.selectedBuilding = action.payload?.[0]?._id
                state.selectedBuildingData = action.payload?.[0]
            }
        );
    },
})

export const { setAppIsLoaded, setSelectedBuilding, setSelectedBuildingData, resetBuildings } = buildingsSlice.actions;

export default buildingsSlice.reducer;