import { Box, Chip, colors, Typography } from "@mui/material"
import CustomTypography from "../../../../components/texts/CustomTypography"
import { useSelector } from "react-redux"
import { ThemeColors } from "../../../../hooks/ThemeColors"
import ReturnMultiTypeMedia from "../../../../components/media/ReturnMultiTypeMedia"


const HistoryDetails = ({ data }) => {
    const fileReadUrl = useSelector((state) => state?.configurations?.ppmfileReadUrl)
    const { themeMode } = ThemeColors();

    return <Box sx={{
        width: "100%",
        // margin: "1rem",
        paddingX: "2rem",
        paddingTop: "1rem"
    }} >
        <Box sx={{
            backgroundColor: themeMode ? "#5a5a5a" : "#f5f5f5",
            paddingX: "1.5rem",
            paddingY: "1.5rem",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: "1rem"
        }}>
            {data?.comment &&
                <>
                    <CustomTypography sx={{ fontSize: "22px", fontWeight: 600 }} >Comment</CustomTypography>
                    <Typography sx={{ borderRadius: "1rem", fontSize: "18px", paddingLeft: "1rem" }}>{data?.comment}</Typography>
                </>
            }
            {(data?.relatedEntityType === "cost" || data?.relatedEntityType === "media") &&
                <>
                    {data?.relatedEntityType === "media" && <>
                        <CustomTypography sx={{ fontSize: "22px", fontWeight: 600 }} >Images</CustomTypography>
                        <Box
                            sx={{ display: "flex", flexDirection: "row", gap: "1rem", overflow: "auto" }}>
                            {data?.relatedEntities?.map((media, index) => {
                                return <Box key={index} sx={{ width: "15rem" }}>
                                    <ReturnMultiTypeMedia fileReadUrl={fileReadUrl} media={media} height="10rem" />
                                </Box>
                            })}
                        </Box>
                    </>}
                    {data?.relatedEntityType === "cost" && <>
                        <CustomTypography sx={{ fontSize: "22px", fontWeight: 600 }} >Cost Details</CustomTypography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            padding: "1rem",
                            backgroundColor: themeMode ? "gray" : "white",
                            borderRadius: "1rem"
                        }}>
                            {data?.relatedEntities?.map((cost, index) => {
                                return <Box key={index} sx={{ display: "flex", flexDirection: "column" }} >
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-around", paddingLeft: "1rem" }}>
                                        <CustomTypography sx={{ fontSize: "20px" }}>
                                            <strong>Amount:</strong> ₹ {cost?.amount}
                                        </CustomTypography>
                                        <CustomTypography sx={{ fontSize: "20px" }}>
                                            <strong>Expense By:</strong> {cost?.expenseBy?.fullName}
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                label={cost?.expenseBy?.type}
                                                sx={{ marginLeft: "0.5rem" }} />
                                        </CustomTypography>
                                        <CustomTypography
                                            sx={{ fontSize: "20px" }}>
                                            <strong>Invoice Number:</strong> {cost?.invoiceNumber}
                                        </CustomTypography>
                                        <CustomTypography
                                            sx={{ fontSize: "20px" }}>
                                            <strong>Vendor Name:</strong> {cost?.vendorName}
                                        </CustomTypography>
                                        <CustomTypography
                                            sx={{ fontSize: "20px" }}>
                                            <strong>Recorded By:</strong> {cost?.recordedBy?.fullName}
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                label={cost?.recordedBy?.type}
                                                sx={{ marginLeft: "0.5rem" }} />
                                        </CustomTypography>
                                    </Box>
                                </Box>
                            })}
                        </Box>
                    </>}
                </>
            }
        </Box>
    </Box>
}

export default HistoryDetails