const WeatherTempStyles = {
    boxContainerMain: {
        alignItems: "center",
        display: "flex",
    },
    weatherBox: {
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        '&:hover': { cursor: "pointer" }
    }
};

export default WeatherTempStyles;
