import { UPDATE_DASHBOARD_BOOKED_DESKS_TATA_DIGITAL } from "../actions/deskBookingActions"

const initialData = {
    isLoaded: false,
    error: "",
    data: {},
}

const tata_deskBookingReducer = (state = initialData, action) => {
    switch (action.type) {
        case UPDATE_DASHBOARD_BOOKED_DESKS_TATA_DIGITAL: {
            return { ...state, isLoaded: true, data: action.payload }
        }
        default: {
            return state
        }
    }
}

export default tata_deskBookingReducer