import React, { memo, useContext, useEffect } from "react";
import './chat.css';
import ChatInterface from "../../../features/chat/ChatInterface";
import VoiceInterFace from "../../../features/chat/components/VoiceInterFace";
import { ChatContext } from "../../../features/chat/context/ChatContext";
import LandingPage from "../../../features/chat/LandingPage";
import { GET } from "../../../helpers/http";
import { errorToast } from "../../../helpers/apiToast";

const Chat = () => {

    // const navigate = useNavigate();
    // const handleReturnClick = () => {
    //     navigate(-1);
    // }

    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { chatRunning, voiceCommand } = chatInfo;

    useEffect(() => {
        const timer = setTimeout(() => {
            chatDispatch({ type: "SET_CHAT_RUNNING", payload: true });
            // chatDispatch({ type: "SET_VOICE_RUNNING", payload: false });
        }, 5000);

        return () => clearTimeout(timer);
    }, [chatRunning, chatDispatch, voiceCommand]);

    useEffect(() => {
        (async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_MAVERICK_URL}chats`, 'Chat')
                if (response?.status === 200) {
                    chatDispatch({ type: "SET_CHAT_HISTORY", payload: response?.data?.chats })
                }
            } catch (error) {
                errorToast(error?.response?.data?.message || error?.message);
            }
        })()
    }, [])

    const renderedComponent = () => {
        // if (pathname === '/maverick/data') {
        //     if (userRole === 'admin') return <AdminStatsView />
        //     else errorToast('You are not authorized to access this page');
        // }
        if (chatRunning && !voiceCommand) {
            return <ChatInterface />
        }
        else if (!chatRunning && voiceCommand) {
            return <VoiceInterFace />
        }
        else {
            return <LandingPage />
        }
    }

    return (
        renderedComponent()
    );
};

export default memo(Chat);