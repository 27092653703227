import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@mui/icons-material'
import Datatable from '../../../components/datatable/Datatable'
import { GET } from '../../../helpers/http'
import CommonStyles from '../../../styles/CommonStyles'
import EditGroupModal from '../components/modals/EditGroupModal'
import ExternalEmailsStyles from '../../../styles/ExternalEmailsStyles'

const ExternalEmails = () => {
    const [externalEmails, setExternalEmails] = useState([])
    const [openEditGroupModal, setOpenEditGroupModal] = useState(false)
    const [selectedGroupId, setSelectedGroupId] = useState('')
    const [fetchEmails, setFetchEmails] = useState(true)

    useEffect(() => {
        if (fetchEmails) {
            const endpoint = `${process.env.REACT_APP_BASE_URL}um/v1/admin/group/external/members`

            GET(endpoint, 'Fetch External Emails')
                .then((response) => {
                    if (response?.status === 200) {
                        setExternalEmails(response?.data?.externalMembers)
                        setFetchEmails(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [fetchEmails])

    const handleOpenEditGroupModal = () => {
        setOpenEditGroupModal(true)
    }

    const handleCloseEditGroupModal = () => {
        setSelectedGroupId('')
        setOpenEditGroupModal(false)
    }

    const handleEditGroup = (groupId) => {
        setSelectedGroupId(groupId)
        handleOpenEditGroupModal()
    }

    const handleUpdateGroups = (updatedGroup) => {
        setFetchEmails(true)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Email',
            width: 500,
            valueGetter: params => params?.row?.email
        },
        {
            field: 'Groups',
            width: 500,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        {cellValues?.row?.groups?.map(group => {
                            return <Tooltip title="Edit this Group">
                                <IconButton
                                    onClick={() => handleEditGroup(group?._id)}
                                >
                                    <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                    <Typography color={'#DC4D28 !important'} variant={'caption'}>
                                        {group?.name}
                                    </Typography>
                                </IconButton>
                            </Tooltip>
                        })}
                    </Stack>
                )
            },
            sortable: false,
        },
    ]

    return (
        <Box
            sx={CommonStyles?.OuterBox} className='minHeight90vh'>
            <Typography
                sx={ExternalEmailsStyles.headingTypo}
            >
                External Emails
            </Typography>
            <Box
                sx={ExternalEmailsStyles.innerBox}
            >
                <Datatable
                    checkBox={false}
                    rows={externalEmails}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                />
            </Box>
            <EditGroupModal
                openEditGroupModal={openEditGroupModal}
                handleCloseEditGroupModal={handleCloseEditGroupModal}
                handleUpdateGroups={handleUpdateGroups}
                editingGroupId={selectedGroupId}
            />
        </Box>
    )
}

export default ExternalEmails