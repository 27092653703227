import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { errorHandler } from '../../../components/facilityManager/helpers/http'

const fetchHVACSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHVACDashboardStats: builder.query({
            query: (bid) => ({
                url: `twin/v1/hvac/s1/data?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'HVAC Dashboard Stats')
            },
        }),
    })
})

export const { useGetHVACDashboardStatsQuery } = fetchHVACSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        data: []
    },
}

const hvacSlice = createSlice({
    name: 'hvac',
    initialState,
    reducers: {
        resetDashboardHVAC: (state, action) => {
            state.dashboard = initialState.dashboard
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getHVACDashboardStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.isLoaded = true
                    state.dashboard.data = action.payload
                }
            )
    }
})

export const { resetDashboardHVAC } = hvacSlice.actions

export default hvacSlice.reducer
