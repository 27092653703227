import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const CheckBox = (props) => {
    const { checkboxLabel, checked, onChange, ml, pt, name, width, disabled } = props

    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    return (
        <FormControl sx={{ width: width || 400, pt: pt || 0 }}>
            <FormControlLabel
                disabled={!!disabled}
                control={
                    <Checkbox checked={checked} onChange={onChange} name={name} style={{ color }} sx={{ ml: ml || 0, boxShadow: 0 }} />
                }
                label={checkboxLabel}
                sx={{
                    '& .MuiFormControlLabel-label': {
                        color: color, // Change the label color to the desired color
                    }
                }}
            />
        </FormControl>

    )
}

export default CheckBox