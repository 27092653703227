import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material'

const PPMUpdate = (props) => {
    const { selectedUpdatePPMOption, setSelectedUpdatePPMOption, updatePPMOptions, updatePPM, updatingPPM } = props

    return (
        <Stack spacing={2}>
            <FormControl>
                <FormLabel>Select an Option</FormLabel>
                <RadioGroup
                    value={selectedUpdatePPMOption}
                    onChange={(e) => setSelectedUpdatePPMOption(e.target.value)}
                >
                    {Object.keys(updatePPMOptions)?.map((option) => (
                        <FormControlLabel key={option} value={option} control={<Radio />} label={updatePPMOptions[option]} />
                    ))}
                </RadioGroup>
            </FormControl>
            <Button className="actionButton" onClick={() => updatePPM(updatingPPM)}>Update</Button>
        </Stack>
    )
}

export default PPMUpdate