import { Backdrop, Box, Grid } from '@mui/material'
import React, { memo } from 'react'
import NhanceLogoutBtn from './logoutModalButton/NhanceLogoutBtn'

const NhanceLogoutModal = (props) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            onClick={props.handleClose}>
            {/* Logout confirmation modal */}
            <Box>
                <Grid container className='logout-card'>
                    <Grid item xs={12} align="center">
                        Are you sure?
                    </Grid>
                    <Grid item xs={6} align="center">
                        {/* Button to confirm sign out */}
                        <NhanceLogoutBtn className='signoutButton' onClickFunction={props.handleLogout} textContent='Sign Out' />
                    </Grid>
                    <Grid item xs={6} align="center">
                        {/* Button to cancel logout */}
                        <NhanceLogoutBtn className='cancelButton' onClickFunction={props.handleClose} textContent='Cancel' />
                    </Grid>
                </Grid>
            </Box>
        </Backdrop>
    )
}

export default memo(NhanceLogoutModal)