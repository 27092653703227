import React, { useEffect, useState } from 'react'
import View from './View';
import { useParams } from 'react-router-dom';
import { GET } from '../../../helpers/http';
import { errorToast } from '../../../helpers/apiToast';
import Loader from '../../../components/loading/Loader';

const ViewPage = () => {
    const { id } = useParams();

    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchUserData = async (id) => {
            try {
                const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/${id}`, 'Get User');
                if (result?.status === 200) {
                    setUserData(result?.data?.userDetails);
                }
            } catch (error) {
                errorToast(error?.response?.data?.message || error?.message)
            }
        };
        fetchUserData(id);
    }, [id]);

    return (
        Object.keys(userData).length > 0 ?
            <View userData={userData} /> :
            <Loader />
    )
}

export default ViewPage;