import React, { useEffect, useState } from 'react'
import Modal from '../../components/modal/Modal'
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material'
import TextInput from '../../components/formInputs/TextInput'
import CommonStyles from '../../styles/CommonStyles'
import Loader from '../../components/loading/Loader'
import { DELETEWITHBODY, GET } from '../../helpers/http'
import { successToast } from '../../helpers/apiToast'
import { useSelector } from 'react-redux'

const DeleteDepartmentCategoryModal = (props) => {
    const { deleteModalOpen, handleDeleteModalClose, deletingDepartment, deletingCategory, setDepartments, setCategories } = props

    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    // const bgcolor = themeMode ? '#282a2e' : '#fff'

    const entity = deletingDepartment?.name ? 'department' : deletingCategory?.name ? 'category' : ''

    const [dependencies, setDependencies] = useState({
        isLoaded: false,
        data: {}
    })

    const [departmentsSelectable, setDepartmentsSelectable] = useState([])
    const [categoriesSelectable, setCategoriesSelectable] = useState([])

    const [newlySelectedCategory, setNewlySelectedCategory] = useState('')
    const [newlySelectedDepartment, setNewlySelectedDepartment] = useState('')

    const [categoryError, setCategoryError] = useState('')
    const [departmentError, setDepartmentError] = useState('')

    const newlySelectedCategoryDepartmentChange = (e) => {
        if (deletingCategory?.name) setNewlySelectedCategory(e.target.value)
        else if (deletingDepartment?.name) setNewlySelectedDepartment(e.target.value)

        setCategoryError('')
        setDepartmentError('')
    }

    const handleModalClose = () => {
        if (handleDeleteModalClose) handleDeleteModalClose()
        setNewlySelectedCategory('')
        setNewlySelectedDepartment('')
    }

    const handleDelete = async () => {
        if (Object.keys(dependencies?.data)?.length > 0) {
            if (deletingCategory?.name && !newlySelectedCategory) {
                setCategoryError('Please select a new category')
                setDepartmentError('')
            }
            else if (deletingDepartment?.name && !newlySelectedDepartment) {
                setDepartmentError('Please select a new department')
                setCategoryError('')
            }
        }

        try {
            setDepartmentError('')
            setCategoryError('')
            let body = {}
            if (deletingCategory?._id) {
                const newBody = {
                    oldCategoryId: deletingCategory?._id,
                }
                if (newlySelectedCategory) newBody.newCategoryId = newlySelectedCategory
                body = newBody
            }
            else if (deletingDepartment?._id) {
                const newBody = {
                    oldDepartmentId: deletingDepartment?._id,
                }
                if (newlySelectedDepartment) newBody.newDepartmentId = newlySelectedDepartment
                body = newBody
            }
            const { status } = await DELETEWITHBODY(`${process.env.REACT_APP_BASE_URL}um/v1/admin/${deletingCategory?._id ? 'category' : deletingDepartment?._id ? 'department' : ''}`, body, `Delete ${entity}`, true)
            if (status === 200) {
                if (deletingCategory?._id) {
                    const categories = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, 'Fetch Categories')
                    if (setCategories) setCategories(categories?.data?.toSorted((a, b) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
                }
                else if (deletingDepartment?._id) {
                    const departments = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Fetch Departments')
                    if (setDepartments) setDepartments(departments?.data?.toSorted((a, b) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
                }
                successToast(`Deleted ${deletingCategory?.name || deletingDepartment?.name} ${entity} successfully!`)
                handleModalClose()
            }
        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const { status, data } = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/${deletingCategory?._id ? 'category' : deletingDepartment?._id ? 'department' : ''}/dependency/${deletingCategory?._id || deletingDepartment?._id}`, 'Fetch Delete Dependencies')
                if (status === 200) {
                    setDependencies({
                        isLoaded: true,
                        data: data?.dependencies
                    })
                    if (deletingCategory?._id) {
                        setCategoriesSelectable(data?.data)
                    }
                    else if (deletingDepartment?._id) {
                        setDepartmentsSelectable(data?.data)
                    }
                }
            } catch (error) {
                console.log({ error })
            }
        }

        if (deletingCategory?._id || deletingDepartment?._id) {
            getData()
        }
    }, [deletingCategory?._id, deletingDepartment?._id])

    return (
        <Modal
            modalOpen={deleteModalOpen}
            closeModal={handleModalClose}
            modalWidth={600}
            title={`Delete ${entity}`}
        >
            {
                (dependencies?.isLoaded) ? <Stack spacing={2}>
                    {
                        Object.keys(dependencies?.data)?.length > 0 && Object.values(dependencies?.data)?.map((item, index) => {
                            return <Typography key={index} sx={{ fontSize: '16px', color }}>
                                {item?.message}
                            </Typography>
                        })
                    }
                    {
                        (Object.keys(dependencies?.data)?.length > 0) &&
                        <>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, pt: 3, color }}>
                                Before deleting this {entity}, please select a {entity} under which you want to transfer the mentioned notifications/users/faults/assets.
                            </Typography>
                            <TextInput
                                select
                                // size='small'
                                SelectProps={{ style: { color } }}
                                label={`Assign a ${entity}`}
                                value={deletingCategory?.name ? newlySelectedCategory : deletingDepartment?.name ? newlySelectedDepartment : ''}
                                onChange={newlySelectedCategoryDepartmentChange}
                            >
                                {
                                    departmentsSelectable?.length > 0 ? departmentsSelectable.map(department => (
                                        <MenuItem key={department._id} value={department._id}>{department.name}</MenuItem>
                                    )) : categoriesSelectable?.length > 0 ? categoriesSelectable.map(category => (
                                        <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                                    )) : null
                                }
                            </TextInput>
                        </>
                    }
                    {
                        (Object.keys(dependencies?.data)?.length === 0) && <Typography sx={{ fontSize: '16px', textAlign: 'center', color }}>
                            <b>{deletingDepartment?.name || deletingCategory?.name}</b> {entity} has no dependencies found. Would you like to delete it?
                        </Typography>
                    }
                    {(newlySelectedCategory || newlySelectedDepartment) && <Typography sx={{ fontSize: '18px', fontWeight: 600, color }}>The mentioned notification mappings, assets and fault reports will be transferred to {deletingDepartment?.name ? departmentsSelectable.find(department => department._id === newlySelectedDepartment)?.name : deletingCategory?.name ? categoriesSelectable.find(category => category._id === newlySelectedCategory)?.name : ''} {entity}. Are you sure to delete {deletingDepartment?.name || deletingCategory?.name} {entity}?</Typography>}
                    {categoryError && <Typography sx={{ color: 'red', fontSize: '14px' }}>{categoryError}</Typography>}
                    {departmentError && <Typography sx={{ color: 'red', fontSize: '14px' }}>{departmentError}</Typography>}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button sx={{ ...CommonStyles.actionButton, bgcolor: 'black' }} onClick={handleDeleteModalClose}>Cancel</Button>
                        <Button sx={{ ...CommonStyles.actionButton }} onClick={handleDelete}>Delete</Button>
                    </Box>
                </Stack> :
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '300px' }} spacing={2}>
                        <Typography sx={{ fontSize: '16px' }}>Analysing dependencies before deleting the {entity}</Typography>
                        <Loader />
                    </Stack>
            }
        </Modal>
    )
}

export default DeleteDepartmentCategoryModal