import { errorHandler } from "../../components/facilityManager/helpers/http";
import { apiSlice } from "../api/apiSlice";

const fetchWeatherSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchWeather: builder.query({
            query: (bid) => ({
                url: `twin/v1/weather?bid=${bid}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Weather Dashboard')
            },
            providesTags: ['weather']

        }),
        fetchWeatherSecondary: builder.query({
            query: ({ date, bid }) => ({
                url: `twin/v1/weather/list/${date}?bid=${bid}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Weather Secondary')
            },
            providesTags: ['weather']
        })
    })
})

export const { useFetchWeatherQuery, useFetchWeatherSecondaryQuery } = fetchWeatherSlice