import React from 'react'
import ReportHeaderFooter from './ReportHeaderFooterAM'
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import CommonStyles from '../../../../styles/CommonStyles'

const ReportTableAM = (props) => {
    const { reportChunks, type, timePeriod } = props

    const getDateString = () => {
        if (type === 'OVERDUE MAINTENANCE ASSETS' || type === 'EXPIRED ASSETS') {
            return `From ${timePeriod?.startDate}`
        } else if (type === 'UPCOMING MAINTENANCE ASSETS' || type === 'EXPIRING ASSETS') {
            return `Till ${timePeriod?.endDate}`
        }
    }

    const getHeadingString = () => {
        if (type === 'OVERDUE MAINTENANCE ASSETS' || type === 'UPCOMING MAINTENANCE ASSETS') {
            return `Maintenance Date`
        } else if (type === 'EXPIRED ASSETS' || type === 'EXPIRING ASSETS') {
            return `Expiry Date`
        }
    }

    return (reportChunks?.map((reportChunk, i) => <ReportHeaderFooter bodyJustifyContent='center' key={i}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1%' }}>
            <Stack width={'100%'} spacing={1}>
                <Typography fontWeight={600} fontSize={'20px'}>{type} ({getDateString()})</Typography>
                <TableContainer >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>Name</TableCell>
                                <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>Sl. No.</TableCell>
                                <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>Building</TableCell>
                                <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>Floor</TableCell>
                                {/* <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>Status</TableCell> */}
                                <TableCell sx={{ fontSize: '18px', fontWeight: 600 }}>{getHeadingString()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportChunk?.map((ele, i) => (
                                <TableRow key={i}>
                                    <TableCell sx={{ fontSize: '17px', ...CommonStyles.textEllipsis, maxWidth: '200px' }}>{ele?.name}</TableCell>
                                    <TableCell sx={{ fontSize: '17px' }}>{ele?.serialNumber}</TableCell>
                                    <TableCell sx={{ fontSize: '17px' }}>{ele?.building}</TableCell>
                                    <TableCell sx={{ fontSize: '17px' }}>{ele?.floor}</TableCell>
                                    {/* <TableCell sx={{ fontSize: '17px' }}>{ele?.status}</TableCell> */}
                                    <TableCell sx={{ fontSize: '17px' }}>{(moment(ele?.expiryDate || ele?.maintenanceDate)?.format('LL') || 'NA')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
    </ReportHeaderFooter >))
}

export default ReportTableAM