import { Grid, Typography } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import CustomTypography from "../../../components/texts/CustomTypography"
import DetailsInfo from "../components/taskView/DetailsInfo"
import TaskProgress from "../components/taskView/TaskProgress"
import Loader from "../../../components/loading/Loader"
import { useEffect, useState } from "react"
import ImageFromFiles from "../ImageFromFiles"
import Carousel from "react-material-ui-carousel"
import ReturnMultiTypeMedia from "../../../components/media/ReturnMultiTypeMedia"
import { useSelector } from "react-redux"
import HistoryCard from "../components/taskView/HistoryCard"
import NoDataFound from "../../../components/noDataFound/NoDataFound"


const Details = ({ task, isLoading, refreshSingleTask, historyList, setRefreshHistory, refreshHistory }) => {
    const [readmore, setReadmore] = useState(false)
    const fileReadUrl = useSelector(state => state?.configurations?.ppmfileReadUrl)
    const recentHistory = historyList.slice(0, 5)
    const disableArrow = false


    useEffect(() => {
        refreshSingleTask()
        setRefreshHistory(!refreshHistory)
    }, [])



    const capitalizeFirst = (word) => {
        if (!word) { // Check if the word is empty
            return "";
        }
        return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);
    }



    const ppmImageRender = ({ task }) => {
        // if (task?.media?.length === 0) {
        //     return <ImageFromFiles
        //         sx={{
        //             height: "20vh",
        //             borderRadius: '.5rem'
        //         }}
        //     />


        // } else if (task?.media?.length > 1) {
        //     return <Carousel sx={{ height: "20vh", width: "20rem" }} >
        //         {task?.media?.map((file, index) => {
        //             return <ReturnMultiTypeMedia key={index}
        //                 height="10rem"
        //                 fileReadUrl={fileReadUrl}
        //                 media={file} />
        //         })}
        //     </Carousel>
        // } else {
        //     return <ReturnMultiTypeMedia
        //         height="10rem"
        //         fileReadUrl={fileReadUrl}
        //         media={task?.media?.[0]} />
        // }
        if (task?.media?.length === 0) {
            return (
                <ImageFromFiles
                    sx={{
                        height: "20vh",
                        width: "20rem", // Ensure consistent width
                        borderRadius: '.5rem',
                        objectFit: 'cover' // To maintain aspect ratio while filling the container
                    }}
                />
            );
        } else if (task?.media?.length > 1) {
            return (
                <Carousel sx={{ height: "25vh", width: "20rem" }}>
                    {task?.media?.map((file, index) => {
                        return (
                            <ReturnMultiTypeMedia
                                key={index}
                                height="20vh" // Ensure consistent height
                                width="20rem" // Ensure consistent width
                                fileReadUrl={fileReadUrl}
                                media={file}
                                sx={{ objectFit: 'cover' }} // Keep image aspect ratio while filling
                            />
                        );
                    })}
                </Carousel>
            );
        } else {
            return (
                <ReturnMultiTypeMedia
                    height="20vh" // Ensure consistent height
                    width="20rem" // Ensure consistent width
                    fileReadUrl={fileReadUrl}
                    media={task?.media?.[0]}
                    sx={{ objectFit: 'cover' }} // Keep image aspect ratio while filling
                />
            );
        }

    };

    return (
        <CustomComponentContainer
            className='commonComponentSpacing'
        >
            {!isLoading ? (
                <Grid container>  {/* main */}
                    <Grid item xs={12}
                        sx={{
                            margin: "20px",
                            // border: "1px solid red"
                        }}
                    >    {/*  title*/}
                        <CustomTypography
                            sx={{
                                fontSize: "26px", fontWeight: "800",
                                margin: "10px",
                            }}
                        >
                            {task?.title?.slice(0, 50)}
                        </CustomTypography>
                        <Grid container xs={12}
                        // sx={{ border: "1px solid blue" }}
                        >     {/*  body*/}
                            <Grid container
                                xs={12}
                                md={6}
                            // sx={{ border: "1px solid green" }}
                            >  {/*  left*/}
                                <Grid
                                    item
                                    xs={12} md={12} lg={6} // Responsive sizes
                                    sx={{
                                        paddingLeft: "10px",
                                        // border: "1px solid blue"
                                    }}
                                >
                                    {ppmImageRender({ task })}
                                </Grid>
                                <Grid
                                    item xs={12} md={6}
                                // sx={{ border: "1px solid orange" }}
                                >
                                    {!readmore ? (
                                        <Typography
                                            sx={{
                                                textAlign: "justify",
                                                display: "flex", justifyContent: "left"
                                            }}
                                        >
                                            <Typography
                                                className="color-darkgray"
                                                sx={{ fontSize: "18px" }}
                                            >
                                                {task?.description?.slice(0, 200) + " "}
                                                {task?.description?.length > 200 && (
                                                    <Grid
                                                        className="cursorPtr txtTransformLow fontweight600"
                                                        component="span"
                                                        onClick={() => setReadmore(!readmore)}
                                                        size="small"
                                                    >
                                                        <i>...Read more</i>
                                                    </Grid>
                                                )}
                                            </Typography>
                                        </Typography>
                                    ) : (
                                        <Typography className="color-darkgray"
                                            sx={{
                                                textAlign: "justify",
                                                display: "flex", justifyContent: "left"
                                            }}
                                        >
                                            <Grid sx={{ fontSize: "18px" }}>
                                                {task?.description} {readmore && (
                                                    <i
                                                        className="cursorPtr fontweight600 txtTransformLow"
                                                        onClick={() => setReadmore(!readmore)}
                                                    >
                                                        ...read less
                                                    </i>
                                                )}
                                            </Grid>
                                        </Typography>
                                    )}


                                </Grid>
                                <Grid container xs={12}
                                    sx={{
                                        padding: "25px 10px",
                                        display: "flex",
                                        // flexDirection: "column", // Arrange items vertically
                                        justifyContent: "center", // Align items at the top

                                        // border: "1px solid blue"
                                    }}
                                >
                                    <DetailsInfo
                                        refreshSingleTask={refreshSingleTask}
                                        capitalizeFirst={capitalizeFirst}
                                        historyList={historyList}
                                        task={task}
                                    />


                                </Grid>

                            </Grid>

                            <Grid container xs={12} md={6}>
                                <Grid item
                                    xs={12}
                                    sx={{
                                        // border: "1px solid yellow",
                                        minHeight: "200px", // Set a minimum height
                                        maxHeight: "200px", // Set a maximum height to prevent resizing
                                        paddingLeft: "4.8rem"
                                        // display: "flex",
                                        // justifyContent: "center"
                                    }}
                                >

                                    <TaskProgress capitalizeFirst={capitalizeFirst} status={task?.taskStatus} />

                                </Grid>
                                <Grid item
                                    xs={12}
                                    sx={{
                                        // border: "1px solid red",
                                        display: "flex",
                                        flexDirection: "column",
                                        // padding: "10px",
                                        minHeight: "450px",
                                        maxHeight: "450px", // Set a maximum height to prevent resizing
                                        overflowY: "auto" // Enable scrolling if content exceeds the height
                                    }}
                                >
                                    {
                                        recentHistory?.length > 0 ?
                                            recentHistory?.map((log, index) => {
                                                return (

                                                    <HistoryCard
                                                        key={log?._id}
                                                        disableArrow={disableArrow}
                                                        data={log}
                                                        isCardSelected={recentHistory?._id === log?._id}
                                                        sx={{
                                                            borderRadius: "1rem",
                                                        }}
                                                        type={recentHistory[recentHistory?.length - 1]?._id === log?._id ? "last" : "normal"}
                                                    />

                                                )
                                            })
                                            :
                                            <NoDataFound height="70vh" message="Log not found" />
                                    }

                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            ) : (
                <Loader height='70vh' sx={{ width: "100%" }} />
            )
            }
        </CustomComponentContainer >

    )
}

export default Details