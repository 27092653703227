import { Box, Modal, Paper } from '@mui/material'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useSelector } from 'react-redux'
import ModalStyles from '../../../../styles/ModalStyles'

const ModalComp = (props) => {
    const { closeModal, modalOpen, modalWidth } = props
    const darkTheme = useSelector(state => state.theme.darkTheme)
    const color = !darkTheme ? '#282a2e' : '#fff'
    const bgcolor = darkTheme ? '#282a2e' : '#fff'

    return (
        <Modal open={modalOpen} onClose={closeModal} disableScrollLock>
            <Paper sx={{ ...ModalStyles.paperContainer, bgcolor, width: modalWidth ? modalWidth : '50%', height: props.height, ...props.sx }} className='theme-component-background theme-border theme-cards-boxShadow modal-container'>
                <Box sx={ModalStyles.boxContainer}>
                    <CloseOutlinedIcon
                        fontSize="small"
                        sx={{ color: color, '&:hover': { cursor: 'pointer' } }}
                        onClick={closeModal}
                    />
                </Box>
                {props.children}
            </Paper>
        </Modal>
    )
}

export default ModalComp
