import { Avatar, Box, Button, Chip, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from "moment";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { GET, PUT } from "../../../../helpers/http";
import PPMStatusDropdown from "./PPMStatusDropdown";
import FormInput from "../../../../components/formInputs/FormInput";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ThemeColors } from "../../../../hooks/ThemeColors";
import useApiCalls from "../hooks/useApiCalls";
import HistoryCard from "./HistoryCard";
import HistoryDetails from "./HistoryDetails";
import NoDataFound from "../../../../components/noDataFound/NoDataFound";
import { useNavigate, useParams } from "react-router-dom";
import CustomTypography from "../../../../components/texts/CustomTypography";

const DetailsInfo = ({ task, capitalizeFirst, refreshSingleTask, historyList }) => {
    const [selectedStatus, setSelectedStatus] = useState(task?.taskStatus ?? '')
    const [taskOptions, setTaskOptions] = useState([]);
    const [taskoptionsLoaded, setTaskOptionsLoaded] = useState(false)
    const [overdueDateTime, setOverdueDateTime] = useState(task?.overdueFields?.overdueFrom ?? "")
    const { textColor, themeMode } = ThemeColors()
    const { changeTaskStatus, changeOverdue } = useApiCalls()
    const CHIP_BACKGROUND_COLOR = themeMode && "dimgrey"
    const { id } = useParams();
    const { fetchHistory } = useApiCalls()
    const [batchNumber, setBatchNumber] = useState(1)
    // const [historyList, setHistoryList] = useState([])
    const [historyListLoaded, setHistoryListLoaded] = useState(false)
    const [loadMoreEnded, setLoadmoreEnded] = useState(false)
    const [recentHistory, setRecentHistory] = useState([])

    const disableArrow = false
    const navigate = useNavigate()

    useEffect(() => {
        // fetchHistory({ id, batchNumber, setHistoryList, setHistoryListLoaded, historyList, setLoadmoreEnded, setRecentHistory })
        setRecentHistory(historyList.slice(0, 5))

    }, [id, batchNumber])

    const [openDetails, setOpenDetails] = useState(false)
    const [selectedHistory, setSelectedHistory] = useState({})



    // const handleHistory = () => {
    //     navigate("/")
    // }
    const muiChipStyle = {
        width: "10vw",
        display: "flex",
        justifyContent: "start",
        backgroundColor: CHIP_BACKGROUND_COLOR,
        "& .MuiChip-label": {
            color: "black"
        }
    }

    const overdueDate = moment(overdueDateTime || new Date()).format('YYYY-MM-DD HH:mm');
    const overdueFromDate = moment(task?.overdueFields?.overdueFrom || new Date()).format('YYYY-MM-DD HH:mm');
    const isOverdueChanged = overdueDate !== overdueFromDate;

    const getAllTaskStatus = async ({ id, setTaskOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/status-options?id=${id}`, 'Get Task Status');
            if (response.status === 200) {
                setTaskOptions(response?.data);
                setTaskOptionsLoaded(true)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };


    useEffect(() => {
        (async () => {
            if (task?.id) {
                await getAllTaskStatus({ id: task?.id, setTaskOptions });
            }
        })()
    }, [task?.id])

    const handleDropDownChange = async (e) => {
        const value = e.target.value;
        try {
            const response = await changeTaskStatus({ ppmId: task?.id, taskStatus: value })
            if (response?.status === 200) {
                setSelectedStatus(value)
                refreshSingleTask()
                successToast("Status Updated")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const handleOverdueChange = async () => {
        try {

            const overdueFields = {
                isOverdue: !!overdueDateTime
            }

            if (overdueDateTime) overdueFields['overdueFrom'] = new Date(overdueDateTime)
            const response = await changeOverdue({
                ppmId: task?.id,
                overdueFields
            })
            if (response?.status === 200) {
                refreshSingleTask()
                successToast("Overdue Updated")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    }
    return (
        <Grid
            container
            gap={4}
            sx={{
                // paddingTop: "4rem",
                maxWidth: "100%",
            }}
        >

            <Grid item xs={6} md={2.75}
                sx={{
                    // border: "1px solid green"
                }}
            >
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Status</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Priority</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Assignment Status</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Assigned To</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Assigned Vendor</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Start Time</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">End Time</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Overdue On</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Floor</CustomTypography>
            </Grid>

            <Grid item xs={6} md={2}
                sx={{
                    // border: "1px solid orange",
                    // marginRight: "10px"
                }}
            >
                <CustomTypography className="font16 fontweight600 height50px">
                    {(taskoptionsLoaded && !task?.isDeleted) ? (
                        <PPMStatusDropdown
                            selectedValue={selectedStatus}
                            dropdownOptions={[task?.taskStatus, ...taskOptions]}
                            handleChangeFun={handleDropDownChange}
                            capitalizeFirst={capitalizeFirst}
                        />
                    ) : (
                        capitalizeFirst(task?.taskStatus)
                    )}
                </CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                    <Chip
                        sx={{
                            backgroundColor:
                                task?.priority?.toLowerCase() === "low" ? "#ECFFE6" :
                                    task?.priority?.toLowerCase() === "medium" ? "papayawhip" :
                                        task?.priority?.toLowerCase() === "high" ? "mistyrose" : null,
                            border: `1px solid ${task?.priority?.toLowerCase() === "low" ? "green" :
                                task?.priority?.toLowerCase() === "medium" ? "sandybrown" :
                                    task?.priority?.toLowerCase() === "high" ? "red" : null}`,
                            width: "10vw",
                            display: "flex",
                            justifyContent: "start",
                            "& .MuiChip-label": {
                                color: task?.priority?.toLowerCase() === "low" ? "green" :
                                    task?.priority?.toLowerCase() === "medium" ? "sandybrown" :
                                        task?.priority?.toLowerCase() === "high" ? "red" : null,
                            }
                        }}
                        avatar={<FiberManualRecordIcon fontSize="small" style={{
                            color: task?.priority?.toLowerCase() === "low" ? "green" :
                                task?.priority?.toLowerCase() === "medium" ? "sandybrown" :
                                    task?.priority?.toLowerCase() === "high" ? "red" : null
                        }} />}
                        label={capitalizeFirst(task?.priority)}
                    />
                </CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{capitalizeFirst(task?.assignmentStatus)}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                    <Chip
                        sx={{
                            width: "10vw",
                            display: "flex",
                            justifyContent: "start",
                            backgroundColor: CHIP_BACKGROUND_COLOR,
                            "& .MuiChip-label": { color: "black" }
                        }}
                        avatar={<Avatar alt={task?.currentService?.assignedUser?.name} src={task?.currentService?.assignedUser?.profilePictureUrl} />}
                        label={<Box sx={{ display: "flex", alignItems: "center", width: "7vw" }}>
                            {capitalizeFirst(task?.currentService?.assignedUser?.name)}
                            {task?.currentService?.assignedUserType === "external" && <Tooltip title="External User"><ArrowOutwardIcon fontSize="small" /></Tooltip>}
                        </Box>}
                    />
                </CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                    {task?.currentService?.assignedVendor?.name ? (
                        <Chip
                            sx={{ width: "10vw", display: "flex", justifyContent: "start", backgroundColor: CHIP_BACKGROUND_COLOR, "& .MuiChip-label": { color: "black" } }}
                            avatar={<Avatar alt={task?.currentService?.assignedVendor?.name} src={task?.currentService?.assignedVendor?.profilePictureUrl} />}
                            label={capitalizeFirst(task?.currentService?.assignedVendor?.name)}
                        />
                    ) : "-"}
                </CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{moment(`${task?.date}T${task?.startTime}`).format('lll') ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{moment(`${task?.date}T${task?.endTime}`).format('lll') ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.overdueOn ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.floor?.name ?? "-"}</CustomTypography>
            </Grid>

            <Grid item xs={6} md={2}
                sx={{
                    // marginLeft: "10px",
                    // border: "1px solid yellow"
                }}

            >
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Area</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Category</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Asset</CustomTypography>
                <CustomTypography className="color-darkgray font16 fontweight600 height50px">Created By</CustomTypography>
                {
                    task.isRecursive && <>
                        <CustomTypography className="color-darkgray font16 fontweight600 height50px">Frequency</CustomTypography>
                        <CustomTypography className="color-darkgray font16 fontweight600 height50px">Interval</CustomTypography>
                        <CustomTypography className="color-darkgray font16 fontweight600 height50px">Until</CustomTypography>
                        <CustomTypography className="color-darkgray font16 fontweight600 height50px">Avoid Saturday</CustomTypography>
                        <CustomTypography className="color-darkgray font16 fontweight600 height50px">Avoid Sunday</CustomTypography>

                    </>
                }
            </Grid>

            <Grid item xs={6} md={2.75}
            // sx={{ border: "1px solid red" }}
            >
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.area?.name ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.category?.name ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.assetRef?.name ?? "-"}</CustomTypography>
                <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                    <Chip
                        sx={{ width: "10vw", display: "flex", justifyContent: "start", backgroundColor: CHIP_BACKGROUND_COLOR, "& .MuiChip-label": { color: "black" } }}
                        avatar={<Avatar alt={task?.createdBy?.name} src={task?.createdBy?.profilePictureUrl} />}
                        label={<Box sx={{ display: "flex", alignItems: "center", width: "7vw" }}>
                            {capitalizeFirst(task?.createdBy?.name)}
                            {task?.createdBy?.userType === "external" && <Tooltip title="External User"><ArrowOutwardIcon fontSize="small" /></Tooltip>}
                        </Box>}
                    />
                </CustomTypography>
                {
                    task.isRecursive && <>
                        <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.frequency ?? "-"}</CustomTypography>
                        <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.interval ?? "-"}</CustomTypography>
                        <CustomTypography className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.until ?? "-"}</CustomTypography>
                        <CustomTypography className="font16 fontweight600 height50px">
                            <Chip
                                sx={muiChipStyle}
                                avatar={task?.recursiveConfiguration?.avoidSaturday ? <CheckCircleTwoToneIcon fontSize="small" /> : <CancelTwoToneIcon fontSize="small" />}
                                label={"Avoid Saturday"}
                            />
                        </CustomTypography>
                        <CustomTypography className="font16 fontweight600 height50px">
                            <Chip
                                sx={muiChipStyle}
                                avatar={task?.recursiveConfiguration?.avoidSunday ? <CheckCircleTwoToneIcon fontSize="small" /> : <CancelTwoToneIcon fontSize="small" />}
                                label={"Avoid Sunday"}
                            />
                        </CustomTypography>
                    </>
                }
            </Grid>
        </Grid>


    )
}

export default DetailsInfo