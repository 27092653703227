import { validPositiveData } from "./validData"

export const washroomAqiOccColors = (label, value, isLatest) => {
    const washroom = {}
    if (label?.toLowerCase() === "aqi") {
        if (!validPositiveData(value)) washroom.aqi = '#757575'
        else if (isLatest === false) washroom.aqi = '#800080'
        else {
            if (value >= 0 && value <= 50) washroom.aqi = '#00E400' //Excellent
            else if (value >= 51 && value <= 100) washroom.aqi = '#92D050' //Good
            else if (value >= 101 && value <= 150) washroom.aqi = '#FFFF00' //Lightly Polluted
            else if (value >= 151 && value <= 200) washroom.aqi = '#FF7E00' //Moderately polluted
            else if (value >= 201 && value <= 250) washroom.aqi = '#FF0000' //Heavily polluted
            else if (value >= 251 && value <= 350) washroom.aqi = '#99004C' //Severely polluted
            else if (value > 351) washroom.aqi = '#663300' //Extremely polluted
        }

    }
    else if (label?.toLowerCase() === "ammonia") {
        if (!validPositiveData(value)) washroom.ammonia = '#757575'
        else if (isLatest === false) washroom.ammonia = '#800080'
        else {
            if (value >= 0) washroom.ammonia = '#A0E400'
        }
    }
    else if (label?.toLowerCase() === "temperature") {
        if (!validPositiveData(value)) washroom.temperature = '#757575'
        else if (isLatest === false) washroom.temperature = '#800080'
        else {
            if (value <= 21) washroom.temperature = '#0060a9'
            else if (value > 21 && value <= 25) washroom.temperature = '#A0E400'
            else if (value > 25 && value <= 29) washroom.temperature = '#ffa500'
            else if (value > 29) washroom.temperature = '#ff0000'
        }
    }
    else if (label?.toLowerCase() === "humidity") {
        if (!validPositiveData(value)) washroom.humidity = '#757575'
        else if (isLatest === false) washroom.humidity = '#800080'
        else {
            if (value <= 40) washroom.humidity = '#ffa500'
            else if (value > 40 && value <= 60) washroom.humidity = '#A0E400'
            else if (value > 60) washroom.humidity = '#ff0000'
        }
    }
    else if (label?.toLowerCase() === "washroom_occupancy") {
        if (!validPositiveData(value)) washroom.occupancy = '#757575'
        else if (isLatest === false) washroom.occupancy = '#800080'
        else {
            if (value >= 0) washroom.occupancy = '#A0E400'
        }
    }
    else if (label?.toLowerCase() === "washroom_papertowel") {
        if (!validPositiveData(value)) washroom.papertowel = '#757575'
        else if (isLatest === false) washroom.papertowel = '#800080'
        else {
            if (value <= 25) washroom.papertowel = '#ff0000'
            else if (value > 25 && value <= 75) washroom.papertowel = '#ffa500'
            else if (value > 75) washroom.papertowel = '#A0E400'
        }
    }
    else if (label?.toLowerCase() === "washroom_bin") {
        if (!validPositiveData(value)) washroom.bin = '#757575'
        else if (isLatest === false) washroom.bin = '#800080'
        else {
            if (value <= 25) washroom.bin = '#ff0000'
            else if (value > 25 && value <= 75) washroom.bin = '#ffa500'
            else if (value > 75) washroom.bin = '#A0E400'
        }
    }
    else if (label?.toLowerCase() === "soap_solution") {
        if (!validPositiveData(value)) washroom.soapSolution = '#757575'
        else if (isLatest === false) washroom.soapSolution = '#800080'
        else {
            if (value <= 25) washroom.soapSolution = '#ff0000'
            else if (value > 25 && value <= 75) washroom.soapSolution = '#ffa500'
            else if (value > 75) washroom.soapSolution = '#A0E400'
        }
    }

    return washroom
}