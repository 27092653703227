import { validPositiveData } from "./validData"

export const aqiColors = (label, value, isLatest) => {
    const aqi = {}

    if (label?.toLowerCase() === "air_pressure") {
        if (!validPositiveData(value)) aqi["air_pressure"] = '#aaaaaa'
        else if (isLatest === false) aqi["air_pressure"] = '#800080'
        else {
            if (value < 1013.3) aqi["air_pressure"] = '#ffa500'
            else if (value === 1013.3) aqi["air_pressure"] = '#A0E400'
            else if (value > 1013.3) aqi["air_pressure"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "co2") {
        if (!validPositiveData(value)) aqi["co2"] = '#aaaaaa'
        else if (isLatest === false) aqi["co2"] = '#800080'
        else {
            if (value <= 800) aqi["co2"] = '#A0E400'
            else if (value > 800 && value <= 1200) aqi["co2"] = '#ffa500'
            else if (value > 1200) aqi["co2"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "humidity") {
        if (!validPositiveData(value)) aqi["humidity"] = '#aaaaaa'
        else if (isLatest === false) aqi["humidity"] = '#800080'
        else {
            if (value <= 40) aqi["humidity"] = '#ffa500'
            else if (value > 40 && value <= 60) aqi["humidity"] = '#A0E400'
            else if (value > 60) aqi["humidity"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "illuminance") {
        if (!validPositiveData(value)) aqi["illuminance"] = '#aaaaaa'
        else if (isLatest === false) aqi["illuminance"] = '#800080'
        else {
            if (value <= 300) aqi["illuminance"] = '#ffa500'
            else if (value > 300 && value <= 500) aqi["illuminance"] = '#A0E400'
            else if (value > 500) aqi["illuminance"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "radon") {
        if (!validPositiveData(value)) aqi["radon"] = '#aaaaaa'
        else if (isLatest === false) aqi["radon"] = '#800080'
        else {
            if (value <= 100) aqi["radon"] = '#A0E400'
            else if (value > 100 && value <= 150) aqi["radon"] = '#ffa500'
            else if (value > 150) aqi["radon"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "temperature") {
        if (!validPositiveData(value)) aqi["temperature"] = '#aaaaaa'
        else if (isLatest === false) aqi["temperature"] = '#800080'
        else {
            if (value <= 21) aqi["temperature"] = '#0060a9'
            else if (value > 21 && value <= 25) aqi["temperature"] = '#A0E400'
            else if (value > 25 && value <= 29) aqi["temperature"] = '#ffa500'
            else if (value > 29) aqi["temperature"] = '#ff0000'
        }
    }
    if (label?.toLowerCase() === "voc") {
        if (!validPositiveData(value)) aqi["voc"] = '#aaaaaa'
        else if (isLatest === false) aqi["voc"] = '#800080'
        else {
            if (value <= 112) aqi["voc"] = '#A0E400'
            else if (value > 112 && value <= 336) aqi["voc"] = '#ffa500'
            else if (value > 336) aqi["voc"] = '#ff0000'
        }
    }

    return aqi
}