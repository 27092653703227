import { Grid } from '@mui/material'
import React from 'react'
import CustomComponentContainer from '../container/CustomComponentContainer'
import CustomSecondaryHeader from '../../features/components/header/CustomSecondaryHeader'

const SecondaryPageRenderer = ({ componentData = [] }) => {
    return (
        <Grid container>
            {componentData.map(({ className, title, component, xs, tooltip, paddingRight, filterComponent }, index) => {
                return <Grid item xs={xs} key={index} className={paddingRight && 'padright20px'}>
                    {
                        className ? <CustomComponentContainer className={className}>
                            {title && <CustomSecondaryHeader tooltip={tooltip} component={filterComponent}>{title}</CustomSecondaryHeader>}
                            {component}
                        </CustomComponentContainer> :
                            component
                    }
                </Grid>
            })}
        </Grid>
    )
}

export default SecondaryPageRenderer