import { Avatar, Box, Chip, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { GET } from "../../../../../helpers/http"
import { errorToast } from "../../../../../helpers/apiToast"
import ActionButton from "./ActionButton"
import CircularProgressLoader from "../../../../../components/loading/CircularProgress"
import { useSelector } from "react-redux"
import TablePagination from "./TablePagination"
import moment from "moment"
import WallDatatable from "../../../../../components/datatable/WallDatatable"
import ImageRenderer from "../../../../../components/image/ImageRenderer"
import { profilePicture } from "../../../../../../../data/imageUrls"
import { useLocation, useNavigate } from "react-router-dom"

const ContentReviewTable = ({ entity, state, selectedUser, postId }) => {
    const [itemsData, setItemsData] = useState([])
    // const [selectedRow, setSelectedRow] = useState({})
    const location = useLocation()
    const [page, setPage] = useState(location?.state?.page || 1)
    const [isLoading, setIsLoading] = useState(true)
    const [disableNext, setDisableNext] = useState(false)
    const [disablePrev, setDisablePrev] = useState(false)
    const wallFileReadUrl = useSelector(state => state?.configurations?.wallFileReadUrl)
    const navigate = useNavigate()

    const fetchItems = async ({ state, entity, page, selectedUser }) => {
        try {
            let endPoint = `${process.env.REACT_APP_URL}wall/v1/admin/items?type=${entity}&state=${state.toLowerCase()}&page=${page}`
            if (selectedUser) {
                endPoint = endPoint + `&linkedUser=${selectedUser}`
            }
            if (postId) {
                endPoint = endPoint + `&postId=${postId}`
            }

            const allReports = await GET(endPoint, "Fetch Wall Items")
            if (allReports) {
                setItemsData(allReports?.data)
                setIsLoading(false)
                if (allReports?.data?.length < 10) {
                    setDisableNext(true)
                } else if (allReports?.data?.length === 10) {
                    setDisableNext(false)
                }
            }
        } catch (error) {
            if (error?.response) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchItems({ state, entity, page, selectedUser })
        if (page === 1) {
            setDisablePrev(true)
        } else {
            setDisablePrev(false)
        }
    }, [state, selectedUser, entity, page])

    const handleItemUpdate = (updatedObj) => {
        // const updatedItems = itemsData?.map((item) => {
        //     if (item?._id === updatedObj?._id) {
        //         return updatedObj
        //     } else {
        //         return item
        //     }
        // })
        // setItemsData(updatedItems)
        fetchItems({ state, entity, page, selectedUser })
    }

    const handleNextPrevBtn = (value) => {
        if (value === "next") {
            setPage(page + 1)
            navigate("/admin/wall/content-list", { state: { page: page + 1 } })
        } else if (value === "prev") {
            if (page >= 2) {
                setPage(page - 1)
                navigate("/admin/wall/content-list", { state: { page: page - 1 } })
            }
        }
    }

    const postColumns = [
        {
            field: "user",
            headerName: "Posted By",
            width: 280,
            renderCell: (params) => {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        {
                            params.row.user.profilePictureUrl !== "" ?
                                <Avatar alt={`${params.row.user.fullName}'s img`} src={`${wallFileReadUrl}${params.row.user.profilePictureUrl}`} /> :
                                <Avatar>
                                    <ImageRenderer src={profilePicture.src} alt={profilePicture.alt} />
                                </Avatar>
                        }
                        <Typography> {params.row.user.fullName}</Typography>
                    </Box >
                )
            }
        },
        {
            field: "description",
            headerName: "Description",
            width: 400
        },
        {
            field: "createdAt",
            headerName: "Date Posted",
            width: 200,
            renderCell: (params) => moment(params?.row?.createdAt).format('lll')
        },
        {
            field: "postState",
            headerName: "State",
            width: 200,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        {params?.row?.isEdited && <Chip sx={{ border: "solid 1px gray" }} label="Edited" size="small" />}
                        {params?.row?.isDisabled && <Chip sx={{ border: "solid 1px gray" }} label="Disabled" size="small" />}
                        {params?.row?.isDeleted && <Chip sx={{ border: "solid 1px gray" }} label="Deleted" size="small" />}
                    </Stack>
                )
            }
        },
        {
            field: "flagCount",
            headerName: "Flag Count",
            width: 100
        },
        {
            field: "action",
            headerName: "Actions",
            width: 250,
            renderCell: (params) => {
                return (
                    <Stack direction="row" gap={2}>
                        <ActionButton
                            id={params?.row?._id} type={"post"}
                            isDisabled={params?.row?.isDisabled}
                            handleItemUpdate={handleItemUpdate}
                            name={"disable"}
                            isDeleted={params?.row?.isDeleted} />
                        <ActionButton
                            id={params?.row?._id}
                            type={"post"}
                            handleItemUpdate={handleItemUpdate}
                            name={"delete"}
                            isDeleted={params?.row?.isDeleted} />
                    </Stack>
                )
            }
        }
    ]

    const handleOnCellClick = (params) => {
        if (params?.field !== "action") {
            // setSelectedRow(params)
            navigate(`view/${params?.id}`, { state: { id: params?.id } })
        }
    }


    return (
        <Box sx={{ height: '70vh', paddingLeft: '5px', width: "80.6vw" }}>
            {!isLoading
                ?
                <Stack>
                    <WallDatatable
                        checkBox={false}
                        rows={itemsData}
                        // columns={entity === "post" ? postColumns : entity === "comment" && commentColumns}
                        columns={postColumns}
                        pageSize={15}
                        rowsPerPage={15}
                        autoHeight={false}
                        getRowHeightValue={60}
                        isLoading={isLoading}
                        hideFooter={true}
                        tableHeight={"65vh"}
                        hideheaderToolBar={true}
                        onAssetClick={handleOnCellClick}
                    />
                    {/* {(Object.keys(selectedRow).length !== 0 && entity === "post") &&
                        <PostViewComponent postId={selectedRow?.id} type={"post"} clearViewId={clearViewId} />} */}
                    {/* {(Object.keys(selectedRow).length !== 0 && entity === "comment") &&
                        <PostViewComponent postId={selectedRow?.row?.relatedTo} comment={selectedRow?.row} type={"comment"} clearViewId={clearViewId} />} */}
                    <TablePagination
                        handleNextPrevBtn={handleNextPrevBtn}
                        disableNext={disableNext}
                        disablePrev={disablePrev}
                    />
                </Stack>
                :
                <CircularProgressLoader />
            }
        </Box >
    )
}

export default ContentReviewTable