import React, { useCallback, useEffect, useState } from 'react';
import CustomTypography from '../../../components/texts/CustomTypography';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import CustomFormField from '../../../components/formInputs/CustomFormField';
import { ThemeColors } from '../../../hooks/ThemeColors';
import { Box, Button, FormHelperText, Grid, IconButton, styled } from '@mui/material';
import CustomButton from '../../../components/button/CustomButton';
import styles from '../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import { FILEPOST, GET, POST } from '../../../helpers/http';
import LimitTags from '../../../components/formInputs/CustomAutoComplete';
import TextInput from '../../../components/formInputs/TextInput';
import DropDown from '../../../features/settings/vendorUser/components/dropDown/DropDown';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { CloudUpload, HighlightOffOutlined } from '@mui/icons-material';
import AddAssetFormStyles from '../../../../facilityManager/styles/assetManagement/addform/AddAssetFormStyles';
import { useSelector } from 'react-redux';
import CategoryModal from '../../../features/settings/category/components/modals/CategoryModal';
import DepartmentSettingsStyles from '../../../styles/DepartmentSettingStyles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Contract = () => {
    const [title, setTitle] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [buildings, setBuildings] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [isoStringStart, setIsoStringStart] = useState('');
    const [isoStringEnd, setIsoStringEnd] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [documents, setDocuments] = useState([])
    const [categoryModalOpen, setCategoryModalOpen] = useState(false)

    const navigate = useNavigate()

    const { commonSX } = ThemeColors();

    const schema = Yup.object().shape({
        title: Yup.string().trim().required('Title is required'),
        startDate: Yup.string().required('Start date is required'),
        endDate: Yup.string()
            .required('End date is required')
            .when('startDate', (startDate, schema) => {
                return schema.test({
                    test: (endDate) => {
                        return new Date(endDate) > new Date(startDate);
                    },
                    message: 'End date must be later than start date',
                });
            }),
        serviceCategories: Yup.array().min(1, 'At least one service category is required'),
        buildings: Yup.array().min(1, 'At least one building is required'),
        vendor: Yup.string().required('Vendor is required')
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title, startDate: isoStringStart, endDate: isoStringEnd,
            serviceCategories: selectedCategories?.map(el => el._id),
            buildings: selectedBuildings?.map(el => el._id), vendor: selectedVendor,
            documents
        };
        try {
            await schema.validate(formData, { abortEarly: false });
            setFormErrors({});
            const response = await POST(`${process.env.REACT_APP_BASE_URL}um/v1/admin/contract`, formData, "ADD CONTRACT")
            if (response?.status === 201) {
                navigate('/admin/settings/contract')
            }
        } catch (validationErrors) {
            const errors = {};
            validationErrors.inner.forEach(error => {
                errors[error.path] = error.message;
            });
            setFormErrors(errors);
        }
    };

    const handleChange = (e, setState) => {
        const { value } = e.target;
        setState(value);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const handleDateAndTime = (e, setState, setISO) => {
        const localDateTime = e.target.value;
        setState(localDateTime);
        const isoDateTime = new Date(localDateTime).toISOString();
        setISO(isoDateTime);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const getCategories = async () => {
        try {
            const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, "GET CATEGORIES");
            setCategories(result?.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const getAllBuildings = async () => {
        try {
            // const result = await GET('https://dev-gateway.letsnhance.com/twin/v1/buildings', "GET BUILDING IDS");
            const result = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/buildings`, "GET BUILDING IDS")
            setBuildings(result?.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const getAllVendors = async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor/list`, "GET VENDORS");
            setVendor(response?.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const handleDropDownChange = (e, setState) => {
        setState(e.target.value);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    useEffect(() => {
        getCategories();
        getAllBuildings();
        getAllVendors();
    }, []);

    const formFields = [
        { name: 'title', label: 'Title', value: title, setState: setTitle, type: 'textField', required: true, xs: 12 },
        {
            name: 'startDate', label: 'Start Date', value: startDate, setState: setStartDate, setISO: setIsoStringStart,
            type: 'DateTime', required: true, xs: 6
        },
        {
            name: 'endDate', label: 'End Date', value: endDate, setState: setEndDate, setISO: setIsoStringEnd,
            type: 'DateTime', required: true, xs: 6
        },
        {
            name: 'serviceCategories', label: 'Service Categories', value: categories, selectedOptions: selectedCategories,
            setSelectedOptions: setSelectedCategories, setState: setCategories, type: 'multiselect', required: true, xs: 12,
            errors: formErrors.serviceCategories
        },
        {
            name: 'buildings', label: 'Buildings', value: buildings, setState: setBuildings, type: 'multiselect', required: true, xs: 12,
            selectedOptions: selectedBuildings, setSelectedOptions: setSelectedBuildings, errors: formErrors.buildings
        },
        { name: 'vendor', label: 'Vendor', value: vendor, setState: setSelectedVendor, type: 'selectField', required: true, xs: 12 },
        { type: 'title', label: 'Documents *', xs: 12 },
        {
            type: 'uploadInput', label: 'Documents', allowMultiple: true, maxFiles: 3,
            value: documents, xs: 12, required: true, setState: setDocuments, name: 'documents'
        },
    ];

    const fileReadUrl = useSelector(state => state?.configurations?.fileReadUrl)

    const mediaUpload = async (file) => {
        try {
            const result = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, { file }, "MEDIA UPLOAD")
            return result.data
        } catch (error) {
            setFormErrors({ documents: `Invalid File: ${file.name}` })
        }
    }

    const handleMultipleFileUpload = useCallback(async (fileItems, documents) => {
        let response = []
        if (fileItems.length > 0) {
            try {
                response = await Promise.allSettled([...fileItems]?.map((fileItem) => mediaUpload(fileItem)))
                response = response.map((result) => result?.value)
                setDocuments([...documents, ...response])
            } catch (error) {
                setFormErrors({ documents: error?.message })
            }
        }
    }, []);

    const handleFileUploads = useCallback(async (fileItems, name, setState) => {
        let response = ''
        if (fileItems.length > 0) {
            response = await mediaUpload(fileItems[0])
            if (response) {
                setState((prevState) => ({ ...prevState, [name.split('.')[1]]: response }))
            }
        }
    }, []);

    const handleRemoveFile = (id) => {
        const result = documents.filter((el) => el.uploadId !== id)
        setDocuments(result)
    }

    const handleRemoveSingleFile = ({ name, setState }) => {
        setState((prevState) => ({ ...prevState, [name.split('.')[1]]: '' }));
    }

    const imageRenderer = ({ value, name, setState }) => {
        if (!value) return;
        if (Array.isArray(value)) {
            return <div className='flex'>
                {
                    value?.map((el, index) => {
                        return !el ? null : <Box sx={{ ...AddAssetFormStyles.commonImageBox, mt: '10px' }} key={index}>
                            <object data={`${fileReadUrl}${el?.key}`} height={100} width={'100%'} aria-label='images' />
                            <IconButton onClick={() => { handleRemoveFile(el?.uploadId) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
                        </Box>
                    })
                }
            </div>
        }
        else if (!Array.isArray(value) && typeof (value) === 'string') {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>
        }
        else if (!Array.isArray(value) && typeof (value) === 'object' && Object.keys(value).includes('key')) {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value?.key}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>

        }
    }

    const handleAddCategoryClick = () => {
        setCategoryModalOpen(true)
    }

    const addNewCategory = (newCategoryObj) => {
        setCategories((prevState) => {
            return [...prevState, newCategoryObj]
        })
    }

    return (
        <div className='width100 pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Add Contract</CustomTypography>
                <CustomButton
                    variant='contained'
                    sx={{ mr: '20px', width: '12rem', height: '2.4rem' }}
                    className={styles.button_bgc}
                    onClick={() => navigate('/admin/settings/contract')}
                >
                    cancel
                </CustomButton>
            </div>
            <CustomComponentContainer className='mrgnsettingpage pad10'>
                <form onSubmit={handleSubmit}>
                    <Grid container className='pad10 width50'>
                        {
                            formFields?.map(({ xs, name, label, value, setState, type, required, selectedOptions,
                                setSelectedOptions, setISO, allowMultiple, errors }, index) => {
                                return (
                                    <Grid item xs={xs} key={index} className={type !== 'title' && 'pad5'}>
                                        {type === 'title' && (
                                            <CustomTypography className='mrgnleft10 bold' sx={index > 0 && { mt: '10px' }}>
                                                {label}
                                            </CustomTypography>
                                        )}
                                        {type === 'textField' && (
                                            <CustomFormField
                                                variant='outlined'
                                                sx={commonSX}
                                                size='small'
                                                value={value}
                                                label={label}
                                                required={required}
                                                name={name}
                                                onChange={(e) => handleChange(e, setState)}
                                                error={!!formErrors[name]}
                                                helperText={formErrors[name]}
                                            />
                                        )}
                                        {type === 'multiselect' && (
                                            <>
                                                <LimitTags
                                                    required={required}
                                                    sx={commonSX}
                                                    label={label}
                                                    options={value}
                                                    selectedOptions={selectedOptions}
                                                    setSelectedOptions={setSelectedOptions}
                                                    error={errors}
                                                />
                                                {
                                                    name === 'serviceCategories' && <CustomButton textContent='Add Category' variant="outlined"
                                                        sx={{ ...DepartmentSettingsStyles.addButton, mt: '10px', width: '12rem', height: '2.4rem' }}
                                                        onClick={handleAddCategoryClick} />
                                                }
                                            </>
                                        )}
                                        {type === 'DateTime' && (
                                            <TextInput
                                                required={required}
                                                value={value}
                                                onChange={(e) => handleDateAndTime(e, setState, setISO)}
                                                type='datetime-local'
                                                size='small'
                                                label={label}
                                                focused
                                                inputProps={{
                                                    min: isoStringStart.slice(0, 16)
                                                }}
                                                sx={{
                                                    '& .MuiTextField-root': {
                                                        minWidth: '100%',
                                                    },
                                                }}
                                                name={name}
                                                error={!!formErrors[name]}
                                                helperText={formErrors[name]}
                                            />
                                        )}
                                        {type === 'selectField' && (
                                            <DropDown
                                                formErrors={formErrors[name]}
                                                name={name}
                                                required={required}
                                                label={label}
                                                options={value}
                                                setState={setState}
                                                handleDropDownChange={(e) => handleDropDownChange(e, setState)}
                                            />
                                        )}
                                        {
                                            type === 'uploadInput' &&
                                            <>
                                                <Button
                                                    component="label"
                                                    className={styles.button_bgc}
                                                    role={undefined}
                                                    size='small'
                                                    variant="contained"
                                                    tabIndex={-1}
                                                    startIcon={<CloudUpload className='color-white' />}
                                                    sx={{ width: '12rem', height: '2.4rem' }}
                                                >
                                                    {label}
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        accept="image/*,application/pdf"
                                                        multiple={allowMultiple}
                                                        name={name}
                                                        onChange={(e) => allowMultiple ? handleMultipleFileUpload(e.target.files, value) : handleFileUploads(e.target.files, name, setState)}
                                                    />
                                                </Button>
                                                {value && imageRenderer({ value, name, setState })}
                                                {formErrors[name] && <FormHelperText sx={{ color: 'red !important' }}>{formErrors[name]}</FormHelperText>}

                                            </>
                                        }
                                    </Grid>
                                );
                            })
                        }
                        <Grid item xs={12} className='pad5'>
                            <CustomButton
                                variant='contained'
                                sx={{ mt: '10px', width: '12rem', height: '2.4rem' }}
                                className={styles.button_bgc}
                                onClick={handleSubmit}
                            >
                                Submit
                            </CustomButton>
                        </Grid>
                    </Grid>
                </form>
                <CategoryModal
                    categoryModalOpen={categoryModalOpen}
                    setCategoryModalOpen={setCategoryModalOpen}
                    addNewCategory={addNewCategory}
                // selectedCategory={selectedCategory}
                // setSelectedCategory={setSelectedCategory}
                />
            </CustomComponentContainer>
        </div>
    );
};

export default Contract;
