import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Tooltip } from "@mui/material";
import { useState } from "react";
import { WatchLaterTwoTone, RotateLeftTwoTone, CalendarTodayTwoTone, EventAvailableTwoTone, EventBusyTwoTone, ErrorTwoTone, UpcomingTwoTone } from '@mui/icons-material';
import { ThemeColors } from "../../../../hooks/ThemeColors";

const steps = ['upcoming', 'pending', 'inprogress', 'completed'];

const TaskProgress = ({ status, capitalizeFirst }) => {
    const { textColor } = ThemeColors();
    const [activeStatus] = useState(status ? status === 'incomplete' ? 3 : status === 'overdue' ? 2 : steps?.indexOf(status) : 0);

    const ColorlibConnector = styled(StepConnector)({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
        },
    });

    const ColorlibStepIconRoot = styled('div')({
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        '&.active': {
            backgroundImage: 'linear-gradient(0deg, #ef5350 100%, #ef5350 100%, #ef5350 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
        '&.completed': {
            backgroundImage: 'linear-gradient(0deg, #ef5350 100%, #ef5350 100%, #ef5350 100%)',
        },
    });

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <Tooltip title='upcoming' placement='top'><UpcomingTwoTone sx={{ color: '#fff' }} /></Tooltip>,
            2: <Tooltip title='Pending' placement='top'><WatchLaterTwoTone sx={{ color: '#fff' }} /></Tooltip>,
            3: status === "overdue" ?
                <Tooltip title='Overdue' placement='top'><ErrorTwoTone sx={{ color: '#fff' }} /></Tooltip> :
                <Tooltip title='Inprogress' placement='top'><RotateLeftTwoTone sx={{ color: '#fff' }} /></Tooltip>,
            4: status === 'completed' ?
                <EventAvailableTwoTone sx={{ color: '#fff' }} />
                : status === 'incomplete'
                    ? <EventBusyTwoTone sx={{ color: '#fff' }} /> : <CalendarTodayTwoTone sx={{ color: '#fff' }} />,
        };

        return (
            <ColorlibStepIconRoot className={`${className} ${active ? 'active' : ''} ${completed ? 'completed' : ''}`} >
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot >
        );
    }

    return (
        <Stepper
            sx={{
                width: "30vw",
                height: "9rem",
                paddingTop: "2.5rem",
                paddingBottom: "1rem",
                border: "2px gainsboro solid",
                borderRadius: "1rem"
            }}
            alternativeLabel
            activeStep={activeStatus}
            connector={<ColorlibConnector />}>
            {steps?.map((label) => {
                const info = (status === 'incomplete' && label === 'completed') ? 'incomplete' : (status === 'overdue' && label === 'inprogress') ? 'overdue' : label;
                return (
                    <Step key={label}>
                        <StepLabel sx={{ "& .MuiStepLabel-label": { color: textColor() } }} StepIconComponent={ColorlibStepIcon}>
                            {status === info && capitalizeFirst(info)}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export default TaskProgress;