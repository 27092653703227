import React, { useState } from 'react'
import Datatable from '../../components/dataTable/Datatable'
import { Box, Chip, IconButton, Stack, Tooltip } from '@mui/material'
import Loader from '../../components/loading/Loader'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import { EditOutlined, VisibilityOutlined, DeleteOutlined } from '@mui/icons-material'
import DeletePPMModal from './DeletePPMModal'
import EditPPMModal from './EditPPMModal'
import PPMUpdate from './PPMUpdate'
import Modal from '../../components/modals/NewModal'
import useApiCalls from './components/hooks/useApiCalls'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { priorityColors, statusColors } from './colorConstants'
import CustomTypography from '../../components/texts/CustomTypography'

const PPMDatatable = ({ PPMList, triggerList, type }) => {
    // const PPMList = useContext(PPMListContext)
    const navigate = useNavigate()
    const [editOptionModalOpen, setEditOptionModalOpen] = useState(false)
    const { editPPM } = useApiCalls()
    const [editOptions, setEditoptions] = useState({})
    const [selectedUpdatePPMOption, setSelectedUpdatePPMOption] = useState("")
    const [updatingPPM, setupdatingPPM] = useState("")
    const [EditPPMModalFormOpen, setEditPPMModalFormOpen] = useState(false)

    //delete
    const [deletingPPMId, setDeletingPPMId] = useState(null)
    const [openDeletePPMModal, setOpenDeletePPMModal] = useState(false)

    const capitalizeFirst = (word) => {
        if (!word) { // Check if the word is empty
            return "";
        }
        return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);
    }

    const handleDeletePPMClick = (id) => {
        setDeletingPPMId(id)
        setOpenDeletePPMModal(true)
    }
    const updateOptionPPM = async () => {
        try {
            setEditOptionModalOpen(false)
            setEditPPMModalFormOpen(true)
        } catch (error) {
            console.log({ error })
        }
    }

    const handleEditPPM = async ({ ppmId, isRecursive }) => {
        editPPM({ isRecursive, setEditoptions, setEditOptionModalOpen, setupdatingPPM, setEditPPMModalFormOpen, ppmId })
    }

    const returnStatus = (status) => {
        const state = status?.toLowerCase()

        return (
            <Chip size="small"
                sx={{
                    width: "100%",
                    border: `solid 1px ${statusColors?.[state]?.br}`,
                    backgroundColor: 'white',
                    "& .MuiChip-label": {
                        color: statusColors?.[state]?.br,
                        fontSize: "13px"
                    }
                }}
                label={capitalizeFirst(status)} />
        )
    }

    const returnPriority = (priority) => {
        const state = priority?.toLowerCase()

        return (
            <Chip size="small"
                sx={{
                    width: "100%",
                    border: `solid 1px ${priorityColors[state].br}`,
                    backgroundColor: priorityColors[state].bg,
                    "& .MuiChip-label": {
                        color: priorityColors[state].br,
                        fontSize: "13px"
                    }
                }}
                label={capitalizeFirst(state)} />
        )
    }

    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            headerAlign: 'left',
            align: 'left',
            width: 320,
            editable: false,
            renderCell: (params) => {
                return <Box sx={{ pl: 1 }}>{capitalizeFirst(params.row.title) || ''}</Box>
            }
        },
        {
            field: 'priority',
            headerName: 'Priority',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            renderCell: (params) => {
                return returnPriority(params.row?.priority) || ''
            }
        },
        {
            field: 'startTime',
            headerName: 'Start Time',
            headerAlign: 'left',
            align: 'left',
            width: 120,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.startTime).format("h:mm A")}`
            }
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            headerAlign: 'left',
            align: 'left',
            width: 120,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.endTime).format("h:mm A")
                    }`
            }
        },
        {
            field: 'duration',
            headerName: 'Duration',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return params.row.duration
            }
        },
        {
            field: 'category',
            headerName: 'Category',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.category?.name) || '-'} `
            }
        },
        {
            field: 'assignmentStatus',
            headerName: 'Assignment Status',
            headerAlign: 'left',
            align: 'left',
            width: 180,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row?.currentService?.assignedUser?.name) || '-'} `
            }
        },
        {
            field: 'isRecursive',
            headerName: 'Recursive',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            renderCell: (cellValues) => {
                return (<>
                    {cellValues.row.isRecursive && <Tooltip title='This PPM is Repetitive' placement='top'>
                        <EventRepeatTwoToneIcon fontSize='small' /></Tooltip>}
                </>)
            }
        },
        {
            field: 'frequency',
            headerName: 'Frequency',
            headerAlign: 'left',
            align: 'left',
            width: 80,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.recursiveConfiguration?.frequency) || '-'} `
            }
        },
        {
            field: 'interval',
            headerName: 'Interval',
            headerAlign: 'left',
            align: 'left',
            width: 80,
            editable: false,
            valueGetter: (params) => {
                return `${params.row.recursiveConfiguration?.interval || '-'} `
            }
        },
        {
            field: 'taskStatus',
            headerName: 'Task Status',
            headerAlign: 'left',
            align: 'left',
            width: 110,
            editable: false,
            renderCell: (params) => {
                return returnStatus(params.row?.taskStatus) || ''
            }
        },
        // {
        //     field: 'overdue',
        //     headerName: 'Overdue',
        //     headerAlign: 'left',
        //     align: 'left',
        //     width: 180,
        //     editable: false,
        //     valueGetter: (params) => {
        //         return `${moment(params.row.overdueFields?.overdueFrom).format('lll') || '-'} `
        //     }
        // },
        {
            field: 'Actions',
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1.5}>
                        {/* <Tooltip title='TASKS' placement='top'>
                            <IconButton
                                onClick={() => navigate(`/ ppm / task / ${ cellValues.row._id } `)}>
                                <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title='VIEW' placement='top'>
                            <IconButton
                                onClick={() => navigate(`/ppm/task/${cellValues.row._id} `)}>
                                <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => handleDeletePPMClick(cellValues?.row?._id)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                        {(!moment(cellValues?.row?.date).isBefore(moment().startOf('day')) &&
                            cellValues?.row?.taskStatus?.toLowerCase() !== 'completed')
                            && <Tooltip title='EDIT' placement='top'>
                                <IconButton
                                    onClick={() => handleEditPPM({ ppmId: cellValues.row._id, isRecursive: cellValues.row.isRecursive })}>
                                    <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                </IconButton>
                            </Tooltip>}
                    </Stack>
                )
            }
        },
    ]


    const cumulativeDataTable = [
        {
            field: 'title',
            headerName: 'Title',
            headerAlign: 'left',
            align: 'left',
            width: 300,
            editable: false,
            renderCell: (params) => {
                return <Box sx={{ pl: 1 }}>{capitalizeFirst(params.row.title) || ''}</Box>
            }
        },
        {
            field: 'count',
            headerName: 'Task Count',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
        },
        {
            field: 'startTime',
            headerName: 'Start Time',
            headerAlign: 'left',
            align: 'left',
            width: 200,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.startTime).format('lll')
                    } `
            }
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            headerAlign: 'left',
            align: 'left',
            width: 200,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.endTime).format('lll')
                    } `
            }
        },
        {
            field: 'category',
            headerName: 'Category',
            headerAlign: 'left',
            align: 'left',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.category?.name) || '-'} `
            }
        },
        {
            field: 'priority',
            headerName: 'Priority',
            headerAlign: 'left',
            align: 'left',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.priority) || '-'} `
            }
        },
        {
            field: 'frequency',
            headerName: 'Frequency',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.frequency) || '-'} `
            }
        },
        {
            field: 'interval',
            headerName: 'Interval',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${params.row.interval || '-'} `
            }
        },
        {
            field: 'isRecursive',
            headerName: 'Recursive Status',
            headerAlign: 'left',
            align: 'left',
            width: 130,
            editable: false,
            renderCell: (cellValues) => {
                return (<>
                    {cellValues.row.isRecursive && <Tooltip title='This PPM is Repetitive' placement='top'>
                        <EventRepeatTwoToneIcon fontSize='small' /></Tooltip>}
                </>)
            }
        },
        {
            field: 'Actions',
            width: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    !moment(cellValues?.row?.date).isBefore(moment().startOf('day')) && <Stack direction={'row'} spacing={1.5}>
                        <Tooltip title='TASKS' placement='top'>
                            <IconButton
                                onClick={() => navigate(`/ppm/view/${cellValues.row._id} `)}
                            >
                                <FormatListBulletedOutlinedIcon fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        },
    ]

    return (
        <Box maxWidth={'100%'}>
            {PPMList?.isLoaded ? <Datatable disableGridToolBar rows={PPMList?.data || []} columns={type === "cumulative" ? cumulativeDataTable : tableColumns} /> : <Loader height={700} />}
            <Modal
                modalOpen={editOptionModalOpen}
                closeModal={() => setEditOptionModalOpen(false)}
                title={'Update PPM'}
            >
                <PPMUpdate
                    selectedUpdatePPMOption={selectedUpdatePPMOption}
                    setSelectedUpdatePPMOption={setSelectedUpdatePPMOption}
                    updatePPMOptions={editOptions}
                    updatePPM={updateOptionPPM}
                />
            </Modal>
            <EditPPMModal
                triggerList={triggerList}
                openUpdatePPMModal={EditPPMModalFormOpen}
                handleCloseUpdatePPMModal={() => setEditPPMModalFormOpen(false)}
                editingPPMId={updatingPPM}
                selectedUpdatePPMOption={selectedUpdatePPMOption}
            />
            <DeletePPMModal
                triggerList={triggerList}
                openDeletePPMModal={openDeletePPMModal}
                handleCloseDeletePPMModal={() => setOpenDeletePPMModal(false)}
                deletingPPMId={deletingPPMId}
            />
        </Box>
    )
}

export default PPMDatatable