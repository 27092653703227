import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../components/formInputs/TextInput'
import Modal from '../../../../../components/modal/Modal'
import CommonStyles from '../../../../../styles/CommonStyles'
import { GET, POST, PUT } from '../../../../../helpers/http'
import { successToast } from '../../../../../helpers/apiToast'
import validSearchTerm from '../../../../../helpers/validSearchTerm'
import { useSelector } from 'react-redux'

const CategoryModal = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const {
        categoryModalOpen,
        setCategoryModalOpen,
        addNewCategory,
        editCategory,
        selectedCategory,
        setSelectedCategory
    } = props

    const [timer, setTimer] = useState(0)
    const [formErrors, setFormErrors] = useState({})
    const [categories, setCategories] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedParentEntity, setSelectedParentEntity] = useState({})
    const [category, setCategory] = useState({
        name: "",
        code: "",
        description: ""
    })

    useEffect(() => {
        if (selectedCategory?._id) {
            setCategory({
                name: selectedCategory?.name,
                code: selectedCategory?.code,
                description: selectedCategory?.description,
            })
            if (selectedCategory?.parent) setSelectedParentEntity(selectedCategory?.parent)
        }
    }, [selectedCategory?._id])

    const errors = {}

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        if (searchTerm?.length >= 2) {
            const endpoint = selectedCategory?._id ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list?searchTerm=${validSearchTerm(searchTerm)?.trim()}&categoryId=${selectedCategory?._id}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list?searchTerm=${searchTerm?.trim()}`
            debounce(() => {
                GET(endpoint, 'Fetch Categories')
                    .then((response) => {
                        if (response?.status === 200) {
                            setCategories(response?.data)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }, 500)
        }
    }, [searchTerm])

    const handleCategoryModalClose = () => {
        setCategoryModalOpen(false)
        setCategory({
            name: "",
            code: "",
            description: ""
        })
        setSelectedParentEntity({})
        setFormErrors({})
        if (selectedCategory?._id) setSelectedCategory({})
        if (categories?.length > 0) setCategories([])
    }

    const handleChange = (e) => {
        const property = e.target.name
        const value = e.target.value
        setCategory((prevState) => {
            return { ...prevState, [property]: value }
        })
    }

    const handleCategorySearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleParentEntityChange = (e, newValue) => {
        setSelectedParentEntity(newValue)
    }

    const runValidations = () => {
        if (category?.name?.trim()?.length === 0) {
            errors.name = 'Category Name cannot be empty'
        }
        if (category?.code?.trim()?.length === 0) {
            errors.code = 'Category Code cannot be empty'
        }
    }

    const handleCreateCategory = () => {

        runValidations()

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
        } else {
            setFormErrors({})

            const catObj = {}

            catObj.name = category?.name?.trim()
            catObj.code = category?.code?.trim()
            catObj.description = category?.description?.trim()
            if (selectedParentEntity?._id) catObj.parent = selectedParentEntity?._id
            else catObj.parent = null

            if (selectedCategory?._id) {
                //editing the selected category
                PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/${selectedCategory?._id}`, catObj, 'Edit Category')
                    .then((response) => {
                        if (response?.status === 200) {
                            handleCategoryModalClose()
                            editCategory(response?.data)
                            successToast("Category updated successfully")
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                //adding a category
                POST(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category`, catObj, 'Create new Category')
                    .then((response) => {
                        if (response?.status === 201) {
                            handleCategoryModalClose()
                            addNewCategory(response?.data)
                            successToast("Category added successfully")
                        }
                    })
                    .catch(error => console.log(error))
            }
        }
    }

    return (
        <Modal
            modalOpen={categoryModalOpen}
            closeModal={handleCategoryModalClose}
            title={selectedCategory?._id ? 'Edit Category' : 'Create a Category'}
        >
            <Stack spacing={2}>
                <TextInput
                    label={'Category Name'}
                    fullWidth
                    value={category.name}
                    onChange={handleChange}
                    name={'name'}
                    error={formErrors?.name}
                    helperText={formErrors?.name}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Category Code'}
                    fullWidth
                    value={category.code}
                    onChange={handleChange}
                    name={'code'}
                    error={formErrors?.code}
                    helperText={formErrors?.code}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Category Description (Optional)'}
                    fullWidth
                    multiline
                    rows={4}
                    value={category.description}
                    onChange={handleChange}
                    name={'description'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <Autocomplete
                    disablePortal
                    options={categories}
                    getOptionLabel={(option) => option.name || ""}
                    value={selectedParentEntity}
                    onChange={handleParentEntityChange}
                    noOptionsText={searchTerm?.length > 0 ? "No results" : "Search a category by its name or code..."}
                    renderInput={(params) => {
                        return <TextField
                            {...params}
                            onChange={handleCategorySearch}
                            label="Select a Parent Category (Optional)"
                            inputProps={{ ...params.inputProps, style: { color, bgcolor } }}
                            InputLabelProps={{ ...params.InputLabelProps, sx: { color } }}
                        />
                    }}
                />
                <Button
                    sx={{ ...CommonStyles.actionButton }}
                    onClick={handleCreateCategory}
                >{selectedCategory?._id ? "Submit" : "Create Category"}</Button>
            </Stack>
        </Modal>
    )
}

export default CategoryModal