

const managePostStyle = {
    OuterBox: {
        p: 1,
        marginRight: "1rem",
        width: "100%",
        // height: "89.9vh",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    topBar: {
        width: "inherit",
        boxShadow: "0",
        marginTop: "0.5rem",
    },
    typoManagePost: {
        fontWeight: "700",
        margin: "1rem",
        fontSize: "34px"
    },
    typoReviewPost: {
        fontWeight: "500",
        fontSize: "22px"
    }
}

export default managePostStyle