import moment from "moment";

export function formatSecondsToMinutes(seconds) {
    const duration = moment.duration(seconds, 'seconds');
    const minutes = duration.minutes();
    const remainingSeconds = duration.seconds();

    if (minutes > 0 && remainingSeconds > 0) {
        return `${minutes} min and ${remainingSeconds} secs`;
    } else if (minutes > 0) {
        return `${minutes} minutes`;
    } else {
        return `${remainingSeconds} seconds`;
    }
}