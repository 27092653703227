import React, { useEffect, useState } from 'react'
import CustomTypography from '../../../../components/texts/CustomTypography'
import CustomButton from '../../../../components/button/CustomButton'
import { AddCircleOutlineOutlined, EditOutlined } from '@mui/icons-material'
import Datatable from '../../components/dataTable/Datatable'
import Loader from '../../../../components/loading/Loader'
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import { useNavigate } from 'react-router-dom'
import { GET } from '../../../../helpers/http'
import { errorToast } from '../../../../helpers/apiToast'
import { Avatar, IconButton } from '@mui/material'

const VendorUserListing = () => {
    const navigate = useNavigate()

    const [vendorList, setVendorList] = useState([])

    const handleVendorUserAdd = () => {
        navigate('/admin/settings/vendor-user/add')
    }

    const getVendorUserData = async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor-user/list`, 'Get All Vendor User')
            if (response?.status === 200) {
                const vendorsWithId = response?.data?.map(vendor => ({ ...vendor, id: vendor._id }));
                setVendorList(vendorsWithId);
            }
        } catch (error) {
            errorToast(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        getVendorUserData()
    }, [])

    const handleVendorUserEdit = (id) => {
        navigate(`/admin/settings/vendor-user/edit/${id}`)
    }

    // const handleVendorUserDelete = (id) => {
    //     console.log({ id });
    // }

    const src = ''

    const columns = [
        {
            field: 'profilePictureUrl', headerName: 'Profile Picture', flex: 1,
            renderCell: (params) => <Avatar alt={params?.row?.fullName?.toUpperCase()} src={src ? src : params?.row?.fullName[0]} />
        },
        // { field: '_id', headerName: 'ID', flex: 2 },
        { field: 'fullName', headerName: 'Full Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1.5 },
        { field: 'mobile', headerName: 'Mobile', flex: 1 },
        { field: 'address', headerName: 'Address', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        { field: 'empCode', headerName: 'Employee Code', flex: 1 },
        {
            field: 'Actions', headerName: 'Actions', flex: 1,
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton aria-label="edit" onClick={() => { handleVendorUserEdit(cellValues?.row?._id) }}>
                            <EditOutlined sx={{ color: '#1976d2 !important' }} />
                        </IconButton>
                        {/* <IconButton aria-label="delete" onClick={() => { handleVendorUserDelete(cellValues?.row?._id) }}>
                            <DeleteOutlineOutlined sx={{ color: '#ef5350 !important' }} />
                        </IconButton> */}
                    </>
                );
            }
        }
    ]

    return (
        <div className='width100 pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Vendor Users</CustomTypography>
                <CustomButton
                    variant='contained'
                    className={styles.button_bgc} startIcon={<AddCircleOutlineOutlined className='color-white' />}
                    sx={{ mr: '20px', height: '2.4rem' }}
                    onClick={handleVendorUserAdd}
                >
                    Add Vendor User
                </CustomButton>
            </div>
            <div className='mrgnsettingpage'>
                {
                    true ? <Datatable columns={columns} rows={vendorList} /> : <Loader height={'89vh'} width={'70%'} />
                }
            </div>
        </div>
    )
}

export default VendorUserListing