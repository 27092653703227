import { errorToast, successToast } from "../../helpers/apiToast"
import { FILEPOST, GET, POST, PUT } from "../../helpers/http"

export const hasFields = (fields) => fields.some(field => field);

export const fetchBuildings = async ({ setBuildings }) => {
    try {
        // const buildingResponse = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list`, 'Get All Buildings')
        const buildingResponse = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list`, 'Get All Buildings')
        setBuildings(buildingResponse?.data)
    } catch (error) {
        console.log({ error })
    }
}
export const fetchDepartments = async ({ setDepartments }) => {
    try {
        // const departmentResponse = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Get All Departments')
        const departmentResponse = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Get All Departments')
        setDepartments(departmentResponse?.data)
    } catch (error) {
        console.log({ error })
    }
}

export const fetchUserList = async ({ setUserList }) => {
    try {
        const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/list`, 'Get User List')
        if (result?.status === 200) {
            setUserList(result?.data);
            return result?.data;
        } else {
            errorToast('Something went wrong!!');
        }
    } catch (error) {
        console.log({ error })
    }
}

export const handleEditFormSubmit = async ({ id, payload, setUserList }) => {
    try {
        const result = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/user/${id}`, { ...payload }, 'Update User');
        if (result?.status === 200) {
            successToast('User updated successfully!!');
            fetchUserList({ setUserList });
            return result;
        } else {
            errorToast('Something went wrong!!');
        }
    } catch (error) {
        console.log({ error })
    }
}

export const handleSync = async ({ setLoading, setUserList }) => {
    try {
        const result = await POST(`${process.env.REACT_APP_BASE_URL}um/v1/users/sync`, {}, 'Sync User');
        if (result?.status === 200) {
            setLoading(false);
            fetchUserList({ setUserList });
            successToast(`${result?.data?.data}!!`);
        }
    } catch (error) {
        setLoading(false);
    }
}

export const handleCreateUser = async ({ payload, setUserList }) => {
    try {
        const response = await POST(`${process.env.REACT_APP_BASE_URL}um/v1/user`, payload, 'Create User');
        if (response?.status === 201) {
            successToast(`${response?.data?.data}!!`);
            fetchUserList({ setUserList });
            return response;
        }
    } catch (error) {
        console.log({ error });
    }
}

export const updateUser = async ({ id, payload }) => {
    try {
        const result = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/user/${id}`, payload, 'Update User');
        if (result?.status === 200) {
            successToast(`${result?.data?.data}!!`);
            return result
        } else {
            errorToast('Something went wrong!!');
        }
    } catch (error) {
        console.log({ error })
    }
}

export const uploadImage = async ({ payload }) => {
    try {
        // const result = await POST(`${process.env.REACT_APP_BASE_APP_URL}um/v1/user/image`, payload, 'upload Image');
        const result = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/user/image`, { file: payload }, 'upload Image');
        if (result?.status === 201) {
            return result?.data
        }
    } catch (error) {
        console.log({ error })
    }
}

export const fetchRolesList = async ({ setRolesList }) => {
    try {
        const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/options/roles`, 'Get Roles');
        if (result?.status === 200) setRolesList(result?.data?.data);
    } catch (error) {
        console.log({ error })
    }

}