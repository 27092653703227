import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../../facilityManager/helpers/http'

export const asyncFetchBookingsZoneWise = createAsyncThunk('deskBookingHistory/asyncFetchBookingsZoneWise', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}db/v1/bookings/zone?building-id=${bid}`, 'Desk booking history')
        if (response?.status === 200) {
            console.log({ response })
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const initialState = {
    zoneWiseBookingStats: {
        isLoaded: false,
        data: [],
        // margins: [10, 0, 25, 30],
        // min: new Date().getTime() - (16 * 24 * 60 * 60 * 1000),
        // max: new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
    }
}

const deskBookingZoneWiseSlice = createSlice({
    name: 'deskBookingZoneWiseSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(asyncFetchBookingsZoneWise.fulfilled, (state, action) => {
            state.zoneWiseBookingStats.isLoaded = true
            state.zoneWiseBookingStats.data = action.payload
            // state.bookingHistory.min = action.payload[0][0]
            // state.bookingHistory.max = action.payload[action.payload.length - 1][0]
        })
    }

})

export default deskBookingZoneWiseSlice.reducer