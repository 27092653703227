import { OpenInNew } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom'

import './kioskBox.css'
import { PUT } from '../../../helpers/http'
import CommonStyles from '../../../styles/CommonStyles'
import KioskBoxStyles from '../../../styles/KioskBoxStyles'

const KioskBox = (props) => {
    const { _id, kioskCode, location, building, floor, area, isDisabled, isConnected, currentSession, isPrintEnabled, updateKioksArr, friendlyName } = props

    const navigate = useNavigate()

    const themeMode = useSelector(state => state.theme.darkTheme)

    const color = themeMode ? '#fff' : '#000'

    const updateKioskConfig = async (updateBody) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}vm/v1/saas/kiosk/${_id}`, updateBody, 'Update Kiosk Config VM')
            const result = response?.data
            if (response?.status === 200) {
                updateKioksArr(result)
            } else {
                console.log(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const swalPopup = (updateBody, warningText) => {
        swal({
            title: "Are you sure?",
            text: warningText,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    updateKioskConfig(updateBody)
                }
            });
    }

    const handleEnableChange = (e) => {
        const disable = !e.target?.checked
        const updateBody = { isDisabled: disable, location: location?._id, building: building?._id, floor: floor?._id, area: area?._id }
        if (disable) {
            swalPopup(updateBody, "Make sure this code is not connected to any device! Connected device will be disconnected")
        } else {
            updateKioskConfig(updateBody)
        }
    }

    const handlePrinterEnable = (e) => {
        const printEnable = e.target?.checked
        const updateBody = { isPrintEnabled: printEnable, location: location?._id, building: building?._id, floor: floor?._id, area: area?._id }
        if (!printEnable) {
            swalPopup(updateBody, "Visitor badge printing will be disabled.")
        } else {
            updateKioskConfig(updateBody)
        }
    }

    const handleNavigateToKioskDetails = () => {
        navigate(`/admin/visitor-kiosk-sessions/view/${_id}`)
    }

    return (
        <Paper
            key={_id}
            sx={{
                ...KioskBoxStyles?.paperContainer
            }}
            className='theme-component-background theme-border theme-cards-boxShadow bdrRadius4px'
        >
            <Box
                sx={{ height: "100%" }}
            >
                <Grid container pl={2}>
                    <Grid item md={8} lg={8} xl={9} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Typography
                            sx={KioskBoxStyles?.headingTypography}
                            variant='h6'
                            fontWeight={'bold'}
                        >{area && area?.name ? area?.name : ""}{friendlyName && ` - ${friendlyName}`}</Typography>
                    </Grid>
                    <Grid item md={4} lg={4} xl={3}>
                        <Box
                            sx={CommonStyles?.spaceEvenlyAlignCenter}
                        >
                            <Chip
                                label={isDisabled ? "Disabled" : "Enabled"}
                                size="small"
                                sx={{ bgcolor: isDisabled ? '#FF4E5E' : '#8FC70A' }}>

                            </Chip>
                            <Switch
                                size="medium"
                                checked={!isDisabled}
                                color="warning"
                                onChange={handleEnableChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: color }} />
                <Box>
                    <Grid
                        container
                        sx={KioskBoxStyles?.parentGrid}

                    >
                        <Grid
                            container
                            item
                            xs={8}
                            sx={KioskBoxStyles?.gridBox}
                        >
                            <Grid
                                direction={'column'}
                                item
                                container
                                xs={4}
                            >
                                <Grid item xs={3} py={0.5}>
                                    <Typography
                                        sx={KioskBoxStyles?.gridTypography}
                                    >Floor</Typography>
                                </Grid>
                                <Grid item xs={3} py={0.5}>

                                    <Typography
                                        sx={KioskBoxStyles?.gridTypography}

                                    >Code</Typography>
                                </Grid>
                                <Grid item xs={3} py={0.5}>

                                    <Typography
                                        sx={KioskBoxStyles?.gridTypography}

                                    >Device</Typography>
                                </Grid>
                                <Grid item xs={3} py={0.5}>

                                    <Typography
                                        sx={KioskBoxStyles?.gridTypography}

                                    >Status</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                direction={'column'}
                                item
                                container
                                xs={8}
                            >
                                <Grid item xs={3} py={0.5}
                                >
                                    <Typography
                                        sx={KioskBoxStyles?.gridValues}
                                    >{floor && floor?.name ? floor.name : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={3} py={0.5}>

                                    <Typography
                                        sx={KioskBoxStyles?.gridValues}
                                    >{kioskCode}</Typography>
                                </Grid>
                                <Grid item xs={3} py={0.5}>

                                    <Typography
                                        sx={KioskBoxStyles?.deviceName}
                                    >{currentSession && currentSession?.deviceData?.deviceName ? currentSession?.deviceData?.deviceName : "NA"}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    py={0.5}
                                    sx={{
                                        display: "flex"
                                    }}
                                >
                                    <Typography
                                        sx={KioskBoxStyles?.connectionStatus}
                                    >{isConnected ? "Connected" : "Disconnected"}</Typography>
                                    {/* {isConnected && currentSession?.status && <Chip
                                        label={currentSession?.status}
                                        size="small"
                                        sx={{

                                            bgcolor:
                                                currentSession?.status === 'active' ? '#8FC70A' :
                                                    currentSession?.status === 'idle' ? '#AAAAAA' : '#FF4E5E'
                                        }}>

                                    </Chip>} */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            direction={'column'}
                            xs={4}
                            sx={KioskBoxStyles?.rightSideGrid}
                        >
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Box
                                    sx={CommonStyles?.flexEndAlignCenter}
                                >
                                    <Typography
                                        sx={KioskBoxStyles?.printer}>Printer</Typography>
                                    <Switch
                                        size="medium"
                                        color="warning"
                                        checked={isPrintEnabled}
                                        onChange={handlePrinterEnable}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: color }} />
                    <Grid container sx={KioskBoxStyles?.bottomGrid}>
                        <Tooltip title="View Details" placement="left">
                            <OpenInNew
                                fontSize="small"
                                sx={{
                                    color,
                                    alignSelf: "center",
                                    '&:hover': { cursor: "pointer" }
                                }}
                                onClick={handleNavigateToKioskDetails}
                            />
                        </Tooltip>
                    </Grid>
                </Box>
            </Box>

        </Paper >
    )
}

export default KioskBox