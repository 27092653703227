import { Box, Button, Card, Typography } from "@mui/material"
import managePostStyle from "../../../../styles/wall/contentReviewBody/ManagePostStyle"
import ManagePostContainer from "./managePost/ManagePostContainer"
import { ThemeColors } from "../../../../hooks/ThemeColors"

const ManagePost = (props) => {
    const { background_color } = ThemeColors()

    return (
        <>
            <Box sx={{ ...managePostStyle.OuterBox }}>
                <Card sx={{ ...managePostStyle.topBar, backgroundColor: background_color }}>
                    <Typography sx={{ ...managePostStyle.typoManagePost }}>
                        Manage Post
                    </Typography>
                    <Typography m={2} sx={{ ...managePostStyle.typoReviewPost }}>
                        Review Post
                    </Typography>
                </Card>
                <ManagePostContainer />
            </Box>
        </>
    )
}

export default ManagePost