import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const ThemeColors = () => {
    const themeMode = useSelector(state => state.theme.darkTheme)

    const color = () => themeMode ? '#fff' : '#000'

    const textColor = () => themeMode ? '#fff' : '#000'

    const background_color = () => themeMode ? '#282a2e' : '#fff'

    const componentDividerColor = themeMode ? '#fff' : '#757575'

    const commonSX = useMemo(() => ({
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#808080', // Set default border color to #808080
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Change the border color to primary when focused
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Keep the border color the same on hover
            },
            '& .MuiInputBase-input': {
                color: textColor(), // Set input text color to white (#fff)
                cursor: textColor(), // Set cursor color to white (#fff)
            },
            '& .MuiInputBase-input::placeholder': {
                color: textColor(), // Set placeholder color to white (#fff)
            },
        },
        '& .MuiInputLabel-shrink + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main', // Set border color to primary when the label floats up
        },
    }), [])

    const borderSX = {
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#808080', // Set default border color to #808080
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
                borderColor: textColor(), // Change the border color to text color when focused
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: textColor(), // Keep the border color the same on hover
            },
            '& .MuiInputBase-input': {
                color: textColor(), // Set input text color to text color
                cursor: '#fff', // Set cursor color to white (#fff)
            },
            '& .MuiInputBase-input::placeholder': {
                color: textColor(), // Set placeholder color to text color
            },
        },
        '& .MuiInputLabel-shrink + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: textColor(), // Set border color to text color when the label floats up
        },
        '& .MuiFormLabel-root': {
            color: `${textColor()} !important`,
        },
        '& .MuiSvgIcon-root': {
            color: textColor(),
        }
    }

    return { color, textColor, commonSX, borderSX, background_color, componentDividerColor }
}

export { ThemeColors }