import * as React from 'react'
import Button from '@mui/material/Button'

const BasicButton = (props) => {
    const disabled = props.disabled || false
    return (
        <Button
            className='btnFontSize'
            variant={props.variant}
            size={props.size || 'medium'}
            onClick={props.onClick}
            sx={{
                '&:hover': {
                    backgroundColor: props.bgcolor,
                },
                color: props.color,
                bgcolor: props.bgcolor,
                borderColor: props.borderColor,
            }}
            color={props.muiColorCode}
            disabled={disabled}
        >
            {props.name}
        </Button>
    )
}

export default BasicButton
