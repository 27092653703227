import React from 'react'
import ReportHeaderFooter from './ReportHeaderFooterAM'
import { Box } from '@mui/material'
import HighChartsAssetManagementCategories from '../../../assetManagement/components/charts/highCharts/HighChartsAssetManagementCategories'

const ReportChartDataAM = (props) => {
    const { data } = props

    const chartData = data?.map(ele => {
        return {
            name: `${ele.categoryName}-${ele.count} (${ele.percentage})%`,
            count: ele?.count,
            y: ele?.percentage
        }
    })

    return (
        <ReportHeaderFooter>
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <HighChartsAssetManagementCategories data={chartData} height={'75%'} title='Assets By Categories' />
            </Box>
        </ReportHeaderFooter>
    )
}

export default ReportChartDataAM