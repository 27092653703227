import { Divider } from '@mui/material'
import React from 'react'
import { ThemeColors } from '../../hooks/ThemeColors'

const CustomHeaderDivider = (props) => {
    const { componentDividerColor } = ThemeColors()
    return (
        <Divider {...props} color={componentDividerColor} />
    )
}

export default CustomHeaderDivider