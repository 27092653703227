export const dvrDataParser = (response) => {
    return {
        cpu: response?.hardwareDetails?.memory,
        memory: response?.hardwareDetails?.memory,
        camerasConnected: response?.cameras?.connectedCount,
        activeCameras: response?.cameras?.activeCount,
        inactiveCameras: response?.cameras?.inActiveCount,
        time: response?.time,
        apiTimeStamp: response?.apiTimeStamp
    }
}



