import React from 'react'
import VideoBackground from './VideoBackground'
import { Box, Button, Grid } from '@mui/material'
import { ArrowBackIos, Mic } from '@mui/icons-material'
import CustomIconButton from './CustomIconButton'
import CustomTypography from '../../../components/texts/CustomTypography'

const VoiceInterFace = ({ setVoiceCommand }) => {

    const handleBackButton = () => {
        setVoiceCommand(false)
    }
    return (
        <VideoBackground>
            <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                height: '80%', minWidth: '70%', width: '70%', background: '#efefef',
                borderRadius: "1.5rem", position: "relative", zIndex: 2, opacity: 0.9
            }}
                className='elevation'
            >
                <Box sx={{ height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container sx={{
                        alignItems: "center", justifyContent: "center", width: '70%', minWidth: '47vw',
                    }}>
                        <Grid xs={12}>
                            <Button variant="contained"
                                sx={{ position: 'absolute', top: '5%', left: '5%', backgroundColor: 'transparent', boxShadow: 'none' }}
                                className="back-button" onClick={handleBackButton}>
                                <ArrowBackIos />
                            </Button>
                        </Grid>
                        <Grid xs={12} sx={{ mb: '1rem' }}>
                            waves
                        </Grid>
                        <Grid item xs={12} sx={{ mb: '1rem', textAlign: 'center' }}>
                            <CustomIconButton>
                                <>
                                    <Mic sx={{ color: '#9d9d9d !important' }} />
                                    <CustomTypography sx={{ color: '#9d9d9d !important' }}>Speak to ask</CustomTypography>
                                </>
                            </CustomIconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </VideoBackground>
    )
}

export default VoiceInterFace