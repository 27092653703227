import React, { useState, useEffect, useRef } from 'react';
import loadModel from '../../../../features/twin/components/digital/helper/loadModel';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchInitialDeskBookedData, asyncFetchInitialDeskData, asyncFetchCameraUrl, asyncFetchForgeInitialConfig, asyncFetchInitialMeetingRoomData, updateSensorType, updateSseForgeData, updateSseForgeDeskData, updateSseForgeMeetingRoomData, resetCameraUrlData } from '../../../../../../redux/features/services/forgeSlice/forgeActions';
import loadHeatMap from '../../../../features/twin/components/digital/helper/loadHeatMap';
import SidePanel from '../../../../features/twin/components/SidePanel';
import { Air, WidgetsOutlined } from '@mui/icons-material';
import { GET } from '../../../../helpers/http';
import ForgeLoader from '../../../../features/twin/components/ForgeLoader';
// import SlideInPanel from './extensions/SlideInPanel';
import ForgeTopBar from '../../../../features/twin/components/digital/helper/ForgeTopBar';
import UnderMaintenance from '../../../../components/underMaintenance/UnderMaintenance';
import { poweredByNhance } from '../../../../../../data/imageUrls';
// import { asyncFetchCameraUrl, asyncFetchForgeInitialConfig } from '../../Redux/features/services/forgeSlice';


const Autodesk = window.Autodesk;
window.factory = new Autodesk.Viewing.MultiViewerFactory()

var viewer1;

const DigitalTwin = () => {
    const viewerRef = useRef(null);
    const [tooltip, setTootltip] = useState({ x: '', y: '', display: false });
    const [sensorHoverDetails, setSensorHoverDetails] = useState([]);
    const dispatch = useDispatch()
    const { sensorData, sensorType, cameraFeedUrl } = useSelector(state => state.forge)
    const themeMode = useSelector(state => state.theme.mode)
    const underMaintenance = useSelector(state => state.configurations.underMaintenance?.forge)
    const forge = useSelector(state => state.forge)
    const [dataViz, setDataViz] = useState(null)
    const [showSidePanel, setShowSidePanel] = useState(false);
    const [viewer, setViewer] = useState(null)

    const setSensorType = (type) => {
        dispatch(updateSensorType(type))
    }

    const handleOpenSidePanel = () => {
        setShowSidePanel(true)
    }

    const handleCloseSidePanel = () => {
        cameraFeedUrl && dispatch(resetCameraUrlData())
        setShowSidePanel(false)
    }

    const sensorVals = sensorData?.map((sensor) => {
        let value = sensor.value
        if (sensorType === 'co2') {
            if (Number(value) <= 800) return 0.1
            else if (Number(value) > 800 && Number(value) <= 1200) return 0.5
            else if (Number(value) > 1200 || isNaN(Number(value))) return 0.9
            return value
        }
        if (sensorType === 'temperature')
            if (Number(value) <= 21 || isNaN(Number(value))) return 0.1
            else if (Number(value) > 21 && Number(value) <= 25) return 0.33
            else if (Number(value) > 25 && Number(value) <= 29) return 0.66
            else if (Number(value) > 29) return 0.9
        return value
    })

    function getSensorValue(device, sensorType) {
        if (sensorType === 'co2') {
            return sensorVals[Number(device.id)];
        }
        if (sensorType === 'temperature') {
            return sensorVals[Number(device.id)];
        }
    }

    const options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        getAccessToken: async function (callback) {
            GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/token?bid=${localStorage.getItem('bid')}`, "Forge Token")
                .then((response) => {
                    const token = response.data
                    callback(token.access_token, token.expires_in)
                })
                .catch(err => console.log(err))
        }
    };

    useEffect(() => {
        if (dataViz && forge.sseIdentifierBool) {
            dataViz?.updateSurfaceShading(getSensorValue)
        }
    }, [forge])

    useEffect(() => {
        (underMaintenance === false) && dispatch(asyncFetchForgeInitialConfig())
    }, [])


    async function getModelUrn(urn, selectedNode) {
        (underMaintenance === false) && dispatch(asyncFetchForgeInitialConfig())
        try {
            loadModel(viewer1, urn, selectedNode, setSensorType, dispatch, handleOpenSidePanel, forge);
        } catch (error) {
            alert('Forge loading error ');
            console.log(error);
        }
    }

    useEffect(() => {
        // if (forge?.isLoaded) {

        Autodesk.Viewing.Initializer(options, async function () {
            viewer1 = window.factory.createViewer(document.getElementById('viewer1'),
                {
                    extensions: ['Autodesk.AEC.LevelsExtension', "Autodesk.DataVisualization"],
                    // extensions: ['Autodesk.AEC.LevelsExtension', 'Autodesk.DocumentBrowser', "Autodesk.DataVisualization"],
                    modelBrowserExcludeRoot: false, modelBrowserStartCollapsed: true,
                    disabledExtensions: {
                        measure: true,
                        explode: true
                    }
                }, Autodesk.Viewing.Private.GuiViewer3D)
            viewerRef.current = viewer1
            let startedCode = viewer1.start();
            if (startedCode > 0) {
                console.error('Failed to create a Viewer: WebGL not supported.');
                return;
            }
            viewer1.setTheme('dark-theme');
            const spinner = document.getElementById('viewer1').getElementsByClassName('adsk-viewing-viewer')[0].childNodes[1]
            const image = spinner.querySelector('img')
            image.src = poweredByNhance.src
            if (forge?.initialConfig?.deskBooking === true) dispatch(asyncFetchInitialDeskData())
            if (forge?.initialConfig?.liveCamera === true) dispatch(asyncFetchCameraUrl())
            if (forge?.initialConfig?.meetingRooms === true && forge?.initialConfig?.meetingRoomOccupancySensors) dispatch(asyncFetchInitialMeetingRoomData(forge.initialConfig?.meetingRoomOccupancySensors))

            setViewer(viewer1)
        })
        // }

        return function cleanUp() {
            if (viewerRef.current) {
                // viewerRef.current.finish();
                viewerRef.current = null
            }
        };
    }, []);


    useEffect(() => {
        if (sensorType === 'co2') {
            loadHeatMap(
                viewer1, 'co2', setTootltip, setSensorHoverDetails, getSensorValue, sensorData, setDataViz
            );
        } else if (sensorType === 'temperature') {
            loadHeatMap(
                viewer1, 'temperature', setTootltip, setSensorHoverDetails, getSensorValue, sensorData, setDataViz
            );
        }
    }, [JSON.stringify(sensorData)])

    return (
        underMaintenance ? <div style={{ width: '100%' }}><UnderMaintenance height='80vh' /></div> : <div id='forgeviewer' className='widthFill'>
            <div id='viewer1'>
                {viewer && <ForgeTopBar viewer={viewer} forge={forge} getModelUrn={getModelUrn} />}
                <ForgeLoader />
                <div className='tooltip1' style={{ left: tooltip.x ? tooltip.x : '', top: tooltip.y ? tooltip.y : '', display: tooltip.display ? 'block' : 'none' }}>
                    <div className='tooltip-content'>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Air /></div>
                        <div className='text-center ' style={{ fontSize: '15px' }}>{sensorHoverDetails?.headName}</div>
                        <div className='value text-center ' style={{ fontWeight: 'bold', fontSize: '15px' }}>{sensorHoverDetails?.value} {sensorHoverDetails?.units}</div>
                        <div className='text-center ' style={{ fontSize: '10px' }}>{sensorHoverDetails?.floorName} | {sensorHoverDetails?.areaName}</div>
                    </div>
                </div>
            </div>
            <div id="viewer2"></div>
            <SidePanel
                openSidePanel={showSidePanel}
                handleCloseSidePanel={handleCloseSidePanel}
                forgeExternalIdData={forge?.externalIdAQIData}
            />
            {/* <SlideInPanel isOpen={forge?.slideInPanel} /> */}
        </div >
    );
};

export default DigitalTwin;