import React, { useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, Button, Grid, Input, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import TextInput from '../../../components/formInputs/TextInput';
import OperationalHours from './components/OperationalHours';
import CoverPageMedia from './components/CoverPageMeida';
import GalleryUpload from './components/GalleryUpload';
import AmenitiesUpload from './components/AmenitiesUpload';
import { useSelector } from 'react-redux';
import ReturnMultiMedia from '../../../components/media/ReturnMultiMedia';
import { Delete } from '@mui/icons-material';
import { DELETE, GET, POST, PUT } from '../../../helpers/http';
import { errorToast, successToast } from '../../../helpers/apiToast';
import * as Yup from 'yup';
import Dropdown from '../../../components/dropdown/Dropdown';
import { ThemeColors } from '../../../hooks/ThemeColors';
import FormInput from '../../../components/formInputs/FormInput';

// Define initial form data based on schema structure


const BuildingProfile = ({ data, editMode, toggleDrawer, updateList, viewMode }) => {

    const [operationalHours, setOperationalHours] = useState(data?.profile?.operationalHours || {
        monday: { open: '', close: '', isClosed: true },
        tuesday: { open: '', close: '', isClosed: true },
        wednesday: { open: '', close: '', isClosed: true },
        thursday: { open: '', close: '', isClosed: true },
        friday: { open: '', close: '', isClosed: true },
        saturday: { open: '', close: '', isClosed: true },
        sunday: { open: '', close: '', isClosed: true },
    });



    const initialFormData = {
        name: '',
        about: '',
        country: {},
        countryCode: '',
        city: '',
        address: '',
        media: [],
        state: {},
        postalCode: '',
        operationalHours: {},
        contactNumber: '',
        amenities: [],
        media: [],
        coverPageMedia: {},
        operationalHours: {},
        primaryContact: { name: '', email: '', phone: '' },
        timezone: '',
        geoLocationLink: '',
        // latlng: [],
    };
    const fileReadUrl = useSelector((state) => state?.configurations?.fileReadUrl)
    const { commonSX, background_color, textColor } = ThemeColors();

    const [formData, setFormData] = useState(data?.profile || initialFormData);
    const [errors, setErrors] = useState({});
    const [submitState, setSubmitState] = useState(false)
    const [coverPage, setCoverPage] = useState(data?.profile?.coverPageMedia || {})
    const [medias, setMedias] = useState(data?.profile?.media || [])
    const [amenities, setAmenitites] = useState(data?.profile?.amenities || [])
    const [logoMedia, setLogoMedia] = useState(data?.profile?.amenities || [])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [country, setCountry] = useState({})
    const [selectedState, setSelectedState] = useState({})
    const [countries, setCountries] = useState([])
    const [dropDown, setDropDown] = useState([])
    const [render, setRender] = useState(false)
    const [changeInCountry, setChangeInCountry] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const result = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/country-state-city/list?type=country`, "Get countries")
                const result1 = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/country-state-city/list?type=state`, "Get States")
                setStates(result1?.data)
                // const result3 = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/country-state-city/list?type=city`, "Get Cities")

                let countrySelected = {}
                let stateSelected = {}
                setCountries(result?.data || [])
                if (data?.profile?.country) {
                    countrySelected = result?.data?.find(country => {
                        return country.name === data?.profile?.country
                    })
                    setCountry(countrySelected)
                    if (countrySelected) {
                        setFormData((prevFormData) => ({
                            ...prevFormData, country: countrySelected,
                        }))
                        const resultTimeZone = countrySelected?.timezones.map(ele => {
                            return { name: ele?.gmtOffsetName }
                        })
                        setDropDown(resultTimeZone)

                        // setDropDown(countrySelected.timezones)
                        const initialTimezone = resultTimeZone.find(
                            tz => tz.name === data?.profile?.timezone
                        );
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            timezone: initialTimezone || {} // Set initial timezone if matched
                        }));

                    }
                }
                if (data?.profile?.state) {
                    stateSelected = result1?.data?.find(st => {
                        return st.name === data?.profile?.state
                    })
                    setSelectedState(stateSelected)
                    if (stateSelected) {
                        setFormData((prevFormData) => ({
                            ...prevFormData, state: stateSelected,
                        }))
                    }
                }
                setRender(!render)

            } catch (error) {
                console.log(error)
            }


        })();


    }, [])

    useEffect(() => {
        if (country?.timezones?.length === 1) {

            setFormData(prevFormData => ({
                ...prevFormData,
                timezone: { name: country?.timezones[0].gmtOffsetName } || {} // Set initial timezone if matched
            }));
        }

    }, [changeInCountry])


    useEffect(() => {
        (async () => {
            try {

                if (country?.isoCode) {
                    const result3 = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/country-state-city/list?type=city&countryCode=${country.isoCode}&stateCode=${selectedState.isoCode}`, "Get Cities")
                    setCities(result3?.data)
                    const filteredCity = result3?.data?.find(ele => ele.name === data?.profile?.city)
                    if (filteredCity) {
                        setFormData((prevFormData) => (
                            { ...prevFormData, city: filteredCity }
                        ))
                    }

                }


            } catch (error) {
                console.log(error)
            }

        }
        )();

    }, [render, selectedState])

    const handleChange = (e) => {
        if (e.target.name === 'state') {
            setSelectedState(e.target.value)
        }
        setFormData({ ...formData, [e.target.name]: e.target?.value });
    };

    const handleCountry = async (e) => {
        try {
            const selectedCountry = e.target.value; // Get the selected country object
            if (e.target.name === "country") {
                setCountry(selectedCountry)
                setChangeInCountry(!changeInCountry)
                setSelectedState({})

                setFormData((prevFormData) => ({
                    ...prevFormData, city: {},
                    // timezone: {}
                }))
            }


            // Update formData with country name and phone code
            setFormData((prevFormData) => (
                {
                    ...prevFormData,
                    [e.target.name]: selectedCountry,
                    countryCode: selectedCountry?.phonecode,
                }
            )
            );
            if (selectedCountry?.isoCode) {
                const filteredStates = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/country-state-city/list?type=state&countryCode=${selectedCountry.isoCode}`, "Get States")
                setStates(filteredStates?.data || [])

            }

            // const filteredStates = await State.getStatesOfCountry(selectedCountry.isoCode)

            // Extract timezones from the selected country
            const timeZoneArray = selectedCountry?.timezones?.map(ele => {
                return { name: ele.gmtOffsetName }
            });
            // Set dropdown for timezones
            setDropDown(timeZoneArray || []);
            e.target.value = ""

        } catch (error) {
            errorToast(error?.message)
            console.log(error)

        }
    }

    const vaidateSchema = Yup.object().shape({
        name: Yup.string().required('Building Name is required'),
        about: Yup.string(),
        // .required('About is required'),
        country: Yup.string().required('Country is required'),
        countryCode: Yup.string(),
        city: Yup.string(),
        address: Yup.string().required('Address is required'),
        media: Yup.array().default([]),
        // .min(1, 'Media is required'),
        state: Yup.string(),
        coverPageMedia: Yup.object().default({}),
        postalCode: Yup.string().required('Postal Code is required'),
        contactNumber: Yup.string().required('Contact Number is required'),
        amenities: Yup.array().default([]),
        locality: Yup.string().default(''),
        // .of(Yup.string()).min(1, 'At least one amenity is required'),
        primaryContact: Yup.object().shape({
            name: Yup.string().required('Primary Contact Name is required'),
            email: Yup.string().email('Invalid email format').required('Primary Contact Email is required'),
            phone: Yup.string().required('Primary Contact Phone is required')
        }),
        timezone: Yup.string().default('').required('Timezone is required'),
        geoLocationLink: Yup.string()
        // .url('Invalid URL format')
        // .required('Geo-location link is required'),

    })



    const disableSubmit = () => {
        setSubmitState(true)
    }

    const enableSubmit = () => {
        setSubmitState(false)
    }
    const handleSubmit = async () => {
        try {
            const finalObject = {
                address: formData?.address,
                about: formData?.about,
                city: formData?.city?.name,
                country: formData?.country?.name, // Only country name
                countryCode: formData?.countryCode,
                coverPageMedia: formData?.coverPageMedia,
                name: formData?.name,
                amenities: formData?.amenities,
                contactNumber: formData?.contactNumber,
                geoLocationLink: formData?.geoLocationLink,
                coverPageMedia: formData?.coverPageMedia,
                locality: formData?.locality,
                latlng: formData?.latlng,
                media: formData?.media,
                operationalHours: formData?.operationalHours,
                postalCode: formData?.postalCode,
                primaryContact: formData?.primaryContact,
                state: formData?.state?.name, // Only state name
                timezone: formData?.timezone?.name// First timezone's gmtOffsetName
            };

            await vaidateSchema.validate(finalObject, { abortEarly: false });

            await PUT(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/building/profile?bid=${data?.id}`, finalObject, "Form Submission")
            updateList()
            successToast("Form Submission Successful")
            toggleDrawer()
            setErrors({})
        } catch (error) {
            console.log(error)
            if (error.name === 'ValidationError') {
                const newErrors = {};
                error.inner.forEach(e => {
                    newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
            } else {
                console.error('Submission error:', error);
            }
        }

    }

    const handleChangeForPrimary = (event) => {
        const { name, value } = event.target;

        // Split the name by '.' to handle nested properties
        const keys = name.split('.');

        // Use the keys array to build up the nested structure in formData
        setFormData((prevData) => {
            let updatedData = { ...prevData };
            let current = updatedData;

            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current[key] = current[key] || {};
                    current = current[key];
                }
            });

            return updatedData;
        });
    };

    const handleDeleteMediaFun = async (media) => {
        try {
            if (media) {
                setLogoMedia(logoMedia?.filter(mda => mda?.name !== media?.name))
                setFormData({ ...formData, amenities: logoMedia?.filter(mda => mda?.name !== media?.name) })
                successToast("Successfully Deleted LOGO")
            }

        } catch (error) {
            console.log(error)
        }

    }

    const handleCancel = () => {
        toggleDrawer()

    }
    return (

        <CustomComponentContainer
            sx={{
                marginTop: "10px",
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                overflowX: "hidden",
                overflowY: "auto",
                gap: '1.5rem',
                width: '80vw',
                maxHeight: '100vh',  // Restrict height of the container
                overflow: 'auto',    // Enable scroll for overflow content
                backgroundColor: background_color(),
            }}
        >
            <Typography variant='h5'
                sx={{ color: textColor(), marginLeft: "25px" }}>Building Profile</Typography>

            <Grid container
                spacing={2}
                sx={{
                    // margin: "10px",
                    padding: "2",
                    backgroundColor: background_color(),
                    // overflow: "hidden"
                }}
            >


                <Grid item
                    xs={12} md={6}
                >
                    <Paper
                        sx={{
                            ...commonSX,
                            display: "flex",
                            padding: "1rem",
                            flexDirection: "column",
                            gap: "1rem",
                            // marginBottom: "10px",
                            // border: "1px solid red"
                            backgroundColor: background_color(),
                        }}
                    >
                        <FormInput
                            name='name'
                            label='Name'
                            disabled={viewMode}
                            value={formData?.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors?.name || ''}
                        />
                        <FormInput
                            name='contactNumber'
                            type='number'
                            disabled={viewMode}
                            sx={{
                                color: textColor()

                            }}
                            label='Contact Number'
                            value={formData?.contactNumber}
                            onChange={handleChange}
                            error={!!errors?.contactNumber}
                            helperText={errors?.contactNumber || ''}
                        />
                        <FormInput
                            name='about'
                            label='About'
                            disabled={viewMode}
                            multiline
                            rows={3}
                            value={formData?.about}
                            onChange={handleChange}
                            error={!!errors?.about}
                            helperText={errors?.about || ''}
                        />
                        <Typography variant='h6' sx={{ color: textColor() }}>Primary Contact</Typography>
                        <FormInput
                            name='primaryContact.name'
                            label='Primary Contact Name'
                            disabled={viewMode}
                            value={formData?.primaryContact?.name}
                            onChange={handleChangeForPrimary}
                            error={!!errors[`primaryContact.name`]}
                            helperText={errors[`primaryContact.name`] || ''}
                        />
                        <FormInput
                            name='primaryContact.email'
                            label='Primary Contact Email'
                            disabled={viewMode}
                            value={formData?.primaryContact?.email}
                            onChange={handleChangeForPrimary}
                            error={!!errors[`primaryContact.email`]}
                            helperText={errors[`primaryContact.email`] || ''}
                        />
                        <FormInput
                            name='primaryContact.phone'
                            label='Primary Contact Phone'
                            disabled={viewMode}
                            value={formData?.primaryContact?.phone}
                            onChange={handleChangeForPrimary}
                            error={!!errors[`primaryContact.phone`]}
                            helperText={errors[`primaryContact.phone`] || ''}
                        />
                        <Typography variant='h6' sx={{ color: textColor() }}>Location</Typography>
                        <Grid container
                            spacing={2}
                            sx={{
                                // border: "1px solid green",
                                backgroundColor: background_color(),
                            }}
                        >
                            <Grid item
                                xs={6}
                            // sx={{ border: "1px solid orange" }}

                            >
                                <Dropdown
                                    name='country'
                                    label='Country'
                                    inputLabel={viewMode ? "Country" : "Select Country"}
                                    data={countries}
                                    width={200}
                                    sx={commonSX}
                                    value={formData.country || {}}
                                    disabled={viewMode}
                                    onChange={handleCountry}
                                />
                                {errors?.country && <p style={{ color: 'red', fontSize: "12px", marginLeft: "10px" }}>{errors?.country}</p>}
                                {/* 
                                <Dropdown
                                    name='state'
                                    data={states}
                                    label='State'
                                    inputLabel={viewMode ? "State" : "Select State"}
                                    width={200}
                                    sx={commonSX}
                                    value={formData?.state}
                                    disabled={viewMode}
                                    onChange={handleChange}
                                /> */}
                                <FormInput
                                    name='postalCode'
                                    // sx={{ width: "18rem" }}
                                    type='text'
                                    disabled={viewMode}
                                    label='Postal Code'
                                    sx={{ width: "12.5rem" }}
                                    rows={5}
                                    value={formData?.postalCode}
                                    onChange={handleChange}
                                    error={!!errors?.postalCode}
                                    helperText={errors?.postalCode || ''}
                                />


                            </Grid>
                            <Grid item xs={6}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end", // Align items to the right
                                }}
                            >
                                <Dropdown
                                    name='timezone'
                                    data={dropDown}
                                    inputLabel={viewMode ? "Time Zone" : "Select Time Zone"}
                                    variant="outlined"
                                    // margin={1}                   // Margin around the dropdown
                                    width={200}
                                    sx={{ ...commonSX }}
                                    // type='text'
                                    disabled={viewMode}
                                    label='Time Zone'
                                    value={formData?.timezone || ""}
                                    onChange={handleChange}
                                // error={!!errors?.timezone}
                                // helperText={errors?.timezone || ''}
                                />
                                {errors?.timezone && <p style={{ color: 'red', fontSize: "12px", marginLeft: "10px" }}>{errors?.timezone}</p>}

                                <FormInput
                                    name='countryCode'
                                    type='number'
                                    disabled={viewMode}
                                    sx={{ width: "12.5rem" }}
                                    label='Country code'
                                    value={formData?.countryCode}
                                    onChange={handleChange}
                                    error={!!errors?.countryCode}
                                    helperText={errors.countryCode || ''}
                                />



                            </Grid>

                        </Grid>
                        <Grid container spacing={2}
                        // sx={{ border: "1px solid red" }}
                        >
                            <Grid item xs={6}
                            >
                                {/* <Dropdown
                                    variant="outlined"
                                    name='city'
                                    label='City'
                                    inputLabel={viewMode ? "City" : "Select City"}
                                    data={cities}
                                    width={200}
                                    sx={commonSX}
                                    value={formData?.city}
                                    disabled={viewMode}
                                    onChange={handleChange}
                                    error={!!errors?.city}
                                    helperText={errors?.city || ''}
                                /> */}

                            </Grid>
                            {/* <Grid item xs={6}
                                sx={{
                                    display: "flex",
                                    justifyContent: "right"
                                }}
                            >
                                <FormInput
                                    name='postalCode'
                                    // sx={{ width: "18rem" }}
                                    type='text'
                                    disabled={viewMode}
                                    label='Postal Code'
                                    sx={{ width: "12.5rem" }}
                                    rows={5}
                                    value={formData?.postalCode}
                                    onChange={handleChange}
                                    error={!!errors?.postalCode}
                                    helperText={errors?.postalCode || ''}
                                />

                            </Grid> */}
                        </Grid>
                        <FormInput
                            name="locality"
                            label="Locality"
                            disabled={viewMode}
                            sx={{ width: "12.5rem" }}
                            // multiline
                            // rows={3}
                            value={formData?.locality}
                            onChange={handleChange}
                            error={!!errors?.locality}
                            helperText={errors?.locality || ''}
                        />
                        <FormInput
                            name='address'
                            label='Address'
                            disabled={viewMode}
                            multiline
                            rows={3}
                            value={formData?.address}
                            onChange={handleChange}
                            error={!!errors?.address}
                            helperText={errors?.address || ''}
                        />



                        <FormInput
                            name='geoLocationLink'
                            sx={{
                                ...commonSX
                            }}
                            type='text'
                            label='Location Link'
                            disabled={viewMode}
                            placeholder='paste your location'
                            value={formData?.geoLocationLink}
                            onChange={handleChange}
                            error={!!errors?.geoLocationLink}
                            helperText={errors?.geoLocationLink || ''}
                        />




                    </Paper>



                </Grid>

                <Grid
                    item xs={12}
                    md={6}

                // sx={{ border: "1px solid blue" }}

                >
                    <Paper
                        sx={{
                            // display: "flex",
                            padding: "1rem",
                            // flexDirection: "column",
                            gap: "1rem",
                            height: "100%",
                            backgroundColor: background_color(),
                            ...commonSX,
                            // border: "1px solid green"
                        }}
                    >
                        <Typography variant='h6' sx={{ color: textColor() }}>Operational Hours</Typography>

                        <OperationalHours
                            setFormData={setFormData}
                            viewMode={viewMode}
                            formData={formData}
                            operationalHours={operationalHours}
                            setOperationalHours={setOperationalHours}
                            disableSubmit={disableSubmit}
                            enableSubmit={enableSubmit}
                            errors={errors} />
                        <Typography variant='h6' sx={{ color: textColor() }} >cover page( video)</Typography>
                        <Box
                        // sx={{
                        //     padding: '10px',
                        //     border: "1px lightgrey  solid",
                        //     borderRadius: "8px"
                        // }}
                        >
                            <CoverPageMedia
                                setFormData={setFormData}
                                formData={formData}
                                viewMode={viewMode}
                                coverPage={coverPage}
                                setCoverPage={setCoverPage} />

                            {errors[`coverPageMedia.key`]?.length > 0 && <p style={{ color: "red", fontSize: "13px" }}>{errors[`coverPageMedia.key`]}</p>}

                        </Box>

                        <Typography variant='h6' sx={{ color: textColor() }}>Gallery</Typography>
                        <Box
                            sx={{
                                // padding: '10px',
                                // border: "1px lightgrey  solid",
                                // borderRadius: "8px"
                            }}
                        >
                            <GalleryUpload
                                setFormData={setFormData}
                                buildingId={data?.id}
                                formData={formData}
                                viewMode={viewMode}
                                medias={medias}
                                setMedias={setMedias} />
                            {errors?.media?.length > 0 && <p style={{ color: "red", fontSize: "13px" }}>{errors?.media}</p>}
                        </Box>

                        <Typography variant='h6'
                            sx={{ paddingBottom: "10px", color: textColor() }}
                        >Add Amenities</Typography>

                        <AmenitiesUpload
                            formData={formData}
                            setFormData={setFormData}
                            viewMode={viewMode}
                            amenities={amenities} setAmenitites={setAmenitites} setLogoMedia={setLogoMedia} logoMedia={logoMedia} />
                        <Grid
                            // item
                            // sm={6} md={4} lg={3} xl={3}
                            sx={{
                                // border: "1px solid green",
                            }}>
                            {logoMedia?.length > 0 &&
                                logoMedia?.map((media, i) => {
                                    return <Grid
                                        // item
                                        // sm={6} md={4} lg={3} xl={3}
                                        key={i}
                                        sx={{
                                            position: "relative",
                                            // border: "1px solid blue",
                                            display: "flex",
                                            justifyItems: "row"
                                            // height: "100%"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // border: "1px solid orange",
                                                marginBottom: "10px",


                                            }}
                                        >
                                            <ReturnMultiMedia

                                                fileReadUrl={fileReadUrl} media={media.type ? media : { type: "image/svg+xml", key: media?.logo }}
                                                height="10px"
                                                sx={{
                                                    border: '1px solid lightgray',
                                                    // borderRadius: "1rem",    

                                                }} />

                                            {!viewMode &&
                                                <Avatar sx={{
                                                    position: 'absolute',
                                                    height: "1.5rem",
                                                    width: "1.5rem",
                                                    backgroundColor: "black",
                                                    left: "4.5rem",
                                                    top: "1rem",
                                                }}>
                                                    <Delete
                                                        fontSize="small"
                                                        onClick={() => handleDeleteMediaFun(media)}
                                                        sx={{ cursor: 'pointer' }} />
                                                </Avatar>}

                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "1rem",
                                                // border: "1px solid red"
                                            }}
                                        >
                                            <Typography
                                                sx={{ color: textColor() }}
                                            >
                                                {media?.name}
                                            </Typography>

                                        </Box>

                                    </Grid>
                                })
                            }
                            {errors?.amenities && <p style={{ color: "red", fontSize: "13px", padding: "15px" }}>{errors?.amenities}</p>}
                        </Grid>
                    </Paper>

                </Grid>







            </Grid>
            <Box sx={{
                // display: "flex",
                // justifyContent: "space-around",
                // alignItems: "flex-start",
                marginLeft: "25px",
                width: "50%"
            }}>
                <Button
                    variant='contained'
                    disabled={submitState || viewMode}
                    sx={{
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '25%',
                        // height: '2.5rem',
                        marginRight: "30px",
                        // marginLeft: "15px"
                    }}
                    onClick={handleSubmit}
                >
                    {editMode ? 'Update' : 'Submit'}
                </Button>
                <Button
                    variant='contained'
                    onClick={
                        handleCancel
                    }
                    sx={{
                        mr: 1,
                        // borderRadius: 2,
                        bgcolor: '#4B4B4B',
                        borderColor: '#4B4B4B',
                        width: '25%',
                        // height: '2.5rem',
                        // maxHeight: 35,
                        // height: "80%",
                        // alignSelf: 'center',

                    }}


                >Cancel</Button>

            </Box>


        </CustomComponentContainer >


    );
};

export default BuildingProfile;
