export const GroundFloorDeskExternalIdsMapped = {
    "327152917": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-0048451c",//1
    "863614562": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-0048451d",//2
    "868286907": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484963",//3
    "850358044": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484962",//4
    "600977840": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484aca",//5
    "705185726": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484ac9",//6
    "1203399223": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484ac8",//7
    "1412989047": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484ac2",//8
    "2068346964": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484ac3",//9
    "288448888": "26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484ac4"//10
}

export const RoomFloorExternalIdsMapped = {
    "GFMR1": "bf6f49e5-3f08-4203-865a-52da230d616a-004fd082",//GFMR1
    "GFMR2": "bf6f49e5-3f08-4203-865a-52da230d616a-004fcff2",//GFMR2,
    "FFMR1": "bf6f49e5-3f08-4203-865a-52da230d616a-004fd0a2",//FFME1
    "SFMR1": "bf6f49e5-3f08-4203-865a-52da230d616a-004fd297",//SFMR1
    "TFMR1": "bf6f49e5-3f08-4203-865a-52da230d616a-004fd3e9"//TFMR1
}
