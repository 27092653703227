import React from 'react'
import { useTheme, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const Loading = (props) => {
    const theme = useTheme()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    return (
        <CircularProgress sx={{ color: color }} size={props?.size || 20} />
    )
}

export default Loading