import { Box, Button, IconButton, Paper, Typography, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CommonStyles from '../../../styles/CommonStyles'
import { useDVR, useDVRDispatch } from '../../../features/cctv/DVRContext'
import Datatable from '../../../components/datatable/Datatable'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'
import { DELETE, POST } from '../../../helpers/http'
import { errorToast, successToast } from '../../../helpers/apiToast'
import swal from 'sweetalert'
import DVRSwitchButton from '../../../features/cctv/DVRSwitchButton'

const CCTV = (props) => {
    const navigate = useNavigate()
    const currentMode = useSelector(state => state.theme.darkTheme)
    const tenantCode = useSelector(state => state?.user?.tenantCode)
    const bgcolor = currentMode ? '#282a2e' : '#fff'
    const paperBorderColor = currentMode ? '#444A67' : '#D2DAE2'
    const selectedBuildingData = useSelector((state) => state.buildings?.selectedBuildingData)
    const [loading, setLoading] = useState(true)

    const DVRs = useDVR()
    const dispatch = useDVRDispatch()


    useEffect(() => {
        (async () => {
            if (Object.keys(selectedBuildingData)?.length > 0) {
                const formData = {
                    subTenantReference: selectedBuildingData?.subTenant._id,
                    buildingReference: selectedBuildingData?._id,
                    locationReference: selectedBuildingData?.location._id
                }
                try {
                    if (formData.buildingReference === localStorage.getItem('bid')) {
                        const getDVRs = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/list`, formData)
                        dispatch({ type: "ADD_DVR_LIST", payload: getDVRs.data })
                        setLoading(false)
                    }
                } catch (error) {
                    setLoading(false)
                    if (error?.response?.data?.message) {
                        errorToast(error?.response?.data?.message)
                    } else {
                        errorToast(error?.message)
                    }
                }
            }
            dispatch({ type: "CLEAR_SELECTED" })

        })()
    }, [selectedBuildingData])


    const tableData = DVRs.dvrList?.map(dvr => {
        return {
            ...dvr,
            ...dvr.connectionDetails,
            name: dvr?.name,
            id: dvr?._id,
            buildingName: dvr?.buildingReference?.name,
            locationName: dvr?.locationReference?.name,
            subTenantName: dvr?.subTenantReference?.name,
            isDisabled: dvr?.isDisabled
        }
    })

    const handleView = (dvrId) => {
        const viewingDVR = structuredClone(DVRs.dvrList.find(dvr => dvr._id === dvrId))
        dispatch({ type: "updateViewDVR", payload: viewingDVR })
        navigate(`/admin/cctv/view/${viewingDVR.UUID}`)
    }

    const handleEdit = (dvrId) => {

        const updatingDvr = structuredClone(DVRs.dvrList.find(dvr => dvr._id === dvrId))

        navigate(`/admin/cctv/edit/${updatingDvr.UUID}`)
        dispatch({
            type: 'UPDATE_SELECTED_DVR',
            payload: updatingDvr
        })
    }

    const handleDelete = async (dvrId) => {
        swal({
            title: 'Alert',
            text: 'Are you sure you want to delete',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                const deletingDvr = structuredClone(DVRs.dvrList.find(dvr => dvr._id === dvrId))
                try {
                    const deleteDVR = await DELETE(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr?dvrUUID=${deletingDvr.UUID}`, "DVR DELETE")
                    if (deleteDVR.status === 200) {
                        dispatch({
                            type: 'deleteDVR',
                            payload: dvrId
                        })
                        successToast("DVR Deleted")
                    }
                } catch (error) {
                    if (error?.response?.data?.message) {
                        errorToast(error?.response?.data?.message)
                    } else {
                        errorToast(error?.message)
                    }
                }
            }
        })

    }



    const columns = [
        {
            field: 'DVR status',
            headerName: 'Status',
            width: 130,
            renderCell: (cellValues) => {

                return (
                    <>
                        <DVRSwitchButton dvrData={cellValues?.row} />
                    </>
                )
            },
            editable: false
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: false
        },
        {
            field: 'ipAddress',
            headerName: 'IP Address',
            width: 200,
            editable: false
        },
        {
            field: 'port',
            headerName: 'Port',
            width: 100,
            editable: false
        },
        {
            field: 'username',
            headerName: 'User Name',
            width: 180,
            editable: false
        },
        {
            field: 'gatewayIpAddress',
            headerName: 'Gateway IP Address',
            width: 180,
            editable: false
        },
        {
            field: 'locationName',
            headerName: 'Location',
            width: 180,
            editable: false
        },
        {
            field: 'buildingName',
            headerName: 'Building',
            width: 200,
            editable: false
        },
        {
            field: 'subTenantName',
            headerName: 'Sub Tenant',
            width: 200,
            editable: false
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 230,
            renderCell: (cellValues) => {
                return (

                    <Box sx={{ display: 'flex', columnGap: 1 }}>
                        <IconButton size='small' onClick={() => handleView(cellValues?.row?._id)}>
                            <VisibilityOutlined fontSize='small' sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>&nbsp;VIEW</Typography>
                        </IconButton>
                        <IconButton size='small' onClick={() => handleEdit(cellValues?.row?._id)}>
                            <EditOutlined fontSize='small' sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>&nbsp;EDIT</Typography>
                        </IconButton>
                        <IconButton size='small' onClick={() => handleDelete(cellValues?.row?._id)} >
                            <DeleteOutline fontSize='small' sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>&nbsp;DELETE</Typography>
                        </IconButton>
                    </Box>
                )
            },
            sortable: false,
            editable: false
        }
    ]

    return (
        <Box sx={{ ...CommonStyles.OuterBox, minHeight: "89.9vh", maxHeight: "100%" }}>
            <Typography
                sx={CommonStyles.pageHeading}
            >
                CCTV / DVR
            </Typography>
            <Box sx={{ ...CommonStyles.innerBox, justifyContent: 'normal' }}>
                <Paper
                    sx={{
                        ...CommonStyles.topPaper,
                        border: `0.5px solid ${paperBorderColor}`,
                        backgroundColor: bgcolor,
                        boxShadow: `0px 0px 6px ${currentMode ? `#3c3c5c` : `#c2cfdb`}`
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button sx={{ ...CommonStyles.actionButton }} onClick={() => navigate('/admin/cctv/add')}>Create DVR</Button>
                    </Box>
                </Paper>
                {!loading ?
                    DVRs.dvrList.length == 0 ?
                        <Typography variant='h6' margin="auto">No Dvrs Found</Typography> :
                        <Datatable
                            checkBox={false}
                            rows={tableData}
                            columns={columns}
                            pageSize={15}
                            rowsPerPage={15}
                        />
                    :
                    <CircularProgress sx={{ margin: 'auto', color: "#dc4d28" }} />}
            </Box>
        </Box>
    )
}

export default CCTV