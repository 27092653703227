import { Autocomplete, Button, Checkbox, Chip, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/modal/Modal';
import TextInput from '../../../../components/formInputs/TextInput';
import isEmail from 'validator/lib/isEmail';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import CommonStyles from '../../../../styles/CommonStyles'
import { GET, PUT } from '../../../../helpers/http';
import { successToast } from '../../../../helpers/apiToast';
import EditGroupModalStyles from '../../../../styles/EditGroupModalStyles';
import validSearchTerm from '../../../../helpers/validSearchTerm';

const EditGroupModal = (props) => {
    const {
        openEditGroupModal,
        handleCloseEditGroupModal,
        handleUpdateGroups,
        editingGroupId,
    } = props

    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const [allUsersList, setAllUsersList] = useState([])

    const [users, setUsers] = useState([])

    const [editingGroup, setEditingGroup] = useState({})

    const [searchTerm, setSearchTerm] = useState('');

    const [group, setGroup] = useState({})

    const editingMembers = editingGroup?.members?.map(member => {
        return member?.email
    })

    const [selectedEmails, setSelectedEmails] = useState(editingMembers || []);

    const [groupError, setGroupError] = useState('');

    const [editEmailError, setEditEmailError] = useState('');

    const [openAutoComplete, setOpenAutoComplete] = useState(false);
    const [timer, setTimer] = useState(0)

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    const loading = openAutoComplete && users.length === 0;

    const handleChange = (event) => {
        const { name, value } = event.target
        setGroup({
            ...group,
            [name]: value
        })
    }

    const handleCloseEditModal = () => {
        handleCloseEditGroupModal()
        setSearchTerm('')
        setGroupError('')
        setGroup({ name: '', groupCode: '', description: '', members: [] })
        setEditingGroup({})
        setSelectedEmails([])
        setUsers([])
    }


    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/users`);
                if (response?.status === 200) {
                    setAllUsersList([...response?.data])
                }
            } catch (error) {
                console.log({ error })
            }
        }
        fetchAllUsers()
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                if (editingGroupId) {
                    const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/group/${editingGroupId}`, 'Get Group by Id')
                    setGroup({ ...response?.data })
                    setEditingGroup(response?.data)
                    const editingMembers = response?.data?.members?.map(member => {
                        return member?.email
                    })
                    setSelectedEmails(editingMembers)
                }
            } catch (error) {
                console.log({ error })
            }
        }
        fetchData()
    }, [editingGroupId])

    useEffect(() => {
        let active = true;

        if (searchTerm?.length >= 2) {
            debounce(async () => {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/users/?searchTerm=${validSearchTerm(searchTerm)}`); // Apicall.

                if (active) {
                    setUsers([...response?.data]);
                }
            }, 500)
        }

        return () => {
            active = false;
        };
    }, [loading, searchTerm])

    const updateGroup = async () => {
        const conditionsPassed = group?.name?.length >= 3 && group?.name?.length < 256 && group?.groupCode?.length >= 2 && group?.groupCode?.length < 256 && group?.description?.length < 1024 && (searchTerm?.length === 0 || (searchTerm?.length > 0 && selectedEmails.includes(searchTerm)))
        if (group?.name?.length < 3) {
            setGroupError('Group name should be at least 3 characters')
        }
        else if (group?.name?.length > 256) {
            setGroupError('Group name should be less than 256 characters')
        }

        else if (group?.groupCode?.length < 2) {
            setGroupError('Group code should be at least 2 characters')
        }
        else if (group?.groupCode?.length > 256) {
            setGroupError('Group code should be less than 256 characters')
        }
        else if (group?.description?.length > 1024) {
            setGroupError('Group description should be less than 1024 characters')
        }
        else if (searchTerm?.length > 0 && !selectedEmails.includes(searchTerm)) {
            setGroupError(`Please either add or remove ${searchTerm}. Press Enter to add.`)
        }
        else if (conditionsPassed) {
            setGroupError('')
            const apiFormat = selectedEmails?.map((email) => {
                const user = allUsersList?.find((user) => {
                    return user?.email === email
                })
                return user ? user?._id : email
            })
            const newGroup = {
                ...group,
                members: apiFormat
            }
            delete newGroup?._id
            delete newGroup?.createdAt
            try {
                const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/group/${editingGroup?._id}`, newGroup, 'Create Group')
                successToast(`Group ${response?.data?.name} updated successfully`)

                handleUpdateGroups(response?.data)
                handleCloseEditModal()
            } catch (error) {
                setSearchTerm('')
                console.log(error);
            }
        }
    }

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <Modal
            modalOpen={openEditGroupModal}
            closeModal={handleCloseEditModal}
            title={'Update group'}
        >
            <Stack spacing={2}>
                <TextInput
                    label={'Group Name'}
                    fullWidth
                    value={group.name}
                    onChange={handleChange}
                    name={'name'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Group Code'}
                    fullWidth
                    value={group.groupCode}
                    onChange={handleChange}
                    name={'groupCode'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Group Description (Optional)'}
                    fullWidth
                    multiline
                    rows={4}
                    value={group.description}
                    onChange={handleChange}
                    name={'description'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <Stack sx={EditGroupModalStyles.innerStack} spacing={2}>
                    <Typography color={color}>Edit Members</Typography>
                    <Autocomplete
                        value={selectedEmails}
                        onChange={(event, newValue) => {
                            if (newValue.length > 0 && !isEmail(newValue?.[newValue.length - 1])) {
                                setEditEmailError('Invalid email')
                                setGroupError('')
                            }
                            else if (newValue.length > 0 && isEmail(newValue?.[newValue.length - 1])) {
                                setEditEmailError('')
                                setSelectedEmails(newValue);
                                setSearchTerm('')
                                setGroupError('')
                            }
                            else if (newValue.length === 0) {
                                setEditEmailError('')
                                setSelectedEmails([]);
                                setGroupError('')
                            }
                        }}
                        onOpen={() => {
                            setOpenAutoComplete(true);
                        }}
                        onClose={() => {
                            setOpenAutoComplete(false);
                        }}
                        noOptionsText="No Data"
                        multiple
                        limitTags={3}
                        disableCloseOnSelect
                        options={users.map((option) => option.email)}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    style={{ marginRight: 8, color: bgcolor }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        freeSolo
                        renderTags={(selectedEmails, getTagProps) =>
                            selectedEmails.map((option, index) => (
                                <Chip variant="outlined" sx={{ color }} label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                onChange={handleSearchTermChange}
                                {...params}
                                label="Members"
                                placeholder="Search Users"
                                inputProps={{ ...params.inputProps, style: { color, bgcolor } }}
                                InputLabelProps={{ ...params.InputLabelProps, sx: { color } }}
                            />
                        )}
                        style={EditGroupModalStyles.autocomplete}
                    />
                    {
                        editEmailError && <Typography color='error' sx={EditGroupModalStyles.errorTypo}>{editEmailError}</Typography>
                    }
                </Stack>
                {
                    groupError && <Typography color='error' sx={EditGroupModalStyles.errorTypo}>{groupError}</Typography>
                }
                <Button className='btnFontSize' onClick={updateGroup} sx={{ ...CommonStyles.actionButton }}>Update Group</Button>
            </Stack>
        </Modal>
    )
}

export default EditGroupModal