import React, { useEffect, useState } from "react";
import { Box } from '@mui/material'
import { useSelector } from "react-redux";
import moment from 'moment'
import { useFetchWeatherQuery } from "../../../../../../redux/features/weatherSlice";
import { skipToken } from '@reduxjs/toolkit/dist/query';
import WeatherTempStyles from "../../../../styles/WeatherTempStyles";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../hooks/ThemeColors";
import CustomTypography from "../../../../components/texts/CustomTypography";

const WeatherTemp = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)

    const { color } = ThemeColors()

    const { selectedBuilding } = useSelector(state => state.buildings)

    const min2digits = (n) => ((n < 10 ? '0' : '') + n)

    const { data: weather, refetch: refetchWeatherData } = useFetchWeatherQuery(selectedBuilding ? selectedBuilding : skipToken)

    const tempValue = weather?.data?.Temperature?.Metric?.Value
    const tempUnit = weather?.data?.Temperature?.Metric?.Unit
    const wicon = weather?.data?.WeatherIcon
    const WIcon = min2digits(wicon)

    const [time, setTime] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        const currentTime = setInterval(() => {
            setTime(moment().format('hh:mm:ss A'))
            // setTime(new Date().toLocaleString('en-US'))
        }, 1000)
        return () => { clearInterval(currentTime) }
    }, [])

    useEffect(() => {
        if (selectedBuilding) refetchWeatherData()
    }, [selectedBuilding])

    return (
        <Box className='flex flexAlignItemsCenter'>
            <Box
                sx={{ ...WeatherTempStyles?.weatherBox }}
                onClick={() => { navigate(`/weather/${moment().format('YYYY-MM-DD')}`) }}
            >
                {
                    WIcon >= 0 && <img height={'15px'} src={`https://developer.accuweather.com/sites/default/files/${WIcon}-s.png`} />
                }
                {
                    (tempValue && tempUnit) &&
                    <CustomTypography textContent={`${tempValue}° ${tempUnit || 'C'}`} pr={1} borderRight={`1px solid ${color(themeMode)}`} />
                }
            </Box>
            {
                time &&
                <CustomTypography px={1} borderRight={`1px solid ${color(themeMode)}`} textContent={time} />
            }
            <CustomTypography pl={1} textContent={new Date().toUTCString().slice(5, 17)} />
        </Box>
    )
}

export default WeatherTemp