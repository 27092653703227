import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice';
import userReducer from '../features/userSlice'
import themeReducer from '../features/themeSlice';
import configurationsReducer from '../features/configurationsSlice';
import buildingsReducer from '../features/buildingsSlice';
import cameraReducer from '../features/services/cameraSlice'
import alertsReducer from '../features/services/alertSlice'
import occupancyReducer from '../features/services/occupancySlice';
import faultReportReducer from '../features/services/faultReportSlice';
import aqiReducer from '../features/services/aqiSlice';
import washroomReducer from '../features/services/washroomSlice'
import desksReducer from '../features/services/desksSlice';
import energyReducer from '../features/services/energySlice';
import meetingRoomReducer from '../features/services/meetingRoomsSlice';
import matterportReducer from '../features/services/matterportSlice';
import digitalDocumentationReducer from '../features/services/digitalDocumentationSlice';
import forgeReducer from '../features/services/forgeSlice/forgeReducer';
import currencyReducer from '../features/currencySlice'
import gridReducer from '../features/services/gridSlice';
import feedbackReducer from '../features/services/feedbackSlice';
import dateRangeReducer from '../features/dateRangeSlice';
import timeRangeReducer from '../features/timeRangeSlice';
import reportGenerationReducer from '../features/reportGenerationSlice';
import assetManagementReducer from '../features/services/assetManagementSlice';
import visitorManagementReducer from '../features/services/visitorManagementSlice';
import deviceManagementReducer from '../features/services/deviceManagementSlice';
import hvacReducer from '../features/services/hvacSlice';
import waterReducer from '../features/services/waterConsumptionSlice';
import excludeWeekendReducer from '../features/excludeWeekendSlice'

//Fidelity Reducers
import faultReportSliceFidelity from '../fidelity/features/faultReportSliceFidelity';
import alertSliceFidelity from '../fidelity/features/alertSliceFidelity';
import meetingRoomOccupancySliceFidelity from '../fidelity/features/meetingRoomOccupancySliceFidelity';

//Tata Digital Reducers
import tata_deskBookingReducer from "../../components/tenants/TataDigital/tataRedux/reducers/deskBookingReducer"
import deskBookingSlice from '../../components/tenants/TataDigital/tataRedux/slices/deskBookingSlice';
import feedbackSlice from '../../components/tenants/TataDigital/tataRedux/slices/feedbackSlice';
import mrOccupancySlice from '../../components/tenants/TataDigital/tataRedux/slices/meetingRoomSlice';
import deskBookingZoneWiseSlice from '../../components/tenants/TataDigital/tataRedux/slices/deskBookingZoneWiseSlice';


const store = configureStore({
    reducer: {
        user: userReducer,
        theme: themeReducer,
        configurations: configurationsReducer,
        buildings: buildingsReducer,
        cameraData: cameraReducer,
        alerts: alertsReducer,
        totalOccupancy: occupancyReducer,
        faultReports: faultReportReducer,
        aqi: aqiReducer,
        washroom: washroomReducer,
        desks: desksReducer,
        matterport: matterportReducer,
        energy: energyReducer,
        meetingRooms: meetingRoomReducer,
        forge: forgeReducer,
        digitalDocumentation: digitalDocumentationReducer,
        currency: currencyReducer,
        grid: gridReducer,
        feedback: feedbackReducer,
        dateRange: dateRangeReducer,
        timeRange: timeRangeReducer,
        reportGeneration: reportGenerationReducer,
        assetManagementDashboard: assetManagementReducer,
        visitorManagementDashboard: visitorManagementReducer,
        deviceManagementDashboard: deviceManagementReducer,
        hvac: hvacReducer,
        water: waterReducer,
        excludeWeekend: excludeWeekendReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,

        //Fidelity reducers
        faultReportsV1: faultReportSliceFidelity,
        alertsV1: alertSliceFidelity,
        meetingRoomOccupancyV1: meetingRoomOccupancySliceFidelity,

        //Tata Digital reducers
        tataBookedDesks: tata_deskBookingReducer,
        tataDeskBooking: deskBookingSlice,
        tataZoneWiseDBstats: deskBookingZoneWiseSlice,
        tataFeedback: feedbackSlice,
        tataMeetingRoom: mrOccupancySlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})

export default store;
