//Inject TO the main SDK Data of a tag
// import axios from 'axios'
import { POST } from "../../../../../helpers/http"
// import { CAMERA, CONSTANTS } from './constants';

export const injectToMatterPortSdk = async (mpSdk, sid, CONSTANTS, CAMERA) => {
    const tagPromise = new Promise((resolve, reject) => {
        let tagsWithSandBoxData
        let newInsertData = mpSdk.Tag.data.subscribe({
            // onAdded: async function (index, item) {
            //     if (index === sid && item.sandBoxData) {
            //         console.log(item.sandBoxData)
            //         console.log("1st resolve")
            //         resolve(item)
            //     }
            // },
            // onUpdated(index, item, collection) {
            //     console.log({ index, item, collection })
            // },
            onCollectionUpdated(collection) {
                let timeDiff = collection[sid]?.lastUpdatedTime ? Date.now() - collection[sid]?.lastUpdatedTime : Date.now()
                if (!collection[sid]?.lastUpdatedTime || timeDiff > 180000) {
                    POST(`${process.env.REACT_APP_BASE_URL}twin/v1/unreal/sensors/data?bid=${localStorage.getItem('bid')}`, { uCodes: [`${CONSTANTS[sid]}`] }, "Matterport-API-Call")
                        .then((response) => {
                            if (response.status === 200) {
                                console.log("API Successful")
                                tagsWithSandBoxData = response.data[0]
                                collection[sid].sandBoxAttached = true
                                collection[sid].lastUpdatedTime = Date.now()
                                collection[sid].sandBoxData = tagsWithSandBoxData
                                resolve({ ...collection[sid] })
                            }
                        })
                        .catch((err) => {
                            resolve({ ...collection[sid], sandBoxData: [] })
                            reject(err)
                        })
                }
                // newInsertData.cancel()
            }
        })
    })

    const attachmentPromise = new Promise((resolve, reject) => {
        let attachmentObservable = mpSdk.Tag.attachments.subscribe({
            onAdded: function (index, item) {
                // console.log({ index, item })
            },
            onCollectionUpdated(collection) {
                // console.log({ collection })
                resolve(collection);
                // attachmentObservable.cancel();
            }
        });
    });

    await Promise.all([tagPromise, attachmentPromise])
        .then((values) => {
            replaceMedia(values[0], values[1])
        })
        .catch((err) => {
            console.error('Ooops... the Promise went pear-shaped!', err);
        });

    async function replaceMedia(tagValues, attachmentValues) {
        try {
            const tagSensorData = tagValues.sandBoxData.sensorData
            const tagCode = tagValues.sandBoxData.code
            if (tagSensorData.length === 0) {
                const [sandBoxIdNoData, messanger] = await mpSdk.Tag.registerSandbox(renderNoDataHtml(), {
                    name: `SandBox-${sid}`,
                    size: { w: 0, h: 80 }
                });
                mpSdk.Tag.attach(sid, sandBoxIdNoData)
            } else {
                for (let attachment of tagValues?.attachments) {
                    if (attachmentValues[attachment].src === "tag.attachment.custom") {
                        await mpSdk.Tag.detach(tagValues?.id, attachment)
                    }
                }
                if (CAMERA && Object.keys(CAMERA)?.includes(sid)) {
                    const [sandboxId4, messenger2] = await mpSdk.Tag.registerSandbox(renderCamera(JSON.stringify(CAMERA[sid])), {
                        name: `tag.attachment.custom`,//can be done this way and this will reflect in scr of the attachment 
                        size: { w: 0, h: 220 }  // For now it not dynamically adjusting and should do in the future
                    });
                    mpSdk.Tag.attach(sid, sandboxId4)
                }
                if (tagCode.startsWith("HATSA") || tagCode.startsWith("AIRT") || tagCode.startsWith("GYD")) {
                    const [sandboxId1, messenger1] = await mpSdk.Tag.registerSandbox(renderAQI(JSON.stringify(tagSensorData)), {
                        name: `tag.attachment.custom`,//can be done this way and this will reflect in scr of the attachment 
                        size: tagSensorData.length > 6 ? { w: 0, h: 280 } : { w: 0, h: 200 } // For now it not dynamically adjusting and should do in the future
                    });
                    mpSdk.Tag.attach(sid, sandboxId1)
                }
                if (tagCode.startsWith("PC")) {
                    const [sandboxId3, messenger3] = await mpSdk.Tag.registerSandbox(renderOccupancyData(tagSensorData), {
                        name: `tag.attachment.custom`,//can be done this way and this will reflect in scr of the attachment 
                        size: { w: 0, h: 110 }  // For now it not dynamically adjusting and should do in the future
                    });
                    mpSdk.Tag.attach(sid, sandboxId3)
                }
                if (tagCode.startsWith("PG")) {
                    const [sandboxId5, messenger2] = await mpSdk.Tag.registerSandbox(renderOccupancyData(tagSensorData), {
                        name: `tag.attachment.custom`,//can be done this way and this will reflect in scr of the attachment 
                        size: { w: 0, h: 110 }  // For now it not dynamically adjusting and should do in the future
                    });
                    mpSdk.Tag.attach(sid, sandboxId5)
                }
            }

        } catch (error) {
            console.log("Ooops something went wrong.....", error)
        }
    }

    function renderMeetingRoomBookedStatus() {
        return `
        <style>
        .container {
            border: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .box {
            display: flex;
            height:25px;
            align-items: center;
        }
        .text{
          color:white
        }

        .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 8px;
        }
        .busy {
            background-color: #ff4e5e;
        }

        .free {
            background-color: #02B9A4;
        }
        </style>

        <div class="container">
          <div>
            <div class="box">
              <div class="circle free"></div>
              <p class="text">Booked</p>
          </div>
          <div class="box">
              <div class="circle free"></div>
              <p class="text">Occupied</p>
          </div>
            <div class="box">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.2634 23.9491C29.9681 24.247 32 25.053 32 26.3636V29.1818H28.5M27.9091 21.3636C29.1164 21.3636 30.0836 20.3891 30.0836 19.1818C30.0836 17.9745 29.1164 17 27.9091 17C26.7018 17 25.7273 17.9745 25.7273 19.1818C25.7273 20.3891 26.7018 21.3636 27.9091 21.3636ZM21.0909 21.3636C22.2982 21.3636 23.2655 20.3891 23.2655 19.1818C23.2655 17.9745 22.2982 17 21.0909 17C19.8836 17 18.9091 17.9745 18.9091 19.1818C18.9091 20.3891 19.8836 21.3636 21.0909 21.3636ZM21.0909 23.8182C19.3964 23.8182 16 24.6691 16 26.3636V29.1818H26.1818V26.3636C26.1818 24.6691 22.7855 23.8182 21.0909 23.8182Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <p class="text">0/4</p>
            </div>
          </div>
      </div>
      <script>
        
      </script>
        `
    }

    function renderOccupancyData(tagSensorData) {
        return `
        <style>
        .container {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1px;
          }
          
          .grid-item {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 1px;
          }
          
          .box {
            font-size: 25px;
            font-weight: 600;
            color:#aaaaaa;
            font-family: 'Source Sans Pro', sans-serif,'roboto','lato','poppins',sans-serif;
          }
          
          .typography {
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            color:#aaaaaa;
          }
          
        </style>

        <div class="container">
        <div class="grid-item">
            <svg width="58" height="58" viewBox="0 0 48 48" fill=#aaaaaa xmlns="http://www.w3.org/2000/svg">
                <path d="M28.2634 23.9491C29.9681 24.247 32 25.053 32 26.3636V29.1818H28.5M27.9091 21.3636C29.1164 21.3636 30.0836 20.3891 30.0836 19.1818C30.0836 17.9745 29.1164 17 27.9091 17C26.7018 17 25.7273 17.9745 25.7273 19.1818C25.7273 20.3891 26.7018 21.3636 27.9091 21.3636ZM21.0909 21.3636C22.2982 21.3636 23.2655 20.3891 23.2655 19.1818C23.2655 17.9745 22.2982 17 21.0909 17C19.8836 17 18.9091 17.9745 18.9091 19.1818C18.9091 20.3891 19.8836 21.3636 21.0909 21.3636ZM21.0909 23.8182C19.3964 23.8182 16 24.6691 16 26.3636V29.1818H26.1818V26.3636C26.1818 24.6691 22.7855 23.8182 21.0909 23.8182Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          <div class="box">${tagSensorData[0]?.count}</div>
        </div>
        <div class="grid-item">
          <div class="typography">${tagSensorData[0]?.index === "people_count" ? "Total Occupancy" : "Occupancy"}</div>
        </div>
      </div>
        `
    }


    function renderNoDataHtml() {
        return `
        <style>
        body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
        }
        .message {
            font-family: 'Source Sans Pro', sans-serif,'roboto','lato','poppins',sans-serif;
            font-size: 24px;
            font-weight:400;
            color: white;
        }
    </style>
	</style>
    <div class="message">No Data Found!</div>
    `
    }

    function renderIframe(src) {
        return `<style>
        .iframely-embed { width: 100%;height:100% }
        .iframely-responsive { position: absolute;top: 0;left: 0;width: 100%;height: 100%;overflow:hidden }
        .iframely-responsive > * { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow:hidden }
    </style>
    <div class="iframely-embed">
        <div class="iframely-responsive">
            <iframe srcdoc='
                <!DOCTYPE html>
                <html>
                <head>
                    <title>Highcharts Bar Chart</title>
                    <script src="https://code.highcharts.com/highcharts.js"></script>
                    <script src="https://code.highcharts.com/modules/exporting.js"></script>
                    <script src="https://code.highcharts.com/modules/export-data.js"></script>
                </head>
                <body>
                    <div id="chartContainer" style="height:100%;position:absolute;overflow:hidden;width:100%"></div>
                    <script>
                        Highcharts.chart("chartContainer", {
                            chart: {
                                type: 'line',
                                backgroundColor: white,
                                height: chart.height,
                                margin: [10,0,20,30]
                            },
                            tooltip: {
                                shared:true,
                                split: false
                            },
                            legend: {
                                enabled: false,
                            },
                            accessibility: {
                                enabled: false,
                            },
                            time: {
                                useUTC: false,
                            },
                            exporting: {
                                enabled: false
                            },
                            credits: {
                                enabled: false
                            },
                            title: {
                                text: "Total Occupancy"
                            },
                            xAxis: {
                                type: "datetime",
                                min: new Date().setHours(8, 30, 0),
                                max: new Date().setHours(18, 30, 0),
                                tickInterval: 3600 * 1000,
                                dateTimeLabelFormats: {
                                    hour: '%k',
                                },
                                title: {
                                    text: " ",
                                },
                                labels: {
                                    enabled: true,
                                    autoRotationLimit: 0,
                                    style: {
                                        fontSize: "10px",
                                        color: "white",
                                    },
                                },
                            },
                            yAxis: {
                                title: {
                                    text: ''
                                },
                                labels: {
                                    style: {
                                        fontSize: "10px",
                                        color: "white",
                                    },
                                },
                            },
                            // plotOptions: {
                            //     column: {
                            //         //grouping: false,
                            //         shadow: false,
                            //         borderWidth: 0,
                            //     },
                            //     series: {
                            //         marker: {
                            //             enabled: false,
                            //             states: {
                            //                 hover: {
                            //                     enabled: false,
                            //                 },
                            //             },
                            //         },
                            //         lineWidth: 1
                            //     },
                            // },
                            series: [
                                [
                                    1684901800000,
                                    3
                                ],
                                [
                                    1684902326000,
                                    4
                                ],
                                [
                                    1684902400000,
                                    5
                                ],
                                [
                                    1684902551000,
                                    4
                                ],
                                [
                                    1684902609000,
                                    5
                                ],
                                [
                                    1684903887000,
                                    6
                                ],
                                [
                                    1684904264000,
                                    5
                                ],
                                [
                                    1684905158000,
                                    5
                                ],
                                [
                                    1684905380000,
                                    4
                                ],
                                [
                                    1684906711000,
                                    5
                                ],
                                [
                                    1684907345000,
                                    6
                                ],
                                [
                                    1684907453000,
                                    7
                                ],
                                [
                                    1684907547000,
                                    6
                                ],
                                [
                                    1684907901000,
                                    7
                                ],
                                [
                                    1684908012000,
                                    6
                                ]
                            ]
                        });
                    </script>
                </body>
                </html>
            ' frameborder="0" scrolling="no"  height="100%">
            </iframe>
        </div>
    </div>
    <script>
        const script = document.createElement("script");
        script.src = "//cdn.iframe.ly/embed.js?api_key=${src}";
        script.async = true;
        document.body.appendChild(script);
    </script>`
    }

    function renderAQI(data) {
        return `
            <style>
            .grid-container {
                display: flex;
                flex-wrap: wrap; /* Allow items to wrap to the next line */
                /* Add any additional styling for the grid container */
              }
              
              .grid-item {
                flex: 0 0 33.33%; /* Assuming you want 3 columns */
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px; /* Adjust the value as needed */
                /* Add any additional styling for the grid items */
              }
              
              .box {
                /* Add any additional styling for the box */
              }
              
              .title {
                font-size: 20px;
                font-weight: 600;
                font-family: 'source Sans Pro','Segoe UI', 'roboto', 'lato', 'poppins',sans-serif;
                text-align: center;
                /* Add any additional styling for the title */
              }

              .title.green{
                color:#A0E400
              }

              .title.amber{
                color:#ffa500
              }

              .title.red{
                color:#ff0000
              }

              .title.blue{
                color:#0060a9
              }

              .title.nodata{
                color:#aaaaaa
              }
                     
              .subtitle {
                font-size: 12px;
                text-align: center;
                font-family: 'source Sans Pro','Segoe UI', 'roboto', 'lato', 'poppins',sans-serif;
                color: #aaaaaa;
                /* Add any additional styling for the subtitle */
              }
              </style>
              <div class="grid-container" id="gridContainer"></div>
              <script type="text/javascript">
                    // Get the grid container element
                    const gridContainer = document.getElementById('gridContainer');
                    const data = ${data}
                    data?.map((item,i) => {
                        // Create a new div for each grid item
                        const gridItem = document.createElement('div');
                        gridItem.classList.add('grid-item');
                        
                        // Create the content for the grid item
                        const box = document.createElement('div');
                        box.classList.add('box');
                        
                        const title = document.createElement('h1');
                        title.classList.add('title');
                        title.textContent = item?.value ?item?.value : "NA";

                        if(item?.index === "air_pressure"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value < 1013.3) title.classList.add('amber')
                            else if(item?.value === 1013.3) title.classList.add('green')
                            else if(item?.value > 1013.3) title.classList.add('red')
                        }
                        else if(item?.index === "co2"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value <= 800) title.classList.add('green')
                            else if(item?.value > 800 && item?.value <= 1200) title.classList.add('amber')
                            else if(item?.value > 1200) title.classList.add('red')
                        }
                        else if(item?.index === "humidity"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value <= 40) title.classList.add('amber')
                            else if(item?.value > 40 && item?.value <= 60) title.classList.add('green')
                            else if(item?.value > 60) title.classList.add('red')
                        }
                        else if(item?.index === "illuminance"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value <= 300) title.classList.add('amber')
                            else if(item?.value > 300  && item?.value <= 500) title.classList.add('green')
                            else if(item?.value > 500) title.classList.add('red')
                        }
                        else if(item?.index === "radon"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value <= 100) title.classList.add('green')
                            else if(item?.value > 100 && item?.value <= 150) title.classList.add('amber')
                            else if(item?.value > 150) title.classList.add('red')

                        }
                        else if(item?.index === "temperature"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if(item?.value <= 21) title.classList.add('blue')
                            else if(item?.value >21 && item?.value <=25) title.classList.add("green")
                            else if(item?.value > 25 && item?.value <= 29) title.classList.add('amber')
                            else if(item?.value > 29) title.classList.add('red')
                        }
                        else if(item?.index === "voc"){
                            if(item?.value === null) title.classList.add("nodata")
                            else if (item?.value <= 112) title.classList.add('green')
                            else if(item?.value > 112 && item?.value <= 336) title.classList.add('amber')
                            else if(item?.value > 336) title.classList.add('red')
                        }
    
                        const subtitle = document.createElement('p');
                        subtitle.classList.add('subtitle');
                        subtitle.textContent = item?.name + (item?.units ? "(" + item?.units +")" : "");
    
                        // Append the content to the grid item
                        box.appendChild(title);
                        box.appendChild(subtitle);
                        gridItem.appendChild(box);
                        
                        // Append the grid item to the grid container
                        gridContainer.appendChild(gridItem);
                        
                        // Add a line break after the third grid item
                        if ((i + 1) % 3 === 0) {
                            const lineBreak = document.createElement('br');
                            gridContainer.appendChild(lineBreak);
                        }
                    })
              </script>
        `
    }

    function renderHTML(insertData) {
        return `
            <style>         
            .hotspot-content {
                width: 100%;
                height: auto;
                padding: 0 0;
                margin: 0 0;
                box-sizing: border-box;
                border-radius: 4px;
                display: block;
                font-family: Futura, "Helvetica Neue", sans-serif;
                font-size: 13px;
                font-weight: 400;
                overflow-wrap: break-word;
                width: 100%;
                overflow: hidden;
                background: rgba(0, 0, 0, 0);
                color: #fff;
                text-align: left
            }

            
            .hotspot-content .htp-title {
                white-space: normal;
                word-break: break-word;
                text-align: left;
                line-height: 1.6;
                font-family: "Montserrat-Bold";
                font-size: 15px
            }
            
            .hotspot-content .htp-title .mini-title {
                font-size: 13px;
                font-family: "Montserrat-Medium"
            }
            
            .hotspot-content ul {
                width: 100%;
                height: auto;
                padding: 0 0;
                margin: 0 0;
                box-sizing: border-box;
                padding-left: .1em;
                list-style-type: none
            }
            
            .hotspot-content ul li {
                width: 100%;
                height: auto;
                padding: 5px 0;
                margin: 0 0 0 0;
                box-sizing: border-box
            }
            
            .hotspot-content .device-data-list .m-name {
                padding-right: .3em;
                font-size: 13px
            }
            
            .hotspot-content .device-data-list .m-name:after {
                content: ":"
            }
            
            .hotspot-content .device-data-list .m-value {
                color: #03f2b2;
                font-size: 14px
            }
            
            .hotspot-content .device-data-list .m-unit {
                color: #03f2b2
            }
            
            .hotspot-content .device-data-list .m-time {
                display: none
            }
            
            .hotspot-content .device-data-list .device-attr:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                width: 5px;
                height: 5px;
                overflow:auto;
                padding: 0 0;
                margin: 0 0;
                box-sizing: border-box;
                background-color: #03f2b2;
                margin-right: 8px
            }
            
            .hotspot-content .device-data-list .device-attr:last-child:after {
                display: block;
                content: attr(update-time);
                font-size: 12px;
                width: 100%;
                height: auto;
                padding: 0 0;
                margin: 0 0;
                overflow:auto;
                box-sizing: border-box;
                text-align: center;
                font-style: italic;
                margin-top: 1em;
                font-family: sans-serif;
                color: #aaa
            }
            
            .hotspot-content.active {
                top: calc(100% + 1em);
                opacity: 1;
                max-height: -moz-max-content;
                max-height: max-content
            }
            
            .hotspot-content .spot-img {
                width: 100%;
                height: auto;
                padding: 0 0;
                margin: 0 0;
                box-sizing: border-box
            }
            
            .hotspot-content .spot-img img {
                max-width: 100%;
                -o-object-fit: contain;
                object-fit: contain
            }</style>
            <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
            <div class="hotspot-content" >
				<ul class="device-data-list" id="showList">
                </ul>
			</div>
            <script type="text/javascript">
            const data = ${insertData}
            const deviceDataList = document.getElementById("showList")
            if(data.length > 1){
                data.map((item,i) => {
                    const li = document.createElement("li");
                    li.classList.add("device-attr");
                    const nameSpan = document.createElement("span");
                    const valueSpan = document.createElement("span");
                    const unitSpan = document.createElement("span");
                    nameSpan.textContent = item?.name;
                    nameSpan.setAttribute('class',"m-name");
                    // nameSpan.classList.add("m-name");
                    valueSpan.textContent = item?.value ? item?.value : "NA";
                    valueSpan.setAttribute('class',"m-value");
                    // valueSpan.classList.add("m-value");
                    unitSpan.textContent = item?.units;
                    unitSpan.setAttribute('class',"m-unit");
                    // unitSpan.classList.add("m-unit");
                    li.appendChild(nameSpan);
                    li.appendChild(valueSpan);
                    li.appendChild(unitSpan);
                    // if(i===data.length - 1){
                    //     li.setAttribute("update-time",Date.now())
                    // }
                    deviceDataList.appendChild(li);
                })
            }else{
                const li = document.createElement("li");
                li.classList.add("device-attr");
                const nameSpan = document.createElement("span");
                const valueSpan = document.createElement("span");
                const unitSpan = document.createElement("span");
                nameSpan.textContent = data[0].name;
                nameSpan.setAttribute('class',"m-name");
                valueSpan.textContent = data[0].count;
                valueSpan.setAttribute('class',"m-value");
                li.appendChild(nameSpan);
                li.appendChild(valueSpan);
                // li.setAttribute("update-time",Date.now())
                deviceDataList.appendChild(li);
            }
            </script>
    `
    }

    function renderCamera(url) {
        return `
        <style>
        video {
            max-width: 100%;
            height: 90%;
          }
        </style>
        <head>
        <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
        </head>
        <video id="videoPlayer" sty autoplay=true muted=true loop=false controls>
        </video>
        <script>
            var videoPlayer = document.getElementById('videoPlayer');
            if (Hls.isSupported()) {
            var hls = new Hls();
            const url = ${url}
            hls.loadSource(url);
            hls.attachMedia(videoPlayer);
            } else if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
            videoPlayer.src = url;
            }
        </script>
        `
    }
}