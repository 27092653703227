import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails(state, action) {
            return state = action.payload
        },
        resetUserDetails(state, action) {
            return state = initialState
        }
    }
})

export const { setUserDetails, resetUserDetails } = userSlice.actions

export default userSlice.reducer