import React, { useEffect, useState } from 'react'
import { Box, Paper, Tab, Tabs, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import TabContainerStyles from '../../styles/TabStyles';

const TabContainer = (props) => {
    const { tabList, tabPanels, initialTab, className } = props
    const theme = useTheme()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const paperStyle = {
        bgcolor,
        borderRadius: '8px',
        boxShadow: 0
    }

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (initialTab) {
            const index = tabList.indexOf(initialTab);
            if (index !== -1) {
                setValue(index);
            }
        }
    }, [initialTab, tabList]);


    const colorFunc = (index) => {
        if (themeMode) {
            if (value === index) {
                return theme.palette.common.white
            }
            else {
                return theme.palette.grey['500']
            }
        }
        else {
            if (value === index) {
                return theme.palette.common.black
            }
            else {
                return theme.palette.grey['500']
            }
        }
    }
    return (
        <Box sx={TabContainerStyles.boxContainer} typography={'body1'}>
            <Box sx={TabContainerStyles.boxItem} >
                <Paper elevation={0} sx={{ ...TabContainerStyles.paperContainer, ...paperStyle, }} className={`theme-cards-boxShadow theme-border bdrRadius4px ${className}`}> {/*borderRadius: `0px 0px 4px 4px`*/}
                    <Tabs onChange={handleChange} sx={TabContainerStyles.tabs} textColor="inherit" value={value}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#D97D54',
                                marginBottom: 1,
                            },
                        }}>
                        {
                            tabList.map((title, index) => {
                                return (<Tab key={index} sx={{ ...TabContainerStyles.tabList, color: colorFunc(index), fontWeight: value === index ? 'bold' : '' }} label={title} value={index} />
                                )
                            })
                        }
                    </Tabs>
                </Paper>
            </Box>
            {
                Array.isArray(tabPanels) ?
                    <>
                        {value === 0 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[0]}
                            </Box>
                        )}
                        {value === 1 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[1]}
                            </Box>
                        )}
                        {value === 2 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[2]}
                            </Box>
                        )}
                        {value === 3 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[3]}
                            </Box>
                        )}
                        {value === 4 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[4]}
                            </Box>
                        )}
                        {value === 5 && (
                            <Box sx={{ pt: 1 }}>
                                {tabPanels[5]}
                            </Box>
                        )}
                    </>
                    : tabPanels()
            }
        </Box>
    )
}

export default TabContainer