import { Avatar, Box, Chip, Button } from "@mui/material"
import ImageRenderer from "../../../../../components/image/ImageRenderer"
import { profilePicture } from "../../../../../../../data/imageUrls"
import moment from "moment"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { useSelector } from "react-redux";
import { useState } from "react";
import commentsStyle from "../../../../../styles/wall/contentReviewBody/CommentStyle";

const SubComment = ({ comment, replies, viewRepliesHandle, manageComment }) => {
    const wallFileReadUrl = useSelector(state => state?.configurations?.wallFileReadUrl)
    const [readmore, setReadmore] = useState({})


    const hanldeCommentReadMore = (commentId) => {
        setReadmore({ ...readmore, [commentId]: true })
    }

    const hanldeCommentReadLess = (commentId) => {
        setReadmore({ ...readmore, [commentId]: false })
    }

    return (
        <>
            <Box sx={{ marginLeft: "2.5rem" }}>
                {replies?.map((rep) => {
                    return (
                        <Box key={rep?._id} className="flex flexDirectionRow padtop15">
                            <Box
                                className="width15 flex flexJustifyCenter">
                                {
                                    rep?.user?.profilePictureUrl !== "" ?
                                        <Avatar alt={`${rep?.user?.fullName}'s img`} src={`${wallFileReadUrl}${rep?.user?.profilePictureUrl}`} /> :
                                        <Avatar>

                                            <ImageRenderer src={profilePicture?.src} alt={profilePicture?.alt} />
                                        </Avatar>
                                }
                            </Box>
                            {/* Name and Comments */}
                            <Box className="flex flexDirectionCol flexJustifyEvenly width100">
                                <Box className="flex flexDirectionRow flexJustifyStart gap16">
                                    <Box className="fontweight600 font14">
                                        {rep?.user?.fullName}
                                    </Box>
                                    <Box className="font14 color-grey">
                                        {moment(rep?.createdAt).fromNow()}
                                    </Box>
                                    <Chip
                                        sx={{ marginLeft: "1rem" }}
                                        size="small"
                                        label={
                                            <Box sx={{
                                                display: "flex",
                                                gap: "0.5rem",
                                                color: "#ef5350",
                                                alignItems: "center"
                                            }}>
                                                <FlagOutlinedIcon
                                                    sx={{ color: "#ef5350", height: "20px" }} />
                                                {rep?.flagCount}
                                            </Box>
                                        } />
                                    {rep?.isDeleted &&
                                        <Chip
                                            size="small"
                                            label={
                                                <Box className="flex flexDirectionRow color-grey">
                                                    Deleted
                                                    <DeleteOutlineOutlinedIcon fontSize="small" />
                                                </Box>}
                                        />}
                                </Box>
                                <Box className="font14">
                                    {rep?.textContent?.length > 80 && !readmore[rep?._id]
                                        ?
                                        <Box sx={{
                                            overflowWrap: "break-word",
                                            maxWidth: "16vw"
                                        }}>
                                            {rep?.textContent?.slice(0, 80) + "..."}
                                            <Box component="span" className="fontweight600 cursorPtr"
                                                onClick={() => hanldeCommentReadMore(rep?._id)}
                                            ><i>readmore</i></Box>
                                        </Box>
                                        :
                                        <Box sx={{
                                            overflowWrap: "break-word",
                                            maxWidth: "16vw"
                                        }}>
                                            {rep?.textContent}
                                            {readmore[rep?._id] &&
                                                <Box
                                                    component="span"
                                                    className="cursorPtr fontweight600"
                                                    onClick={() => hanldeCommentReadLess(rep?._id)}
                                                ><i>show less</i></Box>}
                                        </Box>
                                    }
                                </Box>
                                <Box className="flex flexJustifyEnd flexAlignItemsCenter">
                                    {!rep?.isDisabled ? <Button
                                        className="txtTransformCaps height25px"
                                        sx={{ ...commentsStyle.buttonStyle }}
                                        onClick={() => manageComment({
                                            action: "disable",
                                            id: rep?._id,
                                            type: "reply"
                                        })}
                                    >
                                        Disable
                                    </Button>
                                        :
                                        <Button
                                            className="txtTransformCaps height25px"
                                            sx={{ ...commentsStyle.buttonStyle }}
                                            onClick={() => manageComment({
                                                action: "enable",
                                                id: rep?._id,
                                                type: "reply"
                                            })}
                                        >
                                            Enable
                                        </Button>
                                    }
                                    {!rep?.isDeleted ? <Button
                                        className="txtTransformCaps height25px"
                                        sx={{ ...commentsStyle.buttonStyle }}
                                        onClick={() => manageComment({
                                            action: "delete",
                                            id: rep?._id,
                                            type: "reply"
                                        })}
                                    >
                                        Delete
                                    </Button>
                                        :
                                        <Button
                                            className="txtTransformCaps height25px"
                                            sx={{ ...commentsStyle.buttonStyle }}
                                            onClick={() => manageComment({
                                                action: "restore",
                                                id: rep?._id,
                                                type: "reply"
                                            })}
                                        >
                                            Restore
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    )
                })
                }
                {replies.length < comment?.childCount && replies.length !== 0
                    &&
                    <Box
                        className="color-grey txtUnderline txtTransformLow cursorPtr"
                        onClick={() => viewRepliesHandle({ relatedTo: comment?.relatedTo, parentId: comment?._id, type: "subcomment" })}
                    >
                        View more
                    </Box>
                }
            </Box>
        </>
    )
}

export default SubComment