import React, { useEffect, useState } from 'react'
import { GET } from '../../helpers/http'
import { Box, Chip, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import Loader from '../../components/loading/Loader'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import EditPPMModal from './EditPPMModal'
import DeletePPMModal from './DeletePPMModal'
import useApiCalls from './components/hooks/useApiCalls'
import PPMUpdate from './PPMUpdate'
import { statusColors } from './colorConstants'

const PPMView = ({ selectedPPMId, setCalendarEvents, triggerList, selectedDateRange, handleCloseViewPPMModal = () => { }, handleEditPPM }) => {
    const [ppm, setPpm] = useState({
        isLoaded: false, data: {}
    })
    const { fetchSinglePpm } = useApiCalls()
    const [openUpdatePPMModal, setOpenUpdatePPMModal] = useState(false)

    const [openDeletePPMModal, setOpenDeletePPMModal] = useState(false)
    // const [editOptionModalOpen, setEditOptionModalOpen] = useState(false)
    // const [selectedUpdatePPMOption, setSelectedUpdatePPMOption] = useState("")
    // const [editOptions, setEditoptions] = useState({})

    const taskStatus = ppm?.data?.taskStatus?.toLowerCase();
    const statusStyle = statusColors[taskStatus] || {};

    const handleOpenUpdatePPMModal = () => {
        setOpenUpdatePPMModal(true)
    }

    const handleCloseUpdatePPMModal = () => {
        setOpenUpdatePPMModal(false)
    }

    const handleOpenDeletePPMModal = () => {
        setOpenDeletePPMModal(true)
    }

    const handleCloseDeletePPMModal = () => {
        setOpenDeletePPMModal(false)
    }

    const capitalizeFirst = (word) => {
        if (!word) { // Check if the word is empty
            return "";
        }
        return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);
    }

    useEffect(() => {
        fetchSinglePpm({ selectedPPMId, setPpm })
        // const fetchEditingPPM = async () => {
        //     try {
        //         const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${selectedPPMId}`, 'Fetch PPM by Id')
        //         setPpm({ isLoaded: true, data })
        //     } catch (error) {
        //         console.log({ error })
        //         setPpm({ isLoaded: true, data: {} })
        //     }
        // }
        // fetchEditingPPM()
    }, [selectedPPMId])

    // const handleEditPPM = async ({ ppmId, isRecursive }) => {
    //     try {
    //         const updateOptionsUrl = `${process.env.REACT_APP_URL}ppm/v1/update/options?type=update`
    //         const { data = {}, status } = await GET(updateOptionsUrl, 'Get PPM Update Option')

    //         if (status === 200) {
    //             if (isRecursive) {
    //                 setEditoptions(data)
    //                 setEditOptionModalOpen(true)
    //                 // handleCloseViewPPMModal(false)
    //                 // setupdatingPPM(ppmId)
    //             } else {
    //                 setOpenUpdatePPMModal(true)
    //                 // setupdatingPPM(ppmId)
    //             }
    //         }
    //     } catch (error) {
    //         console.log({ error })
    //     }
    // }

    // const updateOptionPPM = async () => {
    //     try {
    //         setEditOptionModalOpen(false)
    //         setOpenUpdatePPMModal(true)
    //     } catch (error) {
    //         console.log({ error })
    //     }
    // }

    return (
        ppm?.isLoaded ? <>
            <Stack spacing={2} maxHeight={400} overflow={'auto'}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontWeight={'bold'} fontSize={'20px'}>{ppm?.data?.title}</Typography>
                    {!moment(ppm?.data?.date).isBefore(moment().startOf('day')) && <Box>
                        <Tooltip title='EDIT'>
                            <IconButton
                                onClick={() => handleEditPPM({ ppmId: ppm?.data?.id, isRecursive: ppm?.data?.isRecursive })}
                                sx={{ color: '#dc4d28' }}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE'>
                            <IconButton
                                onClick={handleOpenDeletePPMModal}
                                sx={{ color: '#dc4d28' }}
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>
                    </Box>}
                </Stack>
                {ppm?.data?.description && <Typography>{ppm?.data?.description}</Typography>}
                <Typography>{`${ppm?.data?.building?.name || ''}${ppm?.data?.area?.name ? ` - ${ppm?.data?.area?.name}` : ''}${ppm?.data?.area?.floor?.name ? ` - ${ppm?.data?.area?.floor?.name}` : ''}`}</Typography>
                <Typography>{moment(ppm?.data?.date).format('DD MMM YYYY')} ({ppm?.data?.startTime} - {ppm?.data?.endTime})</Typography>
                {ppm?.data?.category?.name && <Typography>Category: {ppm?.data?.category?.name}</Typography>}
                {ppm?.data?.assetRef?.name && <Typography>Asset: {ppm?.data?.assetRef?.name}</Typography>}
                {ppm?.data?.assignee?.name && <Typography>Assigned to: {ppm?.data?.assignee?.name} - {ppm?.data?.assignedVendor?.name}</Typography>}
                {ppm?.data?.taskStatus && <Typography>Status :
                    {/* <span style={{
                    backgroundColor: statusStyle.bg,
                    border: `2px solid ${statusStyle.br}`,
                    borderRadius: '12px',
                    padding: '5px',
                    display: 'inline-block'
                }}
                >{ppm?.data?.taskStatus}
                </span> */}
                    <Chip size="small"
                        sx={{
                            display: "inline",
                            marginLeft: "10px",
                            padding: "5px",
                            width: "50%",
                            border: `solid 1px ${statusStyle.br}`,
                            backgroundColor: statusStyle.bg,
                            "& .MuiChip-label": {
                                color: statusStyle.br
                            }
                        }}
                        label={capitalizeFirst(ppm?.data?.taskStatus)} />
                </Typography>}
                <Typography>Priority: {ppm?.data?.priority}</Typography>
                {ppm?.data?.isRecursive && <>
                    <Typography>Repeats every: {ppm?.data?.recursiveConfiguration?.interval} {ppm?.data?.recursiveConfiguration?.frequency}</Typography>
                    <Typography>Until: {ppm?.data?.recursiveConfiguration?.until} {(!ppm?.data?.recursiveConfiguration?.avoidSaturday && !ppm?.data?.recursiveConfiguration?.avoidSunday) ? 'except saturdays and sundays' : (ppm?.data?.recursiveConfiguration?.avoidSaturday && !ppm?.data?.recursiveConfiguration?.avoidSunday) ? 'except saturday' : (ppm?.data?.recursiveConfiguration?.avoidSaturday && !ppm?.data?.recursiveConfiguration?.avoidSunday) ? 'except sunday' : ''}</Typography>
                </>}
            </Stack>
            {/* <Modal
                modalOpen={editOptionModalOpen}
                closeModal={() => setEditOptionModalOpen(false)}
                title={'Update PPM'}>
                <PPMUpdate
                    selectedUpdatePPMOption={selectedUpdatePPMOption}
                    setSelectedUpdatePPMOption={setSelectedUpdatePPMOption}
                    updatePPMOptions={editOptions}
                    updatePPM={updateOptionPPM}
                />
            </Modal> */}
            <EditPPMModal
                triggerList={triggerList}
                openUpdatePPMModal={openUpdatePPMModal}
                handleCloseUpdatePPMModal={handleCloseUpdatePPMModal}
                editingPPMId={ppm?.data?.id}
                handleCloseViewPPMModal={handleCloseViewPPMModal}
                setCalendarEvents={setCalendarEvents}
                selectedDateRange={selectedDateRange}
            />
            <DeletePPMModal
                triggerList={triggerList}
                openDeletePPMModal={openDeletePPMModal}
                handleCloseDeletePPMModal={handleCloseDeletePPMModal}
                deletingPPMId={ppm?.data?.id}
                handleCloseViewPPMModal={handleCloseViewPPMModal}
                setCalendarEvents={setCalendarEvents}
                selectedDateRange={selectedDateRange}
            />
        </> : <Loader height={200} />
    )
}

export default PPMView