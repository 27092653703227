import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../../hooks/ThemeColors';

const DropDown = ({ formErrors, name, required, label, handleDropDownChange, options, setState, defaultValue }) => {
    const { commonSX, textColor } = ThemeColors();
    const [selectedOption, setSelectedOption] = useState('')
    

    const handleChange = (e) => {
        setSelectedOption(e.target.value)
        handleDropDownChange(e, setState)
    }

    useEffect(() => {
        setSelectedOption(defaultValue)
    }, [defaultValue])

    return (
        <Box sx={{ minWidth: '100%' }}>
            <FormControl
                fullWidth
                size='small'
                sx={{
                    ...commonSX,
                    '& .MuiFormLabel-root': {
                        color: textColor()
                    },
                    '& .MuiSvgIcon-root': {
                        color: textColor()
                    },
                }}
                error={Boolean(formErrors)}>
                <InputLabel required={required} id={label}>{label}</InputLabel>
                <Select
                    sx={{
                        '& .MuiInputBase-input': {
                            color: `${textColor()} !importanvaluet`
                        }
                    }}
                    MenuProps={{
                        disableScrollLock: true
                    }}
                    labelId={label}
                    id={label}
                    name={name}
                    value={selectedOption}
                    label={label}
                    onChange={handleChange}
                >
                    {options && options?.map(({ _id, name }) => {
                        return <MenuItem value={_id ? _id : name} key={_id ? _id : name}>{name}</MenuItem>
                    })}
                </Select>
                {formErrors && <FormHelperText>{formErrors}</FormHelperText>}
            </FormControl>
        </Box>
    )
}

export default memo(DropDown)