import { Box, Typography } from '@mui/material'
import React from 'react'

const DataView = (props) => {
    const { data, bgcolor, statusIndicator, fontWeight } = props

    return (
        <Box display='flex' justifyContent='space-between' >
            <Typography lineHeight={2} fontWeight={fontWeight}>{data}</Typography>
            {statusIndicator && <Box width={'30px'} borderRadius={'4px'} bgcolor={bgcolor} height={'15px'} mt={0.75}></Box>}
        </Box>
    )
}

export default DataView
