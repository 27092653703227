import { Typography } from '@mui/material'
import React from 'react'
import { ThemeColors } from '../../hooks/ThemeColors'

const CustomTypography = (props) => {
    const { textContent, color, children, ...rest } = props
    const { textColor } = ThemeColors()
    return (
        <Typography color={color || textColor()} {...rest}> {textContent || children}</Typography >
    )
}

export default CustomTypography