import { AddCircleOutline } from "@mui/icons-material"
import { Box, styled } from "@mui/material"
import { ThemeColors } from "../../hooks/ThemeColors";



const AddMediaCard = ({ handleModalOpen, type, handleAddImage, sx, id }) => {
    const { themeMode } = ThemeColors();
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <Box
            component='label'
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '1rem',
                border: '1px solid lightgray',
                backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                ...sx
            }}>
            <Box
                onClick={type === "view" ? () => handleModalOpen(true) : null}
                sx={{
                    display: 'flex',
                    alignItems: 'center', width: '100%', height: '100%'
                }}>
                <AddCircleOutline sx={{ fontSize: '3rem', width: "100%" }} />
                {type === "add" && <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleAddImage({ event, id })}
                />}
            </Box>
        </Box >
    )
}

export default AddMediaCard