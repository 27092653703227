

const postStatusBarStyle = {
    card: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        boxShadow: "0",
        height: "6vh",
    },
    typoBox: {
        display: "flex",
        flexDirection: "row",
        gap: "2rem",
        alignItems: "center",
        marginX: "1rem"
    },
    deleteButton: {
        backgroundColor: "#ef5350",
        color: "#fff",
        marginY: "0.5rem",
        marginX: "1rem",
        "&:hover": {
            backgroundColor: "#ef5350"
        }
    }
}

export default postStatusBarStyle