import { useEffect, useState } from "react";
import { DELETE, GET } from "../../../helpers/http";
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Pagination, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import CustomComponentContainer from "../../../components/container/CustomComponentContainer";
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles";
import { useNavigate } from "react-router-dom";
import { DeleteOutline, EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { ThemeColors } from "../../../hooks/ThemeColors";
import FormInput from "../../../components/formInputs/FormInput";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../../helpers/apiToast";
import swal from 'sweetalert'


const HrDocumentList = () => {
    const [filters, setFilters] = useState({
        locations: [],
        buildings: [],
        departments: [],
        categories: [],
    });
    const [selectedFilters, setSelectedFilters] = useState({
        locations: [],
        buildings: [],
        departments: [],
        categories: [],
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate()
    const { commonSX, background_color, textColor } = ThemeColors();
    const themeMode = useSelector(state => state.theme.darkTheme)
    const [refreshPage, setRefreshPage] = useState(false)

    // Fetch filter options from API
    useEffect(() => {
        const fetchFilters = async () => {
            const locationPromise = GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/location/list`, 'Get All Locations');
            const buildingPromise = GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list`, 'Get All Buildings');
            const departmentPromise = GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, 'Get All Departments');
            const categoryPromise = GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, 'Get All Categories');

            try {
                const [locationResponse, buildingResponse, departmentResponse, categoryResponse] = await Promise.all([
                    locationPromise,
                    buildingPromise,
                    departmentPromise,
                    categoryPromise,
                ]);

                // Assuming each response contains a data property with the relevant array
                setFilters({
                    locations: locationResponse?.data || [],
                    buildings: buildingResponse?.data || [],
                    departments: departmentResponse?.data || [],
                    categories: categoryResponse?.data || [],
                });
            } catch (error) {
                console.error('Error fetching filters:', error);
                // Set each filter to an empty array if there's an error
                setFilters({
                    locations: [],
                    buildings: [],
                    departments: [],
                    categories: [],
                });
            }
        };

        fetchFilters();
    }, []);

    // Function to fetch items based on filters and search term
    const fetchItems = async ({ selectedFilters, searchTerm, page }) => {
        const fetchEndpointUrl = `${process.env.REACT_APP_BASE_URL}um/v1/policy-docs/list?`;
        let filterString = '';
        for (const filterType in selectedFilters) {
            if (selectedFilters[filterType]?.length > 0) {
                filterString += `${filterType}=${selectedFilters[filterType].join(',')}&`;
            }
        }

        if (searchTerm) filterString += `searchTerm=${encodeURIComponent(searchTerm)}&`;
        filterString += `page=${page}`; // Ensure page is always added

        const endpoint = fetchEndpointUrl + filterString;

        try {
            const response = await GET(endpoint, 'Get All HR Documents');
            if (response?.data) {
                setTotalCount(response.data.totalCount); // Set total count from response
                if (response.data.pageItems.length === 0) {
                    setHasMoreItems(false);
                    setItems([]); // Clear items if no items found
                } else {
                    setHasMoreItems(true);
                    setItems(response.data.pageItems); // Replace items with new fetched items
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            setHasMoreItems(false); // Handle error by disabling further fetching
        }
    };


    useEffect(() => {

        // without debouncing
        // fetchItems({ selectedFilters, searchTerm, page });

        // with debouncing
        const controller = new AbortController();
        const waitFetch = setTimeout(() => {
            fetchItems({ selectedFilters, searchTerm, page });
        }, 500);

        return () => {
            controller.abort();
            clearTimeout(waitFetch);
        };

    }, [selectedFilters, searchTerm, page, refreshPage]);

    // Handle checkbox change
    const handleFilterChange = (filterType, id) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [filterType]: prev[filterType].includes(id)
                ? prev[filterType].filter((filterId) => filterId !== id)
                : [...prev[filterType], id],
        }));
    };

    const handleSearchTermChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value)
    }

    const handleApplyFilters = (e) => {
        e.preventDefault();
        setPage(1); // Reset to first page
        setItems([]); // Clear existing items
        fetchItems({ selectedFilters, searchTerm, page: 1 });
    }

    // Calculate items to display based on current page
    const itemsPerPage = 10; // Define how many items to show per page
    const totalPages = Math.ceil(totalCount / itemsPerPage);

    const handleCreateDocument = () => {
        navigate('/admin/settings/hr-docs/add')

    }

    const handleView = (item) => {
        navigate(`/admin/settings/hr-docs/view/${item?._id}`)
    }

    const handleEdit = (item) => {
        navigate(`/admin/settings/hr-docs/edit/${item?._id}`)

    }


    const handleDelete = async (item) => {
        swal({
            title: 'Are you sure?',
            text: 'Delete Document',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                try {
                    await DELETE(
                        `${process.env.REACT_APP_BASE_URL}um/v1/policy-docs/?id=${item._id}`,
                        "Delete the Document"
                    );
                    successToast("Document Deleted Successfully");
                    setRefreshPage(!refreshPage)
                } catch (error) {
                    errorToast("There was an error deleting the document.");
                    console.log(error);
                }
            }
        }).catch(error => {
            console.log(error);
            errorToast("There was an error processing the request.");
        });
    };

    return (
        <Box sx={{
            ...DepartmentSettingsStyles.boxContainer,
            backgroundColor: '#f4f6f9',
            borderRadius: 3,
            padding: 3,
            minHeight: '90vh',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            backgroundColor: background_color(),
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center", // Align content vertically
                    width: "100%",
                    padding: 2, // Optional padding for spacing
                    backgroundColor: background_color(),
                }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 600,
                        // color: '#2c3e50',
                        color: textColor()

                    }}
                >
                    Documents And Policies
                </Typography>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: "#dc4d28",
                        "&:hover": { backgroundColor: "#c43e2d" },
                        width: "10%", // Set fixed width
                        height: "100%", // Set fixed height
                        marginLeft: "auto", // Aligns to the right
                    }}

                    onClick={handleCreateDocument}>
                    Create
                </Button>

            </Box>

            <Grid container spacing={3} >
                <Grid item xs={12} md={4}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: 3,
                            borderRadius: 3,
                            backgroundColor: 'white',
                            height: '80vh',
                            overflowY: 'auto',
                            backgroundColor: background_color(),
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                marginBottom: 3,
                                gap: 2,
                                alignItems: 'center'
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent default form submission
                                    handleApplyFilters(e); // Call your filter logic
                                }
                            }}
                        >
                            <FormInput
                                label="Search Documents"
                                variant="outlined"
                                fullWidth
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                    },
                                    backgroundColor: background_color(),
                                    ...commonSX,
                                    color: textColor()
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApplyFilters}
                                sx={{
                                    borderRadius: 2,
                                    backgroundColor: '#dc4d28',
                                    '&:hover': { backgroundColor: '#c43e2d' },
                                    padding: '14px 20px',
                                    textTransform: 'none',
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                                }}
                            >
                                Apply
                            </Button>
                        </Box>


                        {Object.keys(filters).map((filterType) => (
                            <Box key={filterType} sx={{ marginBottom: 3, backgroundColor: background_color(), }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 600,
                                        // color: '#2c3e50',
                                        color: textColor(),
                                        marginBottom: 2,
                                        borderBottom: '2px solid grey',
                                        paddingBottom: 1
                                    }}
                                >
                                    {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                                </Typography>
                                <Box
                                    sx={{
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                        backgroundColor: '#f9fafb',
                                        borderRadius: 2,
                                        padding: 1,
                                        backgroundColor: background_color(),
                                    }}
                                >
                                    {filters[filterType].map((filter) => (
                                        <FormControlLabel
                                            key={filter._id}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={selectedFilters[filterType].includes(filter._id)}
                                                    sx={{
                                                        color: '#DC4D28', // Default color
                                                        '&.Mui-checked': {
                                                            color: '#DC4D28', // Color when checked
                                                        },
                                                        ...commonSX
                                                    }}
                                                    onChange={() => handleFilterChange(filterType, filter._id)}
                                                />
                                            }
                                            label={filter?.name}
                                            sx={{
                                                display: 'block',
                                                marginBottom: 1,
                                                '&:hover': {
                                                    backgroundColor: themeMode ? '#42424242' : '#f0f0f0',
                                                    // backgroundColor: background_color(),
                                                    borderRadius: 1
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: 3,
                            borderRadius: 3,
                            backgroundColor: 'white',
                            height: '80vh',
                            overflowY: 'auto',
                            backgroundColor: background_color(),
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 600,
                                // color: '#2c3e50',
                                color: textColor(),
                                marginBottom: 3,
                                borderBottom: '2px solid grey',
                                paddingBottom: 1
                            }}
                        >
                            {/* {`Documents List = ${items?.length}`} */}
                            Documents List
                        </Typography>
                        {items.length > 0 ? (
                            items.map((item) => (
                                <Card
                                    key={item?._id}
                                    sx={{
                                        marginBottom: 2,
                                        borderRadius: 3,
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0 4px 10px rgba(0,0,0,0.1)'
                                        },
                                        backgroundColor: background_color(),
                                    }}
                                    elevation={2}
                                // onClick={() => { handleView(item) }}
                                >
                                    <CardContent>
                                        <Stack sx={{ display: "flex", flexDirection: "row", gap: 2, width: '100%', backgroundColor: background_color(), }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    // color: '#2c3e50',
                                                    color: themeMode ? textColor() : '#2c3e50',
                                                    fontWeight: 600,
                                                    marginBottom: 1
                                                }}
                                            >
                                                {item?.title}
                                            </Typography>
                                            <Box sx={{
                                                flexGrow: 1, // This makes the box take up the remaining space
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "1rem",
                                                justifyContent: "flex-end",
                                                // border: "1px solid red",
                                            }}>
                                                <Tooltip title='EDIT' placement='top'>
                                                    <IconButton
                                                        onClick={() => handleEdit(item)}
                                                    >
                                                        <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />

                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title='VIEW' placement='top'>
                                                    <IconButton
                                                        onClick={() => { handleView(item) }}
                                                    >
                                                        <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />

                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" placement="top">
                                                    <IconButton
                                                        aria-label="delete" onClick={() => handleDelete(item)}>
                                                        <DeleteOutline fontSize="small" sx={{ color: '#dc4d28 !important' }} />
                                                    </IconButton>
                                                </Tooltip>

                                            </Box>



                                        </Stack>

                                        {item?.tags?.length > 0 && (
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    backgroundColor: themeMode ? '#75757575' : '#f1f3f5',
                                                    padding: 1,
                                                    borderRadius: 2
                                                }}
                                            >
                                                {item.tags.join(', ')}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#7f8c8d',
                                    textAlign: 'center',
                                    marginTop: 5
                                }}
                            >
                                No documents found
                            </Typography>
                        )}
                        {hasMoreItems && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 3
                            }}>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={(event, value) => {
                                        setPage(value);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{
                                        '& .MuiPaginationItem-root': {
                                            borderRadius: 2,
                                            color: textColor()
                                        },
                                        backgroundColor: background_color(),

                                    }}
                                />
                            </Box>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HrDocumentList