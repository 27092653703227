import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import DataView from '../../../components/widgets/dashboard/DataView'
import moment from 'moment'
import { formatMinutesToHours } from '../../../helpers/formatMinutesToHours'

const DVRComp = (props) => {
    let { data } = props

    data = {
        cpu: data?.cpu,
        activeCameras: data?.activeCameras,
        inactiveCameras: data?.inactiveCameras,
        time: data?.time,
        memory: data?.memory,
        camerasConnected: data?.camerasConnected,
    }

    return (
        <Stack height={'80%'} mx={2} maxHeight={'40vh'} overflow={'auto'}>
            <Box>
                {
                    Object.keys(data)?.length > 0 &&
                    Object.keys(data)?.map((ele, i) => (
                        <DataView
                            key={i}
                            data={
                                ele === "time" ? (data?.time?.differenceInMinutes > 5 && data?.time?.inSync === false) ?
                                    <>
                                        <Typography lineHeight={2}><strong>Device Time</strong>: {moment(data?.time?.deviceTimeStamp).format('lll')}</Typography>
                                        <Typography lineHeight={2}><strong>Time Difference</strong>: {formatMinutesToHours(data?.time?.differenceInMinutes)}</Typography>
                                    </>
                                    :
                                    <Typography lineHeight={2}>
                                        <strong>Device Time</strong>: {moment(data?.time?.deviceTimeStamp).format('MMMM Do YYYY, h:mm:ss a')}
                                    </Typography>
                                    :
                                    ele === 'cpu' ?
                                        < Typography lineHeight={2}>
                                            <strong>CPU Utilisation</strong>: {data?.memory?.utilization} {data?.memory?.unit}
                                        </Typography>
                                        :
                                        ele === 'memory' ?
                                            <>
                                                <Typography lineHeight={2}><strong>Memory Used</strong>: {data?.memory?.used.toFixed(2)} MB </Typography>

                                                <Typography lineHeight={2}><strong>Available Memory</strong>: {data?.memory?.free.toFixed(2)} MB </Typography>
                                            </>
                                            :
                                            ele === 'camerasConnected' ?
                                                < Typography lineHeight={2}>
                                                    <strong>Total Ports</strong>: {data?.camerasConnected}
                                                </Typography>
                                                :
                                                ele === 'activeCameras' ?
                                                    < Typography lineHeight={2}>
                                                        <strong>Active</strong>: {data?.activeCameras}
                                                    </Typography>
                                                    :
                                                    ele === 'inactiveCameras' ?
                                                        < Typography lineHeight={2}>
                                                            <strong>Not Active</strong>: {data?.inactiveCameras}
                                                        </Typography>
                                                        : ''
                            }
                            bgcolor={
                                ele === 'cpu' ?
                                    (data?.cpu?.utilization < 50 ? '#47D095' :
                                        (data?.cpu?.utilization >= 50 && data?.cpu?.utilization <= 90) ? '#F39406' :
                                            (data?.cpu?.utilization > 90) ? '#F01103' : '') :
                                    ele === 'memory' ? 'none' :
                                        ele === 'camerasConnected' ? 'none' :
                                            ele === 'activeCameras' ? data?.activeCameras !== data?.camerasConnected ? '#F39406' : '#47D095' :
                                                ele === 'inactiveCameras' ? '#F01103' : ele === "time" ? (data?.time?.differenceInMinutes > 5) && "#F01103" : ""
                            }
                            statusIndicator={!(ele === 'memory' || ele === 'camerasConnected')}
                        // fontWeight={ele === 'cpu' ? 700 : 400}
                        />
                    ))
                }
            </Box>
        </Stack>
    )
}

export default DVRComp