import { Typography } from '@mui/material'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

const CustomTypography = (props) => {
    const themeMode = useSelector(state => state?.theme?.darkTheme)
    const textColor = themeMode ? '#fff' : '#000'
    return (
        <Typography color={textColor} {...props}>{props.children}</Typography>
    )
}

export default memo(CustomTypography)
