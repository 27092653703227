import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Paper, Box, Typography, IconButton, Divider, useTheme, Chip, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading/Loading';
// import Loading from '../../Loading/Loading';
import ServiceUnsubscribed from '../lockedService/ServiceUnsubscribed';

const WidgetDVR = (props) => {
    const { serviceUnsubscribedHeight, setDivHeight, isLoaded, service, secondaryButtonDisabled } = props
    const { services } = useSelector(state => state.configurations)
    const currentMode = useSelector(state => state.theme.darkTheme)
    const color = currentMode ? "#000" : "#fff"
    const navigate = useNavigate()

    const handleClick = () => {
        props.secondaryLink && navigate(props.secondaryLink)
    }
    const ref = useRef(null)

    useEffect(() => {
        if (props.title === 'Booked Desks') setDivHeight(ref.current.clientHeight, props.title)
        else if (props.title === 'Total Occupancy') setDivHeight(ref.current.clientHeight, props.title)
        // else if (props.title === 'Energy Carbon Emissions') setDivHeight(ref.current.clientHeight, props.title)
        else if (props.title === 'Energy Consumption (Last 7 days)') setDivHeight(ref.current.clientHeight, props.title)
        else if (props.title === 'Energy Sources') setDivHeight(ref.current.clientHeight, props.title)
        else if (props.title === 'Fault Reports (last 30 days)') setDivHeight(ref.current.clientHeight, props.title)
    }, [])

    const styles = {
        backgroundColor: currentMode ? "#282a2e" : "#fff",
        minHeight: props.minHeight || '20vh',
        height: '100%',
        padding: 1,
        paddingTop: 2,
        border: currentMode ? `1px solid gray` : `1px solid lightgray`,
        color: color,
        boxShadow: 0,
        margin: 1
    }

    return (
        <Paper sx={styles} >
            <Box height={'100%'} ref={ref} elevation={0}>
                {props.title &&
                    <Typography variant='subtitle1' fontWeight={700} >
                        <Stack direction='row' justifyContent='space-between'>
                            {props.title}
                            {props.booked && <Typography variant='span' sx={{ color: '#a0e400' }}> {props.booked} </Typography>}
                            {props.occupied && <Typography variant='span' sx={{ color: '#41cbbb' }}> &nbsp; {props.occupied} </Typography>}
                            <Stack direction='row' gap={2}>
                                {props.status && <Chip sx={{ height: "1.5rem", marginLeft: "1vw" }} label={props.status} />}
                                {!secondaryButtonDisabled && <IconButton sx={{ float: 'right', m: 0, p: 0, color: color, }} onClick={handleClick} disabled={!services[service]}>{props.icon}</IconButton>}
                            </Stack>
                        </Stack>

                        <Divider sx={{ mb: 1, borderColor: color, mt: 1 }} />
                    </Typography>}
                {
                    services[service] ?
                        isLoaded ? (props.body) : (
                            <Box height={props.title === undefined ? "100%" : "80%"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Loading />
                            </Box>
                        )
                        : <ServiceUnsubscribed height={serviceUnsubscribedHeight} NA={services[service] === 'NA' || services[service] === undefined} />
                }
            </Box>
        </Paper>
    )
}

export default WidgetDVR