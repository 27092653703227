import DepartmentSettings from "../../../secondary/settings/DepartmentSettings"
import CategorySettings from "../../../secondary/settings/CategorySettings"
import Groups from "../../../secondary/settings/Groups"
import UserManagement from "../../../secondary/userManagement/UserManagement"
import Notifications from "../../../secondary/settings/Notifications"
import NotifyForm from "../../../tertiary/settings/notifications/forms/NotifyForm"
import ExternalEmails from "../../../../features/settings/externalEmails/ExternalEmails"
import EditNotificationForm from "../../../tertiary/settings/notifications/forms/EditNotificationForm"
// import NotFound from "../../../Pages/404Error/404Error"
// import MaintenanceMappings from "./Settings/MaintenanceMapping/MaintenanceMappings"
import AreaSettings from "../../../secondary/settings/AreaSettingsContainer"
import AreaImageView from "../../../tertiary/settings/areas/AreaImageView"
import Attendance from "../../../secondary/attendance/Attendance"
import WallContentReview from "../../../secondary/wall/wallContentReview/WallContentReview"
import CCTV from "../../../../pages/secondary/cctv/CCTV"
import DVRAddForm from "../../../../features/cctv/DVRAddForm"
import DVREditForm from "../../../../features/cctv/DVREditForm"
import DVRView from "../../../../features/cctv/DVRView"
import ManagePost from "../../../../features/wall/components/wallContentReviewBody/ManagePost"
import Communities from "../../../secondary/communities/communities"
import ManageCommunity from "../../../../features/communities/review/manageCommunity/ManageCommunity"
import ManageCommPostContainer from "../../../../features/communities/review/manageCommunity/managePost/ManagePostContainer"
import Vendor from "../../../secondary/settings/Vendor"
import VendorUser from "../../../secondary/settings/vendorUser/VendorUser"
import Contract from "../../../secondary/settings/Contract"
import VendorListing from "../../../../features/settings/vendor/components/VendorListing"
import VendorEdit from "../../../../features/settings/vendor/components/VendorEdit"
import VendorUserListing from "../../../../features/settings/vendorUser/components/VendorUserListing"
import VendorUserEdit from "../../../../features/settings/vendorUser/components/VendorUserEdit"
import ContractEdit from "../../../../features/settings/contract/components/ContractEdit"
import ContractListing from "../../../../features/settings/contract/components/ContractListing"
import SensorsGroup from "../../../secondary/energy/SensorGroups"
import MRTabConfig from "../../../secondary/mrTab/MRTabConfig"
import VisitorKioskConfig from "../../../secondary/visitorKiosk/VisitorKioskConfig"
import VisitorKioskSessions from "../../../secondary/visitorKiosk/VisitorKioskSessions"
import HolidayCalendar from "../../../secondary/attendance/holidayCalendar/HolidayCalendar"
import MaintenanceMapping from "../../../secondary/settings/MaintainenceMapping"
import MaintainenceForm from "../../../tertiary/settings/maintainenceMappings/forms/maintenanceForm"
import EditMaintenance from "../../../tertiary/settings/maintainenceMappings/forms/EditMaintenance"
import EditPage from "../../../../features/userManagement/components/EditPage"
// import AddUser from "../../../../features/userManagement/components/addUserForm"
import ViewPage from "../../../../features/userManagement/components/ViewPage"
import BulkUpload from "../../../../features/userManagement/components/bulk-upload/BulkUpload"
// import FormView from "../../../../features/dynamicForm/FormView"
import FormBuilder from "../../../../features/dynamicForm/FormBuilder"
import FormList from "../../../../features/dynamicForm/FormList"
import UserDetailsBulkUpload from "../../../../features/userManagement/components/user-details-bulk-upload/userDetailsBulkUpload"
// import BuildingProfile from "../../../../features/settings/facilities/BuildingProfile"
import BuildingList from "../../../../features/settings/facilities/BuildingList"
import SensorCatalogs from "../../../secondary/energy/SensorCatalogs"
import DocumentForm from "../../../../features/settings/HRDocuments/DocumentForm"
import HrDocumentList from "../../../../features/settings/HRDocuments/HrDocumentList"
import DocumentView from "../../../../features/settings/HRDocuments/DocumentView"

export const routes = [
    {
        path: "/admin/settings/departments", component: DepartmentSettings
    },
    {
        path: "/admin/settings/categories", component: CategorySettings
    },
    {
        path: "/admin/settings/groups", component: Groups
    },
    {
        path: "/admin/settings/notifications", component: Notifications
    },
    {
        path: "/admin/settings/notifications/add", component: NotifyForm
    },
    {
        path: "/admin/settings/notifications/edit/:id", component: EditNotificationForm
    },
    {
        path: "/admin/settings/external-emails", component: ExternalEmails
    },
    {
        path: "/admin/settings/areas", component: AreaSettings
    },
    {
        path: "/admin/settings/areas/view/:id", component: AreaImageView
    },
    {
        path: "/admin/attendance", component: Attendance
    },
    {
        path: "/admin/attendance/holiday-calendar", component: HolidayCalendar
    },
    {
        path: "/admin/settings/vendor", component: VendorListing
    },
    {
        path: "/admin/settings/vendor/add", component: Vendor
    },
    {
        path: "/admin/settings/vendor/edit/:id", component: VendorEdit
    },
    {
        path: "/admin/settings/vendor-user", component: VendorUserListing
    },
    {
        path: "/admin/settings/vendor-user/add", component: VendorUser
    },
    {
        path: "/admin/settings/vendor-user/edit/:id", component: VendorUserEdit
    },
    {
        path: "/admin/settings/contract", component: ContractListing
    },
    {
        path: "/admin/settings/contract/add", component: Contract
    },
    {
        path: "/admin/settings/contract/edit/:id", component: ContractEdit
    },
    {
        path: "/admin/meeting-room-tab-configurations", component: MRTabConfig
    },
    {
        path: "/admin/visitor-kiosk-configurations", component: VisitorKioskConfig
    },
    {
        path: "/admin/visitor-kiosk-sessions/view/:id", component: VisitorKioskSessions
    },
    {
        path: "/admin/energy/sensor-groups", component: SensorsGroup
    },
    {
        path: "/admin/energy/sensor-catalogs", component: SensorCatalogs
    },
    {
        path: "/admin/usermanagement", component: UserManagement
    },
    {
        path: '/admin/usermanagement/add', component: EditPage
    },
    {
        path: "/admin/usermanagement/edit/:id", component: EditPage
    },
    {
        path: "/admin/usermanagement/view/:id", component: ViewPage
    },
    {
        path: "/admin/usermanagement/bulk-upload", component: BulkUpload
    },
    {
        path: "/admin/wall/content-list", component: WallContentReview
    },
    {
        path: "/admin/wall/content-list/view/:id", component: ManagePost
    },
    {
        path: "/admin/communities", component: Communities
    },
    {
        path: "/admin/communities/view/:id", component: ManageCommunity
    },
    {
        path: "/admin/communities/post-view/:id", component: ManageCommPostContainer
    },
    {
        path: "/admin/settings/maintainence-mapping", component: MaintenanceMapping
    },
    {
        path: "/admin/settings/maintenance-mapping/add", component: MaintainenceForm

    },
    {
        path: "/admin/settings/maintenance-mapping/edit/:id", component: EditMaintenance
    },
    // {
    //     path: "/admin/settings/fault-report/maintenance", component: MaintenanceMappings
    // }, {
    //     path: "*", component: NotFound
    // }
    {
        path: "/admin/dynamic-form", component: FormBuilder
    },
    {
        path: "/admin/dynamic-form/list", component: FormList
    },
    {
        path: "/admin/usermanagement/user-details/bulk-upload", component: UserDetailsBulkUpload,

    },
    {
        path: "/admin/settings/facilities", component: BuildingList
    },
    // {
    //     path: "admin/settings/facilities/edit/:id", component: BuildingProfile
    // }
    {
        path: "/admin/settings/hr-docs/add", component: DocumentForm
    },
    {
        path: "/admin/settings/hr-docs/edit/:id", // Add a route for editing
        component: DocumentForm
    },
    {
        path: "/admin/settings/hr-docs/list", component: HrDocumentList
    },
    {
        path: "/admin/settings/hr-docs/view/:id", component: DocumentView
    },

]

export const cctvRoutes = [
    {
        path: "/admin/cctv", component: CCTV
    },
    {
        path: "/admin/cctv/add", component: DVRAddForm
    },
    {
        path: "/admin/cctv/edit/:id", component: DVREditForm
    },
    {
        path: "/admin/cctv/view/:id", component: DVRView
    }
]