import React from 'react'
import { Box, Button } from '@mui/material'
import FormInput from '../../components/formInputs/FormInput'
import { useLocation } from 'react-router-dom'
import { AddCircleTwoTone } from '@mui/icons-material';

const Selector = ({ handleOpenCreatePPMModal, date, setDate, tabSelected, type }) => {
    const location = useLocation()
    const handleFormInputsChange = (e) => {
        setDate(e.target.value)
    }

    return (
        <Box
            className='flex flexAlignItemsCenter flexJustifyBetween'
        >
            <Box></Box>
            <Box className='flex flexAlignItemsCenter columngap16'>
                {(location?.pathname?.includes("/ppm/list") && tabSelected !== 'calender' && type !== "cumulative") && <Box>
                    <FormInput
                        label=''
                        size='small'
                        type='date'
                        focused
                        fullWidth
                        name='date'
                        value={date}
                        onChange={handleFormInputsChange}
                    />
                </Box>}
                <Button className='actionButton height35px'
                    onClick={handleOpenCreatePPMModal}>
                    <AddCircleTwoTone />
                    <div className='color-white padleft8px font15' >CREATE PPM</div>
                </Button>
            </Box>
        </Box>
    )
}

export default Selector