import { Autocomplete, Box, Breadcrumbs, Button, Card, TextField, Typography } from "@mui/material"
import { ThemeColors } from "../../../../hooks/ThemeColors";
import TabContainer from "../../../../components/tab/Tab";
import { useEffect, useState } from "react";
import ManageCommunityTable from "./ManageCommunityTable";
import { useLocation } from "react-router-dom";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { GET, PUT } from "../../../../helpers/http";
import CustomTypography from "../../../../components/texts/CustomTypography";
import CommonStyles from "../../../../styles/CommonStyles";
import Dropdown from "../../../../components/dropdown/Dropdown";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const ManageCommunity = () => {
	const { background_color, commonSX, textColor } = ThemeColors();
	const [tabs, setTabs] = useState(["Posts", "Members", "Blocked"])
	const [selectedTab, setSelectedTab] = useState('posts')
	const [state, setState] = useState({ list: ["all", "edited", "disabled", "deleted"], selectedState:"all"})
	const[community,setCommunity] = useState({})
	const location = useLocation()
	const navigate = useNavigate()

	//Autocomplete
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedUser, setSelectedUser] = useState('')
	const [usersList, setUsersList] = useState([])
	const[timer, setTimer] = useState(0)
	
	const debounce = (fn, delay) => {
		if (timer) clearTimeout(timer)
		setTimer(setTimeout(fn, delay))
    }
    
    const communityStateCountUpdater = ({ community }) => {
        setCommunity(community)
    } 

	const getSingleCommunity = async (id) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community?communityId=${id}`
			const community = await GET(endPoint, "Fetch Community");
			setCommunity(community?.data);
            if (community?.data?.type === "private") setTabs([...tabs, "Requests"])
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleChangeCommunityType = async ({communityId, action}) => {
		try {
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/actions`
			const body = {communityId, action}
			const updatedComm = await PUT(endPoint, body, "Fetch Community");
			setCommunity({ ...community, type: updatedComm?.data?.type, status: updatedComm?.data?.status });
            if (updatedComm?.data?.type === "private") {
                if (!tabs?.includes("Requests")) setTabs([...tabs, "Requests"])
            }else setTabs(tabs.filter(tab => tab !== "Requests"))
			if (updatedComm) {
				successToast(`Community is now ${action}`)
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleTabChange = (value) => {
		setSelectedTab(tabs[value].toLowerCase())
	}

	useEffect(() => {
		getSingleCommunity(location?.state?.id)
	}, [])
	

	useEffect(() => {
		if (searchTerm && !selectedUser) {
			const endpoint = `${process.env.REACT_APP_URL}comms/v1/admin/users/search?searchTerm=${searchTerm}`
			debounce(async function fetchData() {
				try {
					const response = await GET(endpoint, "Get users by search")
					setUsersList(response?.data)
				} catch (error) {
					console.log(error)
				}
			}, 500)
		} else if (searchTerm === "") {
			setUsersList([])
		}
	}, [searchTerm])

	function handleClick(event) {
		event.preventDefault();
		navigate(`/admin/communities`)
	}

	const breadcrumbs = [
		<Typography className="cursorPtr" onClick={handleClick} sx={{ color: textColor }}>
			Communities
		</Typography>,
		<Typography key="3" sx={{ color: textColor }}>
			{community?.name?.length > 15 ? `${community?.name?.slice(0,15)}...` : community?.name}
		</Typography>
	];


	return (
			<Box className="pad16 width100 flex flexDirectionCol flexAlignItemsStart">
			<Card
				sx={{ backgroundColor: background_color }}
				className="width100 boxShadowNone height12vh flex flexDirectionCol flexJustifyAround mrgnbtm8 pad8x16"
			>
				<Breadcrumbs
					separator={<NavigateNextIcon sx={{ color: textColor }} fontSize="small" />}
					aria-label="breadcrumb"
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Typography className="fontweight700 font26">
					Manage Community
				</Typography>
				<Box className="flex flexDirectionRow flexJustifyBetween flexAlignItemsCenter">
					<Box className="flex flexDirectionRow gap32">
						<CustomTypography>Name: {community?.name?.slice(0,20)}</CustomTypography>
						<CustomTypography>Creator: {community?.createdBy?.fullName}</CustomTypography>
						<CustomTypography>{community?.type?.toLowerCase() === 'private' ? <LockIcon fontSize="small"/> : <LockOpenIcon fontSize="small"/>} </CustomTypography>
					</Box>
					<Box className="flex flexDirectionRow gap32 flexAlignItemsCenter">
						<CustomTypography>Admins: {community?.adminsCount}</CustomTypography>
						<CustomTypography>Members: {community?.membersCount}</CustomTypography>
						<CustomTypography>Blocked: {community?.blockedMembersCount}</CustomTypography>
						<CustomTypography>Posts: {community?.postsCount}</CustomTypography>
						<Button sx={{ ...CommonStyles.actionButton }}
							onClick={() => handleChangeCommunityType({ communityId: community?._id, action: community?.type === "public" ? "private" : "public" })} >Change Type</Button>
						<Button sx={{ ...CommonStyles.actionButton }} onClick={() => handleChangeCommunityType({ communityId: community?._id, action: community?.status === "active" ? "inactive" : "active" })}>{community?.status === "active" ? "Deactivate" : "Activate"}</Button>
					</Box>
				</Box>
			</Card>
			<TabContainer
				tabList={tabs}
				tabPanels={[
				<ManageCommunityTable
					entity={selectedTab}
					communityId={location?.state?.id}
					communityName={community?.name}
					state={state?.selectedState}
					selectedUser={selectedUser?._id}
				/>,
				<ManageCommunityTable
					entity={selectedTab}
					communityId={location?.state?.id}
                    communityName={community?.name}
                    communityStateCountUpdater={communityStateCountUpdater}
                    community={community}
					/>,
				<ManageCommunityTable
					entity={selectedTab}
					communityId={location?.state?.id}
                    communityName={community?.name}
                    community={community}
                    communityStateCountUpdater={communityStateCountUpdater}
				/>,
				<ManageCommunityTable
					entity={selectedTab}
					communityId={location?.state?.id}
                    communityName={community?.name}
                    community={community}
                    communityStateCountUpdater={communityStateCountUpdater}
				/>
				]}
				childrens={
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '3rem',
						}}>
						{selectedTab === 'posts' &&
							<>
							<Dropdown
							margin={0}
							data={state?.list?.map((type) => `${type[0].toUpperCase()}${type.slice(1)}`)}
							value={`${state?.selectedState[0].toUpperCase()}${state?.selectedState?.slice(1)}`}
							onChange={(e)=> setState({...state, selectedState:e.target.value})}
							/>
							<>
							<CustomTypography>Search by User:</CustomTypography>
							<Autocomplete
								disablePortal
								id="search-by-users"
								getOptionLabel={(option) => option?.fullName ?? option}
								options={usersList}
								value={selectedUser}
								onChange={(event, newValue) => {
									setSelectedUser(newValue);
								}}
								inputValue={searchTerm}
								onInputChange={(event, newInputValue) => {
									setSearchTerm(newInputValue);
								}}
								isOptionEqualToValue={(option, value) => {
									return option._id === (value?._id)
								}}
								renderInput={(params) => {
									return (<TextField
										{...params}
										sx={{
											width: 200,
											height: 35,
											borderRadius: "10px",
											'& .MuiInputBase-root': {
												height: '100%',
												alignContent: 'center'
											}, ...commonSX }}
									// inputProps={{ ...params.inputProps, style: { color } }}
									/>)
								}}
							/>
							</>
						</>
						}
					</Box>
				}
				onChange={handleTabChange}
			/>
			</Box>
	)
}

export default ManageCommunity