import { Box, Tab } from "@mui/material"
import Secondary from "../../../components/secondary/Secondary"
import TabContainer from "../../../components/tab/Tab"
import Details from "../../../features/plannedPreventativeMaintenance/TaskView/Details"
import Medias from "../../../features/plannedPreventativeMaintenance/TaskView/Medias"
import Costing from "../../../features/plannedPreventativeMaintenance/TaskView/Costing"
import History from "../../../features/plannedPreventativeMaintenance/TaskView/History"
import { useEffect, useReducer, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { fetchPPMList } from "../../../features/plannedPreventativeMaintenance/context/PPMListContextProvider"
import { PPMListReducer } from "../../../features/plannedPreventativeMaintenance/context/PPMListReducer"
import PPMProvider from "../../../features/plannedPreventativeMaintenance/context/PPMContextProvider"
import PPMStatsProvider from "../../../features/plannedPreventativeMaintenance/context/PPMStatsProvider"
import PPMModal from "../../../features/plannedPreventativeMaintenance/components/modal/PPMModal"
import CreatePPMForm from "../../../features/plannedPreventativeMaintenance/CreatePPMForm"
import useApiCalls from "../../../features/plannedPreventativeMaintenance/components/hooks/useApiCalls"


const TaskViewContainer = () => {
    const { id } = useParams()
    const location = useLocation()
    const [taskData, setTaskData] = useState({})
    const { fetchSingleTask, fetchHistory } = useApiCalls()
    const [batchNumber, setBatchNumber] = useState(1)

    const [isLoading, setIsLoading] = useState(false)
    const [openCreatePPMModal, setOpenCreatePPMModal] = useState(false)
    const [PPMList, dispatch] = useReducer(PPMListReducer, { isLoaded: false, data: [] });

    const [historyList, setHistoryList] = useState([])
    const [historyListLoaded, setHistoryListLoaded] = useState(false)
    const [loadMoreEnded, setLoadmoreEnded] = useState(false)
    const [update, setUpdate] = useState(false)

    const [refresh, setRefresh] = useState(false)
    const [refreshHistory, setRefreshHistory] = useState(false)
    const handleCloseCreatePPMModal = () => {
        setOpenCreatePPMModal(false)
    }


    const refreshSingleTask = () => {
        fetchSingleTask({ id, setIsLoading, setTaskData })
    }

    useEffect(() => {
        refreshSingleTask()
    }, [refresh])

    useEffect(() => {
        fetchHistory({ id, batchNumber, setHistoryList, setHistoryListLoaded, historyList, setLoadmoreEnded })
    }, [id, batchNumber, refreshHistory])


    const handleLoadMore = () => {
        if (!loadMoreEnded) setBatchNumber(batchNumber + 1)
    }


    const onImageUpload = () => {
        setRefresh(!refresh)
    }

    const triggerList = () => {
        fetchPPMList(dispatch)
    }

    return (
        <Box width="100%">
            <Secondary
                title={"Task Details"}
                returnPage={"PPM List"}
                returnRoute={location.state?.hisoryRoute ?? "/ppm/list"}
                children={
                    <TabContainer
                        className='commonComponentSpacing'
                        tabList={["Details", "Media", 'Costing', 'History']}
                        tabPanels={[
                            <Details
                                isLoading={isLoading}
                                task={taskData}
                                refreshSingleTask={refreshSingleTask}
                                historyList={historyList}
                                setRefreshHistory={setRefreshHistory}
                                refreshHistory={refreshHistory}  // remove this

                            />,
                            <Medias isDeleted={taskData?.isDeleted} onImageUpload={onImageUpload}
                            />,
                            <Costing task={taskData} isDeleted={taskData?.isDeleted}
                            />,
                            <History
                                historyList={historyList}
                                handleLoadMore={handleLoadMore}
                                historyListLoaded={historyListLoaded}
                                loadMoreEnded={loadMoreEnded}
                                refreshHistory={refreshHistory}
                                setRefreshHistory={setRefreshHistory}
                            />
                        ]}
                    />
                }
            />
            <PPMProvider>
                <PPMStatsProvider>
                    <PPMModal
                        modalOpen={openCreatePPMModal}
                        closeModal={handleCloseCreatePPMModal}
                        title={'Create PPM'}
                        modalWidth="50vw"
                        children={
                            <PPMProvider>
                                <CreatePPMForm triggerList={triggerList} handleCloseModal={handleCloseCreatePPMModal} />
                            </PPMProvider>
                        }
                    />
                </PPMStatsProvider>
            </PPMProvider>
        </Box>
    )
}

export default TaskViewContainer