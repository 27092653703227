import { Grid, Typography } from "@mui/material";
import React from "react";
import './footer.css'

const Footer = (props) => {
    const textContent = [{ text: "Copyright © 2023 Nhance Digital BuildTech Pvt Ltd. All rights reserved." }, { text: "All trademarks, logos and brand names are the property of their respective owners" }];
    return (
        <div className="pad1115_35 footer-mainContainer">
            <Grid container className="footer-inner-container">
                {textContent.map(({ text }, index) => (
                    <Grid item key={index} md={6} sm={12} className="footer-mobile-view">
                        <Typography variant="caption" className="pad07P opacity0_6 footer-text">
                            {text}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Footer;