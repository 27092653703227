import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../../facilityManager/helpers/http'

export const asyncFetchAreaRatings = createAsyncThunk('AreaFeedback/asyncFetchAreaRatings', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}fb/v1/areas?buildingId=${bid}&limit=5`, 'Area average rating')
        console.log("feedback", response)
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const initialState = {
    feedback: {
        isLoaded: false,
        data: []
    }
}

const feedbackSlice = createSlice({
    name: 'feedbackSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(asyncFetchAreaRatings.fulfilled, (state, action) => {
            state.feedback.isLoaded = true
            state.feedback.data = action.payload
        })
    }

})

export default feedbackSlice.reducer