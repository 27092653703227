import React from 'react'
import { TextField } from '@mui/material'
import { useSelector } from 'react-redux'

const TextInput = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const { label, variant, size, multiline, placeholder, name, width, rows, disabled, value, type, onChange, onKeyDown, id, inputProps, helperText,
        error, InputProps, sx, focused, ...rest } = props

    return (
        <TextField
            autoComplete='off'
            InputLabelProps={{ sx: { color } }}
            id={id}
            label={label || ''}
            size={size || ''}
            variant={variant || 'filled'}
            value={value}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder || ""}
            name={name || ''}
            type={type}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputProps={inputProps}
            InputProps={InputProps}
            sx={{ width: width || 180, ...sx }}
            disabled={disabled}
            shrink={'true'}
            focused={(value || focused) ? true : false}
            error={error}
            helperText={helperText || ""}
            FormHelperTextProps={{ sx: { fontSize: '10px' } }}
            {...rest}
        />
    )
}

export default TextInput;