import { GET, POST } from "../../../../components/facilityManager/helpers/http";

export const FETCH_INITIAL_FORGE_SENSORS = 'FETCH_INITIAL_FORGE_SENSORS'
export const UPDATE_SSE_FORGE_DATA = 'UPDATE_SSE_FORGE_DATA'
export const UPDATE_SENSOR_TYPE = "UPDATE_SENSOR_TYPE"
export const FETCH_SENSOR_AQI_DATA = "FETCH_SENSOR_AQI_DATA"
export const RESET_SENSOR_AQI_DATA = "RESET_SENSOR_AQI_DATA"
export const RESET_ASSET_DATA = "RESET_ASSET_DATA"
export const SHOW_SIDE_PANEL = "SHOW_SIDE_PANEL"
export const FETCH_ASSET_MANAGEMENT_DATA = "FETCH_ASSET_MANAGEMENT_DATA"
export const UPDATE_SSE_DESK_OCCUPANCY_FORGE_DATA = "UPDATE_SSE_DESK_OCCUPANCY_FORGE_DATA"
export const FETCH_INITIAL_DESK_OCCUPANCY_DATA = "FETCH_INITIAL_DESK_OCCUPANCY_DATA"
export const FETCH_INITIAL_DESK_BOOKED_DATA = "FETCH_INITIAL_DESK_BOOKED_DATA"
export const UPDATE_SSE_MEETING_ROOM_FORGE_DATA = "UPDATE_SSE_MEETING_ROOM_FORGE_DATA"
export const FETCH_INITIAL_MEETING_ROOM_OCCUPANCY_DATA = "FETCH_INITIAL_MEETING_ROOM_OCCUPANCY_DATA"
export const FETCH_INITIAL_MEETING_ROOM_BOOKED_DATA = "FETCH_INITIAL_MEETING_ROOM_BOOKED_DATA"
export const CAMERA_FEED = "CAMERA_FEED"
export const FETCH_INITIAL_SETUP_DATA = "FETCH_INITIAL_SETUP_DATA"
export const FETCH_CAMERA_DATA = "FETCH_CAMERA_DATA"
export const SET_INITIAL_VIEWER_STATE = "SET_INITIAL_VIEWER_STATE"
export const RESET_CAMERA_URL_DATA = "RESET_CAMERA_URL_DATA"
export const TOGGLE_SLIDE_PANEL = "TOGGLE_SLIDE_PANEL"

export const fetchInitialForgeSensors = (payload) => {
    return ({
        type: FETCH_INITIAL_FORGE_SENSORS,
        payload
    })
}

export const updateSseForgeDeskData = (payload) => {
    return ({
        type: UPDATE_SSE_DESK_OCCUPANCY_FORGE_DATA,
        payload
    })
}

// export const fetchInitialDeskBookedData = (payload) => {
// 	return ({
// 		type: FETCH_INITIAL_DESK_BOOKED_DATA,
// 		payload
// 	})
// }

export const fetchInitialDeskOccupancyData = (payload) => {
    return ({
        type: FETCH_INITIAL_DESK_OCCUPANCY_DATA,
        payload
    })
}
export const fetchInitialConfigData = (payload) => {
    return ({
        type: FETCH_INITIAL_SETUP_DATA,
        payload
    })
}

export const updateSseForgeMeetingRoomData = (payload) => {
    return ({
        type: UPDATE_SSE_MEETING_ROOM_FORGE_DATA,
        payload
    })
}

export const fetchInitialMeetingRoomData = (payload) => {
    return ({
        type: FETCH_INITIAL_MEETING_ROOM_OCCUPANCY_DATA,
        payload
    })
}

export const fetchInitialMeetingRoomBookedData = (payload) => {
    return ({
        type: FETCH_INITIAL_MEETING_ROOM_BOOKED_DATA,
        payload
    })
}

export const updateSseForgeData = (payload) => {
    return ({
        type: UPDATE_SSE_FORGE_DATA,
        payload
    })
}

export const updateSensorType = (type) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SENSOR_TYPE,
            payload: type
        })
        dispatch(asyncFetchForgeSensors(type))

    }
}

export const fetchAQISensorsData = (payload) => {
    return ({
        type: FETCH_SENSOR_AQI_DATA,
        payload
    })
}

export const fetchAssetManagementData = (payload) => {
    return ({
        type: FETCH_ASSET_MANAGEMENT_DATA,
        payload
    })
}

export const camerafeed = (payload) => ({
    type: CAMERA_FEED,
    payload
})

export const resetAQISensorsData = () => {
    return ({ type: RESET_SENSOR_AQI_DATA })
}

export const resetCameraUrlData = () => {
    return ({ type: RESET_CAMERA_URL_DATA })
}

export const toggleSlideInPanel = () => {
    return ({ type: TOGGLE_SLIDE_PANEL })
}

export const resetAssetData = () => {
    return ({ type: RESET_ASSET_DATA })
}

export const showSidePanelInSplitScreen = () => {
    return ({ type: SHOW_SIDE_PANEL })
}

export const fetchInitialCameraDetails = (payload) => {
    return ({
        type: FETCH_CAMERA_DATA,
        payload
    })
}

export const setInitialViewerState = (payload) => {
    return ({ type: SET_INITIAL_VIEWER_STATE, payload })
}

export const asyncFetchForgeSensors = (sensortype) => {
    return (dispatch) => {
        GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/sensors/data?type=${sensortype}&bid=${localStorage.getItem('bid')}`, "Forge Aqi")
            .then((response) => {
                const result = response.data
                dispatch(fetchInitialForgeSensors(result))

            })
            .catch((err) => {
                console.log("error in forge getting sensors", err)
            })
    }
}

export const asyncFetchAQIDataExternalId = (externalId) => {
    return (dispatch) => {
        GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/sensors?externalId=${externalId}&bid=${localStorage.getItem('bid')}`, "Forge Sensors ExternalId")
            .then((response) => {
                const result = response.data
                if (result) {
                    dispatch(fetchAQISensorsData(result))
                }
                else {
                    GET(process.env.REACT_APP_BASE_URL + `am/v1/asset?external-id=${externalId}&bid=${localStorage.getItem('bid')}`)
                        .then((response) => {
                            if (response.status === 200) {
                                const result = response.data
                                dispatch(fetchAssetManagementData(result))
                            }
                        })
                        .catch((err) => {
                            if (err.response?.status === 404 && err.response?.data?.error === "Invalid External ID/Asset Doesn't Exist") {
                                dispatch(fetchAQISensorsData([{ data: 'No Data Found', externalId }]))
                            }
                        }
                        )
                }
            })
            .catch((err) => {
                console.log("error in forge getting sensors", err)
            })
    }
}

// export const asyncFetchInitialDeskBookedData = () => {
// 	return (dispatch) => {
// 		axios.get(`${process.env.REACT_APP_DESK_BOOKING_URL}desk/status/${Date.now()}`, { headers: { Authorization: localStorage.getItem('token') } })
// 			.then(response => {
// 				const result = response.data
// 				if (result.success && (result.message === 'Desks found')) {
// 					dispatch(fetchInitialDeskBookedData(result.data))
// 				}
// 			})
// 			.catch(error => console.log('error', error.message))
// 	}
// }


export const asyncFetchForgeInitialConfig = () => {
    return (dispatch) => {
        GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/config?bid=${localStorage.getItem('bid')}`, "Forge Initial Setup")
            .then((response) => {
                dispatch(fetchInitialConfigData(response?.data))
            })
            .catch(error => console.log("error", error.message))
    }
}

export const asyncFetchInitialDeskData = () => {
    return (dispatch) => {
        GET(process.env.REACT_APP_BASE_URL + `twin/v1/forge/s2/sensors/deskoccupancy?time=${Date.now()}&bid=${localStorage.getItem('bid')}`, "Forge Desk Occupancy")
            .then((response) => {
                dispatch(fetchInitialDeskOccupancyData(response.data))
            })
            .catch(error => console.log("error", error.message))
    }
}

export const asyncFetchCameraUrl = () => {
    return (dispatch) => {
        GET(process.env.REACT_APP_BASE_URL + `twin/v1/camera/list?bid=${localStorage.getItem('bid')}`, "Forge Camera List")
            .then((response) => {
                const result = response.data
                dispatch(fetchInitialCameraDetails(result))
            })
            .catch(error => console.log("error", error.message))
    }
}

export const asyncFetchInitialMeetingRoomData = (configData) => {
    return (dispatch) => {

        if (configData && Object.keys(configData)) {
            const areaIds = Object.keys(configData)
            POST(process.env.REACT_APP_BASE_URL + `twin/v1/meetingrooms/s1/occupancy/latest?buildingId=${localStorage.getItem('bid')}`, { areaIds })
                .then(response => {
                    const result = response.data
                    dispatch(fetchInitialMeetingRoomData(result))

                })
                .catch(error => console.log("error", error.message))
        }
    }
}

// export const asyncFetchInitialMeetingRoomBookedData = () => {
// return (dispatch) => {
// axios.get(`http://localhost:8003/desk/alldeskinfo/${Date.now()}`) //Change
// .then(response => {
// const result = response.data
// if (result.success && (result.message === 'Desks found')) {
// dispatch(fetchInitialMeetingRoomBookedData(result.data))
// }
// })
// .catch(error => console.log('error', error.message))
// }
// }