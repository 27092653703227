import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from "../api/apiSlice";
import { GET_CSRF, errorHandler } from '../../components/facilityManager/helpers/http';
import moment from 'moment';

const fetchReportGenerationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAQIAggregationOptions: builder.query({
            query: () => ({
                url: 'twin/v1/aqi/s3/options?report_filters=true',
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch AQI Aggregation Options')
            },
        }),
        getRestRoomsAggregationOptions: builder.query({
            query: () => ({
                url: 'twin/v1/restrooms/s3/report-filters',
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch RestRooms Aggregation Options')
            },
        }),
        getEnergyAggregationOptions: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s2/options?bid=${bid}&type=report`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Energy Aggregation Options')
            },
        }),
        getVisitorManagementFilterOptions: builder.query({
            query: () => ({
                url: 'vm/v1/dashboard/appointment/report-filters',
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Visitor Management Filter Options')
            },
        }),
        getFaultReportsFilterOptions: builder.query({
            query: () => ({
                url: 'fr/v1/report/filters',
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Fault Reports Filter Options')
            },
        }),
        getDeskBookingFilterOptions: builder.query({
            query: (bid) => ({
                url: `db/v1/reports/options?bid=${bid}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Desk Booking Filter Options')
            },
        }),
        getMeetingRoomsFilterOptions: builder.query({
            query: (bid) => ({
                url: `mr/v1/meetingrooms/s2/reports/options?bid=${bid}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Meeting Rooms Filter Options')
            },
        }),
        generateAQIReport: builder.mutation({
            query: (body) => ({
                url: 'twin/v1/aqi/s3/report',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),

            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else errorHandler(error, 'Generate AQI Report')
            },
        }),
        generateRestRoomReport: builder.mutation({
            query: (body) => ({
                url: 'twin/v1/restrooms/s3/report',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),

            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Restroom Report')
            },
        }),
        generateVisitorsAppointmentsReport: builder.mutation({
            query: (body) => ({
                url: 'vm/v1/dashboard/appointment/report ',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Visitor Appointments Report')
            },
        }),
        generateFaultReportsStatusReport: builder.mutation({
            query: (body) => ({
                url: 'fr/v1/report/generate',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Fault Reports Status Report')
            },
        }),
        generateFaultReportsPDFReport: builder.mutation({
            query: (body) => ({
                url: 'fr/v1/insights_report',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Fault Reports PDF Report')
            },
        }),
        generateDeskBookingReport: builder.mutation({
            query: (body) => ({
                url: 'db/v1/reports/generate',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Desk Booking Report')
            },
        }),
        generateMeetingRoomsReport: builder.mutation({
            query: (body) => ({
                url: 'mr/v1/meetingrooms/s2/reports/generate',
                method: 'POST',
                body,
                headers: {
                    'x-csrf-token': localStorage.getItem('csrfToken')
                },
                credentials: 'include'
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Meeting Rooms Report')
            },
        }),
        generateFeedbackPdfReport: builder.mutation({
            query: (body) => ({
                url: 'fb/v1/report/insights',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Feedback Report PDF Report')
            },
        }),
        generateFeedbackExcelReport: builder.mutation({
            query: (body) => ({
                url: 'fb/v1/report/generate',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),

            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Feedback Report Excel Report')
            },
        }),
        generateEnergyExcelReport: builder.mutation({
            query: (body) => ({
                url: 'twin/v1/energy/v2/s2/report',
                method: 'POST',
                body,
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),

            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Generate Energy Excel Report')
            },
        })
    })
})

export const {
    useGetAQIAggregationOptionsQuery,
    useGetRestRoomsAggregationOptionsQuery,
    useGetEnergyAggregationOptionsQuery,
    useGetVisitorManagementFilterOptionsQuery,
    useGetFaultReportsFilterOptionsQuery,
    useGetDeskBookingFilterOptionsQuery,
    useGetMeetingRoomsFilterOptionsQuery,
    useGenerateAQIReportMutation,
    useGenerateRestRoomReportMutation,
    useGenerateVisitorsAppointmentsReportMutation,
    useGenerateFaultReportsStatusReportMutation,
    useGenerateFaultReportsPDFReportMutation,
    useGenerateDeskBookingReportMutation,
    useGenerateMeetingRoomsReportMutation,
    useGenerateFeedbackPdfReportMutation,
    useGenerateFeedbackExcelReportMutation,
    useGenerateEnergyExcelReportMutation
} = fetchReportGenerationSlice

const initialState = {
    startDate: '',
    endDate: '',
    minStartTime: '',
    maxEndTime: '',
    isReportGenerated: 'idle', //'idle', 'pending', 'fulfilled', 'rejected'
    reportId: '',
    noDataMessage: '',
    error: '',
    aggregation: {   //for energy, AQI & Restrooms Tertiary
        isLoaded: false,
        dataGenerationFrequency: [],
        selectedAggregatedDuration: '',
    },
    statusList: {  //for Fault Reports
        isLoaded: false,
        data: []
    },
    deskBookingOptions: {
        isLoaded: false,
        data: []
    },
    pdfReport: {
        isLoaded: false,
        data: {}
    }
}

const reportGenerationSlice = createSlice({
    name: 'reportGeneration',
    initialState,
    reducers: {
        setReportStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setReportEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setSelectedAggregatedDuration: (state, action) => {
            state.aggregation.selectedAggregatedDuration = action.payload
        },
        setIsReportGenerated: (state, action) => {
            state.isReportGenerated = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        resetAggregation: (state, action) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getAQIAggregationOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.aggregation.dataGenerationFrequency = action.payload.dataGenerationFrequency
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    state.aggregation.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getRestRoomsAggregationOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.aggregation.dataGenerationFrequency = action.payload.dataGenerationFrequency
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    state.aggregation.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyAggregationOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.aggregation.dataGenerationFrequency = action.payload?.data?.dataGenerationFrequency
                    state.minStartTime = action.payload?.data?.minStartTime
                    state.maxEndTime = action.payload?.data?.maxEndTime
                    state.aggregation.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getVisitorManagementFilterOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    // state.aggregation.dataGenerationFrequency = action.payload.dataGenerationFrequency
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    // state.aggregation.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getFaultReportsFilterOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    state.statusList.data = action.payload.statusList
                    state.statusList.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getDeskBookingFilterOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    state.deskBookingOptions.data = action.payload.options
                    state.deskBookingOptions.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getMeetingRoomsFilterOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    // state.aggregation.dataGenerationFrequency = action.payload.dataGenerationFrequency
                    state.minStartTime = action.payload.minStartTime
                    state.maxEndTime = action.payload.maxEndTime
                    // state.aggregation.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateAQIReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateRestRoomReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateVisitorsAppointmentsReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateFaultReportsStatusReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateFaultReportsPDFReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.pdfReport.data = action.payload
                    state.pdfReport.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateDeskBookingReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateMeetingRoomsReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateFeedbackPdfReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.pdfReport.data = action.payload
                    state.pdfReport.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateFeedbackExcelReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'generateEnergyExcelReport' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (!action.payload?.reportId) {
                        state.noDataMessage = `The report for the specified time range (from ${moment(state.startDate).format('DD MMM YYYY')} to ${moment(state.endDate).format('DD MMM YYYY')}) contains no data`
                        state.isReportGenerated = 'rejected'
                    }
                    else if (action.payload?.reportId) {
                        state.reportId = action.payload.reportId
                        state.isReportGenerated = 'fulfilled'
                    }
                }
            )
    }
})

export const { setReportStartDate, setReportEndDate, setSelectedAggregatedDuration, setIsReportGenerated, setError, resetAggregation } = reportGenerationSlice.actions

export default reportGenerationSlice.reducer