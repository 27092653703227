import React, { memo, useEffect, useState, useCallback, Fragment } from 'react';
import { GET } from '../../../../helpers/http';
import Loader from '../../../../components/loading/Loader';
import { errorToast } from '../../../../helpers/apiToast';
import { IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import CustomTypography from '../../../../components/texts/CustomTypography';
import Datatable from '../../components/dataTable/Datatable';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button/CustomButton';
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

const VendorListing = () => {
    const [vendorList, setVendorList] = useState([]);

    const navigate = useNavigate()

    const allVendorListing = useCallback(async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor/list`, "GET VENDORS");
            if (response?.status === 200) {
                const vendorsWithId = response?.data?.map(vendor => ({ ...vendor, id: vendor._id }));
                setVendorList(vendorsWithId);
            }
        } catch (error) {
            errorToast(error?.response?.data?.message);
        }
    }, []);

    useEffect(() => {
        allVendorListing();
        return () => {
            setVendorList([]);
        };
    }, [allVendorListing]);

    const handleVendorEdit = async (id) => {
        navigate(`/admin/settings/vendor/edit/${id}`)
    }

    // const handleVendorDelete = (id) => {
    //     console.log({ id });
    // }

    const columns = [
        // { field: '_id', headerName: 'ID', flex: 1.5 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'taxId', headerName: 'Tax ID', flex: 1 },
        {
            field: 'primaryAddress.addressLine1', headerName: 'Address Line 1', flex: 1.5,
            renderCell: (params) => params?.row?.primaryAddress?.addressLine1
        },
        {
            field: 'primaryAddress.city', headerName: 'City', flex: 1,
            renderCell: (params) => params?.row?.primaryAddress?.city
        },
        {
            field: 'primaryAddress.state', headerName: 'State', flex: 1,
            renderCell: (params) => params?.row?.primaryAddress?.state

        },
        {
            field: 'primaryAddress.postalCode', headerName: 'Postal Code', flex: 1,
            renderCell: (params) => params?.row?.primaryAddress?.postalCode
        },
        {
            field: 'primaryAddress.country', headerName: 'Country', flex: 1,
            renderCell: (params) => params?.row?.primaryAddress?.country
        },
        {
            field: 'Actions', headerName: 'Actions', flex: 1,
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton aria-label="edit" onClick={() => { handleVendorEdit(cellValues?.row?._id) }}>
                            <EditOutlined sx={{ color: '#1976d2 !important' }} />
                        </IconButton>
                        {/* <IconButton aria-label="delete" onClick={() => { handleVendorDelete(cellValues?.row?._id) }}>
                            <DeleteOutlineOutlined sx={{ color: '#ef5350 !important' }} />
                        </IconButton> */}
                    </>
                );
            }
        }
    ]

    const handleAddVendor = () => {
        navigate('/admin/settings/vendor/add')
    }

    return (
        <div className='width100 pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Vendors</CustomTypography>
                <CustomButton
                    variant='contained'
                    className={styles.button_bgc} startIcon={<AddCircleOutlineOutlined className='color-white' />}
                    onClick={handleAddVendor}
                    sx={{ mr: '20px', height: '2.4rem' }}
                >Add Vendor
                </CustomButton>
            </div>
            <div className='mrgnsettingpage'>
                {
                    vendorList?.length > 0 ? <Datatable columns={columns} rows={vendorList} /> : <Loader height={'89vh'} width={'70%'} />
                }
            </div>
        </div>
    );
};

export default memo(VendorListing);
