const NotificationsStyles = {
    headingTypo: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
    },
    innerBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    paperContainer: {
        p: 1,
        boxShadow: '0px 0px 6px #c2cfdb',
    },
    addButton: {
        color: 'white',
        borderColor: '#DC4D28',
        bgcolor: '#DC4D28',
        // mr: 3,
        width: '100%',
        height: '100%',
        maxHeight: 35,
        alignSelf: 'center',
        ':hover': { bgcolor: '#DC4D28' },
    },
    searchContainer: {
        alignSelf: 'center',
        width: "100%",
        maxHeight: 40,
    }
}

export default NotificationsStyles