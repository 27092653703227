import { Box, Button, Chip, Divider, Grid, IconButton, Paper, Stack, Switch, Tooltip, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'

import './meetingRoomTabBox.css'
import CommonStyles from '../../../styles/CommonStyles'
import MeetingRoomTabBoxStyles from '../../../styles/MeetingRoomTabBoxStyles'
import { DELETE, FILEPOST, PUT } from '../../../helpers/http'
import { DeleteOutlined, UploadFileOutlined, VisibilityOutlined } from '@mui/icons-material'
import ImageCropper from '../../settings/areas/components/ImageCropper'
import { optimizImg } from '../../../helpers/optimizeImg'
import TransitionsModal from '../../../components/modal/ModalWithAnimAndBackdrop.js'

const MeetingRoomTabBox = (props) => {
    const { _id, accessCode, mediaRef, floor, isDisabled, isConnected, updateTabsArr, roomDetails } = props

    const themeMode = useSelector(state => state.theme.darkTheme)
    const meetingRoomsFileReadUrl = useSelector(state => state.configurations.meetingRoomsFileReadUrl)
    const [imageToBeCropped, setImageToBeCroppped] = useState({})
    const [cropModalOpen, setCropModalOpen] = useState(false)
    const [uploadedImageUrl, setUploadedImageUrl] = useState(mediaRef?.key || '')
    const [previewUploadedImageModal, setPreviewUploadedImageModal] = useState(false)

    const fileRef = useRef(null)
    const color = themeMode ? '#808080' : '#000'

    const handleOpenPreviewUploadedImageModal = () => {
        setPreviewUploadedImageModal(true)
    }

    const handleClosePreviewUploadedImageModal = () => {
        setPreviewUploadedImageModal(false)
    }

    const updateTabConfig = async (updateBody) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}mr/v1/saas/mrtabconfig/${_id}`, updateBody, 'Update Tab Config NR')
            const result = response?.data
            if (response?.status === 200) {
                updateTabsArr(result)
            } else {
                console.log(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const swalPopup = (updateBody, warningText) => {
        swal({
            title: "Are you sure?",
            text: warningText,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    updateTabConfig(updateBody)
                }
            });
    }

    const handleEnableChange = (e) => {
        const disable = !e.target?.checked
        const updateBody = { isDisabled: disable }
        if (disable) {
            swalPopup(updateBody, "Make sure this code is not connected to any device! Connected device will be disconnected")
        } else {
            updateTabConfig(updateBody)
        }
    }

    const handleConnectionToggle = (e) => {
        const connected = e.target?.checked
        const updateBody = { isConnected: connected }
        if (!connected) {
            swalPopup(updateBody, "Connected Device will be disconnected.")
        } else {
            updateTabConfig(updateBody)
        }
    }

    const handleUploadImage = (event) => {
        setImageToBeCroppped({})
        const file = event.currentTarget.files?.[0]
        if (file) {
            setImageToBeCroppped(URL.createObjectURL(file))
            setCropModalOpen(true)
        }
    }

    const handleCloseCroppedModal = () => {
        setCropModalOpen(false)
        setImageToBeCroppped({})
    }

    const uploadCroppedImg = async (img) => {
        //optimize bfr Image
        const optImg = await optimizImg(img, 'image/*')
        const myFile = new File([optImg], `image.${optImg.type.replace('image/', '')}`, {
            type: optImg.type,
        });

        //upload Img
        let formData = new FormData();
        formData.append('file', myFile)
        try {
            const { status, data } = await FILEPOST(`${process.env.REACT_APP_BASE_APP_URL}mr/v1/saas/mrtabconfig/image/upload/${_id}`, formData, "AREA IMAGE UPLOAD")
            if (status === 201) {
                setUploadedImageUrl(data?.url)
                swal("Success!", "Image uploaded successfully", "success");
            }
        } catch (error) {
            console.log(error.message)
            swal("Error!", error.message, 'error')
        }
    }

    const handleRemoveUploadedImg = async () => {
        try {
            const { status, data } = await DELETE(`${process.env.REACT_APP_BASE_APP_URL}mr/v1/saas/mrtabconfig/image/${_id}`, 'Delete Uploaded Image')

            if (status === 200 && data?.message === "File deleted successfully") {
                setUploadedImageUrl('')
                setImageToBeCroppped({})
                swal("Success!", "Image removed successfully", "success");
            }
            else {
                swal("Warning!", 'Something went wrong', 'warning');
            }

        } catch (error) {
            console.log(error.message)
            swal("Error!", error.message, 'error')
        }
    }

    return (
        <Paper
            key={_id}
            sx={{
                ...MeetingRoomTabBoxStyles?.paperContainer
            }}
            className='theme-component-background theme-border theme-cards-boxShadow bdrRadius4px'
        >
            <Box
                sx={{ height: "100%" }}
            >
                <Grid container pl={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item md={8} lg={8} xl={9} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Typography
                            sx={MeetingRoomTabBoxStyles?.headingTypography}
                            variant='h6'
                            fontWeight={'bold'}
                        >{roomDetails && roomDetails?.name ? roomDetails?.name : "NA"}</Typography>
                    </Grid>
                    <Grid item md={4} lg={4} xl={3} >
                        <Box
                            sx={{ ...CommonStyles?.justifyContentFlexEnd, alignItems: 'center' }}
                        >
                            <Chip
                                label={isDisabled ? "Disabled" : "Enabled"}
                                size="small"
                                sx={{ bgcolor: isDisabled ? '#FF4E5E !important' : '#8FC70A !important' }}>

                            </Chip>
                            <Switch
                                size="medium"
                                checked={!isDisabled}
                                color="warning"
                                onChange={handleEnableChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: color }} />
                <Box>
                    <Grid
                        container
                        sx={MeetingRoomTabBoxStyles?.parentGrid}
                    >
                        {/* 1st grid */}
                        <Grid
                            container
                            item
                            xs={8}
                            sx={MeetingRoomTabBoxStyles?.gridBox}
                        >
                            {/* 1st sub grid */}
                            <Grid
                                direction={'column'}
                                item
                                container
                                xs={6}
                            >
                                <Grid item xs={4} py={0.5} >
                                    <Typography
                                        sx={MeetingRoomTabBoxStyles?.gridTypography}
                                    >Floor Name</Typography>
                                </Grid>
                                <Grid item xs={4} py={0.5}>

                                    <Typography
                                        sx={MeetingRoomTabBoxStyles?.gridTypography}

                                    >Access Code</Typography>
                                </Grid>
                                <Grid item xs={4} py={0.5} sx={{
                                    width: "100%"
                                }}>

                                    <Typography
                                        sx={{
                                            ...MeetingRoomTabBoxStyles?.gridTypography,
                                            whiteSpace: 'nowrap',
                                            textOverflow: "ellipsis",
                                            overflow: "hidden"
                                        }}

                                    >Max People Count</Typography>
                                </Grid>
                            </Grid>
                            {/* 2nd sub grid */}
                            <Grid
                                direction={'column'}
                                item
                                container
                                xs={6}
                            >
                                <Grid item xs={4} py={0.5}
                                >
                                    <Typography
                                        sx={MeetingRoomTabBoxStyles?.gridValues}
                                    >{floor && floor?.name ? floor.name : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={4} py={0.5}>

                                    <Typography
                                        sx={MeetingRoomTabBoxStyles?.gridValues}
                                    >{accessCode}</Typography>
                                </Grid>
                                <Grid item xs={4} py={0.5}>

                                    <Typography
                                        sx={MeetingRoomTabBoxStyles?.resourceId}
                                    >{roomDetails && roomDetails?.maxCount ? roomDetails?.maxCount : "NA"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* 2nd grid */}
                        <Grid
                            container
                            item
                            direction={'column-reverse'}
                            xs={4}
                        >
                            <Box
                                sx={{ mb: -0.25, mr: -0.25, ...CommonStyles?.flexEndAlignCenter }}
                                maxWidth='98%'
                            >
                                <Typography
                                    sx={{
                                        ...MeetingRoomTabBoxStyles?.connected,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        maxWidth: '60%'
                                    }}>{isConnected ? "Connected" : "Disconnected"}</Typography>
                                <Switch
                                    size="medium"
                                    color="warning"
                                    sx={{ margin: '0px' }}
                                    checked={isConnected}
                                    onChange={handleConnectionToggle}
                                    disabled={!isConnected}
                                />
                            </Box>
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: color }} />
                    <Grid container sx={{ ...MeetingRoomTabBoxStyles?.bottomGrid, justifyContent: uploadedImageUrl ? "space-between" : "flex-end", p: 1 }}>
                        {uploadedImageUrl && <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0.5}>
                            <Tooltip title='Preview image'>
                                <IconButton color='warning' size='small' onClick={handleOpenPreviewUploadedImageModal}>
                                    <VisibilityOutlined fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete image'>
                                <IconButton color='warning' size='small' onClick={handleRemoveUploadedImg}>
                                    <DeleteOutlined fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Stack>}
                        <Button
                            size='small'
                            component="label"
                            className='actionButton' sx={{ px: 1 }}
                        >
                            <UploadFileOutlined fontSize='small' sx={{ mr: 0.5 }} />
                            Upload image
                            <input
                                type="file"
                                accept={"image/*"}
                                hidden
                                onChange={handleUploadImage}
                                ref={fileRef}
                            />
                        </Button>
                    </Grid>
                </Box>
            </Box>
            {cropModalOpen && <ImageCropper img={imageToBeCropped} setImg={setUploadedImageUrl} open={cropModalOpen} close={handleCloseCroppedModal} uploadCroppedImg={uploadCroppedImg} />}
            {previewUploadedImageModal && <TransitionsModal
                handleClose={handleClosePreviewUploadedImageModal}
                url={`${meetingRoomsFileReadUrl}${uploadedImageUrl}`}
            />}
        </Paper >
    )
}

export default MeetingRoomTabBox