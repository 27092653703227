import { GET } from "../../helpers/http"

export const fetchCalendarData = async ({ startDate, endDate, setEvents = () => { } }) => {
    try {
        if (!startDate || !endDate) return
        const buildingId = localStorage.getItem('bid')
        // const { data } = await GET(`${'http://localhost:8030/'}v1/task/calendar/list?buildingId=${buildingId}&startDate=${startDate}&endDate=${endDate}`, 'PPM Calendar List')
        const { data } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/calendar/list?buildingId=${buildingId}&startDate=${startDate}&endDate=${endDate}`, 'PPM Calendar List')
        const events = data.map((event) => ({
            id: event._id,
            title: event.title,
            desc: event.description,
            start: new Date(event.startTime),
            end: new Date(event.endTime),
        }))
        setEvents(events)
    } catch (error) {
        console.log(error)
    }
}