

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { ThemeColors } from "../../../../hooks/ThemeColors";

const PPMStatusDropdown = ({ selectedValue, capitalizeFirst, dropdownOptions, handleChangeFun, sx }) => {
    const { commonSX, themeMode } = ThemeColors();
    const selectStyle = {
        ...commonSX,
        '.MuiOutlinedInput-notchedOutline': {
            border: `0px`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `0px`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `0px`,
        },
        '.MuiSvgIcon-root ': {
            fill: "black",
        },
        height: "2rem",
        borderRadius: "5rem",
        fontSize: "15px",
        color: "black",
        backgroundColor: themeMode ? "dimgrey" : "whitesmoke",
    }

    return (
        <Box sx={{ ...sx, }}>
            <FormControl size="small" fullWidth>
                <Select
                    sx={selectStyle}
                    onChange={(e) => handleChangeFun(e)}
                    value={selectedValue}
                >
                    {dropdownOptions?.map((menu, i) => {
                        return <MenuItem
                            key={i}
                            sx={menu === selectedValue ? {
                                height: "0px",
                                padding: 0,
                                margin: 0,
                                opacity: 0,
                            } : {}}
                            value={menu}>
                            {capitalizeFirst(menu)}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default PPMStatusDropdown