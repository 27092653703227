import { createSlice } from '@reduxjs/toolkit'

const initialState = { mode: 'light', navigationDrawer: false, darkTheme: false }

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        switchModes(state, action) {
            state.darkTheme = action.payload === 'dark'
        },
        toggleTheme(state, action) {
            // return state = { mode: action.payload }
            state.mode = action.payload
        },
        toggleNavigationDrawer(state, action) {
            state.navigationDrawer = !state?.navigationDrawer
        }
    }
})

export const { toggleTheme, toggleNavigationDrawer, switchModes } = themeSlice.actions

export default themeSlice.reducer