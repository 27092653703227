import { useEffect, useState } from "react";
import Datatable from "../components/dataTable/Datatable"
import { GET } from "../../../helpers/http";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { DeleteOutline, EditOutlined, Height, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BuildingProfile from "./BuildingProfile";
import MUIRightDrawer from "../../dynamicForm/MUIRightDrawer";
import CustomComponentContainer from "../../../components/container/CustomComponentContainer";
import CommonStyles from "../../../styles/CommonStyles";
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles";


const BuildingList = () => {
    const navigate = useNavigate()
    const [buildings, setBulidings] = useState([])
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [viewMode, setViewMode] = useState(false)
    const [open, setOpen] = useState(false);
    const [updateParent, setUpdateParent] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/list/buildings`, "Building List")
                setBulidings(response?.data?.formattedBuildings)

            } catch (error) {
                console.log(error)
            }
        })();


    }, [updateParent])

    const handleBuildingView = (row) => {
        toggleDrawer(true);
        dataToEdit(row.id);
        setViewMode(true)
        // navigate()
    }

    const updateList = () => {
        setUpdateParent(!updateParent)
    }
    const dataToEdit = (id) => {
        const result = buildings.find(el => el.id === id);
        setEditData(result);
    };

    const handleEditClick = (row) => {
        const id = row.id
        setEditMode(true);
        dataToEdit(id);
        toggleDrawer(true);

    }



    const toggleDrawer = async (state) => {
        setOpen(state);
        if (!state) {
            // Reset states when closing the drawer
            setEditMode(false);
            setViewMode(false)
            setEditData(null);
        }
    };
    const handleUpdateParent = () => {
        setUpdateParent(!updateParent)
    }

    const toggleEditMode = (state) => {
        setEditMode(state);
    };



    const columns = [

        {
            field: 'Name',
            headerName: 'name',
            flex: 1,
            renderCell: (params) => {
                return params.row ? params?.row?.name : ''
            },
        },

        { field: 'location', headerName: 'location', flex: 1 },
        {
            field: 'address', headerName: 'Address', flex: 1,
            renderCell: (params) => {
                return params?.row?.profile?.address
            }
        },
        {
            field: 'contactNumber', headerName: 'Contact Number', flex: 1,
            renderCell: (params) => {
                return params?.row?.profile?.contactNumber
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip>
                        <IconButton
                            // disabled={params.row.isDeleted} 
                            aria-label="view"
                            onClick={() => { handleBuildingView(params.row) }}
                        >
                            <RemoveRedEyeOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                        <IconButton
                            // disabled={params.row.isDeleted} 
                            aria-label="edit"
                            onClick={() => handleEditClick(params.row)}
                        >
                            <EditOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>

                </>
            ),
        },
    ];


    return (
        <>

            <Box sx={DepartmentSettingsStyles.boxContainer}>
                <Typography
                    sx={
                        DepartmentSettingsStyles.headingTypo
                    }
                >
                    Facilities
                </Typography>

                <CustomComponentContainer
                    // className='commonComponentSpacing'
                    sx={{
                        position: 'relative',
                        width: "100%",
                        height: "89vh"
                    }}
                >

                    <Datatable height="100%" columns={columns} rows={buildings} />
                    <MUIRightDrawer open={open}
                        toggleDrawer={toggleDrawer}
                        title={`${editMode ? 'Update' : 'Add'} Costing`}>
                        <BuildingProfile
                            data={editData}
                            viewMode={viewMode}
                            updateList={updateList}
                            editMode={editMode}
                            handleUpdateParent={handleUpdateParent}
                            toggleEditMode={toggleEditMode}
                            toggleDrawer={toggleDrawer}
                        />
                    </MUIRightDrawer>


                </CustomComponentContainer>


            </Box>
        </>
    )

}
export default BuildingList