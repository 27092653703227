import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ThemeColors } from '../../../../hooks/ThemeColors';
import { useSelector } from 'react-redux';

const HighchartsPPM30DaysTrend = ({ title, data }) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const { textColor, bgColor } = ThemeColors()
    const options = {
        chart: {
            type: 'column',
            height: "300px",
            backgroundColor: bgColor,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: data?.xAxis,
            lineColor: textColor(),
            labels: {
                enabled: true,
                autoRotationLimit: 0,
                style: {
                    fontSize: "12px",
                    color: textColor(),
                },
            },
        },
        credits: {
            enabled: false,
        },
        yAxis: [{
            min: 0,
            title: {
                text: ''
            },
            lineColor: textColor(),
            gridLineColor: 'transparent',
            labels: {
                enabled: true,
                autoRotationLimit: 0,
                style: {
                    fontSize: "12px",
                    color: textColor(),
                },
            },
        }, {
            title: {
                text: ''
            },
            opposite: true
        }],
        legend: {
            shadow: false,
            enabled: true,
            itemStyle: {
                color: textColor(),
                textTransform: 'uppercase',
            },
            itemMarginTop: 10, // Adds space above each legend item
            itemMarginBottom: 10, // Adds space below each legend item
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            },

        },
        series: [
            {
                name: data?.data[1]?.status,
                color: '#0073e6',
                data: data?.data[1]?.data,
                pointPadding: 0.3,
                pointPlacement: -0.3
            },
            {
                name: data?.data[0]?.status,
                data: data?.data[0]?.data,
                color: '#00bfff',
                pointPadding: 0.3,
                pointPlacement: -0.2
            }
        ]
    }

    return (
        <HighchartsReact
            height="100%"
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default HighchartsPPM30DaysTrend;
