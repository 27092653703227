import React from 'react';
import { InputLabel, MenuItem, FormControl, Chip, FormHelperText, Box } from '@mui/material';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import './dropDown.css'

const Dropdown = (props) => {
    const { variant, margin, width, mt, mr, ml, label, labelId, data, value, onChange, textColor, bgcolor, name, disabled, id, needValue, error,
        height, maxHeight, sx, inputLabel, mb, fullWidth } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    return (
        <Box sx={{ width: Boolean(fullWidth) ? '100%' : 'auto' }}>
            <FormControl variant={variant} size="small" sx={{ m: margin >= 0 ? margin : 1, minWidth: width || 100, mt: mt || 0, mr: mr || 0, ml: ml || 0, mb: mb || 0 }}>
                {inputLabel && <InputLabel id={id} sx={{ color: textColor || color }} disabled={disabled}>{inputLabel}</InputLabel>}
                <Select
                    disableUnderline
                    sx={{
                        color: color,
                        fontStyle: 'normal',
                        bgcolor,
                        '.MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${color}`,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${color}`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${color}`,
                        },
                        '.MuiSvgIcon-root ': {
                            fill: `${color}`,
                        },
                        ...sx
                    }}
                    labelId={labelId}
                    value={value}
                    id={id}
                    name={name}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    error={error}
                    height={height}
                    style={{ maxHeight: maxHeight ? maxHeight : null }}
                    MenuProps={{
                        style: { maxHeight: maxHeight ? maxHeight : null },
                        disableScrollLock: true,
                        className: themeMode ? 'menu-container-dark' : 'menu-container-light',
                    }}
                >
                    {data?.map((item, i) => <MenuItem key={i} value={needValue ? item?.name : item?.id || item?._id || item} disabled={item?.disabled}>
                        <>{item?.name || <Chip label={item} sx={{ width: '5rem', backgroundColor: "#dc3d28", color: 'white' }} size="small" />}</>
                    </MenuItem>
                    )}
                </Select>
                {error && <FormHelperText sx={{
                    color: 'error.main',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    marginBottom: '0.5rem',
                    ml: '.5rem'
                }}>{error}</FormHelperText>
                }
            </FormControl>
        </Box >
    );
}


export default Dropdown;