import { Avatar, Box, Button, Card, Chip, Skeleton, Typography } from "@mui/material"
import { ThemeColors } from "../../../../../hooks/ThemeColors"
import ImageRenderer from "../../../../../components/image/ImageRenderer"
import { profilePicture } from "../../../../../../../data/imageUrls"
import { useSelector } from "react-redux"
import moment from "moment"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useEffect, useState } from "react"
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import SubComment from "./SubComment"
import { errorToast, successToast } from "../../../../../helpers/apiToast"
import { GET, PUT } from "../../../../../helpers/http"
import commentsStyle from "../../../../../styles/wall/contentReviewBody/CommentStyle"

const Comment = ({ id }) => {
    const wallFileReadUrl = useSelector(state => state?.configurations?.wallFileReadUrl)
    const [repliesPage, setRepliesPage] = useState({})
    const [replies, setReplies] = useState({})
    const { background_color } = ThemeColors()
    const [loading, setLoading] = useState(true)
    const [commentsData, setCommentsData] = useState([])
    const [page, setPage] = useState(1)
    const [disableLoadMore, setDisableLoadMore] = useState(false)
    const [readmore, setReadmore] = useState({})

    const getCommentDetail = async (commentId) => {
        try {
            const endPointComment = `${process.env.REACT_APP_URL}wall/v1/admin/related-entity/list?entity=comment&id=${commentId}&page=${page}`
            const commentResult = await GET(endPointComment, "Fetch Comments")
            setCommentsData([...commentsData, ...commentResult?.data])
            if (commentResult?.data?.length < 5) {
                setDisableLoadMore(true)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    useEffect(() => {
        getCommentDetail(id)
    }, [page])

    const hanldeCommentReadMore = (commentId) => {
        setReadmore({ ...readmore, [commentId]: true })
    }

    const hanldeCommentReadLess = (commentId) => {
        setReadmore({ ...readmore, [commentId]: false })
    }

    const viewRepliesHandle = async ({ relatedTo, parentId, type }) => {
        try {
            let endPoint = `${process.env.REACT_APP_URL}wall/v1/admin/related-entity/list?entity=comment&id=${relatedTo}&page=${repliesPage[parentId] ? repliesPage[parentId] : 1}&parentId=${parentId}`
            const getReplies = await GET(endPoint, "Get Replies")
            if (type === "subcomment") {
                setReplies({ ...replies, [parentId]: [...replies[parentId], ...getReplies?.data] })
            } else {
                setReplies({ ...replies, [parentId]: getReplies?.data })
            }
            setRepliesPage({ ...repliesPage, [parentId]: repliesPage[parentId] ? repliesPage[parentId] + 1 : 2 })
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const manageComment = async ({ action, id, type }) => {
        try {
            const manageCommentResponse = await PUT(`${process.env.REACT_APP_URL}wall/v1/admin/actions`,
                {
                    "id": id,
                    "type": "comment",
                    "action": action
                },
                "Admin: Delete/Restore Comment")
            if (type === "comment") {
                setCommentsData(commentsData?.map((com) => {
                    if (com?._id === manageCommentResponse?.data?._id) {
                        return manageCommentResponse?.data
                    } else {
                        return com
                    }
                }))
            } else if (type === "reply") {
                setReplies({
                    ...replies, [manageCommentResponse?.data?.parent]: replies[manageCommentResponse?.data?.parent].map((com) => {
                        if (com?._id === manageCommentResponse?.data?._id) {
                            return manageCommentResponse?.data
                        } else {
                            return com
                        }
                    })
                })
            }
            successToast(`Comment hast been ${action}d`)
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const loadMoreCommentsHandle = () => {
        setPage(page + 1)
    }

    return (
        <Card className="boxShadowNone pad25"
            sx={{ backgroundColor: background_color, height: "65vh" }}>
            <Typography variant="h6">Comments</Typography >
            {!loading
                ?
                commentsData.length !== 0
                    ?
                    <Box className="padright8px"
                        sx={{ ...commentsStyle.scrollBarStyle }}>
                        {commentsData?.map((comment) => {
                            return (
                                <Box key={comment?._id} className="flex flexDirectionCol padtop15">
                                    <Box className="flex flexDirectionRow">
                                        {/* avatar */}
                                        <Box className="width15 flex flexJustifyCenter">
                                            {
                                                comment?.user?.profilePictureUrl !== "" ?
                                                    <Avatar alt={`${comment?.user?.fullName}'s img`} src={`${wallFileReadUrl}${comment?.user?.profilePictureUrl}`} /> :
                                                    <Avatar>
                                                        <ImageRenderer src={profilePicture?.src} alt={profilePicture?.alt} />
                                                    </Avatar>
                                            }
                                        </Box>
                                        {/* Name and Comments */}
                                        <Box className="flex flexDirectionCol flexJustifyEvenly width100">
                                            <Box className="flex flexDirectionRow flexJustifyStart gap16">
                                                <Box className="fontweight600 font14">
                                                    {comment?.user?.fullName}
                                                </Box>

                                                <Box className="font14 color-grey">
                                                    {moment(comment?.createdAt).fromNow()}
                                                </Box>
                                                <Chip
                                                    sx={{ marginLeft: "1rem" }}
                                                    size="small"
                                                    label={
                                                        <Box sx={{
                                                            display: "flex",
                                                            gap: "0.5rem",
                                                            color: "#ef5350",
                                                            alignItems: "center"
                                                        }}>
                                                            <FlagOutlinedIcon
                                                                sx={{ color: "#ef5350", height: "20px" }} />
                                                            {comment?.flagCount}
                                                        </Box>
                                                    } />
                                                {comment?.isDeleted &&
                                                    <Chip
                                                        size="small"
                                                        label={
                                                            <Box
                                                                className="flex flexDirectionRow flexAlignItemsCenter color-grey font12">
                                                                Deleted
                                                                <DeleteOutlineOutlinedIcon
                                                                    className="font14" fontSize="small" />
                                                            </Box>}
                                                    />}
                                            </Box>
                                            <Box className="font14" >
                                                {comment?.textContent?.length > 100 && !readmore[comment?._id]
                                                    ?
                                                    <Box>
                                                        {comment?.textContent?.slice(0, 100) + "..."}
                                                        <Box component="span" className="fontweight600 cursorPtr"
                                                            onClick={() => hanldeCommentReadMore(comment?._id)}
                                                        ><i>readmore</i></Box>
                                                    </Box>
                                                    :
                                                    <Box>{comment?.textContent}
                                                        {readmore[comment?._id] &&
                                                            <Box
                                                                component="span"
                                                                className="cursorPtr fontweight600"
                                                                onClick={() => hanldeCommentReadLess(comment?._id)}
                                                            ><i>...read less</i></Box>}
                                                    </Box>
                                                }
                                            </Box>
                                            <Box className="flex flexJustifyBetween flexAlignItemsCenter">
                                                {(comment?.childCount !== 0
                                                    && !repliesPage[comment?._id])
                                                    ?
                                                    <Box
                                                        className="color-grey txtUnderline txtTransformCaps cursorPtr font13"
                                                        onClick={() => viewRepliesHandle({ relatedTo: comment?.relatedTo, parentId: comment?._id })}
                                                    >
                                                        View replies ({comment?.childCount})
                                                    </Box>
                                                    :
                                                    <Box></Box>
                                                }
                                                <Box>
                                                    {!comment?.isDisabled
                                                        ?
                                                        <Button
                                                            className="txtTransformCaps height25px"
                                                            sx={{ ...commentsStyle.buttonStyle }}
                                                            onClick={() => manageComment({
                                                                action: "disable",
                                                                id: comment?._id,
                                                                type: "comment"
                                                            })}
                                                        >
                                                            Disable
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="txtTransformCaps height25px"
                                                            sx={{ ...commentsStyle.buttonStyle }}
                                                            onClick={() => manageComment({
                                                                action: "enable",
                                                                id: comment?._id,
                                                                type: "comment"
                                                            })}
                                                        >
                                                            Enable
                                                        </Button>
                                                    }
                                                    {!comment?.isDeleted
                                                        ?
                                                        <Button
                                                            className="txtTransformCaps height25px"
                                                            sx={{ ...commentsStyle.buttonStyle }}
                                                            onClick={() => manageComment({
                                                                action: "delete",
                                                                id: comment?._id,
                                                                type: "comment"
                                                            })}
                                                        >
                                                            Delete
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="txtTransformCaps height25px"
                                                            sx={{ ...commentsStyle.buttonStyle }}
                                                            onClick={() => manageComment({
                                                                action: "restore",
                                                                id: comment?._id,
                                                                type: "comment"
                                                            })}
                                                        >
                                                            Restore
                                                        </Button>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        Object.keys(replies).length !== 0
                                        &&
                                        Object.keys(replies).includes(comment?._id)
                                        &&
                                        <SubComment viewRepliesHandle={viewRepliesHandle} comment={comment} replies={replies[comment?._id]} manageComment={manageComment} />
                                    }
                                </Box>
                            )
                        })
                        }
                        {!disableLoadMore &&
                            <Box
                                className="color-grey txtUnderline txtTransformCaps cursorPtr flex flexJustifyCenter font13"
                                onClick={loadMoreCommentsHandle}
                            >
                                Load more
                            </Box>
                        }
                    </Box>
                    :
                    <Box
                        className="height100p flex flexJustifyCenter flexAlignItemsCenter color-grey font14">No comments yet...
                    </Box>
                :
                <Box className="fxWidth100 padtop10">
                    {Array.from({ length: 5 }).map((ele) => {
                        return (
                            <Box className="flex flexDirectionRow gap16 flexAlignItemsCenter">
                                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                <Box className="flex flexDirectionCol padtop10" width="100%">
                                    <Skeleton animation="wave" height={20} className="width30" />
                                    <Skeleton animation="wave" height={20} />
                                    <Skeleton animation="wave" height={20} />
                                    <Box className="flex flexDirectionRow flexJustifyEnd" >
                                        <Skeleton animation="wave" height={30} width={80} />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            }
        </Card >
    )
}

export default Comment