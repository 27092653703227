import { createContext, useReducer } from "react";
import { chatReducer } from "./chatReducer";
import Chat from "../../../pages/secondary/chat/Chat";

export const ChatContext = createContext(null);

const chatInitialState = {
    chatHistory: [],
    interfaceChat: [],
    chatRunning: true,
    voiceCommand: false,
    newChatId: undefined,
    populatedQuestion: undefined
};

export const ChatContextProvider = ({ children }) => {
    const [chatInfo, chatDispatch] = useReducer(chatReducer, chatInitialState)
    return (
        <ChatContext.Provider value={{ chatInfo, chatDispatch }}>
            {children}
        </ChatContext.Provider>
    );
}
export const ChatComponentContainer = () => {
    return <ChatContextProvider>
        <Chat />
    </ChatContextProvider>
}