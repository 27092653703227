import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Typography } from "@mui/material"
import CustomTypography from "../../../components/texts/CustomTypography"
import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { ThemeColors } from "../../../hooks/ThemeColors";

import { GET, POST } from "../../../../facilityManager/helpers/http";
import { errorToast, successToast } from "../../../helpers/apiToast";
import Modal from "../../../../facilityManager/components/modals/NewModal";
import { useNavigate, useParams } from "react-router-dom";
import { PUT } from "../../../helpers/http";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import FormInput from "../../../components/formInputs/FormInput";
import QuillContent from "./components/QuillContent";

const DocumentForm = () => {
    const [departments, setDepartments] = useState([])
    const [title, setTitle] = useState('')
    const [allDepartments, setAllDepartments] = useState([])
    const [selectedBuilding, setSelectedBuilding] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [formData, setFormData] = useState({})
    const [tags, setTags] = useState([])
    const [newTag, setNewTag] = useState("");
    const [content, setContent] = useState(""); // State for editor content
    const [allBuildings, setAllBuildings] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([])
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({})
    const { commonSX, background_color, textColor } = ThemeColors();
    const themeMode = useSelector(state => state?.theme?.darkTheme)
    const [filteredBuildings, setFilteredBuildings] = useState([])


    useEffect(() => {
        if (id) {
            setIsEditMode(true);
            fetchDocumentData(id);
        }
    }, [id]);


    const fontFamilyMap = {
        'sans-serif': 'Arial, sans-serif',
        'serif': 'Georgia, serif',
        'monospace': 'Courier New, monospace',
        'arial': 'Arial, sans-serif',
        'times-new-roman': 'Times New Roman, serif',
        'courier-new': 'Courier New, monospace',
        'roboto': 'Roboto, sans-serif',
        'open-sans': 'Open Sans, sans-serif',
        'lato': 'Lato, sans-serif',
        'comic-sans-ms': 'Comic Sans MS, cursive, sans-serif',
    };

    // common styles for the preview and view page 
    const commonStyles = (themeMode, textColor, width) => ({
        height: '70vh',
        width: width || '100%', // Dynamic width
        marginTop: '1rem',
        // overflow: 'auto',
        border: '1px solid #ddd',
        padding: '0.5rem',
        borderRadius: '4px',
        backgroundColor: themeMode ? '#61616161' : '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        color: textColor(),
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        lineHeight: '1.5',
    });

    const styleImagesAndFonts = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        // Apply styles to images
        const images = tempDiv.querySelectorAll('img');
        images.forEach((img) => {
            img.style.maxWidth = '100%'; // Prevent overflow
            img.style.height = 'auto'; // Maintain aspect ratio
            img.style.display = 'block'; // Ensure block display for alignment

            // Apply alignment class based on data-align attribute
            const alignClass = img.getAttribute('data-align');
            if (alignClass) {
                img.parentElement.classList.add(`ql-align-${alignClass}`);
            }
        });
        // Apply font-family styles to elements with ql-font-* classes
        Object.keys(fontFamilyMap).forEach((font) => {
            tempDiv.querySelectorAll(`.ql-font-${font}`).forEach((element) => {
                element.style.fontFamily = fontFamilyMap[font];
            });
        });

        return tempDiv.innerHTML; // Return modified HTML as a string
    };


    useEffect(() => {
        if (selectedLocation.length > 0) {
            const filteredBuildings = allBuildings.filter(building => selectedLocation.includes(building?.location?._id));
            // Update the `selectedBuilding` state to keep only valid IDs
            const checkBuildings = allBuildings.filter(building => selectedBuilding.includes(building?._id))
            const resultBuildings = checkBuildings.filter(ele => selectedLocation.includes(ele?.location?._id)).map(ele => ele._id)

            setFilteredBuildings(filteredBuildings);
            setSelectedBuilding(resultBuildings);
        } else {
            setFilteredBuildings([]);
            setSelectedBuilding([])

        }
    }, [selectedLocation?.length, selectedBuilding?.length]);

    const fetchDocumentData = async (docId) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/policy-docs?id=${docId}`, "Get Single Document");
            const result = response?.data;

            // Style images in the content before setting it
            // const styledContent = styleImages(result?.content);
            // Style images and fonts in the content before setting it
            const styledContent = styleImagesAndFonts(result?.content);

            setTitle(result?.title);
            setDepartments(result?.departments?.map(ele => ele?._id));
            setSelectedBuilding(result?.buildings?.map(ele => ele?._id));
            setSelectedCategories(result?.categories?.map(ele => ele?._id));
            setTags(result?.tags);
            setContent(styledContent);
            setSelectedLocation(result?.locations?.map(ele => ele?._id));
            setFormData((prevFormData) => ({
                ...prevFormData,
                title: result?.title,
                departments: result?.departments?.map(ele => ele?._id),
                buildings: result?.buildings?.map(ele => ele?._id),
                categories: result?.categories?.map(ele => ele?._id),
                tags: result?.tags,
                content: styledContent,
                locations: result?.locations?.map(ele => ele?._id),
            }));
        } catch (error) {
            console.error("Failed to fetch document data", error);
        }
    };


    useEffect(() => {
        (async () => {
            try {
                const apiCalls = [
                    GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, "Fetching Departments"),
                    GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/building/list`, 'Get All Buildings'),
                    GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, 'Get All Categories'),
                    GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/location/list`, 'Get All Locations')
                ];

                const results = await Promise.allSettled(apiCalls);
                // Process each API result
                const departments = results[0].status === 'fulfilled' ? results[0].value?.data : [];
                const buildings = results[1].status === 'fulfilled' ? results[1].value?.data : [];
                const categories = results[2].status === 'fulfilled' ? results[2].value?.data : [];
                const locations = results[3].status === 'fulfilled' ? results[3].value?.data : [];

                // Update state
                setAllDepartments(departments);
                setAllBuildings(buildings);
                setCategories(categories);
                setAllLocations(locations);

            } catch (error) {
                // errorToast(error?.message)
                console.log("An unexpected error occurred:", error);
            }
        })();
    }, []);

    // Add tag handler
    const handleAddTag = (event) => {
        const delimiters = [", ", "Enter"]; // Define delimiters (comma + space and Enter)
        const inputValue = newTag.trim();

        if ((delimiters.includes(event.key) || event.type === "click") && inputValue) {
            // Split input if it contains multiple tags separated by ", "
            const newTags = inputValue.split(",").map(tag => tag.trim()).filter(tag => tag && !tags.includes(tag));

            if (newTags.length > 0) {
                setTags([...tags, ...newTags]);
                setFormData({ ...formData, tags: [...tags, ...newTags] });
            }

            setNewTag(""); // Clear input after adding
        }
    };


    // Delete tag handler
    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
        setFormData({ ...formData, tags: tags.filter((tag) => tag !== tagToDelete) })
    };


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const { name } = event.target
        if (name === 'title') {
            setTitle(value)
            setFormData(prevFormData => ({ ...prevFormData, title: value }))

        }
    }


    const handlePreview = () => {
        setModal(true)
    }
    const closePreview = () => {
        setModal(false)
    }


    const validateSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        content: Yup.string()
            .test(
                'is-not-empty',
                'Content is required',
                (value) => {
                    // Return false if no value
                    if (!value) return false;

                    // Trim spaces and check for common "empty" HTML content
                    const trimmedValue = value.trim();
                    return (
                        trimmedValue !== '' && // Not just empty string
                        trimmedValue !== '<p><br></p>' && // Quill empty paragraph
                        !/^(<p>\s*<\/p>)+$/i.test(trimmedValue) // Handles cases like "<p> </p>"
                    );
                }
            )
            .required('Content is required'),
    });



    const handleSubmit = async () => {
        try {

            const finalFormData = { ...formData, tags }

            // validate the document form 
            await validateSchema.validate(finalFormData, { abortEarly: false });

            const response = isEditMode ?
                await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/policy-docs`, { ...finalFormData, _id: id }, "Update Document")
                : await POST(`${process.env.REACT_APP_BASE_URL}um/v1/policy-docs`, finalFormData, "Create Document")

            if (response.status === 201) {
                successToast(`${finalFormData?.title} Created Successfully`)
                setTimeout(() => {
                    setTitle("");
                    setDepartments([]);
                    setAllDepartments([]);
                    setSelectedBuilding([]);
                    setCategories([]);
                    setSelectedCategories([]);
                    setFormData({});
                    setTags([]);
                    setNewTag("");
                    setContent(""); // State for editor content
                    setAllBuildings([]);
                    setAllLocations([]);
                    setSelectedLocation([]);
                    setErrors({})
                }, 0); // Delay state update to ensure all fields are cleared
                navigate("/admin/settings/hr-docs/list")
            } else if (response.status === 200) {
                successToast(`${finalFormData?.title} Updated Successfully`)
                setTimeout(() => {
                    setTitle("");
                    setDepartments([]);
                    setAllDepartments([]);
                    setSelectedBuilding([]);
                    setCategories([]);
                    setSelectedCategories([]);
                    setFormData({});
                    setTags([]);
                    setNewTag("");
                    setContent(""); // State for editor content
                    setAllBuildings([]);
                    setAllLocations([]);
                    setSelectedLocation([]);
                    setErrors({})
                }, 0); // Delay state update to ensure all fields are cleared
                navigate("/admin/settings/hr-docs/list")

            }


        } catch (error) {
            if (error.name === 'ValidationError') {
                const newErrors = {};
                error.inner.forEach(e => {
                    newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
            } else {
                console.error('Submission error:', error);
            }
        }

    }

    const handleCancel = () => {
        navigate('/admin/settings/hr-docs/list')

    }

    const addInlineStyles = (htmlContent) => {
        const div = document.createElement('div');
        div.innerHTML = htmlContent;

        // Map for dynamic font size styling
        const fontSizeMap = {
            'small': "10px",
            'large': "50px",
            'huge': "100px",
            '10px': '10px',
            '12px': '12px',
            '14px': '14px',
            '16px': '16px',
            '18px': '18px',
            '20px': '20px',
            '24px': '24px',
            '30px': '30px',
            '36px': '36px',
            '48px': '48px',
            '60px': '60px',
            '72px': '72px',
            '100px': '100px',
        };

        // Map for font family styling
        const fontFamilyMap = {
            'sans-serif': 'Arial, sans-serif',
            'serif': 'Georgia, serif',
            'monospace': 'Courier New, monospace',
            'arial': 'Arial, sans-serif',
            'times-new-roman': 'Times New Roman, serif',
            'courier-new': 'Courier New, monospace',
            'roboto': 'Roboto, sans-serif',
            'open-sans': 'Open Sans, sans-serif',
            'lato': 'Lato, sans-serif',
            'comic-sans-ms': 'Comic Sans MS, cursive, sans-serif',
        };

        // Apply font size styles dynamically based on class
        Object.keys(fontSizeMap).forEach((size) => {
            div.querySelectorAll(`.ql-size-${size}`).forEach((element) => {
                element.style.fontSize = fontSizeMap[size];
            });
        });

        // Apply font family styles dynamically based on class
        Object.keys(fontFamilyMap).forEach((font) => {
            div.querySelectorAll(`.ql-font-${font}`).forEach((element) => {
                element.style.fontFamily = fontFamilyMap[font];
            });
        });

        return div.innerHTML;
    };

    const handleCheckBoxChange = (e, id) => {
        const name = e?.target?.name
        if (name === "categories") {
            setSelectedCategories((prev) =>
                prev.includes(id)
                    ? prev.filter((categoryId) => categoryId !== id) // Remove if already selected
                    : [...prev, id] // Add if not selected
            );

            setFormData((prevFormData) => {
                // Determine the updated categories
                const updatedCategories = prevFormData.categories?.includes(id)
                    ? prevFormData.categories.filter((categoryId) => categoryId !== id) // Remove if already present
                    : [...(prevFormData.categories || []), id]; // Add if not present

                // Return the updated formData object
                return { ...prevFormData, categories: updatedCategories };
            });

        } else if (name === 'buildings') {
            setSelectedBuilding((prev) =>
                prev.includes(id)
                    ? prev.filter((buildingId) => buildingId !== id) // Remove if already selected
                    : [...prev, id] // Add if not selected
            )
            setFormData((prevFormData) => {
                // Determine the updated categories
                const updatedBuildings = prevFormData?.buildings?.includes(id)
                    ? prevFormData?.buildings.filter((buildingId) => buildingId !== id) // Remove if already present
                    : [...(prevFormData?.buildings || []), id]; // Add if not present

                // Return the updated formData object
                return { ...prevFormData, buildings: updatedBuildings };
            });
        } else if (name === "locations") {
            setSelectedLocation(
                (prev) =>
                    prev.includes(id)
                        ? prev.filter((locationId) => locationId !== id) // Remove if already selected
                        : [...prev, id]
            )
            setFormData((prevFormData) => {
                // Determine the updated categories
                const updatedLocation = prevFormData?.locations?.includes(id)
                    ? prevFormData?.locations.filter((locationId) => locationId !== id) // Remove if already present
                    : [...(prevFormData?.locations || []), id]; // Add if not present

                // Return the updated formData object
                return { ...prevFormData, locations: updatedLocation };
            });
        } else if (name === "departments") {
            setDepartments(
                (prev) =>
                    prev.includes(id)
                        ? prev.filter((departmentId) => departmentId !== id) // Remove if already selected
                        : [...prev, id]
            )
            setFormData((prevFormData) => {
                // Determine the updated categories
                const updatedDepartments = prevFormData?.departments?.includes(id)
                    ? prevFormData?.locations.filter((departmentId) => departmentId !== id) // Remove if already present
                    : [...(prevFormData?.departments || []), id]; // Add if not present

                // Return the updated formData object
                return { ...prevFormData, departments: updatedDepartments };
            });
        }


    };

    const Preview = ({ content, styles }) => {
        const styledContent = addInlineStyles(content);

        return (
            <Box style={styles}>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: styledContent,
                    }}
                    sx={{
                        overflow: 'auto',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '16px',
                        lineHeight: '1.5',
                    }}
                />
            </Box>
        );
    };


    const handleSelectAll = (e, type) => {
        const isChecked = e.target.checked;
        switch (type) {
            case "departments":
                setDepartments(isChecked ? allDepartments?.map((d) => d._id) : []);
                setFormData((prevFormData) => {
                    // Determine the updated categories
                    const updatedDepartments = isChecked ? allDepartments?.map((d) => d._id) : []
                    return { ...prevFormData, departments: updatedDepartments };
                });
                break;
            case "locations":
                setSelectedLocation(isChecked ? allLocations?.map((l) => l._id) : []);
                setFormData((prevFormData) => {
                    // Determine the updated categories
                    const updatedLocation = isChecked ? allLocations?.map((l) => l._id) : []
                    return { ...prevFormData, locations: updatedLocation };
                });
                break;
            case "buildings":
                setSelectedBuilding(isChecked ? filteredBuildings?.map((b) => b._id) : []);
                setFormData((prevFormData) => {
                    // Determine the updated categories
                    const updatedBuildings = isChecked ? filteredBuildings?.map((b) => b._id) : []
                    return { ...prevFormData, buildings: updatedBuildings };
                });
                break;
            case "categories":
                setSelectedCategories(isChecked ? categories?.map((c) => c._id) : []);
                setFormData((prevFormData) => {
                    // Determine the updated categories
                    const updatedCategories = isChecked ? categories?.map((c) => c._id) : []
                    return { ...prevFormData, categories: updatedCategories };
                });
                break;
            default:
                break;
        }
    };


    const isAllSelected = (type) => {
        switch (type) {
            case "departments":
                return departments?.length === allDepartments?.length;
            case "locations":
                return selectedLocation?.length === allLocations?.length;
            case "buildings":
                if (selectedBuilding.length > 0 && filteredBuildings.length > 0) {
                    return selectedBuilding?.length === filteredBuildings?.length;

                }
            case "categories":
                return selectedCategories?.length === categories?.length;
            default:
                return false;
        }
    };

    return (
        <>
            <CustomTypography
                sx={{
                    marginTop: "10px",
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem',
                    width: '100%',
                    maxHeight: '100vh', // Restrict height of the container
                    backgroundColor: background_color(),
                }}
            >
                <Typography
                    variant="h6"
                    color={textColor()}
                > Document Form </Typography>
                <Grid container
                    sx={{
                        // margin: "10px",
                        height: "89vh",
                        width: "100%",
                        overflow: "auto"

                    }}>
                    <Grid item xs={12} md={6}
                        sx={{
                            // border: "1px solid red",
                            // height: "100vh"

                        }} >
                        <Paper
                            sx={{
                                padding: "3rem",
                                height: "89vh",
                                background: background_color(),
                                overflow: "auto"
                            }}
                        >
                            <FormInput
                                label="Document Title"
                                name="title"
                                fullWidth
                                value={title}
                                sx={{ ...commonSX, color: textColor(), background: background_color }}
                                onChange={handleChange}
                                error={!!errors.title}
                                helperText={errors?.title || ''}

                            />
                            <Typography sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>Select following Attributes for the Document</Typography>

                            <FormControl
                                component="fieldset"
                                sx={{
                                    width: "100%",
                                    // mt: 2,
                                    ...commonSX,
                                    background: background_color,
                                    color: textColor(),
                                    // padding: 2, // Add padding for better spacing
                                    borderRadius: "8px", // Optional: Add rounded corners
                                    // border: `1px solid ${textColor()}` // Optional: Add a border for better visibility
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 1
                                }}>

                                </Box>
                                <Paper
                                    sx={{
                                        maxHeight: '300px', // Set a max height for the Paper
                                        overflow: 'auto', // Enable scrolling when content overflows
                                        padding: '1rem', // Add some padding for better spacing
                                        ...commonSX,
                                        background: background_color
                                    }}

                                >
                                    <Typography>Departments</Typography>

                                    <FormGroup
                                        sx={{
                                            display: 'flex', // Use flexbox
                                            flexDirection: 'row', // Arrange items in a row
                                            flexWrap: 'wrap', // Allow wrapping to the next line if necessary
                                            ...commonSX,
                                            background: background_color
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAllSelected("departments")}
                                                    onChange={(e) => handleSelectAll(e, "departments")}
                                                    sx={{
                                                        ...commonSX,
                                                        background: background_color,
                                                        color: 'primary',
                                                        '&.Mui-checked': {
                                                            color: 'primary',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="All"
                                            sx={{
                                                color: textColor(),
                                                padding: "0.3rem",
                                            }}
                                        />

                                        {allDepartments.map((department) => (
                                            <FormControlLabel
                                                key={department?._id}
                                                control={
                                                    <Checkbox
                                                        // checked={allBuildings.includes(building._id) || false}
                                                        checked={departments.includes(department?._id) || false}
                                                        onChange={(e) => handleCheckBoxChange(e, department?._id)}
                                                        name="departments"
                                                        sx={{
                                                            color: 'primary', // Default color
                                                            '&.Mui-checked': {
                                                                color: 'primary', // Color when checked
                                                            },
                                                            ...commonSX
                                                        }}
                                                    />
                                                }
                                                label={department?.name}
                                                sx={{
                                                    color: textColor(),
                                                    padding: "0.3rem"
                                                }}
                                            />
                                        ))}
                                    </FormGroup>


                                </Paper>


                            </FormControl>

                            <FormControl
                                component="fieldset"
                                sx={{
                                    width: "100%",
                                    // mt: 2,
                                    ...commonSX,
                                    background: background_color,
                                    color: textColor(),
                                    borderRadius: "8px", // Optional: Add rounded corners
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>

                                </Box>
                                <Paper
                                    sx={{
                                        maxHeight: '300px', // Set a max height for the Paper
                                        overflow: 'auto', // Enable scrolling when content overflows
                                        padding: '1rem', // Add some padding for better spacing
                                        ...commonSX,
                                        background: background_color
                                    }}

                                >
                                    <Typography>Locations</Typography>
                                    <FormGroup
                                        sx={{
                                            display: 'flex', // Use flexbox
                                            flexDirection: 'row', // Arrange items in a row
                                            flexWrap: 'wrap', // Allow wrapping to the next line if necessary
                                            // padding: "1rem"
                                            // gap: 2, // Add spacing between items
                                            ...commonSX,
                                            background: background_color
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAllSelected("locations")}
                                                    onChange={(e) => handleSelectAll(e, "locations")}
                                                    sx={{
                                                        color: 'primary',
                                                        '&.Mui-checked': {
                                                            color: 'primary',
                                                            ...commonSX,
                                                            background: background_color
                                                        },
                                                    }}
                                                />
                                            }
                                            label="All"
                                            sx={{
                                                color: textColor(),
                                                padding: "0.3rem",
                                            }}
                                        />
                                        {allLocations.map((location) => (
                                            <FormControlLabel
                                                key={location._id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedLocation.includes(location._id) || false}
                                                        onChange={(e) => handleCheckBoxChange(e, location._id)}
                                                        name="locations"
                                                        sx={{
                                                            color: 'primary', // Default color
                                                            '&.Mui-checked': {
                                                                color: 'primary', // Color when checked
                                                            },
                                                            ...commonSX
                                                        }}
                                                    />
                                                }
                                                label={location.name}
                                                sx={{
                                                    color: textColor(),
                                                    padding: "0.3rem"
                                                }}
                                            />
                                        ))}
                                    </FormGroup>


                                </Paper>


                            </FormControl>

                            <FormControl
                                component="fieldset"
                                sx={{
                                    width: "100%",
                                    // mt: 2,
                                    ...commonSX,
                                    background: background_color,
                                    color: textColor(),
                                    // padding: 2, // Add padding for better spacing
                                    borderRadius: "8px", // Optional: Add rounded corners
                                    // border: `1px solid ${textColor()}` // Optional: Add a border for better visibility
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    //  mb: 1 
                                }}>

                                </Box>
                                <Paper
                                    sx={{
                                        maxHeight: '300px', // Set a max height for the Paper
                                        overflow: 'auto', // Enable scrolling when content overflows
                                        padding: '1rem', // Add some padding for better spacing
                                        ...commonSX,
                                        background: background_color
                                    }}

                                >
                                    <Typography>Buildings</Typography>
                                    <FormGroup
                                        sx={{
                                            display: 'flex', // Use flexbox
                                            flexDirection: 'row', // Arrange items in a row
                                            flexWrap: 'wrap', // Allow wrapping to the next line if necessary
                                            // padding: "1rem"
                                            // gap: 2, // Add spacing between items
                                            ...commonSX,
                                            background: background_color
                                        }}
                                    >
                                        {
                                            selectedLocation.length > 0 ?
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isAllSelected("buildings")}
                                                            onChange={(e) => handleSelectAll(e, "buildings")}
                                                            sx={{
                                                                color: 'primary',
                                                                '&.Mui-checked': {
                                                                    color: 'primary',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label="All"
                                                    sx={{
                                                        color: textColor(),
                                                        padding: "0.3rem",
                                                    }}
                                                /> :
                                                <Typography
                                                    // color="primary"
                                                    sx={{
                                                        fontSize: "11px",
                                                        padding: "13px",
                                                        color: (theme) => `${theme.palette.primary.main} !important`,
                                                    }}
                                                >select atleast one location</Typography>
                                        }

                                        {filteredBuildings.map((building) => (
                                            <FormControlLabel
                                                key={building._id}
                                                control={
                                                    <Checkbox
                                                        // checked={allBuildings.includes(building._id) || false}
                                                        checked={selectedBuilding.includes(building._id) || false}
                                                        onChange={(e) => handleCheckBoxChange(e, building._id)}
                                                        name="buildings"
                                                        sx={{
                                                            color: 'primary', // Default color
                                                            '&.Mui-checked': {
                                                                color: 'primary', // Color when checked
                                                            },
                                                            ...commonSX
                                                        }}
                                                    />
                                                }
                                                label={building.name}
                                                sx={{
                                                    color: textColor(),
                                                    padding: "0.3rem"
                                                }}
                                            />
                                        ))}
                                    </FormGroup>

                                </Paper>



                            </FormControl>

                            <FormControl
                                component="fieldset"
                                sx={{
                                    width: "100%",
                                    // mt: 2,
                                    ...commonSX,
                                    background: background_color,
                                    color: textColor(),
                                    // padding: 2, // Add padding for better spacing
                                    borderRadius: "8px", // Optional: Add rounded corners
                                    // border: `1px solid ${textColor()}` // Optional: Add a border for better visibility
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>

                                </Box>
                                <Paper
                                    sx={{
                                        maxHeight: '300px', // Set a max height for the Paper
                                        overflow: 'auto', // Enable scrolling when content overflows
                                        padding: '1rem', // Add some padding for better spacing
                                        ...commonSX,
                                        background: background_color
                                    }}

                                >
                                    <Typography>Categories</Typography>
                                    <FormGroup
                                        sx={{
                                            display: 'flex', // Use flexbox
                                            flexDirection: 'row', // Arrange items in a row
                                            flexWrap: 'wrap', // Allow wrapping to the next line if necessary
                                            // padding: "1rem"
                                            // gap: 2, // Add spacing between items
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAllSelected("categories")}
                                                    onChange={(e) => handleSelectAll(e, "categories")}
                                                    sx={{
                                                        color: 'primary',
                                                        '&.Mui-checked': {
                                                            color: 'primary',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="All"
                                            sx={{
                                                color: textColor(),
                                                padding: "0.3rem",
                                            }}
                                        />
                                        {categories.map((category) => (
                                            <FormControlLabel
                                                key={category._id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedCategories.includes(category._id)}
                                                        onChange={(e) => handleCheckBoxChange(e, category._id)}
                                                        name="categories"
                                                        sx={{
                                                            color: 'primary', // Default color
                                                            '&.Mui-checked': {
                                                                color: 'primary', // Color when checked
                                                            },
                                                            ...commonSX
                                                        }}
                                                    />
                                                }
                                                label={category.name}
                                                sx={{
                                                    color: textColor(),
                                                    padding: "0.3rem"
                                                }}
                                            />
                                        ))}
                                    </FormGroup>


                                </Paper>


                            </FormControl>

                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                {/* Input for new tag */}
                                <FormInput
                                    label="Add a tag"
                                    name="tags"
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    onKeyDown={handleAddTag} // Add tag on Enter
                                    variant="outlined"
                                    sx={{ flexGrow: 1, ...commonSX }} // Makes it flexible to take available 
                                />
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={handleAddTag} // Add tag on button click
                                >
                                    Add Tag
                                </Button>


                            </Box>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                {tags.map((tag, i) => (
                                    <Chip
                                        key={i}
                                        sx={{ background: themeMode ? '#71717171' : '#e0e0e0e' }}
                                        label={tag}
                                        onDelete={() => handleDeleteTag(tag)} // Delete tag
                                    />
                                ))}
                            </Box>


                        </Paper>


                    </Grid >
                    <Grid item
                        xs={12}
                        md={6}
                    // sx={{ border: "1px solid green" }}
                    >
                        <Paper
                            sx={{
                                padding: "3rem",
                                height: "89vh",
                                background: background_color,
                                color: textColor()
                            }}
                        >
                            <Typography variant="h6">Create Your Own Document</Typography>

                            <QuillContent content={content} setContent={setContent} setFormData={setFormData} setModal={setModal} />
                            {errors?.content && <span style={{ color: "red" }}>{errors?.content}</span>}


                            <Box
                                sx={{
                                    marginTop: "1rem",
                                    // border: "1px solid blue"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '25%',
                                        // height: '2.5rem',
                                        marginRight: "35px",
                                        // marginLeft: "15px"
                                    }}
                                    onClick={handlePreview}
                                >Preview
                                </Button>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#dc4d28',
                                        '&:hover': { backgroundColor: '#c43e2d' },
                                        width: '25%',
                                        marginRight: "35px",
                                        // marginLeft: "15px"
                                    }}
                                    onClick={handleSubmit}
                                > {isEditMode ? "Edit" : "Submit"}</Button>
                                <Button
                                    variant='contained'
                                    onClick={
                                        handleCancel
                                    }
                                    sx={{
                                        bgcolor: '#4B4B4B',
                                        '&:hover': {
                                            backgroundColor: '#2E2E2E',
                                        },
                                        borderColor: '#4B4B4B',
                                        width: '25%',


                                    }}


                                >Cancel</Button>

                            </Box>

                        </Paper>

                    </Grid>

                </Grid>
            </CustomTypography >

            <Modal
                modalOpen={modal}
                closeModal={closePreview}
                height="62vh"
                modalWidth="80vw" // Matches the width of the view page
                aria-labelledby="preview-modal-title"
                aria-describedby="preview-modal-description"
            >
                <Preview content={content} styles={commonStyles(themeMode, textColor, '80vw')} />
            </Modal>

        </>

    )

}
export default DocumentForm