export const dropdownFloorsSort = (array) => {
    return array.sort((a, b) => {
        if (a.name?.startsWith('Ground') && (b.name?.startsWith('First') || b.name?.startsWith('Second') || b.name?.startsWith('Terrace'))) {
            return -1
        }
        if (a.name?.startsWith('First') && (b.name?.startsWith('Second') || b.name?.startsWith('Terrace'))) {
            return -1
        }
        if (a.name?.startsWith('Second') && b.name?.startsWith('Terrace')) {
            return -1
        }
    })
}