import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const FileInput = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    const { accept, buttonText, file, name, color: propsColor, borderColor, width, height, pickHandler, allowMultiple } = props

    const setFile = (event) => {
        if (event.target.files && event.target.files.length !== 0) {
            pickHandler && pickHandler(event)
        }
    }

    return (
        <Stack direction={'row'}>
            <Button variant="outlined" component="label" sx={{
                textTransform: 'none', color: propsColor || color, borderColor: borderColor ||
                    color, fontWeight: 'normal', width, height, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
            }}>
                {buttonText}
                <input hidden accept={accept} type="file" onChange={setFile} name={name} multiple={allowMultiple} />
            </Button>
            <Box>
                {
                    file && Object.keys(file)?.map((ele, i) => (
                        <Typography key={i}>&nbsp;&nbsp;{file[ele].name}</Typography>
                    ))
                }
            </Box>
        </Stack>
    )
}

export default FileInput