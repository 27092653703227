import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchEnergySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // dashboard 
        getEnergySourceConsumptionEmission: builder.query({
            query: (bid) => ({
                // remove -v2 while merging with development branch
                url: `twin/v1/energy/v2/s1/stats?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Dashboard Data')
            },
            providesTags: ['energy']
        }),
        getEnergySourceConsumptionEmissionAverage: builder.query({
            query: (bid) => ({
                // remove -v2 while merging with development branch
                url: `twin/v1/energy/v2/s1/stats?bid=${bid}&type=average`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Dashboard Average Data')
            },
            providesTags: ['energy']
        }),
        getEnergyWeekConsumption: builder.query({
            query: (bid) => ({
                // remove -v2 while merging with development branch
                // url: `twin/v1/energy/s1/data?bid=${bid}&duration=week`,
                url: `twin/v1/energy/v2/s1/consumption/chart?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Week Consumption')
            },
            providesTags: ['energy']
        }),

        // secondary screens
        getEnergySecondaryOptions: builder.query({
            query: (bid) => ({
                // remove -v2 while merging with development branch
                url: `twin/v1/energy/v2/s2/options?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Options')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryStats: builder.query({
            query: ({ bid, groupId, subGroupId, sensorId, duration, startDate, endDate }) => ({
                // remove -v2 while merging with development branch
                url: `twin/v1/energy/v2/s2/stats?bid=${bid}&duration=${duration}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${groupId ? `&group=${groupId}` : ""}${subGroupId ? `&subGroup=${subGroupId}` : ""}${sensorId ? `&sensor_id=${sensorId}` : ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Stats')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryEstimation: builder.query({
            query: ({ bid, groupId, subGroupId, sensorId }) => ({
                url: `twin/v1/energy/v2/s2/forecast?bid=${bid}${groupId ? `&group=${groupId}` : ""}${subGroupId ? `&subGroup=${subGroupId}` : ""}${sensorId ? `&sensor_id=${sensorId}` : ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Estimation')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryTotalConsumptionChartData: builder.query({
            query: ({ bid, groupId, subGroupId, sensorId, duration, startDate, endDate }) => ({
                url: `twin/v1/energy/v2/s2/consumption/chart/history?bid=${bid}&duration=${duration}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${groupId ? `&group=${groupId}` : ""}${subGroupId ? `&subGroup=${subGroupId}` : ""}${sensorId ? `&sensor_id=${sensorId}` : ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Total Consumption Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryMonthwiseTotalConsumptionChartData: builder.query({
            query: ({ bid, groupId, subGroupId, sensorId }) => ({
                url: `twin/v1/energy/v2/s2/consumption/chart/monthwise?bid=${bid}${groupId ? `&group=${groupId}` : ""}${subGroupId ? `&subGroup=${subGroupId}` : ""}${sensorId ? `&sensor_id=${sensorId}` : ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Monthwise Total Consumption Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryParameters: builder.query({
            query: ({ bid, groupId, subGroupId, sensorId, duration, startDate, endDate }) => ({
                url: `twin/v1/energy/v2/s2/parameters?bid=${bid}&duration=${duration}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${groupId ? `&group=${groupId}` : ""}${subGroupId ? `&subGroup=${subGroupId}` : ""}${sensorId ? `&sensor_id=${sensorId}` : ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Parameters')
            },
            providesTags: ['energy']
        }),
        getEnergyGroupsStatisticsData: builder.query({
            query: ({ bid, duration, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/groups?bid=${bid}&duration=${duration}&startDate=${startDate}&endDate=${endDate}`,
                method: "GET",
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Groups Statistics Data')
            },
            providesTags: ['energy']
        })
    })
})

const initialState = {
    dashboard: {
        energyMessage: "",
        energyConsumption: {
            isLoaded: false,
            data: {},
            avgIsLoaded: false,
            avgData: {},
        },
        energySources: {
            isLoaded: false,
            data: {},
        },
        energyCarbonEmissions: {
            isLoaded: false,
            data: {},
            avgIsLoaded: false,
            avgData: {},
        },
        energyWeek: {
            isLoaded: false,
            data: []
        },
        sseData: {},
    },
    secondary: {
        estimationData: {
            isLoaded: false,
            error: "",
            currentConsumption: 0,
            monthEstimation: 0
        },
        totalConsumptionChartData: {
            isLoaded: false,
            error: "",
            data: [],
            previousData: []
        },
        monthwiseTotalConsumptionChartData: {
            isLoaded: false,
            error: "",
            data: {},
        },
        groupStatistics: {
            isLoaded: false,
            error: "",
            data: []
        },

        // new api changes
        options: {
            groups: {
                isLoaded: false,
                data: [],
                selectedGroup: {},
                selectedSubGroup: {},
                showIndividualMeters: false,
                selectedMeter: {},
            },
            durations: {
                isLoaded: false,
                data: [],
                selected: 'today'
            }
        },
        stats: {
            isLoaded: false,
            data: {}
        },
        parameters: {
            isLoaded: false,
            data: {}
        }
    }
}

const energySlice = createSlice({
    name: 'energy',
    initialState,
    reducers: {
        resetDashboardEnergy: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        setEnergySecondaryDuration: (state, action) => {
            if (state.secondary.options.durations.selected !== action.payload.duration) {
                state.secondary.options.durations.selected = action.payload.duration

                state.secondary.stats.isLoaded = false
                state.secondary.totalConsumptionChartData.isLoaded = false
                if (state.secondary.options.groups.selectedMeter?.id) {
                    state.secondary.parameters.isLoaded = false
                } else if (!state.secondary.options.groups.selectedMeter?.id) {
                    state.secondary.parameters.data = initialState.secondary.parameters.data
                }
            }
        },
        setEnergySecondaryGroup: (state, action) => {
            state.secondary.options.groups.selectedGroup = action.payload
            state.secondary.options.groups.showIndividualMeters = false
            if (action.payload?.id === 'meters') {
                state.secondary.options.groups.showIndividualMeters = true
            }
            state.secondary.options.groups.selectedMeter = {}
            state.secondary.options.groups.selectedSubGroup = action.payload?.subGroups?.length > 0 ? action.payload?.subGroups?.[0] : {}

            state.secondary.stats.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            if (state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.isLoaded = false
            } else if (!state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.data = initialState.secondary.parameters.data
            }
        },
        setShowIndividualMeters: (state) => {
            const previousState = state.secondary.options.groups.showIndividualMeters
            state.secondary.options.groups.showIndividualMeters = !previousState
            if (!previousState) {
                if (state.secondary.options.groups.selectedSubGroup?.subGroupSensors?.length > 1) {
                    state.secondary.options.groups.selectedMeter = state.secondary.options.groups.selectedSubGroup?.subGroupSensors?.[0]
                }
                else if (state.secondary.options?.groups?.selectedGroup?.groupSensors?.length > 1) {
                    state.secondary.options.groups.selectedMeter = state.secondary.options?.groups?.selectedGroup?.groupSensors?.[0]
                }
            } else {
                state.secondary.options.groups.selectedMeter = {}
            }

            state.secondary.stats.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            if (state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.isLoaded = false
            } else if (!state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.data = initialState.secondary.parameters.data
            }
        },
        setEnergySecondaryMeter: (state, action) => {
            state.secondary.options.groups.selectedMeter = action.payload

            state.secondary.stats.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            if (state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.isLoaded = false
            } else if (!state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.data = initialState.secondary.parameters.data
            }
        },
        setEnergySecondarySubGroup: (state, action) => {
            state.secondary.options.groups.selectedSubGroup = action.payload
            state.secondary.options.groups.showIndividualMeters = false
            state.secondary.options.groups.selectedMeter = {}

            state.secondary.stats.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            if (state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.isLoaded = false
            } else if (!state.secondary.options.groups.selectedMeter?.id) {
                state.secondary.parameters.data = initialState.secondary.parameters.data
            }
        },

        resetEnergySecondaryData: (state, action) => {
            state.secondary = initialState.secondary
        },
        updateSSEEnergyConsumption: (state, action) => {
            if (!state.dashboard.energyMessage) {
                state.dashboard.energyConsumption.data.today = Number((state.dashboard.energyConsumption.data.today + action.payload?.energyDifference).toFixed(2))
                state.dashboard.energySources.data.grid = Number((state.dashboard.energySources.data.grid + action.payload?.energyDifference).toFixed(2))
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySourceConsumptionEmission' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (action?.payload?.message) {
                        state.dashboard.energyMessage = action.payload?.message
                        state.dashboard.energyConsumption.isLoaded = true
                        state.dashboard.energySources.isLoaded = true
                        state.dashboard.energyCarbonEmissions.isLoaded = true
                    } else {
                        state.dashboard.energyMessage = ""
                        state.dashboard.energyConsumption.isLoaded = true
                        state.dashboard.energyConsumption.data = action.payload?.consumption
                        state.dashboard.energySources.isLoaded = true
                        state.dashboard.energySources.data = action.payload?.source
                        state.dashboard.energyCarbonEmissions.isLoaded = true
                        state.dashboard.energyCarbonEmissions.data = action.payload?.co2
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySourceConsumptionEmissionAverage' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energyConsumption.avgIsLoaded = true
                    state.dashboard.energyConsumption.avgData = action.payload?.consumption
                    state.dashboard.energyCarbonEmissions.avgIsLoaded = true
                    state.dashboard.energyCarbonEmissions.avgData = action.payload?.co2
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyWeekConsumption' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energyWeek.isLoaded = true
                    if (action?.payload?.message) {
                        state.dashboard.energyMessage = action.payload?.message
                    } else {
                        state.dashboard.energyMessage = ""
                        state.dashboard.energyWeek.data = action.payload
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.options.groups.isLoaded = true
                    state.secondary.options.groups.data = action.payload?.groups
                    state.secondary.options.groups.selectedGroup = action.payload?.groups?.[0]
                    if (action.payload?.groups?.[0]?.subGroups?.length > 0) {
                        state.secondary.options.groups.selectedSubGroup = action.payload?.groups?.[0]?.subGroups?.[0]
                    }
                    state.secondary.options.durations.isLoaded = true
                    state.secondary.options.durations.data = action.payload?.durations

                    // parameters call only to be made while an individual meter is selected
                    state.secondary.parameters.isLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.stats.isLoaded = true
                    state.secondary.stats.data = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryEstimation' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.estimationData.isLoaded = true
                    state.secondary.estimationData.currentConsumption = Number((action.payload?.thisMonthConsumption).toFixed(2))
                    state.secondary.estimationData.monthEstimation = Number((action.payload?.forecastForMonth).toFixed(2))
                    state.secondary.estimationData.comparisonPercentWithLastMonth = Number((action.payload?.percentageDelta).toFixed(2))
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const previousData = {
                        name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Yesterday` :
                            state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'Previous Week' :
                                state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'Previous Month' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'Previous Year' : 'Consumption'}`,
                        type: 'spline',
                        data: action.payload?.previousPeriodData?.map(ele => {
                            return (!ele?.x) ? { x: null, ...ele } : ele
                        }),
                        tooltip: {
                            valueSuffix: ' kWh'
                        }
                    }

                    const weatherChartData = {
                        name: 'External Weather',
                        type: 'spline',
                        data: action.payload?.weatherData,
                        yAxis: 1,
                        tooltip: {
                            valueSuffix: ' °C'
                        }
                    }

                    const currentData = {
                        name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                            state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'Consumption'}`,
                        type: 'column',
                        data: action.payload?.currentPeriodData?.map(ele => {
                            return (!ele?.x) ? { x: null, ...ele } : ele
                        }),
                        tooltip: {
                            valueSuffix: ' kWh'
                        }
                    }

                    const durationIfCustom = state.secondary?.options?.durations?.selected?.toLowerCase() === 'custom'
                    state.secondary.totalConsumptionChartData.isLoaded = true
                    state.secondary.totalConsumptionChartData.data = durationIfCustom ? [currentData] : [currentData, previousData, weatherChartData]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryMonthwiseTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.monthwiseTotalConsumptionChartData.isLoaded = true
                    state.secondary.monthwiseTotalConsumptionChartData.data = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryParameters' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.parameters.isLoaded = true
                    const formattedData = {
                        ...action.payload,
                        powerFactor: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'Average Power Factor'}`, data: action.payload?.powerFactor
                        }],
                        voltage: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'Voltage'}`, data: action.payload?.voltage
                        }],
                        current: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'Current'}`, data: action.payload?.current
                        }],
                        frequency: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'Frequency'}`, data: action.payload?.frequency
                        }],
                        ryVoltage: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'RY Voltage'}`, data: action.payload?.ryVoltage
                        }],
                        ybVoltage: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'YB Voltage'}`, data: action.payload?.ybVoltage
                        }],
                        brVoltage: [{
                            name: `${state.secondary.options.durations.selected?.toLowerCase() === 'today' ? `Today` :
                                state.secondary.options.durations.selected?.toLowerCase() === 'week' ? 'This Week' :
                                    state.secondary.options.durations.selected?.toLowerCase() === 'month' ? 'This Month' :
                                        state.secondary.options.durations.selected?.toLowerCase() === 'year' ? 'This Year' : 'BR Voltage'}`, data: action.payload?.brVoltage
                        }],
                    }
                    state.secondary.parameters.data = formattedData
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyGroupsStatisticsData' && action?.meta?.requestStatus === "fulfilled",
                (state, action) => {
                    state.secondary.groupStatistics.isLoaded = true
                    state.secondary.groupStatistics.data = action.payload
                }
            )
    }
})

export const {
    useGetEnergySourceConsumptionEmissionQuery,
    useGetEnergySourceConsumptionEmissionAverageQuery,
    useGetEnergyWeekConsumptionQuery,

    // Secondary screens
    useGetEnergySecondaryOptionsQuery,
    useGetEnergySecondaryStatsQuery,
    useGetEnergySecondaryEstimationQuery,
    useGetEnergySecondaryTotalConsumptionChartDataQuery,
    useGetEnergySecondaryMonthwiseTotalConsumptionChartDataQuery,
    useGetEnergySecondaryParametersQuery,
    useGetEnergyGroupsStatisticsDataQuery,
} = fetchEnergySlice

export const { resetDashboardEnergy, setEnergySecondaryDuration, setEnergySecondaryGroup, setShowIndividualMeters, setEnergySecondaryMeter, setEnergySecondarySubGroup, resetEnergySecondaryData, updateSSEEnergyConsumption } = energySlice.actions

export default energySlice.reducer