import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { ThemeColors } from '../../hooks/ThemeColors';

const CustomFormField = (props) => {
    const { textColor } = ThemeColors();
    const { className, sx, onChange, focused, value, ...rest } = props;

    console.count('form field');

    return (
        <TextField
            className={className}
            onChange={onChange}
            focused={(focused || value)}
            value={value}
            {...rest}
            fullWidth
            sx={{
                '& .MuiFormLabel-root': { color: textColor() },
                '& .MuiInputBase-input': {
                    color: `${textColor()} !important`
                },
                ...sx
            }}
        />
    );
};

export default memo(CustomFormField, (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
});
