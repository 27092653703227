import React, { memo, useContext } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { ChatContext } from '../context/ChatContext';
import { POST } from '../../../helpers/http';
import { errorToast } from '../../../helpers/apiToast';
import { ThemeColors } from '../../../hooks/ThemeColors';

const CustomTextArea = ({ handleSubmit, question, setQuestion, getTextResponse }) => {
    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { interfaceChat } = chatInfo;

    const { textColor } = ThemeColors();

    const handleChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleNewChat = async ({ question, updatedConversation }) => {
        try {
            const response = await POST(`${process.env.REACT_APP_MAVERICK_URL}chat`, { building_id: localStorage.getItem('bid') }, 'New Chat')
            if (response?.status === 200) {
                chatDispatch({ type: 'SET_NEW_CHAT_ID', payload: response?.data?.chat_id });
                getTextResponse({ question, updatedConversation, newChatId: response?.data?.chat_id });
            }
        } catch (error) {
            if (error?.response?.data?.detail) {
                error?.response?.data?.detail?.forEach((el) => errorToast(el.msg));
            } else {
                error?.message && errorToast(error?.message);
            }
        }
    }

    const handleKeyPress = ({ e, question }) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            let updatedConversation;
            updatedConversation = [{ content: question, role: 'user' }, { content: 'loading', role: 'assistant' }];
            setQuestion('');
            e.preventDefault();
            if (question && Object.keys(interfaceChat)?.length === 0) {
                chatDispatch({ type: 'NEW_INTERFACE_CHAT', payload: updatedConversation })
                handleNewChat({ question, updatedConversation });
                return;
            }
            else {
                updatedConversation = {
                    ...interfaceChat, conversation: [...interfaceChat?.conversation,
                    { content: question, role: 'user' },
                    { content: 'loading', role: 'assistant' }]
                };
                chatDispatch({ type: 'SET_INTERFACE_CHAT', payload: updatedConversation })
                handleSubmit({ e, question, updatedConversation });
            }
        }
    };

    // const speakText = (question) => {
    //     if (question) {
    //         const utterance = new SpeechSynthesisUtterance(question);
    //         window.speechSynthesis.speak(utterance);
    //     }
    // };

    return (
        <Box component={'form'} onSubmit={(e) => { handleSubmit({ e, question }) }}
            sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '20rem',
                height: '100%', width: '100%', overflowY: 'auto'
            }}
        >
            <TextareaAutosize
                placeholder='Ask your question here....'
                value={question}
                onChange={handleChange}
                onKeyPress={(e) => { handleKeyPress({ e, question }) }}
                style={{
                    backgroundColor: 'transparent',
                    fontFamily: 'inherit',
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    resize: 'none',
                    paddingLeft: '1.5rem',
                    color: textColor(),
                    fontSize: '1rem',
                    lineHeight: '1.5', // Adjust line height for better vertical centering
                    boxSizing: 'border-box', // Ensure padding is included in total height/width
                }}
                sx={{
                    '&:focus': {
                        outline: 'none',
                    },
                    '&::placeholder': {
                        color: textColor(),
                        lineHeight: '1.5', // Ensure line height matches the content
                    },
                }}
            />
            {/* <button onClick={() => { speakText(interfaceChat[interfaceChat?.length-1]?.response) }}>Read Aloud</button> */}
        </Box>
    );
}

export default memo(CustomTextArea);
