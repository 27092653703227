import React from 'react'
import { Stack, Typography } from '@mui/material'
import { ConstructionOutlined } from '@mui/icons-material'

const UnderMaintenance = (props) => {
    return (
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: props?.height }} spacing={1}>
            <ConstructionOutlined sx={{ fontSize: '40px' }} />
            <Typography sx={{ fontSize: '18px' }}>{`The service is under maintenance.`}</Typography>
            <Typography sx={{ fontSize: '16px' }}>{`Apologies for the inconvenience.`}</Typography>
        </Stack>
    )
}

export default UnderMaintenance