import { useSelector } from "react-redux";


const HighlightedText = ({ text }) => {
    const regex = /'([^']+)'/g;
    const themeMode = useSelector(state => state?.theme?.darkTheme)

    const getHighlightedText = (text) => {
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }
            parts.push(
                <span key={match.index}
                    style={{
                        backgroundColor: !themeMode ? 'whitesmoke' : 'gray',
                        padding: "3px", borderRadius: "5px", fontSize: "16px"
                    }
                    }>
                    {match[1]}
                </span >
            );
            lastIndex = regex.lastIndex;
        }
        if (lastIndex < text?.length) {
            parts.push(text.slice(lastIndex));
        }
        return parts;
    };

    return <div>{getHighlightedText(text)}</div>;
};

export default HighlightedText