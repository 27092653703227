import loadSecondModel from './loadSecondModel';
import $ from 'jquery'
// import '../extensions/CustomSidePanel.js';
import { asyncFetchAQIDataExternalId, camerafeed, resetAQISensorsData, toggleSlideInPanel } from '../../../../../../../redux/features/services/forgeSlice/forgeActions';

const Autodesk = window.Autodesk;
const THREE = window.THREE

function loadModel(viewer, urn, selectedNode, setSensorType, dispatch, handleOpenSidePanel, forge) {
    let roomsDbId = []
    let sidePanelInSplitScreen = false
    // const slideInPanelCallback = () => {
    //     dispatch(toggleSlideInPanel())
    // }
    function onDocumentLoadSuccess(doc) {
        function BuildingToolbarExtension(viewer, options) {
            Autodesk.Viewing.Extension.call(this, viewer, options);
        }

        BuildingToolbarExtension.prototype = Object.create(
            Autodesk.Viewing.Extension.prototype
        );
        BuildingToolbarExtension.prototype.constructor = BuildingToolbarExtension;

        BuildingToolbarExtension.prototype.load = function () {
            console.log('BuildingToolbarExtension is loaded')
            return true;
        };

        BuildingToolbarExtension.prototype.unload = function () {
            // nothing yet
            if (this.subToolbar) {
                console.log('BuildingToolbarExtension is unloaded')
                this.viewer.toolbar.removeControl(this.subToolbar);
                this.subToolbar = null;
            }
        };

        BuildingToolbarExtension.prototype.onToolbarCreated = async function (toolbar) {
            var viewer = this.viewer;

            const dataVizExtn = await viewer.loadExtension("Autodesk.DataVisualization");
            // Get Model level info
            let viewerDocument = viewer.model.getDocumentNode().getDocument();
            const aecModelData = await viewerDocument.downloadAecModelData();
            let levelsExt;
            if (aecModelData) {
                levelsExt = await viewer.loadExtension("Autodesk.AEC.LevelsExtension", {
                    doNotCreateUI: true,
                });
            }

            var homeButton = new Autodesk.Viewing.UI.Button('home-button-svg');
            homeButton.addClass('home-button-svg');
            homeButton.setToolTip('Home View');
            homeButton.onClick = async function (e) {
                await levelsExt.floorSelector.selectFloor()
                await viewer.setActiveNavigationTool('orbit')
                const UI = await viewer.getExtension('Autodesk.BimWalk');

                //Closing all the viewer panels
                await viewer.loadedExtensions["Autodesk.DocumentBrowser"]?.ui?.panel?.setVisible(false)
                await viewer.loadedExtensions["Autodesk.AEC.LevelsExtension"]?.levelsPanel?.setVisible(false)
                for (let i = 0; i <= 4; i++) {
                    await viewer.dockingPanels[i]?.setVisible(false)
                }

                const dataViz = await viewer.getExtension('Autodesk.DataVisualization')
                await dataViz.showHideViewables(false)
                await dataViz.removeSurfaceShading(viewer.getAllModels()?.[1])
                await dataViz.showTextures()
                await UI.deactivate();
                await viewer.showAll()
                await viewer.hide(roomsDbId, viewer.getAllModels()[1])//Should change
                setTimeout(() => { viewer.utilities.fitToView() }, 1200)

                // await viewer.restoreState(forge?.viewerState)
                viewer.setViewFromArray(forge.initialConfig?.initialAndHomeViewArray)//from config
                dispatch(resetAQISensorsData())
            };

            var dualScreen = new Autodesk.Viewing.UI.Button('Nested-viewer');
            dualScreen.addClass('split-screen-svg')
            dualScreen.setToolTip('Split-Screen')
            dualScreen.onClick = async function (event) {
                sidePanelInSplitScreen = !sidePanelInSplitScreen

                //Closing all the viewer panels
                await viewer.loadedExtensions["Autodesk.DocumentBrowser"]?.ui?.panel?.setVisible(false)
                await viewer.loadedExtensions["Autodesk.AEC.LevelsExtension"]?.levelsPanel?.setVisible(false)
                for (let i = 0; i <= 4; i++) {
                    await viewer.dockingPanels[i]?.setVisible(false)
                }

                if ($('#viewer1').width() === $('#forgeviewer').width()) {
                    $('#viewer1').width('50%')
                    $('#viewer2').css({ "display": "inline-block", })
                    // loadModel(viewer, urn, setSensorType, dispatch, handleOpenSidePanel, forge)
                    loadSecondModel(urn, roomsDbId);



                    viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async function () {
                        let instanceTree = await viewer.model.getData().instanceTree?.nodeAccess;
                        let allDbIdsStr = Object.keys(instanceTree?.dbIdToIndex);
                        if (instanceTree && allDbIdsStr) {
                            const dbIds = allDbIdsStr.map((id) => {
                                return parseFloat(id)
                            })
                            const result = Array.isArray(dbIds) ? dbIds.slice(2) : [dbIds].slice(2)
                            window.viewer2.isolate(result, window.viewer2.model)
                        }
                    })


                    viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function () {
                        const tree = viewer.model.getInstanceTree();
                        const frags = viewer.model.getFragmentList();
                        function listFragmentProperties(fragId) {
                            const objectIds = frags.getDbIds(fragId);
                            if (objectIds) {
                                window.viewer2.select(objectIds, window.viewer2.model)
                            }
                        }
                        if (tree) {
                            const selectedIds = viewer.getSelection();
                            for (const dbId of selectedIds) {
                                const fragIds = [];
                                tree.enumNodeFragments(dbId, listFragmentProperties, false);
                            }
                        }
                    })

                    viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, function (event) {
                        if (!event) return;

                        let { camera } = event;
                        let view = {
                            aspect: camera.aspect,
                            isPerspective: camera.isPerspective,
                            fov: camera.fov,
                            position: camera.position.clone(),
                            target: camera.target.clone(),
                            up: camera.up.clone(),
                            orthoScale: camera.orthoScale
                        };

                        let inverseOffsetMatrix = viewer?.model?.getInverseModelToViewerTransform();
                        let offsetMatrix = window.viewer2?.model?.getModelToViewerTransform();
                        if (offsetMatrix && inverseOffsetMatrix) {
                            view.position = view.position.applyMatrix4(inverseOffsetMatrix).applyMatrix4(offsetMatrix);
                            view.target = view.target.applyMatrix4(inverseOffsetMatrix).applyMatrix4(offsetMatrix);
                            view.up = view.up.applyMatrix4(inverseOffsetMatrix).applyMatrix4(offsetMatrix)
                            window.viewer2.impl.setViewFromCamera(view);
                        }
                    });
                } else {
                    $('#viewer1').width("100%")
                    $('#viewer2').css({ "display": "none" })
                }


            }

            var heatmap = new Autodesk.Viewing.UI.ComboButton('Heatmap')
            heatmap.setToolTip('Heatmap')
            heatmap.addClass('heatmap-button-svg')

            // Button 1
            var button1 = new Autodesk.Viewing.UI.Button('co2-button-svg', false);
            const levExt = viewer.loadedExtensions["Autodesk.AEC.LevelsExtension"]?.levelsPanel;
            // const dataVizExtn = viewer.getExtension("Autodesk.DataVisualization");
            button1.onClick = async (e) => {
                setSensorType('co2')
                await dataVizExtn.hideTextures()
                await viewer.isolate(roomsDbId, viewer.model)
                await levExt.setVisible(true)
                document.getElementById("LevelsPanel-0").style.left = "16px"
                document.getElementById("LevelsPanel-0").style.top = "50px"
            };
            button1.addClass('co2-button-svg');
            button1.setToolTip('co2');
            if (forge?.initialConfig?.heatmap?.co2) heatmap.addControl(button1)

            // Button 2
            var button2 = new Autodesk.Viewing.UI.Button('temperature-button-svg', false);
            button2.onClick = async (e) => {
                setSensorType('temperature')
                await dataVizExtn.hideTextures()
                await levExt.setVisible(true)
                await viewer.isolate(roomsDbId, viewer.model)
                document.getElementById("LevelsPanel-0").style.left = "16px"
                document.getElementById("LevelsPanel-0").style.top = "50px"
            };
            button2.addClass('temperature-button-svg');
            button2.setToolTip('Temperature');
            if (forge?.initialConfig?.heatmap?.temperature) heatmap.addControl(button2)

            // SubToolbar
            this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(
                'my-custom-toolbar'
            );
            if (Object.values(forge?.initialConfig?.heatmap).includes(true)) this.subToolbar.addControl(heatmap);
            this.subToolbar.addControl(homeButton);
            if (forge?.initialConfig?.isSplitScreenEnabled) this.subToolbar.addControl(dualScreen)
            toolbar.addControl(this.subToolbar);
        };

        Autodesk.Viewing.theExtensionManager.registerExtension('BuildingToolbarExtension', BuildingToolbarExtension);

        // viewer.loadDocumentNode(doc, forge?.initialConfig?.isDefaultView ? doc.getRoot().getDefaultGeometry(true, 1) : doc.getRoot().getDefaultGeometry()).then(async (model) => {

        const bubbleNode = selectedNode;
        viewer.loadDocumentNode(doc, !bubbleNode ? doc.getRoot().getDefaultGeometry() : bubbleNode,
            {
                // keepthemeModels: true,
                // preserveView: true,  // 2D drawings
                // modelSpace: true,    // 2D drawings
                // applyRefPoint: true, // 3D shared coordinates
            }
        ).then(async (model) => {

            // document.getElementById('toolbar-levelsTool').getElementsByClassName('adsk-button-icon')[0].childNodes[0].remove()
            // [position-x, position-y, position-z, target-x, target-y, target-z, up-x, up-y, up-z, aspect, fov (radians), orthoScale, isPerspective (0=perspective, 1=ortho)]
            forge.initialConfig?.initialAndHomeViewArray && await viewer.setViewFromArray(forge.initialConfig?.initialAndHomeViewArray)
            async function getDbId(externalId) {
                if (externalId) {
                    return new Promise(resolve => {
                        viewer.model?.getExternalIdMapping(data => {
                            let dbId = data[externalId]
                            return resolve(dbId)
                        })
                    })
                }
            }

            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async function (event) {
                console.log('Model has been loaded');
                document.getElementById('toolbar-levelsTool')?.getElementsByClassName('adsk-button-icon')[0]?.childNodes[0]?.remove()
                // await viewer.unloadExtension('Autodesk.Section');
                await viewer.unloadExtension('Autodesk.ViewerSettings')
                await doc.downloadAecModelData()
                await viewer.loadExtension('Autodesk.ViewCubeUi');
                // if (forge?.initialConfig?.isRoomPanelListEnabled) await viewer.loadExtension('Autodesk.ADN.MoveToRoomExtension');
                if (forge?.initialConfig?.isSplitScreenEnabled) await viewer.loadExtension('Autodesk.ADN.CameraTweenTool');
                await viewer.loadExtension('BuildingToolbarExtension');
                await viewer.loadExtension('Autodesk.AEC.LevelsExtension');
                // await viewer.loadExtension('Autodesk.AEC.Minimap3DExtension')

                const meetingRoomExtId = forge?.meetingRoomOccupancyData?.initialOccupiedData;
                const iconPromises = Object.keys(meetingRoomExtId).map(async area => {
                    return {
                        dbId: await getDbId(forge?.initialConfig?.meetingRoomOccupancySensors[area]),
                        label: '', // Empty label initially
                        css: 'fas fa-dot-circle',
                        area: area
                    };
                });

                const icons = await Promise.all(iconPromises);
                const filteredIcons = icons.filter(icon => icon.dbId !== undefined);
                let selectedIconId = null;

                // const sidePanelExt = await viewer.loadExtension('CustomSidePanelExtension', { callback: slideInPanelCallback });

                const markupExtension = await viewer.loadExtension('IconMarkupExtension', {
                    button: {
                        icon: 'fa-dot-circle',
                        tooltip: 'Show Room Labels'
                    },
                    icons: filteredIcons,
                    onClick: async (id) => {
                        if (selectedIconId) {
                            const prevIcon = structuredClone(filteredIcons).find(icon => icon.dbId === selectedIconId);
                            if (prevIcon) {
                                const labelElement = document.querySelector(`label[data-id="${selectedIconId}"]`);
                                if (labelElement) {
                                    const spanElement = labelElement.querySelector('span'); // Select the <span> element
                                    if (spanElement) {
                                        // Append the new content to the <span> element
                                        spanElement.innerHTML = '';
                                    }
                                }
                            }
                        }
                        const icon = structuredClone(filteredIcons).find(icon => icon.dbId === +id?.currentTarget?.dataset['id']);
                        if (icon) {
                            // Fetch and set the content for the clicked label
                            const areaData = meetingRoomExtId[icon.area]; // Assuming dbId corresponds to an area
                            if (areaData) {
                                // const content = `${areaData?.areaName} - ${areaData?.subarea} <br> Current Occupancy - ${areaData?.meetingRoomOccupancy}`;
                                const content = `
                                <div style="padding:10px">
                                    <p style="font-weight: 600; font-size:14px;text-align: center;margin:0;">Meeting Room Occupancy</p>
                                    <p style="margin:0; margin-top:5px; font-weight: 400;">Area : ${areaData?.areaName}</p>
                                    <p style="margin:0; margin-top:5px; font-weight: 400;">Floor : ${areaData?.floorName}</p>
                                    <p style="margin:0; margin-top:5px; font-weight: 400;">SubArea : ${areaData?.subarea}</p>
                                    <p style="margin:0; margin-top:5px; font-weight: 400;">Count : ${areaData?.meetingRoomOccupancy}</p>
                                </div>`
                                icon.label = content;

                                // Update the label's content in the extension
                                await markupExtension.updateIcons(id, {
                                    label: content
                                });

                                const labelElement = document.querySelector(`label[data-id="${+id?.currentTarget?.dataset['id']}"]`);

                                if (labelElement) {
                                    const spanElement = labelElement.querySelector('span'); // Select the <span> element
                                    if (spanElement) {
                                        // Append the new content to the <span> element
                                        spanElement.innerHTML = '';
                                        spanElement.innerHTML += content;
                                    }
                                }

                                // await viewer.impl.invalidate(true, true, true);
                                viewer.utilities.fitToView(); // Adjust view
                            }
                            selectedIconId = +id?.currentTarget?.dataset['id'];
                        }
                    }
                });
                // await viewer.loadExtension("NestedViewerExtension", { filter: ["2d"], crossSelection: true })

                // const dataVizExt = await viewer.loadExtension('Autodesk.DataVisualization');

                const forgeDeskData = forge?.deskOccupancyData
                const forgeMeetingRoomData = forge?.meetingRoomOccupancyData
                await viewer.search(
                    'Revit Rooms',
                    (dbIds) => { return roomsDbId.push(...dbIds) },
                    (error) => console.log(error),
                    ['Category'],
                    { searchHidden: true }
                );
                await viewer.search(
                    'Revit Spaces',
                    (spaces) => {
                        if (spaces.length > 0) {
                            return roomsDbId.push(...spaces)
                        }
                    },
                    (error) => console.log(error),
                    ['Category'],
                    { searchHidden: true }
                )
                let toolbar = await viewer.getToolbar(true);
                if (viewer.loadedExtensions["Autodesk.AEC.LevelsExtension"]?.floorSelector?._floors && forge?.initialConfig?.isDefaultView) {
                    viewer["loadedExtensions"]["Autodesk.AEC.LevelsExtension"]["floorSelector"]["_floors"][1]["zMax"] = -7.65//This for Adrianse
                    // viewer["loadedExtensions"]["Autodesk.AEC.LevelsExtension"]["floorSelector"]["_floors"][4]["zMax"] = 35 //to be fixed for adrianse
                }
                await toolbar.navTools?.removeControl("toolbar-cameraSubmenuTool")
                await viewer.autocam?.setCurrentViewAsHome(true)

                const documentPanel = document.getElementById("toolbar-documentModels")
                const levelPanel = document.getElementById('toolbar-levelsTool')
                if (levelPanel) {
                    levelPanel.addEventListener("click", (e) => {
                        document.getElementById("LevelsPanel-0").style.left = "16px";
                        document.getElementById("LevelsPanel-0").style.top = "80px";
                        // document.getElementById("LevelsPanel-0").style.width = "250px";
                    })
                }
                if (documentPanel) {
                    documentPanel.addEventListener('click', (e) => {
                        document.getElementById("lmv-document-extension_0").style.left = "16px";
                        document.getElementById("lmv-document-extension_0").style.top = "50px";
                        // document.getElementById("lmv-document-extension_0").style.width = "300px";
                    })
                }
                const levelScroll = document.getElementById("LevelsPanel-0-scroll-container")
                if (levelScroll) {
                    levelScroll.addEventListener('click', async (e) => {
                        const dbId = await getDbId("26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484440")
                        viewer.hide(dbId)
                        // [position-x, position-y, position-z, target-x, target-y, target-z, up-x, up-y, up-z, aspect, fov (radians), orthoScale, isPerspective (0=perspective, 1=ortho)]
                        viewer.setViewFromArray(forge?.initialConfig?.topDownViewArray)
                        setTimeout(() => { viewer.utilities.fitToView() }, 1500)
                    })
                }

                //Desk Occupancy Color
                if (Object.keys(forgeDeskData?.initialOccupiedData).length > 0) {
                    const configDeskData = forge.initialConfig?.deskOccupancySensors
                    for (let sensorId in configDeskData) {
                        const dbId = await getDbId(configDeskData[sensorId])
                        if (forgeDeskData?.initialOccupiedData[sensorId] === true) {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1))
                        } else if (forgeDeskData?.initialOccupiedData[sensorId] === "NA") {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(1, 1, 1, 1))
                        } else {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1))
                        }
                    }
                }
                // Meeting Room Color 
                if (Object.keys(forgeMeetingRoomData?.initialOccupiedData).length > 0) {
                    const configRoomData = forge.initialConfig?.meetingRoomOccupancySensors
                    for (let areaId in configRoomData) {
                        const dbId = await getDbId(configRoomData[areaId])
                        if (forgeMeetingRoomData?.initialOccupiedData[areaId]?.meetingRoomOccupancy > 0 && forgeMeetingRoomData?.initialOccupiedData[areaId]?.isLatest) {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1))
                        } else if (forgeMeetingRoomData?.initialOccupiedData[areaId]?.meetingRoomOccupancy === 0 && forgeMeetingRoomData?.initialOccupiedData[areaId]?.isLatest) {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1))
                        } else {
                            viewer.model.setThemingColor(dbId, new THREE.Vector4(1, 1, 1, 1))
                        }
                    }
                }


                await viewer.impl.invalidate(true, true, true);
                $('#forgeLoader').css({ "opacity": "0", "visibility": "hidden" });
                setTimeout(() => {
                    if ($('#forgeLoader').css('visibility') === "hidden") {
                        $('#forgeLoader').css({ "display": "none" })
                    }
                }, 2500)
                // const viewerState = await viewer.getState()
                // dispatch(setInitialViewerState(viewerState))
            });

            viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, function (event) {
                const propertiesPanel = viewer.loadedExtensions['Autodesk.PropertiesManager']
                if (propertiesPanel && propertiesPanel.getPanel() && propertiesPanel.getPanel().container) {
                    propertiesPanel.getPanel().container.dockRight = true;
                }
                if (sidePanelInSplitScreen === false) {
                    const dbId = event?.selections?.[0]?.dbIdArray?.[0]
                    if (dbId !== undefined && propertiesPanel?.isActive() === false && !forge?.initialConfig?.isDefaultView) {
                        propertiesPanel?.activate()
                    } else if (dbId === undefined && propertiesPanel?.isActive() === true) {
                        propertiesPanel?.deactivate();
                        return;
                    }

                    // viewer.model.getProperties(dbId, onSuccessCallback, onErrorCallback)
                    // viewer.model.getBulkProperties2(event.dbIdArray, { propFilter: ['name', 'externalId', 'floor'] }, onSuccessCallback, onErrorCallback)
                    (dbId && forge?.initialConfig?.isDefaultView) && viewer.getProperties(dbId, onSuccessCallback, onErrorCallback)

                }
                async function onSuccessCallback(data) {
                    if (data) {
                        let obj = {};
                        obj.dbId = data.dbId;
                        obj.name = data.name;
                        obj.externalId = data.externalId.split('/');
                        console.log('The DB Id is', obj.dbId)
                        console.log('The External Id is', obj.externalId)
                        const externalId = obj.externalId.length >= 2 ? obj.externalId[1] : obj.externalId[0]
                        if (forge.initialConfig?.cameraSensors && Object.keys(forge.initialConfig?.cameraSensors)?.includes(externalId)) {
                            let url
                            forge.cameraConfig?.forEach(cam => {
                                if (cam.sensorId === forge.initialConfig.cameraSensors[externalId]) {
                                    url = cam.liveFeedUrl
                                }
                            })
                            dispatch(camerafeed({ url: url, openSidePanel: handleOpenSidePanel }))
                        }
                        else {
                            await dispatch(asyncFetchAQIDataExternalId(externalId));
                            sidePanelInSplitScreen === false && handleOpenSidePanel()
                        }
                    }
                }

                function onErrorCallback(err) {
                    console.log(err)
                }
            })

            // viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, function () {
            //     const tree = viewer.model.getInstanceTree();
            //     const frags = viewer.model.getFragmentList();
            //     function listFragmentProperties(fragId) {
            //         // console.log('Fragment ID:', fragId);
            //         // Get IDs of all objects linked to this fragment
            //         const objectIds = frags.getDbIds(fragId);
            //         console.log('Linked object IDs:', objectIds);
            //         // Get the fragment's world matrix
            //         // let matrix = new THREE.Matrix4();
            //         // frags.getWorldMatrix(fragId, matrix);
            //         // console.log('World matrix:', JSON.stringify(matrix));
            //         // Get the fragment's world bounds
            //         let bbox = new THREE.Box3();
            //         frags.getWorldBounds(fragId, bbox);
            //         const center = {
            //             x: +((bbox.min.x + bbox.max.x) / 2).toFixed(4),
            //             y: +((bbox.min.y + bbox.max.y) / 2).toFixed(4),
            //             z: +((bbox.min.z + bbox.max.z) / 2).toFixed(4)
            //         }
            //         console.log(center)
            //         //External Id
            //         // model.getProperties(objectIds, (data) => { console.log(data) }, (err) => { console.log(err) })
            //         // console.log('World bounds:', JSON.stringify(bbox));
            //         // console.log((bbox.min.x + bbox.max.x) / 2)
            //         // console.log((bbox.min.y + bbox.max.y) / 2)
            //         // console.log((bbox.min.z + bbox.max.z) / 2)
            //     }
            //     if (tree) {
            //         // Could be null if the tree hasn't been loaded yet
            //         const selectedIds = viewer.getSelection();
            //         for (const dbId of selectedIds) {
            //             const fragIds = [];
            //             tree.enumNodeFragments(dbId, listFragmentProperties, false);
            //         }
            //     }
            // })
        });
    }
    function onDocumentLoadFailure(code, message) {
        alert('Could not load model. See the console for more details.');
        console.error(message);
    }
    // window.location.hash = urn;
    Autodesk.Viewing.Document.load('urn:' + urn, onDocumentLoadSuccess, onDocumentLoadFailure);
}
//dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLnFUMm9EYXBvU2VTU2hFSFlCTC03ZWc_dmVyc2lvbj0yNQ
//dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLmNPTll3cWlMUnNPWXdvSnJzSHpOdWc_dmVyc2lvbj0xOQ
export default loadModel;