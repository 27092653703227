import { Box, Button, Typography } from "@mui/material";
import CommonStyles from "../../../../../styles/CommonStyles";
import Modal from "../../../../../components/modal/Modal";
import { useSelector } from "react-redux";
import { DELETE } from "../../../../../helpers/http";
import { successToast } from "../../../../../helpers/apiToast";
import { ContentPasteSearchOutlined } from "@mui/icons-material";

export default function DeleteMaintenanceMapping(props) {
    const { openDeleteMappingModal, handleCloseDeleteModal, maintanenceMapping, setMaintanenceMapping, selectedMapping } = props;
    const themeMode = useSelector(state => state.theme.darkTheme);
    const color = themeMode ? '#fff' : '#000';
    const handleDeleteNotificationMapping = async () => {
        try {
            // const response = await DELETE(`http://localhost:8001/v1/admin/maintenance-mapping?id=${selectedMapping?._id}`, 'Delete Maintenance Mapping')
            const response = await DELETE(`${process.env.REACT_APP_BASE_URL}um/v1/admin/maintenance-mapping?id=${selectedMapping?._id}`, "Delete Maintenance Mapping")
            const mappingsAfterDelete = maintanenceMapping.filter(mapping => mapping?._id !== response?.data?._id)
            setMaintanenceMapping([...mappingsAfterDelete])
            successToast(`Mapping ${selectedMapping?.name} deleted successfully`)

            handleCloseDeleteModal();
        } catch (error) {
            console.log({ error });
        }
    }

    return (
        <Modal
            modalOpen={openDeleteMappingModal}
            closeModal={handleCloseDeleteModal}
            title={selectedMapping?.name}
        >
            <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography sx={{ textAlign: 'center', fontSize: '16px', color }}>
                    Are you sure you want to delete the mapping {selectedMapping?.name}?
                </Typography>
                <Box display={'flex'} justifyContent={'space-evenly'} mt={2}>
                    <Button sx={{ ...CommonStyles.actionButton }} onClick={handleDeleteNotificationMapping}>Delete</Button>
                    <Button onClick={handleCloseDeleteModal} sx={{
                        bgcolor: '#212121',
                        color: 'white',
                        textTransform: 'none',
                        "&:hover": {
                            bgcolor: '#111111',
                        }
                    }}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
}
