import { Box, IconButton, Modal, Slide, Typography } from "@mui/material"
import { ThemeColors } from "../../hooks/ThemeColors"
import { CloseOutlined } from "@mui/icons-material"

const SidebarModal = (props) => {
    const { openModal, handleClose, title, children, direction, sx = {} } = props

    const { textColor, background_color } = ThemeColors()

    const modalStyle = {
        bgcolor: background_color(),
        color: textColor(),
        ...sx
    }

    return (
        <Modal
            onClose={handleClose}
            open={openModal}
            disableScrollLock
        >
            <Slide direction={direction || "left"} in={openModal}>
                <Box className='sidebarModal' sx={modalStyle}>
                    <Box className='flex flexJustifyBetween flexAlignItemsCenter'>
                        <Typography className="font24 fontweight600">{title}</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseOutlined sx={{ color: 'grey' }} />
                        </IconButton>
                    </Box>
                    <Box m={1}>
                        {children}
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export default SidebarModal