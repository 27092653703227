import { Grid, MenuItem } from '@mui/material'
import React from 'react'
import FormInput from '../../components/formInputs/FormInput'
import CheckBox from '../../components/formInputs/CheckBox'
import moment from 'moment'

const CreateRecursivePPMInputs = (props) => {
    const { PPMData, PPMOptionsData, handleFormInputsChange, validationErrors, selectedUpdatePPMOption, assetId } = props

    return (
        <>
            <Grid item xs={6}>
                <FormInput
                    label='Repeats every'
                    size='small'
                    type='number'
                    fullWidth
                    name='interval'
                    value={PPMData?.recursiveConfiguration?.interval}
                    onChange={handleFormInputsChange}
                    inputProps={{
                        min: 1
                    }}
                    error={!!validationErrors?.interval}
                    helperText={validationErrors?.interval}
                    disabled={assetId && selectedUpdatePPMOption === "CRR_PPM"}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInput
                    label='Frequency'
                    size='small'
                    select
                    fullWidth
                    name='frequency'
                    value={PPMData?.recursiveConfiguration?.frequency}
                    onChange={handleFormInputsChange}
                    error={!!validationErrors?.frequency}
                    helperText={validationErrors?.frequency}
                    disabled={assetId && selectedUpdatePPMOption === "CRR_PPM"}
                >
                    {PPMOptionsData.durations.map((duration) => (
                        <MenuItem
                            key={duration}
                            value={duration}
                        >
                            {duration}
                        </MenuItem>
                    ))}
                </FormInput>
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    label='Stops On'
                    size='small'
                    type='date'
                    focused
                    fullWidth
                    name='until'
                    value={PPMData?.recursiveConfiguration?.until}
                    onChange={handleFormInputsChange}
                    inputProps={{
                        min: moment(PPMData.startDate).format('YYYY-MM-DD'),
                    }}
                    error={!!validationErrors?.until}
                    helperText={validationErrors?.until}
                    disabled={assetId && selectedUpdatePPMOption === "CRR_PPM"}
                />
            </Grid>
            <Grid item xs={6}>
                <CheckBox
                    checkboxLabel='Avoid Saturdays'
                    name='avoidSaturday'
                    checked={PPMData?.recursiveConfiguration?.avoidSaturday}
                    onChange={handleFormInputsChange}
                    width='100%'
                    disabled={assetId && selectedUpdatePPMOption === "CRR_PPM"}
                />
            </Grid>
            <Grid item xs={6}>
                <CheckBox
                    checkboxLabel='Avoid Sundays'
                    name='avoidSunday'
                    checked={PPMData?.recursiveConfiguration?.avoidSunday}
                    onChange={handleFormInputsChange}
                    width='100%'
                    disabled={assetId && selectedUpdatePPMOption === "CRR_PPM"}
                />
            </Grid>
        </>
    )
}

export default CreateRecursivePPMInputs