
export const statusColors = {
    upcoming: {
        bg: '#E0F7FF',
        br: '#00BFFF'
    },
    pending: {
        bg: '#FFF4E0',
        br: '#FFA500'
    },
    inprogress: {
        bg: '#E6FFE6',
        br: '#32CD32'
    },
    overdue: {
        bg: "#FFE6E0",
        br: "#FF4500"
    },
    completed: {
        bg: "#E6F5E6",
        br: "#008000"
    },
    incomplete: {
        bg: "#FFEDE8",
        br: "#FF6347"
    },
    deleted: {
        bg: "#F0F0F0",
        br: "#A9A9A9"
    }
}

export const priorityColors = {
    medium: {
        bg: 'Ivory',
        br: 'goldenrod'
    },
    low: {
        bg: "Honeydew",
        br: "green"
    },
    high: {
        bg: "Seashell",
        br: "IndianRed"
    }
}