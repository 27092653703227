import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ThemeColors } from '../../../../../hooks/ThemeColors';
import { useSelector } from 'react-redux';

const HighchartsTicketsRaisedCompletionDaywise = ({ title, data = [] }) => {
    const { textColor, bgColor } = ThemeColors()
    const themeMode = useSelector(state => state.theme.darkTheme)

    const options = {
        chart: {
            type: 'areaspline',
            backgroundColor: bgColor,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: '',
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 120,
            y: 70,
            floating: true,
            borderWidth: 1,
            backgroundColor: themeMode ? "lightgray" : "#fff",
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: ''
            },
            labels: {
                format: '{value:%d-%b}',
                style: {
                    color: textColor()
                }
            }
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                style: {
                    color: textColor()
                }
            }
        },
        tooltip: {
            shared: true,
            headerFormat: '<b>{point.key}</b><br/>',
            pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointStart: Date.parse(data[data?.length - 1]?.date),
                pointInterval: 24 * 3600 * 1000 // One day in milliseconds
            },
            areaspline: {
                fillOpacity: 0.5
            }
        },
        series: [
            {
                name: 'Reported',
                data: data?.map(value => [new Date(value?.date)?.getTime(), value?.reportedCount])
            },
            {
                name: 'Closed',
                data: data?.map(value => [new Date(value?.date)?.getTime(), value?.closedCount])
            }
        ]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};

export default HighchartsTicketsRaisedCompletionDaywise;
