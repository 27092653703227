

const commentsStyle = {
    scrollBarStyle: {
        overflow: "auto", height: "95%",
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '8px',
            opacity: 0
        },
        "&:hover::-webkit-scrollbar": {
            opacity: 1
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '8px',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)', // Adjusted color when hovered
        },
    },
    buttonStyle: {
        backgroundColor: "#ef5350",
        color: "#fff",
        fontSize: "13px",
        marginY: "0.5rem",
        marginX: "0.5rem",
        "&:hover": {
            backgroundColor: "#ef5350"
        }
    }
}

export default commentsStyle