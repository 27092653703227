import { Box } from '@mui/material'
import SensorGroupsStyle from '../../../styles/SensorGroupsStyle'
import CustomTypography from '../../../components/texts/CustomTypography'
import CatalogTable from '../../../features/energy/sensorsCatalog/CatalogTable'

const SensorCatalogs = () => {
    return (
        <Box
            sx={SensorGroupsStyle.boxContainer}
            className='minHeight90vh'
        >
            <CustomTypography
                sx={SensorGroupsStyle.headingTypo}>
                Energy Sensor Catalogs
            </CustomTypography>
            <CatalogTable />
        </Box>
    )
}

export default SensorCatalogs