import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import PDFReportGeneratorStyles from '../../../../styles/PDFReportGenerator'
import { useSelector } from 'react-redux'

const InitialPageAM = (props) => {
    const { selectedBuildingData, timePeriod } = props
    const nhanceLogo = process.env.REACT_APP_NHANCE_LOGO
    const logo = useSelector(state => state.configurations.logo)
    const themeMode = useSelector(state => state.theme?.darkTheme)

    return (
        <Stack sx={{ ...PDFReportGeneratorStyles.pageContainer, justifyContent: 'space-between', pb: '40px' }}>
            <Box>
                <Box component={'img'} src={nhanceLogo} alt='' />
                <Typography sx={{ ...PDFReportGeneratorStyles.reportTitle }}>Asset Management: Insights Report</Typography>
            </Box>
            <Stack spacing={2}>
                <Box component={'img'} src={themeMode ? logo?.dark : logo?.light} alt='' sx={{ ...PDFReportGeneratorStyles.clientLogo }} />
                <Typography sx={{ fontSize: '40px' }}>{`${selectedBuildingData?.location?.name} - ${selectedBuildingData?.name}`}</Typography>
                <Stack >
                    <Typography sx={{ fontSize: '20px' }}>Time period:</Typography>
                    <Typography sx={{ fontSize: '20px' }}>{`${moment(timePeriod?.startDate).format('LL')} - ${moment(timePeriod?.endDate).format('LL')}`}</Typography>
                </Stack>
                <Box component={'footer'} sx={{ ...PDFReportGeneratorStyles.initialPageFooter, pt: 10 }}>
                    <Typography>{moment().format('LL')}</Typography>
                    Copyright 2023 | Nhance Digital Buildtech Pvt Ltd.,
                </Box>
            </Stack>
        </Stack>
    )
}

export default InitialPageAM