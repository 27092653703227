//Considers negative and undefined values as invalid
export const validData = (data) => {
    return (
        typeof data === 'number' &&
        !isNaN(data) &&
        data !== null &&
        data !== "" &&
        data !== false
    )
}

export const validPositiveData = (data) => {
    return (
        data >= 0 &&
        data !== null &&
        data !== "" &&
        data !== false
    )
}


export const validStringData = (data) => {
    return (
        !!data &&
        typeof data === 'string' &&
        data?.length > 0
    )
}