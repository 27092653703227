import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Datatable from '../../../components/datatable/Datatable'
import { CloseOutlined, DoneOutlined, EditOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { GET } from '../../../helpers/http'
import CatalogEditForm from './CatalogEditForm'

const CatalogTable = () => {
    const serviceEnabled = useSelector((state) => state.configurations.services?.energy)

    const underMaintenance = useSelector((state) => state.configurations.underMaintenance?.energy)

    const [sensorCatalogs, setSensorCatalogs] = useState([])

    const [editingCatalog, setEditingCatalog] = useState({})

    const [openEditCatalogModal, setOpenEditCatalogModal] = useState(false)

    const handleOpenEditCatalogModal = (sensorCatalog) => {
        setOpenEditCatalogModal(true)
        setEditingCatalog(sensorCatalog)
    }

    const handleCloseEditCatalogModal = () => {
        setOpenEditCatalogModal(false)
    }

    useEffect(() => {
        const selectedBuilding = localStorage.getItem('bid')
        const fetchSensorCatalogs = async () => {
            if (serviceEnabled && !underMaintenance && selectedBuilding) {
                try {
                    const { data } = await GET(`${process.env.REACT_APP_URL}v1/energysensorcatalogs/list?bid=${selectedBuilding}`, "Get all sensor catalogs")
                    const sensorData = data?.map((sensor) => ({ ...sensor, id: sensor.sensor_id }))
                    setSensorCatalogs(sensorData)
                } catch (error) {
                    console.log({ error })
                    setSensorCatalogs([])
                }
            }
        }

        fetchSensorCatalogs()
    }, [serviceEnabled, underMaintenance])

    const columns = [
        {
            field: 'sensorId',
            headerName: 'Sensor Id',
            width: 150,
            editable: false,
        },
        {
            field: 'sensorDisplayName',
            headerName: 'Display Name',
            width: 250,
            editable: false,
        },
        {
            field: 'readsAreaConsumption',
            headerName: 'Reads Area Consumption',
            width: 200,
            editable: false,
            align: 'center',
            renderCell: (cellValues) => {
                return cellValues.row.readsAreaConsumption ? <DoneOutlined sx={{ color: 'green !important' }} /> : <CloseOutlined sx={{ color: 'red !important' }} />
            }
        },
        {
            field: 'areaInSqft',
            headerName: 'Area (Sq. ft)',
            width: 150,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            editable: false,
        },
        {
            field: 'Actions',
            width: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title='EDIT' placement='top'>
                            <IconButton size='small' onClick={() => handleOpenEditCatalogModal(cellValues?.row)}>
                                <EditOutlined fontSize='small' sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ]

    return (
        <>
            <Datatable
                rows={sensorCatalogs}
                columns={columns}
                pageSize={15}
                rowsPerPage={15}
                checkBox={false}
            />
            <CatalogEditForm
                openEditCatalogModal={openEditCatalogModal}
                handleCloseEditCatalogModal={handleCloseEditCatalogModal}
                editingCatalog={editingCatalog}
                setSensorCatalogs={setSensorCatalogs}
            />
        </>
    )
}

export default CatalogTable