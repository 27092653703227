import Box from '@mui/material/Box';
import { Paper, Stack, Button, IconButton, Grid, useTheme, Typography, Tooltip } from "@mui/material"
import { ArrowBack, HighlightOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import CircularProgressLoader from '../../../../components/formInputs/FileInput.js';
import FileInput from '../../../../components/formInputs/FileInput.js';
import CommonStyles from '../../../../styles/CommonStyles.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react'
import { FILEPOST, } from '../../../../helpers/http.js';
import swal from 'sweetalert';
import { GET, PUT } from '../../../../helpers/http.js';
import { successToast, errorToast } from '../../../../helpers/apiToast.js'
import TransitionsModal from '../../../../components/modal/ModalWithAnimAndBackdrop.js.js';
import { areaImageViewStyle } from '../../../../styles/AreaImageViewStyle.js';
import ImageCropper from '../../../../features/settings/areas/components/ImageCropper.js';
import { optimizImg } from '../../../../helpers/optimizeImg.js';
import { fetchTwinConfigurations } from '../../../../../../redux/features/configurationsSlice.js';

const AreaImageView = (props) => {
    //states
    const [isPageLoaded, setIsPageLoaded] = useState(true)
    const [areaData, setAreaData] = useState({})
    const [imageOpened, setImageOpened] = useState('');
    const [initialCount, setInitialCount] = useState(0)
    const [isSubmit, setIsSubmit] = useState(true)
    //cropImgStates
    const [imgToBeCropped, setImageToBeCroppped] = useState({})
    const [cropModalOpen, setCropModalOpen] = useState(false)

    //colors Ref
    const theme = useTheme()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const paperBorderColor = !themeMode ? '#444A67' : '#D2DAE2'
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const iconColor = themeMode ? '#fff' : theme.palette.grey['600']
    const imgBgColor = themeMode ? theme.palette.common.black : theme.palette.grey['500']

    const fileReadUrl = useSelector((state) => state?.configurations?.fileReadUrl)
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setIsPageLoaded(false)
        if (id) {
            GET(`${process.env.REACT_APP_BASE_URL}twin/v1/area/areaimage?areaId=${id}`, "GET AREA DATA")
                .then((data) => {
                    setAreaData(data?.data);
                    setInitialCount(data?.data?.areaImages?.length)
                    setIsPageLoaded(true)
                })
                .catch((error) => {
                    console.log(error);
                    errorToast(`GET AREA DATA : ${error?.message}`)
                    setIsPageLoaded(true)
                })
        }
    }, [id, dispatch])

    useEffect(() => {
        if (initialCount !== 0) {
            if (areaData?.areaImages?.length !== initialCount) {
                setIsSubmit(false)
            }
        } else {
            if (areaData?.areaImages?.length > initialCount) {
                setIsSubmit(false)
            }
        }
    }, [areaData?.areaImages])

    const FileInputHandler = (files) => {
        setImageToBeCroppped(URL.createObjectURL(files[0]))
        setCropModalOpen(true)
    }

    const uploadCroppedImg = async (img) => {
        //optimize bfr Image
        const optImg = await optimizImg(img)
        // console.log("🚀 ~ afetr opt ~ img:", optImg.size / 1000, 'kb')

        const myFile = new File([optImg], 'image.jpeg', {
            type: optImg.type,
        });

        //upload Img
        let formData = new FormData();
        formData.append('file', myFile)
        FILEPOST(`${process.env.REACT_APP_URL}v1/area/areaimage`, formData, "AREA IMAGE UPLOAD")
            .then((data) => {
                if (data?.status === 201) {
                    setAreaData({
                        ...areaData, areaImages: [...areaData.areaImages, data?.data]
                    })
                }
            })
            .catch((error) => {
                console.log(error.message)
                swal("Warning!", error.message, 'warning')
            })
    }

    const submitButtonHandle = () => {
        const formData = {
            imgUrls: [...areaData.areaImages]
        }

        PUT(`${process.env.REACT_APP_BASE_URL}twin/v1/area/areaimage?areaId=${id}`, formData, "AREA IMAGE UPDATE")
            .then((data) => {
                if (data?.status === 200) {
                    setIsSubmit(true)
                    successToast('Area Image Updated')
                    setInitialCount(data?.data?.areaImages?.length)

                    navigate({ pathname: '/admin/settings/areas', state: { floorId: location?.state?.floorId, url: location?.pathname, collapseStatus: true } })
                }
            })
            .catch((error) => {
                console.log(error);
                errorToast(`AREA IMAGE UPDATE : ${error?.message}`)
            })
    }

    const fileDeleteHandler = (imageUrl) => {
        setAreaData({ ...areaData, areaImages: areaData?.areaImages.filter((img) => img !== imageUrl) })
    }

    const handleCloseModal = () => {
        setImageOpened('')
        setImageToBeCroppped({})
    }

    const handleCloseCroppedModal = () => {
        setCropModalOpen(false)
        setImageToBeCroppped({})
    }

    const backButtonHandle = () => {
        if (areaData?.areaImages?.length !== initialCount) {
            swal({
                title: "Are you sure?",
                text: "Your changes will be lost. Please Submit to retain changes.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/settings/areas')
                    }
                });
        } else {
            navigate({ pathname: '/admin/settings/areas', state: { floorId: location?.state?.floorId, url: location?.pathname, collapseStatus: true } })
        }
    }

    return (
        <>
            <Box sx={CommonStyles?.OuterBox}>
                <Paper
                    sx={{
                        ...areaImageViewStyle.topBarPaper,
                        bgcolor: bgcolor,
                        border: `0.5px solid ${paperBorderColor}`,
                        boxShadow: `0px 0px 6px ${themeMode ? `#3c3c5c` : `#c2cfdb`}`
                    }}
                    className='theme-border theme-component-background theme-boxShadow'
                >
                    <Stack direction='row'>
                        <Button
                            sx={{ ...CommonStyles.actionButton, marginLeft: '1vw', height: "2.5rem", marginY: 'auto' }}
                            onClick={backButtonHandle}
                            variant='contained'> <ArrowBack /></Button>
                        <Typography variant='h5' marginY='auto' ml={2}>
                            {areaData?.name} Images ({areaData?.areaImages?.length})
                        </Typography>
                    </Stack>
                    <Stack direction='row' gap={2} pr={2}>
                        <Box marginY='auto' >
                            <FileInput
                                accept={"image/*"}
                                buttonText='Add Area Image'
                                pickHandler={((file) => FileInputHandler(file.currentTarget.files))}
                                width='8rem'
                                height={40}
                            />
                        </Box>
                        <Button disabled={isSubmit} sx={{ ...CommonStyles.actionButton, width: '8rem', height: "2.5rem", marginY: 'auto' }} onClick={submitButtonHandle} variant='contained'>Submit</Button>
                    </Stack>
                </Paper>

                {
                    isPageLoaded
                        ?
                        <Grid container
                            pt={4}
                            spacing={{ xs: 2, sm: 2, md: 2, lg: 3 }}
                            columns={{ xs: 4, sm: 8, md: 10, lg: 12 }}
                        >
                            {areaData.areaImages?.length !== 0
                                ?
                                areaData?.areaImages?.map((img, index) => (
                                    <Grid item xs={2} sm={4} md={3} lg={3} xl={3} key={index}  >
                                        <Paper
                                            key={index}
                                            sx={{
                                                ...areaImageViewStyle.singleImagePaper,
                                            }}
                                            className='theme-component-background theme-border theme-boxShadow'
                                        >
                                            <Stack
                                                sx={areaImageViewStyle.singleImgTopbar}>
                                                <Tooltip title='VIEW' placement='top'>
                                                    <IconButton style={{ color: iconColor }} onClick={() => setImageOpened(img)}>
                                                        <VisibilityOutlined sx={{
                                                            width: 28,
                                                            height: 28,
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='DELETE' placement='top'>
                                                    <IconButton style={{ color: iconColor }} onClick={() => fileDeleteHandler(img)}>
                                                        <HighlightOffOutlined sx={{ width: 24, height: 24 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>

                                            <img src={`${fileReadUrl}${img}`}
                                                alt='area img'
                                                style={{ ...areaImageViewStyle.singleImg, backgroundColor: imgBgColor, }}
                                            />
                                        </Paper>
                                    </Grid>
                                ))
                                :
                                <Typography height='70vh' variant='h6' margin='auto' paddingTop='35vh'>No images found for {areaData?.name}. Please add images.</Typography>
                            }
                        </Grid>
                        :
                        <CircularProgressLoader />
                }
            </Box >
            {/* View Image */}
            {
                imageOpened &&
                <TransitionsModal
                    handleClose={handleCloseModal}
                    url={`${fileReadUrl}${imageOpened}`}
                />
            }
            {/* Crop Image */}
            {cropModalOpen && <ImageCropper img={imgToBeCropped} open={cropModalOpen} close={handleCloseCroppedModal} uploadCroppedImg={uploadCroppedImg} />}
        </>
    )
}

export default AreaImageView