import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux'

const ReportDateRangeSelector = ({ handleSetStartTimeEndTime }) => {
    const dispatch = useDispatch()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const minStartTime = useSelector(state => state.reportGeneration.minStartTime)
    const maxEndTime = useSelector(state => state.reportGeneration.maxEndTime)
    const minDate = useMemo(() => minStartTime ? new Date(minStartTime) : new Date(moment().subtract(90, 'days')), [minStartTime])
    const maxDate = useMemo(() => maxEndTime ? new Date(maxEndTime) : new Date(), [maxEndTime])
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#F2F3F4'

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [error, setError] = useState('')

    useEffect(() => {
        if (!state?.[0]?.startDate && state?.[0]?.endDate) {
            setError('Please select start date')
        }
        else if (state?.[0]?.startDate && !state?.[0]?.endDate) {
            setError('Please select end date')
        }
        else if (!state?.[0]?.startDate && !state?.[0]?.endDate) {
            setError('Please select start date and end date')
        }
        else if (state?.[0]?.startDate < minDate) {
            setError('Start date should be within 90 days')
        }
        else if (state?.[0]?.endDate > maxDate) {
            setError('End date cannot be set in future')
        }
        else if (state?.[0]?.startDate >= minDate && state?.[0]?.endDate <= maxDate) {
            setError('')
            handleSetStartTimeEndTime({ startTime: moment(state?.[0]?.startDate).format('YYYY-MM-DD'), endTime: moment(state?.[0]?.endDate).format('YYYY-MM-DD') })
        }
    }, [dispatch, state, minDate, maxDate])

    return (
        <Box sx={{ borderRadius: '8px', bgcolor }}>
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                minDate={minDate}
                maxDate={maxDate}
                startDatePlaceholder='Start Date'
                endDatePlaceholder='End Date'
                className='rdrDateDisplayWrapper'
                rangeColors={['#dc4d28']}
            />
            {error && <Typography color={'error'} fontSize={'12px'} ml={2}>{error}</Typography>}
            <style>
                {`
                    .rdrDateDisplayWrapper {
                        background-color: ${bgcolor};
                    }
                    .rdrDayDisabled {
                        background-color: transparent;
                    }
                    .rdrMonthAndYearPickers select {
                        color: ${color};
                        -moz-appearance: ${!themeMode ? 'none' : 'auto'};
                        appearance: ${!themeMode ? 'none' : 'auto'};
                        -webkit-appearance: ${!themeMode ? 'none' : 'auto'};
                    }
                    .rdrMonthAndYearPickers select option {
                        background-color: ${bgcolor};
                    }
                    .rdrDateDisplayItem input {
                        background-color: ${bgcolor};
                        color: ${color};
                    }
                    .rdrDateDisplayItemActive, .rdrDateDisplayItem {
                        border-color: ${themeMode ? '#3c3c3c' : 'lightgray'};
                    }
                    .rdrDayNumber span{
                        color: ${color}; 
                        font-weight: 500;
                    }
                    .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
                        filter: grayscale(0%) opacity(100%);
                    }
                    .rdrWeekDay {
                        color: ${color};
                    }
                `}
            </style>
        </Box >
    )
}

export default ReportDateRangeSelector