// PieChart.js

import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ThemeColors } from '../../../../hooks/ThemeColors';

const HighChartsFaultPieChart = ({ data }) => {
    const { textColor, bgColor, color } = ThemeColors()

    const options = {
        tooltip: {
            pointFormat: '<span><b>{point.percentage:.1f}%</b></span>' // Custom tooltip format
        },
        chart: {
            type: 'pie',
            height: "330px",
            backgroundColor: bgColor,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                colors: ["#ADD8E6", "#90EE90", "#F08080", "#FFB6C1", "#E9E9A2",
                    "#FFA07A", "#87CEFA", "#B0C4DE", "#AFEEEE", "#E6E6FA",
                    "#B1EFB1", "#C3F4F4", "#FFE4E1", "#FFDAB9", "#F5F5D4"],
                allowPointSelect: true,
                size: '90%',
                cursor: 'pointer',
                slicedOffset: 0,

                dataLabels: {
                    enabled: false,
                    format: '<span><b>{point.name} <br/> {point.y}%</b>',
                    style: {
                        fontSize: '0.8em',
                        // color: color(),
                        fontWeight: 500
                    },
                },
                showInLegend: true,

            }
        },
        legend: {
            itemStyle: {
                color: textColor(), // White text for dark mode
                fontSize: '0.9em',
                fontWeight: 500,
            },
            itemHoverStyle: {
                color: textColor(), // White text on hover if dark mode
            }
        },
        series: [
            {
                name: '',
                colorByPoint: true,
                data: data ?? []
            }
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        // Wrapper Component or Inside the Same Component

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default HighChartsFaultPieChart;
