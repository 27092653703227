import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../components/formInputs/TextInput'
import Modal from '../../../../../components/modal/Modal'
import CommonStyles from '../../../../../styles/CommonStyles'
import { GET, POST, PUT } from '../../../../../helpers/http'
import { successToast } from '../../../../../helpers/apiToast'
import validSearchTerm from '../../../../../helpers/validSearchTerm'
import { useSelector } from 'react-redux'

const DepartmentModal = (props) => {
    const {
        departmentModalOpen,
        setDepartmentModalOpen,
        addNewDepartment,
        editDepartment,
        selectedDepartment,
        setSelectedDepartment
    } = props

    const [timer, setTimer] = useState(0)
    const [formErrors, setFormErrors] = useState({})
    const [departments, setDepartments] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedParentEntity, setSelectedParentEntity] = useState({})
    const [department, setDepartment] = useState({
        name: "",
        code: "",
        description: ""
    })
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const bgcolor = themeMode ? '#282a2e' : '#fff'

    useEffect(() => {
        if (selectedDepartment?._id) {
            setDepartment({
                name: selectedDepartment?.name,
                code: selectedDepartment?.code,
                description: selectedDepartment?.description,
            })
            if (selectedDepartment?.parent) setSelectedParentEntity(selectedDepartment?.parent)
        }
    }, [selectedDepartment?._id])

    const errors = {}

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        if (searchTerm?.length >= 2) {
            const endpoint = selectedDepartment?._id ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list?searchTerm=${validSearchTerm(searchTerm)?.trim()}&departmentId=${selectedDepartment?._id}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list?searchTerm=${searchTerm?.trim()}`

            debounce(() => {
                GET(endpoint, 'Fetch Departments')
                    .then((response) => {
                        if (response?.status === 200) {
                            setDepartments(response?.data)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }, 500)
        }
    }, [searchTerm])

    const handleDepartmentModalClose = () => {
        setDepartmentModalOpen(false)
        setDepartment({
            name: "",
            code: "",
            description: ""
        })
        setSelectedParentEntity({})
        setFormErrors({})
        if (selectedDepartment?._id) setSelectedDepartment({})
        if (departments?.length > 0) setDepartments([])
    }

    const handleChange = (e) => {
        const property = e.target.name
        const value = e.target.value
        setDepartment((prevState) => {
            return { ...prevState, [property]: value }
        })
    }

    const handleDepartmentSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleParentEntityChange = (e, newValue) => {
        setSelectedParentEntity(newValue)
    }

    const runValidations = () => {
        if (department?.name?.trim()?.length === 0) {
            errors.name = 'Department Name cannot be empty'
        }
        if (department?.code?.trim()?.length === 0) {
            errors.code = 'Department Code cannot be empty'
        }
    }

    const handleCreateDepartment = () => {

        runValidations()

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
        } else {
            setFormErrors({})

            const deptObj = {}

            deptObj.name = department?.name?.trim()
            deptObj.code = department?.code?.trim()
            deptObj.description = department?.description?.trim()
            if (selectedParentEntity?._id) deptObj.parent = selectedParentEntity?._id
            else deptObj.parent = null

            if (selectedDepartment?._id) {
                //editing the selected department
                PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/${selectedDepartment?._id}`, deptObj, 'Edit Department')
                    .then((response) => {
                        if (response?.status === 200) {
                            handleDepartmentModalClose()
                            editDepartment(response?.data)
                            successToast("Department updated successfully")
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                //adding a department
                POST(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department`, deptObj, 'Create new Department')
                    .then((response) => {
                        if (response?.status === 201) {
                            handleDepartmentModalClose()
                            addNewDepartment(response?.data)
                            successToast("Department added successfully")
                        }
                    })
                    .catch(error => console.log(error))
            }
        }
    }

    return (
        <Modal
            modalOpen={departmentModalOpen}
            closeModal={handleDepartmentModalClose}
            title={selectedDepartment?._id ? 'Edit Department' : 'Create a Department'}
        >
            <Stack spacing={2}>
                <TextInput
                    label={'Department Name'}
                    fullWidth
                    value={department.name}
                    onChange={handleChange}
                    name={'name'}
                    error={formErrors?.name}
                    helperText={formErrors?.name}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Department Code'}
                    fullWidth
                    value={department.code}
                    onChange={handleChange}
                    name={'code'}
                    error={formErrors?.code}
                    helperText={formErrors?.code}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <TextInput
                    label={'Department Description (Optional)'}
                    fullWidth
                    multiline
                    rows={4}
                    value={department.description}
                    onChange={handleChange}
                    name={'description'}
                    inputProps={{ style: { color, bgcolor } }}
                />
                <Autocomplete
                    disablePortal
                    options={departments}
                    getOptionLabel={(option) => option.name || ""}
                    value={selectedParentEntity}
                    onChange={handleParentEntityChange}
                    noOptionsText={searchTerm?.length > 0 ? "No results" : "Search a department by its name or code..."}
                    renderInput={(params) => {
                        return <TextField
                            {...params}
                            onChange={handleDepartmentSearch}
                            label="Select a Parent Department (Optional)"
                            inputProps={{ ...params.inputProps, style: { color, bgcolor } }}
                            InputLabelProps={{ ...params.InputLabelProps, sx: { color } }}
                        />
                    }}
                />
                <Button
                    sx={{ ...CommonStyles.actionButton }}
                    onClick={handleCreateDepartment}
                >{selectedDepartment?._id ? "Submit" : "Create Department"}</Button>
            </Stack>
        </Modal>
    )
}

export default DepartmentModal