import { Box, Grid, IconButton, InputAdornment, MenuItem, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import NotificationsStyles from "../../../styles/NotificationsStyles";
import CustomButton from "../../../components/button/CustomButton";
import { DeleteOutlined, EditOutlined, SearchOutlined, VisibilityOutlined } from "@mui/icons-material";
import Datatable from "../../../components/datatable/Datatable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import validSearchTerm from "../../../helpers/validSearchTerm";
import { error } from "jquery";
import { errorHandler, GET } from "../../../helpers/http";
import CommonStyles from "../../../styles/CommonStyles";
import ViewMaintenanceMapping from "../../../features/settings/maintenance/components/ViewMaintenanceMapping";
import DeleteMaintenanceMapping from "../../../features/settings/maintenance/components/modals/DeleteMaintainanceMapping";

export default function MaintenanceMapping() {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const navigate = useNavigate()

    const [timer, setTimer] = useState(0)
    const [maintanenceMapping, setMaintanenceMapping] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const [openDeleteMappingModal, setOpenDeleteMappingModal] = useState(false)
    const [openViewMappingModal, setOpenViewMappingModal] = useState(false)
    const [selectedMapping, setSelectedMapping] = useState({})
    const [selectedViewMapping, setSelectedViewMapping] = useState({})

    const handleEditCategoryClick = (mappingId) => {
        navigate(`/admin/settings/maintenance-mapping/edit/${mappingId}`)
    }

    const deleteClickHandler = (mapping) => {
        setSelectedMapping(mapping)
        setOpenDeleteMappingModal(true)
    }

    const viewClickHandler = (mapping) => {
        setSelectedViewMapping(mapping)
        setOpenViewMappingModal(true)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteMappingModal(false)
    }

    const handleCloseViewModal = () => {
        setOpenViewMappingModal(false)
    }

    const handleAddMaintanenceMapping = () => {
        navigate(`/admin/settings/maintenance-mapping/add`)
    }

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    useEffect(() => {
        let endpoint = `${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/admin/maintenance-mapping/list`
        // let endpoint = `http://localhost:8001/v1/admin/maintenance-mapping/list`

        if (searchTerm.length >= 2) {
            endpoint += `?searchTerm=${validSearchTerm(searchTerm)?.trim()}`
        }
        debounce(() => {
            GET(endpoint, 'Fetch Maintenance Mappings')
                .then(response => {
                    if (response?.status === 200) {
                        setMaintanenceMapping(response?.data)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }, 500)
    }, [searchTerm])


    const columns = [
        {
            field: 'service',
            headerName: 'Service',
            width: 250,
            renderCell: (cellValues) => {
                const serviceReference = cellValues?.row?.serviceReference
                return <Typography>{serviceReference.name}</Typography>
            },
        },
        {
            field: 'name',
            headerName: 'Maintenance Mapping Name',
            width: 200,
        },
        {
            field: 'code',
            headerName: 'Maintenance Mapping Code',
            width: 200,
        },
        {
            field: 'categories',
            headerName: 'Categories',
            width: 250,
            renderCell: (cellValues) => {
                const categories = cellValues
                    ?.row
                    ?.categoriesReference
                    ?.map(category => category?.name)
                    ?.join(', ')

                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row
                                        ?.categories
                                        ?.map((category, i) => <MenuItem key={i}>{category?.name}</MenuItem>)
                                }
                            </Box>
                        </>
                    }
                >
                    {categories}
                </Tooltip>
            }
        },
        {
            field: 'departments',
            headerName: 'Departments',
            width: 250,
            renderCell: (cellValues) => {
                const department = cellValues
                    ?.row
                    ?.departmentsReference
                    ?.map(department => department?.name)
                    ?.join(', ')

                return <Tooltip
                    title={
                        <>
                            <Typography fontWeight={600} borderBottom={'0.5px solid'}>{cellValues?.row?.name}</Typography>
                            <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                {
                                    cellValues?.row
                                        ?.department
                                        ?.map((department, i) => <MenuItem key={i}>{department?.name}</MenuItem>)
                                }
                            </Box>
                        </>
                    }
                >
                    {department}
                </Tooltip>
            }
        },
        {
            field: 'Actions',
            headerAlign: 'center',
            align: 'right',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Tooltip title='EDIT' placement='top'>
                            <IconButton
                                onClick={() => handleEditCategoryClick(cellValues?.row?._id)}
                            >
                                <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;EDIT
                            </Typography> */}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='VIEW' placement='top'>
                            <IconButton
                                onClick={() => viewClickHandler(cellValues?.row)}
                            >
                                <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />

                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => deleteClickHandler(cellValues?.row)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />

                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ]

    const onSearchChange = (e) => {
        setSearchTerm(e.target?.value)
    }
    return (
        <Box p={1} height={'100%'} width={'82%'}>
            <Typography
                sx={NotificationsStyles.headingTypo}
            >
                Maintainence Mapping
            </Typography>
            <Box
                sx={NotificationsStyles.innerBox}
            >
                <Paper
                    sx={{
                        mb: '10px',
                        ...NotificationsStyles.paperContainer,
                        bgcolor,
                    }}
                    className='theme-border theme-component-background bdrRadius4px'
                >
                    <Grid container direction={'row-reverse'} spacing={1}>
                        <Grid item xs={1.5} border={'solid transparent'}> {/* for button alignment */}
                            <CustomButton textContent={'Add New Mapping'} className={'btnFontSize'}
                                onClick={handleAddMaintanenceMapping}
                                sx={{ ...NotificationsStyles.addButton }} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={searchTerm}
                                onChange={onSearchChange}
                                sx={NotificationsStyles.searchContainer}
                                placeholder="Search a Notification by name or code.."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlined sx={{ color: '#616161' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        // Customize the border color
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the focused border color
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the input text color
                                        '& .MuiInputBase-input': {
                                            color: themeMode ? '#fff' : '#000'
                                        },
                                        // Customize the cursor color
                                        '& .MuiInputBase-input::selection': {
                                            backgroundColor: '#808080',
                                        },
                                        // Customize the border color on hover
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Datatable
                    height={650}
                    checkBox={false}
                    rows={maintanenceMapping}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                />
            </Box>
            <ViewMaintenanceMapping
                openViewMappingModal={openViewMappingModal}
                handleCloseViewModal={handleCloseViewModal}
                selectedViewMapping={selectedViewMapping}
            />
            <DeleteMaintenanceMapping
                openDeleteMappingModal={openDeleteMappingModal}
                handleCloseDeleteModal={handleCloseDeleteModal}
                selectedMapping={selectedMapping}
                maintanenceMapping={maintanenceMapping}
                setMaintanenceMapping={setMaintanenceMapping}
            />
        </Box >
    )
}