import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'

import UnderMaintenance from '../../../components/underMaintenance/UnderMaintenance'
import Loading from '../../../components/loading/Loading'
import { GET } from '../../../helpers/http'
import KioskBox from '../KioskBox/KioskBox'

function KioskConfigContainer() {

    const [kiosks, setKiosks] = useState([])
    const [areKiosksLoaded, setAreKiosksLoaded] = useState(false)

    const serviceEnabled = useSelector((state) => state.configurations.services?.visitorManagement)

    const underMaintenance = useSelector((state) => state.configurations.underMaintenance?.visitorManagement)

    const selectedBuildingId = localStorage.getItem('bid')

    const centerDiv = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
    }

    useEffect(() => {
        if (selectedBuildingId && (serviceEnabled && serviceEnabled !== 'NA') && !underMaintenance) {
            //Fetching all the kiosks configured for this building
            GET(`${process.env.REACT_APP_BASE_URL}vm/v1/dashboard/kiosks?buildingId=${selectedBuildingId}`, 'Fetch All Kiosks VM')
                .then((response) => {
                    const result = response.data
                    if (response?.status === 200) {
                        setAreKiosksLoaded(true)
                        setKiosks(result)
                    } else {
                        setAreKiosksLoaded(true)
                    }
                })
                .catch((err) => console.log(err))
        }
    }, [selectedBuildingId, underMaintenance, serviceEnabled])

    const updateKioksArr = (updatedKiosk) => {
        setKiosks(prevState => {
            return prevState.map(kiosk => {
                if (kiosk?._id === updatedKiosk?._id) {
                    return { ...kiosk, ...updatedKiosk }
                } else {
                    return { ...kiosk }
                }
            })
        })
    }

    return (
        underMaintenance ? <UnderMaintenance height={'80vh'} /> : <Box ml={1} width={'100%'} height={'80vh'}>
            {areKiosksLoaded ? (
                <Box >
                    {kiosks?.length > 0 ? (
                        <Grid container >
                            {kiosks.map(kiosk => {
                                return <Grid key={kiosk?._id} item md={6} >
                                    <KioskBox key={kiosk?._id} {...kiosk} updateKioksArr={updateKioksArr} />
                                </Grid>
                            })}
                        </Grid>
                    ) : (
                        <Box sx={centerDiv}>
                            No Kiosks Added.
                        </Box>
                    )}
                </Box>
            ) : (
                <Box
                    sx={centerDiv}
                >
                    <Loading />
                </Box>
            )
            }

        </Box >
    )

}

export default KioskConfigContainer