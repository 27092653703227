import { Paper } from '@mui/material'
import React from 'react'

const CustomComponentContainer = (props) => {
    const { className, children, ...rest } = props
    return (
        <Paper className={`theme-component-background theme-cards-boxShadow bdrRadius4px ${className}`} {...rest}>{children}</Paper>
    )
}

export default CustomComponentContainer