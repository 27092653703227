import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    selectedDateRange: `${moment().format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`,
    startDate: '',
    endDate: '',
    customXAxisTickInterval: 24,
}

const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setSelectedDateRange: (state, action) => {
            state.selectedDateRange = action.payload
            const daysDifference = moment(state.endDate).diff(moment(state.startDate), 'days')
            if (daysDifference <= 7) {
                state.customXAxisTickInterval = 1 * 24 //Converting days to hours
            }
            else if (daysDifference <= 14 && daysDifference > 7) {
                state.customXAxisTickInterval = 2 * 24
            }
            else if (daysDifference <= 30 && daysDifference > 14) {
                state.customXAxisTickInterval = 7 * 24
            }
            else if (daysDifference <= 180 && daysDifference > 30) {
                state.customXAxisTickInterval = 14 * 24
            }
            else if (daysDifference <= 360 && daysDifference > 180) {
                state.customXAxisTickInterval = 30 * 24
            }
            else if (daysDifference > 360) {
                state.customXAxisTickInterval = 60 * 24
            }
        },
        resetDateRange: (state) => {
            return initialState
        }
    }
})

export const { setStartDate, setEndDate, setSelectedDateRange, resetDateRange } = dateRangeSlice.actions

export default dateRangeSlice.reducer