import { Typography, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { formatSecondsToMinutes } from "../../helpers/formatSecondsToMinutes"
import { Button } from "@mui/material"
import CommonStyles from "../../styles/CommonStyles"
import { GET, POST } from "../../helpers/http"
import DVRBackdrop from "../../components/Backdrop/DVRBackdrop"
import { useSelector } from "react-redux"
import { errorToast } from "../../helpers/apiToast"

const ManualRefreshButton = (props) => {
    const { seconds, dvrData, errorAndLoadedStateUpdator } = props
    let [refreshAfterSeconds, setRefreshAfterSeconds] = useState(seconds)
    const [loading, setLoading] = useState(false)
    const tenantCode = useSelector(state => state?.user?.tenantCode)

    useEffect(() => {
        const timer = setInterval(() => {
            if (refreshAfterSeconds !== 0) {
                setRefreshAfterSeconds(refreshAfterSeconds - 1)
                clearInterval(timer)
            }
        }, 1000)
    }, [refreshAfterSeconds])


    //refresh
    const handleRefreshFeed = async () => {
        setLoading(true)
        const body = {
            dvrUUID: dvrData
        }
        let requestRes
        try {
            requestRes = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/refresh`, body, "Refresh request")
            if (requestRes.data.requestId) {
                const timer = setInterval(async () => {
                    try {
                        const refreshData = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/refresh-result?requestId=${requestRes.data.requestId}`, "Refresh Data")
                        if (refreshData?.status === 200) {

                            if (refreshData?.data?.error) {
                                setRefreshAfterSeconds(refreshData?.data?.refreshAfterSeconds || 0)
                                errorToast(`${refreshData?.data?.error}`)
                                // setRefreshAfterSeconds(0)
                                clearInterval(timer)
                                setLoading(false)
                                errorAndLoadedStateUpdator(refreshData?.data)
                            } else {
                                clearInterval(timer)
                                setRefreshAfterSeconds(Number(refreshData?.data?.refreshAfterSeconds || 0))
                                setLoading(false)

                                //set state
                                errorAndLoadedStateUpdator(refreshData?.data)
                            }
                        }
                    } catch (error) {
                        setLoading(false)
                        clearInterval(timer)
                        if (error?.response?.data?.message) {
                            errorToast(error?.response?.data?.message)
                        } else {
                            errorToast(error?.message)
                        }
                    }

                }, 5000)
            } else if (requestRes.data.dvrUUID) {
                setLoading(false)
                setRefreshAfterSeconds(Number(requestRes?.data?.refreshAfterSeconds || 0))
                //set state
                errorAndLoadedStateUpdator(requestRes?.data)
            } else if (requestRes?.data?.error) {
                setRefreshAfterSeconds(requestRes?.data?.refreshAfterSeconds || 0)
                errorToast(`${requestRes?.data?.error}`)
                // setRefreshAfterSeconds(0)
                setLoading(false)
                errorAndLoadedStateUpdator(requestRes?.data)
            }
        } catch (error) {
            setLoading(false)
            errorAndLoadedStateUpdator(error?.data)

            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    return (
        <>
            <DVRBackdrop loading={loading} message={"Getting DVR information..."} />
            {refreshAfterSeconds !== 0 ? <Stack alignItems='center'>
                <Button
                    sx={{
                        ...CommonStyles.actionButton,
                        "&.Mui-disabled": {
                            color: "rgba(255,255,255, 0.5)"
                        },
                        width: "10rem",
                        fontSize: '14px',
                        py: 1,
                        height: "2.5rem"
                    }}
                    disabled={refreshAfterSeconds !== 0 ? true : false}
                    onClick={() => handleRefreshFeed()}>
                    <Typography sx={{ fontSize: "12px" }}>Wait for {formatSecondsToMinutes(refreshAfterSeconds)}
                    </Typography>
                </Button>
            </Stack>
                :
                <Stack alignItems='center'>
                    <Button
                        sx={{
                            ...CommonStyles.actionButton,
                            "&.Mui-disabled": {
                                color: "#ffffff"
                            },
                            width: "10rem",
                            fontSize: '14px',
                            py: 1,
                            height: "2.5rem"
                        }}
                        disabled={refreshAfterSeconds !== 0 ? true : false}
                        onClick={() => handleRefreshFeed()}>Refresh Feed
                    </Button>
                </Stack>
            }

        </>
    )
}

export default ManualRefreshButton