export const meetingRoomFirstFloorAssets = [
	{
		"Name": '72quot_LCD_TV_WALL_MOUNTED_12431 [4735761]',
		"Category": 'EF_40 : Signage, fittings, furnishings and equipment',
		"Description": 'Furniture & Accessories',
		"Level": '1ST FLOOR',
		"Elevation from Level": '916.4 mm',
		"Default Elevation": '0.0 mm',
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Asset ID": 'ADR_1F_LCD_001',
		"Length": '1219.2 mm',
		"Height": '914.4 mm',
		"Depth": '609.6 mm',
		"Width": '0.0 mm',
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484311'
	},
	{
		"Name": 'HVAC_Mitsubishi [4037334]',
		"Category": "EF_60 : Heating, cooling and refrigeration functions",
		"Description": "Heating, Ventilation, and Air Conditioning (HVAC)",
		"Elevation from Level": '0.0 mm',
		"Offset from Host": "875.0 mm",
		"Default Elevation": '1219.2 mm',
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"System Classification": "Supply Air, Domestic Hot Water",
		"Asset ID": 'ADR_1F_HVAC_MT_FDT_002',
		"Type Name": "FDT28KXE6F",
		"Materials": "Plastic, Opaque White",
		"Height": '200.0 mm',
		"Width": '950.0 mm',
		"External-ID": 'a503ba1d-defb-42e3-851d-ed7b9c9b56a6-003d9ad6'
	},
	{
		"Name": 'HermanMiller_Collection_EamesTables_RectangularTableSegmentedBase [4735669]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": '1ST FLOOR',
		"Elevation from Level": '1.0 mm',
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "0.0 mm",
		"CTRL Leg": "381.0 mm",
		"Default Elevation": '0.0 mm',
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Top Finish": "Herman Miller - Veneer UX Walnut on Cherry",
		"Edge Finish": "Herman Miller - Veneer UX Walnut on Cherry",
		"Coulmn Finish": "Herman Miller - Finish BU Black Umber",
		"Base Finish": "Herman Miller - Finish PA Polished Aluminum",
		"Catalog Code": "HMH",
		"Product Documentation Link": "http://www.hermanmiller.com/Products/Eames-Tables",
		"Catalog": "Herman Miller Collection",
		"Asset ID": "ADR_1F_HM_ET-001",
		"Type Name": "Ven Top/ Edg 28.5H 36D 72W",
		"Watermarked By": "http://revitwatermark.com",
		"URL": "http://www.hermanmiller.com",
		"Package Qty": 1,
		"Model": "ET143W",
		"Manufacturer": "Herman Miller, Inc.",
		"Identity Description": "Table Rect Seg Base Ven Edge / Top 28.5H 36D 72W",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"OmniClass Title": "Office Furniture",
		"Width": "2100.0 mm",
		"Depth": "1200.0 mm",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842b5'
	},
	{
		"Name": 'Curtain Door [4738080]',
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Frame": "Iron, Ductile",
		"Glass": "Glass",
		"Construction Function": "Interior",
		"Width": "1049.0 mm",
		"Height": "2425.0 mm",
		"Frame Thk.": "25.0 mm",
		"Frame Width": "50.0 mm",
		"Glass Width": "25.0 mm",
		"Lock Distance": "150.0 mm",
		"Lock height": "1050.0 mm",
		"Thickness": "0.0 mm",
		"Rough Width": "0.0 mm",
		"Rough Height": "0.0 mm",
		"Mark": "GD10",
		"Type Name": "Curtain Door 50MM",
		"Type Mark": 113,
		"OmniClass Number": "23.30.10.00",
		"OmniClass Title": "Doors",
		"Define Thermal Properties by": "Schematic Type",
		"Visual Light Transmittance": 0,
		"Solar Heat Gain Coefficient": 0,
		"Thermal Resistance(R)": "0.0000(m²·K)/W",
		"Heat Transfer Coefficient(U)": "0.0000 W/(m²·K)",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484c20'
	},
	{
		"Name": 'Mobile-Egan_Visual-SplitPanel-FourPoint_Base [4735673]',
		"Category": "EF_75 : Communications, security, safety and protection functions",
		"Description": "Communications & Security",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Length 2": "1376.7 mm",
		"Length 1": "582.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Frame": "Aluminum–Egan–Clear Anodized",
		"Fabric": "Fabric-Egan-EF Swatch",
		"Extrusion": "Aluminum–Egan–Clear Anodized",
		"Caster": "Aluminum–Egan–Clear Anodized",
		"Cap": "Aluminum–Egan–Clear Anodized",
		"Bumper": "Paint–Egan–White",
		"Board": "INT_WHITE PAINT",
		"Type Name": "30w x 54h x 20d",
		"Product data url": "https://bimobject.com/eganvisual/product/egan-020",
		"URL": "http://www.egan.com/",
		"Product Page URL": "http://www.egan.com/#!mobiles-v-series-4pt/c28s",
		"Model": "VCF3054",
		"Manufacturer": "Egan Visual",
		"Description": "V- Series Mobile SplitPanel with Four - Point Base 30w x 54h x 20d",
		"Copyright ©": "© 2015 Egan Visual Inc.",
		"Assembly Code": "E1010800",
		"Width": "600.0 mm",
		"Height": "1371.6 mm",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842b9'
	},
	{
		"Name": 'NDB_Point grab [4941548]',
		"CreatedBy": "Ajay",
		"CreatedOn": "22-09-2022",
		"Category": "Sensor",
		"Color": "White",
		"Description": "NDB_Point grab_Cognipoint_",
		"Expected Life": "10 years",
		"Manufacturer": "CogniPoint",
		"Type": "PointGrab",
		"Type Name": "CogniPoint",
		"Nominal Height": "35.0 mm",
		"Nominal Length": "120.0 mm",
		"Nominal Width": "0.0 mm",
		"Size": "12cm diameter x 3.5cm thickness",
		"Subcategory": "Specialty Equipment",
		"Uniclass description": "Communications, security, safety and protection functions",
		"Schedule Level": "Basement",
		"Elevation from Level": "8680.0 mm",
		"Host": "Compound Ceiling: TRANSLUSCENT GRID 600X600",
		"Default Elevation": "1219.2 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"PointGrab Body Material": "Point Grab Body",
		"PointGrab PIR Lens": "PointGrab PIR Lens",
		"External-ID": '9432ef7d-a98c-40e9-9604-878fdaddfafe-004b66ec'
	},
	{
		"Name": 'Curtain [4743523]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Mat": "INT_Curtain",
		"Height": "2880.0 mm",
		"Offset": "1.0 mm",
		"Width": "1500.0 mm",
		"Default Elevation": "0.0 mm",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00486163'
	},
	{
		"Name": 'Curtain [4743526]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Mat": "INT_Curtain",
		"Height": "2880.0 mm",
		"Offset": "1.0 mm",
		"Width": "600.0 mm",
		"Default Elevation": "0.0 mm",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00486166'
	},
	{
		"Name": 'Curtain [4743520]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Mat": "INT_Curtain",
		"Height": "2880.0 mm",
		"Offset": "1.0 mm",
		"Width": "600.0 mm",
		"Default Elevation": "0.0 mm",
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00486160',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735668]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_024",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842b4'
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735660]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_019",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842ac',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735659]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_018",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842ab'
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735658]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_017",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842aa',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735657]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_016",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842a9',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735667]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_023",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842b3',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4736307]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_025",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-00484533',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735661]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_020",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842ad',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735662]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_021",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842ae',
	},
	{
		"Name": 'HermanMiller_Seating_AER1B_NewAeron_WorkChair_BSize [4735663]',
		"Category": "EF_40 : Signage, fittings, furnishings and equipment",
		"Description": "Furniture & Accessories",
		"Level": "1ST FLOOR",
		"Elevation from Level": "1.0 mm",
		"Host": "Floor: Generic 275mm",
		"Offset from Host": "-0.0 mm",
		"Moves With Nearby Elements": 0,
		"Default Elevation": "0.0 mm",
		"Phase Created": 'New Construction',
		"Phase Demolished": 'None',
		"Armpad Finish": "Herman Miller - Finish BK Black",
		"Base Finish": "Herman Miller - Finish BK Black",
		"Chassis Finish": "Herman Miller - Finish BK Black",
		"Frame Finish": "Herman Miller - Finish BK Black",
		"Pellicle": "Herman Miller - Pellicle 23102 mesh",
		"Product Documentation Link": "https://www.hermanmiller.com/products/seating/office-chairs/aeron-chairs/",
		"Catalog Code": "HST",
		"Catalog": "Seating",
		"Asset ID": "ADR_1F_HM_AER_022",
		"Type Name": "B Size Low- Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads Basic Back Sup",
		"URL": "http://www.hermanmiller.com/",
		"Package Qty": 1,
		"Model": "AER1B11AF",
		"Manufacturer": "Herman Miller, Inc.",
		"Description": "Work Chair New Aeron B Size Low - Hgt Range Adj Std Tlt Hgt Adj Plus Pivot Arms Leather Armpads",
		"Copyright ©": "Copyright © Herman Miller, Inc.",
		"Authoring Version": "Autodesk Revit 2016",
		"Assembly Code": "E2020200",
		"OmniClass Title": "Retail and Commercial Seating Furniture",
		"Adj Lumbar Support": 0,
		"Adj PostureFit SL": 0,
		"Arms": 1,
		"PostureFit": 0,
		"Type": 0,
		"External-ID": '26f23c54-9781-45a7-ab1b-c21007eb6bb2-004842af',
	},
]