import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { errorHandler } from '../../../components/facilityManager/helpers/http'

const fetchCameraSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHikvisionCameras: builder.query({
            query: (bid) => ({
                url: `twin/v1/hikvision/camera/liveurl?bid=${bid}`,
                method: 'GET',
                credentials: "include",
                providesTags: ['camera']
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'CCTV Camera')
            },
        }),
        getCameras: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/camera/list?bid=${bid}`,
                method: 'GET',
                credentials: "include",
                providesTags: ['camera']
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'CCTV Camera')
            },
        }),
        getCameraDashboardStats: builder.query({
            query: (bid) => ({
                url: `twin/v1/camera/s1/data?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'CCTV Camera Dashboard Stats')
            },
        })
    })
})

export const { useGetHikvisionCamerasQuery, useGetCamerasQuery, useGetCameraDashboardStatsQuery } = fetchCameraSlice

const initialState = {
    isLoaded: false,
    error: "",
    cameras: [],
    dashboardStats: {
        isLoaded: false,
        data: {}
    }
}

const cameraSlice = createSlice({
    name: 'cameraSlice',
    initialState,
    reducers: {
        resetCameraDashboardStats: (state, action) => {
            state.dashboardStats = initialState.dashboardStats
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'getHikvisionCameras' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.isLoaded = true
                state.cameras = action.payload
            }
        )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getCameras' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.isLoaded = true
                    state.cameras = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getCameraDashboardStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboardStats.isLoaded = true
                    state.dashboardStats.data = action.payload
                }
            )
    }
})

export const { resetCameraDashboardStats } = cameraSlice.actions

export default cameraSlice.reducer