import { Box, Typography } from '@mui/material'
import React from 'react'
import { adminConsoleDark, adminConsoleWhite, digitalTwinDark, digitalTwinWhite } from '../../../../../../../data/imageUrls'

const RoleSwitch = (props) => {
    const { themeMode, handleDigitalTwinClick, handleAdminDashboardClick, pathname } = props

    return (
        <Box className="switchAdminMenuContainer">
            {/* Digital Twin Option */}
            <Box className="flex flexDirectionCol flexAlignItemsCenter">
                <img
                    src={themeMode ? digitalTwinWhite.src : digitalTwinDark.src}
                    alt="Digital Twin Icon"
                    className={`d72 cursorPtr pad16 bdrRadius8px ${!pathname?.includes("admin") ? "bdr-4s-dc4d28" : "bdr-05s-808080"}`}
                    onClick={handleDigitalTwinClick}
                />
                <Typography className="txtcenter mrgntop8">
                    Digital Twin
                </Typography>
            </Box>
            {/* Admin Console Option */}
            <Box className='flex flexDirectionCol flexAlignItemsCenter'>
                <img
                    src={themeMode ? adminConsoleWhite.src : adminConsoleDark.src}
                    alt="Admin Console Icon"
                    className={`d72 cursorPtr pad16 bdrRadius8px ${pathname?.includes("admin") ? "bdr-4s-dc4d28" : "bdr-05s-808080"}`}
                    onClick={handleAdminDashboardClick}
                />
                <Typography className="txtcenter mrgntop8">
                    Admin Console
                </Typography>
            </Box>
        </Box>
    )
}

export default RoleSwitch