import { Box, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DepartmentSettingsStyles from '../../../styles/DepartmentSettingStyles'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@mui/icons-material'
import Datatable from '../../../components/datatable/Datatable'
import { GET } from '../../../helpers/http'
import DepartmentModal from '../../../features/settings/department/components/modals/DepartmentModal'
import { useSelector } from 'react-redux'
import CommonStyles from '../../../styles/CommonStyles'
import validSearchTerm from '../../../helpers/validSearchTerm'
import DeleteDepartmentCategoryModal from '../../../components/modal/DeleteDepartmentCategoryModal'
import CustomButton from '../../../components/button/CustomButton'

const DepartmentSettings = () => {
    const [departments, setDepartments] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [departmentModalOpen, setDepartmentModalOpen] = useState(false)
    const [deleteDepartmentModalOpen, setDeleteDepartmentModalOpen] = useState(false)
    const [deletingDepartment, setDeletingDepartment] = useState({})
    const [selectedDepartment, setSelectedDepartment] = useState({})
    const [timer, setTimer] = useState(0)

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    const themeMode = useSelector(state => state.theme.darkTheme)

    useEffect(() => {
        const endpoint = searchTerm?.length >= 2 ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list?searchTerm=${validSearchTerm(searchTerm)?.trim()}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`

        debounce(() => {
            GET(endpoint, 'Fetch Departments')
                .then((response) => {
                    if (response?.status === 200) {
                        setDepartments(response?.data?.toSorted((a, b) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 500)
    }, [searchTerm])

    const onSearchChange = (e) => {
        setSearchTerm(e.target?.value)
    }

    const handleAddDepartmentClick = () => {
        setDepartmentModalOpen(true)
    }

    const handleDepartmentEdit = (departmentObj) => {
        setDepartmentModalOpen(true)
        setSelectedDepartment(departmentObj)
    }

    const addNewDepartment = (newDepartmentObj) => {
        setDepartments((prevState) => {
            return [...prevState, newDepartmentObj]
        })
    }

    const editDepartment = (updatedDepartmentObj) => {
        setDepartments((prevState) => {
            return prevState.map(dept => {
                if (dept?._id === updatedDepartmentObj?._id) {
                    return { ...dept, ...updatedDepartmentObj }
                } else {
                    return { ...dept }
                }
            })
        })
    }

    const handleDeleteDepartmentModalOpen = (department) => {
        setDeleteDepartmentModalOpen(true)
        setDeletingDepartment(department)
    }

    const handleDeleteDepartmentModalClose = () => {
        setDeleteDepartmentModalOpen(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Department Name',
            width: 250,
        },
        {
            field: 'code',
            headerName: 'Department Code',
            width: 250,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 450,
        },
        {
            field: 'parent',
            headerName: 'Parent Department',
            width: 250,
            valueGetter: (params) => `${params.row?.parent?.name || ''}`,
        },
        {
            field: 'Actions',
            headerAlign: 'center',
            align: 'right',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Tooltip title='EDIT' placement='top'>
                            <IconButton
                                onClick={() => handleDepartmentEdit(cellValues?.row)}
                            >
                                <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                                {/* <Typography color={'#DC4D28'} variant={'caption'}>
                                    &nbsp;EDIT
                                </Typography> */}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => handleDeleteDepartmentModalOpen(cellValues?.row)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ]

    return (
        <Box
            sx={CommonStyles?.OuterBox}>
            <Typography
                sx={DepartmentSettingsStyles.headingTypo}
            >
                Departments
            </Typography>
            <Box
                sx={DepartmentSettingsStyles.innerBox}
            >
                <Paper
                    sx={{
                        ...DepartmentSettingsStyles.paperContainer,
                    }}
                    className='theme-cards-boxShadow theme-component-background bdrRadius4px'
                >
                    <Grid container direction={'row-reverse'} spacing={1} className='flexAlignItemsCenter'>
                        <Grid item xs={1.5}>
                            <CustomButton textContent='Add Department' variant="outlined" sx={DepartmentSettingsStyles.addButton} onClick={handleAddDepartmentClick} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={searchTerm}
                                onChange={onSearchChange}
                                className='width100 maxHeight40px'
                                placeholder="Search a Department by name or code.."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlined sx={{ color: '#616161' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        // Customize the border color
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the focused border color
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the input text color
                                        '& .MuiInputBase-input': {
                                            color: themeMode ? '#fff' : '#000'
                                        },
                                        // Customize the cursor color
                                        '& .MuiInputBase-input::selection': {
                                            backgroundColor: '#808080',
                                        },
                                        // Customize the border color on hover
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Datatable
                    checkBox={false}
                    rows={departments}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                />
            </Box>
            <DepartmentModal
                departmentModalOpen={departmentModalOpen}
                setDepartmentModalOpen={setDepartmentModalOpen}
                addNewDepartment={addNewDepartment}
                editDepartment={editDepartment}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
            />
            <DeleteDepartmentCategoryModal
                deleteModalOpen={deleteDepartmentModalOpen}
                handleDeleteModalClose={handleDeleteDepartmentModalClose}
                deletingDepartment={deletingDepartment}
                setDepartments={setDepartments}
            />
        </Box>
    )
}

export default DepartmentSettings