import { Button } from '@mui/material'
import React from 'react'

const CustomButton = (props) => {
    const { textContent, className, children, ...rest } = props
    return (
        <Button
            className={`btnFontSize ${className}`}
            {...rest}
        >
            {textContent || children}
        </Button>
    )
}

export default CustomButton