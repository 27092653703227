import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";
import { dropdownFloorsSort } from "../../../components/facilityManager/helpers/dropdownFloorsSort";
import { washroomAqiOccColors } from "../../../components/facilityManager/helpers/washroomAqiOccColors";

const floorInitialState = (floor, area, id) => {
    return {
        isLoaded: false,
        error: "",
        floor,
        areaId: id,
        area,
        data: [],
    }
}

const singleAreaDataFormatter = (responseData) => {
    const newData = structuredClone(responseData)
    const colorData = newData && newData?.length > 0 ? newData?.map(unit => {
        const label = unit.hasOwnProperty('aqi') ? 'aqi' :
            unit.hasOwnProperty('ammonia') ? 'ammonia' :
                unit.hasOwnProperty('washroom_bin') ? 'washroom_bin' :
                    unit.hasOwnProperty('washroom_occupancy') ? 'washroom_occupancy' :
                        unit.hasOwnProperty('temperature') ? 'temperature' :
                            unit.hasOwnProperty('washroom_papertowel') ? 'washroom_papertowel' :
                                unit.hasOwnProperty('soap_solution') ? 'soap_solution' : ''

        const value = unit?.aqi >= 0 ? unit?.aqi :
            unit?.ammonia >= 0 ? unit?.ammonia :
                unit?.washroom_bin >= 0 ? unit?.washroom_bin :
                    unit?.washroom_occupancy >= 0 ? unit?.washroom_occupancy :
                        unit?.temperature >= 0 ? unit?.temperature :
                            unit?.washroom_papertowel >= 0 ? unit?.washroom_papertowel :
                                unit?.soap_solution >= 0 ? unit?.soap_solution : 'NA'

        return {
            name: unit.hasOwnProperty('aqi') ? 'AQI' :
                unit.hasOwnProperty('ammonia') ? 'Ammonia' :
                    // unit.hasOwnProperty('washroom_bin') ? 'Bin' :
                    unit.hasOwnProperty('washroom_bin') ? unit.alias || 'Bin' :
                        unit.hasOwnProperty('washroom_occupancy') ? 'Occupancy' :
                            unit.hasOwnProperty('temperature') ? 'Temperature' :
                                unit.hasOwnProperty('washroom_papertowel') ? 'Towel' :
                                    unit.hasOwnProperty('soap_solution') ? 'Soap' : '',
            label,
            isLatest: unit?.isLatest,
            value,
            unit: unit?.units,
            color: Object.values(washroomAqiOccColors(label, value, unit?.isLatest))?.[0],
            sensorId: unit?.sensorId
        }
    }) : []
    return colorData
}

const fetchWashroomSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        fetchWashroomData: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/restrooms/s1/data?bid=${bid}&areas=all`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: responseData => {
                const areas = responseData?.data
                const areaData = (areas && areas?.length > 0) ? areas?.map(floorData => {
                    const data = floorData?.data
                    const indicesData = (data && data?.length > 0) ? data?.map(index => {
                        return {
                            ...index,
                            latestData: {
                                ...index?.latestData,
                                value: index?.latestData?.value >= 0 ? index?.latestData?.value : "NA",
                                color: Object.values(washroomAqiOccColors(index?.index, index?.latestData?.value, index?.latestData?.isLatest))?.[0]
                            }

                        }
                    }) : []
                    return { ...floorData, data: indicesData }
                }) : []

                return areaData
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Washroom Dashboard Data')
            },
            providesTags: ['washrooms']
        }),
        fetchWashroomFloors: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/restrooms/s2/options?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: responseData => {
                const result = responseData?.data
                const floors = dropdownFloorsSort(result?.floors)
                const newFloorDetails = []
                const areas = []

                floors.forEach((floor, index) => {
                    floor.areas.forEach((ele, i) => {
                        areas.push({ ...ele, floor: floor.name })
                        newFloorDetails.push(floorInitialState(floor.name, ele.name, ele.id))
                    })
                })
                return { floors: { isLoaded: true, selectedFloor: { name: floors?.[0]?.name }, floors }, areas, floorDetails: newFloorDetails, selectedFloorDetails: newFloorDetails }
            },
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Washroom Secondary Options')
            },
            providesTags: ['washrooms']
        }),
        fetchSelectedWashroomFloorData: builder.query({
            query: ({ area, bid }) => ({
                url: `twin/v1/restrooms/s2/data?areaId=${area}&bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Washroom Secondary Floor Data')
            },
            transformResponse: (responseData, meta, arg) => {
                const colorData = singleAreaDataFormatter(responseData)
                const features = colorData?.filter(ele => ele.name)
                features.sort((a, b) => a?.name?.localeCompare(b?.name))

                const areaId = arg?.area

                return { features, areaId }
            },
            providesTags: ['washrooms']
        }),
        fetchWashroomTertiaryDurations: builder.query({
            query: () => ({
                url: `twin/v1/restrooms/s3/options?durations=true`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Washroom Tertiary Options')
            },
            providesTags: ['washrooms']
        }),
        fetchSelectedAreaWashroomTertiary: builder.query({
            query: ({ areaId, bid }) => ({
                url: `twin/v1/restrooms/s2/data?areaId=${areaId}&bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Washroom Tertiary Latest Data')
            },
            transformResponse: responseData => {
                const colorData = singleAreaDataFormatter(responseData)
                const features = colorData?.filter(ele => ele.name)
                features.sort((a, b) => a?.name?.localeCompare(b?.name))

                return features
            },
            providesTags: ['washrooms']
        }),
        fetchWashroomHistoryData: builder.query({
            query: ({ areaId, bid, duration, dataType, startDate, endDate }) => ({
                url: `twin/v1/restrooms/s3/data?areaId=${areaId}&bid=${bid}&duration=${duration}&parameter=${dataType}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Washroom Tertiary Chart Data')
            },
            transformResponse: responseData => {
                const data = [...responseData.aqi, ...responseData.others]
                return data
            },
            providesTags: ['washrooms']
        })
    })
})

export const { useFetchWashroomDataQuery, useFetchWashroomFloorsQuery, useFetchSelectedWashroomFloorDataQuery, useFetchWashroomTertiaryDurationsQuery, useFetchSelectedAreaWashroomTertiaryQuery, useFetchWashroomHistoryDataQuery } = fetchWashroomSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        error: "",
        data: [],
    },
    secondary: {
        floors: {
            isLoaded: false,
            error: "",
            selectedFloor: {},
            data: [],

        },
        floorDetails: [],
        selectedFloorDetails: []
    },
    tertiary: {
        latest: {
            isLoaded: false,
            error: "",
            data: [],
        },
        chartData: {
            isLoaded: false,
            error: "",
            data: [],
        },
        durations: {
            data: [],
            isLoaded: false
        },
        margins: [15, 10, 25, 35],
        lineWidth: 2
    }
}

const washroomSlice = createSlice({
    name: 'washroomSlice',
    initialState,
    reducers: {
        resetDashboardRestRooms: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        dashboardWashroomSocket: (state, action) => {
            const payload = structuredClone(action.payload)

            if (payload?.name?.toLowerCase() === "ammonia") payload.name = "ammonia"
            else if (payload?.name?.toLowerCase() === "washroom aqi") payload.name = "aqi"
            else if (payload?.name?.toLowerCase() === "washroom bin") payload.name = "washroom_bin"
            else if (payload?.name?.toLowerCase() === "washroom occupancy") payload.name = "washroom_occupancy"
            else if (payload?.name?.toLowerCase() === "washroom papertowel") payload.name = "washroom_papertowel"
            else if (payload?.name?.toLowerCase() === "temperature") payload.name = "temperature"

            const newData = state.dashboard?.data?.find(ele => (
                ele?.areaName?.toLowerCase() === payload?.area?.toLowerCase() &&
                ele?.floorName?.toLowerCase() === payload?.floor?.toLowerCase()
            ))

            const modifierData = newData?.data?.find(ele => ele?.index === payload.name)

            if (modifierData?.latestData) {
                if (modifierData?.index === 'washroom_papertowel' || modifierData?.index === 'washroom_bin') {
                    modifierData.latestData.value = payload.value?.p
                }
                else modifierData.latestData.value = payload.value
            }
        },
        resetWashroomSecondary: (state, action) => {
            state.secondary = initialState.secondary
        },
        updateSelectedWashroomFloor: (state, action) => {
            const selectedFloor = action.payload
            const selectedFloorData = selectedFloor === 'All Floors' ? [...state.secondary?.floorDetails] : state.secondary?.floorDetails?.filter(floor => {
                return floor.floor === selectedFloor
            })

            const sortedSelectedFloorData = JSON.parse(JSON.stringify(selectedFloorData))
            sortedSelectedFloorData.sort((a, b) => {
                if (a.floor?.startsWith('Ground') && (b.floor?.startsWith('First') || b.floor?.startsWith('Second') || b.floor?.startsWith('Terrace'))) {
                    return -1
                }
                if (a.floor?.startsWith('First') && (b.floor?.startsWith('Second') || b.floor?.startsWith('Terrace'))) {
                    return -1
                }
                if (a.floor?.startsWith('Second') && b.floor?.startsWith('Terrace')) {
                    return -1
                }
            })
            state.secondary = {
                ...state.secondary,
                floors: { ...state.secondary?.floors, selectedFloor: { name: selectedFloor } },
                selectedFloorDetails: sortedSelectedFloorData
            }
        },
        resetWashroomTertiary: (state, action) => {
            state.tertiary = initialState.tertiary
        },
        resetWashroomTertiaryChartIsLoaded: (state, action) => {
            state.tertiary.chartData.isLoaded = false
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchWashroomData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.dashboard.isLoaded = true
                state.dashboard.data = action.payload
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchWashroomFloors' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.secondary = {
                    ...state.secondary,
                    floors: { ...state.secondary?.floors, ...action.payload?.floors },
                    areas: action.payload?.areas,
                    floorDetails: action.payload?.floorDetails,
                    selectedFloorDetails: action.payload?.selectedFloorDetails
                }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchSelectedWashroomFloorData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const newFloorsData = state.secondary?.floorDetails?.map(floor => {
                    if (floor.areaId === action.payload?.areaId) return { ...floor, isLoaded: true, data: action.payload?.features }
                    else return floor
                })

                const selectedFloorName = state.secondary.floors?.selectedFloor?.name
                const selectedFloorDetails = selectedFloorName === "All Floors" ? newFloorsData : newFloorsData.filter(ele => ele.floor === selectedFloorName)
                state.secondary = { ...state.secondary, floorDetails: newFloorsData, selectedFloorDetails }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchWashroomTertiaryDurations' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary = { ...state.tertiary, durations: { data: action.payload, isLoaded: true } }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchSelectedAreaWashroomTertiary' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary = { ...state.tertiary, latest: { ...state.tertiary?.latest, isLoaded: true, data: action.payload } }
            }
        )
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchWashroomHistoryData' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.tertiary = { ...state.tertiary, chartData: { isLoaded: true, data: action.payload } }
            }
        )
    }
})

export const { resetDashboardRestRooms, dashboardWashroomSocket, resetWashroomSecondary, updateSelectedWashroomFloor, resetWashroomTertiary, resetWashroomTertiaryChartIsLoaded } = washroomSlice.actions

export default washroomSlice.reducer