import { Button, Checkbox, ListItemText, MenuItem, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/formInputs/TextInput'
import CommonStyles from '../../../../styles/CommonStyles'
import { PUT, errorHandler } from '../../../../helpers/http'
import { successToast } from '../../../../helpers/apiToast'
import { useSelector } from 'react-redux'
import { fetchBuildings, fetchDepartments } from '../../helpers'

const EditUser = (props) => {
    const { editUser, closeEditUserModal, users, setUsers } = props

    const themeMode = useSelector(state => state.theme.darkTheme)

    const color = themeMode ? '#fff' : '#000'

    const bgcolor = themeMode ? '#282a2e' : '#fff'

    const updatingUser = structuredClone(editUser)

    updatingUser.buildings = editUser?.buildings?.map(building => building?._id)

    updatingUser.department = editUser?.department?._id

    const roles = ['admin', 'facility manager', 'employee']

    const [buildings, setBuildings] = useState([])

    const [departments, setDepartments] = useState([])

    const [updateUser, setUpdateUser] = useState(structuredClone(updatingUser) || {})

    const [formErrors, setFormErrors] = useState({})

    const errors = {}

    const handleUserChange = (e) => {
        const { name, value } = e.target
        setFormErrors(prevState => {
            return { ...prevState, [name]: "" }
        })
        setUpdateUser(prevState => {
            if (name === 'buildings') {
                return { ...prevState, [name]: typeof value === 'string' ? value.split(',') : value }
            }
            return { ...prevState, [name]: value }
        })
    }

    const runValidations = () => {
        if (!updateUser?.department) {
            errors.department = "Please select a department"
        }
        if (updateUser?.role === 'facility manager' && updateUser?.buildings?.length === 0) {
            errors.buildings = "Please select atleast one building"
        }
    }

    const handleUpdateUserCall = async () => {
        try {
            const formObj = {
                role: updateUser?.role,
                buildings: updateUser?.buildings,
                buildingId: updateUser?.buildingId,
                department: updateUser?.department,
                designation: updateUser?.designation
            }

            const { data } = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/user/${updatingUser?._id}`, formObj, 'Update User')
            const updatedUser = users.map(user => {
                if (user?._id === data?._id) {
                    return data
                }
                return user
            })
            setUsers(updatedUser)
            closeEditUserModal()
            successToast("User Details updated")
        } catch (error) {
            if (error.response?.status === 403) {
                errorHandler(error.response?.data?.message)
            }
            console.log({ error })
        }
    }

    const handleUpdateUser = () => {
        runValidations()

        if (Object.keys(errors)?.length === 0) {
            setFormErrors({})
            handleUpdateUserCall()
        } else {
            setFormErrors(errors)
        }
    };

    useEffect(() => {
        fetchBuildings({ setBuildings })
        fetchDepartments({ setDepartments })
    }, []);

    return (
        <>
            <Typography textAlign={'center'} fontSize={'16px'} color={color}>User: {updateUser?.fullName}</Typography>
            <Stack spacing={2} mt={2}>
                <TextInput
                    label={'Role'}
                    select
                    name='role'
                    value={updateUser?.role}
                    onChange={handleUserChange}
                    SelectProps={{
                        style: { color },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: bgcolor,
                                color
                            },
                        },
                        disableScrollLock: true,
                        className: themeMode ? 'menu-dark' : 'menu-light'
                    }}
                >
                    {
                        roles.map(role => (
                            <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))
                    }
                </TextInput>
                <TextInput
                    label={'Buildings'}
                    select
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                            const buildingNames = buildings?.filter(building => selected?.indexOf(building?._id) > -1)?.map(building => building?.name)
                            return buildingNames.join(', ')
                        },
                        style: { color }
                    }}
                    name='buildings'
                    value={updateUser?.buildings || []}
                    onChange={handleUserChange}
                    error={formErrors?.buildings}
                    helperText={formErrors?.buildings}
                >
                    {
                        buildings?.map(building => (
                            <MenuItem key={building?._id} value={building?._id}>
                                <Checkbox checked={updateUser?.buildings?.indexOf(building?._id) > -1} />
                                <ListItemText primary={building?.name} />
                            </MenuItem>
                        ))
                    }
                </TextInput>
                <TextInput
                    label={'Assigned Building'}
                    select
                    name='buildingId'
                    value={updateUser?.buildingId}
                    onChange={handleUserChange}
                    error={formErrors?.buildings}
                    helperText={formErrors?.buildings}
                >
                    {
                        buildings?.map(building => (
                            <MenuItem key={building?._id} value={building?._id}>
                                <ListItemText primary={building?.name} />
                            </MenuItem>
                        ))
                    }
                </TextInput>
                <TextInput
                    label={'Department'}
                    select
                    name='department'
                    value={updateUser?.department || ""}
                    onChange={handleUserChange}
                    error={formErrors?.department}
                    helperText={formErrors?.department}
                >
                    {
                        departments?.map(department => (
                            <MenuItem key={department?._id} value={department?._id}>{department?.name}</MenuItem>
                        ))
                    }
                </TextInput>
                <TextInput
                    label={'Designation'}
                    name='designation'
                    value={updateUser?.designation || ""}
                    onChange={handleUserChange}
                />
                <Button sx={{ ...CommonStyles.actionButton, fontSize: '14px' }} onClick={handleUpdateUser}>Update User</Button>
            </Stack>
        </>
    )
}

export default EditUser