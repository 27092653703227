import { Box, Card, Typography } from "@mui/material";
import { ThemeColors } from "../../../hooks/ThemeColors";
import CommunitiesReviewBody from "../../../features/communities/review/CommunityReviewBody";

const Communities = (props) => {
	const { background_color } = ThemeColors();

	return (
		<>
			<Box className="pad16 width100 flex flexDirectionCol flexAlignItemsStart">
				<Card
					sx={{ backgroundColor: background_color }}
					className="width100 boxShadowNone mrgntop8 height12vh"
				>
					<Typography className="fontweight700 mrgntop15 mrgnleft15 font26">
						Manage Communities
					</Typography>
					<Typography className="fontweight500 font20 mrgntop5 mrgnleft15">
						Review Communities
					</Typography>
				</Card>
				<CommunitiesReviewBody />
			</Box>
		</>
	);
};

export default Communities;
