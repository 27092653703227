import { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CommonStyles from '../../../../styles/CommonStyles'
import DepartmentSettingsStyles from '../../../../styles/DepartmentSettingStyles'
import Modal from '../../../../components/modal/Modal'
import AddSingleHoliday from '../../../../features/attendance/holidayCalendar/AddSingleHoliday'
import Selectors from '../../../../features/attendance/holidayCalendar/Selectors'
import { GET } from '../../../../helpers/http'
import AddMultipleHolidaysExcel from '../../../../features/attendance/holidayCalendar/AddMultipleHolidaysExcel'
import DeleteHoliday from '../../../../features/attendance/holidayCalendar/DeleteHoliday'
import Datatable from '../../../../components/datatable/Datatable'

const HolidayCalendar = () => {

    //** Building Dropdown Logic **//
    const { buildings } = useSelector(state => state.buildings)

    const initialBuildingId = buildings?.[0]?._id || ''

    const [selectedBuilding, setSelectedBuilding] = useState(initialBuildingId)

    const handleBuildingChange = (e) => {
        setSelectedBuilding(e.target.value)
    }

    useEffect(() => {
        setSelectedBuilding(initialBuildingId)
    }, [initialBuildingId])

    //** Year Dropdown Selection Logic **//
    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value)
    }

    //** Add Single Holiday Form Modal Logic **//
    const [addSingleHolidayFormModal, setAddSingleHolidayFormModal] = useState(false)

    const handleOpenAddSingleHolidayFormModal = () => {
        setAddSingleHolidayFormModal(true)
    }

    const handleCloseAddSingleHolidayFormModal = () => {
        setAddSingleHolidayFormModal(false)
    }

    //** Add Multiple Holiday Excel File Input Logic **//
    const [addMultipleHolidaysExcelFile, setAddMultipleHolidaysExcelFile] = useState(null)

    const addMultipleHolidaysExcelFileRef = useRef(null);

    const handleAddMultipleHolidaysExcelFileChange = (e) => {
        setAddMultipleHolidaysExcelFile(e.target.files[0])
    }

    const handleAddMultipleHolidaysExcelFileReset = () => {
        setAddMultipleHolidaysExcelFile(null);
        addMultipleHolidaysExcelFileRef.current.value = null;
    }

    //** Add Multiple Holiday Excel Modal Logic **//
    const [addMultipleHolidaysExcelModal, setAddMultipleHolidaysExcelModal] = useState(false)

    const handleOpenAddMultipleHolidaysExcelModal = () => {
        setAddMultipleHolidaysExcelModal(true)
    }

    const handleCloseAddMultipleHolidaysExcelModal = () => {
        setAddMultipleHolidaysExcelModal(false)
        handleAddMultipleHolidaysExcelFileReset()
    }

    //** Edit Holiday Form Modal Logic **//
    const [editingHoliday, setEditingHoliday] = useState({})

    const [editHolidayFormModal, setEditHolidayFormModal] = useState(false)

    const handleOpenEditHolidayFormModal = (editingHoliday) => {
        setEditHolidayFormModal(true)
        setEditingHoliday(editingHoliday)
    }

    const handleCloseEditHolidayFormModal = () => {
        setEditHolidayFormModal(false)
    }

    //** Delete Holiday Modal Logic **//
    const [deletingHoliday, setDeletingHoliday] = useState({})

    const [deleteHolidayModal, setDeleteHolidayModal] = useState(false)

    const handleOpenDeleteHolidayModal = (deletingHoliday) => {
        setDeleteHolidayModal(true)
        setDeletingHoliday(deletingHoliday)
    }

    const handleCloseDeleteHolidayModal = () => {
        setDeleteHolidayModal(false)
    }

    //** Year Dropdown Options Logic **//
    const [holidayYearOptions, setHolidayYearOptions] = useState([])

    useEffect(() => {
        const fetchOptions = async () => {
            const { data } = await GET(`${process.env.REACT_APP_URL}at/v1/holidaycalendar/options`, "Holiday Years Options")
            setHolidayYearOptions(data?.years)
        }

        fetchOptions()
    }, [])

    //** Holidays Datatable Logic **//
    const [holidayData, setHolidayData] = useState([])

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const { data } = await GET(`${process.env.REACT_APP_URL}at/v1/holidaycalendar?bid=${selectedBuilding}&year=${selectedYear}`, "GET ALL HOLIDAYS")
                setHolidayData(data)
            } catch (error) {
                console.log({ error })
                setHolidayData([])
            }
        }
        if (selectedBuilding && selectedYear) {
            fetchHolidays()
        }
    }, [selectedBuilding, selectedYear])

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 200,
            valueGetter: (params) => `${moment(params.row?.date).format('DD MMM YYYY')}`,
        },
        {
            field: "name",
            headerName: "Name",
            width: 250,
        },
        {
            field: "leaveType",
            headerName: "Leave Type",
            width: 200,
        },
        {
            field: "Actions",
            headerAlign: "center",
            align: "center",
            renderCell: (cellValues) => {
                return (
                    <Stack direction={"row"} spacing={2}>
                        <Tooltip title="Edit" onClick={() => handleOpenEditHolidayFormModal(cellValues?.row)}>
                            <IconButton>
                                <EditOutlined
                                    fontSize="small"
                                    sx={{
                                        color: "#DC4D28 !important",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" >
                            <IconButton onClick={() => handleOpenDeleteHolidayModal(cellValues?.row)}>
                                <DeleteOutline
                                    fontSize="small"
                                    sx={{
                                        color: "#DC4D28 !important",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
            width: 250,
            sortable: false,
        },
    ];

    return (
        <Box sx={CommonStyles?.OuterBox}>
            <Typography sx={DepartmentSettingsStyles.headingTypo}>
                Holiday Calendar
            </Typography>
            <Box sx={DepartmentSettingsStyles.innerBox}>
                <Selectors
                    selectedYear={selectedYear}
                    handleYearChange={handleYearChange}
                    selectedBuilding={selectedBuilding}
                    handleBuildingChange={handleBuildingChange}
                    initialBuildingId={initialBuildingId}
                    buildings={buildings}
                    holidayYearOptions={holidayYearOptions}
                    handleOpenAddSingleHolidayFormModal={handleOpenAddSingleHolidayFormModal}
                    handleOpenAddMultipleHolidaysExcelModal={handleOpenAddMultipleHolidaysExcelModal}
                />
                <Datatable
                    rows={holidayData}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                    enableSearch
                    height={'80vh'}
                />
            </Box>
            <Modal
                modalOpen={addSingleHolidayFormModal}
                closeModal={handleCloseAddSingleHolidayFormModal}
                title={"Add Single Holiday"}
            >
                <AddSingleHoliday
                    closeAddSingleHolidayModal={handleCloseAddSingleHolidayFormModal}
                    setHolidayData={setHolidayData}
                />
            </Modal>
            <Modal
                modalOpen={addMultipleHolidaysExcelModal}
                closeModal={handleCloseAddMultipleHolidaysExcelModal}
                title={"Add Multiple Holidays"}
            >
                <AddMultipleHolidaysExcel
                    addMultipleHolidaysExcelFile={addMultipleHolidaysExcelFile}
                    addMultipleHolidaysExcelFileRef={addMultipleHolidaysExcelFileRef}
                    handleAddMultipleHolidaysExcelFileChange={handleAddMultipleHolidaysExcelFileChange}
                    handleCloseAddMultipleHolidaysExcelModal={handleCloseAddMultipleHolidaysExcelModal}
                    handleAddMultipleHolidaysExcelFileReset={handleAddMultipleHolidaysExcelFileReset}
                    setHolidayData={setHolidayData}
                    selectedBuilding={selectedBuilding}
                />
            </Modal>
            <Modal
                modalOpen={editHolidayFormModal}
                closeModal={handleCloseEditHolidayFormModal}
                title={"Edit Holiday"}
            >
                <AddSingleHoliday
                    closeAddSingleHolidayModal={handleCloseEditHolidayFormModal}
                    setHolidayData={setHolidayData}
                    editingHoliday={editingHoliday}
                />
            </Modal>
            <Modal
                modalOpen={deleteHolidayModal}
                closeModal={handleCloseDeleteHolidayModal}
                title={"Delete Holiday"}
                modalWidth={600}
            >
                <DeleteHoliday
                    closeDeleteHolidayModal={handleCloseDeleteHolidayModal}
                    setHolidayData={setHolidayData}
                    deletingHoliday={deletingHoliday}
                />
            </Modal>
        </Box>
    )
}

export default HolidayCalendar