import { useEffect, useState } from "react";
import WallDatatable from "../../../components/datatable/WallDatatable";
import Dropdown from "../../../components/dropdown/Dropdown";
import { errorToast, successToast } from "../../../helpers/apiToast";
import { GET, PUT } from "../../../helpers/http";
import {  Avatar, Box, Button, Card, FormControl, OutlinedInput, Stack,  Typography, } from "@mui/material";
import CommonStyles from "../../../styles/CommonStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ThemeColors } from "../../../hooks/ThemeColors";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TableSkeleton from "../../../components/Skeleton/table/TableSkeleton";
import moment from "moment";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const CommunitiesReviewBody = (props) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [communities, setCommunities] = useState({});
	const [loading, setLoading] = useState(true);
	const[timer,setTimer] = useState(0)
	const [options, setOptions] = useState(
		{
		status: ["active", "inactive", "all" ],
		type: ["public", "private","all" ]
		});
	const [page, setPage] = useState(location?.state?.page || 1)
	const [disablePrev, setDisablePrev] = useState(false)
	const [disableNext, setDisableNext] = useState(false)
	const [selectedOption, setSelectedOption] = useState({ status: "active", type:"all"})
	const { background_color, textColor } = ThemeColors();
	const communitiesFileReadUrl = useSelector(state => state?.configurations?.communitiesFileReadUrl)
	const [searchTerm, setSearchTerm] = useState('')
	
	const debounce = (fn, delay) => {
		if (timer) clearTimeout(timer)
		setTimer(setTimeout(fn, delay))
	}

	const getCommunities = async ({ type, status, page, searchTerm }) => {
		try {
			setLoading(true);
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/list?type=${type}&status=${status}&page=${page}&searchTerm=${searchTerm}`;
			debounce(async function fetchData() {
				try {
					const communities = await GET(endPoint, "Get users by search")
					setCommunities({
						totalCount: communities?.data?.totalCount, communities: communities?.data?.communities?.map((data, idx) => {
							return { ...data, slno: (idx + 1) + ((page - 1) * 10) }
						})})
					setLoading(false);
					if (communities?.data?.communities?.length < 10) setDisableNext(true)
					else if (communities?.data?.communities?.length === 10) setDisableNext(false)
				} catch (error) {
					console.log(error)
				}
			}, 500)
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	};

    const handleNextPrevBtn = (value) => {
        debounce(() => {
            if (value === "next") {
                setPage(page + 1)
                navigate("/admin/communities", { state: { page: page + 1 } })
            } else if (value === "prev") {
                if (page >= 2) {
                    setPage(page - 1)
                    navigate("/admin/communities", { state: { page: page - 1 } })
                }
            }
        }, 500)
	}

	const handleChangeCommStatus = async ({ action , communityId,status}) => {
		try {
			const body = { action, communityId }
			const endPoint = `${process.env.REACT_APP_URL}comms/v1/admin/community/actions`;
			const updatedCommunity = await PUT(endPoint, body, "Change Communities Status");
			if (status === "all") setCommunities({
				...communities, communities: communities?.communities?.map((comm) => {
					if (comm?._id === updatedCommunity?.data?._id) return { ...comm, status: updatedCommunity?.data?.status }
					else return comm
				})
				});
			else setCommunities({...communities,communities:communities?.communities?.filter((comm) => {
				return comm?._id !== updatedCommunity?.data?._id
				})});
			setLoading(false);
			successToast(`Community status changed to ${updatedCommunity?.data?.status}`)
		} catch (error) {
			console.log(error);
			if (error?.response) {
				errorToast(error?.response?.data?.message);
			} else {
				errorToast(error?.message);
			}
		}
	}

	const handleOnCellClick = (params) => {
		if (params?.field !== "actions") {
			navigate(`/admin/communities/view/${params?.id}`, { state: { id: params?.id } })
		}
	}

	useEffect(() => {
		getCommunities({ type: selectedOption?.type, status: selectedOption?.status, page: page, searchTerm });
		if (page === 1) setDisablePrev(true)
		else setDisablePrev(false)
	}, [selectedOption?.status, selectedOption?.type, searchTerm, page]);
	

	const communityColumns = [
		{
			field: "slno",
			headerName: "Sl No",
			width: 50,
		},	
		{
			field: "name",
			headerName: "Name",
			width: 250,
			renderCell: (params) => {
				return (
					<Box
						className="flex flexDirectionRow flexAlignItemsCenter gap8"
					>
						<Avatar alt={`${params?.row?.user?.fullName}'s img`}
							src={params?.row?.profilePhoto[0]?.key && `${communitiesFileReadUrl}${params?.row?.profilePhoto[0]?.key}`}
						/>
						<Typography> {params?.row?.name}</Typography>
					</Box >
				)
			}
		},
		{
			field: "description",
			headerName: "Description",
			width: 330,
		},
		{
			field: "membersCount",
			headerName: "Members",
			width: 80,
		},
		{
			field: "blockedCount",
			headerName: "Blocked",
			width: 80,
		},
		{
			field: "type",
			headerName: "Type",
			width: 80,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.type?.toLowerCase() === "private" ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small"/>}
					</>
				)
			}
		},
		{
			field: "status",
			headerName: "Status",
			width: 80,
			renderCell: (params) => {
				return params?.row?.status?.toLowerCase() === "active" ?
					<Typography className="bg-active bdrRadius1rem width62 flex flexJustifyCenter color-white font13">Active</Typography> :
					<Typography className="bg-inactive bdrRadius1rem width62 flex flexJustifyCenter color-white font13">Inactive</Typography>
			}
		},
		{
			field: "createdBy",
			headerName: "Creator",
			width: 200,
			renderCell: (params) => params?.row?.createdBy?.fullName,
		},
		{
			field: "createdAt",
			headerName: "Date Created",
			width: 160,
			renderCell: (params) => moment(params?.row?.createdAt).format('lll')
		},
		{
			field: "flagCount",
			headerName: "Flag Count",
			width: 100,
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 120,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.status === "active" ? (
							<Button
								sx={{ ...CommonStyles.actionButton }}
								onClick={() => handleChangeCommStatus({
								action: "inactive",
								communityId: params?.row?._id,
								status: selectedOption?.status
							})}>Deactivate</Button>
						) : (
							<Button
								sx={{ ...CommonStyles.actionButton }}
								onClick={() => handleChangeCommStatus({
								action: "active",
								communityId: params?.row?._id,
								status: selectedOption?.status
							})}>Activate</Button>
						)}
					</>
				);
			},
		},
	];

	return (
		<>
			<Card
				sx={{ backgroundColor: background_color }}
				className="height5vh bdrRadius4px mrgnY10 width100 boxShadowNone flex flexDirectionRow flexAlignItemsCenter gap32"
			>
				<Box className="flex flexDirectionRow">
				<Dropdown
					margin={0}
					data={options?.type?.map(
						(type) => `${type[0].toUpperCase()}${type.slice(1)}`
					)}
					value={`${selectedOption?.type[0]?.toUpperCase()}${selectedOption?.type?.slice(1)}`}
					onChange={(e)=> setSelectedOption({...selectedOption, type:e.target.value.toLowerCase()})}
				/>
				<Dropdown
					margin={0}
					data={options?.status?.map(
						(status) => `${status[0].toUpperCase()}${status.slice(1)}`
					)}
					value={`${selectedOption?.status[0]?.toUpperCase()}${selectedOption?.status?.slice(1)}`}
					onChange={(e) => setSelectedOption({ ...selectedOption, status: e.target.value.toLowerCase() })}
					/>	
				</Box>
				<FormControl
					sx={{
						"& .MuiInputBase-root": {
						height:"2rem"
						},
					}}
					>
					<OutlinedInput
						sx={{ color: textColor }}
						placeholder="Search Community"
						onChange={(e) => {
							setSearchTerm(e.target.value)
							setPage(1)
					}} />
				</FormControl>
			</Card>
			<Box className="height60vh width100">
			{!loading ?
				< WallDatatable
				checkBox={false}
				rows={communities?.communities}
				columns={communityColumns}
				pageSize={15}
				rowsPerPage={15}
				autoHeight={false}
				getRowHeightValue={50}
				isLoading={loading} //change later
				hideFooter={true}
				tableHeight={"60vh"}
				hideheaderToolBar={true}
				onAssetClick={handleOnCellClick}
				/>
				:
				<TableSkeleton tableProp={communityColumns} />
				}
				</Box>
				<Box className="flex flexDirectionRow gap16 flexJustifyEnd padright80 padtop15 flexAlignItemsCenter width100" >
				{!loading && <Typography>
					{`${1 + ((page - 1) * 10)} to ${10 * page > communities?.totalCount ? communities?.totalCount : 10 * page} of ${communities?.totalCount}`}
				</Typography>}
				<Button
					onClick={() => handleNextPrevBtn("prev")}
					sx={{
						backgroundColor: "#dc3d28",
						color: "white",
						"&:hover": { backgroundColor: "#dc3d28" },
					}}
					disabled={disablePrev}
				>
					<ArrowBackIcon />
				</Button>
				<Button
					onClick={() => handleNextPrevBtn("next")}
					sx={{
						backgroundColor: "#dc3d28",
						color: "white",
						"&:hover": { backgroundColor: "#dc3d28" },
					}}
					disabled={disableNext}
				>
					<ArrowForwardIcon />
					</Button>
				</Box>
			</>
	);
};

export default CommunitiesReviewBody;
