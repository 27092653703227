import React from 'react'
import Modal from '../../../../../components/modal/Modal'
import { Box, Button, Typography } from '@mui/material'
import CommonStyles from '../../../../../styles/CommonStyles'
import { DELETE } from '../../../../../helpers/http'
import { successToast } from '../../../../../helpers/apiToast'

const DeleteGroupModal = (props) => {
    const { openDeleteGroupModal, handleCloseDeleteGroupModal, groups, setGroups, deletingGroup } = props

    const handleDeleteGroup = async () => {
        try {
            const response = await DELETE(`${process.env.REACT_APP_BASE_URL}um/v1/admin/group/${deletingGroup?._id}`, 'Delete Group')
            const groupsAfterDelete = groups.filter(group => group?._id !== response?.data?._id)
            setGroups([...groupsAfterDelete])
            handleCloseDeleteGroupModal()
            successToast(`Group ${deletingGroup?.name} deleted successfully`)
        } catch (error) {
            console.log({ error })
        }
    }

    return (
        <Modal
            modalOpen={openDeleteGroupModal}
            closeModal={handleCloseDeleteGroupModal}
            title={'Delete this group?'}
        >
            <Typography sx={{ textAlign: 'center', fontSize: '16px' }}>Are you sure you want to delete the group {deletingGroup?.name}?</Typography>
            <Box display={'flex'} justifyContent={'space-evenly'} mt={2}>
                <Button sx={{ ...CommonStyles.actionButton }} onClick={handleDeleteGroup}>Delete</Button>
                <Button onClick={handleCloseDeleteGroupModal} sx={{
                    bgcolor: '#212121',
                    color: 'white',
                    textTransform: 'none',
                    "&:hover": {
                        bgcolor: '#111111',
                    }
                }}>Cancel</Button>
            </Box>
        </Modal>
    )
}

export default DeleteGroupModal