import { useState } from 'react';
import { Stack, Switch, Typography } from '@mui/material';
import { POST } from '../../helpers/http';
import { errorToast, successToast } from '../../helpers/apiToast';
import { useSelector } from 'react-redux';

const DVRSwitchButton = (props) => {
    const { dvrData } = props
    const [checked, setChecked] = useState(dvrData.isDisabled);
    const tenantCode = useSelector(state => state?.user?.tenantCode)

    const handleChange = async (event) => {
        setChecked(event.target.checked);
        const switchValue = event.target.checked === true ? false : true

        const body = {
            connectionDetails: { ...dvrData?.connectionDetails },
            subTenantReference: dvrData?.subTenantReference?._id,
            dvrUUID: dvrData?.UUID,
            isDisabled: switchValue,
        }
        try {
            const switchResponse = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check`, body, "DVR SWITCH")

            setChecked(!checked)
            successToast(switchResponse?.data?.dvrConfig?.isDisabled === true ? "DVR Disabled" : "DVR Enabled")
        } catch (error) {
            errorToast(`DVR SWITCH: Error while switching DVR`)
        }
    };

    return (
        <Stack direction='row' alignItems='center'>
            <Typography>off</Typography>
            <Switch
                checked={!checked}
                sx={{
                    '& .MuiSwitch-switchBase': {
                        color: '#ef5350',
                        '&.Mui-checked': {
                            color: '#ef5350',
                        },
                        '&.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#ef5350',
                        },
                    },
                    "& .MuiSwitch-thumb": {
                        color: '#ef5350'
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: 'gray',
                    },
                }}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>on</Typography>
        </Stack>
    );
}

export default DVRSwitchButton