import React from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector } from "react-redux";
import DataTableStyles from "../../styles/DatatableStyles";
import './Datatable.css';

const Datatable = (props) => {
    const { rows, columns, setSelectionModel, onAssetClick, enableSearch, checkboxSelection, height, disableGridToolBar } = props;

    const themeMode = useSelector(state => state.theme.darkTheme);

    return (
        <div sx={DataTableStyles.boxContainer} style={{ height: height || 723, width: '100%' }}
            className={`DataGridContainer ${themeMode ? 'dark' : 'light'} bdrRadius4px`}>
            <DataGrid
                sx={{
                    '& .MuiPaper-root .MuiDataGrid-paper': {
                        backgroundColor: themeMode ? '#282a2e' : '#fff',
                    }
                }}
                className='theme-component-background theme-border theme-cards-boxShadow theme-color'
                rows={rows}
                columns={columns}
                slots={{
                    toolbar: disableGridToolBar ? null : GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: enableSearch,
                    },
                }}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'data-grid-row even' : 'data-grid-row odd'
                }
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (setSelectionModel)
                        setSelectionModel(newSelectionModel);
                }}
                getRowId={(row) => row.id ? row.id : row._id}
                onCellClick={(event) => onAssetClick ? onAssetClick(event) : null}
                autoPageSize
            />
        </div>
    )
}

export default Datatable;
