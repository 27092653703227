export const initialPPMOptions = {
    durations: [],
    priorities: [],
    areas: [],
    categories: [],
    assets: [],
    vendors: [],
    vendorAssignees: [],
    internalAssignees: []
}

export const PPMOptionsReducer = (state, action) => {
    switch (action.type) {
        case 'durations': {
            return { ...state, durations: action.payload }
        }
        case 'priorities': {
            return { ...state, priorities: action.payload }
        }
        case 'areas': {
            return { ...state, areas: action.payload }
        }
        case 'categories': {
            return { ...state, categories: action.payload }
        }
        case 'assets': {
            return { ...state, assets: action.payload }
        }
        case 'vendors': {
            return { ...state, vendors: action.payload }
        }
        case 'vendorAssignees': {
            return { ...state, vendorAssignees: action.payload }
        }
        case 'internalAssignee': {
            return { ...state, internalAssignees: action.payload }
        }
        default: return state
    }
}