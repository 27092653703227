import { useDispatch, useSelector } from 'react-redux'
import { Divider, List, ListItem, ListItemButton, ListItemIcon, Toolbar, ListItemText, Tooltip, Stack, IconButton, Collapse } from '@mui/material'
import { toggleNavigationDrawer } from '../../../../redux/features/themeSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, useMemo, Fragment } from 'react'
import { Drawer, listItem, servicesStatus } from './helpers/helpers'
import ImageRenderer from '../../../admin/components/image/ImageRenderer'
import { menuDark, menuWhite } from '../../../../data/imageUrls'
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material'
import CommonStyles from '../../styles/CommonStyles'
import {
    fetchAmConfigurations, fetchDbConfigurations, fetchDocConfigurations, fetchFbConfigurations, fetchFrConfigurations,
    fetchFrV2Configurations, fetchMrConfigurations, fetchPPMConfigurations, fetchTwinConfigurations, fetchVmConfigurations
} from '../../../../redux/features/configurationsSlice';

const SideBar = (props) => {

    const [open, setOpen] = useState(false)
    const [collapseOpen, setCollapseOpen] = useState({
        'Home': false,
        'Digital Twin': false,
        'Asset Management': false,
        'Visitor Management': false,
    });

    const themeMode = useSelector(state => state.theme.darkTheme)
    const user = useSelector((state) => state.user)
    const { services, underMaintenance } = useSelector(state => state.configurations)
    const color = themeMode ? '#fff' : '#000'

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const serviceStatus = useMemo(() => servicesStatus(services, user), [services, user])

    const listItems = useMemo(() => listItem({ navigate, themeMode, location, services, underMaintenance }), [navigate, themeMode, location, services, underMaintenance])

    const handleDrawerOpen = () => {
        setOpen(true)
        dispatch(toggleNavigationDrawer())
    }

    const handleDrawerClose = () => {
        setOpen(false)
        dispatch(toggleNavigationDrawer())
    }

    const handleCollapseClick = (service) => {
        const otherServices = Object.keys(collapseOpen).filter((key) => key !== service);
        const collapse = otherServices.map((key) => ({ [key]: false }));
        setCollapseOpen({ ...collapse, [service]: !collapseOpen[service] });
    };

    useEffect(() => {
        dispatch(fetchTwinConfigurations())
        // dispatch(fetchFrConfigurations())
        dispatch(fetchFrV2Configurations())
        dispatch(fetchAmConfigurations())
        dispatch(fetchDbConfigurations())
        dispatch(fetchMrConfigurations())
        dispatch(fetchFbConfigurations())
        dispatch(fetchDocConfigurations())
        dispatch(fetchVmConfigurations())
        dispatch(fetchPPMConfigurations())
    }, [dispatch])

    return (
        <Drawer
            variant="permanent"
            open={open}
            className='sidebar-drawer'
            PaperProps={{
                className: 'flex zIndex0 width72 theme-component-background',
                sx: { ...CommonStyles.scrollStyle }
            }}
        >
            <Toolbar />
            <List disablePadding>
                <ListItem disablePadding className='show'>
                    {!open ? (
                        <ListItemButton className={open ? 'justifyInitial' : 'flexJustifyCenter'}
                        >
                            <ListItemIcon
                                className='flexJustifyCenter mrgntop8'
                                onClick={handleDrawerOpen}
                            >
                                {themeMode ? <ImageRenderer src={menuWhite.src} alt={menuWhite.alt} className='d35' /> : <ImageRenderer src={menuDark.src} alt={menuDark.alt} className='d35' />}
                            </ListItemIcon>
                        </ListItemButton>
                    ) : (
                        <ListItem className='flex flexJustifyBetween mrgntop8'>
                            <Stack direction='row' justifyContent='space-between' width={'100%'} >
                                <Stack direction='row' alignItems='center'>
                                    <ImageRenderer src={themeMode ? menuWhite.src : menuDark.src} alt={themeMode ? menuWhite.alt : menuDark.alt} className='d35' />
                                    <ListItemText sx={{
                                        paddingLeft: { sm: "0vw", md: "1.5vw", lg: "16px" },
                                        '.MuiTypography-root': {
                                            fontSize: '14px',
                                            color: themeMode ? '#fff' : '#000',
                                        }
                                    }}>Menu</ListItemText>
                                </Stack>
                                <IconButton
                                    sx={{ mr: -0.5 }}
                                    onClick={() => handleDrawerClose()}
                                >
                                    <Close sx={{ fontSize: '15px', color: themeMode ? '#fff' : '#000', }} />
                                </IconButton>
                            </Stack>
                        </ListItem>
                    )}
                </ListItem>
                <Divider />
                {listItems.map((item, index) => (
                    <Fragment key={item.text}>
                        <Tooltip placement="right" title={item.text} >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: 'block',
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5, //open ? 0.5 : 2.5,
                                        // mx: open ? 2 : 0,
                                        // borderRadius: open ? '8px' : 0,
                                        backgroundColor:
                                            location.pathname === item.path
                                                ? '#dc4d28'
                                                : 'none',
                                        '&:hover': {
                                            backgroundColor: '#dc4d28',
                                        },
                                    }}
                                    onClick={() => { item.onClick(); handleCollapseClick(item.text) }}
                                    disabled={(!serviceStatus[item?.text]) || serviceStatus[item?.text] === "NA"}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.text}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            fontSize: '14px',
                                            color: location.pathname === item.path ? '#fff' : color,
                                            ...CommonStyles.textEllipsis
                                        }}
                                        disableTypography
                                    />
                                    {item.children?.length > 0 && (collapseOpen?.[item.text] ? <ExpandLess
                                        sx={{ color: location.pathname === item.path ? '#fff' : color, fontSize: '15px' }}
                                    /> : <ExpandMore
                                        sx={{ color: location.pathname === item.path ? '#fff' : color, fontSize: '15px' }}
                                    />)}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        {item.children?.length > 0 && <Collapse in={collapseOpen?.[item.text]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{
                                // mx: open ? 2 : 0,
                                bgcolor: themeMode ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0, 0, 0, 0.01)',
                                // borderRadius: open ? '8px' : 0,
                            }}>
                                {item.children.map((child, index) => (
                                    <Tooltip placement="right" title={child?.title} key={child?.title}>
                                        <ListItemButton
                                            sx={{
                                                pl: 3, //open ? 1 : 3,
                                                // borderRadius: open ? '8px' : 0,
                                                backgroundColor: child?.path === location.pathname ? "#dc4d28" : "none",
                                                '&:hover': {
                                                    backgroundColor: '#dc4d28',
                                                },
                                            }}
                                            disabled={!child?.serviceStatus || child.serviceStatus === "NA" || child.maintenanceStatus}
                                            onClick={() => navigate(child?.path)}
                                        >
                                            <ListItemIcon sx={{ mr: open ? -1.5 : -1, }}> {/*open ? -1.5 : -1, */}
                                                <ImageRenderer src={(themeMode || location?.pathname === child?.path) ? child?.icons?.[0] : child?.icons?.[1]} className='d20' />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={child?.title}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        fontSize: '14px',
                                                        color: location?.pathname === child?.path ? '#fff' : color,
                                                        ...CommonStyles.textEllipsis
                                                    },
                                                }}
                                            />
                                        </ListItemButton>
                                    </Tooltip>
                                ))}
                            </List>
                        </Collapse>}
                    </Fragment>))}
            </List>
            <Divider />
        </Drawer>
    )
}

export default SideBar