import Header from '../header/Header'
import AppContainer from '../../pages/landingPage/App'

const AdminDashboard = () => {
    return (
        <div>
            <Header />
            <AppContainer />
        </div>
    )
}

export default AdminDashboard
