// PieChart.js

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ThemeColors } from '../../../../../hooks/ThemeColors';

const HighChartsFaultPieChart = ({ data }) => {
    const { textColor, bgColor } = ThemeColors()

    const options = {
        chart: {
            type: 'pie',
            backgroundColor: bgColor,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                colors: ["#ADD8E6", "#90EE90", "#F08080", "#FFB6C1", "#E9E9A2",
                    "#FFA07A", "#87CEFA", "#B0C4DE", "#AFEEEE", "#E6E6FA",
                    "#B1EFB1", "#C3F4F4", "#FFE4E1", "#FFDAB9", "#F5F5D4"],
                allowPointSelect: false,
                cursor: 'pointer',
                slicedOffset: 0,
                dataLabels: {
                    enabled: true,
                    format: '<span><b>{point.name}</b>' +
                        '</span><br>' +
                        '<span>Count: {point.y}</span>',
                    style: {
                        fontSize: '0.8em',
                        color: textColor(),
                        fontWeight: 500
                    },
                },
            }
        },
        series: [
            {
                name: '',
                colorByPoint: true,
                data: data ?? []
            }
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default HighChartsFaultPieChart;
