import Box from '@mui/material/Box';
import { Collapse, IconButton, Table, TableBody, TableRow, TableCell, TableHead, Tooltip, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './areaTableCollapsed.css'

const AreasRow = (props) => {
    const { floor } = props;
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false);
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    useEffect(() => {
        // if (location?.state?.floorId === floor?.floorId && location?.state?.url?.includes('/admin/settings/areas/view')) {
        //     setOpen(true)
        // }
        if (location?.state?.collapseStatus && location?.state?.floorId === floor?.floorId) {
            setOpen(true)
        }
    }, [])

    const viewArea = (id) => {
        navigate({ pathname: `/admin/settings/areas/view/${id}`, state: { floorId: floor?.floorId, collapseStatus: true } })
    }

    const arrowButtonHandle = () => {
        setOpen(!open)
        navigate({ state: { floorId: floor?.floorId, collapseStatus: !open } })
    }

    const tableCellStyle = { fontSize: 16, fontWeight: 600, color }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={arrowButtonHandle}
                    >
                        {open ? <KeyboardArrowUp style={{ color: color }} /> : <KeyboardArrowDown style={{ color: color }} />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ fontSize: 16, color }}>
                    {floor?.floorName}
                </TableCell>
            </TableRow>
            <TableRow className='collapse-row'>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table border='1'
                                className='theme-component-background collapse-table'
                                sx={{
                                    borderColor: 'divider',
                                }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={tableCellStyle}>Area Names</TableCell>
                                        <TableCell sx={tableCellStyle} align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {floor?.areas?.map((area) => (
                                        <TableRow key={area?.areaId}>
                                            <TableCell
                                                sx={{ fontSize: 15, color }}>
                                                {area?.areaName} {area?.friendlyName && `(${area?.friendlyName})`}
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Tooltip title='OPEN IMAGES' placement='top'>
                                                    <IconButton
                                                        onClick={() => viewArea(area?.areaId)}
                                                    >
                                                        <OpenInNew fontSize="small" sx={{ color: '#DC4D28' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default AreasRow