import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/dataTable/Datatable';
import { Box, FormHelperText, Grid, MenuItem } from '@mui/material';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import FormInput from '../../../components/formInputs/FormInput';
import { getFeedBackData, getUsersList } from '../helper';
import Loader from '../../../components/loading/Loader';
import moment from 'moment';
import { ThumbUpAltRounded, ThumbDownRounded } from '@mui/icons-material';

const AdminDataGrid = () => {
    const [reactionFilter, setReactionFilter] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [feebackData, setFeebackData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [userList, setUserList] = useState('');

    const getUserName = (id) => {
        return userList?.find(user => user._id === id)?.fullName
    }

    const columns = [
        {
            field: 'user_id', headerName: 'User', flex: 1,
            renderCell: (params) => getUserName(params.row.user_id)
        },
        { field: 'user_message', headerName: 'Message', flex: 2 },
        { field: 'agent_response', headerName: 'Response', flex: 2 },
        {
            field: 'user_feedback', headerName: 'Reaction', flex: .5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.user_feedback === 'upvote') {
                    return <ThumbUpAltRounded sx={{ color: 'green !important' }} />;
                } else if (params.row.user_feedback === 'downvote') {
                    return <ThumbDownRounded sx={{ color: 'red !important' }} />;
                }
                else {
                    return <ThumbDownRounded sx={{ transform: 'rotate(-90deg)', color: '#cece5fa3 !important' }} />;
                }
            }
        },
        {
            field: 'created_at', headerName: 'Created At', flex: 1.25,
            renderCell: (params) => moment(params.row?.created_at).format('MMMM Do YYYY, h:mm:ss a')
        },
    ];

    const handleDateChange = (type) => (e) => {
        const value = e.target.value;

        if (type === 'start') {
            setStartDate(value);
            if (endDate && new Date(endDate) <= new Date(value)) {
                setEndDateError('End date must be after start date.');
            } else {
                setEndDateError('');
            }
        } else if (type === 'end') {
            setEndDate(value);
            if (startDate && new Date(value) <= new Date(startDate)) {
                setEndDateError('End date must be after start date.');
            } else {
                setEndDateError('');
            }
        }
    };

    useEffect(() => {
        (async () => {
            const response = await getFeedBackData({ pageNumber: currentPage });

            const data = response?.feedbacks?.map((feedback, index) => ({
                ...feedback,
                id: `${Number(new Date())}${index}`,
            }));

            const userlist = await getUsersList();

            setFeebackData(data);
            setUserList(userlist);
        })();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Box sx={{ maxHeight: '100vh', width: '100%', maxWidth: '100vw' }}>
            <Grid container sx={{ width: '100%', mt: '3.5rem' }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    {feebackData?.length === 0 ? <Loader height='100vh' width='100vw' /> : <Box sx={{ width: '90%', height: '100%', maxHeight: '100%' }}>

                        <Grid container spacing={3} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={2}>
                                <CustomComponentContainer>
                                    <FormInput
                                        label="Filter by Reaction"
                                        fullWidth
                                        select
                                        value={reactionFilter}
                                        onChange={(e) => setReactionFilter(e.target.value)}
                                    >
                                        <MenuItem value=""><em>All</em></MenuItem>
                                        <MenuItem value="like">👍 Like</MenuItem>
                                        <MenuItem value="dislike">👎 Dislike</MenuItem>
                                    </FormInput>
                                </CustomComponentContainer>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CustomComponentContainer>
                                    <FormInput
                                        label="Start Date"
                                        fullWidth
                                        type="date"
                                        onChange={handleDateChange('start')}
                                    />
                                </CustomComponentContainer>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CustomComponentContainer>
                                    <FormInput
                                        label="End Date"
                                        fullWidth
                                        type="date"
                                        onChange={handleDateChange('end')}
                                    />
                                </CustomComponentContainer>
                                {endDateError && <FormHelperText error>{endDateError}</FormHelperText>}
                            </Grid>

                        </Grid>

                        <Datatable
                            rows={feebackData}
                            columns={columns}
                            disableSelectionOnClick
                            disableToolBar
                            onPageChange={handlePageChange}
                        />
                    </Box>}
                </Grid>
            </Grid>
        </Box>
    );
};


export default AdminDataGrid;
