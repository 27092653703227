import { AddCircleOutline, Delete } from "@mui/icons-material"
import { Avatar, Box, Grid, styled } from "@mui/material"
import { ThemeColors } from "../../../../hooks/ThemeColors";
import { useSelector } from "react-redux";
import { useState } from "react";
import Loading from "../../../../components/loading/Loading";
import ReturnMultiMedia from "../../../../components/media/ReturnMultiMedia";
import { DELETE, FILEPOST, PUT } from "../../../../helpers/http";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import ImageCropper from "../../areas/components/ImageCropper";
import { optimizImg } from "../../../../helpers/optimizeImg";

const GalleryUpload = ({ formData, setFormData, medias, setMedias, viewMode, buildingId }) => {
    const { themeMode } = ThemeColors();
    const [uploadingMedia, setUploadingMedia] = useState(false)
    const [imageToBeCropped, setImageToBeCroppped] = useState({})
    const fileReadUrl = useSelector((state) => state?.configurations?.fileReadUrl)
    const [cropModalOpen, setCropModalOpen] = useState(false)


    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const handleImage = async ({ event }) => {
        try {
            //check maxMedia count
            const fileData = event.target.files[0]
            // const img = new Image();
            // img.onload = () => {
            //     setImageToBeCroppped({
            //         src: URL.createObjectURL(fileData),
            //         width: img.naturalWidth,
            //         height: img.naturalHeight,
            //     });
            //     setCropModalOpen(true);
            // };
            // img.src = URL.createObjectURL(fileData);

            setImageToBeCroppped(URL.createObjectURL(fileData))
            // const formData1 = new FormData()

            if (!fileData) {
                return errorToast("No file selected");
            }

            // List of allowed MIME types for images and videos
            const allowedTypes = [
                // Image MIME types
                "image/jpeg",        // JPEG
                "image/jpg",         // JPG
                "image/png",         // PNG
                "image/gif",         // GIF
                "image/svg",         // SVG (non-standard)
                "image/svg+xml",     // SVG (standard)
                // "image/webp",        // WebP
                // "image/bmp",         // BMP
                // "image/tiff",        // TIFF
                // "image/x-icon",      // ICO (icon format)
                // "image/heif",        // HEIF
                // "image/heic",        // HEIC

                // Video MIME types
                // "video/mp4",         // MP4
                // "video/webm",        // WebM
                // "video/ogg",         // Ogg
                // "video/quicktime",   // MOV
                // "video/x-msvideo",   // AVI
                // "video/x-ms-wmv",    // WMV
                // "video/x-flv",       // FLV
                // "video/mpeg",        // MPEG
                // "video/3gpp",        // 3GP
                // "video/3gpp2",       // 3G2
                // "video/x-matroska",  // MKV
                // "video/x-m4v",       // M4V
            ];

            if (!allowedTypes.includes(fileData.type)) {
                event.target.value = null; // Clear the input
                return errorToast("Only images and videos are allowed");
            }


            //  crop Modal open to crop the image 

            setCropModalOpen(true)


            // formData1.append('file', fileData)
            // setUploadingMedia(true)
            // const response = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, formData1, 'File Upload : Add Image', true)
            // // const response = await FILEPOST(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media/upload`, formData, 'File Upload : Add Image', true)
            // if (response?.status === 201) {
            //     setMedias([...medias, response?.data])
            //     // setFormData({ ...formData, media: [...formData.media, response?.data] })
            //     setFormData(prevFormData => ({
            //         ...prevFormData,
            //         media: Array.isArray(prevFormData.media)
            //             ? [...prevFormData.media, response.data]
            //             : [response.data] // Default to array if media isn't an array
            //     }));

            //     setUploadingMedia(false)
            //     successToast("Image Uploaded Successfully")
            // }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            setUploadingMedia(false)
            console.log(error)
        }
    }

    const uploadCroppedImg = async (img) => {
        try {
            const optImg = await optimizImg(img)
            const myFile = new File([optImg], 'image.jpeg', {
                type: optImg.type,
            });
            const formData1 = new FormData()
            formData1.append('file', myFile)
            setUploadingMedia(true)
            const response = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, formData1, 'File Upload : Add Image', true)
            // const response = await FILEPOST(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media/upload`, formData, 'File Upload : Add Image', true)
            if (response?.status === 201) {
                setMedias([...medias, response?.data])
                // setFormData({ ...formData, media: [...formData.media, response?.data] })
                setFormData(prevFormData => ({
                    ...prevFormData,
                    media: Array.isArray(prevFormData.media)
                        ? [...prevFormData.media, response.data]
                        : [response.data] // Default to array if media isn't an array
                }));

                setUploadingMedia(false)
                successToast("Image Uploaded Successfully")
            }

        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            setUploadingMedia(false)
            console.log(error)
        }

    }

    const handleCloseCroppedModal = () => {
        setCropModalOpen(false)
    }

    const handleDeleteMediaFun = async (media) => {
        try {
            // const response = await DELETE(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/?uploadId=${media.uploadId}`, "Delete Media")
            // const response = await DELETE(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media?uploadId=${media?.uploadId}`)

            const mediaArray = medias?.filter(mda => mda?.uploadId !== media?.uploadId)
            setMedias(mediaArray)
            // const result = await PUT(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/building/profile?bid=${buildingId}`, { media: mediaArray }, "Updated Building Profile")

            setFormData({ ...formData, media: mediaArray })
            successToast("Media Deleted Successfully")

        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }

    }
    return (
        <Box sx={{ height: "8rem", overflow: "auto" }}>
            <Grid container spacing={2} sx={{ height: "8rem", }}>
                <Grid item sm={6} md={4} lg={3} xl={3} >
                    <Box
                        component='label'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                            // ...sx
                        }}>
                        <AddCircleOutline sx={{ fontSize: '3rem' }} />
                        <VisuallyHiddenInput
                            type="file"
                            accept={"image/*"}
                            disabled={viewMode}
                            onChange={(event) => handleImage({ event })}
                        />
                    </Box>
                </Grid>
                {medias?.length > 0 &&
                    medias?.map((media, i) => {
                        return <Grid item
                            key={i}
                            sm={6} md={4} lg={3} xl={3}
                            sx={{ position: "relative", height: "100%" }}
                        >
                            <ReturnMultiMedia
                                // fitImage="cover"
                                fileReadUrl={fileReadUrl} media={media} height="100%"
                                sx={{ border: '1px solid lightgray', borderRadius: "1rem" }} />
                            {!viewMode && <Avatar sx={{
                                position: 'absolute',
                                height: "1.5rem",
                                width: "1.5rem",
                                backgroundColor: "black",
                                right: "0.5rem",
                                top: "1.5rem"
                            }}>
                                <Delete
                                    fontSize="small"
                                    onClick={() => handleDeleteMediaFun(media)}
                                    sx={{ cursor: 'pointer' }} />
                            </Avatar>}
                        </Grid>
                    })
                }
                {uploadingMedia && <Grid item sm={6} md={4} lg={3} xl={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                        }}>
                        <Loading height="100%" />
                    </Box>
                </Grid>}

                {cropModalOpen && <ImageCropper
                    img={imageToBeCropped}
                    open={cropModalOpen}
                    close={handleCloseCroppedModal}
                    uploadCroppedImg={uploadCroppedImg}
                />}
            </Grid>
        </Box >
    )

}
export default GalleryUpload