const AddAssetFormStyles = {
    gridContainer: {
        position: 'sticky',
        top: 50,
        zIndex: 500,
    },
    paperContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: '#fff',
        mt: 1,
        p: 1,
    },
    buttonSwitch: {
        textTransform: 'none',
        color: '#DC4D28',
        borderColor: '#DC4D28',
        ml: 2,
    },
    boxContainer: {
        pr: 2,
        display: 'flex',
    },
    cancelButton: {
        mr: 1,
        // borderRadius: 2,
        bgcolor: '#4B4B4B',
        borderColor: '#4B4B4B',
        width: '100%',
        // maxHeight: 35,
        // height: "80%",
        alignSelf: 'center',
    },
    submitButton: {
        color: 'white',
        borderColor: '#DC4D28',
        bgcolor: '#DC4D28',
        width: '100%',
        // maxHeight: 35,
        // height: "80%",
        alignSelf: 'center',
        ':hover': { bgcolor: '#DC4D28' },
    },
    gridContainerTwo: {
        p: 1,
        pl: 2,
    },
    stackContainer: {
        direction: 'row',
    },
    textpaddingBottom: {
        pb: 1,
    },
    textpaddingTop: {
        pt: 2
    },
    commonStackItem: {
        pl: 3,
    },
    multipleAssetsText: {
        pb: 1,
        pl: 3,
    },
    commonAssignedToText: {
        pt: 2,
        pb: 1,
    },
    dropDown: {
        width: 400,
        margin: 0,
        maxHeight: 500,
    },
    stackSpacing6: {
        pl: 3, pt: 2
    },
    buttonAddField: {
        width: 150,
        color: '#DC4D28', my: 2
    },
    fileInputStyle: {
        width: 100,
        height: 40
    },
    CommonBoxStyles: {
        ml: 3
    },
    commonBoxFlexWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 2, mb: 2
    },
    boxImageLinks: {
        display: 'flex', alignItems: 'flex-start', mt: 2
    },
    imageHeightWidth: {
        height: 100, width: 100
    },
    commonBox: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: 200, mt: 2, mr: 4
    },
    boxSpaceBetween: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'
    },
    commonImageBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1
    },
    textManuls: {
        mt: 1.5, pb: 1
    }
}
export default AddAssetFormStyles
