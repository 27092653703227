import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    startTime: '08:00 AM',
    endTime: '07:00 PM',
}

const timeRangeSlice = createSlice({
    name: 'timeRange',
    initialState,
    reducers: {
        setStartTime: (state, action) => {
            if (action.payload.split(':')[0] > state.endTime.split(':')[0]) {
                state.endTime = ''
            }
            state.startTime = action.payload
        },
        setEndTime: (state, action) => {
            state.endTime = action.payload
        },
        resetTimeRange: (state) => {
            return initialState
        }
    }
})

export const { setStartTime, setEndTime, resetTimeRange } = timeRangeSlice.actions

export default timeRangeSlice.reducer