import React from 'react'
import Modal from '../../../../../components/modal/Modal'
import { Box, Button, Typography } from '@mui/material'
import CommonStyles from '../../../../../styles/CommonStyles'
import { DELETE } from '../../../../../helpers/http'
import { successToast } from '../../../../../helpers/apiToast'
import { useSelector } from 'react-redux'

const DeleteNotificationMappingModal = (props) => {
    const { openDeleteMappingModal, handleCloseDeleteModal, notificationMappings, setNotificationMappings, selectedMapping } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    const handleDeleteNotificationMapping = async () => {
        try {
            const response = await DELETE(`${process.env.REACT_APP_BASE_URL}um/v1/admin/notification-mappings/${selectedMapping?._id}`, 'Delete Notification Mapping')
            const notificationsAfterDelete = notificationMappings.filter(mapping => mapping?._id !== response?.data?._id)
            setNotificationMappings([...notificationsAfterDelete])
            handleCloseDeleteModal()
            successToast(`Mapping ${selectedMapping?.name} deleted successfully`)
        } catch (error) {
            console.log({ error })
        }
    }

    return (
        <Modal
            modalOpen={openDeleteMappingModal}
            closeModal={handleCloseDeleteModal}
            title={'Delete this Notification Mapping?'}
        >
            <Typography sx={{ textAlign: 'center', fontSize: '16px', color }}>Are you sure you want to delete the mapping {selectedMapping?.name}?</Typography>
            <Box display={'flex'} justifyContent={'space-evenly'} mt={2}>
                <Button sx={{ ...CommonStyles.actionButton }} onClick={handleDeleteNotificationMapping}>Delete</Button>
                <Button onClick={handleCloseDeleteModal} sx={{
                    bgcolor: '#212121',
                    color: 'white',
                    textTransform: 'none',
                    "&:hover": {
                        bgcolor: '#111111',
                    }
                }}>Cancel</Button>
            </Box>
        </Modal>
    )
}

export default DeleteNotificationMappingModal