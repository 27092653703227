import * as React from 'react';
import { Box, Modal, TextField, Button, } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommonStyles from '../../../styles/CommonStyles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';


export default function AttendanceModal(props) {
    const { handleClose, setUpdateTime, updateEmployeeAttendance, empName } = props
    const [value, setValue] = React.useState(new Date());

    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const shadowColor = themeMode ? '#808080' : '#b4cbdf'

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: themeMode ? '#282a2e' : '#fff',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        color: color
    };

    const commonSX = {
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#808080', // Set default border color to #808080
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Change the border color to primary when focused
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Keep the border color the same on hover
            },
            '& .MuiInputBase-input': {
                color: color, // Set input text color to white (#fff)
                cursor: '#fff', // Set cursor color to white (#fff)
            },
            '& .MuiInputBase-input::placeholder': {
                color: color, // Set placeholder color to white (#fff)
            },
        },
        '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
                color: color,
            },
        },
        '& .MuiFormLabel-root': {
            color: color, // Set placeholder color to white (#fff)
        },
        '& .MuiInputLabel-shrink + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main', // Set border color to primary when the label floats up
        },
    }


    return (
        <Modal
            open={true}
            onClose={handleClose}
            closeAfterTransition
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
            className='regularize-modal'
        >
            <Box sx={style}>

                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update {empName}'s Attendance
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label="Time"
                        value={value}
                        onChange={(newValue) => {
                            console.log({ newValue });
                            const { $H: hour, $m: minutes } = { ...newValue };
                            setUpdateTime(`${hour}:${minutes}`);
                            console.log(`${hour}:${minutes}`);
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} sx={commonSX} />}
                        PopperProps={{
                            sx: {
                                '& .MuiPaper-root': {
                                    backgroundColor: themeMode ? '#282a2e' : '#fff',
                                    color: color,
                                    boxShadow: `0px 0px 6px ${shadowColor}`
                                }, '& .MuiSvgIcon-root': {
                                    color: color
                                },
                                '& .MuiTypography-root': {
                                    color: color
                                },
                                '& .MuiClock-squareMask': {
                                    backgroundColor: '#e0e0e0'
                                }
                            }
                        }}
                    />
                </LocalizationProvider>

                <Button
                    sx={{ ...CommonStyles.actionButton }}
                    onClick={updateEmployeeAttendance}
                >{"Update Attendance"}</Button>

            </Box>
        </Modal>
    );
}