
import React, { useState } from 'react';
import { TextField, Select, MenuItem, Switch, RadioGroup, FormControlLabel, Radio, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, ThemeProvider, createTheme, Grid, Typography } from '@mui/material';
import { ThemeColors } from "../../hooks/ThemeColors"
import { POST } from '../../helpers/http';
import { successToast } from '../../helpers/apiToast';
const FormView = (props) => {
    const { formData, finalForm, handleSubmit, errors, disableSubmit } = props
    console.log({ finalForm })
    console.log(formData)


    const theme = createTheme({
        components: {

            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'gray', // outline color on hover
                            borderWidth: '.5px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'gray', // outline color when focused
                            borderWidth: '1px',
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&:hover:not(.Mui-disabled):before': {
                            borderColor: 'gray', // underline color on hover
                            borderWidth: '1px',
                        },
                        '&.Mui-focused:after': {
                            borderColor: 'gray', // underline color when focused
                            borderWidth: '1px',
                        }
                    },
                },
            },
        },
    });

    const [open, setOpen] = useState(false);
    const { commonSX, background_color, color, textColor, borderSX } = ThemeColors();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.handleClose(false)
    };


    const renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <TextField
                        key={field.id}
                        label={field.label}
                        required={field.required}
                        value={field.value}
                        // onChange={(e) => console.log(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                );
            case 'number':
                return (
                    <TextField
                        key={field.id}
                        label={field.label}
                        type="number"
                        required={field.required}
                        value={field.value}
                        sx={commonSX}
                        // onChange={(e) => console.log(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                );
            case 'textarea':
                return (
                    <TextField
                        key={field.id}
                        label={field.label}
                        required={field.required}
                        value={field.value}
                        // onChange={(e) => console.log(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        sx={{
                            ...borderSX,
                            ...commonSX,
                            background: background_color,
                            color: textColor()
                        }}
                    />
                );
            case 'select':
                return (
                    <FormControl key={field.id} fullWidth margin="normal"
                        sx={{
                            ...borderSX,
                            ...commonSX,
                            background: background_color,
                            color: textColor()
                        }}
                    >
                        <InputLabel sx={{
                            color: textColor(),
                            background: background_color
                        }}>{field.label}</InputLabel>
                        <Select
                            value={field.value}
                            sx={{
                                ...borderSX,
                                ...commonSX,
                                background: background_color,
                                color: textColor()
                            }}
                        // onChange={(e) => console.log(e.target.value)}
                        >
                            {field.options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option}
                                    sx={{
                                        ...borderSX,
                                        ...commonSX,
                                        background: background_color,
                                        color: textColor()
                                    }}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'switch':
                return (
                    <div fullWidth
                    >
                        <FormControlLabel
                            key={field.id}
                            control={<Switch checked={Boolean(field.value)} />}
                            label={field.label}
                            sx={{
                                color: textColor()
                            }}
                        />

                    </div>

                );
            case 'radio':
                return (
                    <RadioGroup key={field.id}
                    >
                        {field.options.map((option, index) => (
                            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                        ))}
                    </RadioGroup>
                );
            case 'checkbox':
                return (
                    <div fullWidth
                    // style={{ marginBottom: '0px' }}
                    >
                        <FormControlLabel
                            key={field.id}
                            sx={{
                                color: textColor()
                            }}
                            control={<Checkbox checked={Boolean(field.value)} />}
                            label={field.label}
                        />

                    </div>

                );
            case 'fileUpload':
                return (
                    <div fullWidth
                        style={{ marginBottom: '10px' }}
                    >
                        <Button
                            key={field.id}
                            variant="contained"
                            component="label"
                        // sx={{
                        //     mr: 2,
                        //     backgroundColor: '#dc4d28',
                        //     '&:hover': {
                        //         backgroundColor: '#b33c20', // Darker shade for hover state
                        //     }
                        // }}
                        >
                            {field.label}

                            <input type="file" hidden />
                        </Button>

                    </div>

                );
            case 'date':
                return (
                    <TextField
                        key={field.id}
                        label={field.label}
                        type="date"
                        required={field.required}
                        value={field.value}
                        // onChange={(e) => console.log(e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{
                            ...borderSX,
                            ...commonSX,
                            background: background_color,
                            color: textColor()
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                );
            case 'time':
                return (
                    <TextField
                        key={field.id}
                        label={field.label}
                        type="time"
                        required={field.required}
                        value={field.value}
                        // onChange={(e) => console.log(e.target.value)}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        sx={{
                            ...borderSX,
                            ...commonSX,
                            background: background_color,
                            color: textColor()
                        }}
                    />
                );
            case 'multiSelect':
                return (
                    <FormControl fullWidth size="small"
                        sx={{
                            ...borderSX,
                            ...commonSX,
                            background: background_color,
                            color: textColor()
                        }}
                    >
                        <InputLabel>Select {field.label}</InputLabel>
                        <Select
                            multiple
                            value={[]}
                            required={field.required}
                            label={`Select ${field.label}`}
                            // disabled={true}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {field.options?.map((option, i) => (
                                <MenuItem key={i} value={option}
                                    sx={{
                                        ...borderSX,
                                        ...commonSX,
                                        background: background_color,
                                        color: textColor()
                                    }}
                                >
                                    <Checkbox checked={false} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            default:
                return null;
        }
    };

    return (
        <div >
            <Dialog
                open={props?.open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    ...borderSX,
                    '& .MuiInputBase-input': {
                        color: textColor(), // Text color for the input text
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: textColor(), // Placeholder text color
                        opacity: 1, // Ensure placeholder is visible
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        background: background_color,
                        color: textColor(),

                    }}
                ><Typography variant="h6" component="span" sx={
                    {
                        // padding: "10px"
                    }
                }>
                        {finalForm?.name || '--'}
                    </Typography></DialogTitle>
                {/* <Grid container  >
                    <ThemeProvider theme={theme}>

                        <Grid item md={6}>
                            <TextField
                                required
                                // id="filled-required"
                                helperText={errors?.name || ''}
                                error={errors?.name ?? ''}
                                label="Form Name"
                                onChange={(e) => { handleFormName('name', e.target.value) }}
                                variant="filled"
                                sx={{
                                    mb: 2,
                                    mx: 4,
                                    background: background_color,
                                    color: textColor(),
                                    commonSX

                                }}
                                size="small"
                                value={finalForm?.name || ''}
                            />

                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label="Description"
                                helperText={errors?.description || ''}
                                error={errors?.description ?? ''}
                                // disabled
                                // onChange={(e) => { handleFormName("description", e.target.value) }}
                                size="small"

                                variant='filled'
                                sx={{
                                    mb: 2,
                                    width: "70%",
                                    background: background_color,
                                    commonSX
                                }}
                                value={finalForm?.description}

                            />
                        </Grid>




                    </ThemeProvider>
                </Grid> */}

                <DialogContent

                    sx={{
                        background: background_color,
                        ...borderSX,
                        '& .MuiInputBase-input': {
                            color: textColor(), // Text color for the input text
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: textColor(), // Placeholder text color
                            opacity: 1, // Ensure placeholder is visible
                        },
                    }}
                >
                    <form>
                        {formData ? formData.map((field) => renderField(field)) : (
                            <p>No form data</p>
                        )}


                    </form>
                </DialogContent>
                <DialogActions
                    sx={{
                        background: background_color,
                        ...borderSX,
                        '& .MuiInputBase-input': {
                            color: textColor(), // Text color for the input text
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: textColor(), // Placeholder text color
                            opacity: 1, // Ensure placeholder is visible
                        },
                    }}
                >
                    <Button onClick={handleClose} color="primary"
                        sx={{
                            background: background_color,
                            color: textColor(),
                            ...borderSX,
                            '& .MuiInputBase-input': {
                                color: textColor(), // Text color for the input text
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: textColor(), // Placeholder text color
                                opacity: 1, // Ensure placeholder is visible
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    {/* <Button color="primary"
                        sx={{
                            background: background_color,
                            color: textColor(),
                            ...borderSX,
                            '& .MuiInputBase-input': {
                                color: textColor(), // Text color for the input text
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: textColor(), // Placeholder text color
                                opacity: 1, // Ensure placeholder is visible
                            },
                        }}
                    >
                        Submit
                    </Button> */}
                    <Button
                        variant="contained"
                        disabled={disableSubmit || false}
                        onClick={() => { handleSubmit() }}
                        sx={{
                            mr: 2,
                            backgroundColor: '#dc4d28',
                            '&:hover': {
                                backgroundColor: '#b33c20', // Darker shade for hover state
                            }
                        }}
                    // onClick={handleSubmit}
                    // disabled={formFields.length === 0}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormView;
