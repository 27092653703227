export const breadcrumbValues = {
    "adminSettings": {
        originPath: "/admin/settings",
        heading: "Settings",
        listItems: [
            { paths: ['/admin/settings/departments'], name: "Departments" },
            { paths: ['/admin/settings/categories'], name: "Categories" },
            { paths: ['/admin/settings/groups'], name: "Groups" },
            // { paths: ['/admin/settings/fault-report/maintenance'], name: "Fault Report Maintenance" },
            { paths: ['/admin/settings/notifications', '/admin/settings/notifications/add', '/admin/settings/notifications/edit/'], name: "Notifications" },
            { paths: ['/admin/settings/external-emails'], name: "External Emails" },
            { paths: ['/admin/settings/areas', '/admin/settings/areas/view/'], name: "Areas" },
            { paths: ['/admin/settings/vendor', '/admin/settings/vendor/add', '/admin/settings/vendor/edit/'], name: "Vendor" },
            { paths: ['/admin/settings/vendor-user', '/admin/settings/vendor-user/add', '/admin/settings/vendor-user/edit/'], name: "Vendor User" },
            { paths: ['/admin/settings/contract', '/admin/settings/contract/add', '/admin/settings/vendor-user/edit/'], name: "Contract" },
            { paths: ['/admin/settings/maintainence-mapping', '/admin/settings/maintenance-mapping/add', '/admin/settings/maintenance-mapping/edit/'], name: "Maintenance Mapping" },
            {
                paths: ['/admin/settings/facilities',
                    // 'admin/settings/facilities/edit/'
                ], name: "Facilities"
            },
            {
                paths: [
                    '/admin/settings/hr-docs/list',
                    '/admin/settings/hr-docs/add',
                    '/admin/settings/hr-docs/edit/',
                    '/admin/settings/hr-docs/view/'
                ], name: "Documents And Policies"
            }

        ]
    },
    "wall": {
        originPath: "/admin/wall",
        heading: "Wall",
        listItems: [
            { paths: ['/admin/wall/content-list', '/admin/wall/content-list/view/'], name: "Content Review" },
            // { paths: ['/admin/wall/reports', '/admin/wall/reports/view/'], name: "Reports" },
        ]
    },
    "communities": {
        originPath: "/admin/communities",
        heading: "Communities",
        listItems: [
            { paths: ['/admin/communities', '/admin/communities/view/', '/admin/communities/post-view/'], name: "Review" },
            // { paths: ['/admin/communities/reports', '/admin/communities/reports/view/'], name: "Reports" },
        ]
    },
    "energyConfigurations": {
        originPath: "/admin/energy",
        heading: "Energy Configuration",
        listItems: [
            { paths: ['/admin/energy/sensor-groups'], name: "Sensor Groups" },
            { paths: ['/admin/energy/sensor-catalogs'], name: "Sensor Catalogs" }
        ]
    },
    "attendance": {
        originPath: "/admin/attendance",
        heading: "Attendance",
        listItems: [
            { paths: ['/admin/attendance'], name: "Attendance Report" },
            { paths: ['/admin/attendance/holiday-calendar',], name: "Holiday Calendar" },
        ]
    },
    "dynamic-form": {
        originPath: "/admin/dynamic-form",
        heading: "Form",
        listItems: [
            { paths: ['/admin/dynamic-form'], name: "Create Form" },
            { paths: ['/admin/dynamic-form/list'], name: "Form List" }
        ]
    }
}

export const breadcrumbItems = {
    '/admin/settings': "adminSettings",
    '/admin/settings/departments': "adminSettings",
    '/admin/settings/categories': "adminSettings",
    '/admin/settings/groups': "adminSettings",
    // '/admin/settings/fault-report/maintenance': "adminSettings",
    '/admin/settings/notifications': "adminSettings",
    '/admin/settings/notifications/add': "adminSettings",
    '/admin/settings/notifications/edit/': "adminSettings",
    '/admin/settings/external-emails': "adminSettings",
    '/admin/settings/areas': "adminSettings",
    '/admin/settings/areas/view/': "adminSettings",
    '/admin/settings/vendor': "adminSettings",
    '/admin/settings/vendor/add': "adminSettings",
    '/admin/settings/vendor/edit/': "adminSettings",
    '/admin/settings/vendor-user': "adminSettings",
    '/admin/settings/vendor-user/add': "adminSettings",
    '/admin/settings/vendor-user/edit/': "adminSettings",
    '/admin/settings/contract': "adminSettings",
    '/admin/settings/contract/edit/': "adminSettings",
    '/admin/settings/contract/add': "adminSettings",
    '/admin/settings/maintainence-mapping': "adminSettings",
    '/admin/settings/maintenance-mapping/add': "adminSettings",
    '/admin/settings/maintenance-mapping/edit/': "adminSettings",
    // '/admin/wall/reports': "wall",
    // '/admin/wall/reports/view/': "wall",
    '/admin/wall/content-list': "wall",
    '/admin/wall/content-list/view/': "wall",
    '/admin/communities': "communities",
    '/admin/communities/view/': "communities",
    '/admin/communities/post-view/': "communities",
    '/admin/energy/sensor-groups': "energyConfigurations",
    '/admin/energy/sensor-catalogs': "energyConfigurations",
    '/admin/attendance': "attendance",
    '/admin/attendance/holiday-calendar': "attendance",
    '/admin/dynamic-form': "dynamic-form",
    '/admin/dynamic-form/list': "dynamic-form",
    '/admin/dynamic-form/add': "dynamic-form",
    '/admin/settings/facilities': "adminSettings",
    // 'admin/settings/facilities/edit/': "adminSettings",
    '/admin/settings/hr-docs/list': "adminSettings",
    '/admin/settings/hr-docs/add': "adminSettings",
    '/admin/settings/hr-docs/edit/:id': "adminSettings",
    '/admin/settings/hr-docs/view/': "adminSettings"
}