import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import CustomComponentContainer from '../../../components/container/CustomComponentContainer'
import Loader from '../../../components/loading/Loader'
import NoDataFound from '../../../components/noDataFound/NoDataFound'
import StatsNumberCard from '../components/stats/StatsNumberCard'
import HighChartPPMRaisedMonthwise from '../components/charts/HighChartsPPMRaisedMonthwise'
import HighchartsPPM30DaysTrend from '../components/charts/HighchartsPPM30DaysTrend'
import CustomTypography from '../../../components/texts/CustomTypography'
import HighChartsFaultPieChart from '../components/charts/HighChartsFaultPieChart'
import { useNavigate } from 'react-router-dom'
import useApiCalls from '../components/hooks/useApiCalls'

const PPMStatistics = ({ reload }) => {
    const [statisticsData, setStatisticsData] = useState({ isLoaded: false, data: {} })
    const { getPpmStatistics } = useApiCalls()
    const [chartData, setChartData] = useState({ isLoaded: false, data: {} })
    const [monthChart, setMonthChart] = useState({ isLoaded: false, data: {} })
    const [totalPpm, setTotalPpm] = useState({ isLoaded: false, data: {} })
    console.log("🚀 ~ PPMStatistics ~ totalPpm:", totalPpm)
    const [pieChart1, setPieChart1] = useState({ isLoaded: false, data: [] })
    const [pieChart2, setPieChart2] = useState({ isLoaded: false, data: [] })
    const [pieChart3, setPieChart3] = useState({ isLoaded: false, data: [] })
    const CARD_HEIGHT = "20vh"
    const PIE_CHART_HEIGHT = "35vh"
    const STATS_CARD_VALUE = 2.4
    const navigate = useNavigate()

    const clickableCards = ["totaltasks", "today", "pending", "inprogress", "completed"]

    useEffect(() => {
        getPpmStatistics({ setStatisticsData, setChartData, setTotalPpm, setMonthChart, setPieChart1, setPieChart2, setPieChart3 })
    }, [reload]);


    const handleListingItem = ({ title }) => {
        navigate(`/ppm/list`, { state: { type: title === 'today' ? 'all' : title === 'totaltasks' ? "cumulative" : title } })
    }

    const gridComponents = [
        {
            xs: STATS_CARD_VALUE,
            title: "TOTAL TASKS",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    !totalPpm?.isLoaded ? <Loader height="100%" /> : (Object.keys(totalPpm)?.length > 0 &&
                        totalPpm?.data?.totalPPM >= 0) ?
                        <StatsNumberCard data={totalPpm?.data?.totalPPM} /> : <NoDataFound height="100%" />

                }
            </Box >
        },
        {
            xs: STATS_CARD_VALUE,
            title: "TODAY",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    !statisticsData?.isLoaded ? <Loader height="100%" /> : (Object.keys(statisticsData).length > 0 &&
                        statisticsData?.data?.totalPPMCount >= 0) ?
                        <StatsNumberCard data={statisticsData?.data?.totalPPMCount} />
                        : <NoDataFound height="100%" />
                }
            </Box>,
        },
        {
            xs: STATS_CARD_VALUE,
            title: "PENDING",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    !statisticsData?.isLoaded ? <Loader height="100%" /> : Object.keys(statisticsData).length > 0 &&

                        statisticsData?.data?.pending >= 0 ?
                        <StatsNumberCard data={statisticsData?.data?.pending} />
                        : <NoDataFound height="100%" />

                }
            </Box>
        },
        {
            xs: STATS_CARD_VALUE,
            title: "IN PROGRESS",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    !statisticsData?.isLoaded ? <Loader height="100%" /> : (Object.keys(statisticsData).length > 0 &&
                        statisticsData?.data?.inProgress >= 0) ?
                        <StatsNumberCard data={statisticsData?.data?.inProgress} />
                        : <NoDataFound height="100%" />

                }
            </Box>
        },
        {
            xs: STATS_CARD_VALUE,
            title: "COMPLETED",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    !statisticsData?.isLoaded ? <Loader height="100%" /> : (Object.keys(statisticsData).length > 0 &&

                        statisticsData?.data?.completed >= 0) ?
                        <StatsNumberCard data={statisticsData?.data?.completed} />
                        : <NoDataFound height="100%" />

                }
            </Box>
        },
        {
            xs: 4,
            title: "CATEGORY WISE TASKS",
            component:
                <Box sx={{ height: PIE_CHART_HEIGHT, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        !pieChart1?.isLoaded ? <Loader height="100%" /> : (Array.isArray(pieChart1?.data) &&
                            pieChart1?.data.length !== 0) ? <HighChartsFaultPieChart
                            data={pieChart1?.data}
                        /> : <NoDataFound height="100%" />
                    }

                </Box>
        },
        {
            xs: 4,
            title: "CATEGORY WISE PENDING TASKS",
            component:
                <Box sx={{ height: PIE_CHART_HEIGHT, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        !pieChart2?.isLoaded ? <Loader height="100%" /> : (Array.isArray(pieChart2?.data) &&
                            pieChart2?.data.length !== 0) ? <HighChartsFaultPieChart
                            data={pieChart2?.data}
                        /> : <NoDataFound height="100%" />
                    }


                </Box>
        },
        {
            xs: 4,
            title: "PRIORITY WISE TASKS",
            component:
                <Box sx={{ height: PIE_CHART_HEIGHT, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        !pieChart3?.isLoaded ? <Loader height="100%" /> : (Array.isArray(pieChart3?.data) &&
                            pieChart3?.data.length !== 0) ? <HighChartsFaultPieChart
                            data={pieChart3?.data}
                        /> : <NoDataFound height="100%" />
                    }


                </Box>
        },
        {
            xs: 12,
            title: "30 DAYS TREND OF PPM COMPLETION",
            component: <Box sx={{ height: PIE_CHART_HEIGHT, paddingX: "1rem", paddingTop: "1rem" }}>
                {
                    !chartData?.isLoaded ? <Loader height="100%" /> : (Object.keys(chartData).length > 0 &&
                        chartData?.data?.data?.length > 0) ?
                        <HighchartsPPM30DaysTrend
                            data={chartData?.data}
                        />
                        : <NoDataFound height="100%" />
                }
            </Box>
        },
        {
            xs: 12,
            title: "MONTHLY HISTORICAL COMPLETION TRENDS",
            component: <Box sx={{
                height: PIE_CHART_HEIGHT,
                width: '100%', // Ensure it takes full width of the container
                paddingX: '1rem',
                paddingTop: '1rem',
                display: 'flex',
                justifyContent: 'center', // Center the chart horizontally
                alignItems: 'center', // Center the chart vertically
                // overflow: 'hidden'
            }}
            >
                {

                    !monthChart?.isLoaded ? <Loader height="100%" /> : (Object.keys(monthChart).length > 0 &&
                        monthChart?.data?.data?.length > 0) ?
                        <>
                            <Box sx={{ width: "100%", height: '100%', overflow: "hidden" }}>
                                <HighChartPPMRaisedMonthwise
                                    data={monthChart?.data}
                                />
                            </Box>
                        </>

                        : <NoDataFound height="100%" />
                }
            </Box>
        },
    ]

    return (
        <Box px={2.5} pt={3}>
            <Grid container spacing={3}>
                {gridComponents?.map(({ xs, component, title }, index) => {
                    return <Grid item xs={xs} >
                        <CustomComponentContainer
                            className={clickableCards?.includes(title?.toLowerCase()?.replace(/\s+/g, '')) ? "cursorPtr" : ''}
                            onClick={() => {
                                if (clickableCards?.includes(title?.toLowerCase()?.replace(/\s+/g, ''))) {
                                    handleListingItem({ title: title?.toLowerCase()?.replace(/\s+/g, '') })
                                }
                            }}
                        >
                            <CustomTypography
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontWeight: 700,
                                    paddingY: ".4rem",
                                    borderBottom: "1px solid lightgrey",
                                }}
                            >
                                {title}
                            </CustomTypography >
                            {component}
                        </CustomComponentContainer>
                    </Grid>
                })}
            </Grid>
        </Box>
    )
}

export default PPMStatistics