import { co2 } from "../../../../../../../data/imageUrls";

const Autodesk = window.Autodesk;
const THREE = window.THREE;

export default async function loadHeatMap(
    viewer,
    sensorType,
    setTootltip,
    setSensorHoverDetails,
    getSensorValue, sensorData, setDataViz
) {
    // Given a model loaded from Forge
    const SensorStyleDefinitions = {
        co2: {
            url: co2.src,
            color: 0xffffff,
        },
        temperature: {
            url: co2.src,
            color: 0xffffff,
        },
        default: {
            url: co2.src,
            color: 0xffffff,
        },
    };

    const dataVizExt = await viewer.getExtension('Autodesk.DataVisualization');
    setDataViz(dataVizExt)
    const DATAVIZEXTN = Autodesk.DataVisualization.Core;
    var styleMap = {};

    const levelsExt = await viewer.loadExtension("Autodesk.AEC.LevelsExtension", {
        doNotCreateUI: true,
    });

    const floorData = levelsExt.floorSelector?.floorData?.map((floor) => {//can be optimized using the level info from backend
        return floor.name
    })

    // Create model-to-style map from style definitions.    
    Object.entries(SensorStyleDefinitions).forEach(([type, styleDef]) => {
        styleMap[type] = new DATAVIZEXTN.ViewableStyle(
            DATAVIZEXTN.ViewableType.SPRITE,
            new THREE.Color(styleDef.color),
            styleDef.url
        );
    });

    const viewableData = new DATAVIZEXTN.ViewableData();
    viewableData.spriteSize = 16;
    let startId = 1;

    await dataVizExt.removeAllViewables()
    await dataVizExt.removeSurfaceShading()
    sensorData.forEach((device) => {
        let style = styleMap[device.type] || styleMap['default'];
        const viewable = new DATAVIZEXTN.SpriteViewable(
            device.position,
            style,
            startId
        );
        viewableData.addViewable(viewable);
        startId++;
    });

    await viewableData.finish();
    dataVizExt.addViewables(viewableData);
    const structureInfo1 = new DATAVIZEXTN.ModelStructureInfo(viewer.getAllModels()[1]);
    const heatmapData = await structureInfo1.generateSurfaceShadingData(sensorData);

    await dataVizExt.setupSurfaceShading(viewer.getAllModels()[1], heatmapData);

    dataVizExt.registerSurfaceShadingColors('co2', [0x00ff00, 0xff0000]);
    dataVizExt.registerSurfaceShadingColors('temperature', [0x0000ff, 0x00ff00, 0xffff00, 0xff0000]);

    async function onItemHovering(event) {
        if (event.hovering) {
            setTootltip({
                x: event.originalEvent?.clientX,
                y: event.originalEvent?.clientY,
                display: true,
            })
        }
        else setTootltip({ x: '', y: '', display: false });
        setSensorHoverDetails(sensorData[Number(event.dbId) - 1]);
    }

    viewer.addEventListener(DATAVIZEXTN.MOUSE_HOVERING, onItemHovering)
    dataVizExt.renderSurfaceShading(floorData, sensorType, getSensorValue, 35);
}
