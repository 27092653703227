import React, { useEffect, useState } from 'react';
import { Breadcrumbs, List, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 10,
    },
    listItem: {
        cursor: 'pointer',
    },
    breadcrumb: {
        width: "100%"
    },
}));

const BreadCrumb = ({ keys }) => {
    const { listItems: items, heading } = keys

    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    const location = useLocation()
    const pathname = location?.pathname

    const pathnameToCheck = () => {
        if (pathname?.includes('view') || pathname?.includes('edit')) {
            return pathname.slice(0, -24)
        } else {
            return pathname
        }
    }

    const findCurrentItem = () => {
        const matchingItem = items?.find(item => item?.paths?.includes(pathnameToCheck()))
        if (matchingItem) return matchingItem?.name
        else return ''
    }

    const classes = useStyles();
    const [currentItem, setCurrentItem] = useState(findCurrentItem());
    const navigate = useNavigate()

    useEffect(() => {
        const breadcrumbItem = findCurrentItem()
        if (currentItem !== breadcrumbItem) setCurrentItem(breadcrumbItem)
    }, [pathname])

    const handleListItemClick = (item) => {
        navigate(item?.paths[0])
        setCurrentItem(item?.name);
    };

    return (
        <div className={classes.root} >
            <Typography className={classes.breadcrumb}>
                <Breadcrumbs color={color} sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: '18px' }} >
                        {heading}
                    </Typography>
                    <Typography color="textPrimary" sx={{ fontSize: '18px', fontWeight: 600 }} >{currentItem}</Typography>
                </Breadcrumbs>
            </Typography>

            <List style={{ width: "100%" }}>
                {items.map((item, index) => (
                    <ListItem
                        key={index}
                        className={classes.listItem}
                        onClick={() => handleListItemClick(item)}
                        sx={{ ml: -2, fontSize: '14px', fontWeight: item?.name === currentItem ? "bold" : null }}
                    >
                        {item?.name}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default BreadCrumb;
