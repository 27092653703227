import { CloseOutlined, Search } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, InputAdornment, Link, Modal, Paper, Slide, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetAQISensorsData, resetAssetData } from '../../../../../redux/features/services/forgeSlice/forgeActions'
import HighChartsForgeAQI from './charts/highCharts/HighChartsForgeAQI'
import Loading from '../../../components/loading/Loading'
import { meetingRoomFirstFloorAssets } from './digital/helper/forgeObjects'
import Carousel from '../../../components/carousel/Carousel'
import ReactPlayer from 'react-player'

import { TextField } from '@mui/material'
import swal from 'sweetalert'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { GET, PUT } from '../../../helpers/http'

const SidePanel = (props) => {

    const [openSidePanel, setOpenSidePanel] = useState(props.openSidePanel)

    const [searchTerm, setSearchTerm] = useState('')
    const [assetList, setAssetList] = useState([])

    let selectedAssetData = {}

    const theme = useTheme()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const themeMode = useSelector(state => state.theme.darkTheme)

    const { externalIdAQIData, isLoaded, assetData, cameraFeedUrl } = useSelector(state => state.forge)

    const color = themeMode ? '#fff' : '#000'

    const widgetBgColor = themeMode ? '#282a2e' : '#fff'

    const bgcolor = themeMode ? 'black' : '#ECEFF3'

    const requiredAssetData = {
        "Asset Name": assetData?.assetName || 'NA',
        "Friendly Name": assetData?.friendlyName || 'NA',
        "Asset Brand": assetData?.brand || 'NA',
        "Asset Description": assetData?.description || 'NA',
        "Asset Cost": `${assetData?.currency?.symbol || ""} ${assetData?.cost || "NA"}`,
        "Asset Model": assetData?.model || 'NA',
        "Serial Number": assetData?.serialNumber || 'NA',
        "Status": assetData?.status || 'NA',
        "Purchase Date": assetData?.purchaseDate || 'NA',
        "Vendor Name": assetData?.vendorName || 'NA',
        "Building": assetData?.building || 'NA',
        "Floor": assetData?.floor || 'NA',
        "Area": assetData?.area || 'NA',
        "Category": assetData?.category || 'NA',
        "Sub-Category": assetData?.subCategory || 'NA',
    }

    const aqiDataRange = {
        airPressure: {
            good: {
                start: 700,
                end: 1013,
                color: '#ffa500'
            },
            better: {
                start: 1013,
                end: 1015,
                color: '#A0E400'
            },
            bad: {
                start: 1015,
                end: 2000,
                color: '#ff0000'
            }
        },
        co2: {
            good: {
                start: 0,
                end: 800,
                color: '#A0E400'
            },
            better: {
                start: 800,
                end: 1200,
                color: '#ffa500'
            },
            bad: {
                start: 1200,
                end: 4000,
                color: '#ff0000'
            }
        },
        humidity: {
            good: {
                start: 0,
                end: 40,
                color: '#ffa500'
            },
            better: {
                start: 40,
                end: 60,
                color: '#A0E400'
            },
            bad: {
                start: 60,
                end: 100,
                color: '#ff0000'
            }
        },
        illuminance: {
            good: {
                start: 0,
                end: 300,
                color: '#ffa500'
            },
            better: {
                start: 300,
                end: 500,
                color: '#A0E400'
            },
            bad: {
                start: 500,
                end: 1100,
                color: '#ff0000'
            }
        },
        radon: {
            good: {
                start: 0,
                end: 100,
                color: '#ffa500'
            },
            better: {
                start: 100,
                end: 150,
                color: '#A0E400'
            },
            bad: {
                start: 150,
                end: 400,
                color: '#ff0000'
            }
        },
        temperature: {
            good: {
                start: 0,
                end: 25,
                color: '#A0E400'
            },
            better: {
                start: 25,
                end: 29,
                color: '#ffa500'
            },
            bad: {
                start: 29,
                end: 40,
                color: '#ff0000'
            }
        },
        tvoc: {
            good: {
                start: 0,
                end: 112,
                color: '#A0E400'
            },
            better: {
                start: 112,
                end: 336,
                color: '#ffa500'
            },
            bad: {
                start: 336,
                end: 1000,
                color: '#ff0000'
            }
        }
    }

    const aqiChartData = externalIdAQIData?.map(data => {
        if (data !== null) {
            if (data?.data) {
                data.name = data.data
                props?.forgeExternalIdData?.forEach(ele => {
                    const selectedAssetExternalId = ele.externalId
                    const selectedAssetForgeData = meetingRoomFirstFloorAssets.find(asset => asset["External-ID"] === selectedAssetExternalId)
                    selectedAssetData = selectedAssetForgeData
                })
            }
            if (data?.index === 'air_pressure') {
                data.name = 'Air Pressure'
                data.units = data.units || "hPa"
                data.range = aqiDataRange.airPressure
            }
            if (data?.index === 'co2') {
                data.name = 'CO2'
                data.units = data.units || "ppm"
                data.range = aqiDataRange.co2
            }
            if (data?.index === "humidity") {
                data.name = 'Humidity'
                data.units = data.units || "%"
                data.range = aqiDataRange.humidity
            }
            if (data?.index === "illuminance") {
                data.name = 'Illuminance'
                data.units = data.units || "lux"
                data.range = aqiDataRange.illuminance
            }
            if (data?.index === 'radon') {
                data.name = 'Radon'
                data.units = data.units || "Bq/m\u00B3"
                data.range = aqiDataRange.radon
            }
            if (data?.index === "temperature") {
                data.name = 'Temperature'
                data.units = data.units || "°C"
                data.range = aqiDataRange.temperature
            }
            if (data?.index === "voc") {
                data.name = 'Tvoc'
                data.units = data.units || 'ppb'
                data.range = aqiDataRange.tvoc
            }
            return data
        }
        else if (data === null) return null
    })

    const style = {
        position: 'absolute',
        top: '0%',
        right: '0%',
        height: '100%',
        width: 400,
        bgcolor,
        boxShadow: 10,
        pl: 2,
        pt: 2,
        overflow: 'auto'
    }

    const handleCloseSidePanel = () => {
        setAssetList([])
        setSearchTerm('')
        setOpenSidePanel(false)
        props.handleCloseSidePanel(openSidePanel)
        dispatch(resetAQISensorsData())
        // dispatch(resetAssetData())
    }

    useEffect(() => {
        setOpenSidePanel(props.openSidePanel);
    }, [props.openSidePanel]);

    useEffect(() => {
        if (searchTerm?.length >= 3) {
            GET(process.env.REACT_APP_BASE_URL + `am/v1/assets/forge?friendly-name=${searchTerm}&bid=${localStorage.getItem('bid')}`, "Forge Friendly name")
                .then((response) => {
                    const result = response.data
                    if (response.status === 200) {
                        setAssetList(result)
                    } else {
                        setAssetList([])
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                })
        } else {
            setAssetList([])
        }
    }, [searchTerm])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleListItemClick = (asset) => {
        swal({
            title: "Are you sure?",
            text: "You are about to tag external Id of the selected Asset to this Asset.",
            icon: "info",
            buttons: true,
            dangerMode: true,
        })
            .then((confirm) => {
                if (confirm) {
                    PUT(process.env.REACT_APP_BASE_URL + `am/v1/asset/${asset._id}`, { data: { externalId: externalIdAQIData[0]?.externalId } }, "Edit assent in forge")
                        .then((response) => {
                            if (response.status === 200) {
                                swal("Success!", "Asset Details updated", "success");
                                handleCloseSidePanel()

                            } else {
                                swal("Warning!", response?.data?.message || '', "warning")
                            }
                        }).catch(e => {
                            console.log("Error while Updating the Asset", e.toString());
                        })
                }
            });
    }

    const handleExternalIdRemove = () => {
        swal({
            title: "Are you sure?",
            text: "You are about remove the External Id of the selected Asset.",
            icon: "info",
            buttons: true,
            dangerMode: true,
        })
            .then((confirm) => {
                if (confirm) {
                    PUT(process.env.REACT_APP_BASE_URL + `am/v1/asset/${assetData._id}`, { data: { externalId: '' } }, "Remove asset in forge")
                        .then((response) => {
                            if (response.status === 200) {
                                swal("Success!", "Asset Details updated", "success");
                                handleCloseSidePanel()

                            } else {
                                swal("Warning!", response?.data?.message || '', "warning")
                            }
                        }).catch(e => {
                            console.log("Error while Updating the Asset", e.toString());
                        })
                }
            });
    }

    return (
        <div >
            <Modal
                open={openSidePanel}
                onClose={handleCloseSidePanel}
            >
                <Slide direction="left" in={openSidePanel}>
                    <Box sx={style}>
                        {isLoaded ?
                            cameraFeedUrl ? <Box mr={1}>
                                <Typography fontWeight={'bold'} mb={1}>{cameraFeedUrl.includes('ground') ? 'GROUND FLOOR CAMERA' : cameraFeedUrl.includes('terrace') ? 'TERRACE FLOOR CAMERA' : ''}</Typography>
                                <ReactPlayer url={cameraFeedUrl}
                                    width="100%"
                                    height="100%"
                                    muted={true}
                                    playing={true}
                                    playbackRate={1}
                                    loop={false}
                                    controls={false}
                                />
                            </Box> :
                                <Box>
                                    {
                                        externalIdAQIData.length === 1 && externalIdAQIData[0].data === 'No Data Found' ? (
                                            <>
                                                <IconButton sx={{ mr: 1, float: 'right' }} onClick={handleCloseSidePanel}>
                                                    <CloseOutlined sx={{ color }} />
                                                </IconButton>
                                                <Typography fontWeight={'bold'}>No Asset Tagged with this External Id</Typography>
                                                <TextField
                                                    required
                                                    fullWidth={true}
                                                    placeholder="Search Assets by their friendly name..."
                                                    value={searchTerm}
                                                    variant="filled"
                                                    onChange={handleSearchChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment>
                                                                <IconButton>
                                                                    <Search />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    sx={{ maxWidth: '100%', mt: 2, pr: 1 }}
                                                />

                                                {assetList?.length > 0 &&
                                                    <Box
                                                        sx={{ maxHeight: '30vh', overflow: 'auto', maxWidth: '100%', mr: 1, bgcolor: 'background.paper', borderRadius: '6px', mt: 1 }}
                                                    >
                                                        <List>
                                                            {assetList?.map((asset, i) => {
                                                                return <ListItem
                                                                    onClick={() => { handleListItemClick(asset) }}
                                                                    key={i}
                                                                    disablePadding
                                                                >
                                                                    <ListItemButton>
                                                                        <ListItemText
                                                                            primary={`${asset?.friendlyName} - ${asset.floor?.name ? asset.floor?.name : '{Floor Name}'} - ${asset.area?.name ? asset.area?.name : '{Area Name}'}`}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            })}
                                                        </List>
                                                    </Box>}
                                            </>
                                        ) : (
                                            <>
                                                <Box display={'flex'} justifyContent='space-between'>
                                                    <Typography variant="h6" component="h2">
                                                        {
                                                            (selectedAssetData && Object.keys(selectedAssetData).length > 0) ? selectedAssetData?.Name :
                                                                Object.keys(assetData).length ? 'Asset Details' :
                                                                    (externalIdAQIData.length === 1 && externalIdAQIData[0].data === 'No Data Found') ? '' : 'Air Quality Index'
                                                        }
                                                    </Typography>
                                                    <IconButton sx={{ mt: -1 }} onClick={handleCloseSidePanel}>
                                                        <CloseOutlined sx={{ color }} />
                                                    </IconButton>
                                                </Box>
                                                <Grid container spacing={2} mt={1}>
                                                    {aqiChartData?.map((param, i) => (
                                                        param?.value >= 0 ?
                                                            <Grid item xs={12} key={i}>
                                                                <Paper elevation={0} sx={{ bgcolor: widgetBgColor, mr: 2, height: 210 }}>
                                                                    <Typography m={1} pt={1} fontWeight={500}>{param?.name} {param?.units && `(${param?.units})`}</Typography>
                                                                    {param?.value >= 0 && <HighChartsForgeAQI range={param?.range} value={isNaN(Number(param?.value)) ? 0 : param?.value} />}
                                                                </Paper>
                                                            </Grid> :
                                                            (selectedAssetData && Object.keys(selectedAssetData).length > 0) ?
                                                                <Grid item xs={12} key={i}>
                                                                    {Object.keys(selectedAssetData).map((key, i) => (
                                                                        key !== "External-ID" && <Typography key={i} mb={2}>{`${key} : ${selectedAssetData[key]}`}</Typography>
                                                                    ))}
                                                                </Grid> :
                                                                <Typography key={i} ml={2} width='100%' align='center' fontWeight={'bold'}>No asset data found</Typography>
                                                    ))}
                                                    {
                                                        Object.keys(assetData).length ?
                                                            <Box width={'100%'}>
                                                                {assetData && <Box mx={1}><Carousel id={assetData._id} /></Box>}
                                                                {Object.keys(requiredAssetData).map((ele, i) => (
                                                                    <Box width={'100%'} ml={2} key={i}>
                                                                        <Typography key={i} mb={2} textTransform='capitalize'>{`${ele} : ${requiredAssetData[ele]}`}</Typography>
                                                                    </Box>
                                                                ))}
                                                                <Box display={'flex'} justifyContent='center' alignItems={'center'}>
                                                                    <Button onClick={handleExternalIdRemove}>Remove External Id</Button>
                                                                    <Button><Link href={`/assetmanagement/assetview/${assetData?._id}`} underline="none" target="_blank" rel="noopener">View more</Link></Button>
                                                                    <Button><Link href={`/assetmanagement/assetedit/${assetData?._id}`} underline="none" target="_blank" rel="noopener">Edit Asset</Link></Button>
                                                                </Box>
                                                            </Box> : null
                                                    }
                                                </Grid>
                                            </>

                                        )
                                    }

                                </Box> :
                            <Box height={'100%'} display='flex' justifyContent={'center'} alignItems='center'>
                                <Loading />
                            </Box>
                        }
                    </Box>
                </Slide>
            </Modal>
        </div>
    )
}

export default SidePanel 