import { FormHelperText } from '@mui/material';
import React, { memo } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ThemeColors } from '../../../hooks/ThemeColors';

const DropDownPhoneInput = ({ phone, handlePhoneInputs, formErrors, name, inputStyle, isValid }) => {
    const { background_color, textColor } = ThemeColors();
    const handleChange = (value, data) => {
        const countryCallingCode = data.dialCode;
        const result = `+${countryCallingCode} ${value.slice(countryCallingCode?.length)}`
        handlePhoneInputs({ name, result: result });
    };

    return (
        <>
            <PhoneInput
                id={name}
                isValid={isValid}
                country={'in'}
                value={phone}
                // onChange={handlePhoneInputs}
                onChange={handleChange}
                inputStyle={{ width: '100%', height: '40px', backgroundColor: background_color(), color: textColor(), ...inputStyle }}
                buttonStyle={{ backgroundColor: 'lightgrey' }}
                dropdownStyle={{ maxWidth: '300px', backgroundColor: background_color() }}
                autoFormat={true}
                countryCodeEditable={false}
            />
            {formErrors && <FormHelperText style={{ color: '#d32f2f' }}>{formErrors}</FormHelperText>}
        </>
    )
}

export default memo(DropDownPhoneInput)