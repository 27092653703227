import React, { useContext, useState } from 'react';
import { ListItemButton, ListItem, ListItemText, IconButton, Box, styled, useTheme, Drawer, CssBaseline, List, Divider, Typography, AppBar, Avatar } from '@mui/material';
import { Menu, LightModeOutlined, DarkModeOutlined, Logout, SwapHorizRounded, QueryStatsOutlined } from '@mui/icons-material';
import { ChatContext } from '../../context/ChatContext';
import ImageRenderer from '../../../../components/image/ImageRenderer';
import { isToday, isYesterday } from 'date-fns';
import { edit_dark } from '../../../../../../data/imageUrls';
import { useSelector } from 'react-redux';
import MUIAvatarMenu from '../../../../components/Menu/MUIAvatarMenu';
import useHandleLogout from '../../../../hooks/useHandleLogout';
import CustomIconButton from '../CustomIconButton';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function GenerativeChatMenu({ children, setMenuOpen }) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
        Boolean(setMenuOpen) && setMenuOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        Boolean(setMenuOpen) && setMenuOpen(false);
    };

    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { chatHistory } = chatInfo;

    // Helper functions to categorize chats
    const categorizeChats = () => {
        const todayChats = [];
        const yesterdayChats = [];
        const pastChats = [];

        chatHistory.forEach(chat => {
            const chatDate = new Date(chat.created_at);
            if (isToday(chatDate)) {
                todayChats.push(chat);
            } else if (isYesterday(chatDate)) {
                yesterdayChats.push(chat);
            } else {
                pastChats.push(chat);
            }
        });

        return { todayChats, yesterdayChats, pastChats };
    };

    const { todayChats, yesterdayChats, pastChats } = categorizeChats();

    const handleExsistingChat = (id) => {
        if (chatHistory?.length > 0) {
            const currentConversation = chatHistory?.find(el => el?._id === id);
            if (currentConversation) {
                chatDispatch({ type: 'SET_INTERFACE_CHAT', payload: { ...currentConversation } });
                chatDispatch({ type: 'SET_NEW_CHAT_ID', payload: undefined });
                setSelectedChat(id);
            }
        }
    };

    const handleNewChat = async () => {
        chatDispatch({ type: 'SET_INTERFACE_CHAT', payload: {} });
        setSelectedChat(null);
    };

    const renderChatList = (chats) => (
        chats.map(({ _id, conversation }) => (
            conversation[0]?.content && (
                <ListItem key={_id} disablePadding>
                    <ListItemButton
                        disablePadding
                        onClick={() => { handleExsistingChat(_id); }}
                        sx={{
                            backgroundColor: selectedChat === _id ? theme.palette.action.selected : 'inherit'
                        }}
                    >
                        <ListItemText primary={conversation[0]?.content} />
                    </ListItemButton>
                </ListItem>
            )
        ))
    );

    const userName = useSelector(state => state.user.fullName);

    // Avatar Menu
    const { handleLogout } = useHandleLogout();
    const [avatarModalState, setAvatarModalState] = useState(false);
    const handleAvatarClick = (event) => {
        setAvatarModalState(true);
    };
    const handleAvatarClose = () => {
        setAvatarModalState(false);
    };
    const handleToggleTheme = () => {
        chatDispatch({ type: 'TOGGLE_THEME' });
    }
    const [mode, setMode] = useState(false)
    const menuItems = [
        { label: 'Change Theme', handleMenuItemClick: handleToggleTheme, icon: true ? LightModeOutlined : DarkModeOutlined },
        { label: 'Switch Building', handleMenuItemClick: '', icon: SwapHorizRounded },
        { label: 'Logout', handleMenuItemClick: handleLogout, icon: Logout },
    ]
    const handleThemeToggle = () => {
        setMode(!mode)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                sx={{ backgroundColor: 'transparent', maxHeight: '4rem', padding: '1rem' }}
            >
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative', // Establishes a positioning context
                            transition: 'margin-left 0.3s ease' // Smooth transition for margin change
                        }}
                    >
                        <IconButton
                            color="default" // Correct color property
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ padding: '8px 0 8px 8px' }}
                        >
                            <Menu />
                        </IconButton>
                        <ImageRenderer onClick={handleNewChat} src={edit_dark.src} alt={edit_dark.alt} sx={{ ml: '1rem', cursor: 'pointer', width: '20px' }} />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ml: open ? '15rem' : '0', // Apply margin-left conditionally
                                transition: 'margin-left 0.3s ease', // Smooth transition
                            }}
                        >
                            <ImageRenderer src={'https://twin-development.s3.ap-south-1.amazonaws.com/$2b$10$bvT6kFdZaGgoEGtihII.R.__nhance_logo.png'} alt='logo' sx={{ ml: '1rem', height: '2rem', width: '100%' }} />
                            {/* <CustomTypography>.AI</CustomTypography> */}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                        <CustomIconButton
                            sx={{ border: '1px solid #b5b5b5', padding: '0.45rem', backgroundColor: '#b5b5b5' }}
                        >
                            <QueryStatsOutlined sx={{ color: '#fff' }} />
                        </CustomIconButton>
                        <CustomIconButton onClick={handleThemeToggle} sx={{ border: '1px solid', padding: '0.45rem' }}>
                            {!mode ? <LightModeOutlined /> : <DarkModeOutlined />}
                        </CustomIconButton>
                        <Avatar
                            sx={{ cursor: 'pointer' }}
                            id="Avatar-Menu"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleAvatarClick}>
                            {`${userName[0]}${userName.includes(' ') ? userName?.split(' ')[1][0] : ''}`}
                        </Avatar>
                        <MUIAvatarMenu
                            handleClose={handleAvatarClose}
                            modalState={avatarModalState}
                            menuItems={menuItems}
                        />
                    </Box>

                </Box>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '18rem',
                        boxSizing: 'border-box',
                        backgroundColor: '#f5f3f4',
                        border: 'none',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <Menu /> : <Menu />}
                    </IconButton>
                    <IconButton onClick={handleNewChat} disableRipple>
                        <ImageRenderer src={edit_dark.src} alt={edit_dark.alt} sx={{ ml: '1rem' }} />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List sx={{ pl: '.2rem' }}>
                    {/* Today */}
                    {todayChats.length > 0 && (
                        <>
                            <Typography variant="subtitle1" sx={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>Today</Typography>
                            {renderChatList(todayChats)}
                        </>
                    )}

                    {/* Yesterday */}
                    {yesterdayChats.length > 0 && (
                        <>
                            <Typography variant="subtitle1" sx={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>Yesterday</Typography>
                            {renderChatList(yesterdayChats)}
                        </>
                    )}

                    {/* Past */}
                    {pastChats.length > 0 && (
                        <>
                            <Typography variant="subtitle1" sx={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>Past</Typography>
                            {renderChatList(pastChats)}
                        </>
                    )}
                </List>
            </Drawer>
            <Main open={open} sx={{ padding: 0, width: 'fit-content', backgroundColor: '#fff' }}>
                {children}
            </Main>
        </Box >
    );
}
