import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeColors } from "../../hooks/ThemeColors";
import { FormHelperText } from "@mui/material";
export default function LimitTags({ label, options, setSelectedOptions, selectedOptions, error, defaultValue }) {

    const handleChange = (event, newValue) => {
        setSelectedOptions(newValue);
    };

    useEffect(() => {
        if (defaultValue) {
            const setValue = options?.filter((el, index) => el?._id === defaultValue[index]?._id)
            setSelectedOptions(setValue);
        }
    }, [defaultValue])

    const { commonSX, textColor } = ThemeColors()

    return (
        <Autocomplete
            filterSelectedOptions
            multiple
            fullWidth
            size="small"
            limitTags={2}
            options={options}
            getOptionLabel={(option) => option?.name}
            value={selectedOptions}
            onChange={handleChange}
            sx={{
                ...commonSX,
                '& .MuiSvgIcon-root': {
                    color: textColor()
                },
                '& .MuiInputLabel-root': {
                    color: textColor()
                },
                '& .MuiAutocomplete-listbox': {
                    backgroundColor: 'black',
                    color: 'white'
                },
                '& .MuiAutocomplete-option': {
                    color: 'white'
                }
            }}
            renderInput={(params) => (
                <>
                    <TextField {...params} error={!!error} label={label} placeholder={label} />
                    {!!error && <FormHelperText sx={{ color: '#d32f2f !important', ml: 2 }}>{error}</FormHelperText>}
                </>
            )}
        />
    );
}