import React from 'react'
import { TextField } from '@mui/material'
import { ThemeColors } from '../../hooks/ThemeColors'
import { useSelector } from 'react-redux'

const FormInput = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const { borderSX } = ThemeColors()
    const {
        label, variant, size, multiline, placeholder, name, rows, disabled, value, type, onChange, onKeyDown, id, inputProps,
        helperText, error, InputProps, fullWidth, select, SelectProps, children, sx, focused
    } = props

    return (
        <TextField
            autoComplete='off'
            id={id}
            label={label}
            size={size}
            variant={variant}
            value={value}
            multiline={!!multiline}
            rows={rows}
            placeholder={placeholder}
            name={name}
            type={type}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputProps={inputProps}
            InputProps={InputProps}
            fullWidth={!!fullWidth}
            sx={{ ...borderSX, ...sx }}
            disabled={!!disabled}
            focused={!!focused || !!value || type === 'date' || type === 'time'}
            error={!!error}
            helperText={helperText}
            select={!!select}
            SelectProps={{
                MenuProps: {
                    className: themeMode ? 'menu-container-dark' : 'menu-container-light',
                    disableScrollLock: true,
                    PaperProps: { sx: { maxHeight: 250 } },
                },
                ...SelectProps,
            }}
            FormHelperTextProps={{ sx: { fontSize: '12px' } }}
        >
            {children}
        </TextField>
    )
}

export default FormInput;