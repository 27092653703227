import { poweredByNhance } from "../../../../../../../data/imageUrls";

const Autodesk = window.Autodesk;

function loadSecondModel(urn, roomsDbId) {
    window.viewer2 = window.factory.createViewer(document.getElementById('viewer2'), {}, Autodesk.Viewing.Private.GuiViewer3D)
    window.viewer2.start()
    window.viewer2.setTheme('dark-theme');
    const spinner = document.getElementById('viewer2').getElementsByClassName('adsk-viewing-viewer')[0].childNodes[1]
    if (spinner) {
        const image = spinner?.querySelector('img')
        image.src = poweredByNhance.src
    }

    return new Promise(function (resolve, reject) {
        function onDocumentLoadSuccess(doc) {
            Autodesk.Viewing.Document.getAecModelData(doc.getRoot()).then(aec => console.log('AEC metadata', aec));
            // viewer.loadExtension('Autodesk.DocumentBrowser');
            // viewer.loadExtension('Autodesk.AEC.LevelsExtension')
            resolve(window.viewer2.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry(true, 1)).then((model) => {

                window.viewer2.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async function () {
                    await window.viewer2.hide(roomsDbId, window.viewer2.model)
                    await window.viewer2.unloadExtension('Autodesk.Section');
                    await window.viewer2.unloadExtension('Autodesk.Measure');
                    await window.viewer2.unloadExtension('Autodesk.Explode');
                    await window.viewer2.unloadExtension('Autodesk.ViewerSettings');
                    window.viewer2.impl.invalidate(true, true, true);
                })
            }));
        }
        function onDocumentLoadFailure(code, message) {
            console.error('Could not load document.', message);
            reject(message);
        }
        Autodesk.Viewing.Document.load('urn:' + urn, onDocumentLoadSuccess, onDocumentLoadFailure);
    });
}

export default loadSecondModel