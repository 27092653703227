import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import { errorHandler } from "../../components/facilityManager/helpers/http";

const fetchCurrencySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCurrency: builder.query({
            query: (bid) => ({
                url: `am/v1/currency?bid=${bid}`,
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Currency')
            },
        })
    })
})

export const { useGetCurrencyQuery } = fetchCurrencySlice

const initialState = {
    isLoaded: false,
    name: '',
    acronym: '',
    symbol: ''
};

const currencySlice = createSlice({
    name: "currency",
    initialState,
    reducers: {
        resetCurrency: (state, action) => {
            state.isLoaded = false
            state.name = ''
            state.acronym = ''
            state.symbol = ''
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'getCurrency' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.isLoaded = true
                state.id = action.payload?._id
                state.name = action.payload?.name
                state.acronym = action.payload?.acronym
                state.symbol = action.payload?.symbol
            }
        );
    }
})

export const { resetCurrency } = currencySlice.actions

export default currencySlice.reducer