import React, { memo } from 'react';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';

const MUIAvatarMenu = ({ modalState, handleClose, menuItems }) => {
    const open = Boolean(modalState);
    const handleItemClick = ({ handleMenuItemClick }) => {
        Boolean(handleMenuItemClick) && handleMenuItemClick();
        handleClose();
    }

    return (
        <Menu
            disableScrollLock
            anchorEl={modalState}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    top: '3.2rem !important',
                    left: '1720px !important',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {menuItems?.map(({ label, icon: Icon, handleMenuItemClick }, index) => (
                <MenuItem key={index} onClick={(e) => { handleItemClick({ handleMenuItemClick }) }}>
                    <ListItemIcon>
                        <Icon fontSize="small" />
                    </ListItemIcon>
                    {label}
                </MenuItem>
            ))
            }
        </Menu >
    );
}
export default memo(MUIAvatarMenu);