import { apiSlice } from "../api/apiSlice";
import { GET_CSRF, errorHandler } from "../../components/facilityManager/helpers/http";

const fetchPasswordSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        resetPassword: builder.mutation({
            query: (newPassword) => ({
                url: `um/v1/user/password`,
                method: 'PUT',
                body: { newPassword },
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Reset Password')
            },
        })
    })
})

export const { useResetPasswordMutation } = fetchPasswordSlice;