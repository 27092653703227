import React, { memo } from 'react'
import CustomTypography from '../../../../components/texts/CustomTypography'
import { Box } from '@mui/material'

const Question = ({ question }) => {
    return (
        <Box className='question' sx={{
            width: 'fit-content', maxWidth: '70%', backgroundColor: '#f5f3f4', padding: '0.5rem 1.5rem',
            mb: '1rem', borderRadius: '2rem', float: 'right'
        }}>
            <CustomTypography sx={{ fontSize: '18px', lineHeight: 1.5, color: '#606060 !important' }}>{question}</CustomTypography>
        </Box>
    )
}

export default memo(Question)