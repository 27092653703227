import { useEffect, useReducer } from "react";
import PPMDatatable from "./PPMDatatable"
import { PPMListReducer } from "./context/PPMListReducer";
import { fetchPPMList } from "./context/PPMListContextProvider";
import { Box } from "@mui/material";

const PPMListPage = ({ triggerList }) => {
    const [PPMList, dispatch] = useReducer(PPMListReducer, { isLoaded: false, data: [] });

    useEffect(() => {
        fetchPPMList(dispatch)
    }, [])

    return (
        <Box px={2.5} pt={2}>
            <PPMDatatable
                PPMList={PPMList}
                triggerList={triggerList} />,
        </Box>
    )
}

export default PPMListPage