import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET, errorHandler } from '../../../components/facilityManager/helpers/http'
import { apiSlice } from '../../api/apiSlice'

const initialState = {
    isLoaded: false,
    error: "",
    sdkKey: "",
    modelId: "",
    sensorMappingWithMattertags: {},
    cameraMappingWithMattertags: {},
}

const fetchMatterportSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchMatterportInitalConfig: builder.query({
            query: (bid) => ({
                url: `twin/v1/matterport/s2/config?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Matterport config data')
            },
        })
    })
})

export const { useFetchMatterportInitalConfigQuery } = fetchMatterportSlice


const matterportSlice = createSlice({
    name: "matterportSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchMatterportInitalConfig' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.isLoaded = true
                state.sensorMappingWithMattertags = action.payload?.sensorMappingWithTags
                state.cameraMappingWithMattertags = action.payload?.cameraMappingWithTags
                state.modelId = action.payload?.modelId
                state.sdkKey = action.payload?.sdkKey
            }
        )
    }
})

export default matterportSlice.reducer