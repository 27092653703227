import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET, POST } from '../../../components/facilityManager/helpers/http'
import { validPositiveData } from '../../../components/facilityManager/helpers/validData'

const initialState = {
    dashboard: {
        dataIsLoaded: false,
        data: {},
        error: "",
        statsIsLoaded: false,
        stats: {}
    },
    secondary: {
        floors: {
            isLoaded: false,
            error: "",
            floors: [],
            areas: [],
            selectedFloorAreas: [],
            selectedFloor: "",
        },
        latest: {
            isLoaded: false
        },
        totalCounts: {
            isLoaded: false
        },
        durations: {
            isLoaded: false,
            error: "",
            list: [],
            selected: 'today'
        },
        todaysUtilization: {
            isLoaded: false
        },
        weeksUtilization: {
            isLoaded: false
        },
        bookings: {
            isLoaded: false,
            chartData: [],
            stats: {}
        },
        margins: [15, 0, 25, 20],
        lineWidth: 2
    }
}

export const asyncFetchMeetingRoomData = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchMeetingRoomData', async ({ buildingId }) => {
    try {
        const response = await POST(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s1/occupancy/latest?buildingId=${buildingId}`, {}, 'Meeting Rooms Occupancy')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchMeetingRoomStats = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchMeetingRoomStats', async () => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/occupancy/s1/pg/occupancy`, 'Meeting Rooms Occupancy Stats')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchMeetingRoomSecondaryOptions = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchMeetingRoomSecondaryOptions', async (buildingId) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s2/options?buildingId=${buildingId}`, 'Meeting Room Secondary Options')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchUtilisationData = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchUtilisationData', async ({ buildingId, areas }) => {
    try {
        if (Object.values(areas)[0].length > 0) {
            const response = await POST(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s2/stats?buildingId=${buildingId}&range=today`, areas, 'Meeting Room Secondary Today Utilisation')
            if (response.status === 200) {
                return response?.data
            }
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchUtilisationWeekData = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchUtilisationWeekData', async ({ buildingId, areas }) => {
    try {
        if (Object.values(areas)[0].length > 0) {
            const response = await POST(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s2/stats?buildingId=${buildingId}&range=week`, areas, 'Meeting Room Secondary Week Utilisation')
            if (response.status === 200) {
                return response?.data
            }
        }
    } catch (error) {
        console.log(error)
    }
})

export const fetchTotalPeopleOccupied = createAsyncThunk('mrOccupancySliceFidelity/fetchTotalPeopleOccupied', async ({ buildingId }) => {
    try {
        const response = await POST(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s1/occupancy/latest?buildingId=${buildingId}`, {}, 'Meeting Rooms Occupancy')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

export const asyncFetchFloorwiseChartData = createAsyncThunk('mrOccupancySliceFidelity/asyncFetchFloorwiseChartData', async ({ areas, buildingId, duration }, thunkAPI) => {
    try {
        for (const area of areas) {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/meetingrooms/s2/data/${area.area}?buildingId=${buildingId}&duration=${duration}`, 'Meeting Room Secondary Occupancy ChartData')
            if (response.status === 200) {
                thunkAPI.dispatch(fetchFloorwiseChartData(response?.data))
            }
        }
    } catch (error) {
        console.log(error)
    }
})

const mrOccupancySliceFidelity = createSlice({
    name: 'mrOccupancySliceFidelity',
    initialState,
    reducers: {
        resetMeetingRoomsSecondaryData: (state, action) => {
            state.secondary = initialState?.secondary
        },
        updateMeetingRoomSecondaryDuration: (state, action) => {
            state.secondary = {
                ...state?.secondary,
                durations: {
                    ...state.secondary?.durations,
                    isLoaded: true,
                    selected: action.payload
                }
            }
        },
        updateMeetingRoomSecondarySelectedFloor: (state, action) => {
            const areas = JSON.parse(JSON.stringify(state.secondary?.floors?.areas))
            const selectedFloorAreas = action.payload === 'All Floors' ? areas : areas.filter(ele => ele.floor === action.payload)
            const updatedAreas = selectedFloorAreas?.map(e => ({
                ...e, data: []
            }))

            state.secondary = {
                ...state.secondary,
                floors: {
                    ...state.secondary?.floors,
                    isLoaded: true,
                    selectedFloor: action.payload,
                    selectedFloorAreas: updatedAreas
                }
            }
        },
        fetchFloorwiseChartData: (state, action) => {
            const areas = state.secondary?.floors?.selectedFloorAreas?.map(ele => {
                if (ele.area === action.payload?.area) {
                    return {
                        ...ele,
                        data: action.payload?.data,
                        dataIsLoaded: true
                    }
                }
                else return ele
            })

            state.secondary = {
                ...state.secondary,
                floors: { ...state.secondary?.floors, isLoaded: true, selectedFloorAreas: areas }
            }
        },
        resetAreasChartData: (state, action) => {
            const areas = state.secondary?.floors?.selectedFloorAreas?.map(ele => {
                return {
                    ...ele,
                    data: [],
                    dataIsLoaded: false
                }

            })

            state.secondary = {
                ...state.secondary,
                floors: { ...state.secondary?.floors, selectedFloorAreas: areas }
            }
        },
        updateMeetingRoomOccupancySse: (state, action) => {
            const payload = action?.payload
            const data = state?.dashboard?.data
            if (payload?.hasOwnProperty('area') && validPositiveData(payload?.count) && data?.hasOwnProperty(payload?.area)) {
                data[payload?.area].meetingRoomOccupancy = payload?.count
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(asyncFetchMeetingRoomData.fulfilled, (state, action) => {
            state.dashboard = {
                ...state.dashboard,
                dataIsLoaded: true,
                data: action.payload
            }
        })
        builder.addCase(asyncFetchMeetingRoomStats.fulfilled, (state, action) => {
            state.dashboard = {
                ...state.dashboard,
                statsIsLoaded: true,
                stats: action.payload
            }
        })
        builder.addCase(asyncFetchMeetingRoomSecondaryOptions.fulfilled, (state, action) => {
            const updatedAreas = action.payload?.areas?.map(e => {
                return ({
                    ...e, data: []
                })
            })

            const selectedFloorAreas = updatedAreas?.filter(ele => ele.floor === action.payload?.floors[0]?.floor)

            state.secondary = {
                ...state.secondary,
                durations: {
                    ...state.secondary?.durations,
                    isLoaded: true,
                    list: action.payload?.durations
                },
                floors: {
                    ...state.secondary?.floors,
                    isLoaded: true,
                    floors: action.payload.floors,
                    areas: action.payload.areas,
                    selectedFloor: action.payload?.floors[0]?.floor,
                    selectedFloorAreas
                },
                totalCounts: {
                    ...state.secondary?.totalCounts,
                    isLoaded: true,
                    totalMaxCount: action.payload.totalMaxCount
                }
            }
        })
        builder.addCase(asyncFetchUtilisationData.fulfilled, (state, action) => {
            if (action.payload != undefined) {
                const todaysUtilization = {}
                const latest = action.payload?.recentDataForSensors || {}
                const totalCounts = {
                    ...state?.secondary?.totalCounts,
                    isLoaded: true,
                }
                action.payload?.todaysUtilization?.forEach(e => todaysUtilization[e.area] = e.count)
                todaysUtilization.totalTodaysUtilization = action.payload?.totalTodaysUtilization
                todaysUtilization.isLoaded = true
                latest.isLoaded = true

                state.secondary = {
                    ...state.secondary,
                    todaysUtilization,
                    latest,
                    totalCounts
                }
            }
        })
        builder.addCase(asyncFetchUtilisationWeekData.fulfilled, (state, action) => {
            const weeksUtilization = {}
            action.payload?.weeksUtilization?.forEach(e => weeksUtilization[e.area] = e.count)
            weeksUtilization.totalWeeksUtilization = action.payload?.totalWeeksUtilization
            weeksUtilization.isLoaded = true

            state.secondary = {
                ...state?.secondary,
                weeksUtilization
            }
        })
        builder.addCase(fetchTotalPeopleOccupied.fulfilled, (state, action) => {
            const data = Object.values(action.payload)
            let totalPeople = 0
            data.forEach(ele => {
                if (ele?.meetingRoomOccupancy !== 'NA') totalPeople += ele?.meetingRoomOccupancy
            })
            state.dashboard.data = action.payload
            state.dashboard.dataIsLoaded = true
            state.secondary.totalCounts = { ...state.secondary?.totalCounts, totalOccupancyToday: totalPeople }
        })
    }
})

export const { resetMeetingRoomsSecondaryData, updateMeetingRoomSecondaryDuration, updateMeetingRoomSecondarySelectedFloor, fetchFloorwiseChartData, resetAreasChartData, updateMeetingRoomOccupancySse } = mrOccupancySliceFidelity.actions

export default mrOccupancySliceFidelity.reducer