export const initialPPMState = {
    building: localStorage.getItem('bid'),
    title: "",
    description: "",
    priority: "",
    // area: "",
    category: "",
    // assetRef: "",
    date: "",
    startTime: "",
    endTime: "",
    media: [],
    comment: "",

    //repetitive
    isRecursive: false,
    recursiveConfiguration: {
        frequency: "",
        interval: null,
        until: "",
        avoidSaturday: false,
        avoidSunday: false
    },

    //currentService
    currentService: {
        assignedUserType: "internal",
        assignedVendor: "",
        assignedUser: ""
    }
}

export const PPMReducer = (state, action) => {
    switch (action.type) {
        case 'title': {
            return { ...state, title: action.payload }
        }
        case 'description': {
            return { ...state, description: action.payload }
        }
        case 'priority': {
            return { ...state, priority: action.payload }
        }
        case 'area': {
            return { ...state, area: action.payload }
        }
        case 'category': {
            return { ...state, category: action.payload }
        }
        case 'assetRef': {
            return { ...state, assetRef: action.payload }
        }
        case 'date': {
            return { ...state, date: action.payload }
        }
        case 'startTime': {
            return { ...state, startTime: action.payload }
        }
        case 'endTime': {
            return { ...state, endTime: action.payload }
        }
        case 'taskStatus': {
            return { ...state, taskStatus: action.payload }
        }
        case 'media': {
            return { ...state, media: action.payload }
        }
        case 'comment': {
            return { ...state, comment: action.payload }
        }

        //currentService

        case 'assignedUserType': {
            return {
                ...state, currentService: {
                    ...state.currentService,
                    assignedUserType: action.payload,
                    assignedVendor: "",
                    assignedUser: ""
                }
            }
        }
        case 'assignedVendor': {
            return { ...state, currentService: { ...state.currentService, assignedVendor: action.payload } }
        }
        case 'assignedUser': {
            return { ...state, currentService: { ...state.currentService, assignedUser: action.payload } }
        }
        case 'form': {
            return { ...state, formId: action.payload }
        }

        //repetitive
        case 'isRecursive': {
            return { ...state, isRecursive: action.payload }
        }

        case 'interval': {
            return { ...state, recursiveConfiguration: { ...state.recursiveConfiguration, interval: action.payload } }
        }
        case 'frequency': {
            return { ...state, recursiveConfiguration: { ...state.recursiveConfiguration, frequency: action.payload } }
        }
        case 'until': {
            return { ...state, recursiveConfiguration: { ...state.recursiveConfiguration, until: action.payload } }
        }
        case 'avoidSaturday': {
            return {
                ...state, recursiveConfiguration: { ...state.recursiveConfiguration, avoidSaturday: action.payload }
            }
        }
        case 'avoidSunday': {
            return { ...state, recursiveConfiguration: { ...state.recursiveConfiguration, avoidSunday: action.payload } }
        }

        //edit PPM Object
        case 'update': {
            return { ...action.payload }
        }
        default: return state
    }
}