import { Box, Typography } from '@mui/material'
import React from 'react'
import PDFReportGeneratorStyles from '../../../../styles/assetManagement/PDFReportGenerator'
import ReportHeaderFooterStyles from '../../../../styles/ReportHeaderFooter'
import CommonStyles from '../../../../styles/CommonStyles'
import { useSelector } from 'react-redux'

const ReportHeaderFooterAM = (props) => {
    const { children, bodyJustifyContent } = props
    const logo = useSelector(state => state.configurations.logo)
    const themeMode = useSelector(state => state.theme?.darkTheme)
    const nhanceLogo = process.env.REACT_APP_NHANCE_LOGO
    const buildings = useSelector(state => state.buildings?.buildings)
    const selectedBuilding = localStorage.getItem('bid')
    const selectedBuildingData = buildings?.find(building => building?._id === selectedBuilding)

    return (
        <Box sx={{ ...ReportHeaderFooterStyles.mainContainer }}>  {/*container */}
            <Box sx={{ ...ReportHeaderFooterStyles.reportContainer }}>
                <Box sx={{ ...ReportHeaderFooterStyles.headerContainer }}>  {/*header */}
                    <Box sx={{ ...PDFReportGeneratorStyles.reportHeader }}>
                        <Box component={'img'} src={themeMode ? logo?.dark : logo?.light} alt='' sx={{ height: '60px' }} />
                        <Typography sx={{ fontSize: '40px' }}>|</Typography>
                        <Typography sx={{ ...ReportHeaderFooterStyles.headerBuildingLocationTypography }}>{`${selectedBuildingData?.location?.name} - ${selectedBuildingData?.name}`}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ ...ReportHeaderFooterStyles.headerServiceNameTypography }}>Asset Management: Insights Report</Typography>
                    </Box>
                </Box>
                <Box sx={{ ...ReportHeaderFooterStyles.bodyContainer, justifyContent: bodyJustifyContent || 'space-between' }}>  {/*body */}
                    {children}
                </Box>
                <Box sx={{ height: '5%', }}>  {/*footer */}
                    <Box component={'footer'} sx={{ ...CommonStyles.justifyContentFlexEnd }}>
                        <Box component={'img'} src={nhanceLogo} alt='' sx={{ objectFit: 'contain' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportHeaderFooterAM