import { Autocomplete, Checkbox, IconButton, TextField } from "@mui/material";
import FormInput from "../../../components/formInputs/FormInput";
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from "react";
import { ThemeColors } from "../../../hooks/ThemeColors"
import * as Yup from "yup"

const SubGroupForm = ({ sensorsData, formData, handleAddSubSensorGroup, handleUpdateSubSensorGroup, selectedSubGroupName }) => {
    const [subGroupForm, setSubGroupForm] = useState({ name: "", sensors: [] })
    const [subGroupFormErrors, setSubGroupFormErrors] = useState({})
    const { borderSX, textColor } = ThemeColors()

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const existingSubGroupNames = formData?.sensorSubGroups?.map(sub => sub?.name)

    useEffect(() => {
        if (selectedSubGroupName) {
            setSubGroupForm(formData?.sensorSubGroups?.find(sub => sub?.name === selectedSubGroupName))
        } else {
            setSubGroupForm({ name: "", sensors: [] })
        }
    }, [selectedSubGroupName])

    const validateSubSensorFormData = async ({ formData }) => {
        try {
            const validationSchema = Yup.object().shape({
                name: Yup.string()
                    .min(3, "Name should be at least 3 characters")
                    .max(20, "Name should be at most 20 characters")
                    .test('not-included', 'Sub-group name already exists', (name) => {
                        return !existingSubGroupNames?.some(forbidden => name?.toLowerCase() === forbidden?.toLowerCase());
                    })
                    .required("Name is required"),
                sensors: Yup.array()
                    .of(Yup.string().required("Sensor is required"))
                    .min(1, "At least one sensor must be selected")
                    .required("Sensors are required"),
            });

            const editValidationSchema = Yup.object().shape({
                name: Yup.string()
                    .min(3, "Name should be at least 3 characters")
                    .max(20, "Name should be at most 20 characters")
                    .required("Name is required"),
                sensors: Yup.array()
                    .of(Yup.string().required("Sensor is required"))
                    .min(1, "At least one sensor must be selected")
                    .required("Sensors are required"),
            });
            if (selectedSubGroupName) await editValidationSchema.validate(formData, { abortEarly: false });
            else await validationSchema.validate(formData, { abortEarly: false });
            return {};
        } catch (validationErrors) {
            const errors = validationErrors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            return errors;
        }
    }

    const handleAddSubSensor = async ({ e, subGroupForm }) => {
        e.preventDefault()
        const structuredFormData = {
            name: subGroupForm?.name,
            sensors: subGroupForm?.sensors?.map(sensor => sensor?._id),
        }
        const errors = await validateSubSensorFormData({ formData: structuredFormData })
        if (Object.keys(errors)?.length > 0) {
            setSubGroupFormErrors(errors)
        } else {
            if (selectedSubGroupName) {
                handleUpdateSubSensorGroup(subGroupForm)
                setSubGroupFormErrors({})
            } else {
                setSubGroupFormErrors({})
                handleAddSubSensorGroup(subGroupForm)
                setSubGroupForm({ name: "", sensors: [] })
            }
        }
    }

    return (
        <div className={`flex flexDirectionRow gap16 flexAlignItemsCenter`}>
            <FormInput
                label={'Name'}
                value={subGroupForm?.name}
                onChange={(e) => setSubGroupForm({ ...subGroupForm, name: e.target.value })}
                name={'name'}
                helperText={subGroupFormErrors?.name ?? " "}
                sx={{ width: "20vw" }}
            />
            <Autocomplete
                multiple
                fullWidth
                id="select-sensors"
                options={sensorsData}
                value={subGroupForm?.sensors}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option?.sensorId}`}
                sx={{
                    ...borderSX,
                    ".MuiChip-root": {
                        color: textColor(), border: `1px solid ${textColor()}`
                    },
                    ".MuiChip-deleteIcon": {
                        color: textColor()
                    }
                }}
                onChange={(event, newValue) => {
                    setSubGroupForm({ ...subGroupForm, sensors: newValue })
                }}
                filterOptions={(options, { inputValue }) => {
                    const selectedSensorIds = formData?.sensors.map(sensor => sensor.sensorId) ?? [];
                    const selectedSubGroupSensorIds = formData?.sensorSubGroups?.map(group => group?.sensors).flat().map(value => value?.sensorId)
                    const allSensorIds = [...selectedSensorIds, ...selectedSubGroupSensorIds]
                    if (!inputValue) {
                        return options.filter(option => !allSensorIds.includes(option.sensorId));
                    }
                    return options.filter(option => {
                        const isNotSelected = !allSensorIds.includes(option.sensorId);
                        const matchesSearch = (
                            option.sensorId.toLowerCase().includes(inputValue.toLowerCase()) ||
                            option.vendorName.toLowerCase().includes(inputValue.toLowerCase()) ||
                            option.floorName.toLowerCase().includes(inputValue.toLowerCase()) ||
                            option.areaName.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        return isNotSelected && matchesSearch;
                    });
                }}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {`${option?.sensorId} - ${option?.vendorName}- ${option?.floorName} - ${option?.areaName}`}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Sub-group Sensors"
                        placeholder="Sensors"
                        helperText={subGroupFormErrors?.sensors ?? " "}
                    />
                )}
            />
            <IconButton type="submit" className="mrgnbtm30" onClick={(e) => handleAddSubSensor({ e, subGroupForm })} >
                <DoneTwoToneIcon />
            </IconButton>
        </div>
    )
}

export default SubGroupForm