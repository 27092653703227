import { useNavigate, useParams } from "react-router-dom";
import { memo, useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, styled } from "@mui/material";
import moment from "moment";
import { FILEPOST, GET, PUT } from "../../../../helpers/http";
import { errorToast } from "../../../../helpers/apiToast";
import CustomTypography from "../../../../components/texts/CustomTypography";
import CustomFormField from "../../../../components/formInputs/CustomFormField";
import CustomButton from "../../../../components/button/CustomButton";
import CustomComponentContainer from "../../../../components/container/CustomComponentContainer";
import DropDown from "../../vendorUser/components/dropDown/DropDown";
import { ThemeColors } from "../../../../hooks/ThemeColors";
import LimitTags from "../../../../components/formInputs/CustomAutoComplete";
import TextInput from "../../../../components/formInputs/TextInput";
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import { CloudUpload, HighlightOffOutlined } from "@mui/icons-material";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import AddAssetFormStyles from "../../../../styles/AddAssetFormStyles";
import DepartmentSettingsStyles from "../../../../styles/DepartmentSettingStyles";
import CategoryModal from "../../category/components/modals/CategoryModal";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ContractEdit = () => {
    const fileReadUrl = useSelector(state => state?.configurations?.fileReadUrl)

    const schema = Yup.object().shape({
        title: Yup.string().trim().required('Title is required'),
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date().required('End date is required').min(Yup.ref('startDate'), 'End date cannot be before start date'),
        serviceCategories: Yup.array().min(1, 'At least one service category is required'),
        buildings: Yup.array().min(1, 'At least one building is required'),
        vendor: Yup.string().required('Vendor is required')
    });

    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [buildings, setBuildings] = useState([]);
    const [isoStringStart, setIsoStringStart] = useState('');
    const [isoStringEnd, setIsoStringEnd] = useState('');
    const [selectedVendor, setSelectedVendor] = useState({});
    const [documents, setDocuments] = useState([]);
    const [categoryModalOpen, setCategoryModalOpen] = useState(false)


    const handleDateAndTime = (e, setState, setISO) => {
        const localDateTime = e.target.value;
        setState(localDateTime);
        const isoDateTime = new Date(localDateTime).toISOString();
        setISO(isoDateTime);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const formatDateTimeLocal = (isoString) => {
        return moment(isoString).format('YYYY-MM-DDTHH:mm');
    };

    const getSingleContract = useCallback(async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/contract/?id=${id}`, "GET SINGLE CONTRACT");
            setTitle(response?.data?.title);
            setBuildings(response?.data?.buildings);
            setStartDate(formatDateTimeLocal(response?.data?.startDate));
            setEndDate(formatDateTimeLocal(response?.data?.endDate));
            setSelectedCategories(response?.data?.serviceCategories);
            setSelectedBuildings(response?.data?.buildings);
            setSelectedVendor(response?.data?.vendor);
            setIsoStringStart(moment(response?.data?.startDate).toISOString());
            setIsoStringEnd(moment(response?.data?.endDate).toISOString());
            setDocuments(response?.data?.documents);
        } catch (error) {
            errorToast(error?.response?.data?.message);
        }
    }, [id]);

    const formFields = [
        { name: 'title', label: 'Title', value: title, setState: setTitle, type: 'textField', required: true, xs: 12 },
        { name: 'startDate', label: 'Start Date', value: startDate, setState: setStartDate, setISO: setIsoStringStart, type: 'DateTime', required: true, xs: 6 },
        { name: 'endDate', label: 'End Date', value: endDate, setState: setEndDate, setISO: setIsoStringEnd, type: 'DateTime', required: true, xs: 6 },
        {
            name: 'serviceCategories', label: 'Service Categories', value: categories, selectedOptions: selectedCategories,
            setSelectedOptions: setSelectedCategories, setState: setCategories, type: 'multiselect', required: true, xs: 12
        },
        {
            name: 'buildings', label: 'Buildings', value: buildings, setState: setBuildings, type: 'multiselect', required: true, xs: 12,
            selectedOptions: selectedBuildings, setSelectedOptions: setSelectedBuildings
        },
        {
            name: 'vendor', label: 'Vendor', value: selectedVendor?.name, setState: setSelectedVendor, type: 'textField', required: true, xs: 12,
            defaultValue: { _id: selectedVendor?._id, name: selectedVendor?.name }, disabled: true
        },
        { type: 'title', label: 'Documents *', xs: 12 },
        {
            type: 'uploadInput', label: 'Documents', allowMultiple: true, maxFiles: 3,
            value: documents, xs: 12, required: true, setState: setDocuments, name: 'documents'
        },
    ];

    const navigate = useNavigate()



    useEffect(() => {
        const getAllCategories = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, "GET CATEGORIES")
                if (response?.status === 200 && selectedCategories.length > 0) {
                    setCategories(response?.data.filter(ele => !selectedCategories?.find(el => el._id === ele._id)))
                }
            } catch (error) {
                errorToast(error?.response?.data?.message)
            }
        }
        getAllCategories()
    }, [selectedCategories])

    useEffect(() => {
        getSingleContract();
    }, [getSingleContract]);

    const { commonSX } = ThemeColors();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title, startDate: isoStringStart, endDate: isoStringEnd, serviceCategories: selectedCategories?.map(el => el._id),
            buildings: buildings?.map(el => el._id), vendor: selectedVendor?._id, documents
        };
        try {
            await schema.validate(formData, { abortEarly: false });
            setFormErrors({});
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/contract/?id=${id}`, formData, "ADD CONTRACT")
            if (response?.status === 200) {
                navigate('/admin/settings/contract')
            }
        } catch (error) {
            const errors = {};
            error?.inner?.forEach(error => {
                errors[error.path] = error.message;
            });
            setFormErrors(errors || error?.response?.data?.message);
        }
    };

    const handleDropDownChange = (e, setState) => {
        setState(e.target.value);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const handleChange = (e, setState) => {
        const { value } = e.target;
        setState(value);
        setFormErrors(prevErrors => ({ ...prevErrors, [e.target.name]: '' }));
    };

    const handleRemoveFile = (id) => {
        const result = documents.filter((el) => el.uploadId !== id)
        setDocuments(result)
    }

    const handleRemoveSingleFile = ({ name, setState }) => {
        setState((prevState) => ({ ...prevState, [name.split('.')[1]]: '' }));
    }

    const mediaUpload = async (file) => {
        try {
            const result = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, { file }, "MEDIA UPLOAD")
            return result.data
        } catch (error) {
            setFormErrors({ documents: `Invalid File: ${file.name}` })
        }
    }

    const handleMultipleFileUpload = useCallback(async (fileItems, documents, name) => {
        let response = []
        if (fileItems.length > 0) {
            try {
                response = await Promise.allSettled([...fileItems]?.map((fileItem) => mediaUpload(fileItem)))
                response = response.map((result) => result?.value)
                setDocuments([...documents, ...response])
            } catch (error) {
                setFormErrors({ [name]: error?.message || error?.response?.data?.message })
            }
        }
    }, []);

    const imageRenderer = ({ value, name, setState }) => {
        if (!value) return;
        if (Array.isArray(value)) {
            return <div className='flex'>
                {
                    value?.map((el, index) => {
                        return !el ? null : <Box sx={{ ...AddAssetFormStyles.commonImageBox, mt: '10px' }} key={index}>
                            <object data={`${fileReadUrl}${el?.key}`} height={100} width={'100%'} aria-label='images' />
                            <IconButton onClick={() => { handleRemoveFile(el?.uploadId) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
                        </Box>
                    })
                }
            </div>
        }
        else if (!Array.isArray(value) && typeof (value) === 'string') {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>
        }
        else if (!Array.isArray(value) && typeof (value) === 'object' && Object.keys(value).includes('key')) {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value?.key}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>

        }
    }

    const handleAddCategoryClick = () => {
        setCategoryModalOpen(true)
    }

    const addNewCategory = (newCategoryObj) => {
        setCategories((prevState) => {
            return [...prevState, newCategoryObj]
        })
    }

    return (
        <div className='width100 pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Edit Contract</CustomTypography>
                <CustomButton
                    variant='contained'
                    sx={{ mr: '20px', width: '12rem', height: '2.4rem' }}
                    className={styles.button_bgc}
                    onClick={() => navigate('/admin/settings/contract')}
                >
                    cancel
                </CustomButton>
            </div>
            <div className='mrgnsettingpage'>
                <CustomComponentContainer className='mrgnsettingpage pad10'>
                    <form onSubmit={handleSubmit}>
                        <Grid container className='pad10 width50'>
                            {
                                formFields?.map(({ xs, name, label, value, setState, type, required,
                                    selectedOptions, setSelectedOptions, setISO, defaultValue, disabled, allowMultiple }, index) => {
                                    return (
                                        <Grid item xs={xs} key={index} className={type !== 'title' && 'pad5'}>
                                            {type === 'title' && (
                                                <CustomTypography className='mrgnleft10 bold' sx={index > 0 && { mt: '10px' }}>
                                                    {label}
                                                </CustomTypography>
                                            )}
                                            {type === 'textField' && (
                                                <CustomFormField
                                                    variant='outlined'
                                                    disabled={disabled}
                                                    sx={commonSX}
                                                    size='small'
                                                    value={value || defaultValue}
                                                    label={label}
                                                    required={required}
                                                    name={name}
                                                    onChange={(e) => handleChange(e, setState)}
                                                    error={!!formErrors[name]}
                                                    helperText={formErrors[name]}
                                                />
                                            )}
                                            {type === 'multiselect' && (
                                                <>
                                                    <LimitTags
                                                        sx={commonSX}
                                                        label={label}
                                                        options={value}
                                                        selectedOptions={selectedOptions}
                                                        setSelectedOptions={setSelectedOptions}
                                                        error={formErrors.selectedCategories}
                                                    />
                                                    {
                                                        name === 'serviceCategories' && <CustomButton textContent='Add Category' variant="outlined"
                                                            sx={{ ...DepartmentSettingsStyles.addButton, mt: '10px', width: '12rem', height: '2.4rem' }}
                                                            onClick={handleAddCategoryClick} />
                                                    }
                                                </>
                                            )}
                                            {type === 'DateTime' && (
                                                <TextInput
                                                    disabled={name === 'startDate' && true}
                                                    value={value}
                                                    onChange={(e) => handleDateAndTime(e, setState, setISO)}
                                                    type='datetime-local'
                                                    label={label}
                                                    size='small'
                                                    focused
                                                    inputProps={{
                                                        min: isoStringStart.slice(0, 16)
                                                    }}
                                                    sx={{
                                                        '& .MuiTextField-root': {
                                                            minWidth: '100%',
                                                        },
                                                    }}
                                                    name={name}
                                                />
                                            )}
                                            {type === 'selectField' && (
                                                <DropDown
                                                    defaultValue={defaultValue}
                                                    formErrors={formErrors[name]}
                                                    name={name}
                                                    required={required}
                                                    label={label}
                                                    options={value}
                                                    setState={setState}
                                                    handleDropDownChange={(e) => handleDropDownChange(e, setState)}
                                                />
                                            )}
                                            {
                                                type === 'uploadInput' &&
                                                <>
                                                    <Button
                                                        component="label"
                                                        className={styles.button_bgc}
                                                        role={undefined}
                                                        size='small'
                                                        variant="contained"
                                                        tabIndex={-1}
                                                        startIcon={<CloudUpload className='color-white' />}
                                                        sx={{ width: '12rem', height: '2.4rem' }}
                                                    >
                                                        {label}
                                                        <VisuallyHiddenInput
                                                            type="file"
                                                            accept="image/*,application/pdf"
                                                            multiple={allowMultiple}
                                                            name={name}
                                                            // value={value}
                                                            onChange={(e) => handleMultipleFileUpload(e.target.files, documents)}
                                                        />
                                                    </Button>
                                                    {value && imageRenderer({ value, name, setState })}
                                                </>
                                            }
                                        </Grid>
                                    );
                                })
                            }
                            <Grid item xs={12} className='pad5'>
                                <CustomButton
                                    variant='contained'
                                    sx={{ mt: '10px', width: '12rem', height: '2.4rem' }}
                                    className={styles.button_bgc}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                    <CategoryModal
                        categoryModalOpen={categoryModalOpen}
                        setCategoryModalOpen={setCategoryModalOpen}
                        addNewCategory={addNewCategory}
                    />
                </CustomComponentContainer>
            </div>
        </div>
    );
};

export default memo(ContractEdit);
