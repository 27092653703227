
import { GroundFloorDeskExternalIdsMapped, RoomFloorExternalIdsMapped } from "../../../../components/facilityManager/features/twin/components/externalIds/extIds";
import { FETCH_INITIAL_FORGE_SENSORS, FETCH_SENSOR_AQI_DATA, RESET_SENSOR_AQI_DATA, FETCH_INITIAL_DESK_OCCUPANCY_DATA, SET_INITIAL_VIEWER_STATE, SHOW_SIDE_PANEL, UPDATE_SENSOR_TYPE, UPDATE_SSE_DESK_OCCUPANCY_FORGE_DATA, UPDATE_SSE_FORGE_DATA, FETCH_INITIAL_MEETING_ROOM_OCCUPANCY_DATA, UPDATE_SSE_MEETING_ROOM_FORGE_DATA, TOGGLE_SLIDE_PANEL, FETCH_ASSET_MANAGEMENT_DATA, CAMERA_FEED, FETCH_INITIAL_SETUP_DATA, FETCH_CAMERA_DATA, RESET_CAMERA_URL_DATA } from "./forgeActions"

const THREE = window.THREE

const initialState = {
    isLoaded: false,
    error: '',
    sensorData: [],
    externalIdAQIData: [],
    sensorType: "",
    slideInPanel: false,
    assetData: {},
    sseIdentifierBool: false,
    sidePanelInSplitScreen: false,
    viewerState: {},
    initialConfig: {},
    cameraConfig: [],
    deskOccupancyData: {
        bookedData: {},
        initialOccupiedData: {}
    },
    meetingRoomOccupancyData: {
        bookedMeetingRoom: {},
        initialOccupiedData: {}
    },
    cameraFeedUrl: ''
}
const forgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INITIAL_FORGE_SENSORS: {
            let l = state.sensorData.length
            while (l > 0) {
                state.sensorData.pop()
                l--
            }
            action.payload?.forEach((ele, ind) => {
                ele.id = ind
                state.sensorData?.push(ele)
            })

            return { ...state, isLoaded: true, sensorData: state.sensorData }
        }
        case UPDATE_SSE_FORGE_DATA: {
            if (action.payload.type === 'CO2_FORGE' && state.sensorType === "co2") {
                const sseData = action.payload.data
                state?.sensorData?.forEach((ele) => {
                    if (ele.sensorId === sseData.sensorId) {
                        ele.value = sseData.value
                    }
                })
                state.sseIdentifierBool = true
                return state
            }
            else if (action.payload.type === 'Temperature_FORGE' && state.sensorType === "temperature") {
                const sseData = action.payload.data
                state?.sensorData?.forEach((ele) => {
                    if (ele.sensorId === sseData.sensorId) {
                        ele.value = sseData.value
                    }
                })
                state.sseIdentifierBool = true
                return state
            } else return state
        }

        // case FETCH_INITIAL_DESK_BOOKED_DATA: {
        // 	state.deskOccupancyData.bookedData = action.payload
        // 	return state
        // }

        case FETCH_INITIAL_DESK_OCCUPANCY_DATA: {
            state.deskOccupancyData.initialOccupiedData = action.payload
            return state
        }

        case FETCH_INITIAL_SETUP_DATA: {
            state.isLoaded = true
            state.initialConfig = action.payload
            return state
        }

        case UPDATE_SSE_DESK_OCCUPANCY_FORGE_DATA: {
            const sseData = action.payload
            state.deskOccupancyData.initialOccupiedData[sseData.data?.sensorId] = sseData.data?.isOccupied
            for (let sensorId in GroundFloorDeskExternalIdsMapped) {
                if (sseData.data.sensorId === sensorId) {
                    getDbId(GroundFloorDeskExternalIdsMapped[sensorId])
                }
            }
            function getDbId(externalId) {
                if (externalId && sseData.viewer1) {
                    sseData.viewer1?.model?.getExternalIdMapping(data => {
                        let dbId = data[externalId]
                        return new Promise((resolve) => {
                            const forgeDeskData = state.deskOccupancyData
                            if (forgeDeskData?.initialOccupiedData[sseData.data?.sensorId]) {
                                resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1), sseData.viewer1.model, true))
                            }
                            //  else if (forgeDeskData?.bookedData[sseData.data.sensorId] || forgeDeskData?.initialOccupiedData[sseData.data.sensorId]) {
                            // 	resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(0.98, 0.67, 0.21, 1), sseData.viewer1.model, true))
                            // }
                            else {
                                resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1), sseData.viewer1.model, true))
                            }
                        })
                    })
                }
            }
            return state
        }

        case TOGGLE_SLIDE_PANEL: {
            return { ...state, slideInPanel: !state.slideInPanel }
        }

        case FETCH_CAMERA_DATA: {
            state.cameraConfig = action.payload
            return state
        }

        case FETCH_INITIAL_MEETING_ROOM_OCCUPANCY_DATA: {
            // const data = action.payload?.reduce((acc, ele) => {
            // 	if (acc[`${ele.subarea}`]) {
            // 		return acc
            // 	} else {
            // 		acc[`${ele.subarea}`] = {
            // 			count: ele["meeting_room_occupancy"],
            // 			isLatest: ele["isLatest"]
            // 		}
            // 		return acc
            // 	}
            // }, {})

            state.meetingRoomOccupancyData.initialOccupiedData = action.payload
            return state
        }

        case SET_INITIAL_VIEWER_STATE: {
            state.viewerState = action.payload
            return state
        }

        case RESET_CAMERA_URL_DATA: {
            return { ...state, cameraFeedUrl: '' }
        }

        case UPDATE_SSE_MEETING_ROOM_FORGE_DATA: {
            const sseData = action.payload
            state.meetingRoomOccupancyData.initialOccupiedData[sseData.data?.subarea].count = sseData.data?.count
            for (let roomName in RoomFloorExternalIdsMapped) {
                if (sseData.data?.subarea === roomName) {
                    getDbId(RoomFloorExternalIdsMapped[roomName])
                }
            }
            function getDbId(externalId) {
                if (externalId && sseData.viewer1) {
                    sseData.viewer1?.model?.getExternalIdMapping(data => {
                        let dbId = data[externalId]
                        return new Promise((resolve, reject) => {
                            const forgeMeetingRoomData = state.meetingRoomOccupancyData
                            if (forgeMeetingRoomData?.initialOccupiedData[sseData.data?.subarea].count > 0 && forgeMeetingRoomData?.initialOccupiedData[sseData.data?.subarea].isLatest) {
                                resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1), sseData.viewer1.model, true))
                            } else if (forgeMeetingRoomData?.initialOccupiedData[sseData.data?.subarea].count === 0 && forgeMeetingRoomData?.initialOccupiedData[sseData.data?.subarea].isLatest) {
                                resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(0, 1, 0, 1), sseData.viewer1.model, true))
                            } else {
                                resolve(sseData.viewer1.setThemingColor(dbId, new THREE.Vector4(1, 1, 1, 1), sseData.viewer1.model, true))
                            }
                        })
                    })
                }
            }
            return state
        }

        case UPDATE_SENSOR_TYPE: {
            return { ...state, sensorType: action.payload }
        }

        case FETCH_SENSOR_AQI_DATA: {
            return { ...state, isLoaded: true, externalIdAQIData: action.payload }
        }

        case RESET_SENSOR_AQI_DATA: {
            return { ...state, sensorData: [], sensorType: "", assetData: {}, externalIdAQIData: [] }
        }

        case SHOW_SIDE_PANEL: {
            // return { ...state, sidePanelInSplitScreen: !state.sidePanelInSplitScreen }
            state.sidePanelInSplitScreen = !state.sidePanelInSplitScreen
            return state
        }
        case FETCH_ASSET_MANAGEMENT_DATA: {
            return { ...state, isLoaded: true, assetData: action.payload }
        }

        case CAMERA_FEED: {
            action.payload?.openSidePanel()
            return { ...state, isLoaded: true, cameraFeedUrl: action.payload?.url }
        }

        default: {
            return state
        }
    }
}

export default forgeReducer
