import { Visibility, VisibilityOff } from "@mui/icons-material"
import TextInput from "../../components/formInputs/TextInput"
import CommonStyles from "../../styles/CommonStyles"
import DVRBackdrop from "../../components/Backdrop/DVRBackdrop"
import DVRDashboard from "../../components/Dashboard/DVRDashboard"
import { Box, Typography, Stack, Button, InputAdornment, IconButton, MenuItem, Paper, useTheme, colors } from "@mui/material"

import { runDVRValidation } from "../../helpers/runDVRValidation"
import { dvrDataParser } from "../../helpers/dvrDataParser"
import { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { useDVR, useDVRDispatch } from './DVRContext'
import { POST, GET } from '../../../admin/helpers/http'
import { successToast, errorToast } from '../../helpers/apiToast'
import { useSelector } from "react-redux"
import { ThemeColors } from "../../hooks/ThemeColors"

const DVRVerificationForm = (props) => {
    const { commonSX } = ThemeColors()
    const formObj = { ipAddress: "", username: "", password: "", port: "", gatewayIpAddress: "", subTenantReference: "", buildingReference: "", locationReference: "", name: "", requestId: "" }
    const [formData, setFormData] = useState(formObj)
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [DVRVerified, setDVRVerified] = useState(false)
    const [selectorData, setSelectorData] = useState({ subTenants: [], buildings: [], locations: [] })
    const timerIdRef = useRef(null);

    const [cameras, setCameras] = useState([])
    const [hardDrives, setHardDrives] = useState([])
    const [dvr, setDvr] = useState({})
    const tenantCode = useSelector(state => state?.user?.tenantCode)

    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDVRDispatch()

    const currentMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = currentMode ? '#282a2e' : '#fff'

    useEffect(() => {
        if (location.pathname.includes('admin/cctv/add')) {
            (async () => {
                try {
                    //Helpers GET not working, giving CORS Error
                    const getSubTenants = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/subtenant/list`, "Get Sub Tenants")
                    setSelectorData({
                        ...selectorData, subTenants: getSubTenants?.data
                    });
                } catch (error) {
                    if (error?.response?.data?.message) {
                        errorToast(error?.response?.data?.message)
                    } else {
                        errorToast(error?.message)
                    }
                }
            })()
        }
    }, [])

    useEffect(() => {
        if (Object.keys(formErrors).length !== 0) {
            const errors = runDVRValidation(formData, DVRVerified)
            setFormErrors(errors)
        }
    }, [formData])


    useEffect(() => {
        if (formData.buildingReference && selectorData.buildings.length !== 0) {
            const getLocation = selectorData.buildings.find((ele) => ele._id === formData.buildingReference)?.location
            setSelectorData({ ...selectorData, locations: [getLocation] })
            setFormData({ ...formData, locationReference: getLocation._id })
        }
    }, [formData.buildingReference, selectorData.buildings])

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleFormInputs = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value })
    }

    const cancelButtonHandle = () => {
        setFormData(formObj)
        navigate('/admin/cctv')
    }

    const apiCaller = ({ result }) => {
        const timer = setInterval(async () => {

            try {
                const validatedData = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check-result?requestId=${result.data.requestId}`, "VALIDATE DVR ADD")

                if (validatedData.status === 200) {
                    if (validatedData?.data?.error) {
                        errorToast(validatedData?.data?.error)
                        setLoading(false)
                        clearInterval(timer)
                    } else {
                        clearInterval(timer)
                        setDVRVerified(!DVRVerified)
                        setLoading(false)
                        setDvr(dvrDataParser(validatedData?.data))
                        setCameras(validatedData?.data?.cameras?.list)
                        setHardDrives(validatedData?.data?.hardwareDetails?.hardDisks)
                    }
                }
                //loop will running be till status 200
            } catch (error) {
                if (error.response.status === 404) {
                    errorToast(`DVR Add Validation: ${error?.response?.data?.message}`)
                    clearInterval(timer)
                    setLoading(false)
                } else {
                    errorToast(error?.message)
                }
            }
        }, 5000)
        timerIdRef.current = timer;
    }


    const cancelDVRCheck = (timerId) => {
        setLoading(false)
        if (timerIdRef.current) {
            clearInterval(timerIdRef.current);
            timerIdRef.current = null;
        }
    }


    const AddDVRHandle = async (e) => {
        try {
            e.preventDefault()
            //validate Form
            const errors = runDVRValidation(formData, DVRVerified)
            setFormErrors(errors)

            if (Object.keys(errors).length === 0) {

                const formObj = {
                    connectionDetails: {
                        ipAddress: formData.ipAddress,
                        port: formData.port,
                        username: formData.username,
                        password: formData.password,
                        gatewayIpAddress: formData.gatewayIpAddress
                    },
                    subTenantReference: formData.subTenantReference
                }

                if (!DVRVerified) {
                    const result = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check`, formObj, "DVR VERIFICATION")
                    setFormData({ ...formData, requestId: result.data.requestId })

                    if (result?.status === 200) {
                        setLoading(true)
                        apiCaller({ result })
                        // const timer = setInterval(async () => {
                        //     try {
                        //         const validatedData = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check-result?requestId=${result.data.requestId}`, "VALIDATE DVR ADD")

                        //         if (validatedData?.status === 200) {
                        //             if (validatedData?.data?.error) {
                        //                 errorToast(validatedData?.data?.error)
                        //                 setLoading(false)
                        //                 clearInterval(timer)
                        //             } else {
                        //                 clearInterval(timer)
                        //                 setDVRVerified(!DVRVerified)
                        //                 setLoading(false)
                        //                 setDvr(dvrDataParser(validatedData?.data))
                        //                 setCameras(validatedData?.data?.cameras?.list)
                        //                 setHardDrives(validatedData?.data?.hardwareDetails?.hardDisks)
                        //             }
                        //         }
                        //         //loop will running be till status 200
                        //     } catch (error) {
                        //         if (error.response.status === 404) {
                        //             errorToast(`DVR Add Validation: ${error?.response?.data?.message}`)
                        //             clearInterval(timer)
                        //             setLoading(false)
                        //         }
                        //     }
                        // }, 5000)

                        //get buildings and location
                        const getBuildings = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/building/list?subTenantId=${formData.subTenantReference}`, 'Get Buildings')
                        setSelectorData({
                            ...selectorData, buildings: getBuildings.data
                        });
                    } else {
                        errorToast(result.response.data.message)
                    }

                } else {
                    //ADD DVR
                    const postData = {
                        name: formData.name,
                        buildingReference: formData.buildingReference,
                        locationReference: formData.locationReference,
                        requestId: formData.requestId,
                        subTenantReference: formData.subTenantReference
                    }

                    const result = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/`, postData, "ADD DVR")
                    successToast("DVR added Successfully")
                    dispatch({ type: "ADD_DVR", payload: result.data.dvrConfig })
                    navigate('/admin/cctv')
                }
            }
        } catch (err) {
            if (err.response.status === 404) {
                errorToast(`DVR CHECK: ${err.response.data.message}`)
            } else {
                errorToast(err?.message)
            }
        }
    }


    return (
        <>
            <Stack margin='auto'>
                <Box sx={{ ...CommonStyles.OuterBox, minHeight: "89.9vh", maxHeight: "100%" }} >
                    <Typography sx={{ ...CommonStyles.pageHeading, marginTop: "1rem" }}>{!DVRVerified ? "DVR Verification" : "Add DVR"}</Typography>
                    <Box sx={{ ...CommonStyles.centerDiv, width: '100%' }}
                        component='form'
                        onSubmit={AddDVRHandle} >
                        <Stack
                            direction={"column"}
                        >
                            <>
                                <Paper sx={{ padding: "1rem", marginLeft: ".5rem", marginRight: "1rem", marginTop: ".5rem", backgroundColor: bgcolor }} elevation={0}>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            width: !DVRVerified ? "30rem" : "100rem",
                                            flexDirection: !DVRVerified ? "column" : "row",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        {!DVRVerified &&
                                            <>
                                                <TextInput
                                                    label="IP Address"
                                                    name='ipAddress'
                                                    sx={commonSX}
                                                    value={formData.ipAddress}
                                                    helperText={formErrors.ipAddress}
                                                    error={formErrors.ipAddress && true}
                                                    onChange={(e) => handleFormInputs(e, 'ipAddress')}
                                                />

                                                <TextInput
                                                    label="User Name"
                                                    name='username'
                                                    sx={commonSX}

                                                    value={formData.username}
                                                    helperText={formErrors.username}
                                                    error={formErrors.username && true}
                                                    onChange={(e) => handleFormInputs(e, 'username')}
                                                />

                                                <TextInput
                                                    label="Password"
                                                    name='password'
                                                    sx={commonSX}

                                                    type={showPassword ? 'text' : 'password'}
                                                    value={formData.password}
                                                    helperText={formErrors.password}
                                                    error={formErrors.password && true}
                                                    onChange={(e) => handleFormInputs(e, "password")}
                                                    InputProps={
                                                        {
                                                            endAdornment: < InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    }
                                                />

                                                <TextInput
                                                    label="Port"
                                                    name='port'
                                                    sx={commonSX}

                                                    value={formData.port}
                                                    helperText={formErrors.port}
                                                    error={formErrors.port && true}
                                                    onChange={(e) => handleFormInputs(e, "port")}
                                                />


                                                <TextInput
                                                    label="Gateway IP Address"
                                                    name='gatewayIpAddress'
                                                    sx={commonSX}

                                                    value={formData.gatewayIpAddress}
                                                    helperText={formErrors.gatewayIpAddress}
                                                    error={formErrors.gatewayIpAddress && true}
                                                    onChange={(e) => handleFormInputs(e, "gatewayIpAddress")}
                                                />

                                                <TextInput
                                                    select
                                                    label="Select a Subtenant"
                                                    name='subTenantReference'
                                                    sx={commonSX}

                                                    value={formData.subTenantReference}
                                                    helperText={formErrors.subTenantReference}
                                                    error={formErrors.subTenantReference && true}
                                                    onChange={(e) => handleFormInputs(e, "subTenantReference")}
                                                >
                                                    {selectorData.subTenants.map((subT) => {
                                                        return <MenuItem key={subT._id} value={subT._id}>{subT.name}</MenuItem>
                                                    })}
                                                </TextInput>
                                            </>
                                        }

                                        {DVRVerified &&
                                            <Stack direction={"row"} alignItems='center' flexWrap='wrap' gap={3} paddingTop="1rem">
                                                <TextInput
                                                    label="Name"
                                                    name='name'
                                                    sx={{ ...commonSX, width: "20rem" }}
                                                    value={formData.name}
                                                    helperText={formErrors.name}
                                                    error={formErrors.name && true}
                                                    onChange={(e) => handleFormInputs(e, "name")}
                                                />
                                                <TextInput
                                                    select
                                                    label="Select a Building"
                                                    name='buildingReference'
                                                    sx={{ ...commonSX, width: "20rem" }}
                                                    value={formData.buildingReference}
                                                    helperText={formErrors.buildingReference}
                                                    error={formErrors.buildingReference && true}
                                                    onChange={(e) => handleFormInputs(e, "buildingReference")}
                                                >
                                                    {selectorData.buildings.map((build) => {
                                                        return <MenuItem key={build._id} value={build._id}>{build.name}</MenuItem>
                                                    })}
                                                </TextInput>

                                                <TextInput
                                                    select
                                                    label="Select a Location"
                                                    name='locationReference'
                                                    sx={{ ...commonSX, width: "20rem" }}
                                                    value={formData?.locationReference}
                                                    helperText={formErrors.locationReference}
                                                    error={formErrors.locationReference && true}
                                                    onChange={(e) => handleFormInputs(e, "locationReference")}
                                                >
                                                    {selectorData?.locations?.map((loc) => {
                                                        return <MenuItem key={loc?._id} value={loc?._id}>{loc?.name}</MenuItem>
                                                    })}
                                                </TextInput>
                                            </Stack>
                                        }


                                        <Box sx={{ display: 'flex', columnGap: 2, width: '30rem', height: "4em" }}>
                                            <Button onClick={cancelButtonHandle} sx={{ ...CommonStyles.actionButton, width: "50%", bgcolor: 'black', fontSize: '14px', py: 1 }}>Cancel</Button>

                                            <Button type="submit" sx={{ ...CommonStyles.actionButton, width: "50%", fontSize: '14px', py: 1 }}>
                                                {!DVRVerified ? "Validate DVR" : "Add DVR"}
                                            </Button>
                                        </Box>

                                    </Stack>
                                </Paper>
                                <DVRBackdrop cancelDVRCheck={cancelDVRCheck} loading={loading} message={"Getting DVR information..."} />
                            </>
                            {DVRVerified &&
                                <DVRDashboard loading={loading} errorMessage={errorMessage} dvr={dvr} hardDrives={hardDrives} cameras={cameras} />}
                        </Stack>
                    </Box>
                </Box>
            </Stack >
        </>
    )
}


export default DVRVerificationForm