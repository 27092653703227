import { createContext, useEffect, useReducer } from "react";
import { PPMListReducer } from "./PPMListReducer";
import { GET } from "../../../helpers/http";
import { useSelector } from "react-redux";

export const PPMListContext = createContext(null);
export const PPMListDispatchContext = createContext(null);

export const fetchPPMList = async (dispatch, date, type) => {
    try {
        const buildingId = localStorage.getItem('bid')
        if (buildingId) {
            const today = new Date().toISOString().slice(0, 10)
            const url = `${process.env.REACT_APP_URL}ppm/v1/task/list?buildingId=${buildingId}&type=${type ?? "all"}`
            const urlWithDate = `&date=${date ?? today}`
            // const { data = [] } = await GET(`${'http://localhost:8030/'}v1/task/list?buildingId=${buildingId}`, 'PPM List')
            const { data = [] } = await GET(!date ? url : url + urlWithDate, 'PPM List')
            if (dispatch) {
                dispatch({ type: 'list', payload: data, isLoaded: true })
            }
        }
    } catch (error) {
        console.log({ error })
        if (dispatch) {
            dispatch({ type: 'list', payload: [], isLoaded: true })
        }
    }
}

const PPMListProvider = ({ children }) => {
    const [PPMList, dispatch] = useReducer(PPMListReducer, { isLoaded: false, data: [] });
    const { ppm } = useSelector(state => state.configurations.services)

    useEffect(() => {
        if (ppm) {
            // fetchPPMList(dispatch)
        } else {
            dispatch({ type: 'list', payload: [], isLoaded: true })
        }

        return () => dispatch({ type: 'list', payload: [], isLoaded: false })
    }, [ppm])

    return (
        <PPMListContext.Provider value={PPMList}>
            <PPMListDispatchContext.Provider value={dispatch}>
                {children}
            </PPMListDispatchContext.Provider>
        </PPMListContext.Provider>
    )
}

export default PPMListProvider
