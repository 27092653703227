import React from 'react'
import Modal from '../Modal'
import { Box, Button, Typography } from '@mui/material'
import CommonStyles from '../../../../../styles/CommonStyles'
import { convertToXls } from '../../../../../helpers/convertToXls'

const BulkUserDetailsFailedModal = (props) => {
    const { openModal, handleCloseModal, notUploadableUserData } = props

    return (
        <Modal
            modalOpen={openModal}
            closeModal={handleCloseModal}
            modalWidth={'30%'}
        >
            <Typography sx={{ fontSize: '22px', p: 1 }}>Some/All User(s) Failed to upload!</Typography>
            <Box sx={{ height: "20vh", display: 'flex', flexDirection: 'column', justifyContent: "space-evenly", p: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: '16px' }}>User Details upload Failed for {notUploadableUserData?.length} User(s)?</Typography>
                <Box display={'flex'} justifyContent={'space-evenly'} mt={2}>
                    <Button sx={{ ...CommonStyles.actionButton }} onClick={() => { convertToXls(notUploadableUserData, 'failed-users'); handleCloseModal() }}>Download details</Button>
                    <Button onClick={handleCloseModal} sx={{
                        bgcolor: '#212121',
                        color: 'white',
                        textTransform: 'none',
                        "&:hover": {
                            bgcolor: '#111111',
                        }
                    }}>Cancel</Button>
                </Box>

            </Box>
        </Modal>
    )
}

export default BulkUserDetailsFailedModal