import { Box, Button, MenuItem, Stack } from "@mui/material"
import FormInput from "../../../components/formInputs/FormInput"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { GET, POST, PUT } from "../../../helpers/http"
import { successToast } from "../../../helpers/apiToast"
import moment from "moment"

const AddSingleHoliday = ({ closeAddSingleHolidayModal = () => { }, setHolidayData = () => { }, editingHoliday = {} }) => {
    //** Year Dropdown Selection Logic **//
    const [selectedYear] = useState(Number(moment().format('YYYY')));
    const { buildings } = useSelector(state => state.buildings);

    const [holidayLeaveTypeOptions, setHolidayLeaveTypeOptions] = useState([])

    const [selectedBuilding, setSelectedBuilding] = useState(editingHoliday?.building?._id || buildings?.[0]?._id || '')

    const handleBuildingChange = (e) => {
        setSelectedBuilding(e.target.value)
        setAddHolidayErrors({})
    }

    const [addHoliday, setAddHoliday] = useState({
        name: editingHoliday?.name || '',
        date: editingHoliday?.date || '',
        leaveType: editingHoliday?.leaveType || '',
    })

    const [addHolidayErrors, setAddHolidayErrors] = useState({})

    const handleAddHolidayFormChange = (e) => {
        const { name, value } = e.target
        setAddHoliday({ ...addHoliday, [name]: value })
        setAddHolidayErrors({})
    }

    const postHoliday = async () => {
        try {
            const { data } = await POST(`${process.env.REACT_APP_URL}at/v1/holidaycalendar?bid=${selectedBuilding}`, { data: addHoliday }, "ADD HOLIDAY", true)
            successToast(`${addHoliday?.name} Holiday added successfully`)
            const year = moment(addHoliday?.date).year();
            if (year === selectedYear) setHolidayData(prevData => [...prevData, data]);
            closeAddSingleHolidayModal()
        } catch (error) {
            console.log({ error })
        }
    }

    const putHoliday = async () => {
        try {
            const { data } = await PUT(`${process.env.REACT_APP_URL}at/v1/holidaycalendar/${editingHoliday?._id}?bid=${selectedBuilding}`, { data: addHoliday }, "EDIT HOLIDAY", true)
            successToast(`${editingHoliday?.name} Holiday updated successfully`)
            const year = moment(addHoliday?.date).year();
            if (year === selectedYear) setHolidayData(prevData => prevData.map(holiday => holiday?._id === editingHoliday?._id ? data : holiday))
            closeAddSingleHolidayModal()
        } catch (error) {
            console.log({ error })
        }
    }

    const validateHolidayForm = () => {
        if (!selectedBuilding) {
            setAddHolidayErrors(prevErrors => ({ ...prevErrors, building: 'Please select a building' }))
        }
        else if (!addHoliday?.date) {
            setAddHolidayErrors(prevErrors => ({ ...prevErrors, date: 'Please select a date' }))
        }
        else if (addHoliday?.name?.trim()?.length < 3) {
            setAddHolidayErrors(prevErrors => ({ ...prevErrors, name: 'Name should be at least 3 characters' }))
        }
        else if (!addHoliday?.leaveType) {
            setAddHolidayErrors(prevErrors => ({ ...prevErrors, leaveType: 'Please select a leave type' }))
        }
        else {
            setAddHolidayErrors({})
            if (editingHoliday?._id) { putHoliday() }
            else { postHoliday() }
        }
    }

    const handleSubmitAddHoliday = (e) => {
        e.preventDefault()
        validateHolidayForm()
    }

    useEffect(() => {
        if (editingHoliday?.building?._id) {
            setSelectedBuilding(editingHoliday?.building?._id)
        }
    }, [editingHoliday?.building?._id])

    useEffect(() => {
        try {
            const fetchOptions = async () => {
                const { data } = await GET(`${process.env.REACT_APP_URL}at/v1/holidaycalendar/leave-types/options`, "HOLIDAY LEAVE TYPE OPTIONS")
                setHolidayLeaveTypeOptions(data)
            }

            fetchOptions()
        } catch (error) {
            console.log({ error })
            setHolidayLeaveTypeOptions([])
        }
    }, [])

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmitAddHoliday}
        >
            <Stack spacing={2}>
                <FormInput
                    label="Building"
                    value={selectedBuilding}
                    onChange={handleBuildingChange}
                    select
                    error={!!addHolidayErrors?.building}
                    helperText={addHolidayErrors?.building}
                >
                    {buildings?.map((building) => (
                        <MenuItem
                            key={building._id}
                            value={building._id}
                        >
                            {building.name}
                        </MenuItem>
                    ))}
                </FormInput>
                <FormInput
                    label="Date"
                    type="date"
                    inputProps={{
                        // min: moment().format('YYYY-MM-DD')
                    }}
                    name="date"
                    value={addHoliday.date}
                    onChange={handleAddHolidayFormChange}
                    error={!!addHolidayErrors?.date}
                    helperText={addHolidayErrors?.date}
                />
                <FormInput
                    label="Name"
                    name="name"
                    value={addHoliday.name}
                    onChange={handleAddHolidayFormChange}
                    error={!!addHolidayErrors?.name}
                    helperText={addHolidayErrors?.name}
                />
                <FormInput
                    label="Type"
                    select
                    name="leaveType"
                    value={addHoliday.leaveType}
                    onChange={handleAddHolidayFormChange}
                    error={!!addHolidayErrors?.leaveType}
                    helperText={addHolidayErrors?.leaveType}
                >
                    {holidayLeaveTypeOptions?.map((type) => (
                        <MenuItem
                            key={type}
                            value={type}
                        >
                            {type}
                        </MenuItem>
                    ))}
                </FormInput>
                <Button className="actionButton" type="submit">Submit</Button>
            </Stack>
        </Box>
    )
}

export default AddSingleHoliday