import { Box } from '@mui/material'
import React from 'react'

const ImageFromFiles = ({ imageSrc, ...rest }) => {

    return (

        Boolean(imageSrc) ? <Box component='img' alt='image' src={imageSrc} {...rest} /> : <Box component='img' src='https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' alt='image' {...rest} />

    )
}

export default ImageFromFiles