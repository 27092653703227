import { UploadFileOutlined } from "@mui/icons-material"
import { Button } from "@mui/material"
import { ThemeColors } from "../../hooks/ThemeColors"

const FormFileInput = (props) => {
    const { buttonText, accept, sx = {}, fullWidth, fileRef, multiple, onChange = () => { } } = props
    const { textColor } = ThemeColors()

    return (
        <Button
            component="label"
            variant="outlined"
            sx={{
                borderColor: textColor(),
                color: textColor(),
                ":hover": {
                    borderColor: textColor(),
                },
                ...sx,
            }}
            fullWidth={!!fullWidth}
            startIcon={<UploadFileOutlined />}
        >
            {buttonText}
            <input
                type="file"
                accept={accept}
                hidden
                onChange={onChange}
                multiple={!!multiple}
                ref={fileRef}
            />
        </Button>
    )
}

export default FormFileInput