import { AddCircleOutline, Delete } from "@mui/icons-material"
import { Avatar, Box, Button, Grid, styled, TextField, Typography } from "@mui/material"
import { ThemeColors } from "../../../../hooks/ThemeColors";
import { useEffect, useRef, useState } from "react";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { FILEPOST } from "../../../../helpers/http";
import TextInput from "../../../../components/formInputs/TextInput";
import ReturnMultiMedia from "../../../../components/media/ReturnMultiMedia";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading/Loading";

const AmenitiesUpload = ({ formData, setFormData, amenities, setAmenitites, setLogoMedia, logoMedia, viewMode }) => {
    const { themeMode } = ThemeColors();
    const [singleMedia, setSingleMedia] = useState({})
    const [uploadingMedia, setUploadingMedia] = useState(false)
    const [logoName, setLogoName] = useState('')
    const [error, setError] = useState('')

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const handleImage = async ({ event }) => {
        const fileData = event.target.files[0];
        const formData = new FormData();

        if (!fileData) {
            return errorToast("No file selected");
        }

        // List of allowed MIME types for images and videos
        const allowedTypes = ["image/jpeg", "image/png",
            //  "image/gif", 
            "image/svg",
            "image/svg+xml",
            // "video/mp4", "video/webm", "video/ogg"
        ];

        if (!allowedTypes.includes(fileData.type)) {
            setSingleMedia({});
            event.target.value = null; // Clear the input
            return errorToast("Only images are allowed");
        }

        // Read and validate SVG dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(e.target.result, "image/svg+xml");
            const svgElement = svgDoc.documentElement;

            // If valid, proceed with upload
            formData.append("file", fileData);

            setUploadingMedia(true);
            FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, formData, "Upload File")
                .then((response) => {
                    setSingleMedia(response.data)
                    successToast("LOGO successfully uploaded")

                })
                .catch((error) => {
                    console.error("File upload failed:", error);
                    errorToast("Failed to upload the file. Please try again.");
                })
                .finally(() => {
                    setUploadingMedia(false);
                });
        };

        reader.readAsText(fileData); // Read the SVG file to validate dimensions
    };

    const handleName = (event) => {
        setLogoName(event.target.value)
    }

    const handleSubmit = async () => {
        let validateSingleMedia = Object.keys(singleMedia)

        if (!logoName || !validateSingleMedia) {
            setError("Both logo name and image are required.");
            return;
        }
        setError("")
        setLogoMedia([...logoMedia, { ...singleMedia, name: logoName }])
        // setAmenitites([...amenities, { name: logoName, logo: singleMedia?.key }])
        setAmenitites((prevAmenites) => [...prevAmenites, { name: logoName, logo: singleMedia?.key }])
        setFormData(prevFormData => {
            const newAmenities = [
                ...(prevFormData?.amenities || []),
                { name: logoName, logo: singleMedia?.key }
            ]
            return { ...prevFormData, amenities: newAmenities }
        })
        setSingleMedia({})
        setLogoName("")
    }


    return (
        <Box
            sx={{
                // height: "20rem",
                // overflow: "auto"
            }}
        >
            <Grid container spacing={2}
                gap={5}
                sx={{
                    height: "8rem",
                    // border: "1px solid red"
                }}>

                <Grid item sm={6} md={4} lg={3} xl={3}
                //  sx={{ border: "1px solid orange" }}
                >
                    <Box
                        component='label'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "55%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                            // ...sx
                        }}>
                        <AddCircleOutline sx={{ fontSize: '3rem' }} />
                        <VisuallyHiddenInput
                            type="file"
                            disabled={viewMode}
                            onChange={(event) => handleImage({ event })}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} md={4} lg={3} xl={3}
                    sx={{
                        // border: "1px solid black",
                    }}
                >
                    <TextInput
                        focused={Object.keys(singleMedia).length && true}
                        name='logo'
                        type='text'
                        disabled={viewMode}
                        label='Logo Name'
                        sx={{ width: "10rem" }}
                        value={logoName}
                        onChange={(event) => { handleName(event) }}

                    />
                    {Object.keys(singleMedia).length > 0 &&
                        <Typography sx={{ color: "blue", fontSize: "13px" }}>Logo has been uploaded</Typography>
                    }

                </Grid>
                <Grid
                    item
                    sm={6} md={4} lg={3} xl={3}
                >
                    <Button
                        variant='contained'
                        disabled={viewMode}
                        sx={{
                            backgroundColor: 'blue',
                            '&:hover': { backgroundColor: '#c43e2d' },
                            width: '20%',
                            padding: "1.7rem",
                            height: '2.5rem',
                        }}
                        onClick={handleSubmit}
                    >
                        Add {/* {editMode ? 'Update' : 'Submit'} */}
                    </Button>

                </Grid>
                {error.length > 0 && <p style={{ color: "red", padding: "10px", fontSize: "13px" }}>{error}</p>}
            </Grid>
        </Box >
    )

}
export default AmenitiesUpload