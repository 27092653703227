import { Box } from "@mui/material"
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import { ThemeColors } from "../../../../hooks/ThemeColors";

const ReturnMultiTypeMedia = ({ fileReadUrl, media, sx, height, fitImage }) => {
    const { themeMode } = ThemeColors();

    const plyrOptions = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
        hideControls: false,
        ratio: "16:9",
        settings: ['captions', 'quality', 'speed', 'loop'],
        //quality: { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240] }
    }

    return (
        <Box sx={{ height: "inherit", width: "inherit", ...sx }} >
            {
                media?.type?.includes("image") ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '1rem',
                            height: height,
                            overflow: 'hidden',
                            backgroundColor: themeMode ? '#5a5a5a' : '#eceff4'
                        }}>
                        <img
                            src={`${fileReadUrl}${media?.key}`}
                            style={{
                                objectFit: fitImage ?? 'contain',
                                width: '100%',
                                height: '100%'
                            }}
                            alt={media?.key}
                        />
                    </Box>
                    :
                    media?.type?.includes("pdf") ?
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '1rem',
                                height: height,
                                overflow: 'hidden',
                                objectFit: fitImage ?? 'contain',
                            }}
                        >
                            <iframe
                                title="pdf"
                                src={`${fileReadUrl}${media?.key}`}
                                type="application/pdf"
                                style={{
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: fitImage ?? 'contain',
                                }}
                            />
                        </Box>
                        :
                        media?.type?.includes("video") ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '1rem',
                                    height: height,
                                    overflow: 'hidden',
                                }}
                            >
                                <Plyr
                                    source={{ type: "video", sources: [{ src: `${fileReadUrl}${media?.key}` }] }}
                                    options={plyrOptions}
                                />
                            </Box> :
                            <Box>
                                <p>No Media</p>
                            </Box>
            }
        </Box>
    )
}

export default ReturnMultiTypeMedia