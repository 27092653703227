import { useEffect, useState } from "react";
import { minmap } from "../../../../features/twin/components/matterport/3dSceneModule/minimap.js"
import { injectToMatterPortSdk } from "../../../../features/twin/components/matterport/libs/sdk-injectToSdk.js";
import { useSelector } from "react-redux";
import '../../../../features/twin/components/matterport/libs/matterport.css'
import { useFetchMatterportInitalConfigQuery } from "../../../../../../redux/features/services/matterportSlice.js";
import Dropdown from "../../../../components/formInputs/dropDown/Dropdown.js";

const MatterportTwin = () => {


    const { sensorMappingWithMattertags: CONSTANTS, sdkKey, isLoaded, cameraMappingWithMattertags: CAMERA, modelId } = useSelector(state => state.matterport)
    const [selectedAreaId, setSelectedAreaId] = useState('')

    useFetchMatterportInitalConfigQuery(localStorage.getItem('bid'))



    useEffect(() => {
        const showcase = document.getElementById('showcase');
        const showcaseWindow = showcase.contentWindow;

        const loadListener = async () => {
            let mpSdk;
            try {
                mpSdk = await showcaseWindow.MP_SDK.connect(showcaseWindow);
                if (mpSdk) {
                    await minmap(mpSdk, sdkKey);
                    await mpSdk.on("tag.hover", async (sid, hovering) => {
                        if (hovering && CONSTANTS[sid]) {
                            injectToMatterPortSdk(mpSdk, String(sid), CONSTANTS, CAMERA);
                        }
                    });
                    await mpSdk.App.state.waitUntil(async (appState) => {
                        if (appState.phase === 'appphase.playing') {
                            console.log('Hello Bundle SDK', mpSdk);
                            // await startApp(mpSdk)
                        }
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };

        if (isLoaded) {
            showcase.addEventListener('load', loadListener);

            return () => {
                showcase.removeEventListener('load', loadListener);
            };
        }
    }, [isLoaded, selectedAreaId]);


    useEffect(() => {
        modelId.length > 0 && setSelectedAreaId(modelId[0].id)
    }, [isLoaded])


    // async function startApp(sdk) {
    //     // subscription to pointer object to help find position values

    //     // Registering the component with the sdk
    //     await sdk.Scene.register(videoPlaneType, makeVideoPlane);
    //     // Create a scene object. `createObjects` can create multiple objects and returns an array with that number of objects
    //     const [sceneObject] = await sdk.Scene.createObjects(1);
    //     // Create a scene node
    //     const node = sceneObject.addNode();
    //     // set initial input values for VideoPlane inputs
    //     const video_node_inputs = {
    //         videoSrc: video,
    //         visible: true,
    //         aspectRatio: 16 / 9,
    //         localScale: { x: 1.87, y: 1.9, z: 2 },
    //         localRotation: { x: 0, y: 0, z: 0 },
    //         // localPosition: { x: 6.84, y: -0.12, z: 1.22 }
    //         // localPosition: { x: 2, y: 0, z: 0 }

    //     }

    //     // const video_node_inputs1 = {
    //     //   videoSrc: video,
    //     //   visible: true,
    //     //   aspectRatio: 16 / 9,
    //     //   localScale: { x: 1.25, y: 1.25, z: 1.25 },
    //     //   localRotation: { x: 0, y: 0, z: 0 },
    //     //   localPosition: { x: 1, y: 1, z: 1 }
    //     // }

    //     node.addComponent(videoPlaneType, video_node_inputs);
    //     // node.addComponent(videoPlaneType, video_node_inputs1);
    //     node.start();
    // }


    const handleAreaSelection = (e) => {
        setSelectedAreaId(e.target.value)
    }

    return (
        <div>
            <article className="theme-component-background widthFill">
                <Dropdown
                    mt={2}
                    inputLabel='Select the area'
                    label='Select the area'
                    width='200px'
                    data={modelId?.length > 0 ? modelId : []}
                    onChange={handleAreaSelection}
                    value={selectedAreaId}
                />
                <div className="showcase_container" style={{ position: "relative" }}>
                    <iframe
                        id="showcase"
                        src={`/bundle/showcase.html?m=${selectedAreaId}&newtags=1&play=1&hr=0&applicationKey=${sdkKey}`}
                        width="100%"
                        height="745"
                        frameBorder="0"
                        allow="xr-spatial-tracking"
                        allowFullScreen
                        title=" "
                    >
                    </iframe>
                    <div className="map_container">
                        <div id="matterport_minimap"></div>
                    </div>
                </div>
            </article>

        </div>

    );
}


export default MatterportTwin;