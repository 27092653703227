import { Skeleton, Table, TableCell, TableHead, TableRow } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"

const TableSkeleton = ({ tableProp }) => {
	const themeMode = useSelector(state => state.theme.darkTheme)
	const [tableData, setTableData] = useState(tableProp)
	const bg_table_color = () => themeMode ? 'gray' : '#fff'
	const background_color = () => themeMode ? 'black' : '#fff'

	return (
        <Table sx={{ height:"inherit" }}>
			<TableHead>
				<TableRow sx={{ backgroundColor: background_color }}>
					{tableData?.map((cell, index) => <TableCell key={index} sx={{ width: cell?.width,}} >{cell?.headerName}</TableCell>)}
				</TableRow>
			</TableHead>
			{[...Array(10)].map((row, index) => (
				<TableRow key={index} sx={{ backgroundColor: bg_table_color }}>
					{[...Array(tableData?.length)]?.map((value, index) => {
						return (
							<TableCell key={index} component="th" scope="row" >
								<Skeleton animation="pulse" variant="text" width="90%" height="15px" />
							</TableCell>
						)
					})}
				</TableRow>))}
		</Table>
	)
}

export default TableSkeleton