import { createContext, useContext, useReducer } from 'react';

const DVRContext = createContext(null);

const DVRDispatchContext = createContext(null);

export function DVRProvider({ children }) {
    const [DVRs, dispatch] = useReducer(
        dvrReducer,
        initialState
    );

    return (
        <DVRContext.Provider value={DVRs}>
            <DVRDispatchContext.Provider value={dispatch}>
                {children}
            </DVRDispatchContext.Provider>
        </DVRContext.Provider>
    );
}

export function useDVR() {
    return useContext(DVRContext);
}

export function useDVRDispatch() {
    return useContext(DVRDispatchContext);
}

function dvrReducer(DVRs, action) {
    switch (action.type) {
        case 'DVRadded': {
            return {
                ...DVRs,
                dvrList: [...DVRs.dvrList, action.payload]
            };
        }
        case 'UPDATE_DVR': {
            const updatedDVRs = DVRs?.dvrList?.map(dvr => {
                if (dvr._id === action.payload._id) {
                    return action.payload;
                }
                return dvr;
            })
            return {
                ...DVRs,
                dvrList: updatedDVRs
            };
        }
        case 'DVRdeleted': {
            return DVRs?.dvrList?.filter(dvr => dvr._id !== action.payload);
        }
        case 'fetchAllDVRs': {
            return {
                ...DVRs,
                dvrList: action.payload
            }
        }
        case 'addDVRFormState': {
            return {
                ...DVRs,
                addDvr: {
                    ...DVRs.addDvr,
                    ...action.payload
                }
            }
        }
        case 'updateDVRFormState': {
            return {
                ...DVRs,
                selectedDvr: {
                    ...DVRs.selectedDvr,
                    ...action.payload
                }
            }
        }
        case 'UPDATE_SELECTED_DVR': {
            return {
                ...DVRs,
                selectedDvr: action.payload
            }
        }

        case "ADD_DVR_LIST": {
            return {
                ...DVRs,
                dvrList: action.payload
            }
        }

        case "ADD_DVR": {
            return {
                ...DVRs,
                dvrList: [...DVRs.dvrList, action.payload]
            }
        }

        case "CLEAR_SELECTED": {
            return {
                ...DVRs,
                selectedDvr: {},
                viewDvr: {}
            }
        }

        case "updateViewDVR": {
            return {
                ...DVRs,
                viewDvr: action.payload
            }
        }

        case "deleteDVR": {
            return {
                ...DVRs, dvrList: DVRs.dvrList.filter((ele) => ele._id !== action.payload)
            }
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const initialState = {
    dvrList: [],
    selectedDvr: {},
    viewDvr: {}
};
