import { Box, Chip, IconButton, Stack, Tooltip } from "@mui/material"
import Secondary from "../../../components/secondary/Secondary"
import Datatable from "../../../components/dataTable/Datatable"
import Loader from "../../../components/loading/Loader"
import moment from "moment"
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { GET } from "../../../helpers/http"
import { useEffect, useState } from "react"
import Modal from "../../../components/modals/NewModal"
import PPMUpdate from "../../../features/plannedPreventativeMaintenance/PPMUpdate"
import EditPPMModal from "../../../features/plannedPreventativeMaintenance/EditPPMModal"
import { DeleteOutlined } from '@mui/icons-material'
import DeletePPMModal from "../../../features/plannedPreventativeMaintenance/DeletePPMModal"
import { statusColors } from "../../../features/plannedPreventativeMaintenance/colorConstants"

const PPMTaskList = () => {
    const { id } = useParams()
    const location = useLocation()
    const [PPMTasks, setPPMTasks] = useState([])
    const [editOptionModalOpen, setEditOptionModalOpen] = useState(false)
    const [editOptions, setEditoptions] = useState({})
    const [selectedUpdatePPMOption, setSelectedUpdatePPMOption] = useState("")
    const [updatingPPM, setupdatingPPM] = useState("")
    const [EditPPMModalFormOpen, setEditPPMModalFormOpen] = useState(false)

    //delete
    const [deletingPPMId, setDeletingPPMId] = useState(null)
    const [openDeletePPMModal, setOpenDeletePPMModal] = useState(false)
    const navigate = useNavigate()


    const fetchTaskList = async ({ id }) => {
        try {
            const bid = localStorage.getItem('bid')
            const tasksListUrl = `${process.env.REACT_APP_URL}ppm/v1/task/grouped-tasks?id=${id}&buildingId=${bid}`
            const { data = {}, status } = await GET(tasksListUrl, 'Get PPM Task List')
            if (status === 200) {
                setPPMTasks(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchTaskList({ id })
    }, [])

    const triggerList = ({ id }) => {
        if (id) {
            navigate(`/ppm/view/${id}`, { replace: true })
            fetchTaskList({ id })
        } else {
            fetchTaskList({ id })
        }
    }

    const handleEditPPM = async ({ ppmId, isRecursive }) => {
        try {
            const updateOptionsUrl = `${process.env.REACT_APP_URL}ppm/v1/update/options?type=update`
            const { data = {}, status } = await GET(updateOptionsUrl, 'PPM Create Form Vendor Assignee Options')

            if (status === 200) {
                if (isRecursive) {
                    setEditoptions(data)
                    setEditOptionModalOpen(true)
                    setupdatingPPM(ppmId)
                } else {
                    setEditPPMModalFormOpen(true)
                    setupdatingPPM(ppmId)
                }
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const updateOptionPPM = async () => {
        try {
            setEditOptionModalOpen(false)
            setEditPPMModalFormOpen(true)
        } catch (error) {
            console.log({ error })
        }
    }

    const handleDeletePPMClick = (id) => {
        setDeletingPPMId(id)
        setOpenDeletePPMModal(true)
    }

    const capitalizeFirst = (word) => {
        if (!word) { // Check if the word is empty
            return "";
        }
        return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);
    }

    const returnStatus = (status) => {
        const state = status?.toLowerCase()

        return (
            <Chip size="small"
                sx={{
                    width: "100%",
                    border: `solid 1px ${statusColors[state].br}`,
                    backgroundColor: statusColors[state].bg,
                    "& .MuiChip-label": {
                        color: statusColors[state].br,
                        fontSize: "13px"
                    }
                }}
                label={capitalizeFirst(status)} />
        )
    }

    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            headerAlign: 'left',
            align: 'left',
            width: 320,
            editable: false,
            renderCell: (params) => {
                return <Box sx={{ pl: 1 }}>{capitalizeFirst(params.row.title) || ''}</Box>
            }
        },
        {
            field: 'taskStatus',
            headerName: 'Task Status',
            headerAlign: 'left',
            align: 'left',
            width: 110,
            editable: false,
            renderCell: (params) => {
                return returnStatus(params.row?.taskStatus) || ''
            }
        },
        {
            field: 'assignmentStatus',
            headerName: 'Assignment Status',
            headerAlign: 'left',
            align: 'left',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row?.assignmentStatus) || ''} `
            }
        },
        {
            field: 'frequency',
            headerName: 'Frequency',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.recursiveConfig.frequency) || '-'}`
            }
        },
        {
            field: 'interval',
            headerName: 'Interval',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${params.row.recursiveConfig.interval || '-'}`
            }
        },
        {
            field: 'date',
            headerName: 'Date',
            headerAlign: 'left',
            align: 'left',
            width: 120,
            editable: false,
            valueGetter: (params) => {
                return `${params.row.date}`
            }
        },
        {
            field: 'startTime',
            headerName: 'Start Time',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.startTime).format("h:mm A")
                    }`
            }
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${moment(params.row.endTime).format("h:mm A")
                    }`
            }
        },
        {
            field: 'category',
            headerName: 'Category',
            headerAlign: 'left',
            align: 'left',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.category?.name) || '-'} `
            }
        },
        {
            field: 'priority',
            headerName: 'Priority',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: false,
            valueGetter: (params) => {
                return `${capitalizeFirst(params.row.priority) || '-'} `
            }
        },
        {
            field: 'Actions',
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1.5}>
                        <Tooltip title='VIEW' placement='top'>
                            <IconButton
                                onClick={() => navigate(`/ppm/task/${cellValues.row._id}`,
                                    { state: { hisoryRoute: location?.pathname } }
                                )}
                            >
                                <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='DELETE' placement='top'>
                            <IconButton
                                onClick={() => handleDeletePPMClick(cellValues?.row?._id)}
                            >
                                <DeleteOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                        {!moment(cellValues?.row?.date).isBefore(moment().startOf('day')) && <Tooltip title='EDIT' placement='top'>
                            <IconButton
                                onClick={() => handleEditPPM({ ppmId: cellValues.row._id, isRecursive: cellValues.row.isRecursive })}>
                                <EditOutlined fontSize="small" sx={{ color: '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>}
                    </Stack>
                )
            }
        },]
    return (
        <>
            <Box width={"100%"} paddingLeft="1rem">
                <Secondary
                    title={"PPM Tasks"}
                    returnPage={"PPM List"}
                    returnRoute={"/ppm/list"}
                    children={
                        <>
                            {!PPMTasks?.isLoaded ? <Datatable disableGridToolBar rows={PPMTasks || []} columns={tableColumns} /> : <Loader height={700} />}
                        </>
                    }
                />
            </Box>
            <Modal
                modalOpen={editOptionModalOpen}
                closeModal={() => setEditOptionModalOpen(false)}
                title={'Update PPM'}
            >
                <PPMUpdate
                    selectedUpdatePPMOption={selectedUpdatePPMOption}
                    setSelectedUpdatePPMOption={setSelectedUpdatePPMOption}
                    updatePPMOptions={editOptions}
                    updatePPM={updateOptionPPM}
                />
            </Modal>
            <EditPPMModal
                triggerList={triggerList}
                openUpdatePPMModal={EditPPMModalFormOpen}
                handleCloseUpdatePPMModal={() => setEditPPMModalFormOpen(false)}
                editingPPMId={updatingPPM}
                selectedUpdatePPMOption={selectedUpdatePPMOption}
            />
            <DeletePPMModal
                triggerList={triggerList}
                openDeletePPMModal={openDeletePPMModal}
                handleCloseDeletePPMModal={() => setOpenDeletePPMModal(false)}
                deletingPPMId={deletingPPMId}
            />
        </>
    )
}

export default PPMTaskList
