export const chatReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CHAT_RUNNING': {
            return { ...state, chatRunning: action.payload }
        }
        case 'SET_INTERFACE_CHAT': {
            return { ...state, interfaceChat: action.payload }
        }
        case 'NEW_INTERFACE_CHAT': {
            return { ...state, interfaceChat: { conversation: [...action.payload] } }
        }
        case 'ADD_INTERFACE_CHAT': {
            return {
                ...state, interfaceChat: {
                    ...state.interfaceChat,
                    conversation: state.interfaceChat?.conversation?.map(el => {
                        return el.role === 'assistant' && el.content === 'loading' ? action.payload : el
                    })
                }
            }
        }
        case 'SET_VOICE_RUNNING': {
            return { ...state, voiceCommand: action.payload }
        }
        case "SET_CHAT_HISTORY": {
            return { ...state, chatHistory: action.payload }
        }
        case "SET_NEW_CHAT_ID": {
            return { ...state, newChatId: action.payload }
        }
        case 'SET_POPULATED_QUESTION': {
            return { ...state, populatedQuestion: action.payload }
        }
        default: return state
    }
}