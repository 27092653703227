import { Box, Typography, Stack, Grid, Paper, Divider } from "@mui/material"
import Widget from "../widgets/Widget"

import NoDataFound from "../noDataFound/NoDataFound"
import CameraRecording from "../../features/cctv/Sections/CameraRecordingComp"
import DVR from "../../features/cctv/Sections/DVRComp"
import HardDrive from "../../features/cctv/Sections/HardDriveComp"
import { useSelector } from "react-redux"
import WidgetDVR from "../widgets/WidgetDVR"
import moment from "moment"
import { memo } from "react"
import ImageRenderer from "../image/ImageRenderer"
import { cameraConnected, cameraConnectedInverted, cameraDisconnected, cameraDisconnectedInverted, dvrIcon, dvrInverted, hddConnected, hddConnectedInverted, hddDisconnected, hddDisconnectedInverted, lastSync, syncInverted } from "../../../../data/imageUrls"

const DVRDashboard = (props) => {
    const { loading, errorMessage, dvr, hardDrives, cameras } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const timeStamp = moment(dvr?.apiTimeStamp).format('lll')


    return (
        <Grid container item xs={12} spacing={1} direction="row" m={1} ml={0} mt={0} pr={2} width={'100%'} >
            <Grid item xs={4}>
                <Stack spacing={1}>
                    {
                        Object.keys(dvr)?.length > 0 ?
                            <Widget
                                isLoaded={!loading}
                                body={<DVR data={dvr} />}
                                service='video'
                                title={<Typography component={'span'} fontWeight={700} ml={2} lineHeight={2}>DVR</Typography>}
                                icon={<Box mt={-1} mr={2}>{themeMode ? <ImageRenderer src={dvrInverted.src} alt={dvrInverted.alt} /> : <ImageRenderer src={dvrIcon.src} alt={dvr.alt} />}</Box>}
                                helperText={
                                    <Typography component={'span'} fontWeight={400} ml={4} lineHeight={2}>
                                        {themeMode ? <ImageRenderer src={syncInverted.src} alt={syncInverted.alt} /> : <ImageRenderer src={lastSync.src} alt={lastSync.alt} />}
                                        <Typography component={'span'} p={1} >{timeStamp}
                                        </Typography>
                                    </Typography>}
                            />
                            : <Widget
                                isLoaded={!loading}
                                service='video'
                                title={<Typography component={'span'} fontWeight={700} ml={2} lineHeight={2}>DVR</Typography>}
                                icon={<Box mt={-1} mr={2}>{<ImageRenderer src={dvrIcon.src} alt={dvr.alt} />}</Box>}
                                body={<NoDataFound height={'20vh'} message={errorMessage} />}
                            />
                    }
                    <Paper elevation={0} sx={{ backgroundColor: themeMode ? '#282a2e' : '#fff' }}>
                        <Typography fontWeight={700} mt={1} ml={2} lineHeight={2}>HARD DRIVES</Typography>
                        <Divider sx={{ mb: 1, borderColor: color, mt: 1 }} />
                        {hardDrives?.length > 0 ? hardDrives.map(hd => {
                            return <WidgetDVR
                                key={hd?.name}
                                isLoaded={!loading}
                                body={<HardDrive data={hd} errorMessage={errorMessage} />}
                                service='video'
                                // status={hd?.name}
                                title={<Typography component={'span'} fontWeight={700} ml={2} lineHeight={2}>{hd?.name.toUpperCase()}</Typography>}
                                icon={<Box mt={-1} mr={1.5}>{hd?.status?.name === "ok"
                                    ?
                                    themeMode ? <ImageRenderer src={hddConnectedInverted.src} alt={hddConnectedInverted.alt} /> : < ImageRenderer src={hddConnected.src} alt={hddConnected.alt} />
                                    :
                                    themeMode ? <ImageRenderer src={hddDisconnectedInverted.src} alt={hddDisconnectedInverted.alt} /> : <ImageRenderer src={hddDisconnected.src} alt={hddDisconnected.alt} />}</Box>}
                            />
                        })
                            :
                            <Widget
                                isLoaded={!loading}
                                service='video'
                                icon={<Box mt={-1} mr={2}>{themeMode ? <ImageRenderer src={hddDisconnectedInverted.src} alt={hddDisconnectedInverted.alt} /> : <ImageRenderer src={hddDisconnected.src} alt={hddDisconnected.alt} />}</Box>}
                                body={<NoDataFound height={'20vh'} message={errorMessage} />}
                            />}
                    </Paper>
                </Stack>
            </Grid>
            <Grid item container xs={8} spacing={1}>
                {
                    cameras?.length > 0 ?
                        cameras?.map((camera, i) => (
                            <Grid item xs={5} md={5} lg={4} xl={3} key={i}>
                                <Widget
                                    isLoaded={!loading}
                                    service='video'
                                    // status={camera?.name} //new
                                    body={<CameraRecording data={camera} />}
                                    title={<Typography component={'span'} fontWeight={700} ml={2} lineHeight={2}>{camera?.name}</Typography>}
                                    icon={<Box mt={-1} mr={2}> {camera?.cameraStatus === "inactive"
                                        ?
                                        themeMode ? <ImageRenderer src={cameraDisconnectedInverted.src} alt={cameraDisconnectedInverted.alt} /> : < ImageRenderer src={cameraDisconnected.src} alt={cameraDisconnected.alt} />
                                        :
                                        themeMode ? <ImageRenderer src={cameraConnectedInverted.src} alt={cameraConnectedInverted.alt} /> : <ImageRenderer src={cameraConnected.src} alt={cameraConnected.alt} />} </Box>}
                                    minHeight={'100%'}
                                />
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <Widget
                                isLoaded={!loading}
                                service='video'
                                title={<Typography component={'span'} fontWeight={700} ml={2} lineHeight={2}>Cameras</Typography>}
                                icon={<Box mt={-1} mr={2}>{themeMode ? <ImageRenderer src={cameraDisconnectedInverted.src} alt={cameraDisconnectedInverted.alt} /> : < ImageRenderer src={cameraDisconnected.src} alt={cameraDisconnected.alt} />}</Box>}
                                body={<NoDataFound height={'100%'} message={errorMessage} />}
                            />
                        </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default memo(DVRDashboard)
