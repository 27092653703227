import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddGroupModal from '../../../features/settings/group/components/modals/AddGroupModal'
import { DeleteOutlined, EditOutlined, ExpandMore, SearchOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import CommonStyles from '../../../styles/CommonStyles'
import { GET } from '../../../helpers/http'
import DeleteGroupModal from '../../../features/settings/group/components/modals/DeleteGroupModal'
import EditGroupModal from '../../../features/settings/components/modals/EditGroupModal'
import Loading from '../../../components/loading/Loading'
import GroupsStyles from '../../../styles/GroupsStyles'
import validSearchTerm from '../../../helpers/validSearchTerm'

const Groups = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const color = themeMode ? '#fff' : '#000'

    const [openAddGroupModal, setOpenAddGroupModal] = useState(false)
    const [openEditGroupModal, setOpenEditGroupModal] = useState(false)
    const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false)
    const [expandedPanel, setExpandedPanel] = useState(null);
    const [editingGroup, setEditingGroup] = useState({})
    const [deletingGroup, setDeletingGroup] = useState({})
    const [searchTerm, setSearchTerm] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [timer, setTimer] = useState(0)

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleChange = (panelId) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panelId : null);
    };

    const handleOpenAddGroupModal = () => {
        setOpenAddGroupModal(true)
    }

    const handleCloseAddGroupModal = () => {
        setOpenAddGroupModal(false)
    }

    const handleOpenEditGroupModal = () => {
        setOpenEditGroupModal(true)
    }

    const handleCloseEditGroupModal = () => {
        setEditingGroup({})
        setOpenEditGroupModal(false)
    }

    const handleOpenDeleteGroupModal = () => {
        setOpenDeleteGroupModal(true)
    }

    const handleCloseDeleteGroupModal = () => {
        setOpenDeleteGroupModal(false)
    }

    const [groups, setGroups] = useState([])

    useEffect(() => {
        if (isLoaded) setIsLoaded(false)
        const endpoint = searchTerm?.length >= 2 ? `${process.env.REACT_APP_BASE_URL}um/v1/admin/group/list?searchTerm=${validSearchTerm(searchTerm)}` : `${process.env.REACT_APP_BASE_URL}um/v1/admin/group/list`
        debounce(async function fetchData() {
            try {
                const response = await GET(endpoint)
                setGroups([...response?.data])
                setIsLoaded(true)
            } catch (error) {
                console.log(error)
                setIsLoaded(true)
            }
        }, 300)
    }, [setGroups, searchTerm])


    const handleDeleteGroup = (groupId) => {
        const selectedGroup = groups.find(group => group?._id === groupId)
        setDeletingGroup(selectedGroup)
        handleOpenDeleteGroupModal()
    }

    const handleEditGroup = (groupId) => {
        const selectedGroup = groups.find(group => group?._id === groupId)
        setEditingGroup(selectedGroup)
        handleOpenEditGroupModal()
    }

    const handleUpdateGroups = (updatedGroup) => {
        const editedGroups = groups?.map(group => {
            if (group?._id === updatedGroup?._id) {
                return { ...group, ...updatedGroup }
            }
            return { ...group }
        })
        setGroups(editedGroups)
    }

    return (
        <Box sx={GroupsStyles.boxContainer} className='minHeight90vh'>
            <Typography
                sx={GroupsStyles.headingTypo}
            >
                Groups
            </Typography>
            <Box sx={{
                ...GroupsStyles.paperBoxContainer,
                bgcolor,
            }} className='theme-cards-boxShadow bdrRadius4px'>
                <Grid container direction={'row-reverse'} spacing={1} >
                    <Grid item xs={1.5} border={'solid transparent'} > {/* for button alignment */}
                        <Button onClick={handleOpenAddGroupModal} className='btnFontSize' sx={GroupsStyles.addButton}>Add group</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            size="small"
                            value={searchTerm}
                            onChange={onSearchChange}
                            sx={GroupsStyles.searchfield}
                            placeholder="Search a Group by name or code.."
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchOutlined sx={{ color: '#616161' }} />
                                    </InputAdornment>
                                ),
                                sx: {
                                    // Customize the border color
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#808080',
                                    },
                                    // Customize the focused border color
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#808080',
                                    },
                                    // Customize the input text color
                                    '& .MuiInputBase-input': {
                                        color: themeMode ? '#fff' : '#000'
                                    },
                                    // Customize the cursor color
                                    '& .MuiInputBase-input::selection': {
                                        backgroundColor: '#808080',
                                    },
                                    // Customize the border color on hover
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#808080',
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <AddGroupModal
                openAddGroupModal={openAddGroupModal}
                handleCloseAddGroupModal={handleCloseAddGroupModal}
                groups={groups}
                setGroups={setGroups}
            />
            {
                isLoaded ? (
                    groups?.length > 0 ? (
                        <Grid container spacing={2} width={'100%'}>
                            {
                                groups.map((group) => (
                                    <Grid key={group?._id} item xs={4} >
                                        <TableContainer component={Paper} sx={{
                                            bgcolor,
                                        }}
                                            className='theme-cards-boxShadow theme-border'>
                                            <Table
                                                sx={{
                                                    // width: "100%",
                                                    "& .MuiTableCell-head": {
                                                        fontWeight: 700,
                                                        bgcolor,
                                                        color
                                                        // minWidth: 500
                                                    },
                                                    "& .MuiTableCell-body": {
                                                        color
                                                        // minWidth: 500
                                                    }
                                                }}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                                        <TableCell align="left" sx={{ fontWeight: 'bold', color }}>Group Code</TableCell>
                                                        <TableCell align="left" sx={{ fontWeight: 'bold', color }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{group?.name}</TableCell>
                                                        <TableCell>{group?.groupCode}</TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', columnGap: 2 }}>
                                                                <Tooltip title="Edit Group">
                                                                    <IconButton size='small' onClick={() => handleEditGroup(group?._id)}>
                                                                        <EditOutlined color='primary' fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Remove Group">
                                                                    <IconButton size='small' onClick={() => handleDeleteGroup(group?._id)} disabled>
                                                                        <DeleteOutlined color='error' fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Accordion
                                                                expanded={expandedPanel === group?._id}
                                                                onChange={handleChange(group?._id)}
                                                                sx={{
                                                                    bgcolor,
                                                                }}
                                                                className='theme-cards-boxShadow'
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore sx={{ color: "grey" }} />}
                                                                >
                                                                    <Typography>Members ({group?.members?.length})</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails
                                                                >
                                                                    <TableContainer
                                                                        component={Card}
                                                                        sx={{
                                                                            width: "100%",
                                                                            bgcolor,
                                                                            maxHeight: '200px',
                                                                            overflow: "auto",
                                                                            ...CommonStyles.scrollStyle
                                                                        }}
                                                                        className='theme-cards-boxShadow'
                                                                    >
                                                                        <Table stickyHeader>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell sx={{ fontWeight: 'bold' }} className='theme-component-background'>Name</TableCell>
                                                                                    <TableCell sx={{ fontWeight: 'bold' }} className='theme-component-background'>Type</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {group?.members.map((feature) => (
                                                                                    <TableRow key={feature.email}>
                                                                                        <TableCell>{feature.email}</TableCell>
                                                                                        <TableCell>{feature.type}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    ) : (
                        <Box sx={GroupsStyles.loaderBox} >
                            <Typography>No Groups.</Typography>
                        </Box>
                    )
                ) : (
                    <Box sx={GroupsStyles.loaderBox} >
                        <Loading />
                    </Box>
                )
            }
            <DeleteGroupModal
                openDeleteGroupModal={openDeleteGroupModal}
                handleCloseDeleteGroupModal={handleCloseDeleteGroupModal}
                groups={groups}
                setGroups={setGroups}
                deletingGroup={deletingGroup}
            />
            <EditGroupModal
                openEditGroupModal={openEditGroupModal}
                handleCloseEditGroupModal={handleCloseEditGroupModal}
                handleUpdateGroups={handleUpdateGroups}
                editingGroupId={editingGroup?._id}
            />
        </Box >
    )
}

export default Groups