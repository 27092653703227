import { Box, Button, Card, Skeleton, Typography } from "@mui/material"
import postStatusBarStyle from "../../../../../styles/wall/contentReviewBody/PostStatusBarStyle"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import { ThemeColors } from "../../../../../hooks/ThemeColors"
import moment from "moment";
import { errorToast, successToast } from "../../../../../helpers/apiToast";
import { PUT } from "../../../../../helpers/http";

const PostStatusBar = ({ loading, data, postDataUpdater }) => {
    const { background_color } = ThemeColors()

    const handlePost = async (action) => {
        try {
			const deletePostResponse = await PUT(`${process.env.REACT_APP_URL}comms/v1/admin/actions`,
                {
                    "id": data?._id,
					"type": "post",
					"communityId": data?.community,
                    "action": action
                },
                "Admin: Delete/Restore Post")
            postDataUpdater(deletePostResponse?.data)
            successToast(`Post hast been ${action}ed`)
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    return (
        <>
            {!loading
                ?
                <Card
                    sx={{ ...postStatusBarStyle.card, backgroundColor: background_color }}>
                    <Box sx={{ ...postStatusBarStyle.typoBox }} >
                        <Typography fontSize={18}>Author: <b>{data?.user?.fullName}</b></Typography>
                        <Typography fontSize={18}>Date & Time: <b>{moment(data?.createdAt).format('L')} & {moment(data?.createdAt).format('LT')} </b></Typography>
                        <Typography fontSize={18}>Flag Count: <b>{data?.flagCount}</b></Typography>
                    </Box>
                    <Box>
                        {!data?.isDeleted
                            ?
                            <Button sx={{ ...postStatusBarStyle.deleteButton }} onClick={() => handlePost("delete")}>
                                Delete Post
                                <DeleteOutlineIcon sx={{ marginLeft: "0.3rem", fontSize: "20px" }} />
                            </Button>
                            :
                            <Button sx={{ ...postStatusBarStyle.deleteButton }} onClick={() => handlePost("restore")}>
                                Restore
                                <RestoreIcon sx={{ marginLeft: "0.3rem", fontSize: "20px" }} />
                            </Button>
                        }
                    </Box>
                </Card >
                :
                <Card sx={{ ...postStatusBarStyle.card, backgroundColor: background_color }}>
                    <Skeleton variant="text" sx={{ width: "30%", marginLeft: "1rem" }} />
                    <Skeleton variant="text" sx={{ width: "10%", marginRight: "1rem" }} />
                </Card>
            }
        </>
    )
}

export default PostStatusBar