import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import './App.css';
import { CssBaseline } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import axios from 'axios';

import { GET, errorHandler } from './components/facilityManager/helpers/http';

import Loader from './components/facilityManager/components/loading/Loader';
import { setUserDetails } from './redux/features/userSlice';
import { setAppIsLoaded } from './redux/features/buildingsSlice';
import Router from './routes/Router';

function App() {
    const themeMode = useSelector(state => state.theme.darkTheme)

    const { appIsLoaded } = useSelector(state => state.buildings)
    const { companyName } = useSelector(state => state.configurations)

    const [csrfLoaded, setCsrfLoaded] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const dispatcher = useCallback((user) => {
        dispatch(setUserDetails(user))
        dispatch(setAppIsLoaded(true))
    }, [dispatch])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/csrf`, { withCredentials: true })
            .then((response) => {
                if (response?.status === 200) {
                    localStorage.setItem('csrfToken', response?.data?.csrf_token)
                    setCsrfLoaded(true)
                }
            })
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        if (location.search?.includes("?exchange_token=")) {
            const queryParams = new URLSearchParams(location.search);
            const exchange_token = queryParams.get("exchange_token");
            const exchange_id = queryParams.get("exchange_id");


            const decodedExchangeToken = decodeURIComponent(exchange_token)
            const decodedExchangeId = decodeURIComponent(exchange_id)

            if (decodedExchangeToken && decodedExchangeId) {
                //move code into action
                axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/user/token`, {
                    headers: { 'exchange-token': decodedExchangeToken, 'exchange-id': decodedExchangeId },
                    withCredentials: true
                }).then(response => {
                    const defaultPath = response?.data?.defaultPath || ""
                    if (response?.status === 200) {
                        GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, 'Get User Details').then(response => {
                            // console.log({ response: response.data })
                            if (response.data?.success) {
                                dispatcher(response?.data?.user)
                                //default path set
                                if (defaultPath) navigate(`${defaultPath}`)
                                navigate("/")
                            } else {
                                window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                            }
                        })
                            .catch((err) => {
                                errorHandler(err)
                                if (err.response?.status === 403) {
                                    window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                                } else {
                                    console.log(err)
                                }

                            })
                    } else {
                        // console.log({ response: response.data })
                        window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                    }

                }).catch((err) => {
                    //handle 401 status
                    if (err.response?.status === 401) {
                        window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                    } else {
                        console.log(err)
                    }
                })


            }
        } else {
            //move code into action
            GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, 'Validate User')
                .then(response => {
                    // console.log({ response: response.data })
                    if (response.data?.success) {
                        dispatcher(response?.data?.user)
                    } else {
                        window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 403) {
                        window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                    } else {
                        console.log(err)
                    }
                })
        }

    }, [location.search, dispatcher])

    useEffect(() => {
        document.title = companyName || 'Nhance Twin'
    }, [companyName])

    const handleContextMenu = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu)

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu)  //After event handler is executed,remove it to prevent memeory leaks
        }
    }, [])

    //clickJacking prevention - frameBusting code
    useEffect(() => {
        if (window !== window.top) {
            window.top.location = window.location
        }

        // window.addEventListener('online', () => {
        //     console.log('online')
        // });

        // window.addEventListener('offline', () => {
        //     console.log('offline')
        // });
    }, [])

    useEffect(() => {
        return () => {
            // clearing the local storage when app unmounts 
            localStorage.clear();
        };
    }, []);

    return (
        <div className={themeMode ? "dark" : "light"}>
            <CssBaseline />
            {(appIsLoaded && csrfLoaded) ? (
                <>
                    <Router />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                        theme="colored"
                    />
                </>
            ) : (<Loader height={"100vh"} />)}
        </div>
    )
}

export default App;