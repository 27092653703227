import { GET } from "../../../../facilityManager/helpers/http"

export const UPDATE_DASHBOARD_BOOKED_DESKS_TATA_DIGITAL = "UPDATE_DASHBOARD_BOOKED_DESKS_TATA_DIGITAL"


const fetchBookedDesksTataDigital = (payload) => {
    return {
        type: UPDATE_DASHBOARD_BOOKED_DESKS_TATA_DIGITAL,
        payload
    }
}

export const asyncFetchBookedDesksOccupancyDataTataDigital = (bid) => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_BASE_URL}db/v1/bookings/data?building-id=${bid}`)
            .then((response) => {
                const status = response.status
                if (status === 200) {
                    const result = response.data
                    dispatch(fetchBookedDesksTataDigital(result))
                }
            })
            .catch(error => console.log(error.message))
    }
}
