import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";
import moment from "moment";

const currentDate = moment().format("YYYY-MM-DD")

const time = new Date().getHours()

const inFirstHalf = time < 12 ? true : false

const inSecondHalf = time > 12 ? true : false

const fetchDesksSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchDashboardDeskBookingChartData: builder.query({
            query: (buildingId) => ({
                url: `db/v1/bookings?frequency=hourly&date=${currentDate}&bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Booking Chart Data')
            }
        }),
        fetchDashboardDeskOccupancyCountData: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/desk/s2/stats?bid=${buildingId}&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Occupancy Count Data')
            }
        }),
        fetchDashboardDeskOccupancyChartData: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/desk/s1/data?interval=hourly&bid=${buildingId}&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Occupancy Chart Data')
            }
        }),
        fetchSecondaryDeskBookingStatistics: builder.query({
            query: ({ date, buildingId }) => ({
                url: `db/v1/bookings/statistics?date=${date}&bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Booking Secondary Statstics')
            }
        }),
        fetchSecondaryDeskOccupancyStatistics: builder.query({
            query: (buildingId) => ({
                url: `twin/v1/desk/s2/stats?bid=${buildingId}&duration=today`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Occupancy Secondary Statstics')
            }
        }),
        fetchSecondaryDesksOptions: builder.query({
            query: (buildingId) => ({
                url: `db/v1/options?bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Booking Secondary Options')
            }
        }),
        fetchSecondaryDeskOccupancyOptions: builder.query({  //fetch options from twin if db is disabled
            query: (buildingId) => ({
                url: `twin/v1/desk/options?bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desk Occupancy Secondary Options')
            }
        }),
        fetchSecondaryBookedChartData: builder.query({
            query: ({ duration, buildingId, floorId, startDate, endDate }) => ({
                url: `db/v1/bookings?time=${new Date(new Date().setHours(19, 0, 0)).getTime()}&duration=${duration}&bid=${buildingId}&floor-id=${floorId}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desks Secondary Booked Chart Data')
            }
        }),
        fetchSecondaryOccupiedChartData: builder.query({
            query: ({ duration, buildingId, floorId, zoneId, startDate, endDate }) => ({
                url: `twin/v1/desk/s2/data?zoneid=${zoneId}&bid=${buildingId}&floorid=${floorId}&duration=${duration}&time=${Date.now()}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData, meta, arg) => {
                return { data: responseData, zone: arg?.zoneId }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desks Secondary Occupied Chart Data')
            }
        }),
        fetchDesksFloorViewMap: builder.query({
            query: ({ zoneId, floorId }) => ({
                url: `db/v1/desks/?app-type=dashboard&zone-id=${zoneId}${floorId ? `&floor-id=${floorId}` : ''}&selected-date=${currentDate}&first-half=${inFirstHalf}&second-half=${inSecondHalf}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desks Floor View Map')
            }
        }),
        fetchDesksFloorViewOccupancyData: builder.query({
            query: ({ buildingId, zoneId, floorId }) => ({
                url: `twin/v1/desk/latest/data?bid=${buildingId}&zoneid=${zoneId}${floorId ? `&floorid=${floorId}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Desks Floor View Occupancy Data')
            }
        }),

        // Desk Occupancy New Screens API for Fidelity and all other tenants
        fetchSecondaryDeskOccupancyLatestDataStatistics: builder.query({
            query: ({ buildingId, floorId, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/statistics?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Latest Data Statistics')
            }
        }),
        fetchSecondaryDeskOccupancyTodayFloorwiseChartData: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/floor-wise/statistics?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Floorwise Chart Data')
            }
        }),
        fetchSecondaryDeskOccupancyTodayChartData: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/occupancy?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData) => {
                const formattedData = responseData?.data?.map(ele => ({
                    ...ele,
                    name: `${ele?.name}${ele?.zoneName ? ` - ${ele?.zoneName}` : ''} ${ele.totalDesks ? `(Total Desks: ${ele.totalDesks})` : ''}`,
                }))
                return { ...responseData, data: formattedData }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Today Chart Data')
            }
        }),
        fetchSecondaryDeskOccupancyAverageUtilization: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/average/utilization?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Average Utilization')
            }
        }),
        fetchSecondaryDeskOccupancyAverageWeekUtilization: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/average/utilization/weekly?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData) => {
                const formattedData = responseData?.data?.map(ele => ({
                    ...ele,
                    name: `${ele.name}${ele?.zoneName ? ` - ${ele?.zoneName}` : ''} ${ele.maxHours ? `(Max Hours: ${ele.maxHours})` : ''}`,
                }))
                return { ...responseData, data: formattedData }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Average Week Utilization')
            }
        }),
        fetchSecondaryDeskOccupancyAverageOccupancy: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/average/occupancy?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Average Occupancy')
            }
        }),
        fetchSecondaryDeskOccupancyAverageWeekOccupancy: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/average/occupancy/weekly?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData) => {
                const formattedData = responseData?.data?.map(ele => ({
                    ...ele,
                    name: `${ele.name}${ele?.zoneName ? ` - ${ele?.zoneName}` : ''} ${ele.totalDesks ? `(Total Desks: ${ele.totalDesks})` : ''}`,
                }))
                return { ...responseData, data: formattedData }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Average Week Occupancy')
            }
        }),
        fetchSecondaryDeskOccupancyPeakStats: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate }) => ({
                url: `twin/v1/desk/s2/peak_stats?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Peak Stats')
            }
        }),
        fetchSecondaryDeskStatus: builder.query({
            query: ({ buildingId, floorId, zoneId }) => ({
                url: `db/v1/desk/status?buildingId=${buildingId}&floor=${floorId}&zone=${zoneId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desks Status')
            }
        }),
        fetchSecondaryDeskOccupancyDeskStatus: builder.query({
            query: ({ buildingId, floorId, zoneId }) => ({
                url: `twin/v1/desk/status?bid=${buildingId}&floor=${floorId}&zone=${zoneId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Secondary Desk Occupancy Desks Status')
            }
        }),
        fetchSecondaryDeskOccupancyUsageHeatmapData: builder.query({
            query: ({ buildingId, floorId, startTime, endTime, excludeWeekend, zoneId, selectedDate, heatMapSelectedDateOnly }) => ({
                url: `twin/v1/desk/s2/heatmap/hourly?bid=${buildingId}&floor=${floorId}&zone=${zoneId}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&date=${selectedDate}&selectedDateOnly=${!!heatMapSelectedDateOnly}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Secondary Desk Occupancy Usage Heatmap')
            },
        }),
    })
})

export const {
    useFetchDashboardDeskBookingChartDataQuery,
    useFetchDashboardDeskOccupancyCountDataQuery,
    useFetchDashboardDeskOccupancyChartDataQuery,
    useFetchSecondaryDeskBookingStatisticsQuery,
    useFetchSecondaryDeskOccupancyStatisticsQuery,
    useFetchSecondaryDesksOptionsQuery,
    useFetchSecondaryDeskOccupancyOptionsQuery,
    useFetchSecondaryBookedChartDataQuery,
    useFetchSecondaryOccupiedChartDataQuery,
    useFetchDesksFloorViewMapQuery,
    useFetchDesksFloorViewOccupancyDataQuery,

    // Desk Occupancy New Screens API for Fidelity and all other tenants
    useFetchSecondaryDeskOccupancyLatestDataStatisticsQuery,
    useFetchSecondaryDeskOccupancyTodayFloorwiseChartDataQuery,
    useFetchSecondaryDeskOccupancyTodayChartDataQuery,
    useFetchSecondaryDeskOccupancyAverageUtilizationQuery,
    useFetchSecondaryDeskOccupancyAverageWeekUtilizationQuery,
    useFetchSecondaryDeskOccupancyAverageOccupancyQuery,
    useFetchSecondaryDeskOccupancyAverageWeekOccupancyQuery,
    useFetchSecondaryDeskOccupancyPeakStatsQuery,
    useFetchSecondaryDeskStatusQuery,
    useFetchSecondaryDeskOccupancyDeskStatusQuery,
    useFetchSecondaryDeskOccupancyUsageHeatmapDataQuery,
} = fetchDesksSlice

const initialState = {
    dashboard: {
        deskBooking: {
            isLoaded: false,
            error: "",
            booked: [],
            margins: [10, 0, 25, 30]
        },
        deskOccupancy: {
            isLoaded: false,
            error: "",
            data: [],
            activeSensors: [],
            apiToggle: false,
            occupied: 'NA'
        }
    },
    secondary: {
        availableDesksToday: 0,
        bookedToday: 0,
        totalDesks: 0,
        error: "",
        statisticsData: {
            data: {},
            isLoaded: false
        },
        occupiedCount: {
            count: 0,
            isLoaded: false
        },
        options: {
            durations: [],
            floors: [],
            hours: [],
            isLoaded: false
        },
        bookedChartData: {
            data: {},
            isLoaded: false
        },
        occupancyChartData: {
            data: {},
            isLoaded: false
        },
        zonewiseTotalDesks: {},
        zonewiseBookedDesks: {},
        selectedFloor: {},
        selectedZone: {},
        selectedFloorAllZonesChartData: {},
        selectedFloorAllZonesChartDataIsLoaded: false,
        margins: [15, 10, 25, 30],

        //states for new screens of desk occupancy for fidelity and all other tenants
    },
    secondaryFloorView: {
        desks: {
            data: [],
            desksIsLoaded: false,
            desksUpdated: false
        },
        floors: [],
        selectedFloor: {},
        selectedZone: {},
        selectedZoneMap: {},
        selectedZoneOccupancyData: {},
    }
}

const desksSlice = createSlice({
    name: 'desks',
    initialState,
    reducers: {
        resetDashboardDesks: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        updateSelectedFloor: (state, action) => {
            state.secondary.selectedFloor = action.payload
            state.secondary.occupancyChartData.isLoaded = false
            state.secondary.selectedFloorAllZonesChartDataIsLoaded = false
            state.secondary.selectedFloorAllZonesChartData = initialState.secondary.selectedFloorAllZonesChartData
        },
        updateSelectedZone: (state, action) => {
            state.secondary.selectedZone = action.payload
            state.secondary.occupancyChartData.isLoaded = false
        },
        updateFloorViewSelectedFloor: (state, action) => {
            state.secondaryFloorView.desks.desksIsLoaded = false
            state.secondaryFloorView.selectedFloor = action.payload
        },
        updateFloorViewSelectedZone: (state, action) => {
            state.secondaryFloorView.desks.desksIsLoaded = false
            state.secondaryFloorView.selectedZone = action.payload
        },
        resetFloorViewDesksIsLoaded: (state, action) => {
            state.secondaryFloorView.desks.desksIsLoaded = false
        },
        resetDesksSecondaryData: (state, action) => {
            state.secondary = initialState.secondary
        },
        resetChartIsLoadedonDurationChange: (state, action) => {
            state.secondary.occupancyChartData.isLoaded = false
        },
        updateDeskOccupancySocket: (state, action) => {
            const existingChartData = [...state.dashboard?.deskOccupancy?.data]
            const existingHour = new Date(existingChartData?.[existingChartData?.length - 1]?.[0]).getHours()
            const incomingTime = action.payload?.updatedTime
            const incomingHour = new Date(incomingTime).getHours()

            state.dashboard.deskOccupancy.occupied = action.payload?.occupied
            if (existingHour === incomingHour) {
                existingChartData[existingChartData?.length - 1][1] = action.payload.occupied
                state.dashboard.deskOccupancy.data = existingChartData
            } else if (existingHour < incomingHour) {
                state.dashboard.deskOccupancy.data = [...existingChartData, [incomingTime, action.payload?.occupied]]
            }
        },
        updateDeskOccupancyFloorViewSocket: (state, action) => {
            const desks = state.secondaryFloorView?.desks?.data

            action?.payload?.forEach(ele => {
                const matchingDesk = desks?.find(desk => {
                    return desk?.sensor === ele?.sensorId
                })

                if (matchingDesk) {
                    matchingDesk.isOccupied = ele?.isOccupied
                }
            })
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDashboardDeskBookingChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.deskBooking.isLoaded = true
                    state.dashboard.deskBooking.booked = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDashboardDeskOccupancyCountData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.deskOccupancy.isLoaded = true
                    state.dashboard.deskOccupancy.occupied = action.payload?.occupied
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDashboardDeskOccupancyChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.deskOccupancy.isLoaded = true
                    state.dashboard.deskOccupancy.data = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryDeskBookingStatistics' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.statisticsData.isLoaded = true
                    state.secondary.statisticsData.data = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryDeskOccupancyStatistics' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.occupiedCount.isLoaded = true
                    state.secondary.occupiedCount.count = action.payload?.occupied
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryDesksOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.options.isLoaded = true
                    state.secondary.options.durations = action.payload?.duration
                    state.secondary.options.floors = action.payload?.floors
                    state.secondary.options.hours = [...action.payload?.hours, '11:59 PM'] || []
                    state.secondary.selectedFloor = action.payload?.floors?.[0]
                    state.secondary.selectedZone = action.payload?.floors?.[0]?.zones?.[0]
                    const newFloors = action.payload?.floors?.map(ele => {
                        return {
                            ...ele,
                            zones: [
                                ...ele?.zones,
                                { zone: 'all', zoneName: 'All Zones' }
                            ]
                        }
                    })
                    state.secondaryFloorView.floors = newFloors
                    state.secondaryFloorView.selectedFloor = newFloors?.[0]
                    state.secondaryFloorView.selectedZone = newFloors?.[0]?.zones?.[0]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryDeskOccupancyOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.options.isLoaded = true
                    state.secondary.options.durations = action.payload?.duration
                    state.secondary.options.hours = [...action.payload?.hours, '11:59 PM'] || []
                    state.secondary.options.floors = action.payload?.floors
                    state.secondary.selectedFloor = action.payload?.floors?.[0]
                    state.secondaryFloorView.floors = action.payload?.floors
                    state.secondaryFloorView.selectedFloor = action.payload?.floors?.[0]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryBookedChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const zones = { ...state.bookedChartData?.data }
                    const zonewiseTotalDesks = { ...state.zonewiseTotalDesks }
                    const zonewiseBookedDesks = { ...state.zonewiseBookedDesks }

                    action.payload?.forEach(ele => {
                        zones[ele?.zone?.id] = ele?.dataInDuration
                        zonewiseTotalDesks[ele?.zone?.id] = ele.desks
                        zonewiseBookedDesks[ele?.zone?.id] = ele.booked
                    })

                    state.secondary.bookedChartData.isLoaded = true
                    state.secondary.bookedChartData.data = zones
                    state.secondary.zonewiseTotalDesks = zonewiseTotalDesks
                    state.secondary.zonewiseBookedDesks = zonewiseBookedDesks
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchSecondaryOccupiedChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    if (state.secondary.selectedZone === "All Zones") {
                        state.secondary.occupancyChartData.isLoaded = true
                        state.secondary.selectedFloorAllZonesChartDataIsLoaded = true
                        state.secondary.selectedFloorAllZonesChartData[action?.payload?.zone] = action?.payload?.data
                    }
                    else {
                        state.secondary.occupancyChartData.isLoaded = true
                        state.secondary.occupancyChartData.data[action.payload?.zone] = action.payload?.data
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDesksFloorViewMap' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondaryFloorView.desks.data = action.payload?.data
                    state.secondaryFloorView.selectedZoneMap = action.payload?.zoneMap
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDesksFloorViewOccupancyData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const occupancyData = {}

                    action.payload?.forEach(ele => ele.sensorId && (
                        occupancyData[ele.sensorId] = ele.isOccupied
                    ))

                    const desksData = state.secondaryFloorView?.desks?.data?.map(ele => {
                        return { ...ele, isOccupied: occupancyData[ele.sensor] }
                    })

                    state.secondaryFloorView.desks.desksIsLoaded = true
                    state.secondaryFloorView.desks.data = desksData
                    state.secondaryFloorView.selectedZoneOccupancyData = occupancyData
                }
            )

        //states for new screens of desk occupancy for fidelity and all other tenants
    }
})

export const { resetDashboardDesks, updateSelectedFloor, updateSelectedZone, resetFloorViewDesksIsLoaded, resetDesksSecondaryData, resetChartIsLoadedonDurationChange, updateFloorViewSelectedFloor, updateFloorViewSelectedZone, updateDeskOccupancySocket, updateDeskOccupancyFloorViewSocket } = desksSlice.actions

export default desksSlice.reducer