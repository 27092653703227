import { Avatar, Box, Button, Chip, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material"
import CustomTypography from "../../../components/texts/CustomTypography"
import { EditOutlined, Height } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { DELETE, GET } from "../../../helpers/http"
import { useNavigate, useParams } from "react-router-dom"
import swal from 'sweetalert'
import { errorToast, successToast } from "../../../helpers/apiToast"
import { ThemeColors } from "../../../hooks/ThemeColors"
import { useSelector } from "react-redux"

const DocumentView = () => {
    const { id } = useParams()
    const [singleDocument, setSingleDocument] = useState({})
    const navigate = useNavigate()
    const { commonSX, background_color, textColor } = ThemeColors();
    const themeMode = useSelector(state => state.theme.darkTheme)
    useEffect(() => {
        (async () => {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/policy-docs?id=${id}`)
            setSingleDocument(response?.data)
        })();

    }, [])

    const handleEdit = () => {
        navigate(`/admin/settings/hr-docs/edit/${id}`)
    }


    const handleDelete = async () => {
        swal({
            title: 'Are you sure?',
            text: 'Delete Document',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                try {
                    await DELETE(
                        `${process.env.REACT_APP_BASE_URL}um/v1/policy-docs/?id=${id}`,
                        "Delete the Document"
                    );
                    successToast("Document Deleted Successfully");
                    navigate("/admin/settings/hr-docs/list");
                } catch (error) {
                    errorToast("There was an error deleting the document.");
                    console.log(error);
                }
            }
        }).catch(error => {
            console.log(error);
            errorToast("There was an error processing the request.");
        });
    };

    const handleCancel = () => {
        navigate('/admin/settings/hr-docs/list')
    }

    const fontFamilyMap = {
        'sans-serif': 'Arial, sans-serif',
        'serif': 'Georgia, serif',
        'monospace': 'Courier New, monospace',
        'arial': 'Arial, sans-serif',
        'times-new-roman': 'Times New Roman, serif',
        'courier-new': 'Courier New, monospace',
        'roboto': 'Roboto, sans-serif',
        'open-sans': 'Open Sans, sans-serif',
        'lato': 'Lato, sans-serif',
        'comic-sans-ms': 'Comic Sans MS, cursive, sans-serif',
    };

    const styleImagesAndFonts = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content; // Parse the provided content into a DOM structure

        Object.keys(fontFamilyMap).forEach((font) => {
            tempDiv.querySelectorAll(`.ql-font-${font}`).forEach((element) => {
                element.style.setProperty('font-family', fontFamilyMap[font], 'important');
            });
        });

        // Font Size Mapping
        const fontSizeMap = {
            '10px': '10px',
            '12px': '12px',
            '14px': '14px',
            '16px': '16px',
            '18px': '18px',
            '20px': '20px',
            '24px': '24px',
            '30px': '30px',
            '36px': '36px',
            '48px': '48px',
            '60px': '60px',
            '72px': '72px',
            '100px': '100px',
        };

        Object.keys(fontSizeMap).forEach((size) => {
            tempDiv.querySelectorAll(`.ql-size-${size}`).forEach((element) => {
                element.style.setProperty('font-size', fontSizeMap[size], 'important');
            });
        });

        // Style Images
        const images = tempDiv.querySelectorAll('img');
        images.forEach((img) => {
            // Set default alt attribute if missing
            if (!img.hasAttribute('alt')) {
                img.setAttribute('alt', 'Image');
            }

            const widthValue = img.style.width;
            // Ensure responsive width and maintain aspect ratio
            img.setAttribute('width', widthValue);
            img.setAttribute('height', 'auto');

            // Add a consistent style for alignment and responsiveness
            img.style.verticalAlign = 'bottom';
            img.style.display = 'block';
            img.style.maxWidth = '100%';
            img.style.height = 'auto';

            //     // Apply alignment class based on data-align attribute
            const alignClass = img.getAttribute('data-align');
            if (alignClass) {
                img.parentElement.classList.add(`ql-align-${alignClass}`);
            }
        });

        // Return the updated HTML content
        return tempDiv.innerHTML;
    };

    const Preview = (content) => {
        const styledContent = styleImagesAndFonts(content);

        return (
            <Box style={{
                padding: '10px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                lineHeight: '1.5',
            }}>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: styledContent,
                    }}
                    sx={{
                        overflow: 'auto',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '16px',
                        lineHeight: '1.5',
                    }}
                />
            </Box>
        );
    };


    return (
        <>
            <CustomTypography

                sx={{
                    width: "100%",
                    height: "100%",
                    margin: "10px",
                    backgroundColor: background_color(),

                }}
            >
                <Grid container spacing={2} style={{ padding: '2rem', backgroundColor: background_color() }}>
                    {/* Title */}
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        sx={{ backgroundColor: background_color(), }}
                    >
                        <Typography variant="h5" gutterBottom sx={{ color: textColor() }}>
                            {singleDocument?.title}
                        </Typography>
                        <Box
                            sx={{
                                width: "50%",
                                // border: "1px solid red",
                                display: 'flex',
                                justifyContent: 'flex-end',
                                backgroundColor: background_color()
                            }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#dc4d28',
                                    '&:hover': { backgroundColor: '#c43e2d' },
                                    width: '25%',
                                    marginRight: "25px",
                                    // marginLeft: "15px"
                                }}
                                onClick={handleEdit}

                            >Edit</Button>
                            <Button
                                variant='contained'

                                sx={{
                                    bgcolor: '#4B4B4B',
                                    '&:hover': {
                                        backgroundColor: '#2E2E2E',
                                    },
                                    borderColor: '#4B4B4B',
                                    width: '25%',
                                }}
                                onClick={handleDelete}
                            >Delete</Button>
                            <Button
                                variant='contained'
                                onClick={
                                    handleCancel
                                }
                                sx={{
                                    bgcolor: 'primary',
                                    borderColor: '#4B4B4B',
                                    // marginRight: "25px",
                                    marginLeft: "25px",
                                    width: '25%',


                                }}


                            >Cancel</Button>

                        </Box>


                    </Grid>


                    {/* Content */}
                    <Grid item xs={12}>
                        <Paper style={{ padding: '1rem', backgroundColor: background_color() }}>
                            <Typography variant="h6" sx={{ color: textColor() }}>Content</Typography>

                            <Box

                                style={{
                                    height: '70vh', // Fixed height
                                    marginTop: '1rem',
                                    overflow: 'auto', // Enable scrolling
                                    border: '1px solid #ddd', // Optional: Add a border for better visibility
                                    padding: '0.5rem', // Optional: Add some padding for the content
                                    borderRadius: '4px', // Optional: Add a rounded corner look
                                    // backgroundColor: '#f9f9f9', // Optional: Light background for better UX
                                    backgroundColor: themeMode ? '#61616161' : '#f9f9f9',
                                    display: 'flex', // Flexbox
                                    flexDirection: 'column', // Ensures content doesn't break layout
                                    alignItems:
                                        singleDocument?.content && singleDocument?.content.trim() !== "<p><br></p>" && singleDocument?.content.trim() !== ""
                                            ? 'flex-start'
                                            : 'center', // Center only if no content
                                    justifyContent:
                                        singleDocument?.content && singleDocument?.content.trim() !== "<p><br></p>" && singleDocument?.content.trim() !== ""
                                            ? 'flex-start'
                                            : 'center', // Center only if no content
                                }}
                            >
                                {singleDocument?.content && singleDocument?.content.trim() !== "<p><br></p>" && singleDocument?.content.trim() !== "" ? (
                                    // <div dangerouslySetInnerHTML={{ __html: singleDocument?.content }} />
                                    <div style={{ color: textColor() }}>
                                        {Preview(singleDocument?.content)}
                                    </div>
                                ) : (
                                    <Typography variant="body2" color={textColor()}>
                                        No content available.
                                    </Typography>
                                )}
                            </Box>


                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper
                            style={{
                                padding: '1rem',
                                background: background_color(),
                                ...commonSX
                                // marginTop: '1rem', minHeight: '150px'
                            }}
                        >
                            <Typography variant="h6" color={textColor()}>Tags</Typography>
                            <Stack direction="row" spacing={1} flexWrap="wrap" style={{ marginTop: '1rem', background: background_color() }}>
                                {
                                    singleDocument?.tags?.length > 0 ?
                                        singleDocument?.tags?.map((tag, index) => (
                                            <Chip key={index} label={tag} variant="outlined" />
                                        )) : <Typography variant="body1" color="textSecondary">-</Typography>
                                }
                            </Stack>
                        </Paper>

                    </Grid>



                    {/* Categories */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            style={{
                                padding: '1rem',
                                background: background_color(),
                                ...commonSX
                                //  marginTop: '1rem', minHeight: '150px'
                            }}>
                            <Typography variant="h6" color={textColor()}>Categories</Typography>
                            <Stack direction="row" spacing={1} flexWrap="wrap" style={{ marginTop: '1rem', background: background_color(), color: textColor() }}>
                                {
                                    singleDocument?.categories?.length > 0 ?
                                        singleDocument?.categories?.map((category) => (
                                            <Chip key={category?._id} label={category?.name} variant="outlined"
                                                style={{ ...commonSX }}
                                            />
                                        )) : <Typography variant="body1" color="textSecondary">-</Typography>
                                }
                            </Stack>
                        </Paper>
                    </Grid>

                    {/* Tags */}
                    <Grid item xs={12}>
                        <Paper style={{
                            padding: '1rem',
                            background: background_color(),
                            ...commonSX
                            // marginTop: '1rem', minHeight: '150px'
                        }}>
                            <Typography variant="h6" color={textColor()}>Departments</Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                style={{ marginTop: '1rem', flexWrap: 'wrap' }} // Prevent wrapping
                                alignItems="center" // Align content vertically
                            >
                                {singleDocument?.departments?.length > 0
                                    ? singleDocument?.departments.map((department) => (
                                        <Chip
                                            key={department?._id}
                                            label={department?.name}
                                            variant="outlined"
                                        // style={{ marginTop: "1rem" }}
                                        />
                                    ))
                                    : <Typography variant="body1" color={themeMode ? textColor() : "textSecondary"}> - </Typography>
                                }
                            </Stack>
                        </Paper>

                    </Grid>

                    {/* Locations */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            style={{
                                padding: '1rem',
                                background: background_color(),
                                ...commonSX
                                //  marginTop: '1rem', minHeight: '150px' 
                            }}
                        >
                            <Typography variant="h6">Locations</Typography>
                            <Stack direction="row" spacing={1} flexWrap="wrap" style={{ marginTop: '1rem' }}>
                                {
                                    singleDocument?.locations?.length > 0 ?
                                        singleDocument?.locations?.map((location) => (
                                            <Chip key={location?._id} label={location?.name} variant="outlined" />
                                        )) : <Typography variant="body1" color={themeMode ? textColor() : "textSecondary"} >-</Typography>
                                }
                            </Stack>
                        </Paper>
                    </Grid>

                    {/* Buildings */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            // style={{ padding: '1rem' }}
                            style={{
                                padding: '1rem',
                                background: background_color(),
                                ...commonSX
                                //  marginTop: '1rem', minHeight: '150px'
                            }}
                        >
                            <Typography variant="h6" color={textColor()}>Buildings</Typography>
                            <Stack direction="row" spacing={1} flexWrap="wrap" style={{ marginTop: '1rem' }}>
                                {
                                    singleDocument?.buildings?.length > 0 ?
                                        singleDocument?.buildings?.map((building) => (
                                            <Chip key={building?._id} label={building?.name} variant="outlined" />
                                        )) : <Typography variant="body1" color={themeMode ? textColor() : "textSecondary"}>-</Typography>
                                }
                            </Stack>
                        </Paper>
                    </Grid>


                    <Grid item xs={12}>
                        <Paper style={{
                            padding: '1rem', marginBottom: '1rem', background: background_color(),
                            ...commonSX
                        }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                {/* Created By Section */}
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Created By:
                                    </Typography>
                                    <Avatar
                                        src={singleDocument?.createdBy?.profilePictureUrl}
                                        alt={singleDocument?.createdBy?.fullName}
                                        sx={{
                                            bgcolor: 'lightblue', // Light blue background
                                            color: 'black',
                                        }}
                                    />
                                    <Box>
                                        <Typography variant="body1">{singleDocument?.createdBy?.fullName}</Typography>
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="caption" color="textSecondary">
                                                {singleDocument?.createdBy?.email}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                Created At: {new Date(singleDocument?.createdAt).toLocaleString()}
                                            </Typography>

                                        </Box>

                                    </Box>
                                </Stack>

                                {/* Last Edited By Section */}
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Last Edited By:
                                    </Typography>
                                    <Avatar
                                        src={singleDocument?.lastEditedBy?.profilePictureUrl}
                                        alt={singleDocument?.lastEditedBy?.fullName}
                                        sx={{
                                            bgcolor: 'lightgreen', // Light green background
                                            color: 'black',
                                        }}
                                    />
                                    <Box>
                                        <Typography variant="body1">{singleDocument?.lastEditedBy?.fullName}</Typography>
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="caption" color="textSecondary">
                                                {singleDocument?.lastEditedBy?.email}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                Updated At: {new Date(singleDocument?.updatedAt).toLocaleString()}
                                            </Typography>

                                        </Box>

                                    </Box>
                                </Stack>
                            </Box>
                        </Paper>
                    </Grid>


                </Grid>
            </CustomTypography >
        </>
    )
}
export default DocumentView