import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import FormFileInput from "../../../components/formInputs/FormFileInput"
import { CloseOutlined, FileDownloadOutlined } from "@mui/icons-material"
import { ThemeColors } from "../../../hooks/ThemeColors"
import { FILEPOST } from "../../../helpers/http"
import { useState } from "react"
import { successToast } from "../../../helpers/apiToast"
import Modal from "../../../components/modal/Modal"
import NotUploadedHolidays from "./NotUploadedHolidays"

const AddMultipleHolidaysExcel = (props) => {
    const {
        addMultipleHolidaysExcelFile, addMultipleHolidaysExcelFileRef,
        handleAddMultipleHolidaysExcelFileChange, handleCloseAddMultipleHolidaysExcelModal,
        handleAddMultipleHolidaysExcelFileReset, setHolidayData = () => { }, selectedBuilding
    } = props


    const url = process.env.REACT_APP_HOLIDAY_TEMPLATE_URL

    const { textColor } = ThemeColors()

    const [addMultipleHolidaysExcelError, setAddMultipleHolidaysExcelError] = useState('')

    const [notUploadedHolidays, setNotUploadedHolidays] = useState([])

    const [notUploadedHolidaysModalOpen, setNotUploadedHolidaysModalOpen] = useState(false)

    const handleOpenNotUploadedHolidaysModal = () => {
        setNotUploadedHolidaysModalOpen(true)
    }

    const handleCloseNotUploadedHolidaysModal = () => {
        setNotUploadedHolidaysModalOpen(false)
    }

    const handleAddMultipleHolidaysExcelFile = async (e) => {
        e.preventDefault()
        try {
            if (!addMultipleHolidaysExcelFile?.name) {
                setAddMultipleHolidaysExcelError('Please select an excel file')
            } else {
                setAddMultipleHolidaysExcelError('')
                const { data, status } = await FILEPOST(`${process.env.REACT_APP_URL}at/v1/holidaycalendar/bulk`, { file: addMultipleHolidaysExcelFile }, "ADD MULTIPLE HOLIDAYS EXCEL FILE", true)
                const addedSelectedBuildingHolidays = data?.addedHolidays?.filter(holiday => holiday?.building?._id === selectedBuilding)
                const notUploadableArray = data?.notUploadableArray || []

                if (status === 201) {
                    if (notUploadableArray?.length > 0) {
                        setNotUploadedHolidays(data?.notUploadableArray)
                        setHolidayData(prevData => [...prevData, ...addedSelectedBuildingHolidays])
                        handleOpenNotUploadedHolidaysModal()
                    } else {
                        successToast(data?.message)
                        handleCloseAddMultipleHolidaysExcelModal()
                        setHolidayData(prevData => [...prevData, ...addedSelectedBuildingHolidays])
                    }
                }
                else if (status === 200) {
                    setNotUploadedHolidays(data?.data)
                    handleOpenNotUploadedHolidaysModal()
                }
            }
        } catch (error) {
            console.log({ error })
            handleCloseAddMultipleHolidaysExcelModal()
        }
    }

    const handleChange = (e) => {
        setAddMultipleHolidaysExcelError('')
        handleAddMultipleHolidaysExcelFileChange(e)
    }

    const handleCloseModal = () => {
        handleCloseAddMultipleHolidaysExcelModal()
        setAddMultipleHolidaysExcelError('')
    }

    return (
        <>
            <Stack spacing={2}>
                <a style={{ 'text-decoration': 'none' }} href={url}>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: textColor(),
                            color: textColor(),
                            ":hover": {
                                borderColor: textColor(),
                            },
                        }}
                        fullWidth
                        startIcon={<FileDownloadOutlined />}
                    >
                        Download Template
                    </Button>
                </a>
                <Box className='displayFlex flexJustifyCenter flexAlignItemsCenter'>
                    <FormFileInput
                        buttonText="Upload Excel"
                        fullWidth
                        onChange={handleChange}
                        accept=".xlsx, .xls" //.csv
                        fileRef={addMultipleHolidaysExcelFileRef}
                    />
                </Box>
                {addMultipleHolidaysExcelError &&
                    <Typography color={'error'} fontSize={'10px'}>{addMultipleHolidaysExcelError}</Typography>
                }
                {
                    addMultipleHolidaysExcelFile?.name &&
                    <Box className='displayFlex flexJustifyBetween columngap16 flexAlignItemsCenter' >
                        <Typography sx={{ color: textColor() }}>{addMultipleHolidaysExcelFile?.name}</Typography>
                        <IconButton
                            onClick={handleAddMultipleHolidaysExcelFileReset}
                            color='error'
                            size='small'
                        >
                            <CloseOutlined fontSize='small' />
                        </IconButton>
                    </Box>
                }
                <Box className='displayFlex flexJustifyBetween columngap16'>
                    <Button
                        className='actionButton'
                        onClick={handleCloseModal}
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Button
                        className='actionButton'
                        onClick={handleAddMultipleHolidaysExcelFile}
                        fullWidth
                    >
                        Submit
                    </Button>
                </Box>
            </Stack>
            <Modal
                modalOpen={notUploadedHolidaysModalOpen}
                closeModal={handleCloseNotUploadedHolidaysModal}
                title={"Not Uploaded Holidays"}
            >
                <NotUploadedHolidays
                    handleCloseAddMultipleHolidaysExcelModal={handleCloseAddMultipleHolidaysExcelModal}
                    closeNotUploadedHolidaysModal={handleCloseNotUploadedHolidaysModal}
                    notUploadedHolidays={notUploadedHolidays}
                />
            </Modal>
        </>
    )
}

export default AddMultipleHolidaysExcel