import 'react-quill/dist/quill.core.css';
import ReactQuill, { Quill } from "react-quill";
import QuillResizeImage from 'quill-resize-image'; // Import resize image module

import "react-quill/dist/quill.snow.css"; // Quill default styles
import "../IndexDocs.css"; // Custom styles
import { Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import Compressor from 'compressorjs';

const CustomIcons = Quill.import('ui/icons');

// Add custom icons
CustomIcons['table'] = '<i class="fa fa-table"></i>'; // Example using Font Awesome
CustomIcons['addRow'] = '<i class="fa fa-rows"></i>'; // Add row icon
CustomIcons['addColumn'] = '<i class="fa fa-columns"></i>'; // Add column icon
CustomIcons['deleteRow'] = '<i class="fa fa-minus"></i>'; // Delete row icon
CustomIcons['deleteColumn'] = '<i class="fa fa-trash"></i>';
CustomIcons['insert'] = '<i class="fa fa-plus"></i>';

// const { commonSX, background_color, textColor } = ThemeColors();

// Initialize Grammarly Editor SDK

const BaseImage = Quill.import('formats/image');

const ATTRIBUTES = ['alt', 'height', 'width', 'style'];
const BlockEmbed = Quill.import("blots/block/embed");

class CustomImage extends BaseImage {
    static formats(domNode) {
        const formats = {};
        ATTRIBUTES.forEach(attr => {
            if (domNode.hasAttribute(attr)) {
                formats[attr] = domNode?.getAttribute(attr);
            }
        });
        return formats;
    }

    format(name, value) {
        if (ATTRIBUTES.includes(name)) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }

    static create(value) {
        const node = super.create();

        // Ensure the value is an object with a `url` property
        if (typeof value === 'string') {
            node.setAttribute('src', value); // For Quill's default behavior
        } else if (value?.url) {
            node.setAttribute('src', value.url); // Custom URL
        }

        node.setAttribute('style', 'display:inline; float:right; margin:0 5px;'); // Apply default styles
        return node;
    }
    static value(domNode) {
        return {
            url: domNode.getAttribute('src'),
            alt: domNode.getAttribute('alt') || '',
            width: domNode.getAttribute('width') || '',
            height: domNode.getAttribute('height') || '',
        };
    }


}


class TableBlot extends BlockEmbed {
    static create({ rows, cols }) {
        const node = super.create();
        const table = document?.createElement("table");
        for (let i = 0; i < rows; i++) {
            const row = document?.createElement("tr");
            for (let j = 0; j < cols; j++) {
                const cell = this.createCell(); // Use createCell method
                row.appendChild(cell);
            }
            table.appendChild(row);
        }
        node.appendChild(table);
        return node;
    }

    static createCell() {
        const cell = document?.createElement("td");
        cell.setAttribute("contenteditable", "true"); // Allow editing in each cell
        cell.appendChild(document.createTextNode("\u200B")); // Placeholder

        // Add input event listener to update content immediately
        cell.addEventListener('input', function () {
            const editor = Quill.find(cell).quill; // Get Quill instance
            const currentContent = editor?.root?.innerHTML;
            editor.setContents(editor.clipboard.convert(currentContent)); // Update contents

            // Manually trigger a text-change event
            editor?.emit('text-change'); // Notify Quill of text change
        });

        return cell;
    }

    static value(node) {
        const table = node?.querySelector("table");

        return {
            rows: table?.rows?.length,
            cols: table?.rows[0]?.cells?.length || 0,
        };
    }

    static addRow(node) {
        const table = node.querySelector("table");
        if (table) {
            const cols = table?.rows[0]?.cells?.length || 1;
            const newRow = document?.createElement("tr");
            for (let i = 0; i < cols; i++) {
                const cell = this.createCell(); // Use createCell method
                newRow?.appendChild(cell);
            }
            table.appendChild(newRow);
        }
    }

    static addColumn(node) {
        const table = node?.querySelector("table");
        if (table) {
            for (const row of table.rows) {
                const cell = this.createCell(); // Use createCell method
                row?.appendChild(cell);
            }
        }
    }

    static deleteRow(node) {
        const table = node.querySelector("table");
        if (table && table.rows.length > 0) {
            table?.deleteRow(-1); // Remove the last row
        }
    }

    static deleteColumn(node) {
        const table = node.querySelector("table");
        if (table && table?.rows[0]?.cells.length > 0) {
            for (const row of table.rows) {
                row?.deleteCell(-1); // Remove the last cell in each row
            }
        }
    }
}

TableBlot.blotName = "table";
TableBlot.tagName = "div";
TableBlot.className = "custom-table";
Quill.register(TableBlot);


const QuillContent = (props) => {
    const { content, setContent, setFormData } = props
    const quillRef = useRef(null);
    const grammarlyRef = useRef(null);
    const isInitialized = useRef(false); // Ref to track initialization
    const [previousContent, setPreviousContent] = useState(content);

    const Font = Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'arial', 'times-new-roman', 'courier-new', 'roboto', 'open-sans', 'lato', 'comic-sans-ms'];
    Quill.register(Font, true);

    const Size = Quill.import('formats/size');
    Size.whitelist = [
        'small', false, 'large', 'huge',
        '10px', '12px', '14px', '16px', '18px',
        '20px', '24px', '30px', '36px', '48px',
        '60px', '72px', '100px',
    ];
    Quill.register(Size, true);

    // Register the resize image module
    Quill.register('modules/resizeImage', QuillResizeImage);

    // Add a custom keyboard binding for backspace inside tables
    const customBackspaceHandler = {
        key: 'Backspace',
        format: ['table'], // Applies only when the cursor is inside a table
        handler(range, context) {
            const editor = this.quill;

            // Get the blot at the current cursor position
            const [blot, offset] = editor.scroll.descendant(TableBlot, range.index);

            if (blot) {
                const table = blot?.domNode.querySelector('table');
                if (table) {
                    // Get the active cell
                    const cell = document?.activeElement;

                    // Check if the current cell is empty
                    const isEmpty = cell && cell.innerText.trim() === '';

                    if (isEmpty) {
                        // Prevent the deletion of the entire table
                        cell.innerText = '\u200B'; // Add a placeholder character to keep the cell editable
                        return false; // Stop further backspace handling
                    }
                }
            }

            return true; // Allow backspace for non-table content
        },
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.querySelector("#insert-table-dropdown");
            if (dropdown && !dropdown.contains(event.target) && event.target.className !== 'ql-insert') {
                dropdown.style.display = "none"; // Hide the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const quill = quillRef?.current?.getEditor();
        const result = quill?.root?.innerHTML

        if (!isInitialized.current && content) {
            quill.clipboard.dangerouslyPasteHTML(content); // Set initial content safely
            isInitialized.current = true; // Mark as initialized
        }

        const handleTextChange = () => {
            const currentContent = quill.root.innerHTML;


            // Force header sizes and remove conflicting size formats
            const headers = quill.root.querySelectorAll('h1, h2, h3');
            headers.forEach((header) => {
                header.classList.remove(
                    ...Array.from(header.classList).filter((cls) => cls.startsWith('ql-size'))
                );
            });
            headers.forEach((header) => {
                // Remove conflicting size classes
                header.classList.remove(
                    ...Array.from(header.classList).filter((cls) => cls.startsWith('ql-size'))
                );

                const font = quill.getFormat(header).font || 'sans-serif';
                header.style.fontFamily = font; // Apply font family
            });


            if (currentContent === previousContent) return; // Prevent redundant updates

            setPreviousContent(currentContent); // Update previous content

            // Update state with new content immediately on text change
            setContent(currentContent);
            setFormData((prevFormData) => ({
                ...prevFormData,
                content: currentContent, // Save content to formData
            }));

        };
        quill.on("text-change", handleTextChange);

        // this is for the headers selection
        const handleSelectionChange = (range) => {
            if (range) {
                const format = quill.getFormat(range);
                if (format.header) {
                    quill.format('size', false); // Remove size formatting if a header is applied
                }
            }
        };

        // Register the selection-change event
        quill.on('selection-change', handleSelectionChange);

        return () => {
            quill.off("text-change", handleTextChange);
            quill.off('selection-change', handleSelectionChange);
        };
    }, []); // Only depend on setFormData


    Quill.register('formats/image', CustomImage);

    const handleImageUpload = (alignment = 'center') => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement('input');


        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();


        input.onchange = async () => {
            const file = input.files[0];
            if (file && file.type.startsWith('image/')) {
                // Compress the image
                new Compressor(file, {
                    quality: 0.2, // Adjust compression quality
                    maxWidth: 800, // Maximum width of the compressed image
                    success: (compressedFile) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const imageSrc = reader.result;

                            // Create a new Image object to get natural dimensions
                            const imgElement = new Image();
                            imgElement.src = imageSrc;
                            imgElement.onload = () => {
                                const width = imgElement.naturalWidth;
                                const height = imgElement.naturalHeight;

                                const range = editor.getSelection();

                                editor.insertEmbed(range.index, 'image', { url: imageSrc, width: `${width}px`, height: `${height}px` });
                                // Set alignment and style attributes
                                setTimeout(() => {
                                    const img = editor.container.querySelector(`img[src="${imageSrc}"]`);
                                    if (img) {
                                        img.style.maxWidth = '100%'; // Ensure responsive width
                                        img.style.height = 'auto'; // Maintain aspect ratio

                                        // Set alignment class on parent block
                                        const parentBlock = img.closest('p');
                                        if (parentBlock) {
                                            parentBlock.classList.add(`ql-align-${alignment}`);
                                        }
                                    }
                                }, 0);

                            };

                            imgElement.onerror = () => {
                                console.error('Error loading image');
                            };
                        };

                        reader.onerror = () => {
                            console.error('Error reading the compressed image file.');
                        };

                        // Read the compressed image file
                        reader.readAsDataURL(compressedFile);
                    },
                    error: (err) => {
                        console.error('Image compression failed:', err.message);
                    },
                });
            } else {
                console.error('Please upload a valid image file.');
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, false] }],
                [{ font: Font.whitelist }],
                [
                    {
                        'size': [
                            'small', false, 'large', 'huge',
                            '10px', '12px', '14px', '16px',
                            '18px', '20px', '24px', '30px',
                            '36px', '48px', '60px', '72px', '100px',
                        ],
                    },
                ],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#0066cc', '#33cc33'] }],
                [{ 'background': [] }],
                ['bold', 'italic', 'underline', 'strike'], // Text formatting
                [{ 'align': [] }],
                [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                ['link', 'image',
                    // 'insert'
                ],
                ['clean'],
            ],
            handlers: {
                image: handleImageUpload, // Custom image upload handler
                table: function () {
                    // Insert table logic
                    const editor = this.quill;
                    const range = editor.getSelection();
                    const index = range ? range.index : editor.getLength();
                    editor.insertEmbed(index, 'table', { rows: 3, cols: 3 });
                },
                insert: function () {
                    const editor = this.quill;
                    const range = editor.getSelection();

                    if (range) {
                        const toolbarButton = document.querySelector(".ql-insert"); // Assuming the button has the class 'ql-insert'
                        const dropdown = document.querySelector("#insert-table-dropdown");

                        if (toolbarButton && dropdown) {
                            // Get the position of the toolbar button
                            const rect = toolbarButton.getBoundingClientRect();

                            // Position the dropdown below the button
                            dropdown.style.top = `${rect.bottom + window.scrollY}px`;
                            dropdown.style.left = `${rect.left + window.scrollX}px`;
                            dropdown.style.display = "block"; // Show the dropdown
                        }
                    }
                },
                addRow: function () {
                    const editor = this.quill;
                    const [blot] = editor.scroll.descendant(TableBlot, editor.getSelection()?.index);
                    if (blot) {
                        TableBlot.addRow(blot.domNode);
                    }
                },
                addColumn: function () {
                    const editor = this.quill;
                    const [blot] = editor.scroll.descendant(TableBlot, editor.getSelection()?.index);
                    if (blot) {
                        TableBlot.addColumn(blot.domNode);
                    }
                },
                deleteRow: function () {
                    const editor = this.quill;
                    const [blot] = editor.scroll.descendant(TableBlot, editor.getSelection()?.index);
                    if (blot) {
                        TableBlot.deleteRow(blot.domNode);
                    }
                },
                deleteColumn: function () {
                    const editor = this.quill;
                    const [blot] = editor.scroll.descendant(TableBlot, editor.getSelection()?.index);
                    if (blot) {
                        TableBlot.deleteColumn(blot.domNode);
                    }
                },
            },

        },
        keyboard: {
            bindings: {
                backspaceInTable: customBackspaceHandler,
            },
        },
        resizeImage: {
            // Additional resize image options can go here
        },
        clipboard: {
            matchVisual: false,
        },
        cursors: false

    }), []); // Memoize to avoid re-renders

    function handleTableAction(action) {
        const editor = quillRef.current.getEditor();
        const [blot] = editor.scroll.descendant(TableBlot, editor.getSelection()?.index);
        if (!blot && action !== "insertTable") return;

        switch (action) {
            case "insertTable":
                const range = editor.getSelection();
                const index = range ? range.index : editor.getLength();
                editor.insertEmbed(index, "table", { rows: 3, cols: 3 });
                break;
            case "addRow":
                TableBlot.addRow(blot.domNode);
                break;
            case "addColumn":
                TableBlot.addColumn(blot.domNode);
                break;
            case "deleteRow":
                TableBlot.deleteRow(blot.domNode);
                break;
            case "deleteColumn":
                TableBlot.deleteColumn(blot.domNode);
                break;
            default:
                console.error("Unknown action");
        }

        // Hide dropdown after action
        const dropdown = document.querySelector("#insert-table-dropdown");
        if (dropdown) dropdown.style.display = "none";
    }


    useEffect(() => {
        const grammarlyPlugin = grammarlyRef.current;

        if (grammarlyPlugin) {
            console.log("Grammarly Plugin initialized:", grammarlyPlugin);

            // Optionally check if clientId is required
            if (!grammarlyPlugin.clientId) {
                console.log("No clientId set; proceeding with default functionality.");
            }

            // Optionally add event listeners for Grammarly events
            grammarlyPlugin.addEventListener("suggestions", (event) => {
                console.log("Suggestions available:", event.detail);
            });

            // Cleanup on unmount
            return () => {
                grammarlyPlugin.removeEventListener("suggestions", () => { });
            };
        }
    }, []);

    return <>
        <Box
            sx={{
                // border: "1px solid green",
                height: "70vh",
                overflow: "auto"
            }}
        >
            <div sx={{ height: "100vh" }}>
                <grammarly-editor-plugin ref={grammarlyRef}>
                    <ReactQuill
                        value={content}
                        ref={quillRef}
                        onChange={(value) => setContent(value)}
                        modules={modules} // Pass your custom toolbar
                        theme="snow" // Theme: snow or bubble
                        placeholder="Start typing here..."
                        style={{
                            minHeight: "400px",
                            fontFamily: "'Roboto', sans-serif",
                            overflow: "auto", // Enable scrolling if needed
                        }}
                        spellCheck={true} // Enable browser spellcheck
                        onChangeSelection={() => {
                            const editor = quillRef.current.getEditor();
                            if (editor) {
                                const editableDiv = editor.container.querySelector('[contenteditable="true"]');
                                if (editableDiv) {
                                    editableDiv.spellcheck = true; // Enable browser spellcheck
                                }
                            }
                        }}
                    />
                    <div
                        id="insert-table-dropdown"
                        style={{
                            display: "none",
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            zIndex: 1000,
                            padding: "10px",
                        }}
                    >
                        <button onClick={() => handleTableAction("insertTable")}>Insert Table</button>
                        <button onClick={() => handleTableAction("addRow")}>Add Row</button>
                        <button onClick={() => handleTableAction("addColumn")}>Add Column</button>
                        <button onClick={() => handleTableAction("deleteRow")}>Delete Row</button>
                        <button onClick={() => handleTableAction("deleteColumn")}>Delete Column</button>
                    </div>
                </grammarly-editor-plugin>
            </div>

        </Box>

    </>
}
export default QuillContent