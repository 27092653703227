import { Box, Typography } from "@mui/material"
import CommonStyles from "../../../styles/CommonStyles"
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles"
import KioskConfigContainer from "../../../features/visitorKiosk/KioskConfigContainer/KioskConfigContainer"

const VisitorKioskConfig = () => {
    return (
        <Box
            sx={CommonStyles?.OuterBox}>
            <Typography
                sx={DepartmentSettingsStyles.headingTypo}
            >
                Visitor Kiosk Configurations
            </Typography>
            <KioskConfigContainer />
        </Box>
    )
}

export default VisitorKioskConfig