import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import CustomComponentContainer from '../../../components/container/CustomComponentContainer'
import PieChart from './components/charts/HighChartsFaultPieChart'
import { GET } from '../../../helpers/http'
import { errorToast } from '../../../helpers/apiToast'
import Loader from '../../../components/loading/Loader'
import HighchartsTicketsRaisedCompletionDaywise from './components/charts/HighchartsTicketsRaisedCompletionDaywise'
import NoDataFound from '../../../components/noDataFound/NoDataFound'
import CustomSecondaryHeader from '../../components/header/CustomSecondaryHeader'

const FRDashboard = () => {
    const [statisticsData, setStatisticsData] = useState({
        ticketsBasedOnTaskStatus: { data: [], isLoaded: false },
        ticketsBasedOnCategories: { data: [], isLoaded: false },
        ticketsBasedOnPriorities: { data: [], isLoaded: false },
        ticketsBasedOnDueDate: { data: [], isLoaded: false },
        ticketsRaisedCompletionDaywise: { data: [], isLoaded: false }
    })

    const getStatistics = async () => {
        try {
            // const optionsResponse = await GET(`http://localhost:8004/v2/fault/statistics-options`)
            const optionsResponse = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/statistics-options`)
            if (optionsResponse?.status === 200) {
                const requests = optionsResponse?.data?.map(async ({ key, option }) => {
                    // return { key, data: await GET(`http://localhost:8004/v2/fault/statistics?option=${option}&buildingId=${localStorage.getItem('bid')}`, `Get ${key} `) }
                    return { key, data: await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/statistics?option=${option}&buildingId=${localStorage.getItem('bid')}`, `Get ${key} `) }
                })

                const results = await Promise.allSettled(requests)
                const newStatisticsData = results?.reduce((acc, { status, value }) => {
                    if (status === "fulfilled") {
                        acc[value?.key] = { data: value?.data["data"], isLoaded: true };
                    } else {
                        acc[value?.key] = { data: [], isLoaded: true };
                    }
                    return acc;
                }, {});

                setStatisticsData({
                    ...statisticsData,
                    ...newStatisticsData
                });
            } else {
                setStatisticsData({
                    ticketsBasedOnTaskStatus: { data: [], isLoaded: true },
                    ticketsBasedOnCategories: { data: [], isLoaded: true },
                    ticketsBasedOnPriorities: { data: [], isLoaded: true },
                    ticketsBasedOnDueDate: { data: [], isLoaded: true },
                    ticketsRaisedCompletionDaywise: { data: [], isLoaded: true }
                })
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error.message);
        }
    }

    useEffect(() => {
        getStatistics()
    }, [])

    const gridComponents = [
        {
            xs: 4,
            title: "Tickets based on Task Status",
            component: <Box sx={{ height: "50vh" }}>
                {statisticsData?.ticketsBasedOnTaskStatus?.isLoaded ?
                    statisticsData?.ticketsBasedOnTaskStatus?.data?.length > 0 ?
                        <PieChart
                            data={statisticsData?.ticketsBasedOnTaskStatus?.data}
                        /> : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 4,
            title: "Tickets based on Category",
            component:
                <Box sx={{ height: "50vh" }}>
                    {statisticsData?.ticketsBasedOnCategories?.isLoaded ?
                        statisticsData?.ticketsBasedOnCategories?.data?.length > 0 ?
                            <PieChart
                                data={statisticsData?.ticketsBasedOnCategories?.data}
                            /> : <NoDataFound height="100%" />
                        : <Loader height="100%" />}
                </Box>
        },
        {
            xs: 4,
            title: "Tickets based on Priorities",
            component:
                <Box sx={{ height: "50vh" }}>
                    {statisticsData?.ticketsBasedOnPriorities?.isLoaded ?
                        statisticsData?.ticketsBasedOnPriorities?.data?.length > 0 ?
                            <PieChart
                                data={statisticsData?.ticketsBasedOnPriorities?.data}
                            /> : <NoDataFound height="100%" />
                        : <Loader height="100%" />}
                </Box>
        },
        {
            xs: 4,
            title: "Tickets based on Due Date",
            component:
                <Box sx={{ height: "50vh" }}>
                    {statisticsData?.ticketsBasedOnDueDate?.isLoaded ?
                        statisticsData?.ticketsBasedOnDueDate?.data?.length > 0 ?
                            <PieChart
                                data={statisticsData?.ticketsBasedOnDueDate?.data}
                            /> : <NoDataFound
                                message="No overdue tickets found"
                                height="100%" />
                        : <Loader height="100%" />}
                </Box>
        },
        {
            xs: 8,
            title: "Last 30 days Daywise Reports",
            component: <Box sx={{ height: "50vh", paddingX: "1rem", paddingTop: "2rem" }}>
                {statisticsData?.ticketsRaisedCompletionDaywise?.isLoaded ?
                    statisticsData?.ticketsRaisedCompletionDaywise?.data?.length > 0 ?
                        <HighchartsTicketsRaisedCompletionDaywise
                            data={statisticsData?.ticketsRaisedCompletionDaywise?.data} /> : <NoDataFound height="100%" />
                    : <Loader height="100%" />
                }
            </Box>
        },
    ]
    return (
        <Box ml={0}>
            {/* <CustomComponentContainer className='pad8' sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
                <Dropdown disabled={true} label='Floors' inputLabel='Floors' sx={{ width: '8rem', height: 'fit-content', mb: 0 }} />
            </CustomComponentContainer> */}
            <Grid container>
                {gridComponents?.map(({ xs, component, title }, index) => {
                    return <Grid item xs={xs} >
                        <CustomComponentContainer className={'commonCardSpacing'}>
                            <CustomSecondaryHeader >{title}</CustomSecondaryHeader >
                            {component}
                        </CustomComponentContainer>
                    </Grid>
                })}
            </Grid>
        </Box>
    )
}

export default FRDashboard