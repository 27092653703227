import { memo, useEffect } from 'react'
import { Grid } from '@mui/material'
import Footer from '../footer/Footer'
import SideBar from '../sidebar/SideBar'
import PrivateRoute from '../../authentication/PrivateRoute'
import { componentRoutes } from './data/componentRoutes'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetAssetManagementFilters } from '../../../../redux/features/services/assetManagementSlice'

function AppContainer() {
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    useEffect(() => {
        if (!pathname.includes('assetmanagement')) {
            dispatch(resetAssetManagementFilters())
        }
    }, [pathname, dispatch])

    return (
        <div className='flex'>
            <SideBar />
            <Grid container className='main-content-container'>
                <Grid item xs={12} >
                    {componentRoutes.map(({ path, component }, index) => <PrivateRoute key={index} path={path} component={component} />)}
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </div>

    )
}

export default memo(AppContainer)