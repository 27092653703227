import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomTypography from '../../../../components/texts/CustomTypography'
import CustomComponentContainer from '../../../../components/container/CustomComponentContainer'
import { Box, Button, Grid, IconButton, styled } from '@mui/material'
import { ThemeColors } from '../../../../hooks/ThemeColors'
import { FILEPOST, GET, PUT } from '../../../../helpers/http'
import CustomFormField from '../../../../components/formInputs/CustomFormField'
import LimitTags from '../../../../components/formInputs/CustomAutoComplete'
import CustomButton from '../../../../components/button/CustomButton'
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css'
import { CloudUpload, HighlightOffOutlined } from '@mui/icons-material';
import AddAssetFormStyles from '../../../../styles/AddAssetFormStyles'
import * as Yup from 'yup';
import { useSelector } from 'react-redux'
import { errorToast } from '../../../../helpers/apiToast'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    taxId: Yup.string().required('Tax ID is required'),
    documents: Yup.mixed().required('Documents are required'),
    primaryAddress: Yup.object().shape({
        addressLine1: Yup.string().required('Primary Address line 1 is required'),
        country: Yup.string().required('Country is required'),
        postalCode: Yup.string().required('Postal Code is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
    }),
    logo: Yup.object().shape({
        light: Yup.string(),
        dark: Yup.string()
    }),
    primaryContact: Yup.object().shape({
        name: Yup.string().required('Primary Contact Name is required'),
        email: Yup.string().email('Invalid Email').required('Primary Contact Email is required'),
        phone: Yup.string().matches(/^[0-9]*$/, 'invalid phone number').required('Primary Contact Phone is required'),
        profilePhoto: Yup.string()
    }),
    secondaryContact: Yup.object().shape({
        name: Yup.string().trim(),
        email: Yup.string().email('Invalid Email'),
        phone: Yup.string().matches(/^[0-9]*$/, 'invalid phone number'),
        profilePhoto: Yup.string()
    }),
    financeContact: Yup.object().shape({
        name: Yup.string().required('Finance Contact Name is required'),
        email: Yup.string().email('Invalid Email').required('Finance Contact Email is required'),
        phone: Yup.string().matches(/^[0-9]*$/, 'invalid phone number').required('Primary Contact Phone is required'),
        profilePhoto: Yup.string()
    }),
    serviceCategories: Yup.array().min(1, 'Please select at least one category').required('Categories are required'),
});

const VendorEdit = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const [personalInfo, setPersonalInfo] = useState({ name: '', taxId: '' });

    const [primaryAddress, setPrimaryAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        country: '',
        postalCode: '',
        city: '',
        state: '',
    });

    const [secondaryAddress, setSecondaryAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        country: '',
        postalCode: '',
        city: '',
        state: '',
    });

    const [logo, setLogos] = useState({ light: '', dark: '' });

    const [primaryContact, setPrimaryContact] = useState({
        name: '',
        email: '',
        phone: '',
        profilePhoto: ''
    });

    const [secondaryContact, setSecondaryContact] = useState({
        name: '',
        email: '',
        phone: '',
        profilePhoto: ''
    });

    const [financeContact, setFinanceContact] = useState({
        name: '',
        email: '',
        phone: '',
        profilePhoto: ''
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [documents, setDocuments] = useState([])

    const [formErrors, setFormErrors] = useState({});

    const fileReadUrl = useSelector(state => state?.configurations?.fileReadUrl)

    const formFieldInfo = useMemo(() => [
        { type: 'title', label: 'Personal Information', xs: 12 },
        { type: 'textInput', label: 'Name', xs: 6, name: 'name', value: personalInfo?.name, setState: setPersonalInfo, required: true },
        { type: 'textInput', label: 'Tax ID', value: personalInfo?.taxId, setState: setPersonalInfo, xs: 6, required: true, name: 'taxId' },
        { type: 'title', label: 'Primary Address', xs: 12 },
        { type: 'textInput', label: 'Address line 1', value: primaryAddress?.addressLine1, xs: 12, required: true, name: 'primaryAddress.addressLine1', setState: setPrimaryAddress },
        { type: 'textInput', label: 'Address line 2', value: primaryAddress?.addressLine2, xs: 12, name: 'primaryAddress.addressLine2', setState: setPrimaryAddress },
        { type: 'textInput', label: 'Country', value: primaryAddress?.country, xs: 6, required: true, name: 'primaryAddress.country', setState: setPrimaryAddress },
        { type: 'textInput', label: 'Postal Code', value: primaryAddress?.postalCode, xs: 6, required: true, name: 'primaryAddress.postalCode', setState: setPrimaryAddress },
        { type: 'textInput', label: 'City', value: primaryAddress.city, xs: 6, required: true, setState: setPrimaryAddress, name: 'primaryAddress.city' },
        { type: 'textInput', label: 'State', value: primaryAddress.state, xs: 6, setState: setPrimaryAddress, name: 'primaryAddress.state', required: true },
        { type: 'title', label: 'Secondary Address', xs: 12 },
        { type: 'textInput', label: 'Address line 1', value: secondaryAddress.addressLine1, xs: 12, setState: setSecondaryAddress, name: 'secondaryAddress.addressLine1' },
        { type: 'textInput', label: 'Address line 2', value: secondaryAddress.addressLine2, xs: 12, setState: setSecondaryAddress, name: 'secondaryAddress.addressLine2' },
        { type: 'textInput', label: 'Country', value: secondaryAddress.country, xs: 6, setState: setSecondaryAddress, name: 'secondaryAddress.country' },
        { type: 'textInput', label: 'Postal Code', value: secondaryAddress.postalCode, xs: 6, setState: setSecondaryAddress, name: 'secondaryAddress.postalCode' },
        { type: 'textInput', label: 'City', value: secondaryAddress.city, xs: 6, setState: setSecondaryAddress, name: 'secondaryAddress.city' },
        { type: 'textInput', label: 'State', value: secondaryAddress.state, xs: 6, setState: setSecondaryAddress, name: 'secondaryAddress.state' },
        { type: 'title', label: 'Documents *', xs: 12 },
        { type: 'uploadInput', label: 'Documents', allowMultiple: true, maxFiles: 3, value: documents, xs: 12, required: true, setState: setDocuments, name: 'documents' },
        { type: 'title', label: 'Logo *', xs: 12 },
        { type: 'uploadInput', label: 'light logo', value: logo?.light, xs: 12, required: true, setState: setLogos, name: 'logo.light' },
        { type: 'uploadInput', label: 'dark logo', value: logo?.dark, xs: 12, required: true, setState: setLogos, name: 'logo.dark' },
        { type: 'title', label: 'Primary Contact', xs: 12 },
        { type: 'textInput', label: 'Name', xs: 6, value: primaryContact.name, name: 'primaryContact.name', setState: setPrimaryContact, required: true },
        { type: 'textInput', label: 'Email', xs: 6, value: primaryContact.email, name: 'primaryContact.email', setState: setPrimaryContact, required: true },
        { type: 'textInput', label: 'Phone', xs: 6, value: primaryContact.phone, name: 'primaryContact.phone', setState: setPrimaryContact, required: true },
        { type: 'uploadInput', label: 'Profile Photo', xs: 12, value: primaryContact.profilePhoto, name: 'primaryContact.profilePhoto', setState: setPrimaryContact, required: true },
        { type: 'title', label: 'Secondary Contact', xs: 12 },
        { type: 'textInput', label: 'Name', xs: 6, value: secondaryContact.name, name: 'secondaryContact.name', setState: setSecondaryContact },
        { type: 'textInput', label: 'Email', xs: 6, value: secondaryContact.email, name: 'secondaryContact.email', setState: setSecondaryContact },
        { type: 'textInput', label: 'Phone', xs: 6, value: secondaryContact.phone, name: 'secondaryContact.phone', setState: setSecondaryContact },
        { type: 'uploadInput', label: 'Profile Photo', xs: 12, value: secondaryContact.profilePhoto, name: 'secondaryContact.profilePhoto', setState: setSecondaryContact },
        { type: 'title', label: 'Finance Contact', xs: 12 },
        { type: 'textInput', label: 'Name', xs: 6, value: financeContact.name, name: 'financeContact.name', setState: setFinanceContact, required: true },
        { type: 'textInput', label: 'Email', xs: 6, value: financeContact.email, name: 'financeContact.email', setState: setFinanceContact, required: true },
        { type: 'textInput', label: 'Phone', xs: 6, value: financeContact.phone, name: 'financeContact.phone', setState: setFinanceContact, required: true },
        { type: 'uploadInput', label: 'Profile Photo', xs: 12, value: financeContact.profilePhoto, name: 'financeContact.profilePhoto', setState: setFinanceContact, required: true },
        { type: 'title', label: 'Categories *', xs: 12 },
    ], [personalInfo, primaryAddress, secondaryAddress, logo, primaryContact, secondaryContact, financeContact, documents]);

    const { commonSX } = ThemeColors();

    console.log({ formErrors })

    const submitCall = async ({ formData }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor`, { ...formData }, "VENDOR ADD")
            return response
        } catch (error) {
            errorToast(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, "GET CATEGORIES")
                if (response?.status === 200 && selectedCategories.length > 0) {
                    setCategories(response?.data.filter(ele => !selectedCategories?.find(el => el._id === ele._id)))
                }
            } catch (error) {
                errorToast(error?.response?.data?.message)
            }
        }
        getCategories()
    }, [selectedCategories])

    const mediaUpload = async (file) => {
        try {
            const result = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, { file }, "MEDIA UPLOAD")
            return result.data
        } catch (error) {
            setFormErrors({ documents: `Invalid File: ${file.name}` })
        }
    }

    const handleMultipleFileUpload = useCallback(async (fileItems, documents, name) => {
        let response = []
        if (fileItems.length > 0) {
            try {
                response = await Promise.allSettled([...fileItems]?.map((fileItem) => mediaUpload(fileItem)))
                response = response.map((result) => result?.value)
                setDocuments([...documents, ...response])
            } catch (error) {
                setFormErrors({ [name]: error?.message || error?.response?.data?.message })
            }
        }
    }, []);

    const handleFileUploads = useCallback(async (fileItems, name, setState) => {
        let response = ''
        if (fileItems.length > 0) {
            try {
                response = await mediaUpload(fileItems[0])
                if (response) {
                    setState((prevState) => ({ ...prevState, [name.split('.')[1]]: response }))
                }
            } catch (error) {
                setFormErrors({ [name]: error?.message || error?.response?.data?.message })
            }
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            vendorId: id,
            ...personalInfo,
            primaryAddress,
            secondaryAddress,
            logo: { light: logo.light?.key || '', dark: logo.dark?.key || '' },
            documents,
            primaryContact,
            secondaryContact,
            financeContact,
            serviceCategories: selectedCategories.map(el => el._id)
        };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setFormErrors({});
            const response = await submitCall({ formData });
            if (response?.status === 200) {
                resetForm();
                navigate('/admin/settings/vendor')
            }
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                if (err.path) errors[err.path] = err.message;
            });
            setFormErrors(errors);
        }
    };

    const resetForm = () => {
        setFormErrors({});
        setPersonalInfo({ name: '', taxId: '' });
        setPrimaryAddress({ addressLine1: '', country: '', postalCode: '', city: '', state: '' });
        setSecondaryAddress({ addressLine1: '', country: '', postalCode: '', city: '', state: '' });
        setLogos({ light: '', dark: '' });
        setPrimaryContact({ name: '', email: '', phone: '', profilePhoto: '' });
        setSecondaryContact({ name: '', email: '', phone: '', profilePhoto: '' });
        setFinanceContact({ name: '', email: '', phone: '', profilePhoto: '' });
        setSelectedCategories([]);
        setDocuments([]);
    };


    useEffect(() => {
        const getSingleVendorById = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor/?id=${id}`, "GET SINGLE VENDOR")
                if (response?.status === 200) {
                    setPersonalInfo({ name: response?.data?.name, taxId: response?.data?.taxId })
                    setPrimaryAddress({ ...response?.data?.primaryAddress })
                    setSecondaryAddress({ ...response?.data?.secondaryAddress })
                    setPrimaryContact({ ...response?.data?.primaryContact })
                    setSecondaryContact({ ...response?.data?.secondaryContact })
                    setFinanceContact({ ...response?.data?.financeContact })
                    setSelectedCategories(response?.data?.serviceCategories)
                    setLogos({ light: response?.data?.logo?.light || '', dark: response?.data?.logo?.dark || '' })
                    setDocuments(response?.data?.documents)
                }
            }
            catch (error) {
                console.log({ error });
            }
        }

        getSingleVendorById()
        return () => resetForm();
    }, [id])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleRemoveFile = (id) => {
        const result = documents.filter((el) => el.uploadId !== id)
        setDocuments(result)
    }

    const handleRemoveSingleFile = ({ name, setState }) => {
        setState((prevState) => ({ ...prevState, [name.split('.')[1] || name]: '' }));
    }

    const handleChange = useCallback((e, setState) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name.split('.')[1] || name]: value }));
    }, []);

    const imageRenderer = ({ value, name, setState }) => {
        if (!value) return;
        if (Array.isArray(value)) {
            return <div className='flex'>
            {
                value?.map((el, index) => {
                    return !el ? null : <Box sx={{ ...AddAssetFormStyles.commonImageBox, mt: '10px' }} key={index}>
                        <object data={`${fileReadUrl}${el?.key}`} height={100} width={'100%'} aria-label='images' />
                        <IconButton onClick={() => { handleRemoveFile(el?.uploadId) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
                    </Box>
                })
            }
        </div>
        }
        if (!Array.isArray(value) && typeof (value) === 'string') {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>
        }
        else if (!Array.isArray(value) && typeof (value) === 'object' && Object.keys(value).includes('key')) {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value?.key}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>

        }
    }

    // const imageRenderer = ({ value, name, setState }) => {
    //     const renderFile = (file) => {
    //         const fileUrl = `${fileReadUrl}${file.key || file}`;
    //         const fileType = fileUrl.split('.').pop().toLowerCase();
    //         const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileType);
    //         const isPDF = fileType === 'pdf';

    //         return (
    //             <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }} key={file.key || file}>
    //                 {isImage && <img src={fileUrl} height={100} width={'100%'} alt="Preview" />}
    //                 {isPDF && <iframe src={fileUrl} height={100} width={'100%'} title="PDF Preview" />}
    //                 {!isImage && !isPDF && <object data={fileUrl} height={100} width={'100%'} />}
    //                 <IconButton onClick={() => { handleRemoveFile(file.uploadId || { name, setState }) }}>
    //                     <HighlightOffOutlined fontSize='medium' />
    //                 </IconButton>
    //             </Box>
    //         );
    //     };

    //     if (Array.isArray(value)) {
    //         return (
    //             <div className='flex'>
    //                 {value.map(renderFile)}
    //             </div>
    //         );
    //     } else if (typeof value === 'string' || (typeof value === 'object' && Object.keys(value).includes('key'))) {
    //         return renderFile(value);
    //     } else {
    //         return null;
    //     }
    // };

    return (
        <div className='pad8'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Edit Vendor</CustomTypography>
                <CustomButton
                    variant='contained'
                    sx={{ mr: '20px', width: '12rem', height: '2.4rem' }}
                    className={styles.button_bgc}
                    onClick={() => navigate('/admin/settings/vendor')}
                >
                    cancel
                </CustomButton>
            </div>
            <CustomComponentContainer className='mrgnsettingpage pad10 flex'>
                <form onSubmit={handleSubmit}>
                    <Grid container className='pad10 width50'>
                        {formFieldInfo?.map(({ type, xs, label, value, required, name, setState, allowMultiple }, index) => (
                            <Grid item xs={xs} key={index} className={type !== 'title' && 'pad5'}>
                                {type === 'title' && (
                                    <CustomTypography className='mrgnleft10 bold' sx={index > 0 && { mt: '10px' }}>
                                        {label}
                                    </CustomTypography>
                                )}
                                {type === 'textInput' && (
                                    <CustomFormField
                                        variant='outlined'
                                        sx={commonSX}
                                        value={value}
                                        label={label}
                                        required={required}
                                        name={name}
                                        onChange={(e) => handleChange(e, setState)}
                                        size='small'
                                        error={!!formErrors[name]}
                                        helperText={formErrors[name]}
                                    />
                                )}
                                {type === 'uploadInput' && (
                                    <>
                                        <Button
                                            component="label"
                                            className={styles.button_bgc}
                                            role={undefined}
                                            size='small'
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUpload className='color-white' />}
                                            sx={{ width: '12rem', height: '2.4rem' }}
                                        >
                                            {label}
                                            <VisuallyHiddenInput
                                                type="file"
                                                accept="image/*,application/pdf"
                                                multiple={allowMultiple}
                                                name={name}
                                                // value={value}
                                                onChange={(e) => allowMultiple ? handleMultipleFileUpload(e.target.files, documents) : handleFileUploads(e.target.files, name, setState)}
                                            />
                                        </Button>
                                        {value && imageRenderer({ value, name, setState })}
                                    </>
                                )}
                            </Grid>
                        ))}
                        <Grid item xs={6} className='pad5'>
                            <LimitTags
                                options={categories}
                                selectedOptions={selectedCategories}
                                setSelectedOptions={setSelectedCategories}
                                error={formErrors.serviceCategories}
                            />
                        </Grid>
                        <Grid item xs={12} className='pad5'>
                            <CustomButton
                                variant='contained'
                                sx={{ mt: '10px', width: '12rem', height: '2.4rem' }}
                                className={styles.button_bgc}
                                type='submit'
                            >
                                Submit
                            </CustomButton>
                        </Grid>
                    </Grid>
                </form>
            </CustomComponentContainer>
        </div >
    )
}

export default VendorEdit