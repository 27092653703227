const GroupsStyles = {
    boxContainer: { p: 1, width: '100%', minHeight: '850px', },
    headingTypo: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
        mb: '16px'
    },
    paperBoxContainer: {
        p: 1,
        mb: 2,
    },
    addButton: {
        color: 'white',
        borderColor: '#DC4D28',
        bgcolor: '#DC4D28',
        // mr: 3,
        width: '100%',
        height: '100%',
        maxHeight: 35,
        alignSelf: 'center',
        ':hover': { bgcolor: '#DC4D28' },
    },
    searchfield: {
        alignSelf: 'center',
        width: "100%",
        maxHeight: 40,
    },
    loaderBox: { height: '80%', display: 'flex', justifyContent: "center", alignItems: "center" }
}

export default GroupsStyles