import { Box, Typography } from "@mui/material"
import { AreaSettingsStyle } from "../../../styles/AreaSettingsStyle"
import AreasTable from "../../../features/settings/areas/components/areasTable/AreasTable"

const AreaSettings = (props) => {
    return (
        <Box
            className="area-settings-container pad8 width100 minHeight90vh flex flexDirectionCol flexAlignItemsStart">
            <Typography
                sx={{ ...AreaSettingsStyle.headingTypo, marginBottom: '2vh' }}
            >
                Areas
            </Typography>
            <AreasTable />
        </Box>
    )
}

export default AreaSettings