import { Box } from '@mui/material'
import React from 'react'

const ImageRenderer = (props) => {
    const { src, alt, ...rest } = props
    return (
        <Box component={'img'} src={src} alt={alt} {...rest} />
    )
}

export default ImageRenderer