import React from 'react'
import CustomTypography from '../../../components/texts/CustomTypography'
import CustomHeaderDivider from '../../../components/divider/CustomHeaderDivider'
import { InfoOutlined } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

const CustomSecondaryHeader = ({ tooltip, children, component }) => {
    return (
        <>
            <div className='padgridcardM flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='fontweight600 font18' noWrap>{children}</CustomTypography>
                <Box className='flex flexAlignItemsCenter columngap16'>
                    {component}
                    {tooltip && <Tooltip
                        followCursor={true}
                        arrow={true}
                        title={<Typography fontSize={'14px'}>{tooltip}</Typography>}
                    >
                        <InfoOutlined fontSize="small" sx={{ color: 'grey' }} />
                    </Tooltip>}
                </Box>
            </div >
            <CustomHeaderDivider />
        </>
    )
}

export default CustomSecondaryHeader