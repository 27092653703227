import { PlayCircleOutlined, ShareOutlined, SummarizeOutlined } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import DataView from '../../../components/widgets/dashboard/DataView'

const CameraRecordingComp = (props) => {
    // const theme = useTheme()

    // const currentMode = useSelector(state => state.theme).mode

    // const color = currentMode === 'dark' ? theme.palette.nhance.light : theme.palette.nhance.dark

    const { data } = props


    const pickedObj = {
        cameraStatus: data.cameraStatus,
        liveStreamStatus: data.liveStreamStatus,
        recordingStatus: data.recordingStatus,
    }


    return (
        <Stack height={'80%'} mx={2} >
            <Box height={'100%'}>
                {
                    Object.keys(pickedObj)?.length > 0 &&
                    Object.keys(pickedObj)?.map((ele, i) =>
                        (ele !== 'name' && ele !== 'id') &&
                        <DataView
                            key={i}
                            data={
                                ele === 'cameraStatus' ? `Status: ${pickedObj?.cameraStatus?.toUpperCase()}` :
                                    ele === 'liveStreamStatus' ? `Live Stream: ${pickedObj?.liveStreamStatus ? 'YES' : 'NO'}` :
                                        ele === 'recordingStatus' ? `Recording: ${pickedObj?.recordingStatus ? 'YES' : 'NO'}` : ""
                            }
                            bgcolor={
                                ele === 'cameraStatus' ? (pickedObj?.cameraStatus?.toLowerCase() === 'active' ? '#47D095' : '#F01103') :
                                    ele === 'liveStreamStatus' ? (pickedObj?.liveStreamStatus ? '#47D095' : '#F01103') :
                                        ele === 'recordingStatus' ? (pickedObj?.recordingStatus ? '#47D095' : '#F01103') : 'none'
                            }
                            statusIndicator={true}
                        />
                    )
                }
            </Box>
        </Stack >
    )
}

export default React.memo(CameraRecordingComp)