import { AddCircleOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { IconButton, Switch, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GET, PUT } from '../../../../helpers/http';
import { errorToast } from '../../../../helpers/apiToast';
import Datatable from '../../components/dataTable/Datatable';
import Loader from '../../../../components/loading/Loader';
import CustomTypography from '../../../../components/texts/CustomTypography';
import CustomButton from '../../../../components/button/CustomButton';
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import Modal from '../../../../components/modal/Modal';
import CustomFormField from '../../../../components/formInputs/CustomFormField';

const ContractListing = () => {
    const [contracts, setContracts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [terminationClause, setTerminationClause] = useState('');
    const [terminationId, setTerminationId] = useState('');
    const [error, setError] = useState('');
    const terminationClauseRef = useRef(null);

    const allContractListing = useCallback(async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/contract/list`, "GET CONTRACTS");
            if (response?.status === 200) {
                const vendorsWithId = response?.data?.map(vendor => ({ ...vendor, id: vendor._id }));
                setContracts(vendorsWithId);
            }
        } catch (error) {
            errorToast(error?.response?.data?.message);
        }
    }, []);

    const handleContractId = async (id) => {
        navigate(`/admin/settings/contract/edit/${id}`);
    };

    useEffect(() => {
        allContractListing();
    }, [allContractListing]);

    const handleSwitchChange = useCallback(async ({ terminationId, terminationClause }) => {
        if (!terminationClause) {
            setError('Termination Clause is required');
            return;
        }

        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/contract/terminate/?id=${terminationId}`, { terminationClause }, "UPDATE CONTRACT");
            if (response?.status === 200) {
                allContractListing();
                setModalOpen(false);  // Close the modal on successful submission
            }
        } catch (error) {
            errorToast(error?.response?.data?.message);
        }
    }, [allContractListing]);

    const columns = [
        // { field: '_id', headerName: 'Id', flex: 2 },
        { field: 'title', headerName: 'Title', flex: 1.5 },
        {
            field: 'vendor', headerName: 'Vendor Name', flex: 1, renderCell: (params) => {
                return params?.row?.vendor?.name;
            }
        },
        {
            field: 'startDate', headerName: 'Start Date', flex: 1.5, renderCell: (params) => {
                return (<>{moment(params.value).format('lll ')}</>);
            }
        },
        {
            field: 'endDate', headerName: 'End Date', flex: 1.5, renderCell: (params) => {
                return (<>{moment(params.value).format('lll ')}</>);
            }
        },
        {
            field: 'buildings', headerName: 'Buildings', flex: 1.5, renderCell: (params) => {
                return (<>{params.value.length === 1 ? params.value.map(building => building.name) : `${params.value[0].name} +${params.value.length - 1}`}</>);
            }
        },
        {
            field: 'isActive', headerName: 'Status ', flex: 1.5, renderCell: (params) => {
                return (<>{params.value ? <Typography sx={{ color: 'green !important' }}>ACTIVE</Typography> : <Typography sx={{ color: 'red !important' }}>INACTIVE</Typography>}</>);
            }
        },
        {
            field: 'isTerminated', headerName: 'Terminated', flex: 1, renderCell: (params) => {
                return (
                    <Switch
                        sx={{
                            '& .MuiSwitch-switchBase': {
                                color: params?.value ? 'gray' : 'green',
                                '&.Mui-checked': {
                                    color: params?.value ? 'gray' : 'green',
                                },
                                '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: params?.value ? 'gray' : 'green',
                                }
                            },
                            "& .MuiSwitch-thumb": {
                                color: params?.value ? 'gray' : 'green'
                            },
                            '& .MuiSwitch-track': {
                                backgroundColor: params?.value ? 'gray' : 'green',
                            },
                        }}
                        checked={params?.value}
                        disabled={params?.value}
                        onChange={() => { handleModalToggle(params.row._id) }}
                    />
                );
            }
        },
        {
            field: 'serviceCategories', headerName: 'Service Categories', flex: 1.5, renderCell: (params) => {
                return (<>{params.value.length === 1 ? params.value.map(category => category.name) : `${params.value[0].name} +${params.value.length - 1}`}</>);
            }
        },
        {
            field: 'Actions', headerName: 'Actions', flex: 1,
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton disabled={cellValues?.row?.isTerminated} aria-label="edit" onClick={() => { handleContractId(cellValues?.row?._id) }}>
                            <EditOutlined sx={{ color: cellValues?.row?.isTerminated ? 'gray !important' : '#1976d2 !important' }} />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    const handleAddVendor = () => {
        navigate('/admin/settings/contract/add');
    };

    const handleModalToggle = (id) => {
        setTerminationId(id);
        setModalOpen(!modalOpen);
        setError('');  // Reset error message when opening the modal
        setTimeout(() => {
            if (terminationClauseRef.current) {
                terminationClauseRef.current.focus();
            }
        }, 100);
    };

    return (
        <div className='width100 pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Contracts</CustomTypography>
                <CustomButton
                    variant='contained'
                    className={styles.button_bgc} startIcon={<AddCircleOutlineOutlined className='color-white' />}
                    onClick={handleAddVendor}
                    sx={{ mr: '20px', height: '2.4rem' }}
                >Add Contract
                </CustomButton>
            </div>
            <div className='mrgnsettingpage'>
                {contracts?.length > 0 ? <Datatable columns={columns} rows={contracts} checkBox={false} /> : <Loader height={'89vh'} width={'70%'} />}
            </div>
            <Modal
                modalOpen={modalOpen}
                closeModal={handleModalToggle}
                title={'Termination Clause'}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSwitchChange({ terminationId, terminationClause });
                }}>
                    <CustomFormField
                        value={terminationClause}
                        onChange={(e) => setTerminationClause(e.target.value)}
                        label={'Termination Clause'}
                        name={'terminationClause'}
                        placeholder={'Enter Termination Clause'}
                        inputRef={terminationClauseRef}
                        error={!!error}  // Check if there's an error
                        helperText={error}  // Display the error message
                    />
                    <CustomButton variant='contained' type='submit' sx={{ mt: '10px', width: '6rem' }}>Submit</CustomButton>
                </form>
            </Modal>
        </div>
    );
};

export default ContractListing;
