import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { ReportOutlined } from '@mui/icons-material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BulkUploadFailed({ errorContent, title, modalOpen, handleBulkUploadModalClose, handleBulkUploadModalOpen }) {

    return (
        <Dialog
            open={modalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleBulkUploadModalClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <ReportOutlined sx={{ color: 'red', fontSize: '1rem', marginRight: '0.5rem' }} />
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {errorContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBulkUploadModalClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}