import { Button, Stack, Typography } from "@mui/material"
import { convertToXls } from "../../../helpers/convertToXls"
import { ThemeColors } from "../../../hooks/ThemeColors"
import { WarningAmberRounded } from "@mui/icons-material"

const NotUploadedHolidays = (props) => {
    const { closeNotUploadedHolidaysModal = () => { }, notUploadedHolidays, handleCloseAddMultipleHolidaysExcelModal = () => { } } = props

    const { textColor } = ThemeColors()

    const handleNotUploadedHolidays = () => {
        convertToXls(notUploadedHolidays, 'Not Uploaded Holidays')
        closeNotUploadedHolidaysModal()
        handleCloseAddMultipleHolidaysExcelModal()
    }

    return (
        <Stack spacing={2}>
            <WarningAmberRounded sx={{ fontSize: '100px', alignSelf: 'center' }} color="error" />
            <Typography align="center" sx={{ color: textColor(), fontSize: '18px' }}>{notUploadedHolidays?.length} Holiday(s) not added!</Typography>
            <Button
                className='actionButton'
                onClick={handleNotUploadedHolidays}
                fullWidth
            >
                Download Not Uploaded Holidays Excel
            </Button>
        </Stack>
    )
}

export default NotUploadedHolidays