import { useCallback } from 'react'
import { POST } from '../helpers/http'
import { resetUserDetails } from '../../../redux/features/userSlice'
import { resetBuildings } from '../../../redux/features/buildingsSlice'
import { useDispatch, useSelector } from 'react-redux'

const useHandleLogout = () => {
    // Get the user data from the Redux store
    const user = useSelector((state) => state.user)

    // Redux dispatch function to dispatch actions
    const dispatch = useDispatch()

    // Function to handle the logout process
    const handleLogout = useCallback(async () => {
        // Make a POST request to log out the user
        await POST(`${process.env.REACT_APP_BASE_URL}um/v1/user/logout`, {}, 'user logout')
        // Clear localStorage
        localStorage.clear()
        // Reset user details and buildings in Redux
        dispatch(resetUserDetails())
        dispatch(resetBuildings())
        // Redirect the user to the accounts UI URL with their email as a query parameter
        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${user?.email}`
    }, [dispatch, user?.email])

    return { handleLogout }
}

export default useHandleLogout