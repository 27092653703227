import Header from '../header/Header'
import AppContainer from '../dashboard/App'
import './dashboard.css'
import { useLocation } from 'react-router-dom'
import { ChatComponentContainer } from '../../features/chat/context/ChatContext'

const Dashboard = () => {
    const { pathname } = useLocation();

    return (
        pathname.includes('/maverick') ? <ChatComponentContainer /> : <div>
            <Header />
            <AppContainer />
        </div>
    )
}

export default Dashboard
