import { Box } from "@mui/material"
import Secondary from "../../../components/secondary/Secondary"
import PPMStatistics from "../../../features/plannedPreventativeMaintenance/secondary/PPMStatistics"
import GridTabContainer from "../../../features/plannedPreventativeMaintenance/components/gridTabPPM/GridTabContainer"
import PPMCalendar from "../../../features/plannedPreventativeMaintenance/PPMCalendar"
import Selector from "../../../features/plannedPreventativeMaintenance/Selector"
import PPMModal from "../../../features/plannedPreventativeMaintenance/components/modal/PPMModal"
import PPMProvider from "../../../features/plannedPreventativeMaintenance/context/PPMContextProvider"
import CreatePPMForm from "../../../features/plannedPreventativeMaintenance/CreatePPMForm"
import { useReducer, useState } from "react"
import PPMStatsProvider from "../../../features/plannedPreventativeMaintenance/context/PPMStatsProvider"
import { PPMListReducer } from "../../../features/plannedPreventativeMaintenance/context/PPMListReducer"
import { fetchPPMList } from "../../../features/plannedPreventativeMaintenance/context/PPMListContextProvider"
import PPMListPage from "../../../features/plannedPreventativeMaintenance/PPMListPage"

const PPMStatisticsPage = (props) => {
    const [openCreatePPMModal, setOpenCreatePPMModal] = useState(false)
    const [PPMList, dispatch] = useReducer(PPMListReducer, { isLoaded: false, data: [] });
    const [reload, setReload] = useState(false)

    const handleOpenCreatePPMModal = () => {
        setOpenCreatePPMModal(true)
    }
    const handleCloseCreatePPMModal = () => {
        setOpenCreatePPMModal(false)
        setReload(!reload)
    }

    const triggerList = () => {
        fetchPPMList(dispatch)
    }

    return (
        <Box width={"100%"}>
            <Secondary
                title={"Planned Preventive Maintenance"}
                returnPage={"Dashboard"}
                returnRoute={"/"}
                children={
                    <>
                        <GridTabContainer
                            tabList={["Overview", "Calendar",
                                // "List"
                            ]}
                            tabPanels={[
                                <PPMStatistics reload={reload} />,
                                <PPMCalendar triggerList={triggerList} />,
                                // <PPMListPage triggerList={triggerList} />
                            ]}
                            children={
                                <Selector
                                    // date={date}
                                    // setDate={setDate}
                                    handleOpenCreatePPMModal={handleOpenCreatePPMModal}
                                />
                            }
                        />
                    </>
                }
            />
            <PPMProvider>
                <PPMStatsProvider>
                    <PPMModal
                        modalOpen={openCreatePPMModal}
                        closeModal={handleCloseCreatePPMModal}
                        title={'Create PPM'}
                        modalWidth="50vw"
                        children={
                            <PPMProvider>
                                <CreatePPMForm triggerList={triggerList} handleCloseModal={handleCloseCreatePPMModal} />
                            </PPMProvider>
                        }
                    />
                </PPMStatsProvider>
            </PPMProvider>
        </Box>
    )
}

export default PPMStatisticsPage