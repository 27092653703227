import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { chatBackgroundVideo } from '../../../../../data/imageUrls';


const VideoBackground = ({ children }) => {
    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            className='chat-container'
        >
            <video ref={videoRef} autoPlay muted loop id="background-video">
                <source src={chatBackgroundVideo.src} type="video/mp4" />
            </video>
            {children}
        </Box>
    )
}

export default VideoBackground