import React from 'react'
import ReportHeaderFooter from './ReportHeaderFooterAM'
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'

const ReportStatsAM = (props) => {
    const theme = useTheme()
    const themeMode = useSelector(state => state.theme?.darkTheme)
    const bgcolor = themeMode?'#282a2e':'#fff'

    const { statistics } = props

    const labels = {
        "totalAssets": "TOTAL ASSETS",
        "activeAssets": "ACTIVE ASSETS",
        "underRepair": "UNDER REPAIR",
        "assetsLost": "ASSETS LOST",
        "disposedOrDeleted": "DISPOSED/DELETED",
        "underMaintenance": "UNDER MAINTENANCE",
        "expiringWarranty": "EXPIRING WARRANTY"
    }

    return (
        <ReportHeaderFooter>
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={2} >
                    {statistics && Object.keys(statistics).map((ele, i) => (
                        ele !== 'disposedOrDeleted' && <Grid item xs={2} key={i}>
                            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', borderRadius: '4px', bgcolor, border: `1px solid #808080` }}>
                                <Box width={'100%'}>
                                    <Stack spacing={1} padding={'1%'}>
                                        <Typography sx={{ fontSize: ele === 'averageResolutionTime' ? '30px' : '50px', fontWeight: '500', textAlign: 'center' }} >{statistics[ele]}</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '10px', textAlign: 'center' }}>{labels[ele]}</Typography>
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ReportHeaderFooter>
    )
}

export default ReportStatsAM