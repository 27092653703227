import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../components/facilityManager/helpers/http'

const initialState = {
    faults: [],
    isLoaded: false,
    total: 0,
    open: 0,
    inProgress: 0,
    closed: 0
}

export const asyncFetchFaultReports = createAsyncThunk('frSliceFidelity/asyncFetchFaultReports', async (bid) => {
    try {
        const response = await GET(`${process.env.REACT_APP_BASE_URL}fr/v1/faultreports?buildingId=${bid}`, 'Fault reports')
        if (response?.status === 200) {
            return response?.data
        }
    } catch (error) {
        console.log(error)
    }
})

const frSliceFidelity = createSlice({
    name: 'frSliceFidelity',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(asyncFetchFaultReports.fulfilled, (state, action) => {
            let open = 0, closed = 0, inProgress = 0;
            action.payload?.faults?.forEach(ele => {
                if (ele?.status === 'open') open++;
                if (ele?.status === 'assigned') inProgress++;
                if (ele?.status === 'closed') closed++;
            });
            state.faults = action.payload.faults
            state.isLoaded = true
            state.total = action.payload.total
            state.open = open
            state.closed = closed
            state.inProgress = inProgress
        })
    }
})

export default frSliceFidelity.reducer