

export const AreaSettingsStyle = {
    headingTypo: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
    }
}

