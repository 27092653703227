export const PPMStatsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PPM_STATS':
            return {
                ...state,
                ppmStats: action.payload?.ppmStats,
                isLoaded: action.payload?.isLoaded,
            }
        default:
            return state
    }
}