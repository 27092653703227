import React from 'react'
import Modal from '../../../../../components/modal/Modal'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const ViewNotificationMappingModal = (props) => {
    const { openViewMappingModal, handleCloseViewModal, selectedViewMapping } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    return (
        <Modal
            modalOpen={openViewMappingModal}
            closeModal={handleCloseViewModal}
            title={selectedViewMapping?.name}
        >
            <Stack spacing={1}>
                <Typography sx={{ fontSize: '16px', color }}><b>Services: </b>{selectedViewMapping?.servicesReference?.map(service => `${service?.name} - ${service?.notificationType}`)?.join(', ')}</Typography>
                <Typography sx={{ fontSize: '16px', color }}><b>Name: </b>{selectedViewMapping?.name}</Typography>
                <Typography sx={{ fontSize: '16px', color }}><b>Categories: </b>{selectedViewMapping?.categoriesReference?.map(category => category?.name)?.join(', ')}</Typography>
                {
                    selectedViewMapping?.departmentsReference?.length > 0 &&
                    <Typography sx={{ fontSize: '16px', color }}><b>Departments: </b>{selectedViewMapping?.departmentsReference?.map(department => department?.name)?.join(', ')}</Typography>
                }
                {
                    selectedViewMapping?.groupsReference?.length > 0 && <>
                        <Typography sx={{ fontSize: '16px', color }}><b>Groups: </b>{selectedViewMapping?.groupsReference?.map(group => group?.name)?.join(', ')}</Typography>
                        <Typography sx={{ fontSize: '16px', color }}><b>Locations: </b>{selectedViewMapping?.locationsGeoReference?.map(location => location?.locationReference?.name)?.join(', ')}</Typography>
                        <Typography sx={{ fontSize: '16px', color }}><b>Buildings: </b>{selectedViewMapping?.locationsGeoReference?.map(location => location?.buildingsReference?.map(building => building?.name))?.join(', ')}</Typography>
                    </>
                }
            </Stack>
        </Modal>
    )
}

export default ViewNotificationMappingModal