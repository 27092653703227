import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MuiDrawer from '@mui/material/Drawer'
import { Divider, List, ListItem, ListItemButton, ListItemIcon, Toolbar, styled, ListItemText, Box, Tooltip } from '@mui/material'
import { toggleNavigationDrawer } from '../../../../redux/features/themeSlice'
import { Close } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { menuDark, menuWhite, settings, settingsWhite, cctv, cctvWhite, userManagement, userManagementWhite, wallDark, wallLight, communitiesDark, communitiesLight, energy, energyWhite, kioskConfig, kioskConfigWhite, meetingRoom, meetingRoomWhite, attendance, attendanceWhite, ppm, ppmWhite } from '../../../../data/imageUrls'
import ImageRenderer from '../../components/image/ImageRenderer'
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';

const drawerWidth = '14%'
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen + 500,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `72px`,
    [theme.breakpoints.up('sm')]: {
        width: `72px`,
    },
})

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const AdminNavigation = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const themeMode = useSelector(state => state.theme.darkTheme)
    const wallServiceEnabled = useSelector((state) => state?.configurations?.services?.wall)
    const communitiesServiceEnanbled = useSelector(state => state?.configurations?.services?.communities)
    const isCultfitLogged = useSelector((state) => state?.user?.tenantCode === "cultfit")
    const isEnergyServiceEnabled = useSelector(state => state?.configurations?.services?.energy)
    const isMRTabServiceEnabled = useSelector(state => state?.configurations?.services?.meetingRoomsTab)
    const isVisitorServiceEnabled = useSelector(state => state?.configurations?.services?.visitorManagement)
    const isAttendanceServiceEnabled = useSelector(state => state?.configurations?.services?.attendance)
    // const isDynamicFormServiceEnabled = useSelector(state => state?.configurations?.services?.dynamicForm)

    const dispatch = useDispatch()

    const color = themeMode ? '#fff' : '#000'

    const returnIcon = (LightIcon, DarkIcon, path) =>
        themeMode ? (
            <Box sx={{ ml: 0.5 }} height={24} width={20} >
                <ImageRenderer src={LightIcon} />
            </Box>
        ) : (
            <Box sx={{ ml: 0.5 }} height={24} width={20} >
                {location?.pathname?.includes(path) ? <ImageRenderer src={LightIcon} /> : <ImageRenderer src={DarkIcon} />}
            </Box>
        )

    const listItems = [
        {
            text: 'User Management',
            icon: returnIcon(userManagementWhite.src, userManagement.src, '/admin/usermanagement'),
            onClick: () => navigate('/admin/usermanagement'),
            path: '/admin/usermanagement',
            isServiceEnabled: true
        },
        {
            text: 'Settings',
            icon: returnIcon(settingsWhite.src, settings.src, '/admin/settings/departments'),
            onClick: () => navigate('/admin/settings/departments'),
            path: '/admin/settings',
            isServiceEnabled: true,
        },
        {
            text: 'Wall',
            icon: returnIcon(wallLight.src, wallDark.src, '/admin/wall/content-list'),
            onClick: () => navigate('/admin/wall/content-list'),
            path: '/admin/wall',
            isServiceEnabled: wallServiceEnabled
        },
        {
            text: 'Communities',
            icon: returnIcon(communitiesLight.src, communitiesDark.src, '/admin/communities'),
            onClick: () => navigate('/admin/communities'),
            path: '/admin/communities',
            isServiceEnabled: communitiesServiceEnanbled
        },
        {
            text: 'CCTV',
            icon: returnIcon(cctvWhite.src, cctv.src, '/admin/cctv'),
            onClick: () => navigate('/admin/cctv'),
            path: '/admin/cctv',
            isServiceEnabled: isCultfitLogged,
        },
        {
            text: 'Energy Configurations',
            icon: returnIcon(energyWhite.src, energy.src, 'admin/energy'),
            onClick: () => navigate('/admin/energy/sensor-groups'),
            path: '/admin/energy',
            isServiceEnabled: isEnergyServiceEnabled,
        },
        {
            text: 'Meeting Room Tab Configurations',
            icon: returnIcon(meetingRoomWhite.src, meetingRoom.src, 'admin/meeting-room-tab-configurations'),
            onClick: () => navigate('/admin/meeting-room-tab-configurations'),
            path: '/admin/meeting-room-tab-configurations',
            isServiceEnabled: isMRTabServiceEnabled,
        },
        {
            text: 'Visitor Kiosk Configurations',
            icon: returnIcon(kioskConfigWhite.src, kioskConfig.src, 'admin/visitor-kiosk-configurations'),
            onClick: () => navigate('/admin/visitor-kiosk-configurations'),
            path: '/admin/visitor-kiosk',
            isServiceEnabled: isVisitorServiceEnabled,
        },
        {
            text: 'Attendance',
            icon: returnIcon(attendanceWhite.src, attendance.src, 'admin/attendance'),
            onClick: () => navigate('/admin/attendance'),
            path: '/admin/attendance',
            isServiceEnabled: isAttendanceServiceEnabled,
        },
        {
            text: 'Dynamic Form',
            icon: returnIcon(ppmWhite.src, ppm.src, '/admin/dynamic-form'),
            onClick: () => navigate('/admin/dynamic-form'),
            path: '/admin/dynamic-form',
            isServiceEnabled: true,

        }
    ]

    const handleDrawerOpen = () => {
        setOpen(true)
        dispatch(toggleNavigationDrawer())
    }

    const handleDrawerClose = () => {
        setOpen(false)
        dispatch(toggleNavigationDrawer())
    }

    return (
        <Drawer
            variant="permanent"
            open={open}
            className='sidebar-drawer'
            PaperProps={{
                className: 'flex zIndex1 width72 theme-component-background',
            }}
        >
            <Toolbar />
            <List disablePadding>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    {!open ? (
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: color,
                                    justifyContent: 'center',
                                    ml: -2.5,
                                    mt: 1
                                }}
                                onClick={handleDrawerOpen}
                            >
                                <Box sx={{ marginInlineStart: -1.25 }} mt={-3} height={24} width={20} >
                                    {themeMode ? <ImageRenderer src={menuWhite.src} alt={menuWhite.alt} /> : <ImageRenderer src={menuDark.src} alt={menuDark.alt} />}
                                </Box>
                            </ListItemIcon>
                        </ListItemButton>
                    ) : (
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'right',
                                px: 2.5,
                                cursor: 'default',
                            }}
                        >
                            <ListItem
                                disablePadding
                                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                                <Close
                                    className='theme-icons'
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleDrawerClose()}
                                />
                            </ListItem>
                        </ListItemButton>
                    )}
                </ListItem>
                {listItems.map((item, index) => (
                    <Tooltip placement="right" title={item.text} key={item.text}>
                        <ListItem
                            disablePadding
                            sx={{ display: 'block' }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor:
                                        location.pathname.includes(item?.path)
                                            ? '#dc4d28'
                                            : 'none',
                                    '&:hover': {
                                        backgroundColor: '#dc4d28',
                                    },
                                }}
                                disabled={!item?.isServiceEnabled}
                                onClick={item.onClick}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color:
                                            location.pathname === item.path ? 'white' : color,
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        fontSize: '14px',
                                        color:
                                            location.pathname === item.path ? 'white' : color,
                                    }}
                                    disableTypography
                                />
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
            <Divider />
        </Drawer>
    )
}

export default AdminNavigation