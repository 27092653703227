import Grid from "@mui/material/Unstable_Grid2"
import PostStatusBar from "./PostStatusBar";
import { useEffect, useState } from "react";
import { GET } from "../../../../../helpers/http";
import { useLocation } from "react-router-dom";
import Post from "./Post";
import Comment from "./Comment";
import { errorToast } from "../../../../../helpers/apiToast"
import Reactions from "./Reactions";

const ManagePostContainer = (props) => {
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [postData, setPostData] = useState({})

    const getPostDetail = async () => {
        try {
            const endPointPost = `${process.env.REACT_APP_URL}wall/v1/admin/item?type=${"post"}&id=${location?.state.id}`
            const postResult = await GET(endPointPost, "Fetch Single Post")
            setPostData(postResult?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const postDataUpdater = (data) => {
        setPostData(data)
    }

    //initialCall
    useEffect(() => {
        getPostDetail()
    }, [])


    return (
        <>
            <Grid container spacing={2}
                sx={{ width: "inherit", marginX: "0px", marginTop: "0.5rem" }}>
                <Grid xs={12} px={0} >
                    <PostStatusBar loading={loading} data={postData} postDataUpdater={postDataUpdater} />
                </Grid>
                <Grid xs={5} md={12} lg={5}
                    sx={{ paddingLeft: "0px", height: "inherit" }} >
                    <Post loading={loading} data={postData} />
                </Grid>
                <Grid xs={4} md={6} lg={4}
                    sx={{ height: "inherit" }} >
                    <Comment id={location?.state?.id} />
                </Grid>
                <Grid xs={3} md={6} lg={3}
                    sx={{ paddingRight: "0px", height: "inherit" }} >
                    <Reactions id={location?.state.id} />
                </Grid>
            </Grid >
        </>
    )
}

export default ManagePostContainer