import { Box, Typography, Stack, Button, MenuItem, Paper, CircularProgress, Switch } from "@mui/material"
import CommonStyles from "../../styles/CommonStyles"
import { runDVRValidation } from "../../helpers/runDVRValidation"
import { useEffect, useState, lazy, useRef } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { useDVR, useDVRDispatch } from './DVRContext'
import { POST, GET, PUT } from '../../helpers/http'
import { successToast, errorToast } from '../../helpers/apiToast'
import { dvrDataParser } from "../../helpers/dvrDataParser"
import { useSelector } from 'react-redux'
import { ThemeColors } from "../../hooks/ThemeColors"

const TextInput = lazy(() => import('../../components/formInputs/TextInput'))
const DVRBackdrop = lazy(() => import('../../components/Backdrop/DVRBackdrop'))
const DVRDashboard = lazy(() => import('../../components/Dashboard/DVRDashboard'))

const DVREditForm = (props) => {
    const formObj = { ipAddress: "", username: "", password: "", port: "", gatewayIpAddress: "", subTenantReference: "", buildingReference: "", locationReference: "", name: "", requestId: "", isDisabled: false }
    const [formData, setFormData] = useState(formObj)
    const [changedFormData, setChangedFormData] = useState(formObj)
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [EditDVRVerification, setEditDVRVerification] = useState(false)
    const [selectorData, setSelectorData] = useState({ subTenants: [], buildings: [], locations: [] })
    // const [isPasswordReset, setIsPasswordReset] = useState(false)
    // const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const timerIdRef = useRef(null);
    const tenantCode = useSelector(state => state?.user?.tenantCode)
    const { commonSX } = ThemeColors()

    const currentMode = useSelector(state => state.theme.darkTheme)

    const backgroundPaper = currentMode ? "#282a2e" : "white"

    const [cameras, setCameras] = useState([])
    const [hardDrives, setHardDrives] = useState([])
    const [dvr, setDvr] = useState({})


    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const inEditPage = location.pathname.includes('/admin/cctv/edit')

    const DVRstate = useDVR()
    const dispatch = useDVRDispatch()

    const EditFormLength = Object.keys(DVRstate.selectedDvr).length

    useEffect(() => {
        if (Object.keys(formErrors).length !== 0) {
            const errors = runDVRValidation(formData, EditDVRVerification)
            setFormErrors(errors)
        }
    }, [formData])


    useEffect(() => {
        (async () => {
            try {
                if (EditFormLength !== 0) {
                    //get DVR Data
                    const getDVR = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr?dvrUUID=${DVRstate.selectedDvr.UUID}`)

                    //get buildings and location
                    const getBuildings = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/building/list?subTenantId=${getDVR.data.dvrConfig.subTenantReference._id}`)
                    const getSubTenants = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/subtenant/list`)

                    setSelectorData({
                        ...selectorData, subTenants: getSubTenants?.data, buildings: getBuildings?.data
                    });

                    const gotDVR = getDVR.data
                    setFormData({
                        ipAddress: gotDVR?.dvrConfig?.connectionDetails?.ipAddress,
                        username: gotDVR?.dvrConfig?.connectionDetails?.username,
                        port: gotDVR?.dvrConfig?.connectionDetails?.port,
                        password: gotDVR?.dvrConfig?.connectionDetails?.password,
                        gatewayIpAddress: gotDVR?.dvrConfig?.connectionDetails?.gatewayIpAddress,
                        subTenantReference: gotDVR?.dvrConfig?.subTenantReference?._id,
                        buildingReference: gotDVR?.dvrConfig?.buildingReference?._id,
                        locationReference: gotDVR?.dvrConfig?.locationReference?._id,
                        name: gotDVR?.dvrConfig?.name,
                        isDisabled: gotDVR?.dvrConfig?.isDisabled,
                    })
                    setDvr(dvrDataParser(gotDVR?.dvrData))
                    setCameras(gotDVR?.dvrData?.cameras?.list)
                    setHardDrives(gotDVR?.dvrData?.hardwareDetails?.hardDisks)
                    setPageLoaded(true)
                } else {
                    if (inEditPage) {
                        const getEditableDVR = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr?dvrUUID=${params.id}`)
                        dispatch({ type: "UPDATE_SELECTED_DVR", payload: getEditableDVR?.data?.dvrConfig })
                        setPageLoaded(true)
                    }
                }
            } catch (error) {
                if (error?.response?.data?.message) {
                    errorToast(error?.response?.data?.message)
                } else {
                    errorToast(error?.message)
                }
            }
        })()
    }, [EditFormLength])

    useEffect(() => {
        if (formData.buildingReference && selectorData.buildings.length !== 0) {
            const getLocation = selectorData.buildings.find((ele) => ele._id === formData.buildingReference)?.location
            setSelectorData({ ...selectorData, locations: [getLocation] })
            setFormData({ ...formData, locationReference: getLocation?._id })
            setChangedFormData({ ...changedFormData, locationReference: getLocation?._id })
        }
    }, [formData.buildingReference, selectorData.buildings])

    // const handleClickShowPassword = () => {
    //     setShowPassword((show) => !show)
    // }

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault()
    // }

    const handleFormInputs = async (e, field) => {
        setChangedFormData({ ...changedFormData, [field]: e.target.value })
        setFormData({ ...formData, [field]: e.target.value })
        if (field === "subTenantReference") {
            const getBuildings = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/building/list?subTenantId=${e.target.value}`)
            setSelectorData({ ...selectorData, buildings: getBuildings?.data })
        }
        // if (field === "buildingReference") {
        //     setSelectorData({ ...selectorData, locations: selectorData?. })
        // }
    }

    const cancelButtonHandle = () => {
        setFormData(formObj)
        navigate('/admin/cctv')
        if (EditFormLength !== 0) {
            dispatch({ type: "CLEAR_SELECTED" })
        }
    }

    const switchHandlerFunction = (e) => {
        setFormData({ ...formData, isDisabled: !e.target.checked })
        setChangedFormData({ ...changedFormData, isDisabled: !e.target.checked })
    }


    const EditDVRHandle = async (e) => {
        try {
            e.preventDefault()
            //validate Form
            const errors = runDVRValidation(formData, EditDVRVerification)
            setFormErrors(errors)

            if (Object.keys(errors).length === 0) {
                const connFields = ['ipAddress', "username", "password", "port", "gatewayIpAddress"]

                const formEdit = Object.entries(changedFormData).reduce((initial, value) => {
                    if (connFields.includes(value[0]) && value[1] !== '') {
                        initial.connectionDetails[value[0]] = value[1]
                    } else if (!connFields.includes(value[0]) && value[1] !== '') {
                        initial[value[0]] = value[1]
                    }
                    return initial
                }, { connectionDetails: {} })
                formEdit.dvrUUID = params.id
                formEdit.subTenantReference = formData.subTenantReference

                if (!EditDVRVerification) {
                    const getRequestIdOrData = await POST(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check`, formEdit, "DVR VERIFICATION")
                    if (getRequestIdOrData.data.requestId) {
                        setLoading(true)
                        const timer = setInterval(async () => {
                            try {
                                const checkResult = await GET(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr/check-result?requestId=${getRequestIdOrData?.data?.requestId}`, "DVR CHECK")

                                if (checkResult?.status === 200) {

                                    if (checkResult?.data?.error) {
                                        // setVerifyError(!verifyError)
                                        setDvr({})
                                        setCameras([])
                                        setHardDrives([])
                                        setErrorMessage(checkResult?.data?.error)
                                        errorToast(`DVR CHECK: ${checkResult?.data?.error}`)
                                    }
                                    setLoading(false)
                                    clearInterval(timer)
                                    setEditDVRVerification(!EditDVRVerification)
                                    setFormData({ ...formData, requestId: getRequestIdOrData?.data?.requestId })
                                }
                            } catch (error) {
                                if (error.response.status === 404) {
                                    errorToast(`VERIFY DVR:${error.response.data.message}`)
                                }
                                setLoading(false)
                                clearInterval(timer)
                                if (error?.response?.data?.message) {
                                    errorToast(error?.response?.data?.message)
                                } else {
                                    errorToast(error?.message)
                                }
                            }
                        }, 5000)
                        timerIdRef.current = timer;
                    } else {
                        successToast("DVR Updated")
                        dispatch({ type: "UPDATE_DVR", payload: getRequestIdOrData.data.dvrConfig })
                        navigate('/admin/cctv')
                    }

                } else {
                    // EDIT DVR
                    const updateForm = {}
                    updateForm.requestId = formData.requestId
                    // updateForm.buildingReference = formData.buildingReference
                    // updateForm.locationReference = formData.locationReference
                    // updateForm.subTenantReference = formData.subTenantReference

                    const result = await PUT(`${process.env.REACT_APP_JOBS_URL}${tenantCode}/dvr`, updateForm, "DVR UPDATE")
                    successToast("DVR updated")

                    dispatch({ type: "UPDATE_DVR", payload: result.data.dvrConfig })
                    navigate('/admin/cctv')
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                errorToast(error?.response?.data?.message)
            } else {
                errorToast(error?.message)
            }
        }
    }

    const cancelDVRCheck = (timerId) => {
        setLoading(false)
        if (timerIdRef.current) {
            clearInterval(timerIdRef.current);
            timerIdRef.current = null;
        }
    }

    return (
        <>
            <Stack sx={{ flexDirection: { md: 'row', xs: "column" } }} justifyContent='space-around' width='100%'>
                <Box sx={{ ...CommonStyles.OuterBox, minHeight: "89.9vh", maxHeight: "100%" }} >
                    <Typography sx={{ ...CommonStyles.pageHeading, marginBottom: "0px" }}>{!EditDVRVerification ? "Edit Form" : "DVR Verification"}</Typography>
                    {pageLoaded ? <Box sx={{ ...CommonStyles.centerDiv, width: '100%' }}
                        component='form'
                        onSubmit={EditDVRHandle} >
                        <Stack
                            sx={{ flexDirection: { lg: 'row', md: "column" } }}
                            gap={2}
                            width={EditFormLength !== 0 ? "100%" : "undefined"}
                        >
                            <>
                                <Stack sx={{ marginX: "auto" }}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            display: "flex",
                                            width: { sm: "60vw", md: "60vw", lg: "35vw", xl: "28vw" },
                                            flexDirection: "column",
                                            backgroundColor: backgroundPaper,
                                            padding: "1rem",
                                            marginLeft: "1rem",
                                            marginTop: ".5rem",
                                            gap: "1rem"
                                        }}
                                    >
                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            label="IP Address"
                                            name='ipAddress'
                                            sx={commonSX}
                                            value={formData.ipAddress}
                                            helperText={formErrors.ipAddress}
                                            error={formErrors.ipAddress && true}
                                            onChange={(e) => handleFormInputs(e, 'ipAddress')}
                                        />

                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            label="User Name"
                                            name='username'
                                            sx={commonSX}
                                            value={formData.username}
                                            helperText={formErrors.username}
                                            error={formErrors.username && true}
                                            onChange={(e) => handleFormInputs(e, 'username')}
                                        />

                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            label="Port"
                                            name='port'
                                            sx={commonSX}
                                            value={formData.port}
                                            helperText={formErrors.port}
                                            error={formErrors.port && true}
                                            onChange={(e) => handleFormInputs(e, "port")}
                                        />


                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            label="Gateway IP Address"
                                            name='gatewayIpAddress'
                                            sx={commonSX}
                                            value={formData.gatewayIpAddress}
                                            helperText={formErrors.gatewayIpAddress}
                                            error={formErrors.gatewayIpAddress && true}
                                            onChange={(e) => handleFormInputs(e, "gatewayIpAddress")}
                                        />

                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            select
                                            label="Select a Subtenant"
                                            sx={commonSX}
                                            name='subTenantReference'
                                            value={formData.subTenantReference}
                                            helperText={formErrors.subTenantReference}
                                            error={formErrors.subTenantReference && true}
                                            onChange={(e) => handleFormInputs(e, "subTenantReference")}
                                        >
                                            {selectorData.subTenants.map((subT) => {
                                                return <MenuItem key={subT._id} value={subT._id}>{subT.name}</MenuItem>
                                            })}
                                        </TextInput>

                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            label="Name"
                                            name='name'
                                            sx={{ ...commonSX, width: (EditFormLength === 0) ? "20rem" : null }}
                                            value={formData.name}
                                            helperText={formErrors.name}
                                            error={formErrors.name && true}
                                            onChange={(e) => handleFormInputs(e, "name")}
                                        />
                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            select
                                            label="Select a Building"
                                            name='buildingReference'
                                            sx={{ ...commonSX, width: (EditFormLength === 0) ? "20rem" : null }}
                                            value={formData.buildingReference}
                                            helperText={formErrors.buildingReference}
                                            error={formErrors.buildingReference && true}
                                            onChange={(e) => handleFormInputs(e, "buildingReference")}
                                        >
                                            {selectorData.buildings.map((build) => {
                                                return <MenuItem key={build._id} value={build._id}>{build.name}</MenuItem>
                                            })}
                                        </TextInput>

                                        <TextInput
                                            disabled={EditDVRVerification ? true : false}
                                            select
                                            label="Select a Location"
                                            name='locationReference'
                                            sx={{ ...commonSX, width: (EditFormLength === 0) ? "20rem" : null }}
                                            value={formData?.locationReference}
                                            helperText={formErrors.locationReference}
                                            error={formErrors.locationReference && true}
                                            onChange={(e) => handleFormInputs(e, "locationReference")}
                                        >
                                            {selectorData?.locations?.map((loc) => {
                                                return <MenuItem key={loc?._id} value={loc?._id}>{loc?.name}</MenuItem>
                                            })}
                                        </TextInput>


                                        <Stack direction='row' alignItems='center'>
                                            <Typography>change status</Typography>
                                            <Switch
                                                disabled={EditDVRVerification ? true : false}
                                                checked={!formData.isDisabled}
                                                onChange={switchHandlerFunction}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Stack>

                                        {/* {!isPasswordReset && <Button sx={{ color: "#dc4d28", paddingTop: "0px", fontWeight: "500", fontSize: "15px" }} onClick={() => setIsPasswordReset(!isPasswordReset)}>Reset Password</Button>} */}

                                        {/* {isPasswordReset &&
                                            <Stack gap={1} direction="row">
                                                <TextInput
                                                    disabled={EditDVRVerification ? true : false}
                                                    sx={{ width: "80%" }}
                                                    label="Password"
                                                    name='password'
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={formData.password}
                                                    helperText={formErrors.password}
                                                    error={formErrors.password && true}
                                                    onChange={(e) => handleFormInputs(e, "password")}
                                                    InputProps={
                                                        {
                                                            endAdornment: < InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    }
                                                />
                                                <Button onClick={() => setIsPasswordReset(false)} sx={{ ...CommonStyles.actionButton, width: "20%", bgcolor: 'black', fontSize: '14px', py: 1, height: "3rem" }}>Cancel</Button>
                                            </Stack>
                                        } */}
                                        <Box sx={{ display: 'flex', marginX: 'auto', columnGap: 2, width: { sm: "50vw", md: "50vw", lg: "30vw", xl: "25vw" }, height: "4em" }}>
                                            <Button onClick={cancelButtonHandle} sx={{ ...CommonStyles.actionButton, width: "50%", fontSize: '14px', py: 1, bgcolor: "black" }}>Cancel</Button>
                                            <Button
                                                // disabled={EditDVRVerification ? true : false}
                                                type="submit" sx={{ ...CommonStyles.actionButton, width: "50%", fontSize: '14px', py: 1 }}>
                                                {EditDVRVerification ? "Verify/Update DVR" : "Verify/Update DVR"}
                                            </Button>
                                        </Box>

                                    </Paper>
                                </Stack>
                                <DVRBackdrop cancelDVRCheck={cancelDVRCheck} loading={loading} message={"Getting DVR information..."} />
                            </>
                            <DVRDashboard loading={loading} dvr={dvr} hardDrives={hardDrives} cameras={cameras} errorMessage={errorMessage} />
                        </Stack>
                    </Box> : <CircularProgress sx={{ color: "#dc4d28", margin: "auto" }} />}
                </Box>
            </Stack >
        </>
    )
}


export default DVREditForm