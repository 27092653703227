const ReportHeaderFooterStyles = {
    mainContainer: {
        height: '100vh',
        padding: '5%',
    },
    reportContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    headerContainer: {
        height: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    headerBuildingLocationTypography: {
        fontSize: '15px',
        fontWeight: 200
    },
    headerServiceNameTypography: {
        fontSize: '18px',
        fontWeight: 500
    },
    bodyContainer: {
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
    }
}

export default ReportHeaderFooterStyles