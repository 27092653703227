import { IconButton } from '@mui/material'
import React, { memo } from 'react'

const CustomIconButton = ({ sx, children, width, ...rest }) => {
    return (
        <IconButton
            aria-label="delete"
            disableRipple
            sx={{
                padding: 0, justifyContent: 'flex-start', height: '100%',
                alignItems: 'flex-end', width: 'fit-content', cursor: 'pointer',
                ...sx
            }}
            {...rest}
        >
            {children}
        </IconButton>
    )
}

export default memo(CustomIconButton)