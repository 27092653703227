import { useEffect, useState } from "react";
import { GET, PUT } from "../../../helpers/http.js";
import { Box, Grid, IconButton, Paper, Stack, Button, Tooltip, Typography, } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import Datatable from "../../../components/datatable/Datatable.js";
import CommonStyles from "../../../styles/CommonStyles.js";
import DepartmentSettingsStyles from "../../../styles/DepartmentSettingStyles.js";
import AttendanceModal from "../../../features/attendance/modal/AttendanceModal.js";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../../helpers/apiToast.js";
import moment from "moment";
import TextInput from "../../../components/formInputs/TextInput.js";
import GenerateReportModal from "../../../components/generateReportModal/GenerateReportModal.jsx";
import AssessmentIcon from '@mui/icons-material/Assessment';


const Attendance = () => {

    const [attendanceData, setAttendanceData] = useState([]);
    const [empData, setEmpData] = useState({});
    const [updateTime, setUpdateTime] = useState(moment().format("HH:mm"));
    const [dateForAttendance, setDateForAttendance] = useState(moment().format("YYYY-MM-DD")); //Date to be selected for attendance

    const [openModal, setOpenModal] = useState(false);
    const [reportModalOpen, setReportModal] = useState(false)
    const [employeeCode, setEmployeeCode] = useState("")

    const attendanceIsSubscribed = useSelector((state) => state.configurations?.services?.attendance);

    const putEndpoint = `${process.env.REACT_APP_URL}at/v1/updateOne?empCode=${empData?.empCode}&attendanceDate=${dateForAttendance}`;

    const themeMode = useSelector(state => state.theme.darkTheme)

    const updateAttendance = async () => {
        try {

            const { data } = await PUT(putEndpoint, { endTime: updateTime }, "update Attendance");

            const result = attendanceData.map((emp) => {
                if (emp.empCode === data.empCode) {
                    return {
                        ...data,
                        fullName: empData.fullName,
                    };
                } else return emp;
            });
            setAttendanceData(result);
            setOpenModal(false);
            successToast("Successfully Updated Attendance");
        } catch (error) {
            console.log({ error });
        }
    };

    useEffect(() => {
        const getEndpoint = `${process.env.REACT_APP_URL}at/v1/all?attendanceDate=${dateForAttendance ?? ""}`;

        const fetchAttendance = async () => {
            try {
                const { data } = await GET(getEndpoint, "fetch Attendance");
                setAttendanceData(data);
            } catch (error) {
                // console.log({ error });
                errorToast(error?.response?.data);
            }
        };
        if (attendanceIsSubscribed) {
            fetchAttendance();
        }
    }, [dateForAttendance, attendanceIsSubscribed]);

    const getEmpData = (empData) => {
        setOpenModal(true);
        setEmpData(empData);
    };

    const updateEmployeeAttendance = () => {
        updateAttendance();
    };


    const handleGenerateReport = ({ empCode }) => {
        setReportModal(true)
        if (empCode) setEmployeeCode(empCode)
    }

    const closeReportModal = () => {
        setReportModal(false)
        setEmployeeCode("")
    }


    const columns = [
        {
            field: "fullName",
            headerName: "Employee Name",
            width: 250,
        },
        {
            field: "empCode",
            headerName: "Employee Code",
            width: 200,
        },
        {
            field: "startTime",
            headerName: "Start Time",
            width: 200,
            valueGetter: (params) => `${params.row?.isAttendanceModified
                ? new Date(params.row?.modifiedStartTime).toLocaleTimeString()
                : new Date(params.row?.startTime).toLocaleTimeString()
                }`,
        },
        {
            field: "endTime",
            headerName: "End Time",
            width: 200,
            valueGetter: (params) =>
                `${params.row?.isAttendanceModified
                    ? new Date(params.row?.modifiedEndTime).toLocaleTimeString()
                    : new Date(params.row?.endTime).toLocaleTimeString()
                }`,
        },
        {
            field: "totalHours",
            headerName: "Total Time (HH:mm)",
            width: 200,
            valueGetter: (params) => `${params.row?.isAttendanceModified ? params.row?.modifiedTotalHours : params.row?.totalHours}`,
        },
        {
            field: "modifiedBy",
            headerName: "Modified By",
            width: 150,
            valueGetter: (params) => `${params.row?.isAttendanceModified ? params.row?.modifiedBy?.name : "Not Modified"}`,
        },
        {
            field: "Actions",
            headerAlign: "center",
            align: "right",
            renderCell: (cellValues) => {
                return (
                    <Stack direction={"row"} spacing={2}>
                        <Tooltip title="Regularize" placement="top">
                            <IconButton
                                onClick={() => getEmpData(cellValues?.row)}
                                disabled={cellValues?.row?.isAttendanceModified}
                            >
                                <EditOutlined
                                    fontSize="small"
                                    sx={{
                                        color: cellValues?.row?.isAttendanceModified ? "grey !important" : "#DC4D28 !important",
                                    }}
                                />

                                <Typography
                                    color={
                                        cellValues?.row?.isAttendanceModified ? "grey !important" : "#DC4D28 !important"
                                    }
                                    variant={"caption"}
                                >
                                    &nbsp;REGULARIZE
                                </Typography>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Generate Report for ${cellValues?.row?.fullName}`} placement="top">
                            <IconButton
                                onClick={() => handleGenerateReport({ empCode: cellValues?.row?.empCode })}
                            >
                                <AssessmentIcon
                                    fontSize="small"
                                    sx={{
                                        color: cellValues?.row?.isModified ? "grey" : "#DC4D28 !important",
                                    }}
                                />
                                <Typography
                                    color={"#DC4D28 !important"}
                                    variant={"caption"}
                                >
                                    &nbsp;REPORT
                                </Typography>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
            width: 250,
            sortable: false,
        },
    ];

    const color = themeMode ? '#fff' : '#000'
    const commonSX = {
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#808080', // Set default border color to #808080
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Change the border color to primary when focused
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Keep the border color the same on hover
            },
            '& .MuiInputBase-input': {
                color: color, // Set input text color to white (#fff)
                cursor: '#fff', // Set cursor color to white (#fff)
            },
            '& .MuiInputBase-input::placeholder': {
                color: color, // Set placeholder color to white (#fff)
            },
        },
        '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
                color: color,
            },
        },
        '& .MuiFormLabel-root': {
            color: color, // Set placeholder color to white (#fff)
        },
        '& .MuiInputLabel-shrink + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main', // Set border color to primary when the label floats up
        },
    }

    return (
        <Box sx={CommonStyles?.OuterBox}>
            <Typography sx={DepartmentSettingsStyles.headingTypo}>
                Attendance {moment(dateForAttendance).format("DD-MM-YYYY") || ""}
            </Typography>
            <Box sx={DepartmentSettingsStyles.innerBox}>
                <Paper
                    sx={{
                        ...DepartmentSettingsStyles.paperContainer,
                    }}
                    className="theme-component-background theme-border theme-cards-boxShadow"
                >
                    <Grid container direction={"row-reverse"} >
                        <Grid item xs={1.5}>
                            <TextInput
                                label="Date for Attendance"
                                type="date"
                                name="dateForAttendance"
                                value={dateForAttendance}
                                onChange={(e) => setDateForAttendance(e.target.value)}
                                inputProps={{
                                    max: moment().format("YYYY-MM-DD"),
                                }}
                                sx={commonSX}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <Button
                                sx={{ ...CommonStyles.actionButton, width: "9vw", height: "100%" }}
                                onClick={() => handleGenerateReport({})}>Generate Report
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Datatable
                    checkBox={false}
                    rows={attendanceData}
                    columns={columns}
                    pageSize={15}
                    rowsPerPage={15}
                    enableSearch
                />
            </Box>
            {openModal && (
                <AttendanceModal
                    handleClose={() => setOpenModal(false)}
                    setUpdateTime={setUpdateTime}
                    updateEmployeeAttendance={updateEmployeeAttendance}
                    empName={empData.fullName}
                />
            )}
            <GenerateReportModal
                reportModalOpen={reportModalOpen}
                closeReportModal={closeReportModal}
                type='attendence'
                body={employeeCode && { empCode: employeeCode, genEmpRep: true }}
            />
        </Box>
    );
};

export default Attendance;