import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import InitialPageAM from './InitialPageAM'
import ReportStatsAM from './ReportStatsAM'
import ReportTableAM from './ReportTableAM'
import ReportChartDataAM from './ReportChartDataAM'
import splitArrayIntoChunks from '../../../../helpers/splitArrayIntoChunks'

const PDFReportGeneratorAM = React.forwardRef((props, ref) => {
    const { reportData } = props
    const buildings = useSelector(state => state.buildings?.buildings)
    const selectedBuilding = localStorage.getItem('bid')
    const selectedBuildingData = buildings?.find(building => building?._id === selectedBuilding)


    const overdueMaintenanceAssets = splitArrayIntoChunks(reportData?.data?.overdueMaintenanceAssets || [], 7);
    const upcomingMaintenanceAssets = splitArrayIntoChunks(reportData?.data?.upcomingMaintenanceAssets || [], 7);
    const expiringAssets = splitArrayIntoChunks(reportData?.data?.expiringAssets || [], 7);
    const expiredAssets = splitArrayIntoChunks(reportData?.data?.expiredAssets || [], 7);

    const timePeriod = reportData?.data?.timePeriod
    return (
        <Box ref={ref} width={'100%'} >
            {/* initial page of report */}
            <InitialPageAM selectedBuildingData={selectedBuildingData} timePeriod={timePeriod} />
            {/* actual report */}
            <ReportStatsAM statistics={reportData?.data?.statistics || {}} />
            <ReportChartDataAM data={reportData?.data?.categoryWiseAssets || []} />
            <ReportTableAM reportChunks={overdueMaintenanceAssets} type='OVERDUE MAINTENANCE ASSETS' timePeriod={timePeriod} />
            <ReportTableAM reportChunks={upcomingMaintenanceAssets} type='UPCOMING MAINTENANCE ASSETS' timePeriod={timePeriod} />
            <ReportTableAM reportChunks={expiredAssets} type='EXPIRED ASSETS' timePeriod={timePeriod} />
            <ReportTableAM reportChunks={expiringAssets} type='EXPIRING ASSETS' timePeriod={timePeriod} />
        </Box >
    )
})

export default PDFReportGeneratorAM