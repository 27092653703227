export const addNewWidgetTemplateItems = [
    {
        "i": "Video",
        "x": 0,
        "y": 0,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Energy Consumption",
        "x": 0,
        "y": 3,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Energy Sources",
        "x": 0,
        "y": 6,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Alerts",
        "x": 0,
        "y": 9,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Total Occupancy",
        "x": 0,
        "y": 12,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Energy Consumption History",
        "x": 0,
        "y": 15,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Energy Carbon Emissions",
        "x": 0,
        "y": 18,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Booked Desks",
        "x": 0,
        "y": 21,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Meeting Rooms",
        "x": 0,
        "y": 24,
        "w": 3,
        "h": 3,
    },
    {
        "i": "AQI",
        "x": 0,
        "y": 27,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Restrooms",
        "x": 0,
        "y": 30,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Fault Reports",
        "x": 0,
        "y": 33,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Feedback",
        "x": 0,
        "y": 36,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Visitor Management",
        "x": 0,
        "y": 39,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Asset Management",
        "x": 0,
        "y": 42,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Document Hub",
        "x": 0,
        "y": 45,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Device Management",
        "x": 0,
        "y": 48,
        "w": 3,
        "h": 3,
    },
    {
        "i": "CCTV Cameras",
        "x": 0,
        "y": 51,
        "w": 3,
        "h": 3,
    },
    {
        "i": "HVAC",
        "x": 0,
        "y": 54,
        "w": 3,
        "h": 3,
    },
    {
        "i": "Water Consumption",
        "x": 0,
        "y": 57,
        "w": 3,
        "h": 3,
    }
]

const defaultDashboardGrid = [
    {
        "i": "Video",
        "x": 0,
        "y": 0,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Energy Consumption",
        "x": 3,
        "y": 0,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Energy Sources",
        "x": 6,
        "y": 0,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Alerts",
        "x": 9,
        "y": 0,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Total Occupancy",
        "x": 0,
        "y": 3,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Energy Consumption History",
        "x": 3,
        "y": 3,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Energy Carbon Emissions",
        "x": 6,
        "y": 3,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Booked Desks",
        "x": 9,
        "y": 3,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Meeting Rooms",
        "x": 0,
        "y": 6,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "AQI",
        "x": 3,
        "y": 6,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Restrooms",
        "x": 6,
        "y": 6,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Fault Reports",
        "x": 9,
        "y": 6,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Feedback",
        "x": 0,
        "y": 9,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Visitor Management",
        "x": 3,
        "y": 9,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Asset Management",
        "x": 6,
        "y": 9,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Document Hub",
        "x": 9,
        "y": 9,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Device Management",
        "x": 0,
        "y": 12,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "CCTV Cameras",
        "x": 3,
        "y": 12,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "HVAC",
        "x": 6,
        "y": 12,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
    {
        "i": "Water Consumption",
        "x": 9,
        "y": 12,
        "w": 3,
        "h": 3,
        "minH": 3,
        "minW": 3,
        "maxH": 9,
        "maxW": 12
    },
]

export default defaultDashboardGrid