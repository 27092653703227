import { Box, Typography, } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <Box display='flex' justifyContent={'space-between'}>
            <Typography variant='caption' pb={1} sx={{ opacity: 0.6, }}>Copyright © 2023 Nhance Digital BuildTech Pvt Ltd. All rights reserved.</Typography>
            <Typography variant='caption' pb={1} pr={3} sx={{ opacity: 0.6, }}>All trademarks, logos and brand names are the property of their respective owners</Typography>
        </Box>
    )
}

export default Footer