import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json')
            return headers;
        }
    }),
    tagTypes: ['buildings', 'weather', 'userManagement', 'camera', 'occupancy', 'alerts', 'faultReports', 'aqi', 'washrooms', 'desks', "meetingRoom", "energy", "digitalDocumentation", "digitalDocumentationKeydate", "digitalDocumentationReminder", "digitalDocumentationShare", "digitalDocumentationChat"],
    endpoints: builder => ({})
})