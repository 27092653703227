import { Avatar } from '@mui/material'
import React from 'react'

const NhanceAvatar = (props) => {
    const { children, className, ...rest } = props
    return (
        <Avatar className={`avataricon cursorPtr ${className} `} {...rest}>
            {children}
        </Avatar>
    )
}

export default NhanceAvatar