import { useEffect, useState } from "react"
import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, FormHelperText, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import FormInput from "../../../components/formInputs/FormInput"
import CommonStyles from "../../../styles/CommonStyles"
import { errorToast, successToast } from "../../../helpers/apiToast"
import { useSelector } from "react-redux"
import { GET, POST, PUT } from "../../../helpers/http"
import * as Yup from "yup"
import { ThemeColors } from "../../../hooks/ThemeColors"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CustomTypography from "../../../components/texts/CustomTypography"
import SubGroupForm from "./SubGroupForm"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SensorsIcon from '@mui/icons-material/Sensors';

const SensorForm = ({ data, type, handleCloseFormModal, renderSensorGroups }) => {
    const [formData, setFormData] = useState(
        type === "edit" ?
            { name: data?.name, description: data?.description, sensors: data?.sensors, charts: data?.charts, sensorSubGroups: data?.subGroups } : { name: "", description: "", sensors: [], sensorSubGroups: [], charts: [] })
    const [formError, setFormError] = useState({})
    const [sensorsData, setSensorsData] = useState([])
    const [chartTypes, setChartTypes] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [subGroupChecked, setSubGroupChecked] = useState((type === "edit" && data?.subGroups?.length > 0) || false)
    const [selectedSubGroup, setSelectedSubGroup] = useState('')
    const buildingId = useSelector(state => state?.buildings?.selectedBuilding)
    const { borderSX, textColor } = ThemeColors()

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    const getSensorOptions = async (buildingId) => {
        try {
            const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/options?bid=${buildingId}&type=sensor`;
            const response = await GET(endPoint, "Get Energy groups")
            setSensorsData([...response?.data?.sensors])
            setChartTypes([...response?.data?.chartTypes])
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }

    useEffect(() => {
        if (buildingId) {
            getSensorOptions(buildingId)
        }
    }, [buildingId])

    const validateFormData = async ({ formData }) => {
        try {
            const validationSchema = Yup.object().shape({
                name: Yup.string()
                    .min(3, "Name should be at least 3 characters")
                    .max(20, "Name should be at most 20 characters")
                    .required("Name is required"),
                description: Yup.string()
                    .min(5, "Description should be at least 5 characters")
                    .max(500, "Description should be at most 500 characters")
                    .required("Description is required"),
                sensors: Yup.array()
                    .of(Yup.string().required("Sensor is required")),
                //.min(1, "At least one sensor must be selected")
                //.required("Sensors are required"),
                charts: Yup.array()
                    .of(Yup.string().required("charttype is required"))
                    .min(1, "At least one charttype must be selected")
                    .required("Chart type is required"),
                subGroups: Yup.array("sub groups should be an array").of(Yup.object({
                    name: Yup.string(),
                    sensors: Yup.array()
                        .of(Yup.string().required("Sensor is required"))
                        .min(1, "At least one sensor must be selected")
                }))
            });
            await validationSchema.validate(formData, { abortEarly: false });
            return {};
        } catch (validationErrors) {
            const errors = validationErrors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            return errors;
        }
    }

    const handleAddSensorGroup = async ({ e, formData, type, id }) => {
        e.preventDefault()
        try {
            const structuredFormData = {
                name: formData?.name,
                description: formData?.description,
                sensors: formData?.sensors?.map(sensor => sensor?._id),
                charts: formData?.charts,
                subGroups: formData?.sensorSubGroups?.map(group => {
                    return { name: group?.name, sensors: group?.sensors?.map(sensor => sensor?._id) }
                })
            }
            const errors = await validateFormData({ formData: structuredFormData })
            if (Object.keys(errors)?.length > 0) {
                setFormError(errors)
            } else {
                setFormError({})
                if (type === "add") {
                    const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/?bid=${buildingId}`;
                    const response = await POST(endPoint, structuredFormData, "Add Energy group")
                    if (response?.status === 201) {
                        successToast("Sensor Group added Successfully.")
                        handleCloseFormModal()
                        renderSensorGroups()
                    }
                } else if (type === "edit") {
                    if ((subGroupChecked && formData?.sensorSubGroups?.length > 0 && !data?.isDefaultGroup) ||
                        (!subGroupChecked && formData?.sensors?.length > 0)) {
                        const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/${id}?bid=${buildingId}`;
                        const response = await PUT(endPoint, structuredFormData, "Add Energy group")
                        if (response?.status === 200) {
                            successToast("Sensor Groups updated Successfully.")
                            handleCloseFormModal()
                            renderSensorGroups()
                        }
                        setErrorMessage(false)
                    } else {
                        setErrorMessage(true)
                    }
                }
            }
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }

    const handleChartTypeSelection = (e, chart) => {
        if (e.target.checked) setFormData({ ...formData, charts: [...formData?.charts, chart] })
        else setFormData({ ...formData, charts: formData?.charts?.filter(val => val !== chart) })
    }

    const handleAddSubSensorGroup = (subSensorFormData) => {
        setFormData({ ...formData, sensorSubGroups: [...formData.sensorSubGroups, subSensorFormData] })
        setErrorMessage(false)
    }

    const handleUpdateSubSensorGroup = (subSensorFormData) => {
        setFormData({
            ...formData, sensorSubGroups: formData.sensorSubGroups?.map(sub => {
                if (sub?._id === subSensorFormData?._id) {
                    return subSensorFormData
                } else return sub
            })
        })
        setSelectedSubGroup('')
    }

    const handleDeleteSubSensor = ({ name }) => {
        setFormData({ ...formData, sensorSubGroups: formData?.sensorSubGroups?.filter((group) => group?.name !== name) })
    }

    const handleEditSubSensor = ({ name }) => {
        setSelectedSubGroup(name)
    }

    return (
        <>
            <Box className="flex flexDirectionCol gap32 padtop16 padright16">
                <FormInput
                    label={'Group Name'}
                    fullWidth
                    value={formData?.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    name={'name'}
                    helperText={formError?.name ?? null}
                    disabled={data?.isDefaultGroup}
                />
                <FormInput
                    label={'Description'}
                    multiline
                    fullWidth
                    value={formData?.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    name={'description'}
                    helperText={formError?.description ?? null}
                    disabled={data?.isDefaultGroup}
                />
                <div className="flex flexDirectionCol">
                    <div className="flex flexDirectionRow">
                        {chartTypes?.map((chart, i) => {
                            return <FormControlLabel
                                key={i}
                                sx={{
                                    ".MuiSvgIcon-root MuiSvgIcon-fontSizeMedium": {
                                        color: textColor(),
                                    },
                                    '& .Mui-checked': {
                                        color: textColor(),
                                    },
                                }}
                                control={<Checkbox
                                    checked={formData?.charts?.includes(chart)}
                                    onChange={(e) => handleChartTypeSelection(e, chart)}
                                    name="chartType" />}
                                label={`${chart[0].toUpperCase()}${chart?.slice(1)}`}
                            />
                        })}
                    </div>
                    {formError?.charts && <FormHelperText>{formError?.charts}</FormHelperText>}
                </div>
                {!subGroupChecked && <Autocomplete
                    multiple
                    id="select-sensors"
                    options={sensorsData}
                    value={formData?.sensors}
                    disableCloseOnSelect
                    getOptionLabel={(option) => `${option?.sensorId}`}
                    sx={{
                        ...borderSX,
                        ".MuiChip-root": {
                            color: textColor(), border: `1px solid ${textColor()}`
                        },
                        ".MuiChip-deleteIcon": {
                            color: textColor()
                        }
                    }}
                    onChange={(event, newValue) => {
                        setFormData({ ...formData, sensors: newValue })
                        setErrorMessage(false)
                    }}
                    filterOptions={(options, { inputValue }) => {
                        const selectedSensorIds = formData?.sensors?.map(sensor => sensor.sensorId) ?? [];
                        const selectedSubGroupSensorIds = formData?.sensorSubGroups?.map(group => group?.sensors).flat().map(value => value?.sensorId)
                        const allSensorIds = [...selectedSensorIds, ...selectedSubGroupSensorIds]
                        if (!inputValue) {
                            return options.filter(option => !allSensorIds.includes(option?.sensorId));
                        }
                        return options.filter(option => {
                            const isNotSelected = !allSensorIds.includes(option.sensorId);
                            const matchesSearch = (
                                option.sensorId.toLowerCase().includes(inputValue.toLowerCase()) ||
                                option.vendorName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                option.floorName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                option.areaName.toLowerCase().includes(inputValue.toLowerCase())
                            );
                            return isNotSelected && matchesSearch;
                        });
                    }}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                            <li key={key} {...optionProps}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {`${option?.sensorId} - ${option?.vendorName}- ${option?.floorName} - ${option?.areaName}`}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Single Sensors"
                            placeholder="Sensors"
                            helperText={formError?.sensors ?? null} />
                    )}
                />}
                {(!data?.isDefaultGroup && formData?.sensors?.length === 0) && <FormControlLabel
                    sx={{
                        ".MuiSvgIcon-root MuiSvgIcon-fontSizeMedium": {
                            color: textColor,
                        },
                        '& .Mui-checked': {
                            color: textColor,
                        },
                    }}
                    control={<Checkbox
                        style={{ marginRight: 8 }}
                        checked={subGroupChecked}
                        onChange={(e) => { setSubGroupChecked(e.target.checked); setFormData({ ...formData, sensorSubGroups: [] }) }}
                    />}
                    label={`Sub-group`}
                />}
                {subGroupChecked && <SubGroupForm
                    sensorsData={sensorsData}
                    formData={formData}
                    handleAddSubSensorGroup={handleAddSubSensorGroup}
                    handleUpdateSubSensorGroup={handleUpdateSubSensorGroup}
                    selectedSubGroupName={selectedSubGroup}
                />}
                {formData?.sensorSubGroups?.length > 0 &&
                    <div className="flex flexDirectionCol gap8">
                        <CustomTypography className="font16 fontweight600">Sub-Groups</CustomTypography>
                        <Grid container gap={1}>
                            {formData?.sensorSubGroups?.map((group, index) => {
                                return <Grid item key={index} sx={{ height: "3rem", alignContent: "center" }} xs={5.9} className="border bdrRadius1rem">
                                    <div className="flex flexDirectionRow flexJustifyBetween flexAlignItemsCenter gap16 pad-x-16px">
                                        <CustomTypography>{group?.name}</CustomTypography>
                                        <div className="flex flexAlignItemsCenter">
                                            <Tooltip title={group?.sensors?.map(val => val?.sensorId ?? val).join(', ')}>
                                                <Chip label={
                                                    <Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
                                                        <SensorsIcon color='primary' fontSize='small' />
                                                        <Box className='font15' sx={{ color: textColor }} >{group?.sensors?.length}</Box>
                                                    </Box>
                                                } />

                                            </Tooltip>
                                            <IconButton
                                                sx={{ padding: 0, pl: 1, color: textColor }}
                                                className="mrgnleft5"
                                                onClick={() => handleEditSubSensor({ name: group?.name })}>
                                                <EditNoteIcon />
                                            </IconButton>
                                            <IconButton
                                                sx={{ padding: 0, color: textColor }}
                                                className="mrgnleft5"
                                                onClick={() => handleDeleteSubSensor({ name: group?.name })}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                            })}
                        </Grid>
                    </div>}
                {errorMessage && <Typography className="font14 color-red" >{`Please select at least one ${subGroupChecked ? 'Sub group' : 'Sensor'}`}</Typography>}
                {type === "add" ?
                    <Button
                        type="submit"
                        onClick={(e) => handleAddSensorGroup({ e, formData, type: "add" })}
                        sx={{ ...CommonStyles.actionButton }}>
                        Add Group
                    </Button> :
                    <Button
                        type="submit"
                        onClick={(e) => handleAddSensorGroup({ e, formData, type: "edit", id: data?._id })}
                        sx={{ ...CommonStyles.actionButton }}>
                        Update
                    </Button>}
            </Box>
        </>
    )
}

export default SensorForm

