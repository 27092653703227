import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { errorHandler } from '../../../components/facilityManager/helpers/http'

const fetchFeedbackSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFeedback: builder.query({
            query: (buildingId) => ({
                url: `fb/v1/average_ratings/?buildingId=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Get Feedback')
            }
        })
    })
})

export const { useGetFeedbackQuery } = fetchFeedbackSlice

const initialState = {
    avgBuildingRating: {
        isLoaded: false,
        data: {}
    },
    areaRatings: {
        isLoaded: false,
        data: []
    }
}

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        resetFeedback: (state, action) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getFeedback' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.avgBuildingRating.isLoaded = true
                    state.avgBuildingRating.data = action.payload?.buildingAverageRatings?.[0] || {}
                    state.areaRatings.isLoaded = true
                    state.areaRatings.data = action.payload?.areaAverageRatings
                }
            )
    }
})

export const { resetFeedback } = feedbackSlice.actions

export default feedbackSlice.reducer