import * as React from 'react';
import AreaTableCollapsed from './AreaTableCollapsed';
import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Paper, } from '@mui/material';
import { useEffect, useState } from 'react';
import { GET } from '../../../../../helpers/http';
import { useSelector } from 'react-redux';
import CircularProgressLoader from '../../../../../styles/CircularProgress';
import { errorToast } from '../../../../../helpers/apiToast';

export default function AreasTable() {
    const [floorData, setFloorData] = useState([])
    const themeMode = useSelector(state => state.theme.darkTheme)
    const currentBuildingId = useSelector((state) => state?.buildings?.selectedBuilding)
    const [isPageLoaded, setIsPageLoaded] = useState(true)
    const color = themeMode ? '#fff' : '#000'


    useEffect(() => {
        setIsPageLoaded(false)
        if (currentBuildingId) {
            GET(`${process.env.REACT_APP_BASE_URL}twin/v1/area/arealist?bid=${currentBuildingId}`, "Get Floors")
                .then((data) => {
                    setFloorData(data?.data);
                    setIsPageLoaded(true)
                })
                .catch((error) => {
                    setIsPageLoaded(true)
                    errorToast(error.status)
                    console.log(error);
                })
        }
    }, [currentBuildingId])



    return (
        <>
            {
                isPageLoaded ?
                    <TableContainer component={Paper}
                        className='theme-border theme-component-background theme-cards-boxShadow'
                    >
                        <Table aria-label="collapsible table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell width='20vw' />
                                    <TableCell sx={{ fontSize: 18, fontWeight: 600, color }} align='left'>Floors</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {floorData?.map((floor) => (
                                    <AreaTableCollapsed key={floor?.floorId} floor={floor} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer >
                    :
                    <CircularProgressLoader />
            }
        </>
    );
}
