import { createContext, useReducer } from "react";
import { PPMStatsReducer } from "./PPMStatReducer";


export const PPMStatsContext = createContext(null);

const statsInitialValue = {
    ppmStats: {},
    isLoaded: false,
};

const PPMStatsProvider = ({ children }) => {
    const [statistics, statsDispatch] = useReducer(PPMStatsReducer, statsInitialValue)
    return (
        <PPMStatsContext.Provider value={{ statistics, statsDispatch }}>
            {children}
        </PPMStatsContext.Provider>
    )
}

export default PPMStatsProvider