import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import CustomTypography from "../../../components/texts/CustomTypography"
import { useSelector } from "react-redux"

const SensorsTable = ({ selectedSensorGroup }) => {
    const tableHead = ["Sensor Id", "Vendor Name", "Floor    Name"]
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const color = themeMode ? '#fff' : '#000'
    const tableBgColor = themeMode ? '#282a2e' : '#dddddd'

    return (
        <Box className="flex flexDirectionCol gap32 padbtm30">
                <Box className="flex flexDirectionCol gap8"> 
                    <CustomTypography >{selectedSensorGroup?.description}</CustomTypography>
                </Box>
            {selectedSensorGroup?.sensors?.length > 0 && <Box className="flex flexDirectionCol gap8 boxShadow48 bdrRadius8px padbtm10 mrgnright16">     
                <CustomTypography className="font20 bolder padtop16 padleft15">Sensors</CustomTypography>
                <TableContainer component={Paper} 
                    className="bdrRadius0 boxShadowNone pad10"
                    sx={{ maxHeight: "70vh", overflow: "auto", backgroundColor: bgcolor, }}>
                <Table className="border">
                    <TableHead>
                        <TableRow >
                                {tableHead?.map((head, i) => <TableCell sx={{
                                    color: color,
                                    border: 1,
                                    backgroundColor: tableBgColor
                                }} key={i}>{head}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedSensorGroup?.sensors?.map((sensor ) => (
                            <TableRow
                                key={sensor?._id}
                                sx={{ border: 1 }}
                                >
                                <TableCell component="th" scope="row" sx={{ color: color, border: 1 }}>
                                    {sensor?.sensorId ?? sensor}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ color: color, border: 1 }}>
                                    {sensor?.vendorName}
                                </TableCell>
                                <TableCell sx={{ color: color,border: 1 }}>{sensor?.floorName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                </Box>}
            {selectedSensorGroup?.subGroups?.length > 0 && <div className="padbtm10 boxShadow48 bdrRadius8px mrgnright16">
                <CustomTypography className="font20 bolder padtop16 padleft15">Sensor Sub Groups</CustomTypography>
                {selectedSensorGroup?.subGroups?.map(subGroup=>{
                    return (<TableContainer component={Paper}
                        className="bdrRadius0 boxShadowNone pad10"
                        sx={{ maxHeight: "70vh", overflow: "auto", backgroundColor: bgcolor, }}>
                        <CustomTypography className="font18 bolder padtop10 padbtm10">{subGroup?.name}</CustomTypography>
                    <Table className="border">
                        <TableHead>
                            <TableRow > 
                                    {tableHead?.map((head, i) => <TableCell sx={{ color: color, border: 1, backgroundColor: bgcolor }} key={i}>{head}</TableCell>)}
                            </TableRow>
                        </TableHead>    
                        <TableBody>
                                {subGroup?.sensors?.map((sensor) => (
                                <TableRow
                                    key={sensor?._id}
                                    sx={{ border: 1 }}
                                >
                                    <TableCell component="th" scope="row" sx={{ color: color, border: 1 }}>
                                        {sensor?.sensorId ?? sensor}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ color: color, border: 1 }}>
                                        {sensor?.vendorName}
                                    </TableCell>
                                    <TableCell sx={{ color: color, border: 1 }}>{sensor?.floorName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </TableContainer>)
                })}
                </div>
                }
            </Box>
    )
}

export default SensorsTable