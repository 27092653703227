const AddAssetExcelStyles = {
    gridContainer: {
        p: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    boxContainer: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fontWeight700: {
        fontWeight: 700,
        fontSize: 16,
    },
    boxContainerTwo: {
        p: 20,
        border: '1px dashed grey',
        backgroundColor: '#efefef',
        color: 'black',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    boxItem: {
        m: 1,
        color: "black"
    },
    fileInput: {
        bgColor: 'red',
        color: 'black',
        borderColor: 'black'
    }
}
export default AddAssetExcelStyles
